import { makeStyles } from '@material-ui/core/styles'

export const SeeAllAppsMobileStyles = makeStyles((theme) => ({
  appBar: {
    position: 'fixed',
    background: theme.palette.background.paper,
    color: 'rgba(0,0,0,0.54)',
  },
  appListContainer: {
    paddingTop: 55,
  },
  container: {
    flex: 1,
    width: '100%',
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
    overscrollBehavior: 'contain',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    justifyContent: 'center',
    // paddingTop: 55,
  },
  myRutgersLogo: {
    height: 0,
    maxWidth: 0,
  },
  mobileBackButtonIcon: {
    color: theme.palette.base.red,
    fontSize: 24,
  },
  pageTitleContainer: {
    width: '86%',
  },
  pageTitle: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 1,
    wordBreak: 'keep-all',
    overflow: 'hidden',
    color: theme.palette.base.red,
    fontSize: 22,
    fontWeight: '400',
  },
  containerDesktop: {
    flex: 1,
    width: '100%',
    margin: '30px',
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
    marginBottom: 'auto',
    paddingBottom: '20px',
  },
  relatedText: {
    fontSize: '24px',
    fontWeight: '600',
    color: theme.palette.primary.headerText,
    marginRight: '125px',
    paddingBottom: '10px',
  },
}))

export default SeeAllAppsMobileStyles
