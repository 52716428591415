import * as React from 'react'

/**
  Flat List is a better version of map that takes care of a few edge cases.
*/
function FlatList({
  data,
  renderItem,
  keyExtractor,
  inverted,
  ItemSeparatorComponent,
  ListEmptyComponent,
  ListHeaderComponent,
  ListFooterComponent,
}) {
  if (data?.length === 0) return ListEmptyComponent

  function renderItemWithExtras(item, i) {
    return (
      <>
        {i !== 0 ? ItemSeparatorComponent : null}
        {renderItem(item, i)}
      </>
    )
  }

  return (
    <>
      {ListHeaderComponent}
      {(inverted ? data.reverse() : data).map((item, i) =>
        keyExtractor ? (
          <React.Fragment key={keyExtractor(item, i)}>
            {renderItemWithExtras(item, i)}
          </React.Fragment>
        ) : (
          renderItemWithExtras(item, i)
        ),
      )}
      {ListFooterComponent}
    </>
  )
}

export default FlatList
