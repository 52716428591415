import { makeStyles, createStyles } from '@material-ui/styles'

export const NewsSearchBarStyles = makeStyles((theme) =>
  createStyles({
    searchBar: {
      backgroundColor: theme.palette.backgroundColor.secondary,
      width: '100%',
    },
  }),
)

export default NewsSearchBarStyles
