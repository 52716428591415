import * as React from 'react'
import { useDispatch } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { doc, collection, getDoc, setDoc } from 'firebase/firestore'
import { firestore } from '../../../utils/firebase'
import {
  codeOfConductEmployeesLink,
  codeOfConductStudentsLink,
} from '../sideNav/communityAgreement.constants'
import { createCommunityIDUser } from '../../features/dashboard/widgets/MyProfile/myProfileSlice'

function CommunityDialog({ classes, open, handleClose, handleRedirect }) {
  const dispatch = useDispatch()
  const [agree, updateAgree] = React.useState(false)
  const handleAgreement = (event) => {
    updateAgree(event.target.checked)
  }
  const updateCommunityAgreement = (acceptClicked) => {
    const docRef = doc(
      collection(firestore, 'users'),
      localStorage.getItem('user') ?? 'visitor',
      'settings',
      'userState',
    )
    getDoc(docRef)
      .then((docSnap) => {
        const snapshotData = docSnap.data()
        if (!snapshotData.acknowledgedCommunity) {
          setDoc(docRef, { acknowledgedCommunity: acceptClicked })
            .then(() => {
              dispatch(createCommunityIDUser()).then((response) => {
                console.log('Community agreement response ', response)
                if (acceptClicked) handleRedirect()
                else handleClose()
              })
            })
            .catch((error) => {
              console.error('Error updating community agreement:', error)
            })
        }
      })
      .catch((error) => {
        console.error('Error fetching user state:', error)
      })
  }

  const handleAgreeClicked = () => {
    updateCommunityAgreement(true)
  }

  const handleDeclineClicked = () => {
    updateCommunityAgreement(false)
  }
  return (
    <Dialog
      open={open}
      classes={{ paper: classes.mainDialog }}
      onClose={handleClose}
    >
      <DialogTitle>
        <Typography variant="h2">Community Agreement</Typography>
      </DialogTitle>
      <DialogContent>
        <CommunityAgreement agree={agree} handleAgreement={handleAgreement} />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleDeclineClicked} color="primary">
          DECLINE
        </Button>
        <Button
          autoFocus
          onClick={handleAgreeClicked}
          color="primary"
          variant="contained"
          disabled={!agree}
        >
          AGREE
        </Button>
      </DialogActions>
    </Dialog>
  )
}

function CommunityAgreement({ agree, handleAgreement }) {
  const codeOfConductStudent = (
    <a href={codeOfConductStudentsLink}> University Code of Student Conduct</a>
  )
  const codeOfConductEmployee = (
    <a href={codeOfConductEmployeesLink}>Ethics – Code of Conduct </a>
  )
  return (
    <>
      <Typography variant="body1">
        Welcome to my Community feature on myRutgers. This is the official
        service for students, faculty, and staff to engage with the Rutgers
        community. This service is an extension of the Rutgers community where
        we desire to create a positive and uplifting virtual environment that
        reflects our community standards. Therefore, when using the app, users
        will be expected to abide by all of the policies and rules included in
        the {codeOfConductStudent} (student) and {codeOfConductEmployee}
        (employee).
      </Typography>
      <br />
      <Typography variant="body1">
        The Code may be applied to conduct online and via email or other
        electronic media. Students, faculty, staff, and student organizations
        should be aware that online media such as blogs, websites, chats, and
        social networking sites are in the public sphere and some contents are
        not private. Online media that indicate possible misconduct may subject
        a student, faculty, staff, and/or student organization to disciplinary
        action. All members of the Rutgers community who agree to the terms and
        conditions of using my Community feature have access to its content.
      </Typography>
      <Box display="flex" alignItems="center">
        <Checkbox
          name="agree"
          value={agree}
          onChange={handleAgreement}
          inputProps={{ 'aria-label': 'Agree Checkbox' }}
          size="small"
        />
        <Typography variant="subtitle1">
          {' '}
          I agree to the above Terms and Conditions of Rutgers my Community
          agreement.
        </Typography>
      </Box>
    </>
  )
}

export default CommunityDialog
