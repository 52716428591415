import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) => ({
  container: {
    padding: 0,
    margin: 0,
    height: 470,
    width: 335,
    overflowY: 'hidden',
    overflowX: 'hidden',
    [theme.breakpoints.down(370)]: {
      width: 287,
      height: 450,
    },
  },
  listingCard: {
    margin: '5px',
  },
  listingsHolder: {
    height: 'calc(470px - 70px)',
    width: '335px',
    margin: 0,
    padding: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  footer: {
    width: '335px',
    height: '70px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  image: {
    width: '250px',
    padding: '5px',
    height: 'auto',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  description: {
    width: '100%',
    whiteSpace: 'pre-wrap',
    overFlowWrap: 'break-word',
    wordWrap: true,
  },
  applyNowButton: {
    backgroundColor: theme.palette.base.red,
    color: theme.palette.base.white,
  },
}))

export default styles
