import * as React from 'react'
import _ from 'lodash'
import { List, ListSubheader } from '@material-ui/core'
import { useSelector } from 'react-redux'
import {
  fetchRoutesFavorites,
  setSelectedRoutes,
} from '../../../../sections/Map/redux/myMapsSlice'
import BusListComponent from '../../../List/BusListComponent/BusListComponent'
import {
  EmptyComponent,
  sortListAlphabetically,
} from '../../../../utils/helpers'

// Render the bus list within the drawer
function BusList({ classes, dispatch }) {
  const [loading, setLoading] = React.useState(true)

  const {
    routes,
    routesLoading,
    selectedRoutes,
    selectedFavoritedRoutes,
    favoritedRoutesLoading,
  } = useSelector((s) => s.maps)

  React.useEffect(() => {
    dispatch(fetchRoutesFavorites())
  }, [])

  React.useEffect(() => {
    if (
      routesLoading === 'fulfilled' &&
      favoritedRoutesLoading === 'fulfilled'
    ) {
      setSheetData()
      setLoading(false)
    } else {
      setLoading(true)
    }
  }, [routesLoading, favoritedRoutesLoading])

  const setSheetData = () => {
    dispatch(
      setSelectedRoutes(
        sortListAlphabetically(
          routes.filter((el) => ![].includes(el)),
          'route_long_name',
        ),
      ),
    )
  }

  return (
    <div className={classes.outerList}>
      {loading ? (
        <></>
      ) : (
        <>
          {selectedRoutes.length > 0 && (
            <List className={classes.list}>
              {selectedFavoritedRoutes.length > 0 && (
                <li
                  key="section-favorited-routes"
                  className={classes.newListSection}
                >
                  <ul className={classes.newUl}>
                    <ListSubheader className={classes.listSubHeader}>
                      Favorited Routes
                    </ListSubheader>
                    {selectedFavoritedRoutes.map((route) => (
                      <BusListComponent
                        key={_.uniqueId(`favorite_route_`)}
                        route={route}
                        busName={route.route_long_name}
                        busColor={route.route_color}
                        routeID={route.route_id}
                        isFavorited={selectedFavoritedRoutes.some(
                          (r) => r.route_id === route.route_id,
                        )}
                      />
                    ))}
                  </ul>
                </li>
              )}

              <li key="section-routes" className={classes.newListSection}>
                <ul className={classes.newUl}>
                  <ListSubheader className={classes.listSubHeader}>
                    Routes
                  </ListSubheader>
                  {selectedRoutes.map((route) => (
                    <BusListComponent
                      key={_.uniqueId(`route_`)}
                      route={route}
                      busName={route.route_long_name}
                      busColor={route.route_color}
                      routeID={route.route_id}
                      isFavorited={selectedFavoritedRoutes.some(
                        (r) => r.route_id === route.route_id,
                      )}
                    />
                  ))}
                </ul>
              </li>
            </List>
          )}
          {selectedRoutes.length === 0 && (
            <div className={classes.list}>
              <EmptyComponent />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default BusList
