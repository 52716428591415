import React from 'react'
import { Button, Typography, makeStyles, Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import IconPicker from '../../../apps/AppComponents/IconPicker/IconPicker'

const footerButtonStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: (props) =>
      props.bgColor ? props.bgColor : theme.palette.base.red,
    color: theme.palette.base.white,
    textAlign: 'center',
    margin: '4px',
    width: 'calc(100% - 8px)',
    height: '36px',
    '&:focus-visible': {
      outline: `1px solid ${theme.palette.button.focusBorder}`,
      boxShadow: `0 0 0 3px ${theme.palette.button.focusOutline}`,
    },
    '&:hover': {
      cursor: 'pointer',
    },
    boxShadow: 'rgba(0, 0, 0, 0.15) 2px 2px 3px',
  },
  buttonContentWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}))

const WidgetFooterButton = ({ title, handleClick, bgColor, iconName }) => {
  console.log('title: ', title, handleClick)
  const classes = footerButtonStyles({ bgColor })
  return (
    <Button onClick={handleClick} className={classes.button}>
      <Box className={classes.buttonContentWrapper}>
        {iconName && (
          <Box style={{ marginRight: '10px' }}>
            <IconPicker iconName={iconName} size="xl" />
          </Box>
        )}
        <Typography variant="h6">{title}</Typography>
      </Box>
    </Button>
  )
}

export default WidgetFooterButton
WidgetFooterButton.propTypes = {
  title: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  iconName: PropTypes.string,
  handleClick: PropTypes.func,
}
