import React from 'react'
import { useSelector } from 'react-redux'
import ArticleLayout from '../../components/ArticleLayout/ArticleLayout'

function NewsSection(props) {
  const { loadingBuffer } = props
  const { articles, hidden, tag, humanReadableSectionName } = props.newsSection
  const { restArticlesIndex } = useSelector((s) => s.news)
  const [isLoading, setIsLoading] = React.useState(true)
  const IS_REST = tag === 'rest'

  React.useEffect(() => {
    if (articles) {
      // Start loadingBuffer after articles have loaded
      const timer = setTimeout(() => {
        setIsLoading(false)
      }, loadingBuffer + 500)

      // Clear the timer when the component unmounts
      return () => clearTimeout(timer)
    }

    // Return no-op function when `articles` is falsy
    return () => {}
  }, [articles])

  React.useEffect(() => {
    if (articles) {
      // Start loadingBuffer after articles have loaded
      const timer = setTimeout(() => {
        setIsLoading(false)
      }, loadingBuffer + 500)

      // Clear the timer when the component unmounts
      return () => clearTimeout(timer)
    }

    // Return no-op function when `articles` is falsy
    return () => {}
  }, [articles])

  return (
    <>
      {!hidden && (
        <ArticleLayout
          isLoading={isLoading}
          articlesWithImage={!IS_REST ? articles : []}
          sectionName={humanReadableSectionName}
          tag={tag}
          articlesWithoutImage={
            !IS_REST ? [] : articles?.slice(0, restArticlesIndex)
          }
        />
      )}
    </>
  )
}

export default NewsSection
