import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) => ({
  container: {
    padding: 0,
    margin: 0,
    height: 470,
    width: 335,
    overflowY: 'clip',
    overflowX: 'hidden',
    [theme.breakpoints.down(370)]: {
      width: 287,
      height: 450,
    },
  },
  chipBackground: {
    color: theme.palette.base.white,
    padding: 20,
    backgroundColor: theme.palette.base.green,
    minWidth: 200,
    marginTop: 5,
    '&:hover': {
      backgroundColor: `${theme.palette.base.green} !important`,
      filter: 'brightness(90%)',
    },
    '&:focus': {
      backgroundColor: theme.palette.base.green,
    },
  },
  retainer: {
    width: '100%',
  },
  visibilityIcon: {
    paddingTop: 0,
    fontSize: 42,
    color: theme.palette.base.white,
  },
  transparent: {
    backgroundColor: 'transparent !important',
  },
  modalTab: {
    width: '335px',
    height: '35px',
    minHeight: '35px',
    [theme.breakpoints.down(370)]: {
      width: '300px',
    },
  },
  linkIcon: {
    color:
      theme.palette.type === 'light'
        ? theme.palette.base.gray
        : theme.palette.base.white,
    fontSize: '30px',
    margin: '0px',
    padding: '0px',
  },
  icon: {
    color:
      theme.palette.type === 'light'
        ? theme.palette.base.gray
        : theme.palette.base.white,
    textAlign: 'center',
    fontSize: '30px',
    '&:hover': {
      transform: 'scale(1.07)',
    },
  },
  panel: {
    height: '435px',
    overflowX: 'clip',
    overflowY: 'clip',
  },
  fullHeightGrid: {
    height: '100%',
  },
  buttonGridItem: {
    padding: '15px',
  },
  reqHolder: {
    flex: 1,
    padding: '10px 0px',
    width: '100%',
    overflowY: 'auto',
    zIndex: 1,
  },
  noReqBox: {
    background: '#fafafa',
    border: `1px solid ${
      theme.palette.type === 'light'
        ? theme.palette.base.lightGray
        : theme.palette.base.red
    }`,
    padding: '10px',
    margin: '10px',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '10px',
  },
  schoolCodeCard: {
    backgroundColor: theme.palette.backgroundColor.ternary,
    color: theme.palette.primary.headerText,
    padding: '2px 7px',
    width: '325px',
    textAlign: 'center',
    marginBottom: '5px',
  },
  viewGrid: {
    height: '435px',
    padding: '5px 1px',
  },
  viewAwardsGrid: {
    height: '4340px',
    width: '333px',
    padding: '5px 1px',
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  fullWidth: {
    width: '335px',
  },
  statusButtonGreen: {
    width: '80px',
    backgroundColor: theme.palette.base.green,
    color: theme.palette.base.white,
  },
  statusButtonRed: {
    width: '80px',
    backgroundColor: theme.palette.base.red,
    color: theme.palette.base.white,
  },
  notifyTypeBreak: {
    wordWrap: 'break-word',
  },
  awardCard: {
    margin: '5px',
    padding: '5px',
    backgroundColor: theme.palette.backgroundColor.ternary,
  },
  gridButton: {
    margin: '10px 0',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  awardFooter: {
    padding: '0 10px',
  },
  assistanceCard: {
    backgroundColor: theme.palette.backgroundColor.ternary,
    color: theme.palette.primary.headerText,
    padding: '2px 7px',
    width: '325px',
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  titleRow: {
    backgroundColor: theme.palette.backgroundColor.secondary,
  },
  indexAdjust: {
    width: '100%',
    zIndex: 100,
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tinyText: {
    fontSize: '10px',
  },
  footer: {
    zIndex: 100,
    padding: '0px 5px',
    borderTop: '1px solid',
    borderColor: theme.palette.backgroundColor.ternary,
    backgroundColor: theme.palette.backgroundColor.secondary,
    height: '45px',
    paddingTop: '8px',
  },
  sirsRejectedPromptContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '5px',
    padding: '5px',
    backgroundColor: theme.color.rutgersRed,
    color: theme.color.white,
    borderRadius: '1rem',
  },
}))

export default styles
