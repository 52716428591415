import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) => ({
  container: {
    padding: 5,
    margin: 0,
    height: 470,
    width: 335,
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.breakpoints.down(370)]: {
      width: 287,
      height: 450,
    },
  },
  image: {
    width: '100%',
    padding: '0 !important',
    margin: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

export default styles
