import { makeStyles } from '@material-ui/core/styles'

export const useBottomSheetParkingStyles = makeStyles((theme) => ({
  scrollableContainer: {
    overflowX: 'auto',
  },
  parkingMarkerContainer: {
    width: '100%',
    marginTop: 10,
    overflowX: 'auto',
    overflowY: 'auto',
  },
  markerBody: {
    display: 'flex',
    flexDirection: 'column',
    flex: 'none',
    height: 'auto',
    width: 200,
    borderRadius: '1rem',
    border: 'none',
    marginLeft: 15,
    marginRight: 15,
    padding: 10,
    [theme.breakpoints.down(550)]: {
      width: 130,
      height: 130,
    },
    [theme.breakpoints.down(370)]: {
      width: 90,
      height: 90,
    },
  },
  markerLabel: {
    fontWeight: '700',
    marginBottom: 8,
    textAlign: 'center',
    width: '100%',
  },
  timeLabel: {
    marginBottom: 8,
  },
}))
