import { makeStyles } from '@material-ui/core/styles'

// TODO Bring Back Unique Colors
export const styles = makeStyles((theme) => ({
  container: {
    width: '42%',
    padding: '0',
    margin: '0 10px 10px 10px',
    textDecoration: 'none',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  card: {
    background: theme.palette.backgroundColor.ternary,
    borderRadius: '10px',
    boxShadow: 'none',
    transition: 'all .2s',
    '&:hover': {
      transform: 'scale(1.02)',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  image: {
    width: '100%',
    height: 95,
  },
  text: {
    textAlign: 'center',
    fontWeight: 'bold',
    padding: '4px 2px',
    margin: '0',
    color: theme.palette.primary.main,
  },
  cardContent: {
    padding: '0px !important',
    margin: 0,
  },
}))

export default styles
