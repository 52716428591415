import { makeStyles } from '@material-ui/core/styles'

export const busListComponentStyles = makeStyles((theme) => ({
  list: {
    width: '100%',
  },
  appBarIcon: {
    fontSize: 24,
    color: theme.palette.base.white,
  },
  busListText: {
    fontSize: 14,
    color: theme.palette.base.red,
    marginLeft: 10,
    marginRight: 10,
  },
  listItemIcon: {
    fontSize: 24,
    color: theme.palette.base.white,
  },
  favoriteIcon: {
    color: '#979797',
    fontSize: 24,
  },
  favoriteIconSelected: {
    color: '#cc0033',
    fontSize: 24,
  },
  iconButtonBorder: {
    position: 'relative',
    zIndex: 1000,
    fontSize: '24px',
    color: theme.color.rutgersRed,
  },
}))
