/* eslint-disable react/no-array-index-key */
import * as React from 'react'
import {
  Card,
  Typography as Text,
  Grid,
  Link,
  Collapse,
  IconButton,
} from '@material-ui/core'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { logDashboardEvent } from '../../events'
import { AnnouncementObjectStyles } from './RUDOTSServiceCenterStyles'

/**
 * Announcement Object shows announcements in the RU DOTS Service Center widget
 * @param announcement the announcement object with title and link traits
 */
function Announcement({ announcement, widgetId, dispatch }) {
  const classes = AnnouncementObjectStyles()
  const [expanded, setExpanded] = React.useState(false)
  const handleLinkClick = (link, title) => {
    if (widgetId && dispatch) {
      logDashboardEvent(dispatch, 'dashboardLinkEvent', widgetId, title, link)
    }
  }
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  dayjs.extend(localizedFormat)

  return (
    <>
      <Card
        className={classes.announcementCardBackground}
        border={1}
        key={announcement}
        variant="outlined"
      >
        <Grid container justifyContent="flex-start" direction="column">
          <Grid item className={classes.gridItem}>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href={announcement.details_url}
              onClick={() =>
                handleLinkClick(announcement.details_url, announcement.title)
              }
            >
              <Text variant="subtitle1">{announcement.title}</Text>
            </Link>
          </Grid>

          <Grid item>
            <Grid
              container
              justifyContent="flex-start"
              direction="column"
              className={classes.announcementDescription}
            >
              <Grid item>
                <IconButton
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                  className={classes.iconButton}
                >
                  <ExpandMoreIcon
                    className={
                      expanded ? classes.expandOpen : classes.expandClosed
                    }
                  />

                  <Text variant="button">View Additional Details</Text>
                </IconButton>

                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  {announcement.summary.length === 0 ? (
                    <Text variant="caption">
                      There is no announcement description.
                    </Text>
                  ) : (
                    <Text variant="caption" className={classes.descriptionText}>
                      {announcement.summary}
                    </Text>
                  )}
                </Collapse>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Text variant="subtitle2">
              {dayjs(announcement.from).format('LLL')}
            </Text>
          </Grid>
        </Grid>
      </Card>
    </>
  )
}

export default Announcement
