/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { doc, collection, getDoc, updateDoc } from 'firebase/firestore'
import axios from 'axios'
import Cookies from 'js-cookie'
import { auth, firestore } from '../../../utils/firebase'

const initialState = {
  alerts: [{}],
  widgets: [{}],
  status: 'idle',
  usersAddableWidgetsStatus: 'idle',
  usersAddableWidgets: [{}],
  setWidgetActiveStatus: 'idle',
}

export const fetchDashboard = createAsyncThunk('fetchDashboard', async () => {
  const res = await axios.get(process.env.REACT_APP_DASHBOARD_ENDPOINT, {
    headers: {
      JWT: localStorage.getItem('myrJwtToken'),
      XWT: localStorage.getItem('rutgersEduRCPID'),
      guestToken:
        localStorage.getItem('myrJwtToken') === 'F5fy27znno5G8LAJTwiMS6AYo9BYeU'
          ? localStorage.getItem('myrJwtToken')
          : 'noguestmode-ternary',
    },
  })
  return res.data
})

export const fetchUserOrder = createAsyncThunk('fetchUserOrder', async () => {
  try {
    const userOrderDocRef = doc(
      collection(firestore, 'users'),
      localStorage.getItem('user') ?? 'visitor',
      'settings',
      'dashboard',
    )
    const docSnapshot = await getDoc(userOrderDocRef)
    return docSnapshot
  } catch (error) {
    console.error(error)
    throw error
  }
})

export const setWidgetActive = createAsyncThunk(
  'setWidgetActive',
  async (widgetIds) => {
    let widgetIdsStr = ''
    for (let i = 0; i < widgetIds.length; i++) {
      if (widgetIdsStr.length > 0) {
        widgetIdsStr += `,${widgetIds[i]}`
      } else {
        widgetIdsStr += widgetIds[i]
      }
    }
    if (window.location.hostname === 'localhost') {
      const res = await axios.get(process.env.REACT_APP_USERS_ADD_A_WIDGET, {
        headers: {
          JWT: localStorage.getItem('myrJwtToken'),
          XWT: localStorage.getItem('rutgersEduRCPID'),
        },
      })
      return res.data
    }
    const res = await axios.post(
      `${process.env.REACT_APP_USERS_ADD_A_WIDGET}?addList=${widgetIdsStr}`,
      {},
      {
        headers: {
          JWT: localStorage.getItem('myrJwtToken'),
          XWT: localStorage.getItem('rutgersEduRCPID'),
        },
      },
    )
    return res.data
  },
)

export const getUsersAddableWidgets = createAsyncThunk(
  'getUsersAddableWidgets',
  async () => {
    const myrJwtToken = localStorage.getItem('myrJwtToken')
    const rcpid = localStorage.getItem('rutgersEduRCPID')
    const res = await axios.get(
      process.env.REACT_APP_USERS_ADDABLE_WIDGETS_ENDPOINT,
      {
        headers: {
          JWT: myrJwtToken,
          XWT: rcpid,
        },
      },
    )
    return res.data
  },
)

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    updateWidgets: (state, action) => {
      // firebase update
      const newOrder = action.payload
      state.widgets = newOrder
      const mapping = newOrder.map((widgetEntry, index) => ({
        ...widgetEntry,
        id: widgetEntry.id,
        manuallyMoved: widgetEntry.manuallyMoved,
        showOnDashboard: widgetEntry.showOnDashboard,
        order: index,
      }))
      const dashboardRef = doc(
        firestore,
        'users',
        localStorage.getItem('user') ?? 'visitor',
        'settings',
        'dashboard',
      )
      updateDoc(dashboardRef, {
        dashboardData: mapping,
        lastUpdate: Date.now(),
      })
    },
    setWidgetActiveLocal: (state, action) => {
      //   const targetIndex = state.widgets.findIndex(
      //     (widget) => widget.id.toString() === action.payload,
      //   )
      //   const updated = state.widgets.map((widget) => ({
      //     ...widget,
      //   }))
      //   updated[targetIndex].showOnDashboard = true
      let clonedItem = { ...action.payload }
      clonedItem = {
        ...clonedItem,
        showOnDashboard: true,
        manuallyMoved: Date.now(),
      }
      state.widgets = [clonedItem, ...state.widgets]
    },
    handleLogout: () => {
      localStorage.removeItem('user')
      localStorage.removeItem('rcpid')
      localStorage.removeItem('rutgersEduRCPID')
      localStorage.removeItem('myrJwtToken')
      localStorage.removeItem('myrSwtToken')
      localStorage.removeItem('codeResponse')
      Cookies.remove('myrJwtToken', { path: '/' })
      Cookies.remove('myrSwtToken', { path: '/' })
      Cookies.remove('rutgersEduRCPID', { path: '/' })
      auth.signOut()
      window.location.href = process.env.REACT_APP_LOGOUT_LINK
    },
  },
  extraReducers: {
    [fetchDashboard.rejected]: (state) => {
      // localStorage.removeItem('user')
      // localStorage.removeItem('rutgersEduRCPID')
      // localStorage.removeItem('myrJwtToken')
      // localStorage.removeItem('myrSwtToken')
      // Cookies.remove('myrJwtToken', { path: '/' })
      // Cookies.remove('myrSwtToken', { path: '/' })
      // Cookies.remove('rutgersEduRCPID', { path: '/' })
      // auth.signOut()
      // window.location.href = process.env.REACT_APP_LOGOUT_LINK
      state.status = 'error'
    },
    [fetchDashboard.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchDashboard.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.widgets = action.payload
    },
    [fetchUserOrder.pending]: (state) => {
      state.userOrderStatus = 'pending'
    },
    [fetchUserOrder.fulfilled]: (state, action) => {
      state.userOrderStatus = 'fulfilled'
      // eslint-disable-next-line no-nested-ternary
      state.userOrder = action.payload.data()
        ? action.payload.data().dashboardData
        : window.location.hostname === 'localhost'
        ? [
            {
              id: 624,
              manuallyMoved: false,
              showOnDashboard: false,
            },
            {
              id: 580,
              showOnDashboard: true,
              manuallyMoved: true,
            },
            {
              id: 670,
              showOnDashboard: false,
              manuallyMoved: true,
            },
            {
              showOnDashboard: true,
              manuallyMoved: true,
              id: 448,
            },
            {
              manuallyMoved: true,
              showOnDashboard: true,
              id: 540,
            },
            {
              showOnDashboard: false,
              manuallyMoved: true,
              id: 381,
            },
            {
              manuallyMoved: true,
              id: 202,
              showOnDashboard: false,
            },
            {
              id: 446,
              manuallyMoved: true,
              showOnDashboard: true,
            },
            {
              manuallyMoved: false,
              id: 3,
              showOnDashboard: true,
            },
            {
              id: 443,
              showOnDashboard: true,
              manuallyMoved: false,
            },
            {
              showOnDashboard: true,
              id: 5,
              manuallyMoved: false,
            },
            {
              manuallyMoved: false,
              showOnDashboard: true,
              id: 441,
            },
            {
              manuallyMoved: false,
              showOnDashboard: false,
              id: 440,
            },
            {
              showOnDashboard: false,
              id: 449,
              manuallyMoved: false,
            },
            {
              id: 400,
              showOnDashboard: false,
              manuallyMoved: false,
            },
            {
              showOnDashboard: false,
              id: 442,
              manuallyMoved: false,
            },
            {
              id: 480,
              manuallyMoved: false,
              showOnDashboard: false,
            },
            {
              id: 380,
              showOnDashboard: false,
              manuallyMoved: false,
            },
          ]
        : null
    },
    [getUsersAddableWidgets.pending]: (state) => {
      state.usersAddableWidgetsStatus = 'pending'
    },
    [getUsersAddableWidgets.rejected]: (state) => {
      state.usersAddableWidgetsStatus = 'error'
    },
    [getUsersAddableWidgets.fulfilled]: (state, action) => {
      state.usersAddableWidgetsStatus = 'fulfilled'
      state.usersAddableWidgets = action.payload
    },
    [setWidgetActive.pending]: (state) => {
      state.setWidgetActiveStatus = 'pending'
    },
    [setWidgetActive.rejected]: (state) => {
      state.setWidgetActiveStatus = 'error'
    },
    [setWidgetActive.fulfilled]: (state) => {
      state.setWidgetActiveStatus = 'fulfilled'
    },
  },
})

export const selectAlerts = (state) => state.dashboard.alerts
export const selectWidgets = (state) => state.dashboard.widgets
export const selectWidgetCallStatus = (state) => state.dashboard.status
export const selectUserOrderStatus = (state) => state.dashboard.userOrderStatus
export const selectUserOrder = (state) => state.dashboard.userOrder

export const { updateWidgets, setWidgetActiveLocal, handleLogout } =
  dashboardSlice.actions

export default dashboardSlice.reducer
