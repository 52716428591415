import { makeStyles, createStyles } from '@material-ui/styles'

const ForYouImageCarouselStyles = makeStyles((theme) =>
  createStyles({
    carouselBody: {
      height: '30%',
      width: '100%',
    },
    media: {
      height: '30vh',
      backgroundPosition: 'center', // Center the image
    },
    title: {
      fontSize: 18,
      fontWeight: '600',
      color: theme.palette.primary.headerText,
    },
    subheader: {
      fontSize: 12,
      color: theme.palette.secondary.headerText,
    },
    headerLeft: {
      position: 'absolute',
      top: '10%',
      left: '3%',
      color: theme.palette.base.white,
      textAlign: 'left',
      fontSize: 40,
      fontWeight: '900',
      textShadow: '0px 0px 12px black',
    },
    headerRight: {
      position: 'absolute',
      top: '10%',
      right: '4%',
      color: theme.palette.base.white,
      textAlign: 'left',
      fontSize: 40,
      fontWeight: '900',
      textShadow: '0px 0px 12px black',
    },
    learnMoreButtonLeft: {
      position: 'absolute',
      top: '75%',
      left: '3%',
      borderRadius: 8,
      color: theme.palette.base.white,
      fontWeight: '600',
      backgroundColor: theme.palette.base.red,
      '&:hover': {
        backgroundColor: theme.palette.base.redHover,
      },
    },
    learnMoreButtonRight: {
      position: 'absolute',
      top: '75%',
      right: '4%',
      borderRadius: 8,
      color: theme.palette.base.white,
      fontWeight: '600',
      backgroundColor: theme.palette.base.red,
      '&:hover': {
        backgroundColor: theme.palette.base.redHover,
      },
    },
  }),
)
export default ForYouImageCarouselStyles
