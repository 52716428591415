/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchJobsInternships = createAsyncThunk(
  'fetchJobsInternships',
  async () => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const res = await axios.get(
      process.env.REACT_APP_JOBSANDINTERNSHIPS_ENDPOINT,
      {
        headers: {
          JWT: jwtToken,
        },
      },
    )
    return res.data
  },
)

const initialState = {
  status: 'idle',
  data: {
    title: 'Jobs and Internships',
  },
}

const JobsInternshipsSlice = createSlice({
  name: 'jobsInternships',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchJobsInternships.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchJobsInternships.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchJobsInternships.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.data = action.payload
    },
  },
})

export const selectJobsInternshipData = (state) => state.jobsInternships.data
export const selectJobsInternshipStatus = (state) =>
  state.jobsInternships.status

export default JobsInternshipsSlice.reducer
