import React from 'react'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import styles from './dashboardDialogStyles'

function DashboardDialog({
  open,
  dialogTitle,
  dialogContent,
  onCloseDialog,
  closeDialog,
  dispatch,
  maxWidth,
}) {
  const classes = styles()
  const closeDialogAction = () => {
    if (onCloseDialog) {
      dispatch(onCloseDialog())
    }
    dispatch(closeDialog())
  }
  return (
    <Dialog open={open} maxWidth={maxWidth || 'sm'}>
      <DialogTitle>
        <Grid
          container
          direction="row"
          alignItems="baseline"
          justifyContent="space-between"
        >
          <Grid item container direction="column" wrap="nowrap" xs spacing={1}>
            <Grid item>{dialogTitle}</Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>{dialogContent}</DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          className={classes.dialogActionButton}
          onClick={closeDialogAction}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DashboardDialog
