import React from 'react'
import {
  Typography,
  Card,
  ButtonBase,
  ImageList,
  ImageListItem,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import {
  selectFilteredNewsActiveFilter,
  setActiveFilter,
} from '../../Pages/NewsSourcePage/NewsSourcePageSlice'

import useFilterSelectorStyles from './FilterSelectorStyles'

function FilterSelector({ filters }) {
  const classes = useFilterSelectorStyles()
  const activeFilter = useSelector(selectFilteredNewsActiveFilter)
  const dispatch = useDispatch()

  return (
    <div className={classes.root}>
      <ImageList className={classes.filterContainer}>
        {filters.map((filterName) => (
          <ImageListItem
            key={filterName}
            style={{ height: 'auto', width: 'auto', margin: '2px 2px 2x 2px' }}
          >
            <ButtonBase>
              <Card
                className={
                  activeFilter === filterName
                    ? classes.filterCardSelected
                    : classes.filterCard
                }
                onClick={() => dispatch(setActiveFilter(filterName))}
              >
                <Typography
                  className={classes.filterCardText}
                  variant="caption"
                >
                  {filterName}
                </Typography>
              </Card>
            </ButtonBase>
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  )
}

export default FilterSelector
