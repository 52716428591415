import { collection, doc, getDoc } from 'firebase/firestore'
import { firestore } from '../../../../utils/firebase'

export const fetchUserFavoritesFromFirebase = async (field) => {
  const firebaseID = localStorage.getItem('user') ?? 'visitor'
  const favoritesDocRef = doc(
    collection(firestore, 'users'),
    firebaseID,
    'settings',
    'favorites',
  )
  try {
    const docSnapshot = await getDoc(favoritesDocRef)
    const data = docSnapshot.data()
    if (docSnapshot.exists() && data && data[field]) {
      return data[field]
    }
    console.error(`The ${field} field does not exist in the document.`)
    return null
  } catch (error) {
    console.error(`Error fetching favorited ${field} list:`, error)
    return null
  }
}
