import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) => ({
  button: {
    width: 200,
    height: 40,
    backgroundColor: `${theme.palette.base.red} !important`,
    color: theme.palette.base.white,
    borderRadius: 20,
  },
  red: {
    width: 200,
    height: 40,
    backgroundColor: `${theme.palette.base.red} !important`,
    color: theme.palette.base.white,
    borderRadius: 20,
  },
  green: {
    width: 200,
    height: 40,
    backgroundColor: `${theme.palette.base.green} !important`,
    color: theme.palette.base.white,
    borderRadius: 20,
  },
  icon: {
    fontSize: 42,
  },
}))

export default styles
