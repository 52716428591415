/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import _ from 'lodash'
import {
  ROUTE,
  ROUTES,
  VEHICLE,
  SEARCH,
  FIREBASEMARKER,
} from '../../../utils/constants'

import {
  fetchStopsAsGeoJson,
  fetchTrips as fetchPassioGoTrips,
  fetchPassioGoShapesAsGeoJSON,
  fetchTripUpdates,
  fetchPassioGoRoutes,
  fetchPassioGoVehiclePositions,
  fetchNearestStopsApi,
} from '../api/api'

import { fetchFirebaseMarkersApi } from '../firebase/firebase'
import { fetchUserFavoritesFromFirebase } from '../../../firebase/firebase'

export const fetchTrips = createAsyncThunk('map/fetchTrips', async () => {
  const res = await fetchPassioGoTrips()
  return res
})

export const fetchStops = createAsyncThunk('map/fetchStops', async () => {
  const geoJsonResponse = await fetchStopsAsGeoJson()

  return {
    // stops,
    // favoritedStops: favoritedStopsObjectList,
    stopIdWithAssociatedRoutes: geoJsonResponse,
  }
})

export const fetchStopsFavorites = createAsyncThunk(
  'map/fetchStopsFavorites',
  async (x, { getState }) => {
    const state = getState()
    const { stops } = state.maps
    const data = await fetchUserFavoritesFromFirebase('stops')
    const favoritedStops = data
    const favoritedStopsObjectList = []
    if (favoritedStops?.length > 0) {
      favoritedStops.forEach((stopID) => {
        const stopObjs = stops?.filter((feature) => feature?.stop_id === stopID)
        if (stopObjs && stopObjs[0]) {
          favoritedStopsObjectList.push({
            ...stopObjs[0],
            favorited: true,
          })
        }
      })
    }
    return {
      favoritedStops: favoritedStopsObjectList,
    }
  },
)

export const fetchRoutes = createAsyncThunk('map/fetchRoutes', async () => {
  // Fetching routes, stops, and favorites
  const res = await fetchPassioGoRoutes()
  const stopsWithRoutes = await fetchStopsAsGeoJson()
  const routeIdsSet = new Set() // Populate the Set with route IDs from 'stopsWithRoutes'
  stopsWithRoutes.forEach((stop) => {
    const routeIds = stop.properties.routes.map((route) => route.route_id)
    routeIds.forEach((routeId) => routeIdsSet.add(routeId))
  }) // Filter 'res' array to only include items present in 'routeIdsSet'
  // Filter 'res' array to only include items present in 'routeIdsSet'
  const filteredRes = res.filter((route) => routeIdsSet.has(route.route_id))

  return {
    routes: filteredRes,
  }
})

export const fetchRoutesFavorites = createAsyncThunk(
  'map/fetchRoutesFavorites',
  async (y, { getState }) => {
    const state = getState()
    const { routes } = state.maps
    // separate call because of race condition
    const data = await fetchUserFavoritesFromFirebase('routes')

    const favoritedRoutes = data

    const favoritedRoutesObjectList = []
    if (favoritedRoutes?.length > 0) {
      favoritedRoutes?.forEach((routeID) => {
        const routeObj = routes?.filter(
          (routesObj) => routesObj?.route_id === routeID,
        )
        if (routeObj && routeObj[0]) {
          favoritedRoutesObjectList?.push(routeObj[0])
        }
      })
    }

    return {
      favoritedRoutes: favoritedRoutesObjectList,
    }
  },
)

// Fetch shape for a given route
export const fetchShapesAsGeoJSON = createAsyncThunk(
  'map/fetchShapesAsGeoJSON',
  async (tripId) => {
    const response = await fetchPassioGoShapesAsGeoJSON(tripId)
    return response
  },
)

export const fetchTripUpdatesCall = createAsyncThunk(
  'map/fetchTripUpdatesCall',
  async () => {
    const response = await fetchTripUpdates()
    return response
  },
)

export const fetchNearestStops = createAsyncThunk(
  'map/fetchNearestStops',
  async (buildingId) => {
    const response = await fetchNearestStopsApi(buildingId)
    return response
  },
)

export const fetchFirebaseMarkers = createAsyncThunk(
  'map/fetchFirebaseMarkers',
  async () => fetchFirebaseMarkersApi(),
)

export const fetchVehiclePositions = createAsyncThunk(
  'map/fetchVehiclePositions',
  async () => {
    const [vehicleResponse, tripResponse] = await Promise.all([
      fetchPassioGoVehiclePositions(),
      fetchTripUpdates(),
    ])

    return {
      vehicles: vehicleResponse,
      tripUpdates: tripResponse,
    }
  },
)

const initialState = {
  bottomSheet: { open: false, component: null }, // Bottom Sheet Components
  selectedStop: {}, // Current Stop
  selectedRoute: null,
  selectedVehicle: {},

  lat: 40.51868,
  lng: -74.46147,
  zoom: 14,
  stops: [],
  stopsLoading: 'idle',
  routes: [],
  persistentRoutesObject: [],
  routesLoading: 'idle',
  buses: [],

  firebaseMarkersLoading: false,
  firebaseMarkers: [],
  selectedFirebaseMarker: {},

  arrivalsForAllStopsOnRoute: {},
  activeSegmentIndex: null,
  openRoute: null,
  openMarker: null,
  openStop: null,
  openSheet: false,
  routeNames: null,
  route_id: null,
  routeArrivalsLoading: 'idle',
  routeArrivalsError: false,
  arrivals: [],
  arrivalsLoading: 'idle',
  arrivalsError: false,
  vehicles: [],
  vehiclesLoading: 'idle',
  vehiclesError: false,
  vehiclesApproachingStop: {},
  bus: null,
  busInfo: [],
  displayVehicleInfoBottomSheet: false,

  selectedCampus: 'NEW BRUNSWICK',

  searchValue: '',

  favoritedRoutes: [],
  favoritedStops: [],
  enhancedStops: [],
  selectedFavoritedRoutes: [],
  selectedFavoritedStops: [],

  selectedStops: [],
  selectedRoutes: [],

  mapSearchBottomSheetSelectedTab: 0,
  campus: 'Newark',
  routesList: [],
  showNearestStops: false,
  nearestStopsLoading: 'idle',
  nearestStops: [],
  nearestStopsTabValue: 0,
  tabValue: 0,

  showSearchBar: false,

  trips: {}, // Stores route_id to trip_id relationships
  tripUpdates: {},
  tripsLoading: 'idle',
  tripsError: '',

  arrivalsForStopsByRoute: {},
  arrivalsForStopsByRouteLoading: 'idle',
  arrivalsForStopsByRouteError: '',

  stopsOutRoute: {},

  routeShape: [],
}

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    updateBottomSheet: (state, action) => ({
      ...state,
      bottomSheet: { open: true, component: action.payload },
    }),

    setSelectedStop: (state, action) => ({
      ...state,
      selectedStop: action.payload,
    }),

    closeBottomSheet: (state, action) => ({
      ...state,
      bottomSheet: { open: false, component: action.payload },
    }),

    updateLatLng: (state, action) => ({
      ...state,
      lat: parseFloat(action.payload.lat),
      lng: parseFloat(action.payload.lng),
      zoom: action.payload.zoom,
    }),

    setLatLang: (state, action) => ({
      // Update the search bar contents
      ...state,
      lat: parseFloat(action.payload.lat),
      lng: parseFloat(action.payload.lang),
      zoom: action.payload.zoom,
    }),

    handleStopClicked: (state, action) => {
      // Initial Click That Opens Up List Of Routes
      const marker = action.payload
      return {
        ...state,
        selectedStop: marker,
        bottomSheet: { open: true, component: ROUTES },
        lat: parseFloat(marker.stop_lat),
        lng: parseFloat(marker.stop_lon),
        zoom: 18,
      }
    },

    handleFirebaseMarkerClicked: (state, action) => {
      const { location } = action.payload

      return {
        ...state,
        bottomSheet: { open: true, component: FIREBASEMARKER },
        selectedFirebaseMarker: action.payload,
        lat: parseFloat(location._lat),
        lng: parseFloat(location._long),
        zoom: 18,
      }
    },

    handleStopOnRouteClicked: (state, action) => {
      // Gets info for a stop on selected route
      const marker = action.payload

      return {
        ...state,
        selectedStop: marker,
        selectedVehicle: {},
        bottomSheet: { open: true, component: ROUTE },
        lat: parseFloat(marker.stop_lat),
        lng: parseFloat(marker.stop_lon),
        zoom: 18,
      }
    },

    handleVehicleClicked: (state, action) => {
      // Vehicle clicked. Show info

      const { position } = action.payload
      return {
        ...state,
        selectedVehicle: action.payload,
        selectedStop: {},
        bottomSheet: { open: true, component: VEHICLE },
        lat: parseFloat(position.latitude),
        lng: parseFloat(position.longitude),
        zoom: 20,
      }
    },

    // Set the selected route. Having a selectedStop is a prerequisite to this func
    setSelectedRoute: (state, action) => {
      const { busRoute, enhancedStops, openSheet } = action.payload

      return {
        ...state,
        selectedRoute: busRoute,
        stops: enhancedStops, // I don't think we need both?
        enhancedStops,
        bottomSheet: { open: openSheet, component: ROUTE },
      }
    },
    resetMap: (state) => ({
      ...state,
      vehicles: [],
      vehiclesLoading: 'idle',
      vehiclesForStop: [],
      routeShape: [],
      selectedStop: { stop_id: '' },
      selectedRoute: null,
      bottomSheet: { open: false, component: null },
      displayRoutesMobilePage: false,
      showSearchBar: false,
      zoom: 18,
    }),

    handleStopObjectClick: (state) => ({
      ...state,
      selectedStop: {
        ...state.selectedStop,
        markerClicked: !state.selectedStop.markerClicked,
      },
    }),

    handleStopClick: (state, action) => {
      let lat = null
      let lng = null
      let newSelectedStop = state.selectedStop
      if (!_.isEmpty(state.selectedStop)) {
        lat = state.selectedStop.stop_lat
        lng = state.selectedStop.stop_lon
      } else {
        lat = state.stops[0].stop_lat
        lng = state.stops[0].stop_lon
        newSelectedStop = state.stops[0]
      }
      return {
        ...state,
        lat,
        lng,
        selectedStop: newSelectedStop,
        activeRoute: action.payload,
        zoom: 18,
        // decodedCoordinates,
      }
    },
    openSearchBarToggle: (state) => ({
      ...state,
      showSearchBar: true,
      bottomSheet: { open: true, component: SEARCH },
    }),
    closeSearchBarToggle: (state) => ({
      ...state,
      showSearchBar: false,
      bottomSheet: { open: false, component: null },
    }),
    handleSearchBarToggle: (state) => {
      let sheet = { open: true, component: SEARCH }
      if (state.showSearchBar) {
        sheet = { open: false, component: null }
      } else {
        sheet = { open: true, component: SEARCH }
      }
      return {
        ...state,
        showSearchBar: !state.showSearchBar,
        bottomSheet: sheet,
      }
    },

    closeSheet: (state) => ({
      // Close Sheet
      ...state,
      openSheet: false,
    }),
    setCampus: (state, action) => ({
      // Update the search bar contents
      ...state,
      selectedCampus: action.payload,
    }),

    removeRouteFromFavorite: (state, action) => ({
      // Update the search bar contents
      ...state,
      favoritedRoutes: action.payload,
    }),
    addRouteToFavorite: (state, action) => ({
      // Update the search bar contents
      ...state,
      favoritedRoutes: action.payload,
    }),
    removeStopFromFavorite: (state, action) => ({
      // Update the search bar contents
      ...state,
      favoritedStops: action.payload,
    }),
    addStopToFavorite: (state, action) => ({
      // Update the search bar contents
      ...state,
      favoritedStops: action.payload,
    }),

    setSelectedStops: (state, action) => ({
      ...state,
      selectedStops: action.payload.filter(
        (el) => !state.selectedFavoritedStops.includes(el),
      ),
    }),
    setSelectedRoutes: (state, action) => ({
      ...state,
      selectedRoutes: action.payload.filter(
        (el) => !state.selectedFavoritedRoutes.includes(el),
      ),
    }),
    setSearchValue: (state, action) => ({
      ...state,
      searchValue: action.payload,
    }),
    setMapSearchBottomSheetSelectedTab: (state, action) => ({
      ...state,
      mapSearchBottomSheetSelectedTab: action.payload,
    }),

    setSelectedFavoritedStops: (state, action) => ({
      ...state,
      selectedFavoritedStops: action.payload,
    }),
    setSelectedFavoritedRoutes: (state, action) => ({
      ...state,
      selectedFavoritedRoutes: action.payload,
    }),

    setZoom: (state, action) => ({
      ...state,
      zoom: action.payload,
    }),

    handleNearStopsTabValue: (state, action) => ({
      ...state,
      nearestStopsTabValue: action.payload,
    }),
    setShowNearestStop: (state, action) => ({
      ...state,
      showNearestStops: action.payload,
    }),
    updateMapTabValue: (state, action) => ({
      ...state,
      tabValue: action.payload,
    }),

    resetRouteShape: (state) => ({
      ...state,
      selectedRoute: null,
      routeShape: [],
      vehiclesLoading: 'idle',
      vehiclesForStop: [],
    }),
    setTripUpdates: (state, action) => ({
      ...state,
      tripUpdates: action.payload,
    }),
  },
  extraReducers: {
    [fetchStops.pending]: (state) => ({
      ...state,
      stopsLoading: 'pending',
    }),
    [fetchStops.fulfilled]: (state, action) => {
      const { stopIdWithAssociatedRoutes } = action.payload
      //   const stopsWithRouteList = stops.map((stop) => {
      //     const stop_id = stop.stop_id

      //     // Find the matching entry in stopIdWtihAssociatedRoutes
      //     let routes = null
      //     stopIdWithAssociatedRoutes.forEach((feature) => {
      //       if (feature.properties.stop_id === stop_id) {
      //         routes = feature.properties.routes
      //       }
      //     })

      //     // Return a new object combining the original stop data and the found routes
      //     return { ...stop, routes }
      //   })

      const stopsWithRouteList = stopIdWithAssociatedRoutes.map((stop) => ({
        stop_id: stop.properties.stop_id,
        stop_code: stop.properties.stop_code,
        stop_name: stop.properties.stop_name,
        location_type: stop.properties.stop_type,
        stop_lat: stop.geometry.coordinates[1],
        stop_lon: stop.geometry.coordinates[0],
        routes: stop.properties.routes,
      }))

      return {
        ...state,
        stops: stopsWithRouteList,
        // favoritedStops: favoritedStops,
        // selectedFavoritedStops: action.payload.favoritedStops,
        // selectedFavoritedStops: favoritedStops,
        // otherstopsWithRouteList,
        error: false,
        stopsLoading: 'fulfilled',
      }
    },
    [fetchStops.rejected]: (state, action) => ({
      ...state,
      stopsLoading: 'error',
      error: action.payload,
    }),

    [fetchRoutes.pending]: (state) => ({
      ...state,
      routesLoading: 'pending',
    }),
    [fetchRoutes.fulfilled]: (state, action) => {
      // Destructure the payload to get routes and favoritedRoutes
      const { routes } = action.payload
      if (routes && routes?.length > 0) {
        routes.forEach((route) => {
          // Modify long_name if it equals 'Exam'
          if (route.long_name === 'Exam') {
            const routeName = `${route.long_name}  ${route.short_name}`
            route.long_name = routeName
          }
        })
      }

      return {
        ...state,
        routesLoading: 'fulfilled',
        routes,
        persistentRoutesObject: routes,
        error: false,
      }
    },
    [fetchRoutes.rejected]: (state, action) => ({
      ...state,
      error: action.payload,
      routesLoading: 'error',
    }),

    [fetchNearestStops.pending]: (state) => ({
      ...state,
      nearestStopsLoading: 'pending',
    }),
    [fetchNearestStops.fulfilled]: (state, action) => ({
      ...state,
      nearestStopsLoading: 'fulfilled',
      nearestStops: action.payload,
      error: false,
    }),
    [fetchNearestStops.rejected]: (state, action) => ({
      ...state,
      error: action.payload,
      nearestStopsLoading: 'error',
    }),

    [fetchFirebaseMarkers.pending]: (state) => ({
      ...state,
      firebaseMarkersLoading: 'pending',
    }),
    [fetchFirebaseMarkers.fulfilled]: (state, action) => ({
      ...state,
      firebaseMarkersLoading: 'fulfilled',
      firebaseMarkers: action.payload,
      error: false,
    }),
    [fetchFirebaseMarkers.rejected]: (state, action) => ({
      ...state,
      error: action.payload,
      firebaseMarkersLoading: 'error',
    }),

    [fetchTrips.pending]: (state) => ({
      ...state,
      tripsLoading: 'pending',
    }),
    [fetchTrips.fulfilled]: (state, action) => {
      const { trips } = action.payload
      let routeToTripMap = {}
      if (trips.length > 0) {
        routeToTripMap = trips.reduce((acc, trip) => {
          // Extracting route_id and trip_id from each trip
          const { route_id, trip_id } = trip

          // If the route_id is already a key in acc, append the trip_id to its array
          // Otherwise, create a new array for that route_id containing the trip_id
          if (acc[route_id]) {
            if (!acc[route_id].includes(trip_id)) {
              // Checking for unique trip_id
              acc[route_id].push(trip_id)
            }
          } else {
            acc[route_id] = [trip_id]
          }

          return acc
        }, {})
      }

      return {
        ...state,
        trips: routeToTripMap,
        tripsLoading: 'fulfilled',
      }
    },
    [fetchTrips.rejected]: (state, action) => ({
      ...state,
      tripsLoading: 'error',
      error: action.payload,
    }),
    [fetchShapesAsGeoJSON.pending]: (state) => ({
      ...state,
      shapesLoading: 'pending',
    }),
    [fetchShapesAsGeoJSON.pending]: (state) => ({
      ...state,
      shapesLoading: 'pending',
    }),
    [fetchShapesAsGeoJSON.fulfilled]: (state, action) => ({
      ...state,
      shapesLoading: 'fulfilled',
      routeShape: action.payload.features,
    }),
    [fetchShapesAsGeoJSON.pending]: (state) => ({
      ...state,
      shapesLoading: 'error',
    }),
    [fetchVehiclePositions.pending]: (state) => ({
      ...state,
      vehiclesLoading: 'pending',
    }),
    [fetchVehiclePositions.fulfilled]: (state, action) => {
      // Destructure relevant data from payload and state
      const { vehicles, tripUpdates } = action.payload
      const { trips, selectedRoute } = state

      // Get the relevant tripIds and convert them to strings
      const tripIds = trips[selectedRoute.route_id]

      // Initialize an array to store filtered vehicles
      const vehiclesForStop = []

      // Filter out vehicles not in the list of tripIds
      const filteredVehicles = vehicles.filter((vehicleEntity) =>
        tripIds.includes(vehicleEntity.vehicle.trip.tripId.toString()),
      )

      // Iterate through the filtered vehicles
      filteredVehicles.forEach((entityItem) => {
        const {
          stopId: entityStopId,
          trip: { tripId: entityTripId },
          timestamp: entityTimestamp,
        } = entityItem.vehicle

        // Iterate through each trip update to find matching stop and trip IDs
        tripUpdates.forEach(
          ({
            tripUpdate: {
              trip: { tripId: tripUpdateTripId },
              stopTimeUpdate,
            },
          }) => {
            if (tripUpdateTripId === entityTripId) {
              const matchingStop = stopTimeUpdate.find(
                (stop) => stop.stopId === entityStopId,
              )

              // Initialize an object to store vehicle data
              const vehicleData = {
                ...entityItem.vehicle,
                tripId: tripUpdateTripId,
                stopId: entityStopId,
              }

              // If a matching stop is found, update the arrival time; otherwise, use the entity timestamp
              vehicleData.arrivalTime = matchingStop
                ? matchingStop.arrival.time
                : entityTimestamp

              // Add to the list of vehicles for the stop
              vehiclesForStop.push(vehicleData)
            }
          },
        )
      })

      // Update the state
      return {
        ...state,
        vehiclesLoading: 'fulfilled',
        vehiclesForStop,
      }
    },

    [fetchVehiclePositions.pending]: (state) => ({
      ...state,
      shapesLoading: 'error',
    }),
    [fetchTripUpdatesCall.pending]: (state) => ({
      ...state,
      tripUpdatesLoading: 'pending',
    }),
    [fetchTripUpdatesCall.rejected]: (state) => ({
      ...state,
      tripUpdatesLoading: 'error',
    }),
    [fetchTripUpdatesCall.fulfilled]: (state, action) => ({
      ...state,
      tripUpdates: action.payload,
      tripUpdatesLoading: 'fulfilled',
    }),
    [fetchStopsFavorites.pending]: (state) => ({
      ...state,
      favoritedStopsLoading: 'pending',
    }),
    [fetchStopsFavorites.rejected]: (state) => ({
      ...state,
      favoritedStopsLoading: 'error',
    }),
    [fetchStopsFavorites.fulfilled]: (state, action) => ({
      ...state,
      favoritedStopsLoading: 'fulfilled',
      favoritedStops: action.payload.favoritedStops,
      // selectedFavoritedStops: action.payload.favoritedStops,
      selectedFavoritedStops: action.payload.favoritedStops,
    }),
    [fetchRoutesFavorites.pending]: (state) => ({
      ...state,
      favoritedRoutesLoading: 'pending',
    }),
    [fetchRoutesFavorites.rejected]: (state) => ({
      ...state,
      favoritedRoutesLoading: 'error',
    }),
    [fetchRoutesFavorites.fulfilled]: (state, action) => ({
      ...state,
      favoritedRoutesLoading: 'fulfilled',
      favoritedRoutes: action.payload.favoritedRoutes,
      selectedFavoritedRoutes: action.payload.favoritedRoutes, // check BusList component
    }),
  },
})

export const {
  updateBottomSheet,
  openSearchBarToggle,
  closeSearchBarToggle,
  closeBottomSheet,
  handleVehicleClicked,
  handleStopClicked,
  updateLatLng,
  handleStopOnRouteClicked,
  handleStopClick,
  handleFirebaseMarkerClicked,
  handleStopObjectClick,
  setLatLang,
  closeSheet,
  setCampus,
  setSelectedRoutes,
  setSelectedStops,
  setSelectedFavoritedStops,
  setSelectedFavoritedRoutes,
  setSearchValue,
  setRestaurantButtonSelected,
  handleNearStopsTabValue,
  setShowNearestStop,
  updateMapTabValue,
  handleSearchBarToggle,
  setMapSearchBottomSheetSelectedTab,
  removeRouteFromFavorite,
  addRouteToFavorite,
  addStopToFavorite,
  removeStopFromFavorite,
  setDisplayRoutesMobilePage,
  setSelectedStop,
  setSelectedRoute,
  resetMap,
  setBuildingSubheader,
  setZoom,
  resetRouteShape,
  setTripUpdates,
} = mapSlice.actions

export default mapSlice.reducer
