import { makeStyles } from '@material-ui/core/styles'

export const facultyStaffServiceStyles = makeStyles((theme) => ({
  content: {
    padding: 0,
  },
  topContainer: {
    // height: 85,
  },
  textField: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: 500,
  },
  labelText: {
    fontSize: 13,
    fontWeight: 600,
    color: theme.palette.base.white,
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 2px 0px 2px',
    borderRadius: '4px',
    '&:focus-visible': {
      outline: `1px solid ${theme.palette.button.focusBorder}`,
      boxShadow: `0 0 0 3px ${theme.palette.button.focusOutline}`,
      backgroundColor: 'rgba(255, 255, 255, 0.21)',
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.21)',
    },
  },
  tinyText: {
    fontSize: '10px',
    marginTop: '6.5px',
  },
  input: {
    color: theme.palette.base.white,
  },
  iconName: {
    color: theme.palette.base.white,
  },
  searchBar: {
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 5,
    height: '35px !important',
  },
  searchBarContainer: {
    marginLeft: 10,
    marginRight: 10,
  },
  imgContainer: {
    marginTop: 3,
    alignSelf: 'center',
  },
  img: {
    height: 35,
    width: 'auto',
  },

  tabText: {
    overflowY: 'hidden',
    display: 'flex',
    flex: '1',
    minHeight: 35,
    height: 35,
    minWidth: 30,
    width: 30,
    paddingLeft: 0,
    paddingRight: 0,
    fontSize: 13,
    fontWeight: 600,
    color: theme.palette.base.red,
    backgroundColor: theme.palette.background.primary,
  },
  tabBackground: {
    overflowY: 'hidden',
    width: '100%',
    minHeight: 35,
    height: '35px',
    backgroundColor: theme.palette.backgroundColor.secondary,
  },
  modalTabs: {
    width: 335,
    height: 35,
    [theme.breakpoints.down(370)]: {
      width: 300,
    },
  },
  announcementList: {
    width: '100%',
    height: '261px',
    paddingTop: 10,
    paddingRight: 10,
    paddingLeft: 10,
  },
  casesList: {
    width: '100%',
    height: '246px',
    overflow: 'auto',
    paddingTop: 10,
    paddingRight: 10,
    paddingLeft: 10,
  },

  bottomContainer: {
    height: '80px',
    width: '100%',
  },
  tabPanelCases: {
    height: 'auto',
    width: '100%',
    overflowY: 'hidden !important',
  },
  tabPanel1: {
    width: '100%',
    overflowY: 'auto',
  },
  grid1: {
    display: 'flex',
    justifyContent: 'space-around',
    height: '40px',
    backgroundColor: theme.palette.base.blueGray,
  },
  icon: {
    color: theme.palette.base.white,
    width: '100%',
    fontSize: 20,
    '&:hover': {
      transform: 'scale(1.07)',
    },
  },
  tabLink: {
    position: 'relative',
    bottom: 6,
    fontSize: '10px',
    color: 'white',
  },
  tabPanel2: {
    height: '261px',
    overflowY: 'hidden !important',
  },
  grid2: {
    height: '35px',
  },
  circleIcon: {
    fontSize: 70,
    color: theme.palette.base.green,
  },
  divider: {
    backgroundColor: theme.palette.base.red,
    height: '2px',
  },
  facultyStaffServiceCenterFooter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontWeight: 600,
    fontSize: '14px',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'none',
      color: theme.palette.base.blue,
    },
  },
}))

export const AnnouncementObjectStyles = makeStyles((theme) => ({
  gridItem: {
    width: '100%',
  },
  circleIcon: {
    fontSize: 70,
    color: theme.palette.base.green,
  },
  announcementCardBackground: {
    height: 'auto',
    padding: 10,
    paddingLeft: 15,
    paddingRight: 15,
    marginBottom: 15,
    width: '100%',
  },
}))

export const MyCasesObjectStyles = makeStyles((theme) => ({
  gridItem: {
    width: '100%',
  },
  caseCardBackground: {
    height: 'auto',
    padding: 15,
    paddingBottom: 10,
    marginBottom: 15,
    width: '100%',
  },
  iconButton: {
    position: 'relative',
    right: 5,
    padding: 0,
    paddingBottom: 5,
    color: theme.palette.base.darkGray,
  },
  expandOpen: {
    textAlign: 'left',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandClosed: {
    justifyContent: 'left',
    textAlign: 'left',
    transform: 'rotate(180deg)',
  },
  greenText: {
    color: theme.palette.base.green,
  },
  greenIcon: {
    color: theme.palette.base.green,
  },
  yellowText: {
    color: theme.palette.base.yellow,
  },
  yellowIcon: {
    color: theme.palette.base.yellow,
  },
}))

export const ToDoObjectStyles = makeStyles((theme) => ({
  gridItem: {
    width: '100%',
    marginTop: '-5px',
  },
  caseCardBackground: {
    height: 'auto',
    padding: 15,
    paddingBottom: 10,
    marginBottom: 15,
    width: '100%',
  },
  linkStyle: {
    marginBottom: '-5px',
  },
  greenText: {
    color: theme.palette.base.green,
  },
  greenIcon: {
    color: theme.palette.base.green,
  },
  yellowText: {
    color: theme.palette.base.yellow,
  },
  yellowIcon: {
    color: theme.palette.base.yellow,
  },
}))
