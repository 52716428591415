/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchMyBenefits = createAsyncThunk('fetchMyBenefits', async () => {
  const jwtToken = localStorage.getItem('myrJwtToken')
  const rcpid = localStorage.getItem('rcpid')
  const res = await axios.get(process.env.REACT_APP_MYBENEFITS_ENDPOINT, {
    headers: {
      JWT: jwtToken,
      XWT: rcpid,
    },
  })
  return res.data
})

const initialState = {
  status: 'idle',
  data: {
    title: 'My Benefits',
    summary: [],
    links: [],
  },
}

const myBenefitsSlice = createSlice({
  name: 'myBenefitsSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchMyBenefits.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchMyBenefits.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchMyBenefits.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.data = action.payload
    },
  },
})

export const selectMyBenefitsData = (state) => state.myBenefits.data
export const selectMyBenefitsStatus = (state) => state.myBenefits.status

export default myBenefitsSlice.reducer
