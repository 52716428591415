import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Grid } from '@material-ui/core'

import CheckIsMobile from '../../../utils/isMobileHook'
import AppTabs from './AppComponents/AppPageTabs/AppTabs'
import AppSearchBar from './AppComponents/AppSearchBar/AppSearchBar'
import ShowAppList from './AppComponents/ShowAppList/ShowAppList'

import ForYouPage from './Pages/ForYouPage/ForYouPage'
import AllAppsPage from './Pages/AllAppsPage/AllAppsPage'
import FavoritesAppPage from './Pages/FavoritesPage/FavoritesAppPage'

import AppStyles from './AppsStyles'
import ErrorPage from './Pages/Error/ErrorPage'

import {
  selectDisplaySearchItems,
  selectSearchBarAppList,
  selectMyAppsCallStatus,
  fetchMyApps,
  showSearchBarContents,
} from './appSlice'

import Loading from '../dashboard/widgets/helpers/Loading'

function MyApps() {
  const dispatch = useDispatch()
  const classes = AppStyles()
  const isMobile = CheckIsMobile()
  const myAppsCallState = useSelector(selectMyAppsCallStatus)
  React.useEffect(() => {
    const user = localStorage.getItem('user') ?? 'visitor'
    const isGuest = user === '"visitor"' || user === ''
    dispatch(fetchMyApps(isGuest))
    dispatch(showSearchBarContents(false))
  }, [])
  return (
    <Box className={classes.appsPageContainer}>
      {RenderMyAppsState(isMobile, myAppsCallState)}
    </Box>
  )
}

function RenderMyAppsState(isMobile, myAppsCallState) {
  switch (myAppsCallState) {
    case 'fulfilled':
      return <Loaded isMobile={isMobile} />
    case 'pending':
      return <Loading />
    case 'error':
      return <ErrorPage />
    default:
      return <Box />
  }
}

function Loaded({ isMobile }) {
  const classes = AppStyles()
  const searchBarAppList = useSelector(selectSearchBarAppList)
  const displaySearchItems = useSelector(selectDisplaySearchItems)

  return (
    <>
      <div className={classes.container}>
        <Grid container justifyContent="center">
          {isMobile && (
            <Grid item xs={12} className={classes.searchBar}>
              <AppSearchBar isMobile={isMobile} />
            </Grid>
          )}

          {displaySearchItems ? ( // Switch the page to only show The Searched Contents
            <>
              <Box
                style={
                  isMobile
                    ? { height: 0, width: '100%' }
                    : { height: 60, width: '100%' }
                }
              />
              <ShowAppList
                isMobile={isMobile}
                inputAppList={searchBarAppList}
              />
            </>
          ) : (
            <AppTabs
              TabOne={<FavoritesAppPage isMobile={isMobile} />}
              TabTwo={<ForYouPage isMobile={isMobile} />}
              TabThree={<AllAppsPage isMobile={isMobile} />}
            />
          )}
        </Grid>
      </div>
    </>
  )
}

export default MyApps
