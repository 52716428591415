import React from 'react'
import {
  Typography as Text,
  Card,
  CardActionArea,
  CardMedia,
  ImageList,
  ImageListItem,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectAppCategoriesWithApps } from '../../appSlice'
import { getImageFromFirebaseWithHook } from '../../helpers'
import CategoryCardSliderStyles from './CategoryCardSliderStyles'

function CategoryCardSlider({ isMobile }) {
  const classes = CategoryCardSliderStyles()
  const appCategoriesWithApps = useSelector(selectAppCategoriesWithApps)
  const history = useHistory()

  const dataList = appCategoriesWithApps.map(
    (category) =>
      category.active && (
        <CategorySliderCard
          key={category.id}
          isMobile={isMobile}
          history={history}
          id={category.id}
          category={category.name}
          categoryImage={category.image}
          appList={category.apps}
          styles={classes}
        />
      ),
  )

  return (
    <div className={classes.root}>
      <ImageList className={classes.gridList}>{dataList}</ImageList>
    </div>
  )
}

function CategorySliderCard({ isMobile, category, categoryImage, id, styles }) {
  const history = useHistory()
  const classes = styles
  const [img, setImg] = React.useState(categoryImage)

  React.useEffect(() => {
    getImageFromFirebaseWithHook(categoryImage, setImg)
  }, [])

  const handleClick = () =>
    history.push({
      pathname: isMobile ? './SeeAllAppsPageMobile' : './infoPage',
      search: isMobile ? `?id=${id}&type=slider` : `?type=Slider&id=${id}`,
    })

  return (
    <ImageListItem>
      <Card
        className={classes.card}
        style={{ height: isMobile ? 133 : 200, width: isMobile ? 200 : 300 }}
        elevation={3}
      >
        <CardActionArea onClick={() => handleClick()}>
          <Text
            className={classes.title}
            style={{ fontSize: isMobile ? 18 : 22 }}
          >
            {category}
          </Text>
          {img.includes('gs://') ? (
            <CardMedia
              className={classes.media}
              image="./assets/images/image-not-found.png"
            />
          ) : (
            <CardMedia className={classes.media} image={img} />
          )}
        </CardActionArea>
      </Card>
    </ImageListItem>
  )
}

export default CategoryCardSlider
