/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchMyCourseActivity = createAsyncThunk(
  'fetchMyCourseActivity',
  async (openTab, { getState }) => {
    const state = getState()
    const { userProfile } = state.myProfile
    const jwtToken = localStorage.getItem('myrJwtToken')
    const rcpid = localStorage.getItem('rcpid')
    const res = await axios.get(
      `${process.env.REACT_APP_MYCOURSEACTIVITY_ENDPOINT}${
        window.location.hostname === 'localhost'
          ? `ByCourse.json`
          : `${openTab}?netId=${userProfile.netId}`
      }`,
      {
        headers: {
          JWT: jwtToken,
          XWT: rcpid,
        },
      },
    )
    return res.data
  },
)

export const updateTodoTask = createAsyncThunk(
  'updateTodoTask',
  async (updateObj, { getState }) => {
    const state = getState()
    const { userProfile } = state.myProfile
    const jwtToken = localStorage.getItem('myrJwtToken')
    const rcpid = localStorage.getItem('rcpid')
    const res = await axios.get(
      `${process.env.REACT_APP_MYCOURSEACTIVITY_ACK_ENDPOINT}${
        window.location.hostname === 'localhost'
          ? ``
          : `/Todo/ack?netId=${userProfile.netId}&itemId=${updateObj.item.itemId}&ackFlag=${updateObj.value}`
      }`,
      {
        headers: {
          JWT: jwtToken,
          XWT: rcpid,
        },
      },
    )
    return res.data
  },
)

export const updateUserGradeTask = createAsyncThunk(
  'updateUserGrade',
  async (updateObj, { getState }) => {
    const state = getState()
    const { userProfile } = state.myProfile
    const jwtToken = localStorage.getItem('myrJwtToken')
    const rcpid = localStorage.getItem('rcpid')
    const res = await axios.get(
      `${process.env.REACT_APP_MYCOURSEACTIVITY_ACK_ENDPOINT}${
        window.location.hostname === 'localhost'
          ? ``
          : `/UserGrade/ack?netId=${userProfile.netId}&grdBookId=${updateObj.item.grdBookId}&ackFlag=${updateObj.item.value}`
      }`,
      {
        headers: {
          JWT: jwtToken,
          XWT: rcpid,
        },
      },
    )
    return res.data
  },
)

const initialState = {
  status: 'idle',
  ackStatus: 'idle',
  data: {
    title: 'My Course Activity',
    tabData: [],
  },
}

const myCourseActivitySlice = createSlice({
  name: 'myCourseActivityWidget',
  initialState,
  reducers: {
    updateAckFlag: (state, action) => {
      const { item } = action.payload
      // remove item from todoList or userGradeList
      if (item.grdBookId) {
        state.data.tabData = state.data.tabData.map((tabItems) => ({
          ...tabItems,
          userGradeList: tabItems.userGradeList.filter(
            (gradeItems) => gradeItems.grdBookId !== item.grdBookId,
          ),
        }))
      } else if (item.itemId) {
        state.data.tabData = state.data.tabData.map((tabItems) => ({
          ...tabItems,
          todoList: tabItems.todoList.filter(
            (todoItems) => todoItems.itemId !== item.itemId,
          ),
        }))
      }
    },
    resetAckStatus: (state) => {
      state.ackStatus = 'idle'
    },
  },
  extraReducers: {
    [fetchMyCourseActivity.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchMyCourseActivity.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchMyCourseActivity.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.data.tabData = action.payload.myCa
    },
    [updateTodoTask.rejected]: (state) => {
      state.ackStatus = 'error'
    },
    [updateTodoTask.pending]: (state) => {
      state.ackStatus = 'pending'
    },
    [updateTodoTask.fulfilled]: (state) => {
      state.ackStatus = 'fulfilled'
    },
    [updateUserGradeTask.rejected]: (state) => {
      state.ackStatus = 'error'
    },
    [updateUserGradeTask.pending]: (state) => {
      state.ackStatus = 'pending'
    },
    [updateUserGradeTask.fulfilled]: (state) => {
      state.ackStatus = 'fulfilled'
    },
  },
})

export const selectMyCourseActivityData = (state) => state.myCourseActivity.data
export const selectMyCourseActivityStatus = (state) =>
  state.myCourseActivity.status

export const { updateAckFlag, resetAckStatus } = myCourseActivitySlice.actions

export default myCourseActivitySlice.reducer
