import { makeStyles } from '@material-ui/core/styles'

// TODO Bring Back Unique Colors
export const vaccineStatusStyles = makeStyles((theme) => ({
  content: {
    padding: 0,
  },
  headerContainer: {
    width: 330,
  },
  headerText: {
    color: theme.palette.primary.headerText,
    fontWeight: 600,
  },
  divider: {
    backgroundColor: theme.palette.base.red,
  },
  vaccineListContainer: {
    width: 330,
    height: 415,
    overflowY: 'auto',
  },
  vaccineHeader: {
    color: '#006',
    fontWeight: 600,
  },
  vaccineImage: {
    width: '60%',
    height: 70,
    margin: '0 10px',
  },
  vaccineImageTwo: {
    width: '60%',
    margin: '20px auto',
  },
  vaccineContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  singleVaccineImage: {
    width: '90%',
    margin: '10px auto',
  },
  singleVaccineText: {
    color: theme.palette.primary.headerText,
    alignSelf: 'flex-start',
    fontWeight: 600,
    fontSize: 17,
    marginLeft: 10,
    marginTop: 10,
    marginBottom: 10,
  },
  singleVaccineIcon: {
    height: 100,
  },
  singleVaccineIconText: {
    alignSelf: 'center',
    color: '#006',
    fontWeight: 600,
  },
  appointmentsContainer: {
    height: 160,
    overflowY: 'auto',
  },
  fluVaccineHeaderText: {
    color: '#f6923c',
    fontWeight: 'bold',
    fontSize: 29,
    textAlign: 'center',
    marginBottom: 0,
    marginLeft: 25,
    marginRight: 25,
  },
  fluVaccineDateText: {
    color: '#f6923c',
  },
}))

export default vaccineStatusStyles
