import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { Helmet } from 'react-helmet'
import PhoneIcon from '@material-ui/icons/Phone'
import SmsIcon from '@material-ui/icons/Sms'
import EmailIcon from '@material-ui/icons/Email'
import InstagramIcon from '@material-ui/icons/Instagram'
import TwitterIcon from '@material-ui/icons/Twitter'
import FacebookIcon from '@material-ui/icons/Facebook'

import {
  fetchRUInfo,
  selectRUInfoData,
  selectRUInfoStatus,
} from './RUInfoSlice'
import styles from './RUInfoStyles'

import Widget from '../Widget'
import Loading from '../helpers/Loading'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'
// eslint-disable-next-line no-unused-vars
function RUInfo({
  className,
  title,
  showNote,
  noteMessage,
  notePosition,
  noteBackground,
  noteIcon,
  noteUrl,
  noteFontColor,
}) {
  const dispatch = useDispatch()
  const widgetData = useSelector(selectRUInfoData)
  const widgetStatus = useSelector(selectRUInfoStatus)
  const classes = styles()

  React.useEffect(() => {
    dispatch(fetchRUInfo())
  }, [])

  return (
    <Widget className={className}>
      <Widget.Header title={title} />
      <Widget.Content
        className={classes.container}
        showNote={showNote}
        noteMessage={noteMessage}
        notePosition={notePosition}
        noteBackground={noteBackground}
        noteIcon={noteIcon}
        noteUrl={noteUrl}
        noteFontColor={noteFontColor}
      >
        {renderRUInfo(widgetStatus, widgetData, classes)}
      </Widget.Content>
    </Widget>
  )
}

function renderRUInfo(widgetStatus, widgetData, classes) {
  switch (widgetStatus) {
    case 'fulfilled':
      return <Loaded widgetData={widgetData} classes={classes} />
    case 'pending':
      return <Loading />
    case 'error':
      return <ErrorMessage />
    default:
      return <ErrorMessage />
  }
}

function Loaded({ widgetData, classes }) {
  return (
    <Grid
      container
      direction="column"
      align="center"
      className={classes.fullHeight}
      wrap="nowrap"
    >
      <Grid item>
        <Card variant="outlined" className={classes.header}>
          <Typography variant="h5">{widgetData.subtitle}</Typography>
        </Card>
      </Grid>
      <Grid item className={classes.link}>
        <Button onClick={() => window.open(widgetData.website.url, '_blank')}>
          <Typography variant="h6">{widgetData.website.name}</Typography>
        </Button>
      </Grid>
      <Grid item>
        <LinkList data={widgetData.links} classes={classes} />
      </Grid>
      <Grid item className={classes.agentButtonSpacing}>
        <AgentButton />
        <div id="salesforce-chat-embed" />
      </Grid>
    </Grid>
  )
}

function LinkList({ data, classes }) {
  return (
    <Grid container direction="row" justifyContent="space-evenly">
      {data.map((button, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Grid key={index} item xs={4} className={classes.tablink}>
          <Widget.TabLink
            icon={getIcon(button.name, classes)}
            iconLabel={button.name}
            hyperLink={button.url}
          />
        </Grid>
      ))}
    </Grid>
  )
}

function getIcon(name, classes) {
  const icons = {
    Call: <PhoneIcon className={classes.icon} />,
    Text: <SmsIcon className={classes.icon} />,
    Email: <EmailIcon className={classes.icon} />,
    Instagram: <InstagramIcon className={classes.icon} />,
    Twitter: <TwitterIcon className={classes.icon} />,
    Facebook: <FacebookIcon className={classes.icon} />,
  }
  return icons[name]
}

function AgentButton() {
  return (
    <Box>
      <Helmet>
        <style type="text/css">{`.embeddedServiceHelpButton .helpButton .uiButton {
    background-color: #CC0033 !important;
    border-radius: 8px !important;
    position: relative;
    font-family: Inter,system-ui,sans-serif !important;
    font-size: 0.9rem !important;
    font-weight: 500 !important;
    margin-right: 20px !important;
    box-shadow: none !important;
    }
    .embeddedServiceHelpButton .helpButton .uiButton:focus {
    outline: 1px solid #005290 !important;
    }
    .embeddedServiceHelpButton {
      position: relative !important;
    }
    .embeddedServiceHelpButton .helpButton {
      position: relative !important;
      left: 95px;
    }
    `}</style>
      </Helmet>
    </Box>
  )
}

export default RUInfo
