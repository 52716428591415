import React, { forwardRef } from 'react'
import uuid from 'react-uuid'
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Tooltip from '@material-ui/core/Tooltip'
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus'
import useVehicleStopArrivalListStyles from '../VehicleStopArrivalListDropDown/VehicleStopArrivalListDropDownStyles'
import {
  handleStopOnRouteClicked,
  handleVehicleClicked,
} from '../../../Map/redux/myMapsSlice'
import { logMapEvent } from '../../../../utils/events'
import { STOPMARKER, VEHICLEMARKER } from '../../../../utils/constants'
import { getEnhancedStops } from '../../../Map/helpers/helpers'

const VehicleStopArrivalList = forwardRef((props, ref) => {
  const classes = useVehicleStopArrivalListStyles()
  const dispatch = useDispatch()
  const { selectedRoute, vehiclesForStop, trips } = useSelector((s) => s.maps)

  const [stopsWithTimes, setStopsWithTimes] = React.useState([])

  React.useEffect(() => {
    const fetchData = async () => {
      const routeId = selectedRoute.route_id
      const tripsIds = trips[routeId]

      // Fetch stops by route ID
      const enhancedStops = await getEnhancedStops(tripsIds, routeId)
      setStopsWithTimes(enhancedStops)
    }

    fetchData()

    // Set up the interval to call the async function every 30 seconds
    const intervalId = setInterval(fetchData, 30000) // 30000 milliseconds = 30 seconds

    // Clear interval when the component is unmounted
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const getArrivalText = (arrivals) => {
    const recommendedStopsToShow = 6
    if (!arrivals || arrivals.length === 0) {
      return 'No arrival times available' // Return default msg
    }
    // Slice the arrivals array to get up to the first 6 elements
    // then map each element to its arrivalTime property
    // and finally join these mapped strings with ', ' to form the final text
    const stopsToShow = Math.min(recommendedStopsToShow, arrivals.length)

    return arrivals
      .slice(0, stopsToShow)
      .map((arrival) => arrival.arrivalTime)
      .join(', ')
  }

  const selectVehicle = (v) => {
    if (v.arrivals.length > 0) {
      const vehicleToFind = v.arrivals[0].vehicle
      const selectedVehicle = vehiclesForStop.find(
        (vehicle) => vehicle?.vehicle?.id === vehicleToFind?.vehicle?.id,
      )
      if (selectedVehicle) {
        // const stopID = selectedVehicle.stopId
        const vehicleId = selectedVehicle.vehicle.id

        logMapEvent(dispatch, VEHICLEMARKER, vehicleId)
        dispatch(handleVehicleClicked(selectedVehicle))
      }
    }
  }

  const selectStop = (stop) => {
    if (stop) {
      dispatch(handleStopOnRouteClicked(stop))
      logMapEvent(dispatch, STOPMARKER, stop.stop_id)
    }
  }

  return (
    <List className={classes.root} component="nav" ref={ref}>
      {vehiclesForStop &&
        stopsWithTimes?.map((stop) => (
          <ListItem component="a" key={uuid()} className={classes.listItemBody}>
            <ListItemAvatar>
              <Avatar onClick={() => selectVehicle(stop)}>
                {vehiclesForStop.some(
                  (vehicle) => vehicle.stopId === stop.stop_id,
                ) && (
                  <Tooltip
                    title={`Vehicle approaching ${stop.stop_name}`}
                    aria-label="bus"
                  >
                    <DirectionsBusIcon
                      fontSize="large"
                      style={{
                        color: selectedRoute.route_color,
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                )}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              className={classes.listItemText}
              primary={stop.stop_name}
              secondary={getArrivalText(stop.arrivals)}
              onClick={() => selectStop(stop)}
            />
          </ListItem>
        ))}
    </List>
  )
})

export default VehicleStopArrivalList
