/* eslint-disable no-underscore-dangle */
import React from 'react'
import { OverlayView } from '@react-google-maps/api'
import uuid from 'react-uuid'
import { useDispatch, useSelector } from 'react-redux'
import IconPicker from '../../../../../apps/AppComponents/IconPicker/IconPicker'
import { updateBottomSheet, updateMapTabValue } from '../../redux/myMapsSlice'
import { handleViewBuildingInfoSheet } from '../../../Buildings/redux/myBuildingsSlice'
import CheckIsMobile from '../../../../../../../utils/isMobileHook'
import { BUILDING, CAMDENENGAGEBUILDING } from '../../../../utils/constants'

function BuildingMarker(props) {
  const { building } = props
  const { component } = useSelector((s) => s.maps.bottomSheet)
  const dispatch = useDispatch()
  const isMobile = CheckIsMobile()

  const handleClick = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const venueWebsite = urlParams.get('venueWebsite')
    // maps -> bottomSheet -> component === "CAMDENENGAGENBUILDING"
    if (component === CAMDENENGAGEBUILDING) {
      window.open(venueWebsite, '_blank')
    } else if (isMobile) {
      dispatch(updateMapTabValue(1))
    } else {
      dispatch(handleViewBuildingInfoSheet(building.bid))
      dispatch(updateBottomSheet(BUILDING))
    }
  }

  return (
    <button
      type="button"
      aria-label="Building Marker"
      onClick={() => handleClick()}
    >
      <OverlayView
        key={uuid()}
        position={{
          lat: building.lat,
          lng: building.lng,
        }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <IconPicker iconName="fas fa-building" color="#00643c" size="2x" />
      </OverlayView>
    </button>
  )
}

export default BuildingMarker
