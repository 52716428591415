/* eslint-disable no-unused-vars */
/* eslint-disable no-else-return */
import * as React from 'react'
import uuid from 'react-uuid'
import { useSelector } from 'react-redux'
import { Grid, Typography as Text, Box } from '@material-ui/core'
import ForYouImageCarousel from './ForYouComponents/ForYouImageCarousel/ForYouImageCarousel'
import TopBannerCard from './ForYouComponents/TopBannerCard/TopBannerCard'
import BottomBannerCard from './ForYouComponents/BottomBannerCard/BottomBannerCard'
import { selectForYouApps, selectIsGuest } from '../../appSlice'
import SetNumOfAppAltPerRow from '../../AppComponents/ShowAppList/NumOfAppAltPerRowHook'
import AppAlt from '../../AppComponents/AppAlt/AppAlt'
import ForYouPageStyles from './ForYouPagesStyles'
import AppCarouselCombined from '../../AppComponents/AppCarouselCombined/AppCarouselCombined'
import SetCarouselDimensions from '../../AppComponents/AppCarouselCombined/AppCarouselDimensions'
import { generateRandString } from '../../helpers'

/**
 * For You Page
 * Displays For You Content for web and mobile view
 */
function ForYouPage({ isMobile }) {
  const classes = ForYouPageStyles()
  const numAppsAltPerRow = SetNumOfAppAltPerRow()
  const dimensions = SetCarouselDimensions()
  const forYouApps = useSelector((s) => s.myApps.forYouData)
  const [popularAppsList, setPopularAppsList] = React.useState([])
  const [promoWithImgList, setPromoWithImgList] = React.useState([])
  const [promoWithOutImgList, setPromoWithOutImgList] = React.useState([])

  const isGuest = useSelector(selectIsGuest)

  React.useEffect(() => {
    const promoWithImg = []
    const promoWithOutImg = []
    forYouApps?.forEach((recApps) =>
      recApps?.fragmentList?.forEach((rec) => {
        // Sort into respective categories
        if (rec.imageUrl !== null) {
          // if (rec.promo) {
          promoWithImg.push(rec)
        } else {
          promoWithOutImg.push(rec)
        }
        // }
      }),
    )
    setPromoWithImgList(promoWithImg)
    setPromoWithOutImgList(promoWithOutImg)
    createPopularAppsList() // Parse all apps to find most popular
  }, [])

  // Create the most popular apps list
  React.useEffect(() => {
    createPopularAppsList()
  }, [numAppsAltPerRow])

  const selectRandomCard = (promo) => (
    <BottomBannerCard
      key={`${promo.categoryName}BottomBannerCard`}
      isMobile={isMobile}
      title={promo.categoryName}
      img={
        promo.imageUrl ? promo.imageUrl : '/PHOTO-calculator-pen-2-scaled.jpeg'
      }
    />
  )

  const findMostPopularAppsFromPromoWithoutImgList = () => {
    const allForYouApps = forYouApps?.map((recApps) =>
      recApps.fragmentList
        ?.map((rec) => {
          if (recApps.renderType !== 'appList') {
            return rec?.associatedApps?.map((item) =>
              item.apps?.map((arrayAsc) => arrayAsc),
            )
          } else {
            return rec?.apps?.map((item) =>
              item.associatedApps?.map((arrayAsc) => arrayAsc),
            )
          }
        })
        .filter((app) => app.active === true),
    )
    const sortedForYouAppsWithoutDuplicates = Array.from(
      new Set(allForYouApps?.map(JSON.stringify)),
    ).map(JSON.parse)
    const sortedForYouApps = sortedForYouAppsWithoutDuplicates.sort(
      (firstItem, secondItem) => secondItem.clickThru - firstItem.clickThru,
    )
    return sortedForYouApps.slice(0, numAppsAltPerRow)
  }

  const createPopularAppsList = () => {
    const popularApps = findMostPopularAppsFromPromoWithoutImgList()
    const popularAppsListComponent = (
      <>
        <Text className={classes.rowHeaderText}>Most Popular Apps For You</Text>
        <Grid container justifyContent="space-evenly" style={{ marginTop: 8 }}>
          {popularApps.map((app) => (
            <Grid
              item
              style={{ marginBottom: 8 }}
              key={app.title + generateRandString()}
            >
              <AppAlt
                key={app.title}
                app={app}
                isGuest={isGuest}
                isMobile={isMobile}
              />
            </Grid>
          ))}
        </Grid>
      </>
    )
    setPopularAppsList(popularAppsListComponent)
  }

  const renderForYouSection = () => {
    const appsFragmentation = forYouApps
      ?.map((forYou) => {
        if (forYou.renderType === 'jumbotron') {
          return (
            <ForYouImageCarousel
              key={uuid()}
              isMobile={isMobile}
              contentList={forYou.fragmentList}
              classes={classes}
              isGuest={isGuest}
            />
          )
        } else if (forYou.renderType === 'fragmentCard') {
          return (
            <CreatePromoCardsWithImg
              key={uuid()}
              forYou={forYou.fragmentList}
              isGuest={isGuest}
              isMobile={isMobile}
              dimensions={dimensions}
              selectRandomCard={selectRandomCard}
              classes={classes}
            />
          )
        } else if (forYou.renderType === 'appList') {
          return (
            <CreateAppCarousel
              key={uuid()}
              forYou={forYou.fragmentList}
              isGuest={isGuest}
              isMobile={isMobile}
              dimensions={dimensions}
              selectRandomCard={selectRandomCard}
              classes={classes}
            />
          )
        }
        return null
      })
      .filter((apps) => apps !== null)
    console.log('appsFragmentation ', appsFragmentation)
    return appsFragmentation
  }

  return (
    <div className={classes.forYouPageContainer}>{renderForYouSection()}</div>
  )
}

// Render the Promo Cards With Images
function CreatePromoCardsWithImg({
  forYou,
  isMobile,
  classes,
  selectRandomCard,
}) {
  return isMobile ? (
    forYou.map((appsList) => (
      <Box key={appsList.categoryName} marginRight={2}>
        <Box item key={appsList.categoryName}>
          {selectRandomCard(appsList)}
        </Box>
      </Box>
    ))
  ) : (
    <>
      <Grid
        container
        justifyContent="flex-start"
        style={{ marginLeft: '3%', marginRight: '3%' }}
      >
        {forYou.map((appsList) => (
          <Box key={appsList.categoryName} marginRight={2}>
            <Grid item>{selectRandomCard(appsList)}</Grid>
          </Box>
        ))}
      </Grid>
    </>
  )
}

// Render the App Carousel Items
function CreateAppCarousel({ forYou, isMobile, isGuest, dimensions }) {
  return forYou?.map((appList) => {
    if (appList.apps?.length <= 0) {
      return null
    }
    return (
      <AppCarouselCombined
        key={appList.categoryName}
        appList={appList.apps}
        category={appList.categoryName}
        carouselType="forYou"
        dimensions={dimensions}
        id={appList.categoryName}
        isGuest={isGuest}
        isMobile={isMobile}
      />
    )
  })
}

export default ForYouPage
