/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

/*
    This file contains the redux tool kit code for the state that the DidYouKnow component uses, the call that is made to obtain data and selector functions that we can call inside of the DidYouKnow component to get access to our state.
*/

// All functions in redux that need to make a call out to a resource need to be created using createAsyncThunk. Reference: https://redux-toolkit.js.org/api/createAsyncThunk -  The thunk action creator function will have plain action creators for the pending, fulfilled, and rejected cases attached as nested fields. You will see that the extraReducers object contains action creators for fetchDidYouKnow.fulfilled, fetchDidYouKnow.pending, and fetchDidYouKnow.rejected. Inside all of these action creators we are updating the state object accordingly.
export const fetchPolicyItems = createAsyncThunk(
  'fetchPolicyItems',
  async () => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const xwtToken = localStorage.getItem('rcpid')
    const res = await axios.get(process.env.REACT_APP_POLICY_ENDPOINT, {
      params: {
        FieldIds: 798,
      },
      headers: {
        JWT: jwtToken,
        XWT: xwtToken,
      },
    })
    return res.data
  },
)
// DATA: isNew: renders in Announcement tab
// isUpdated: renders in Policy items
// This is the initial state object the reducers and extraReducers will change based on whatever we need to change it to. It is required when creating the createSlice object.
const initialState = {
  status: 'idle',
  data: {
    title: 'Rutgers Policy Library',
    items: [],
  },
}

const policySlice = createSlice({
  name: 'policyWidget',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchPolicyItems.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchPolicyItems.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchPolicyItems.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.data.items = action.payload
    },
  },
})
// List of selector functions that can be used to access state inside of the Policy.js Component.
export const selectPolicyData = (state) => state.policy.data.items
export const selectPolicytatus = (state) => state.policy.status

export default policySlice.reducer
