/**
 * Maps an individual route object to the desired schema.
 * @param {Object} route - The route object to map.
 * @returns {Object} - Route object mapped to the schema.
 */
function mapRouteToSchema(route) {
  return {
    key: route.route_id,
    route_id: route.route_id,
    route_color: `#${route.route_color}`,
    route_desc: route.route_desc,
    route_long_name: route.route_long_name,
    route_short_name: route.route_short_name,
    route_sort_order: route.route_sort_order,
    route_text_color: `#${route.route_text_color}`,
    route_type: route.route_type,
    route_url: route.route_url,
    agency_id: route.agency_id,
    continuous_drop_off: route.continuous_drop_off,
    continuous_pickup: route.continuous_pickup,
    network_id: route.network_id,
  }
}

/**
 * Processes the response, mapping each route in the list to the schema and
 * replacing the original list with the mapped list.
 * @param {Object} response - The API response object.
 * @returns {Object} - Processed response object.
 */
export default function processRoutesResponse(res) {
  const mappedRoutes = res.data.map(mapRouteToSchema) // Assuming "routesList" is the field with the list in the response. Adjust as needed.
  // Replacing the original list with the mapped list
  res.data = mappedRoutes
  return res
}
