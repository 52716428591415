/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { collection, doc, getDoc, runTransaction } from 'firebase/firestore'
import { isEqual } from 'lodash'
// import axios from 'axios'
import { firestore } from '../../../utils/firebase'

// When isDev = true, use the test document
const isDev = true
const docName =
  window.location.hostname === 'localhost' ? 'dashboardTest' : 'dashboard'

const initialState = {
  widgets: [{}],
  status: 'idle',
  reorderDialogOpen: false,
  editDialogOpen: false,
  addWidgetDialog: false,
  focusedWidget: null,
  alertObj: {
    showAlert: false,
    message: '',
  },
  activeWidgetIds: [],
}

// export const fetchDefaultWidgets = createAsyncThunk(
//   'fetchDashboard',
//   async (userRole = '') => {
//     const res = await axios.get(
//       `${process.env.REACT_APP_ADMIN_DEFAULT_WIDGETS}/?role=${userRole}`,
//       {
//         headers: {
//           JWT: localStorage.getItem('myrJwtToken'),
//           XWT: localStorage.getItem('rutgersEduRCPID'),
//         },
//       },
//     )
//     return res.data
//   },
// )

// const adminWidgetDocument = async () => {
//   firestore
//     .collection('DefaultWidgetOrder')
//     .doc('defaultOrder')
//     .collection('settings')
//     .doc(docName)
// }

const defaultWidgetOrderRef = doc(
  collection(firestore, 'DefaultWidgetOrder'),
  'defaultOrder',
)
const adminWidgetDocument = doc(
  collection(defaultWidgetOrderRef, 'settings'),
  docName,
)

export const fetchDefaultWidgets = createAsyncThunk(
  'fetchDefaultWidgets',
  async (userRole = '') => {
    const doc = await getDoc(adminWidgetDocument)
    if (doc.exists()) {
      return doc.data()
    }
  },
)

export const refetchDefaultWidgets = async (userRole = '') => {
  const doc = await getDoc(adminWidgetDocument)
  if (doc.exists()) {
    return doc.data()
  }
}

export function widgetsAreEqual(currentWidgets, newWidgets) {
  if (newWidgets.length !== currentWidgets.length) {
    // The lists have different lengths, so the widgets are not identical
    return false
  } else {
    for (let i = 0; i < newWidgets.length; i++) {
      if (!compareWidget(newWidgets[i], currentWidgets[i])) {
        // The widgets are different, so update the store with the new widgets
        return false
      }
    }
    return true
  }
}

function compareWidget(widget1, widget2) {
  // Check if the widgets have the same number of properties
  if (Object.keys(widget1).length !== Object.keys(widget2).length) {
    console.log('Keys are different length')
    return false
  }

  // Iterate over each property in the widgets and compare their values
  for (const prop in widget1) {
    if (widget1.hasOwnProperty(prop) && widget2.hasOwnProperty(prop)) {
      if (Array.isArray(widget1[prop]) && Array.isArray(widget2[prop])) {
        // Compare the arrays
        if (widget1[prop].length !== widget2[prop].length) {
          return false
        }
        for (let i = 0; i < widget1[prop].length; i++) {
          if (widget1[prop][i] !== widget2[prop][i]) {
            return false
          }
        }
      } else if (widget1[prop] !== widget2[prop]) {
        // Compare the values
        return false
      }
    }
  }
  return true
}

const updateWidgetsInFireStore = async (dashboardData) => {
  try {
    await runTransaction(firestore, async (transaction) => {
      const sfDoc = await transaction.get(adminWidgetDocument)
      if (!sfDoc.exists()) {
        throw 'Document does not exist!'
      }
      console.log('document data ', sfDoc.data())
      transaction.update(adminWidgetDocument, {
        dashboardData: dashboardData,
        lastUpdate: Date.now(),
      })
    })
    console.log('Transaction successfully committed!')
  } catch (e) {
    console.log('Transaction failed: ', e)
  }
}

export const adminPageSlice = createSlice({
  name: 'adminPage',
  initialState,
  reducers: {
    updateWidgets: (state, action) => {
      // firebase update
      const newOrder = action.payload
      state.widgets = newOrder
      //   console.log('widget order before changing the orderId ', state.widgets)
      state.widgets.forEach((widgetOrder, index) => {
        widgetOrder.manuallyMoved = widgetOrder.manuallyMoved
          ? widgetOrder.manuallyMoved
          : Date.now()
        widgetOrder.order = parseInt(index, 10)
        return {
          ...widgetOrder,
        }
      })
      updateWidgetsInFireStore(state.widgets)
    },
    updateActiveWidgetIds: (state, action) => {
      if (!state.activeWidgetIds.includes(action.payload)) {
        state.activeWidgetIds.push(action.payload)
      }
    },
    addEditRemoveWidgetsInFireStore: (state, action) => {
      state.widgets = action.payload
      updateWidgetsInFireStore(action.payload)
    },
    setReorderDialog: (state, action) => {
      state.reorderDialogOpen = action.payload
    },
    setEditDialog: (state, action) => {
      state.editDialogOpen = action.payload
    },
    setAddWidgetDialog: (state, action) => {
      state.addWidgetDialog = action.payload
    },
    setFocusedWidget: (state, action) => {
      state.focusedWidget = action.payload
    },
    updateAlert: (state, action) => {
      state.alertObj = action.payload
    },
    setWidgets: (state, action) => {
      state.widgets = action.payload
    },
  },
  extraReducers: {
    [fetchDefaultWidgets.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchDefaultWidgets.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.widgets = action.payload.dashboardData.sort(
        (a, b) => a.order - b.order,
      )
    },
    [fetchDefaultWidgets.rejected]: (state) => {
      state.status = 'error'
    },
  },
})

export const {
  updateWidgets,
  updateActiveWidgetIds,
  addWidgetToFireBase,
  setReorderDialog,
  setEditDialog,
  setAddWidgetDialog,
  setFocusedWidget,
  addEditRemoveWidgetsInFireStore,
  updateAlert,
  setWidgets,
} = adminPageSlice.actions

export default adminPageSlice.reducer
