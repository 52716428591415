/* eslint-disable no-param-reassign */
import React from 'react'
import { getStorage, ref, getDownloadURL } from 'firebase/storage'
import { doc, collection, getDoc, updateDoc } from 'firebase/firestore'
import { Grid } from '@material-ui/core'
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import IconPicker from './AppComponents/IconPicker/IconPicker'
import { createMyAppsData, updateFavoritedIndices } from './appSlice' // eslint-disable-line import/no-cycle
import CustomDotGroup from './AppComponents/AppCarouselCombined/AppCarouselDots'
import { firestore } from '../../../utils/firebase'
import 'pure-react-carousel/dist/react-carousel.es.css'

const storage = getStorage()
// const firestore = getFirestore()

export const createIcon = (iconName, size) => (
  <IconPicker iconName={iconName} color="white" size={size} />
)

export const createAppCategoriesWithAppsObject = (state) => {
  const appsList = []
  const tempSortedAppsList = {}

  // Organize apps into respective category key
  state.apps.forEach((app) => {
    const appCategory = app.category
    if (appCategory in tempSortedAppsList) {
      tempSortedAppsList[appCategory].push(app)
    } else {
      tempSortedAppsList[appCategory] = [app]
    }
  })

  // Replace category key with category name and convert gs link to https link
  state.appCategories.forEach((category) => {
    // Creating the sortedApps list
    const categoryName = category.name
    const categoryID = category.id
    const dataObj = {
      id: category.id,
      name: categoryName,
      active: category.active,
      descr: category.descr,
      image: category.image,
      order: category.order,
      apps: tempSortedAppsList[categoryID],
    }
    appsList.push(dataObj)
  })

  return appsList.sort((a, b) => (a.name > b.name ? 1 : -1))
}

export const createImageCarousel = (classes, images, setImageCarousel) => {
  const imgCarousel = (
    <Grid container className={classes.categoryCardSliderContainer}>
      <CarouselProvider
        naturalSlideWidth={1}
        naturalSlideHeight={1}
        totalSlides={images.length}
        className={classes.carouselProvider}
        interval="5000"
        isPlaying="true"
        playDirection="forward"
      >
        <Slider className={classes.sliderContainer}>
          {images.map((img) => (
            <Slide key={img}>
              <div className={classes.imageStyle}>
                <img className={classes.imgStyles} alt="temp" src={img} />
              </div>
            </Slide>
          ))}
        </Slider>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <ButtonBack className={classes.buttonStyle}>
              <ArrowBackIosIcon className={classes.backArrow} />
            </ButtonBack>
          </Grid>

          <Grid item className={classes.dotGroup}>
            <div className={classes.customDotGroupContainer}>
              <CustomDotGroup slides={images.length} />
            </div>
          </Grid>

          <Grid item>
            <ButtonNext className={classes.buttonStyle}>
              <ArrowForwardIosIcon className={classes.forwardArrow} />
            </ButtonNext>
          </Grid>
        </Grid>
      </CarouselProvider>
    </Grid>
  )
  setImageCarousel(imgCarousel)
}

export const createForYouDataAppsList = (state) => {
  // ===== Get Tags From For You And Create Object To Keep Track ===== //
  const { forYouData } = state
  forYouData?.forEach((singleApp) => {
    singleApp.fragmentList?.forEach((fragment) => {
      if (singleApp.renderType === 'appList') {
        fragment.apps = setFavoritedApps(
          state.myFavoriteAppsList,
          fragment.apps,
        )
      } else {
        fragment.associatedApps?.forEach((associatedApp) => {
          associatedApp.apps = setFavoritedApps(
            state.myFavoriteAppsList,
            associatedApp.apps,
          )
        })
      }
    })
  })

  return forYouData
}

export const convertGSImgList = async (imgList) => {
  const convertedImages = []
  for (let i = 0; i < imgList.length; i++) {
    const convertedImage = await getImageFromFirebaseWithoutHook(imgList[i]) // eslint-disable-line no-await-in-loop
    convertedImages.push(convertedImage)
  }
  return convertedImages
}

export const fetchFavoriteAppsList = async (firebaseRCPID) => {
  try {
    const favoritesDocRef = doc(
      collection(firestore, 'users', firebaseRCPID, 'settings'),
      'favorites',
    )
    const docSnapshot = await getDoc(favoritesDocRef)
    return docSnapshot
  } catch (error) {
    console.error(error)
    return null
  }
}

export const getImageFromFirebaseWithHook = async (imageUrl, setImage) => {
  if (imageUrl) {
    imageUrl = imageUrl?.includes(
      `gs://${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
    )
      ? imageUrl
      : `gs://${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}${imageUrl}`
  }
  try {
    const imageRef = ref(storage, imageUrl)
    const downloadURL = await getDownloadURL(imageRef)
    setImage(downloadURL)
  } catch (error) {
    console.error(error)
    setImage(
      `${process.env.REACT_APP_IMAGES_URL}/assets/images/image-not-found.png`,
    )
  }
}

export const getImageFromFirebaseWithoutHook = async (imageUrl) => {
  if (imageUrl) {
    imageUrl = imageUrl?.includes(
      `gs://${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
    )
      ? imageUrl
      : `gs://${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}${imageUrl}`
  }
  try {
    const imageRef = ref(storage, imageUrl)
    const downloadURL = await getDownloadURL(imageRef)
    return downloadURL
  } catch (error) {
    console.error(error)
    return './assets/images/image-not-found.png'
  }
}

// Set app as favorites
export const selectFavoritedAppAndUpdate = (dispatch, id) => {
  dispatch(
    updateFavoritedIndices({
      appID: parseInt(id, 10),
    }),
  )
  dispatch(createMyAppsData())
}

// Take the app list and update the isFavorited contents
export const setFavoritedApps = (favoritesAppList = [], appList) => {
  const newAppList = []
  const favoritesDataMap = {} // Change the array to dictionary. Quicker access later

  // Store favorited items to dictionary
  favoritesAppList.forEach((item) => {
    if (!(item in favoritesDataMap)) {
      favoritesDataMap[item] = null
    }
  })
  // Go through apps and set the isFavorited field
  appList.forEach((app) => {
    const appCopy = JSON.parse(JSON.stringify(app))
    if (app.id in favoritesDataMap) {
      appCopy.isFavorited = true
    } else {
      appCopy.isFavorited = false
    }
    newAppList.push(appCopy)
  })

  return newAppList
}

export const updateFirebaseWithNewFavoritesList = (state, action) => {
  const { appID } = action.payload
  const appList = [...state.apps]
  let newFavoritesList = []
  // Check whether we want to add or remove app from favorites and create list
  if (state.myFavoriteAppsList.includes(appID)) {
    newFavoritesList = state.myFavoriteAppsList.filter((id) => id !== appID)
  } else {
    newFavoritesList = [appID].concat(state.myFavoriteAppsList)
  }
  storeFavoritesListToFirebase(newFavoritesList)
  // Set favoritedAppsList to
  state.myFavoriteAppsList = newFavoritesList
  state.apps = setFavoritedApps(newFavoritesList, appList)
  return state
}

export const storeFavoritesListToFirebase = (newFavoritesList) => {
  // Store this new list to Firebase
  const firebaseID = localStorage.getItem('rutgersEduRCPID')
  const favoritesDocRef = doc(
    collection(firestore, 'users', firebaseID, 'settings'),
    'favorites',
  )
  updateDoc(favoritesDocRef, { apps: newFavoritesList })
    .then(() => {
      console.log('Favorites list updated successfully')
    })
    .catch((error) => {
      console.error('Error updating favorites list:', error)
    })
}

export const generateRandString = () =>
  Math.random().toString(16).substring(2, 8)
