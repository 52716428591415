import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/core/styles'
import AppTabStyles from './AppTabsStyles'
import {
  updateReorderFavoriteAppsFlag,
  selectReorderFavoriteApps,
  selectCurrentTab,
  changeCurrentTab,
} from '../../appSlice'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Typography component="span">{children}</Typography>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

export default function AppTabs({ TabOne, TabTwo, TabThree }) {
  const classes = AppTabStyles()
  const theme = useTheme()
  const dispatch = useDispatch()
  const currentTab = useSelector(selectCurrentTab)
  const reorderFavoritesAppList = useSelector(selectReorderFavoriteApps)
  const history = useHistory()
  // eslint-disable-next-line no-restricted-globals
  const myAppsTab = new URLSearchParams(location.search).get('myAppsTab')
  const chooseDefaultTab = () => {
    if (myAppsTab) {
      let currentTabValue = 0
      switch (myAppsTab) {
        case 'myFavorites':
          currentTabValue = 0
          break
        case 'forYou':
          currentTabValue = 1
          break
        case 'allApps':
          currentTabValue = 2
          break
        default:
          currentTabValue = 2
      }
      dispatch(changeCurrentTab(currentTabValue))
    }
  }
  // If the favorites reorder button is clicked while on 1st or 2nd tab, route to 0th tab
  React.useEffect(() => {
    if (currentTab !== 0) {
      if (reorderFavoritesAppList) {
        dispatch(changeCurrentTab(0))
      }
    }
  }, [reorderFavoritesAppList])
  React.useEffect(() => {
    chooseDefaultTab()
  }, [])

  React.useEffect(() => {
    chooseDefaultTab()
  }, [myAppsTab])

  // Check if we are on favorites tab. If not, turn off the reorder favorites flag
  const checkIfFavoritesTab = (tabVal) => {
    if (tabVal !== 0) {
      dispatch(updateReorderFavoriteAppsFlag(false))
    }
  }

  const handleChange = (action, newValue) => {
    history.push({ search: `?myAppsTab=${action.currentTarget.name}` })
    dispatch(changeCurrentTab(newValue))
    checkIfFavoritesTab(newValue)
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={currentTab}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
          variant="fullWidth"
        >
          <Tab label="Favorites" name="myFavorites" {...a11yProps(0)} />
          <Tab label="For You" name="forYou" {...a11yProps(1)} />
          <Tab label="All Apps" name="allApps" {...a11yProps(2)} />
        </Tabs>
      </AppBar>

      <TabPanel value={currentTab} index={0} dir={theme.direction}>
        {TabOne}
      </TabPanel>

      <TabPanel value={currentTab} index={1} dir={theme.direction}>
        {TabTwo}
      </TabPanel>

      <TabPanel value={currentTab} index={2} dir={theme.direction}>
        {TabThree}
      </TabPanel>
    </div>
  )
}
