import { makeStyles } from '@material-ui/core/styles'

export const MyExpensesStyles = makeStyles((theme) => ({
  checkCircleIcon: {
    color: theme.palette.base.green,
  },
  expenseListContainer: {
    borderRadius: '4px',
    paddingTop: 10,
    paddingBottom: 50,
    width: 335,
    height: 430,
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.breakpoints.down(370)]: {
      width: 287,
      height: 450,
    },
  },
  expenseListRecordsContainer: {
    height: 475,
    margin: 0,
  },
  expenseObject: {
    width: '100%',
    height: 70,
    marginBottom: 9,
    marginLeft: 6,
    marginRight: 6,
    backgroundColor: theme.palette.backgroundColor.ternary,
    [theme.breakpoints.down(370)]: {
      width: 270,
    },
  },
  expenseObjectLink: {
    width: '100%',
    height: '100%',
    display: 'block',
    '&:focus': {
      border: `2px solid #000`,
      filter: 'brightness(98%)',
      backgroundColor: theme.palette.background.widgetSubItemBackground,
    },
    '&:hover': {
      filter: 'brightness(98%)',
      backgroundColor: theme.palette.background.widgetSubItemBackground,
    },
  },
  expenseManagementButton: {
    color: theme.palette.base.white,
    backgroundColor: theme.palette.base.green,
    margin: '0 auto',
    marginBottom: 8,
    '&:hover': {
      backgroundColor: theme.palette.base.greenHover,
    },
  },
  paidColor: {
    color: theme.palette.base.green,
  },
  removeCircleIcon: {
    color: theme.palette.base.red,
  },
  unpaidColor: {
    color: theme.palette.base.red,
  },
}))

export default MyExpensesStyles
