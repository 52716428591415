import React from 'react'
import { Typography, Box } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import RoomIcon from '@material-ui/icons/Room'
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { bottomSheetVehicleInfoStyles } from './bottomSheetVehicleInfoStyles'
import {
  getArrivalTime,
  getTextColorForRouteItem,
} from '../../../sections/Map/helpers/helpers'
import { handleStopOnRouteClicked } from '../../../sections/Map/redux/myMapsSlice'

function BottomSheetVehicleInfo({ setSheetConfig }) {
  const classes = bottomSheetVehicleInfoStyles()
  const dispatch = useDispatch()
  const { stops, selectedRoute, selectedVehicle } = useSelector((s) => s.maps)
  const [estimatedArrival, setEstimatedArrival] = React.useState('')
  const [nextStop, setNextStop] = React.useState('')
  const [busoccupancy, setBusOccupancy] = React.useState('')
  const [occupancyIcon, setOccupancyIcon] = React.useState()

  React.useEffect(() => {
    if (!_.isEmpty(selectedVehicle)) {
      extractVehicleInfo()
      const sheetConfig = {
        title: `Vehicle #${selectedVehicle.vehicle.label}`,
        sheetColor: selectedRoute ? selectedRoute.route_color : '#cc0033',
        displayCloseButton: true,
        displayBackButton: false,
        headingColor: getTextColorForRouteItem(selectedRoute),
      }
      setSheetConfig(sheetConfig)

      switch (selectedVehicle.occupancyStatus) {
        case 'STANDING_ROOM_ONLY':
          setOccupancyIcon(<EmojiPeopleIcon className={classes.busIcon} />)
          break
        case 'FEW_SEATS_AVAILABLE':
          setOccupancyIcon(
            <FontAwesomeIcon
              icon="fas fa-user-friends"
              className={classes.busIcon}
            />,
          )
          break
        case 'MANY_SEATS_AVAILABLE':
          setOccupancyIcon(
            <FontAwesomeIcon icon="fas fa-users" className={classes.busIcon} />,
          )
          break
        default:
          setOccupancyIcon(
            <FontAwesomeIcon
              icon="fas fa-user-slash"
              className={classes.busIcon}
            />,
          )
      }
    }
  }, [selectedVehicle])

  const extractVehicleInfo = () => {
    getArrivalVal(selectedVehicle.arrivalTime)
    getNextStop(selectedVehicle.stopId)
    getOccupancy(selectedVehicle.occupancyStatus)
    // getCapacity(selectedVehicle.passenger_load)
  }

  // const getCapacity = (load) => {
  //   const capacityRaw =
  //     load === 0 ? 0 : Math.ceil(selectedVehicle.passenger_load * 100)
  //   setCapacity(capacityRaw)
  // }

  const getNextStop = (stopId) => {
    const stopName = stops.filter((stop) => stop.stop_id === stopId)
    setNextStop(stopName[0])
  }

  const getArrivalVal = (estArrivalTime) => {
    setEstimatedArrival(getArrivalTime(estArrivalTime))
  }

  const capitalizeWords = (str) => {
    const newStr = str
      .replaceAll('_', ' ')
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
    return newStr
  }

  const getOccupancy = (occupancy) => {
    setBusOccupancy(capitalizeWords(occupancy))
  }

  return (
    <Box className={classes.centerContent}>
      <Box className={classes.flexColCenter}>
        <Typography variant="h2" className={classes.headerTop}>
          {'Estimated Arrival: '}
        </Typography>
        <Typography variant="h4" className={classes.estimatedArrivalText}>
          {`${estimatedArrival}`}
        </Typography>
      </Box>
      <Box
        styles={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <RoomIcon
          style={{
            color: selectedRoute?.route_color,
          }}
          className={classes.icon}
        />
        <Typography
          variant="h4"
          className={classes.valueText}
          style={{
            cursor: 'pointer',
            marginTop: -20,
          }}
          onClick={() => dispatch(handleStopOnRouteClicked(nextStop))}
          onKeyPress={() => dispatch(handleStopOnRouteClicked(nextStop))}
          role="button"
          tabIndex={0}
        >
          {nextStop.stop_name}
        </Typography>
      </Box>

      <Box
        styles={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {occupancyIcon}
        <Typography
          variant="h5"
          className={classes.occupancyText}
          style={{
            cursor: 'pointer',
            marginBottom: 20,
            marginLeft: 8,
          }}
        >
          {`${busoccupancy}`}
        </Typography>
      </Box>
    </Box>
  )
}

export default BottomSheetVehicleInfo
