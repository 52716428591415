/* eslint-disable react/no-array-index-key */
import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Grid, Box, Typography as Text, Link } from '@material-ui/core'

// import MenuBookIcon from '@material-ui/icons/MenuBook'
// import BookIcon from '@material-ui/icons/Book'
// import HelpIcon from '@material-ui/icons/Help'
// import ChatIcon from '@material-ui/icons/Chat'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import { facultyStaffServiceStyles } from './facultyStaffCenterStyles'
import {
  TabPanel,
  a11yProps,
} from '../../DashboardComponents/TabPanel/TabPanel'
import Widget, { SmallIconLink } from '../Widget'
import SearchBar from '../../DashboardComponents/SearchBar/SearchBar'

import MyCasesObject from './MyCasesObject'
import ToDoObject from './ToDoObject'
import Announcement from './Announcement'
import {
  fetchStaffAnnouncements,
  fetchStaffOpenCases,
  fetchStaffClosedCases,
  updateQuery,
  updateTabValue,
  updateTabValue2,
} from './facultyStaffCenterSlice'
import Loading from '../helpers/Loading'
import {
  RutgersTab,
  RutgersTabs,
} from '../../DashboardComponents/Tabs/RutgersTabs'
import CaseIndicator from '../../DashboardComponents/CaseIndicator/CaseIndicator'
import { logDashboardEvent } from '../../events'
import Footer from '../../DashboardComponents/Footer/Footer'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'

/**
 * Faculty/Staff Service Center Widget
 * Shows announcements and cases for the account as well as redirect links
 * @param {*} param0
 * @returns
 */
// eslint-disable-next-line no-unused-vars
export default function FacultyStaffCenter({ className, title, widgetId }) {
  const classes = facultyStaffServiceStyles()
  const dispatch = useDispatch()
  const { announcements, status } = useSelector((s) => s.facultyStaffCenter)
  React.useEffect(() => {
    dispatch(fetchStaffAnnouncements())
    dispatch(fetchStaffOpenCases())
  }, [])
  return (
    <Widget className={className}>
      <Widget.Header title={title} />
      <Widget.Content className={classes.content}>
        {renderFSCState(announcements, status, widgetId)}
      </Widget.Content>
    </Widget>
  )
}

function renderFSCState(announcements, status, widgetId) {
  switch (status) {
    case 'fulfilled':
      return <Loaded announcements={announcements} widgetId={widgetId} />
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage />
        </Grid>
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item></Grid>
        </Grid>
      )
  }
}
function renderOpenCaseState(caseStatus, widgetId, dispatch) {
  switch (caseStatus) {
    case 'fulfilled':
      return <MyOpenCases widgetId={widgetId} dispatch={dispatch} />
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage />
        </Grid>
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item></Grid>
        </Grid>
      )
  }
}
function renderClosedCaseState(caseStatus, widgetId, dispatch) {
  switch (caseStatus) {
    case 'fulfilled':
      return <MyClosedCases widgetId={widgetId} dispatch={dispatch} />
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage />
        </Grid>
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item></Grid>
        </Grid>
      )
  }
}

function Loaded(props) {
  const { announcements, widgetId } = props
  const classes = facultyStaffServiceStyles()
  const dispatch = useDispatch()
  const darkMode = useSelector((s) => s.settings)

  const handleChange = (event, newValue) => {
    dispatch(updateTabValue(newValue))
  }
  const handleChange2 = (event, newValue2) => {
    dispatch(updateTabValue2(newValue2))
  }

  const { myCasesOpen, value, value2, query, caseStatus } = useSelector(
    (s) => s.facultyStaffCenter,
  )
  // If the case is open and has a todo task, show it
  const toDoCases = myCasesOpen?.filter((cases) => cases.task_list.length !== 0)

  React.useEffect(() => {
    if (value === 1) {
      dispatch(fetchStaffOpenCases())
      dispatch(fetchStaffClosedCases())
    }
  }, [value])

  // Search bar onSearch: not functional yet
  // We want it to redirect the page to this link when user searches
  const searchOnRequest = (val) => {
    // Spaces are replaced by '%20' in the search link
    const redirectString = val.toString().split(' ').join('%20')
    const link = `${process.env.REACT_APP_FACULTYSTAFF_BASE_URL}/hrportal?id=search&spa=1&q=${redirectString}`

    // Opens new tab with search bar value
    window.open(link, '_blank')
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardEvent',
        widgetId,
        redirectString,
        link,
      )
    }
  }

  const buttonTitle = 'Launch Rutgers OneSource Service Center'
  const footerLink = `${process.env.REACT_APP_FACULTYSTAFF_BASE_URL}/hrportal`
  const handleFooterLinkClick = (link) => {
    // window.open(link)
    if (widgetId && dispatch) {
      logDashboardEvent(dispatch, 'dashboardEvent', widgetId, buttonTitle, link)
    }
  }

  return (
    <>
      <>
        <Grid container direction="column" className={classes.topContainer}>
          <Grid item className={classes.imgContainer}>
            <img
              className={classes.img}
              alt="OneSource"
              src={
                darkMode === true
                  ? './assets/images/one_source_dark.png'
                  : './assets/images/one_source_light.jpg'
              }
            />
          </Grid>

          <Grid item className={classes.searchBarContainer}>
            <SearchBar
              placeholder="How can we help you?"
              className={classes.searchBar}
              value={query}
              onChange={(result) => dispatch(updateQuery(result))}
              onCancelSearch={() =>
                dispatch(updateQuery({ target: { value: '' } }))
              }
              webViewMode
              onRequestSearch={() => searchOnRequest(query)}
              variant="outlined"
            />
          </Grid>
        </Grid>

        <Box className={classes.modalTabs}>
          <RutgersTabs
            value={value}
            onChange={handleChange}
            aria-label="Top Tab"
          >
            <RutgersTab label="Announcements" {...a11yProps(0)} />
            <RutgersTab
              label={
                <Box display="flex">
                  <Text className={classes.tabTextLabel}>My Cases</Text>
                  {myCasesOpen && myCasesOpen?.length > 0 && (
                    <CaseIndicator value={myCasesOpen?.length} />
                  )}
                </Box>
              }
              {...a11yProps(1)}
            />
          </RutgersTabs>
        </Box>
      </>
      <TabPanel value={value} index={0} className={classes.tabPanel1}>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
        >
          <div className={classes.announcementList}>
            <Announcement
              announcements={announcements}
              widgetId={widgetId}
              dispatch={dispatch}
            />
          </div>
        </Grid>
      </TabPanel>

      {/* My cases tab */}
      <TabPanel value={value} index={1} className={classes.tabPanel2}>
        <Box className={classes.modalTabs}>
          <RutgersTabs
            value={value2}
            onChange={handleChange2}
            aria-label="Top Tab"
            className={classes.tabBackground}
          >
            <RutgersTab
              className={classes.tabText}
              label="Open"
              {...a11yProps(2)}
            />
            <RutgersTab
              className={classes.tabText}
              label="Closed"
              {...a11yProps(3)}
            />
            <RutgersTab
              className={classes.tabText}
              label="To-dos"
              {...a11yProps(4)}
            />
          </RutgersTabs>
        </Box>

        {/* Open tab */}
        <TabPanel value={value2} index={0} className={classes.tabPanelCases}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
          >
            <div className={classes.casesList}>
              {renderOpenCaseState(caseStatus, widgetId, dispatch)}
            </div>
          </Grid>
        </TabPanel>

        {/* Closed tab */}
        <TabPanel value={value2} index={1} className={classes.tabPanelCases}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
          >
            <div className={classes.casesList}>
              {renderClosedCaseState(caseStatus, widgetId, dispatch)}
            </div>
          </Grid>
        </TabPanel>

        {/* To-dos tab */}
        <TabPanel value={value2} index={2} className={classes.tabPanelCases}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
          >
            <div className={classes.casesList}>
              {toDoCases.length === 0 ? (
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <CheckCircleOutlineIcon className={classes.circleIcon} />
                  </Grid>

                  <Grid item>
                    <Text variant="subtitle1">You do not have any To-Dos</Text>
                  </Grid>
                </Grid>
              ) : (
                <Grid container direction="column">
                  <>
                    {toDoCases.map((cases, index) => (
                      <ToDoObject
                        key={index}
                        cases={cases}
                        widgetId={widgetId}
                        dispatch={dispatch}
                      />
                    ))}
                  </>
                </Grid>
              )}
            </div>
          </Grid>
        </TabPanel>
      </TabPanel>
      {/* Bottom container */}
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.bottomContainer}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.grid1}
        >
          <Grid item>
            <SmallIconLink
              classes={classes}
              icon="fas fa-book"
              iconLabel={
                <Text component="span" className={classes.tabLink}>
                  Knowledge Base
                </Text>
              }
              link={`${process.env.REACT_APP_FACULTYSTAFF_BASE_URL}/hrportal?id=kb_view2`}
              widgetId={widgetId}
              dispatch={dispatch}
            />
          </Grid>

          <Grid item>
            <SmallIconLink
              classes={classes}
              icon="fas fa-book-open"
              iconLabel={
                <Text component="span" className={classes.tabLink}>
                  Service Catalog
                </Text>
              }
              link={`${process.env.REACT_APP_FACULTYSTAFF_BASE_URL}/hrportal?id=hrj_sc_category`}
              widgetId={widgetId}
              dispatch={dispatch}
            />
          </Grid>

          <Grid item>
            <SmallIconLink
              classes={classes}
              // className={classes.tabLink}
              icon="fas fa-question"
              iconLabel={
                <Text component="span" className={classes.tabLink}>
                  Ask a Question
                </Text>
              }
              link={`${process.env.REACT_APP_FACULTYSTAFF_BASE_URL}/hrportal?id=hrj_sc_cat_item&sys_id=27c78de49f331200d9011977677fcfb3`}
              widgetId={widgetId}
              dispatch={dispatch}
            />
          </Grid>

          <Grid item>
            <SmallIconLink
              classes={classes}
              // className={classes.tabLink}
              icon="fas fa-comments"
              iconLabel={
                <Text component="span" className={classes.tabLink}>
                  Feedback
                </Text>
              }
              link={`${process.env.REACT_APP_FACULTYSTAFF_BASE_URL}/hrportal?id=hrj_sc_cat_item&sys_id=538434491b9a73002c476356bd4bcb1a`}
              widgetId={widgetId}
              dispatch={dispatch}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.grid2}
        >
          <Footer>
            <Link
              onClick={() => handleFooterLinkClick()}
              href={footerLink}
              target="_blank"
              underline="none"
              color="secondary"
              className={classes.facultyStaffServiceCenterFooter}
            >
              {buttonTitle}
            </Link>
          </Footer>
        </Grid>
      </Grid>
    </>
  )
}

function MyOpenCases({ widgetId, dispatch }) {
  const classes = facultyStaffServiceStyles()
  const { myCasesOpen } = useSelector((s) => s.facultyStaffCenter)
  return (
    <>
      {myCasesOpen.length === 0 ? (
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <CheckCircleOutlineIcon className={classes.circleIcon} />
          </Grid>

          <Grid item>
            <Text variant="subtitle1">No Open Case Data</Text>
          </Grid>
        </Grid>
      ) : (
        <Grid container direction="column">
          <>
            {myCasesOpen.map((cases, index) => (
              <MyCasesObject
                key={index}
                cases={cases}
                widgetId={widgetId}
                dispatch={dispatch}
              />
            ))}
          </>
        </Grid>
      )}
    </>
  )
}

function MyClosedCases({ widgetId, dispatch }) {
  const classes = facultyStaffServiceStyles()
  const { myCasesClosed } = useSelector((s) => s.facultyStaffCenter)
  return (
    <>
      {myCasesClosed.length === 0 ? (
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <CheckCircleOutlineIcon className={classes.circleIcon} />
          </Grid>

          <Grid item>
            <Text variant="subtitle1">No Closed Case Data</Text>
          </Grid>
        </Grid>
      ) : (
        <Grid container direction="column">
          <>
            {myCasesClosed.map((cases, index) => (
              <MyCasesObject
                key={index}
                cases={cases}
                status="closed"
                widgetId={widgetId}
                dispatch={dispatch}
              />
            ))}
          </>
        </Grid>
      )}
    </>
  )
}
