import * as React from 'react'
import dayjs from 'dayjs'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { Typography as Text } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Pagination from '@material-ui/lab/Pagination'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchJobs,
  updateJobDetail,
  updateJobDetailOpen,
  updatePage,
  updateSearchTerm,
  updateCancelSearch,
} from './jobOpportunitiesSlice'
import SearchBar from '../../DashboardComponents/SearchBar/SearchBar'
import Widget from '../Widget'
import styles from './jobOpportunitiesStyles'
import Loading from '../helpers/Loading'
import { logDashboardEvent } from '../../events'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'

function JobOpportunities({
  className,
  title,
  widgetId,
  showNote,
  noteMessage,
  notePosition,
  noteBackground,
  noteIcon,
  noteUrl,
  noteFontColor,
}) {
  const dispatch = useDispatch()
  const { page, jobOpps, status, jobDetailOpen, jobDetail, term } = useSelector(
    (s) => s.jobOpportunities,
  )
  React.useEffect(() => {
    dispatch(fetchJobs())
  }, [])
  return (
    <Widget className={className}>
      <Widget.Header title={title} category="Career Services" />
      <Widget.Content
        showNote={showNote}
        noteMessage={noteMessage}
        notePosition={notePosition}
        noteBackground={noteBackground}
        noteIcon={noteIcon}
        noteUrl={noteUrl}
        noteFontColor={noteFontColor}
      >
        {jobOpportunitiesState(
          status,
          page,
          jobOpps,
          jobDetailOpen,
          jobDetail,
          term,
          widgetId,
          noteMessage,
        )}
      </Widget.Content>
    </Widget>
  )
}

function jobOpportunitiesState(
  status,
  page,
  jobOpps,
  jobDetailOpen,
  jobDetail,
  term,
  widgetId,
  noteMessage,
) {
  const classes = styles()
  switch (status) {
    case 'fulfilled':
      return (
        <Loaded
          status={status}
          page={page}
          jobOpps={jobOpps}
          jobDetailOpen={jobDetailOpen}
          jobDetail={jobDetail}
          term={term}
          widgetId={widgetId}
          noteMessage={noteMessage}
        />
      )
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage />
        </Grid>
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item className={classes.text}></Grid>
        </Grid>
      )
  }
}

function Loaded({
  page,
  jobOpps,
  jobDetailOpen,
  jobDetail,
  term,
  widgetId,
  noteMessage,
}) {
  const classes = styles()
  const [search, setSearch] = React.useState('')
  const dispatch = useDispatch()
  const handlePageClick = (event, value) => {
    dispatch(updatePage(value))
  }
  const handleSearchChange = (event) => {
    const text = event.target.value
    setSearch(text)
    dispatch(updateSearchTerm(text))
  }

  const handleSearchCancel = () => {
    setSearch('')
    dispatch(updateSearchTerm(''))
  }

  const handleJobOpen = () => {
    dispatch(updateJobDetailOpen())
  }
  return (
    <>
      {/* TO DO: Add Analytics */}
      {jobDetailOpen === true ? (
        <JobDetail
          jobDetail={jobDetail}
          handleJobOpen={handleJobOpen}
          widgetId={widgetId}
          dispatch={dispatch}
        />
      ) : (
        <>
          <div className={classes.searchBar}>
            <SearchBar
              placeholder="Search Jobs"
              value={search}
              onChange={handleSearchChange}
              onCancelSearch={() => dispatch(updateCancelSearch())}
            />
          </div>
          {jobOpps &&
          jobOpps.data &&
          jobOpps.data.length > 0 &&
          jobOpps.data[page - 1].length > 1 ? (
            <Grid container className={classes.jobsListContainer}>
              <Box
                style={{
                  height:
                    noteMessage === null || noteMessage === '' ? 380 : 340,
                  overflowY: 'auto',
                  overflowX: 'hidden',
                }}
              >
                {jobOpps.data[page - 1].map((job) => (
                  <JobObject
                    key={job.id}
                    job={job}
                    handleJobOpen={handleJobOpen}
                    onCancelSearch={handleSearchCancel}
                    updateJobDetail={updateJobDetail}
                  />
                ))}
              </Box>
              <Pagination
                size="small"
                showFirstButton
                showLastButton
                color="primary"
                count={
                  jobOpps.data.length - 1 > 0 ? jobOpps.data.length - 1 : 1
                }
                onChange={handlePageClick}
              />
            </Grid>
          ) : (
            <Box display="flex" flexDirection="column" alignItems="center">
              <CheckCircleOutlineIcon className={classes.jobSearchIcon} />
              <Text variant="h3">No Positions found for term: {term}</Text>
            </Box>
          )}
        </>
      )}
    </>
  )
}

function JobObject({ job }) {
  const classes = styles()
  const dispatch = useDispatch()
  const handleJobObjectClick = () => {
    dispatch(updateJobDetail(job))
    dispatch(updateJobDetailOpen())
  }
  return (
    <Card
      component="button"
      className={classes.jobCardBackground}
      onClick={handleJobObjectClick}
      variant="outlined"
    >
      <Box display="flex" flexDirection="column">
        <Text variant="subtitle1" className={classes.jobTitleText}>
          {job.title}
        </Text>
        <Box display="flex" justifyContent="space-between">
          <Text variant="caption">Department: {` ${job.author}`}</Text>
          <Text variant="caption">
            Posted: {dayjs(job.isoDate).format('MM/DD/YYYY')}
          </Text>
        </Box>
      </Box>
    </Card>
  )
}

function JobDetail({ handleJobOpen, widgetId, dispatch }) {
  const buttonTitle = 'Apply for this Job'
  const classes = styles()
  const { jobDetail } = useSelector((s) => s.jobOpportunities)
  const handleJobClick = () => {
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardEvent',
        widgetId,
        buttonTitle,
        jobDetail.link,
      )
    }
    window.open(jobDetail.link, '_blank')
  }
  return (
    <Box>
      <Box className={classes.jobDetailButtons}>
        <Button className={classes.jobDetailButton} onClick={handleJobOpen}>
          Back
        </Button>
        <Button
          className={classes.jobDetailButtonApply}
          onClick={handleJobClick}
        >
          {buttonTitle}
        </Button>
      </Box>
      <Card
        component="button"
        className={classes.jobDetailContainer}
        onClick={handleJobClick}
      >
        <Text variant="caption">
          <b>Department: </b>
          {`${jobDetail.author}`}
        </Text>
        <Text variant="caption">
          <b>Posted: </b>
          {dayjs(jobDetail.isoDate).format('MM/DD/YYYY')}
        </Text>
        <Text variant="caption">
          <b>Summary: </b>
          {jobDetail.content}
        </Text>
      </Card>
    </Box>
  )
}

export default JobOpportunities
