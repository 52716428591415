import React from 'react'
import {
  AppBar,
  IconButton,
  Grid,
  Toolbar,
  Typography as Text,
  Button,
} from '@material-ui/core'

import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import FavoriteIcon from '@material-ui/icons/Favorite'
import ViewAppInfoMobileStyles from './ViewAppInfoMobileStyles'
import {
  createIcon,
  createImageCarousel,
  convertGSImgList,
  selectFavoritedAppAndUpdate,
} from '../../../helpers'
import {
  selectApps,
  selectIsGuest,
  selectAppCategories,
  selectMyAppsCallStatus,
  selectAppCategoriesWithApps,
  fetchMyApps,
} from '../../../appSlice'
import SetCarouselDimensions from '../../../AppComponents/AppCarouselCombined/AppCarouselDimensions'
import AppCarouselCombined from '../../../AppComponents/AppCarouselCombined/AppCarouselCombined'
import { addProfileLogEvent } from '../../../../dashboard/widgets/MyProfile/myProfileSlice'
import CheckIsMobile from '../../../../../../utils/isMobileHook'

/**
 * App Info Page For Mobile
 * Displays information about an app in mobile view
 */
export const ViewAppInfoMobile = () => {
  const classes = ViewAppInfoMobileStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const dimensions = SetCarouselDimensions()
  const isMobile = CheckIsMobile()

  const apps = useSelector(selectApps)
  const appCategories = useSelector(selectAppCategories)
  const myAppsSorted = useSelector(selectAppCategoriesWithApps)
  const isGuest = useSelector(selectIsGuest)
  const status = useSelector(selectMyAppsCallStatus)

  const [app, setApp] = React.useState({})
  const [categoryName, setCategoryName] = React.useState('')
  const [favoriteSelected, setFavoriteSelected] = React.useState(false)
  const [icon, setIcon] = React.useState(null)
  const [appCarousel, setAppCarousel] = React.useState(null)
  const [imageCarousel, setImageCarousel] = React.useState([])
  const [showImages, setShowImages] = React.useState(false)

  React.useEffect(() => {
    if (status === 'idle' || status === 'pending') {
      dispatch(fetchMyApps())
    } else {
      const query = location.search
      const id = new URLSearchParams(query).get('appID')
      const selectedApp = apps.filter((a) => a.id === id)[0]
      const category = appCategories.filter(
        (c) => c.id === selectedApp.category.toString(),
      )[0]
      setCategoryName(category)
      createAppCarouselMobile(category)
      setApp(selectedApp)
      setIcon(createIcon(selectedApp.icon, '3x'))
      setFavoriteSelected(selectedApp.isFavorited)
      createCarouselImage(selectedApp)
    }
  }, [status, location])

  const createCarouselImage = async (selectedApp) => {
    if (selectedApp.screenshotUrls) {
      const imageList = await convertGSImgList(selectedApp.screenshotUrls)
      createImageCarousel(classes, imageList, setImageCarousel)
      setShowImages(true)
    }
  }

  const createAppCarouselMobile = (selectedCategory) => {
    const cat = myAppsSorted.filter((c) => c.id === selectedCategory.id)[0]
    const carousel = (
      <AppCarouselCombined
        key={cat.name}
        appList={cat.apps}
        carouselType="carousel"
        category={cat.name}
        dimensions={dimensions}
        id={cat.id}
        isMobile={isMobile}
        isGuest={isGuest}
      />
    )
    setAppCarousel(carousel)
  }

  const handleFavorited = () => {
    selectFavoritedAppAndUpdate(dispatch, app.id)
  }

  return (
    <div className={classes.container}>
      <AppBar className={classes.appBar} position="fixed" elevation={2}>
        <Toolbar className={classes.container}>
          <Grid container justifyContent="flex-start" alignItems="center">
            <Grid item sm={4}>
              <IconButton onClick={() => history.goBack()}>
                <ArrowBackIosIcon className={classes.mobileBackButtonIcon} />
              </IconButton>
            </Grid>
            <Grid item sm={4} className={classes.pageTitleContainer}>
              <Text className={classes.pageTitle}> {app.title} </Text>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div style={{ marginTop: 75, padding: 15 }}>
        <Grid container>
          <Grid
            xs={3}
            item
            className={classes.iconContainer}
            style={{ backgroundColor: app.color }}
          >
            {icon}
          </Grid>

          <Grid item xs={9} className={classes.headingContainer}>
            <Grid container direction="column" alignItems="flex-start">
              <Grid item>
                <Text component="span" className={classes.appNameText}>
                  {app.title}
                </Text>
              </Grid>

              <Grid item>
                <Text component="span" className={classes.categoryText}>
                  {categoryName.name}
                </Text>
              </Grid>

              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item>
                  <Button
                    className={classes.openButton}
                    onClick={() => {
                      if (process.env.REACT_APP_FIREBASE_ON === 'true') {
                        dispatch(
                          addProfileLogEvent({
                            module: 'Apps',
                            object: 'AppTile',
                            objectId: 'AppsInfo',
                            action: 'Click',
                            actionContext: 'ViewFragmentPage',
                            actionContextValue: app.id,
                            rcpid: localStorage.getItem('rutgersEduRCPID'),
                            timestamp: Date.now(),
                            deviceType: 'Web',
                          }),
                        )
                      }
                      window.open(app.url)
                    }}
                  >
                    Open
                  </Button>
                </Grid>

                <Grid item>
                  <IconButton onClick={() => handleFavorited()}>
                    <FavoriteIcon
                      className={
                        favoriteSelected
                          ? classes.selectedFavoriteIconButton
                          : classes.iconButton
                      }
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          className={classes.descriptionContainer}
        >
          <Grid item>
            <Text className={classes.descriptionHeaderText}>{app.info}</Text>
          </Grid>

          <Grid item className={classes.infoTextContainer}>
            <Text className={classes.infoText}>{app.message}</Text>
          </Grid>
        </Grid>
      </div>

      {showImages ? imageCarousel : null}

      <div className={classes.appCarouselBody}>{appCarousel}</div>
    </div>
  )
}

export default ViewAppInfoMobile
