/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import DOMPurify from 'dompurify'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Backdrop from '@material-ui/core/Backdrop'

import styles from './alertSnackbarStyles'

import {
  updateNotificationsDialogOpen,
  updateSnackbarOpen,
  updateAlertIndex,
  acknowledgeAlert,
} from '../../widgets/MyAlerts/MyAlertsSlice'
import { logDashboardEvent } from '../../events'
import { checkIfEmergencyAlertHasBeenSeenAlready } from './utils'

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default function AlertSnackbar() {
  const classes = styles()
  const dispatch = useDispatch()
  const { status, data, snackbarOpen, alertIndex, notificationsDialogOpen } =
    useSelector((s) => s.myAlerts)
  const { notificationAlerts } = data.alerts
  const handleSentDate = (alertSentDate) => {
    const firstDate =
      alertSentDate?.split('T')[0] || alertSentDate?.split('T')[0]
    const [year, month, day] = firstDate?.split('-')
    const longOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }
    const date = new Date(year, month - 1, day)
    return date.toLocaleDateString('en-US', longOptions)
  }
  React.useEffect(() => {
    if (
      status === 'fulfilled' &&
      notificationAlerts &&
      notificationAlerts[alertIndex] &&
      notificationAlerts[alertIndex]?.acknowledgedTS !== '' &&
      checkIfEmergencyAlertHasBeenSeenAlready(notificationAlerts[alertIndex]) &&
      notificationAlerts[alertIndex].alerttype !== 'ITAlert'
    ) {
      const newAlertIndex = alertIndex + 1
      dispatch(updateAlertIndex(newAlertIndex))
    }
  }, [status, alertIndex])
  return status === 'fulfilled' ? (
    <>
      <DashboardDialog
        dialogTitle={
          <Typography variant="h3">
            {notificationAlerts[alertIndex]?.title}
          </Typography>
        }
        open={notificationsDialogOpen}
        dispatch={dispatch}
        closeDialog={updateNotificationsDialogOpen}
        alerts={notificationAlerts}
        alertType={
          notificationAlerts[alertIndex]?.severityLevel ||
          notificationAlerts[alertIndex]?.alerttype
        }
        alertIndex={alertIndex}
        updateSnackbarOpen={updateSnackbarOpen}
        updateAlertIndex={updateAlertIndex}
        dialogContent={
          <AlertContent
            title={notificationAlerts[alertIndex]?.title}
            link={notificationAlerts[alertIndex]?.link}
            description={notificationAlerts[alertIndex]?.message}
            isAcknowledged={
              notificationAlerts[alertIndex]?.acknowledgedTS === ''
                ? 'Not yet Acknowledged'
                : 'Acknowledged'
            }
            createDate={
              notificationAlerts[alertIndex]?.sent_date
                ? handleSentDate(notificationAlerts[alertIndex]?.sent_date)
                : notificationAlerts[alertIndex]?.start_date
            }
            classes={classes}
            alertType={
              notificationAlerts[alertIndex]?.severityLevel ||
              notificationAlerts[alertIndex]?.alerttype
            }
          />
        }
        maxWidth={500}
      />
      {notificationAlerts[alertIndex] &&
      (notificationAlerts[alertIndex].acknowledgedTS === '' ||
        notificationAlerts[alertIndex].alerttype === 'EmergencyAlert' ||
        notificationAlerts[alertIndex].alerttype === 'ITAlert') &&
      notificationAlerts[alertIndex].severityLevel !== 'Low' &&
      Object.keys(notificationAlerts[alertIndex]).length > 0 ? (
        <CustomSnackbar
          duration={notificationAlerts[alertIndex].duration}
          type={
            notificationAlerts[alertIndex]?.severityLevel ||
            notificationAlerts[alertIndex]?.alerttype
          }
          message={notificationAlerts[alertIndex].title}
          updateAlertIndex={updateAlertIndex}
          alertIndex={alertIndex}
          dockey={notificationAlerts[alertIndex].dockey}
          dispatch={dispatch}
          updateNotificationsDialogOpen={updateNotificationsDialogOpen}
          snackbarOpen={snackbarOpen}
          updateSnackbarOpen={updateSnackbarOpen}
          classes={classes}
        />
      ) : null}
    </>
  ) : null
}

const CustomSnackbar = ({
  alertIndex,
  message,
  type,
  dispatch,
  snackbarOpen,
  classes,
  dockey,
}) => {
  const handleClose = (event, reason) => {
    if (reason !== 'clickaway') {
      dispatch(updateSnackbarOpen(true))
      if (
        type !== 'High' &&
        type !== 'EmergencyAlert' &&
        type !== 'SchoolAlert' &&
        type !== 'EmployeeAlert' &&
        snackbarOpen === true
      ) {
        console.log('index update called ')
        const newAlertIndex = alertIndex + 1
        dispatch(updateAlertIndex(newAlertIndex))
      }
    }
    return false
  }
  const handleOpenDialog = () => {
    dispatch(updateNotificationsDialogOpen())
    // handleClose()
    dispatch(updateSnackbarOpen(!snackbarOpen))
    logDashboardEvent(dispatch, 'alertsViewEvent', 'AlertItem', dockey)
  }
  const handleAlertType = () => {
    console.log('type ', type)
    switch (type) {
      case 'High':
        return 'error'
      case 'Medium':
        return 'warning'
      case 'StudentAlert':
        return 'error'
      case 'EmployeeAlert':
        return 'error'
      case 'ITAlert':
        return 'warning'
      default:
        return 'error'
    }
  }
  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={
          snackbarOpen === false ||
          type === 'High' ||
          type === 'EmergencyAlert' ||
          type === 'StudentAlert' ||
          type === 'EmployeeAlert'
            ? null
            : 3000
        }
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        style={{ marginTop: 40 }}
      >
        <Alert
          onClose={handleClose}
          severity={handleAlertType()}
          action={
            <Button color="inherit" size="small" onClick={handleOpenDialog}>
              View
            </Button>
          }
        >
          {message}
        </Alert>
      </Snackbar>
      {snackbarOpen === true ? (
        <div>
          <Backdrop className={classes.backdrop} open={type !== 'Medium'} />
        </div>
      ) : null}
    </>
  )
}

function DashboardDialog({
  open,
  dialogTitle,
  dialogContent,
  closeDialog,
  dispatch,
  alerts,
  alertIndex,
  alertType,
}) {
  const classes = styles()
  const closeDialogAction = () => {
    dispatch(closeDialog())
    logDashboardEvent(
      dispatch,
      'alertsDismissEvent',
      'AlertItem',
      alerts[alertIndex].dockey,
    )
    if (alertIndex < alerts.length) {
      const newAlertIndex = alertIndex + 1
      dispatch(updateAlertIndex(newAlertIndex))
    }
    if (alerts.length - 1 <= alertIndex) {
      dispatch(updateSnackbarOpen(false))
    } else {
      dispatch(updateSnackbarOpen(true))
    }
  }
  const acknowledgeDialogAction = () => {
    logDashboardEvent(
      dispatch,
      'alertsAcknowledgeEvent',
      'AlertItem',
      alerts[alertIndex].dockey,
    )
    dispatch(acknowledgeAlert(alerts[alertIndex])).then(() => {
      dispatch(closeDialog())
      if (alertIndex < alerts.length) {
        const newAlertIndex = alertIndex + 1
        dispatch(updateAlertIndex(newAlertIndex))
      }
      if (alerts.length - 1 <= alertIndex) {
        dispatch(updateSnackbarOpen(false))
      } else {
        dispatch(updateSnackbarOpen(true))
      }
    })
  }
  return (
    <Dialog open={open} maxWidth="md" transitionDuration={0}>
      <DialogTitle
        classes={{
          root:
            alertType === 'High' ||
            alertType === 'Medium' ||
            alertType === 'ITAlert' ||
            alertType === 'EmployeeAlert' ||
            alertType === 'StudentAlert'
              ? classes[`dialogTitle${alertType}Alert`]
              : classes.dialogTitleHighAlert,
        }}
      >
        <Grid
          container
          direction="row"
          alignItems="baseline"
          justifyContent="space-between"
        >
          <Grid item container direction="column" wrap="nowrap">
            <Grid item>{dialogTitle}</Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>{dialogContent}</DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" flex={1}>
          <Button
            variant="outlined"
            className={classes.dialogActionButton}
            onClick={closeDialogAction}
          >
            Close
          </Button>
          {alertType !== 'EmergencyAlert' ? (
            <Button
              variant="contained"
              className={
                alertType === 'High' ||
                alertType === 'Medium' ||
                alertType === 'ITAlert' ||
                alertType === 'EmployeeAlert' ||
                alertType === 'StudentAlert'
                  ? classes[`dialogTitle${alertType}Alert`]
                  : classes.dialogTitleHighAlert
              }
              onClick={acknowledgeDialogAction}
            >
              Acknowledge
            </Button>
          ) : null}
        </Box>
      </DialogActions>
    </Dialog>
  )
}

const AlertContent = ({
  description,
  link,
  isAcknowledged,
  createDate,
  classes,
  alertType,
}) => (
  <Box>
    {createDate ? (
      <Typography variant="subtitle1" className={classes.alertText}>
        Posted <span className={classes.createDateSpan}>{createDate}</span>
      </Typography>
    ) : null}
    {alertType !== 'EmergencyAlert' && alertType !== 'ITAlert' ? (
      <Typography variant="subtitle1" className={classes.alertText}>
        Acknowledged{' '}
        <span className={classes.isAcknowledgedSpan}>{isAcknowledged}</span>
      </Typography>
    ) : null}
    {/* <Typography variant="subtitle1" className={classes.alertText}>
      Title <span className={classes.titleSpan}>{title}</span>
    </Typography> */}
    {link && (
      <Typography variant="subtitle1" className={classes.alertText}>
        Link Title{' '}
        <a
          href={link}
          rel="noopener noreferrer"
          target="_blank"
          className={classes.linkTitleSpan}
        >
          {link}
        </a>
      </Typography>
    )}
    <div
      // eslint-disable-next-line react/no-danger
      className={classes.descriptionStyle}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(description, { ADD_ATTR: ['target'] }),
      }}
    />
  </Box>
)
