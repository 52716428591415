/* eslint-disable arrow-body-style */
import * as React from 'react'
import {
  DialogContent,
  Grid,
  Input,
  Typography,
  Select,
  MenuItem,
  DialogActions,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  Divider,
  InputLabel,
} from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'
import SearchIcon from '@material-ui/icons/Search'

const SearchDialog = () => {
  const classes = SearchDialogStyles()
  const [search, setSearch] = React.useState('')
  const handleTextInput = (event) => {
    setSearch(event.target.value)
  }

  const [tabValue, setTabValue] = React.useState(0)
  const handleChange = (event) => {
    const currentTabValue = event.target.value
    setSearch('')
    setTabValue(currentTabValue)
  }

  const performSearch = () => {
    const url = `${
      searchTypes.rutgers.options[tabs[tabValue]].url
    }${encodeURIComponent(search)}`
    window.open(url)
  }

  const tabs = ['People', 'Websites', 'RBHS Search', 'Buildings']

  const searchTypes = {
    rutgers: {
      options: {
        People: {
          title: 'People',
          placeholder: 'E.g. Smith, Jo*n',
          hintText: 'Search for name or directory listing name',
          url: 'http://search.rutgers.edu/people.html?q=',
        },
        Websites: {
          title: 'Websites',
          placeholder: 'E.g. rutgers.edu',
          hintText:
            'Search for Rutgers specific website URL based your search term',
          url: 'http://search.rutgers.edu/web.html?q=',
        },
        'RBHS Search': {
          title: 'RBHS Search',
          placeholder: 'E.g. Smith, Jo*n',
          hintText:
            'Search for an RBHS specific faculty and staff contact information',
          url: 'http://search.rutgers.edu/rbhs-search.html?q=',
        },
        Buildings: {
          title: 'Buildings',
          placeholder: 'E.g. Old Queens',
          hintText:
            'Search for Rutgers specific buildings such as Student Services Center, ASB I, ASB II',
          url: 'http://search.rutgers.edu/buildings.html?q=',
        },
      },
    },
  }

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setSearch('')
    setTabValue(0)
    setAnchorEl(null)
  }

  return (
    <div className={classes.flex}>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="rutgers search menu"
        onClick={handleClick}
      >
        <SearchIcon className={classes.appBarIcon} />
      </IconButton>
      <Dialog
        onClose={handleClose}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        keepMounted
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <DialogTitle>&#8288;Rutgers Search</DialogTitle>
        <Divider />
        <DialogContent>
          <Grid item container alignItems="flex-end">
            <Grid item>
              <InputLabel id="rutgers-search-select">
                <Select
                  value={tabValue}
                  onChange={handleChange}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'right',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'right',
                    },
                  }}
                >
                  {tabs.map((tab, index) => (
                    <MenuItem key={tab} value={index}>
                      <Typography align="left" className={classes.input}>
                        &#8288;{tab}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </InputLabel>
            </Grid>
            <Grid item>
              <Input
                className={classes.input}
                placeholder={
                  searchTypes.rutgers.options[tabs[tabValue]].placeholder
                }
                onChange={handleTextInput}
                value={search}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogContent>
          <Typography align="left" variant="h5">
            &#8288;{searchTypes.rutgers.options[tabs[tabValue]].hintText}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.button}
            onClick={performSearch}
            disabled={search.length === 0}
            color="primary"
            variant="outlined"
          >
            RU Search
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default SearchDialog

const SearchDialogStyles = makeStyles((theme) =>
  createStyles({
    appBarIcon: {
      color: `${theme.palette.components.topBarIcon} !important`,
      fontSize: 24,
    },
    flex: {
      display: 'flex',
    },
    input: {
      fontSize: '14px',
    },
    button: {
      fontSize: '14px',
    },
  }),
)
