import { makeStyles } from '@material-ui/core/styles'

// TODO Make these colors match properly instead of hardcoded
export const topAppBarStyles = makeStyles((theme) => ({
  appBar: {
    position: 'fixed',
    backgroundColor: theme.palette.components.appBar,
    // zIndex: 1500,
    color: 'rgba(0,0,0,0.54)',
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% - 55px)',
      marginLeft: 55,
    },
  },
  mobileBackButtonIcon: {
    color: theme.palette.base.red,
    fontSize: 24,
  },
  gridClass: {
    marginLeft: 10,
  },
  container: {
    height: 55,
    maxHeight: 55,
    minHeight: 55,
    paddingLeft: 0,
    paddingRight: 0,
  },
  leftMenuButton: {
    marginLeft: theme.spacing(1),
  },
  title: {
    color: '#f50230',
    fontStyle: 'italic',
    fontSize: 14,
    fontWeight: '600',
  },
  myRutgersLogo: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
    maxWidth: '130px',
  },
  appButton: {
    borderRadius: '8px',
    padding: '4px',
    '&:focus-visible': {
      outline: `1px solid ${theme.palette.button.focusBorder}`,
      boxShadow: `0 0 0 3px ${theme.palette.button.focusOutline}`,
    },
    '&:hover': {
      backgroundColor: theme.palette.button.hoverLight,
    },
  },
  appBarIcon: {
    color: `${theme.palette.components.topBarIcon} !important`,
    fontSize: 24,
  },
  searchBar: {
    // backgroundColor: theme.palette.background.widgetSecondaryBackground,
    minWidth: 300,
  },
  searchBarContainer: {
    marginRight: theme.spacing(2),
  },
  reorderFavoritesButton: {
    marginRight: 0,
  },
  reorderButton: {
    color: theme.palette.base.red,
  },
  retainer: {
    height: '100%',
  },
  modalTabs: {
    width: '100% !important',
    height: '35px',
    minHeight: '35px',
    minWidth: '100%',
    [theme.breakpoints.down(370)]: {
      width: '300px',
    },
  },
  mainDialog: {
    maxWidth: '100vw',
  },
  dialog: {
    width: '450px',
    maxWidth: '450px',
    backgroundColor: theme.palette.backgroundColor.secondary,
    height: '555px !important',
    padding: '0px',
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  pastHistoryButton: {
    width: '100%',
    background: theme.palette.primary.main,
    color: theme.palette.base.white,
    height: 44,
    fontSize: '1rem',
  },
  alertListHeight: {
    display: 'block',
    height: 475,
    overflowY: 'auto',
  },
  alertListHighChip: {
    background: theme.palette.base.red,
    color: theme.palette.base.white,
  },
  alertListMediumChip: {
    background: theme.palette.base.blue,
    color: theme.palette.base.white,
  },
  alertListLowChip: {
    background: theme.palette.warning.medium,
    color: theme.palette.base.black,
  },
  grid: {
    width: '100%',
    height: '100%',
    margin: '0px',
  },
  scrollGrid: {
    overflowY: 'auto',
    flex: 1,
  },
  listGridItem: {
    overflowY: 'auto',
  },
  betaModeHeader: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '1.1rem',
    color: theme.palette.primary.main,
    marginLeft: 10,
    marginRight: 10,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginRight: 3,
      fontSize: '0.875rem',
    },
  },
  betaModeSwitchHeader: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '1.1rem',
    color: theme.palette.primary.main,
    marginLeft: 10,
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.775rem',
    },
    [theme.breakpoints.down(400)]: {
      marginLeft: 0,
    },
  },
  betaModeContainer: {
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '3rem',
    width: 130,
    display: 'flext',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 100,
      padding: 3,
    },
    [theme.breakpoints.down(400)]: {
      width: 80,
    },
  },
  betaModeButtonBase: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: 100,
      marginLeft: 10,
    },
    [theme.breakpoints.down(400)]: {
      width: 89,
      marginLeft: 0,
    },
  },
  fullWidth: {
    maxWidth: '100% !important',
  },
  badgeStyle: {
    '& .MuiBadge-badge': {
      border: `1px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  },
}))

export default topAppBarStyles
