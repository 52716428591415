import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) => ({
  container: {
    padding: 0,
    margin: 0,
    height: 475,
    width: 335,
    overflowY: 'hidden',
    overflowX: 'hidden',
    [theme.breakpoints.down(370)]: {
      width: 287,
      height: 450,
    },
  },
  image: {
    width: '100%',
    padding: '0 !important',
    margin: 0,
  },
  gridContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  eventsHolder: {
    flex: 1,
    width: '100%',
    margin: 0,
    padding: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  accordionCard: {
    margin: '5px',
    '&.Mui-expanded': {
      margin: '0px 5px',
    },
  },
}))

export default styles
