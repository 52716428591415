/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchMyExpenses = createAsyncThunk('fetchMyExpenses', async () => {
  const jwtToken = localStorage.getItem('myrJwtToken')
  const rcpid = localStorage.getItem('rcpid')
  const res = await axios.get(process.env.REACT_APP_MYEXPENSES_ENDPOINT, {
    headers: {
      JWT: jwtToken,
      XWT: rcpid,
    },
  })
  return res.data
})

const initialState = {
  status: 'idle',
  data: {
    expensesList: [],
    url: '',
    title: '',
    urlLabel: '',
  },
}

const myExpensesSlice = createSlice({
  name: 'myExpensesWidget',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchMyExpenses.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchMyExpenses.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchMyExpenses.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.data.url = action.payload.expenseURL
      state.data.urlLabel = action.payload.urlLabel
      state.data.expenseList = action.payload.expenseList
      state.data.title = action.payload.title
    },
  },
})

export const selectMyExpensesData = (state) => state.myExpenses.data
export const selectMyExpensesStatus = (state) => state.myExpenses.status

export default myExpensesSlice.reducer
