import * as React from 'react'
import {
  Grid,
  Typography as Text,
  Card,
  Link,
  Collapse,
  IconButton,
} from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { MyCasesObjectStyles } from './facultyStaffCenterStyles'
import { logDashboardEvent } from '../../events'

/**
 * MyCase Object that shows details on cases in the
 * My Cases tab of the Faculty/Staff widget
 * @param cases The case object to be passed down
 * @param status Tells us if the case is closed or open
 */
function MyCasesObject({ cases, status, widgetId, dispatch }) {
  const classes = MyCasesObjectStyles()

  const [expanded, setExpanded] = React.useState(false)
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const link = `${process.env.REACT_APP_FACULTYSTAFF_BASE_URL}/hrportal?id=hrj_ticket_page&sys_id=${cases.sys_id}`
  const handleLinkClick = (eventLink, title) => {
    if (widgetId && dispatch) {
      logDashboardEvent(dispatch, 'dashboardEvent', widgetId, title, eventLink)
    }
  }

  return (
    <Card className={classes.caseCardBackground} border={1} variant="outlined">
      <Grid container justifyContent="flex-start" direction="column">
        <Grid item className={classes.gridItem}>
          <Link
            href={link}
            target="_blank"
            underline="none"
            onClick={() => handleLinkClick(link, cases.title)}
          >
            <Text variant="subtitle1">{cases.title}</Text>
          </Link>
        </Grid>

        <Grid item>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Text variant="body1">{cases.display_value}</Text>
            </Grid>

            <Grid item containrt>
              {status === 'closed' ? (
                <Text variant="body1">Closed Complete</Text>
              ) : (
                <Grid container direction="row" alignItems="center">
                  <FiberManualRecordIcon
                    fontSize="small"
                    className={
                      cases.stateLabel === 'Ready'
                        ? classes.greenIcon
                        : classes.yellowIcon
                    }
                  />
                  <Text
                    variant="body1"
                    className={
                      cases.stateLabel === 'Ready'
                        ? classes.greenText
                        : classes.yellowText
                    }
                  >
                    {`${cases.stateLabel}`}
                  </Text>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container justifyContent="flex-start" direction="column">
            <Grid item>
              <IconButton
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
                disableRipple="true"
                className={classes.iconButton}
              >
                <ExpandMoreIcon
                  className={
                    expanded ? classes.expandOpen : classes.expandClosed
                  }
                />

                <Text variant="body1">Case Description</Text>
              </IconButton>

              <Collapse in={expanded} timeout="auto" unmountOnExit>
                {cases.description.length === 0 ? (
                  <Text variant="body1">There is no case description.</Text>
                ) : (
                  <Text variant="body1">{cases.description}</Text>
                )}
              </Collapse>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}

export default MyCasesObject
