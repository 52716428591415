import { makeStyles, withStyles } from '@material-ui/core/styles'
import DialogContent from '@material-ui/core/DialogContent'

// TODO Make these colors match properly instead of hardcoded
export const adminPageStyles = makeStyles((theme) => ({
  listIconStyle: {
    color: theme.palette.components.sideNavIcon,
  },
  header: {
    textAlign: 'left',
    fontWeight: 500,
    fontSize: '.9rem',
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-user-select': 'none' /* Safari */,
    '-moz-user-select': 'none' /* Firefox */,
    '-ms-user-select': 'none' /* IE10+/Edge */,
    'user-select': 'none' /* Standard */,
  },
  divider: {
    backgroundColor: theme.palette.base.red,
  },
  reorderListStyles: {
    padding: '5px 20px',
    overflowX: 'hidden',
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: '5px 30px',
    },
  },
  dialog: {
    minWidth: '500px',
    width: '70vw',
    maxWidth: '100%',
    height: '80vh',
    maxHeight: '950px',
    overflow: 'hidden',
    padding: '0px',
    backgroundColor: theme.palette.backgroundColor.secondary,
    [theme.breakpoints.down('sm')]: {
      minWidth: '100px',
      width: '90vw',
    },
  },
  dialogContainer: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  deletePaper: {
    padding: '5px',
    '-webkit-user-select': 'none' /* Safari */,
    '-moz-user-select': 'none' /* Firefox */,
    '-ms-user-select': 'none' /* IE10+/Edge */,
    'user-select': 'none' /* Standard */,
  },
  trashIcon: {
    color: theme.palette.base.red,
  },
  containerStyle: {
    background: '#dadada',
    padding: 10,
    margin: 10,
    flex: 1,
    overflow: 'auto',
  },
  headerText: {
    color: theme.palette.base.red,
    fontSize: 22,
    fontWeight: 600,
  },
}))

export default adminPageStyles

export const StyledDialogContent = withStyles((theme) => ({
  root: {
    '& .MuiDialogContent-divider': {
      backgroundColor: theme.palette.base.red,
    },
  },
}))(DialogContent)
