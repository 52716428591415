/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-named-as-default-member */
import * as React from 'react'
import { useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
// import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import styles from './WidgetStyles'
import * as widgets from '.' // index.js import
import Loading from './helpers/Loading'
import { analytics } from '../../../../utils/firebase'
import { Content, Header } from './Widget'
import IconPicker from '../../apps/AppComponents/IconPicker/IconPicker'
// import { logDashboardEvent } from '../events'
// import { addProfileLogEvent } from './MyProfile/myProfileSlice'
import AdminWidget from './Admin/AdminWidget'
import WidgetErrorBoundary from './WidgetErrorBoundary'
import ErrorPage from '../ErrorPage'

/**
  WidgetsList is used to display all the widgets on the dashboard page
 */
function DesktopWidgetsList(params) {
  const classes = styles()
  return (
    <Box>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={5}>
            {renderWidgetListState(
              params.widgetCallState,
              params.apiReturnedWidgets,
              params.isAdmin,
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

function renderWidgetListState(widgetCallState, apiReturnedWidgets, isAdmin) {
  switch (widgetCallState) {
    case 'fulfilled':
      return (
        <Loaded apiReturnedWidgets={apiReturnedWidgets} isAdmin={isAdmin} />
      )
    case 'pending':
      return <Loading />
    case 'error':
      return <ErrorPage />
    default:
      return <Box />
  }
}

function Loaded(props) {
  // const handleLinkClick = (widgetId, link, notesTitle) => {
  // if (widgetId && dispatch) {
  //   logDashboardEvent(
  //     dispatch,
  //     'dashboardLinkEvent',
  //     widgetId,
  //     notesTitle,
  //     link,
  //   )
  // }
  // addProfileLogEvent({
  //   events: 'dashboardLinkEvent',
  //   objectId: widgetId,
  //   actionContextValue: notesTitle,
  //   rcpid: localStorage.getItem('rutgersEduRCPID'),
  //   link,
  //   timestamp: Date.now(),
  // })
  // if (process.env.REACT_APP_FIREBASE_ON === 'true') {
  //     analytics.logEvent('dashboardLinkEvent', { widgetId: widgetId, : notesTitle, :link })
  // }
  // }
  const classes = styles()
  const { isAdmin } = props
  const { userProfile } = useSelector((s) => s.myProfile)
  React.useEffect(() => {
    if (process.env.REACT_APP_FIREBASE_ON === 'true') {
      const widgetNames = props.apiReturnedWidgets.map(
        (apiWidget) => apiWidget.name,
      )
      analytics.logEvent('widget_shown', { widgets: widgetNames })
    }
  }, [])

  return props.apiReturnedWidgets.map((apiWidget, index) => {
    let Widget = widgets[apiWidget.key]
    Widget = Widget ?? widgets.WidgetNotFound

    const { proxyNetId } = userProfile
    // const notesPosition = apiWidget.note_position === 'top' ? '8%' : '92%'
    // not available in proxy mode clause
    // const notesProps = {
    //   note_msg: apiWidget.note_msg,
    //   note_background: apiWidget.note_background,
    //   note_icon: apiWidget.note_icon,
    //   note_url: apiWidget.note_url,
    //   note_fontColor: apiWidget.note_fontColor,
    // }

    if (
      (apiWidget.proxy === 'No' ||
        (apiWidget.proxy !== '' &&
          !userProfile.proxyPermissions?.includes(apiWidget.proxy))) &&
      proxyNetId
    ) {
      return (
        <Grid key={`${apiWidget.widgetId}-${index}`} item>
          <Paper className={classes.widget} elevation={0} category="default">
            <Header title={apiWidget.title} />
            <Content>
              <Box
                height="100%"
                display="flex"
                alignItems="center"
                flexDirection="column"
                className={classes.proxyModeContainer}
              >
                <IconPicker iconName="times-circle" color="#cc0033" size="5x" />
                <Typography
                  variant="subtitle1"
                  className={classes.proxyModeText}
                >
                  Data Not Available in Proxy Mode.
                </Typography>
              </Box>
            </Content>
          </Paper>
        </Grid>
      )
    }
    if (apiWidget.showOnDashboard || isAdmin) {
      Widget = widgets[apiWidget.key]
      return (
        <Grid key={`${apiWidget.widgetId}-${index}`} item>
          {isAdmin ? (
            <AdminWidget
              key={apiWidget.key}
              widgetData={apiWidget}
              index={index}
            />
          ) : (
            <WidgetErrorBoundary
              key={apiWidget.key}
              title={apiWidget.title}
              className={classes.widget}
            >
              <Widget
                key={apiWidget.key}
                widgetId={apiWidget.key}
                className={classes.widget}
                title={apiWidget.title}
                proxy={apiWidget.proxy}
                notes={apiWidget.note_msg}
                notesPosition={apiWidget.note_position}
                showNote={apiWidget.dsp_note}
                noteMessage={apiWidget.note_msg}
                notePosition={apiWidget.note_position}
                noteBackground={apiWidget.note_background}
                noteIcon={apiWidget.note_icon}
                noteUrl={apiWidget.note_url}
                noteFontColor={apiWidget.note_fontColor}
              >
                {apiWidget.name}
              </Widget>
            </WidgetErrorBoundary>
          )}
        </Grid>
      )
    }
    return null
  })
}

export default DesktopWidgetsList
