/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  title: 'My Paycheck',
  netPay: '',
  payDate: '',
  payPeriodStart: '',
  payPeriodEnd: '',
  status: 'idle',
}

export const fetchMyPaycheck = createAsyncThunk('fetchMyPaycheck', async () => {
  const jwtToken = localStorage.getItem('myrJwtToken')
  const rcpid = localStorage.getItem('rcpid')
  const res = await axios.get(process.env.REACT_APP_PAYCHECK_ENDPOINT, {
    headers: {
      JWT: jwtToken,
      XWT: rcpid,
    },
  })
  return res.data
})

export const paycheckSlice = createSlice({
  name: 'myPaycheckSlice',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchMyPaycheck.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchMyPaycheck.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchMyPaycheck.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      //   console.log(action.payload)
      state.title = action.payload.title
      state.netPay = action.payload.netPay
      state.payDate = action.payload.payDate
      state.payPeriodStart = action.payload.payPeriodStart
      state.payPeriodEnd = action.payload.payPeriodEnd
      state.iconList = action.payload.iconLinks
    },
  },
})
export const selectPaycheckTitle = (state) => state.myPaycheck.title
export const selectPaycheckNetPay = (state) => state.myPaycheck.netPay
export const selectPaycheckPayDate = (state) => state.myPaycheck.payDate
export const selectPaycheckPayPeriodStart = (state) =>
  state.myPaycheck.payPeriodStart
export const selectPaycheckPayPeriodEnd = (state) =>
  state.myPaycheck.payPeriodEnd
export const selectIconList = (state) => state.myPaycheck.iconList

export const selectPaycheckStatus = (state) => state.myPaycheck.status

export default paycheckSlice.reducer
