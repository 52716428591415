import { makeStyles, createStyles } from '@material-ui/styles'

const ReorderFavoriteAppsListStyles = makeStyles((theme) =>
  createStyles({
    padding: {
      display: 'block',
      paddingTop: 0,
      paddingBottom: 0,
    },
    reorderFavoritesButton: {
      marginRight: 8,
      [theme.breakpoints.down('sm')]: {
        marginRight: 0,
      },
    },
    reorderButton: {
      color: `${theme.palette.components.topBarIcon} !important`,
    },
  }),
)
export default ReorderFavoriteAppsListStyles
