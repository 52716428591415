/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchFlexibleWork = createAsyncThunk(
  'fetchFlexibleWork',
  async () => {
    // this is what we need when we add authentication back
    const jwtToken = localStorage.getItem('myrJwtToken')
    const rcpid = localStorage.getItem('rcpid')
    const jobs = await axios.get(process.env.REACT_APP_FLEXIBLEWORK_ENDPOINT, {
      headers: {
        JWT: jwtToken,
        XWT: rcpid,
      },
    })
    return jobs.data
  },
)

const requestChange =
  window.location.hostname === 'my.rutgers.edu'
    ? 'https://cas.rutgers.edu/login?service=https://riashrms.rutgers.edu/psp/HRMS1/EMPLOYEE/HRMS/c/RU_CUSTOM_MENU.RU_HR_SS_FWA_VIEW.GBL?PORTALPARAM_PTCNAV=RU_HR_SS_FWA_VIEW&EOPP.SCNode=HRMS&EOPP.SCPortal=EMPLOYEE&EOPP.SCName=CO_EMPLOYEE_SELF_SERVICE&EOPP.SCLabel=Self%20Service&EOPP.SCPTfname=CO_EMPLOYEE_SELF_SERVICE&FolderPath=PORTAL_ROOT_OBJECT.CO_EMPLOYEE_SELF_SERVICE.RU_HR_SS_FWA_VIEW&IsFolder=false'
    : 'https://ptest1-riashrms.rutgers.edu/psp/DANTANNA/EMPLOYEE/HRMS/c/RU_CUSTOM_MENU.RU_HR_SS_FWA_VIEW.GBL?FolderPath=PORTAL_ROOT_OBJECT.CO_EMPLOYEE_SELF_SERVICE.RU_HR_SS_FWA_VIEW&IsFolder=false&IgnoreParamTempl=FolderPath%2cIsFolder'

const approveAssign =
  window.location.hostname === 'my.rutgers.edu'
    ? 'https://cas.rutgers.edu/login?service=https://riashrms.rutgers.edu/psp/HRMS1/EMPLOYEE/HRMS/c/RU_CUSTOM_MENU.RU_HR_FWA_MGR_LST.GBL?PORTALPARAM_PTCNAV=RU_HR_FWA_MGR_LST_GBL&EOPP.SCNode=HRMS&EOPP.SCPortal=EMPLOYEE&EOPP.SCName=CO_MANAGER_SELF_SERVICE&EOPP.SCLabel=Manager%20Self%20Service&EOPP.SCPTfname=CO_MANAGER_SELF_SERVICE&FolderPath=PORTAL_ROOT_OBJECT.CO_MANAGER_SELF_SERVICE.RU_HR_FWA_MGR_LST_GBL&IsFolder=false'
    : 'https://ptest1-riashrms.rutgers.edu/psp/DANTANNA/EMPLOYEE/HRMS/c/RU_CUSTOM_MENU.RU_HR_FWA_MGR_LST.GBL?FolderPath=PORTAL_ROOT_OBJECT.CO_MANAGER_SELF_SERVICE.RU_HR_FWA_MGR_LST_GBL&IsFolder=false&IgnoreParamTempl=FolderPath%2cIsFolder'

const policy = 'https://uhr.rutgers.edu/future-of-work/home'

const initialState = {
  status: 'idle',
  data: {
    title: 'My Flexible Work',
    information: {},
    links: [requestChange, approveAssign, policy],
  },
}

const flexibleWorkSlice = createSlice({
  name: 'flexibleWorkWidget',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchFlexibleWork.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchFlexibleWork.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchFlexibleWork.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      const filteredPayload = action.payload?.jobs?.filter(
        (job) => job.requestStatus !== 'WDRN',
      )
      filteredPayload?.sort((a, b) => b.requestNumber - a.requestNumber)
      if (filteredPayload) {
        state.data.information = {
          ...action.payload,
          jobs: [filteredPayload[0]],
        }
      } else {
        state.data.information = { ...action.payload, jobs: filteredPayload }
      }
    },
  },
})

export const selectFlexibleWorkData = (state) => state.flexibleWork.data
export const selectFlexibleWorkStatus = (state) => state.flexibleWork.status

export default flexibleWorkSlice.reducer
