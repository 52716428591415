/* eslint-disable react/no-array-index-key */
import * as React from 'react'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { Card, Typography as Text, Grid, Link } from '@material-ui/core'

import { AnnouncementObjectStyles } from './itServiceCenterStyles'
import { logDashboardEvent } from '../../events'

/**
 * Announcement Object shows announcements in the IT Service Center widget
 * @param announcement the announcement object with title and link traits
 */
function Announcement({ announcements, widgetId, dispatch }) {
  const handleLinkClick = (link, title) => {
    if (widgetId && dispatch) {
      logDashboardEvent(dispatch, 'dashboardLinkEvent', widgetId, title, link)
    }
  }
  const classes = AnnouncementObjectStyles()
  dayjs.extend(localizedFormat)
  return (
    <>
      {announcements.length === 0 ? (
        <Text className={classes.noCaseText}>No Announcements</Text>
      ) : (
        <>
          {announcements.map((announcement, index) => (
            <Card
              className={classes.announcementCardBackground}
              border={1}
              key={index}
              variant="outlined"
            >
              <Grid container justifyContent="flex-start" direction="column">
                <Grid item className={classes.gridItem}>
                  <Text variant="subtitle1">
                    {dayjs(announcement.date).format('LLL')}
                  </Text>
                </Grid>

                <Grid item>
                  <Text variant="subtitle2">
                    <Link
                      href={announcement.link}
                      onClick={() =>
                        handleLinkClick(announcement.link, announcement.title)
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      color="primary"
                    >
                      {announcement.title}
                    </Link>
                  </Text>
                </Grid>
              </Grid>
            </Card>
          ))}
        </>
      )}
    </>
  )
}

export default Announcement
