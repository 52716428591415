import * as React from 'react'

import { Grid, Box, IconButton } from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'
import ReorderFavoriteAppsListIconButton from '../../../features/apps/AppComponents/ReorderFavoriteAppsList/ReorderFavoriteAppsListIconButton'
import AppSearchBar from '../../../features/apps/AppComponents/AppSearchBar/AppSearchBar'
import { LogOutButton } from './DashboardLocalButtons'

// TODO Add back sensitive info toggle (Should do with login updates)
export default function MyAppsLocalButtons(
  classes,
  isMobile,
  history,
  dispatch,
  userProfile,
) {
  return (
    <>
      <Grid item sm={9} md={4}>
        <Grid
          container
          justifyContent={isMobile ? 'flex-start' : 'flex-end'}
          alignItems="center"
        >
          <Grid>
            <Box display="flex">
              <ReorderFavoriteAppsListIconButton />
            </Box>
          </Grid>
          {!isMobile && (
            <Grid item className={classes.searchBarContainer} md={6}>
              <AppSearchBar />
            </Grid>
          )}
          {isMobile && (
            <Grid item xs={1}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="Settings Menu"
                onClick={() => history.push('/settings')}
              >
                <SettingsIcon className={classes.appBarIcon} />
              </IconButton>
            </Grid>
          )}
          <LogOutButton
            isMobile={isMobile}
            dispatch={dispatch}
            userProfile={userProfile}
            classes={classes}
          />
        </Grid>
      </Grid>
    </>
  )
}
