import React from 'react'
import {
  Typography as Text,
  IconButton,
  AppBar,
  Toolbar,
} from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import useNearesetStopsMobileStyles from './nearestStopsMobileStyles'
import { resetMap } from '../../sections/Map/redux/myMapsSlice'
import {
  resetBuilding,
  resetParking,
} from '../../sections/Buildings/redux/myBuildingsSlice'
import NearestBusStops from '../../sections/Buildings/components/NearestBusStops/NearestBusStops'

function NearesetStopsMobile() {
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useNearesetStopsMobileStyles()

  const { selectedBuilding } = useSelector((s) => s.buildings)

  const exitPage = () => {
    dispatch(resetMap())
    dispatch(resetBuilding())
    dispatch(resetParking())
    history.goBack()
  }

  return (
    <div className={classes.container}>
      <AppBar className={classes.appBar} position="fixed" elevation={2}>
        <Toolbar className={classes.container}>
          <IconButton onClick={() => exitPage()}>
            <ArrowBackIosIcon className={classes.mobileBackButtonIcon} />
          </IconButton>
          <Text className={classes.pageTitle}>
            {`Stops Near ${selectedBuilding.name}`}{' '}
          </Text>
        </Toolbar>
      </AppBar>

      <div style={{ marginTop: 55 }}>
        <NearestBusStops />
      </div>
    </div>
  )
}

export default NearesetStopsMobile
