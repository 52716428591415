import { makeStyles, createStyles } from '@material-ui/styles'

export const useNewsSourceListDropDownStyles = makeStyles((theme) =>
  createStyles({
    appBarIcon: {
      color: `${theme.palette.components.topBarIcon} !important`,
      fontSize: 24,
    },
    alertMenu: {
      marginTop: 3,
      marginLeft: 5,
    },
    flex: {
      display: 'flex',
    },
    checkCircleOutline: {
      color: theme.palette.base.green,
      marginLeft: 20,
    },
    text: {
      fontSize: 18,
      marginLeft: 8,
    },
  }),
)

export default useNewsSourceListDropDownStyles
