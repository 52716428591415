import { makeStyles } from '@material-ui/core/styles'

export const useNewsSourceListStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  listItemBody: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginTop: 8,
  },
  listItemText: {
    color: theme.palette.primary.headerText,
  },
}))

export default useNewsSourceListStyles
