import { makeStyles } from '@material-ui/core/styles'

// Any time that we would like to add CSS to our Components we will create a styles object. The syntax is different then normal CSS. Please notice that camel case is used to set the list-style-type. For your reference please visit https://v4.mui.com
export const adminWidgetStyles = makeStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'wrap',
  },
  categoriesList: {
    listStyleType: 'none',
    padding: '0px',
  },
  fields: {
    fontWeight: 500,
    marginRight: 8,
    fontSize: 15,
  },
  values: {
    fontSize: 14,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    display: 'flex',
    padding: 8,
    margin: 4,
  },
  odd: {
    backgroundColor: '#ffffff',
  },
  even: {
    backgroundColor: '#f7f7f7',
  },
}))

export default adminWidgetStyles
