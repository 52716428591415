import React from 'react'
import { useHistory } from 'react-router-dom'
import { Typography as Text, IconButton, Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import RestaurantIcon from '@material-ui/icons/Restaurant'
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus'
import RoomIcon from '@material-ui/icons/Room'
import LocalParkingIcon from '@material-ui/icons/LocalParking'
import {
  fetchNearestStops,
  // setZoom,
  openSearchBarToggle,
  setLatLang,
  updateBottomSheet,
} from '../../../sections/Map/redux/myMapsSlice'
import {
  fetchRestaurants,
  handleNearestStopClicked,
  resetBuildingSlice,
  // fetchParkingNearBuilding,
  setSelectedBuilding,
} from '../../../sections/Buildings/redux/myBuildingsSlice'
import { bottomSheetBuildingStyles } from './bottomSheetBuildingStyles'
import { logMapEvent } from '../../../utils/events'
import {
  DIRECTIONSMAP,
  NEARESTSTOP,
  NEARESTSTOPSBUTTON,
  RESTAURANT,
} from '../../../utils/constants'
import CheckIsMobile from '../../../../../../utils/isMobileHook'

// Still need to update this component to use the BottomSheet
function BottomSheetBuilding({ setSheetConfig }) {
  const history = useHistory()
  const isMobile = CheckIsMobile()
  const classes = bottomSheetBuildingStyles()
  const dispatch = useDispatch()
  const { buildings, selectedBuildingID, selectedBuilding } = useSelector(
    (s) => s.buildings,
  )
  const [imageSource, updateImageSource] = React.useState('')

  React.useEffect(() => {
    const sheetConfig = {
      title: '',
      sheetColor: '',
      displayBackButton: true,
      displayCloseButton: true,
      backButtonFunc,
    }
    setSheetConfig(sheetConfig)
  }, [])

  const backButtonFunc = {
    myFunction() {
      dispatch(resetBuildingSlice())
      dispatch(openSearchBarToggle())
    },
  }

  // Get Building Data and Organize
  React.useEffect(() => {
    let building = {
      address: '',
      bid: '',
      city: '',
      departments: '',
      lat: '',
      lng: '',
      name: '',
      picture: '',
      zip: '',
    }
    for (let i = 0; i < buildings.length; i++) {
      if (buildings[i].bid === selectedBuildingID) {
        building = buildings[i]
        break
      }
    }
    // Add stuff here
    dispatch(setSelectedBuilding(building))
    dispatch(setLatLang({ lat: building.lat, lang: building.lng, zoom: 18 }))
  }, [selectedBuildingID])

  const getBuildingInfo = () => {
    logMapEvent(dispatch, DIRECTIONSMAP, selectedBuilding.name)
    const start = 'https://maps.rutgers.edu/#/?lat='
    const mid = '&selected='
    const end = '&sidebar=true&zoom=21'
    const link = start
      .concat(selectedBuilding.lat)
      .concat('&lng=')
      .concat(selectedBuilding.lng)
      .concat(mid)
      .concat(selectedBuilding.bid)
      .concat(end)
    window.open(link)
  }

  const getRestaurantData = () => {
    const coord = {
      lat: selectedBuilding.lat,
      lng: selectedBuilding.lng,
    }
    dispatch(fetchRestaurants(coord))
    dispatch(updateBottomSheet(RESTAURANT))
  }

  const getParkingInfo = () => {
    // Don't use API for beta release
    // dispatch(setZoom(16))
    // dispatch(fetchParkingNearBuilding())
    logMapEvent(dispatch, DIRECTIONSMAP, selectedBuilding.name)
    const start = `${process.env.REACT_APP_MAPS_PARKING}/#/?click=true&lat=`
    const mid = '&selected='
    const end = '&sidebar=true&zoom=18&parking=true'
    const link = start
      .concat(selectedBuilding.lat)
      .concat('&lng=')
      .concat(selectedBuilding.lng)
      .concat(mid)
      .concat(selectedBuilding.bid)
      .concat(end)
    window.open(link, '_blank')
  }

  const getNearestBusStops = () => {
    logMapEvent(dispatch, NEARESTSTOPSBUTTON, selectedBuilding.name)
    if (isMobile) {
      history.push('/NearestStopsMobile')
    }
    dispatch(setSelectedBuilding(selectedBuilding))
    dispatch(fetchNearestStops(selectedBuildingID))
    dispatch(handleNearestStopClicked(selectedBuildingID))
    dispatch(updateBottomSheet(NEARESTSTOP))
  }

  React.useEffect(() => {
    if (
      selectedBuilding &&
      selectedBuilding.picture &&
      selectedBuilding.picture.length > 0
    ) {
      updateImageSource(selectedBuilding.picture)
    }
  }, [selectedBuilding])

  const handleImageError = () => {
    updateImageSource('./assets/images/building-placeholder.png')
  }

  function concatBuildingDetails(b) {
    const addressParts = []

    // Check if 'address' exists, then append it to addressParts
    if (b.address) {
      addressParts.push(b.address)
    }

    // Check if 'city' exists, then append it to addressParts
    if (b.city) {
      addressParts.push(addressParts.length > 0 ? `, ${b.city}` : b.city)
    }

    // Check if 'zip' exists, then append it to addressParts
    if (b.zip) {
      addressParts.push(addressParts.length > 0 ? ` ${b.zip}` : b.zip)
    }
    return addressParts.join('')
  }

  return (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <Grid item xs={5} className={classes.imgContainer}>
        <img
          className={classes.img}
          src={imageSource}
          onError={handleImageError}
          alt="img"
        />
      </Grid>

      <Grid item xs={7} className={classes.descriptionContainer}>
        <Grid
          container
          direction="column"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Grid item>
            <Grid container alignItems="center">
              <Grid item>
                <Text className={classes.buildingNameTextHeader}>
                  Building Name:
                </Text>
              </Grid>
              <Grid item>
                <Text className={classes.buildingNameText}>
                  {selectedBuilding ? selectedBuilding.name : ''}
                </Text>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Text className={classes.addressText}>
              {concatBuildingDetails(selectedBuilding)}
            </Text>
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.bottomButtonRow}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item>
            <IconButton
              edge="start"
              className={classes.icon}
              color="inherit"
              aria-label="get nearest stops"
              onClick={() => getNearestBusStops()}
            >
              <DirectionsBusIcon className={classes.icon} />
            </IconButton>
          </Grid>

          <Grid item>
            <IconButton
              edge="start"
              className={classes.icon}
              color="inherit"
              aria-label="get building information"
              onClick={getBuildingInfo}
            >
              <RoomIcon className={classes.icon} />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              edge="start"
              className={classes.icon}
              color="inherit"
              aria-label="get parking info"
              onClick={getParkingInfo}
            >
              <LocalParkingIcon className={classes.icon} />
            </IconButton>
          </Grid>

          <Grid item>
            <IconButton
              edge="start"
              className={classes.icon}
              color="inherit"
              aria-label="get nearest restaurants"
              onClick={getRestaurantData}
            >
              <RestaurantIcon className={classes.icon} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default BottomSheetBuilding
