/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchDiningData = createAsyncThunk('fetchDiningData', async () => {
  // headers needed to make calls
  const jwtToken = localStorage.getItem('myrJwtToken')
  const rcpid = localStorage.getItem('rcpid')
  const res = await axios.get(`${process.env.REACT_APP_DINING_HALL_ENDPOINT}`, {
    headers: {
      JWT: jwtToken,
      XWT: rcpid,
    },
  })
  return {
    data: res.data,
  }
})

const initialState = {
  status: 'pending',
  data: [],
  tabValue: 0,
}

const diningSlice = createSlice({
  name: 'dining',
  initialState,
  reducers: {
    updateTabValue: (state, action) => {
      state.tabValue = action.payload
    },
  },
  extraReducers: {
    [fetchDiningData.pending]: (state) => ({
      ...state,
      status: 'pending',
    }),
    [fetchDiningData.fulfilled]: (state, action) => {
      return {
        ...state,
        data: action.payload.data,
        status: 'fulfilled',
      }
    },
    [fetchDiningData.rejected]: (state) => ({
      ...state,
      status: 'error',
    }),
  },
})

export const { updateTabValue } = diningSlice.actions

export default diningSlice.reducer
