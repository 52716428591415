import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'
import { nearestBusStopsStyles } from './nearestBusStopsStyles'
import { handleNearStopsTabValue } from '../../../Map/redux/myMapsSlice'
import { a11yProps } from '../../../../../dashboard/DashboardComponents/TabPanel/TabPanel'

function NearestBusStopsTabs(props) {
  const { tabs } = props
  const classes = nearestBusStopsStyles()
  const dispatch = useDispatch()
  const { nearestStopsTabValue } = useSelector((s) => s.maps)

  React.useEffect(() => {
    dispatch(handleNearStopsTabValue(0))
  }, [])

  const handleChange = (event, newValue) => {
    dispatch(handleNearStopsTabValue(newValue))
  }
  return (
    <Box className={classes.rootTabBox}>
      <Tabs
        value={nearestStopsTabValue}
        onChange={handleChange}
        className={classes.rootTabContainer}
        aria-label="bus-stops tabs"
        variant="fullWidth"
      >
        {/* Tabs should be props that each widget should create manually, to style and make calls */}
        {tabs.map((tab, index) => (
          <Tab
            key={tab.stopId}
            label={`Stop ${index + 1}`}
            {...a11yProps(index)}
          />
        ))}
      </Tabs>
    </Box>
  )
}

export default NearestBusStopsTabs
