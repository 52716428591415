/* eslint-disable react/no-array-index-key */
import * as React from 'react'

import ItemCard from './ItemCard'
import PolicyStyles from './policyStyles'

import NoItemFound from './NoItemFound'

/**
 * Announcement Object shows announcements in the Faculty/Staff Service Center widget
 * @param announcement the announcement object with title and link traits
 */

function ItemList({ dispatch, widgetId, data, dataSelect }) {
  const classes = PolicyStyles()
  // check if received data has objects with key defined in [dataselect]
  // if so render the list if not return no annuncement ui
  const dataHasItems = data.map((announcement) =>
    JSON.parse(announcement[dataSelect]),
  )

  return (
    <>
      {dataHasItems.some(Boolean) ? (
        data.map(
          (announcement) =>
            JSON.parse(announcement[dataSelect]) && (
              <ItemCard
                item={announcement}
                widgetId={widgetId}
                dispatch={dispatch}
              />
            ),
        )
      ) : (
        <NoItemFound classes={classes} />
      )}
    </>
  )
}

export default ItemList
