import { makeStyles, createStyles } from '@material-ui/core/styles'

const useFavoritesPageStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginBottom: 70,
      background: theme.palette.components.newsBackground,
      height: '85vh',
      [theme.breakpoints.down('sm')]: {
        height: 'auto',
      },
    },
    favoritesRoot: {
      background: theme.palette.components.newsBackground,
    },
    container: {
      flex: 1,
      backgroundColor: 'red',
      overflow: 'auto',
      overscrollBehavior: 'contain',
      width: '100%',
      height: '100%',
    },
    main: {
      height: '100%',
      width: '100%',
    },
    appHeaderTextContainer: {
      paddingTop: '20px',
    },
    popularTextContainer: {
      marginBottom: '10px',
    },
    desktopContainer: {
      backgroundColor: theme.palette.background.paper,
      padding: '20px',
      paddingBottom: '25px',
      margin: '20px',
      borderRadius: '18px',
    },
    appContainer: {
      height: '260px',
      overflowY: 'hidden',
    },
    favoritesContainer: {
      marginTop: '-10px',
      paddingBottom: '30px',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
  }),
)

export default useFavoritesPageStyles
