/* eslint-disable no-unused-vars */
/* eslint-disable react/no-danger */
import React from 'react'
import DOMPurify from 'dompurify'
import {
  Typography as Text,
  Grid,
  Toolbar,
  AppBar,
  IconButton,
  Box,
} from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import {
  fetchMyApps,
  selectAppCategories,
  selectAppCategoriesWithApps,
  selectDisplaySearchItems,
  selectForYouApps,
  selectIsGuest,
  selectMyAppsCallStatus,
  selectSearchBarAppList,
  selectMyFavoriteAppsList,
} from '../../../appSlice'
import ShowAppList from '../../../AppComponents/ShowAppList/ShowAppList'
import InfoPageStyles from './InfoPageStyles'
import { getImageFromFirebaseWithHook } from '../../../helpers'
import SetNumOfAppAltPerRow from '../../../AppComponents/ShowAppList/NumOfAppAltPerRowHook'
import SetCarouselDimensions from '../../../AppComponents/AppCarouselCombined/AppCarouselDimensions'
import AppCarouselCombined from '../../../AppComponents/AppCarouselCombined/AppCarouselCombined'
import CheckIsMobile from '../../../../../../utils/isMobileHook'
/**
 * Category page from All Apps > Category
 * Shows category description, popular applications, and all applications under the category
 */
export const InfoPage = () => {
  const classes = InfoPageStyles()
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()
  const isMobile = CheckIsMobile()
  const numAltApps = SetNumOfAppAltPerRow()
  const dimensions = SetCarouselDimensions()
  const [appCarousel, setAppCarousel] = React.useState(null)
  const [categoryName, setCategoryName] = React.useState('')
  const [categoryDesc, setCateogryDesc] = React.useState('')
  const [img, setImg] = React.useState('./assets/images/image-not-found.png')
  const [appsList, setAppsList] = React.useState([])
  const [popularForYouApps, setPopularForYouApps] = React.useState([])

  const appCategory = useSelector(selectAppCategories)
  const appCategoriesWithApps = useSelector(selectAppCategoriesWithApps)
  const forYouApps = useSelector(selectForYouApps)
  const displaySearchItems = useSelector(selectDisplaySearchItems)
  const searchBarAppList = useSelector(selectSearchBarAppList)
  const status = useSelector(selectMyAppsCallStatus)
  const favoriteList = useSelector(selectMyFavoriteAppsList)
  const isGuest = useSelector(selectIsGuest)

  const query = location.search
  const type = new URLSearchParams(query).get('type')
  let id = new URLSearchParams(query).get('id')
  id = decodeURIComponent(id)
  // let popularForYouApps = []

  const createPage = async () => {
    if (status === 'idle' || status === 'pending') {
      dispatch(fetchMyApps())
    } else {
      let description = null
      let title = null
      let image = null
      let appList = null
      if (type.toLowerCase() === 'foryou') {
        let pageContent = forYouApps
          .map((apps) =>
            apps?.fragmentList?.filter((item) => item.categoryName === id),
          )
          .filter((items) => items.length > 0)
        // eslint-disable-next-line
        pageContent = pageContent[0][0]
        description = pageContent.description
        title = pageContent.categoryName
        image = pageContent.imageUrl
        appList = pageContent.associatedApps
        // most popular ForYou Apps - Remove duplicates
        const allPopularApps = appList
          ?.map((app) => app.apps?.map((arrayAssc) => arrayAssc))
          .flat()
          .filter((app) => app.active === true)
        const allPopularAppsWithoutDuplicates = Array.from(
          new Set(allPopularApps?.map(JSON.stringify)),
        ).map(JSON.parse)
        const allPopularAppsWithoutDuplicatesSorted =
          allPopularAppsWithoutDuplicates.sort(
            (firstItem, secondItem) =>
              secondItem.clickThru - firstItem.clickThru,
          )
        // needs to be in state
        setPopularForYouApps(allPopularAppsWithoutDuplicatesSorted)
      } else {
        const category = appCategory.filter((c) => c.id === id)[0]
        appList = appCategoriesWithApps.filter(
          (c) => c.name === category.name,
        )[0].apps
        title = category.name
        description = category.desc
        image = category.image
      }
      setAppsList(appList)
      createCarousel(title, appList, type)
      setCategoryName(title)
      setCateogryDesc(description)
      if (image) {
        getImageFromFirebaseWithHook(image, setImg)
      }
    }
  }

  // Need to fetch data because if url is sent to someone, page doesn't have data yet
  React.useEffect(() => {
    createPage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, dimensions, favoriteList])
  const createCarousel = (title, apps) => {
    let carousel = ''
    if (type === 'ForYou') {
      carousel = apps.map((app) => (
        <AppCarouselCombined
          key={app.appFolderDescription}
          appList={app.apps}
          carouselHeader={app.appFolderDescription}
          category={title}
          dimensions={dimensions}
          isGuest={isGuest}
          isMobile={isMobile}
          showCategoryText
        />
      ))
    } else {
      carousel = (
        <AppCarouselCombined
          key={title}
          appList={apps}
          carouselHeader={'Other Apps In '.concat(title)}
          category={title}
          dimensions={dimensions}
          isGuest={isGuest}
          isMobile={isMobile}
          showCategoryText
        />
      )
    }
    setAppCarousel(carousel)
  }

  return displaySearchItems ? (
    <div className={classes.container}>
      <Toolbar />
      <ShowAppList isMobile={false} inputAppList={searchBarAppList} />
    </div>
  ) : (
    <div className={classes.container}>
      {isMobile ? (
        <AppBar className={classes.appBar} position="relative" elevation={2}>
          <Toolbar className={classes.appBarContainer}>
            <Grid container justifyContent="flex-start" alignItems="center">
              <Grid item sm={4}>
                <IconButton onClick={() => history.goBack()}>
                  <ArrowBackIosIcon className={classes.mobileBackButtonIcon} />
                </IconButton>
              </Grid>
              <Grid item sm={4} className={classes.pageTitleContainer}>
                <Text className={classes.pageTitle}>{categoryName}</Text>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      ) : null}
      <Grid container className={classes.headerContainer}>
        {!isMobile ? (
          <Box display="flex" alignItems="flex-start">
            <IconButton onClick={() => history.goBack()}>
              <ArrowBackIosIcon className={classes.mobileBackButtonIcon} />
            </IconButton>
          </Box>
        ) : null}
        <img className={classes.img} src={img} alt="img" />

        <Grid item xs>
          <Grid
            container
            direction="column"
            className={classes.categoryTitleContainer}
            justifyContent="center"
          >
            <Grid item style={{ marginBottom: 8 }}>
              <Text className={classes.categoryTitle}>{categoryName}</Text>
            </Grid>

            <Grid item>
              <Text className={classes.categoryDescription}>
                <div
                  // eslint-disable-next-line react/no-danger
                  className={classes.descriptionStyle}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(categoryDesc, {
                      ADD_ATTR: ['target'],
                    }),
                  }}
                />
              </Text>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        alignItems="center"
        justifyContent="space-evenly"
        className={classes.appContainer}
      >
        <ShowAppList
          inputAppList={
            type === 'ForYou'
              ? popularForYouApps?.slice(0, numAltApps)
              : appsList.slice(0, numAltApps)
          }
          appAltList
          appAltListTitle={{
            showTitle: true,
            title: `Popular Apps in ${categoryName}`,
          }}
          showListEmptyComponent={false}
        />
      </Grid>
      <>{appCarousel}</>
    </div>
  )
}

export default InfoPage
