/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchMyAlerts = createAsyncThunk('fetchMyAlerts', async () => {
  const jwtToken = localStorage.getItem('myrJwtToken')
  const rcpid = localStorage.getItem('rutgersEduRCPID')
  const res = await axios.all([
    axios.get(process.env.REACT_APP_MYALERTS_ENDPOINT, {
      headers: {
        JWT: jwtToken,
        XWT: rcpid,
      },
    }),
    axios.get(
      `${process.env.REACT_APP_MYALERTSHISTORY_ENDPOINT}${
        window.location.hostname === 'localhost' ? '' : `?rcpid=${rcpid}`
      }`,
      {
        headers: {
          JWT: jwtToken,
          XWT: rcpid,
        },
      },
    ),
  ])
  // eslint-disable-next-line no-unused-vars
  const personalAlerts = [
    ...res[0].data?.personalAlerts,
    ...res[0].data?.publishedAlerts,
  ]
  const alertHistory = [
    ...res[1].data?.personalAlerts,
    ...res[1].data?.publishedAlerts,
    ...res[1].data?.itAlerts,
    // ...res[1].data?.refundAlerts,
  ]
  return {
    emergencyAlerts: res[0].data.emergencyAlerts,
    personalAlerts,
    notificationAlerts: [
      ...res[0].data?.emergencyAlerts,
      ...res[0].data?.personalAlerts,
      ...res[0].data?.publishedAlerts?.filter(
        (publishedalert) => publishedalert.severityLevel !== 'low',
      ),
      // ...res[0].data?.refundAlerts,
    ],
    itAlerts: res[0].data?.itAlerts,
    alertHistory: alertHistory?.sort((a, b) => {
      // Function to extract the timestamp from a given key or return null if the key is not found
      const extractTimestamp = (obj) =>
        obj.start_date || obj.sent_date || obj.lastUpdated

      // Extract timestamps from the objects
      const timestampA = extractTimestamp(a)
      const timestampB = extractTimestamp(b)

      // Compare timestamps as strings in reverse order
      return timestampB.localeCompare(timestampA)
    }),
    // refundAlerts: res[0].data?.refundAlerts,
  }
})

export const acknowledgeAlert = createAsyncThunk(
  'acknowledgeAlert',
  async (alert) => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const rcpid = localStorage.getItem('rutgersEduRCPID')
    if (window.location.hostname !== 'localhost') {
      const { dockey } = alert
      await axios.post(
        `${process.env.REACT_APP_MYALERTS_ACKNOWLEDGE}?dockey=${dockey}`,
        {},
        {
          headers: {
            JWT: jwtToken,
            XWT: rcpid,
          },
        },
      )
    }
    let url = process.env.REACT_APP_MYALERTS_ENDPOINT
    if (window.location.hostname === 'localhost') {
      url = process.env.REACT_APP_MYALERTS_POST_ENDPOINT
    }
    const res = await axios.get(url, {
      headers: {
        JWT: jwtToken,
        XWT: rcpid,
      },
    })
    return res.data
  },
)

const initialState = {
  status: 'idle',
  data: {
    title: 'My Alerts',
    categories: ['Active', 'History'],
    alerts: [],
  },
  ref: false,
  loaded: false,
  refresh: false,
  notificationsDialogOpen: false,
  alertIndex: 0,
  snackbarOpen: true,
  alertsHistoryOpen: false,
}

const myAlertsSlice = createSlice({
  name: 'myAlerts',
  initialState,
  reducers: {
    focusAlert: (state) => {
      state.ref = !state.ref
    },
    resetRefresh: (state) => {
      state.refresh = false
    },
    updateNotificationsDialogOpen: (state) => {
      state.notificationsDialogOpen = !state.notificationsDialogOpen
    },
    updateAlertIndex: (state, action) => {
      state.alertIndex = action.payload
    },
    updateSnackbarOpen: (state, action) => {
      state.snackbarOpen = action.payload
    },
    updateAlertsHistoryOpen: (state) => {
      state.alertsHistoryOpen = !state.alertsHistoryOpen
    },
  },
  extraReducers: {
    [fetchMyAlerts.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchMyAlerts.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchMyAlerts.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      // categories no longer returned from API so merge with initialState
      state.data.alerts = action.payload
    },
    [acknowledgeAlert.fulfilled]: (state, action) => {
      state.data.alerts = {
        emergencyAlerts: action.payload?.emergencyAlerts,
        personalAlerts: [
          ...action.payload?.emergencyAlerts,
          ...action.payload?.personalAlerts,
          ...action.payload?.publishedAlerts,
        ],
        notificationAlerts: action.payload?.publishedAlerts?.filter(
          (publishedalert) => publishedalert?.severityLevel !== 'low',
        ),
        itAlerts: action.payload?.itAlerts,
        // refundAlerts: action.payload?.refundAlerts,
        alertHistory: action.payload?.alertHistory,
      }
      // state.alertIndex = 0
      state.alertAcknowledgeStatus = 'fulfilled'
    },
  },
})

export const {
  focusAlert,
  widgetLoaded,
  resetRefresh,
  updateNotificationsDialogOpen,
  updateAlertIndex,
  updateAlertsHistoryOpen,
  updateSnackbarOpen,
} = myAlertsSlice.actions

export const selectMyAlertsData = (state) => state.myAlerts.data
export const selectMyAlertsStatus = (state) => state.myAlerts.status
export const selectMyAlertsRef = (state) => state.myAlerts.ref
export const selectMyAlertsRefresh = (state) => state.myAlerts.refresh

export default myAlertsSlice.reducer
