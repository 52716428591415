import { makeStyles, createStyles } from '@material-ui/core/styles'

const AllAppsPageStyles = makeStyles((theme) =>
  createStyles({
    root: {
      paddingBottom: 50,
      background: theme.palette.components.newsBackground,
    },
  }),
)

export default AllAppsPageStyles
