import { makeStyles } from '@material-ui/core/styles'

export const useGenericArticleStyles = makeStyles((theme) => ({
  genericArticleContainer: {
    borderBottom: `2px solid ${theme.palette.backgroundColor.secondary}`,
    position: 'relative',
    margin: '10px 0',
    height: '90px',
  },
  card: {
    backgroundColor: 'transparent',
  },
  articleContainer: {
    padding: '5px 10px',
  },
  secondRow: {
    paddingTop: '5px',
  },
  articleTitle: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 2,
    wordBreak: 'keep-all',
    overflow: 'hidden',
    fontSize: '18px',
    [theme.breakpoints.down('md')]: {
      fontSize: '15px',
    },
    fontWeight: 500,
    color: theme.palette.primary.headerText,
    textAlign: 'left',
    marginTop: 8,
  },
  sponsorLink: {
    position: 'absolute',
    left: '2px',
    zIndex: 99,
    textDecoration: 'none',
    '&:hover': {
      background: '#ffffff',
    },
    textAlign: 'bottom',
  },
  sponsorLinkText: {
    color: theme.palette.base.red,
    fontSize: '16px',
    [theme.breakpoints.down('md')]: {
      fontSize: '13px',
    },
    fontWeight: 600,
    paddingTop: 2,
    letterSpacing: 1,
  },
  fakedSponserLinkSpace: {
    height: '35px',
  },
  timeElapsedText: {
    fontSize: '14px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
    fontWeight: 400,
    // color: theme.palette.secondary.headerText,
    paddingBottom: '3px',
    textAlign: 'right',
  },
  button: {
    backgroundColor: theme.color.rutgersRed,
    '&:hover': {
      backgroundColor: '#B00020',
    },
  },
  buttonText: {
    color: theme.color.white,
  },
  buttonContainer: {
    position: 'relative',
    paddingBottom: '8px',
  },
}))

export default useGenericArticleStyles
