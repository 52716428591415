import * as React from 'react'

import Grid from '@material-ui/core/Grid'

import Widget from '../../Widget'
// eslint-disable-next-line no-unused-vars
function Widget111({ className, title, widgetId }) {
  return (
    <Widget className={className}>
      <Widget.Header title={title} category="default" />
      <Widget.Content>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item></Grid>
        </Grid>
      </Widget.Content>
    </Widget>
  )
}

export default Widget111
