import React from 'react'
import { Box, useMediaQuery } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { MapTabsMobile } from '../MapTabsMobile/MapTabsMobile'
import MapSearchBar from '../../components/Search/MapSearchBar'
import { NEARESTSTOP } from '../../utils/constants'
import NearestBusStops from '../../sections/Buildings/components/NearestBusStops/NearestBusStops'

// For mobile view, overlay the tab panel on top of existing MapPanel component
export default function MapPanelMobile() {
  const mapPanels = ['Bus', 'Buildings']
  const isDesktopView = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const { tabValue, bottomSheet, showSearchBar } = useSelector((s) => s.maps)
  return (
    <>
      {isDesktopView === false ? (
        <Box marginTop={showSearchBar ? '12px' : '56px'} width="100%">
          {(showSearchBar || tabValue === 1) && <MapSearchBar />}
          {bottomSheet.component === NEARESTSTOP && <NearestBusStops />}
          {!showSearchBar && (
            <MapTabsMobile tabs={mapPanels} TabOne={null} TabTwo={null} />
          )}
        </Box>
      ) : null}
    </>
  )
}
