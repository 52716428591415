import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) => ({
  container: {
    padding: 0,
    margin: 0,
    height: 475,
    width: 335,
    overflowY: 'clip',
    overflowX: 'hidden',
    [theme.breakpoints.down(370)]: {
      width: 287,
      height: 450,
    },
  },
  gridContainer: {
    height: '100%',
  },
  coursesContainer: {
    margin: 0,
    padding: 0,
    flex: 1,
    width: 335,
    flexWrap: 'nowrap',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  modalTab: {
    width: '335px',
    height: '35px',
    minHeight: '35px',
    [theme.breakpoints.down(370)]: {
      width: '300px',
    },
  },
  linkIcon: {
    color:
      theme.palette.type === 'light'
        ? theme.palette.base.gray
        : theme.palette.base.white,
    fontSize: '30px',
    margin: '0px',
    padding: '0px',
  },
  icon: {
    color:
      theme.palette.type === 'light'
        ? theme.palette.base.gray
        : theme.palette.base.white,
    textAlign: 'center',
    fontSize: '30px',
    '&:hover': {
      transform: 'scale(1.07)',
    },
  },
  panel: {
    height: '435px',
    overflowX: 'clip',
    overflowY: 'clip',
  },
  classroomCard: {
    margin: '5px 0px',
    padding: '5px',
  },
  courseInfoGridWhite: {},
  courseInfoGridGray: {
    backgroundColor: theme.palette.paper.secondary,
  },
  gridPadding: {
    padding: '5px',
  },
  iconButton: {
    color: theme.palette.base.red,
  },
  footerButton: {
    color: theme.palette.primary.main,
  },
  divider: {
    height: '2px',
    width: '335px',
    backgroundColor: theme.palette.base.red,
  },
  noPadding: {
    padding: '0px !important',
  },
}))

export default styles
