import { makeStyles } from '@material-ui/core/styles'

export const idleTimerStyles = makeStyles((theme) => ({
  sessionTimeoutImage: {
    width: 500,
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}))

export default idleTimerStyles
