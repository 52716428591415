import React from 'react'
import {
  Typography,
  Grid,
  Box,
  ButtonBase,
  Card,
  CardContent,
  Button,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import styles from '../settingsStyles'
import IconPicker from '../../apps/AppComponents/IconPicker/IconPicker'
import { selectDarkMode } from '../settingsSlice'

function EmergencyInformation() {
  const isDark = useSelector(selectDarkMode)
  const classes = styles()
  const history = useHistory()
  const handleMoreInfoClick = (link) => {
    window.open(link, '_blank')
  }
  return (
    <Box className={classes.centerPage}>
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.container}
        wrap="nowrap"
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.sectionTitle}
        >
          <ButtonBase
            onClick={() => history.push('/settings')}
            aria-label="Back"
          >
            <ArrowBackIos />
          </ButtonBase>
          <Typography variant="h1">
            <b>Emergency Information</b>
          </Typography>
        </Grid>
        <Grid item className={classes.fullWidth}>
          <Card className={classes.linksCard}>
            <Grid
              container
              direction="column"
              alignItems="left"
              justifyContent="flex-start"
              spacing={2}
              className={classes.copyrightCard}
            >
              <Grid item>
                <Typography variant="h2">Emergency Action Plan</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  During an emergency, proper action saves lives. For your
                  safety, please familiarize yourself with the basic steps
                  outlined in the Emergency Action Plan which pertain to
                  students, faculty and staff at all Rutgers University
                  locations.
                </Typography>
                <br />
                <Card>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    wrap="nowrap"
                    spacing={4}
                    style={{ backgroundColor: isDark ? '#424242' : '#f5f5f5' }}
                  >
                    <Grid
                      item
                      justifyContent="center"
                      alignItems="center"
                      style={{
                        marginLeft: '50px',
                      }}
                    >
                      <IconPicker
                        iconName="fa-exclamation"
                        size="3x"
                        color="#c41f32"
                      />
                    </Grid>
                    <Grid item justifyContent="flex-start">
                      <CardContent>
                        <Typography
                          variant="h6"
                          style={{ fontStyle: 'italic' }}
                        >
                          If you are experiencing a medical emergency: Dial 911
                          or call the Rutgers University Police Department
                          (RUPD) at <a href="tel:848-932-7211">848-932-7211</a>.
                          Rutgers Student Health (RSH) is not an emergency
                          medical center.
                        </Typography>
                      </CardContent>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item className={classes.fullWidth}>
          <Card className={classes.linksCard}>
            <Grid
              container
              direction="column"
              alignItems="left"
              justifyContent="flex-start"
              spacing={2}
              className={classes.copyrightCard}
            >
              <Grid item>
                <Typography variant="h2">Emergency Reporting</Typography>
              </Grid>
              <Card>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="left"
                  spacing={2}
                  style={{ backgroundColor: isDark ? '#424242' : '#f5f5f5' }}
                  className={classes.copyrightCard}
                >
                  <Grid item>
                    <Typography
                      variant="h4"
                      color="primary"
                      style={{ paddingTop: 5 }}
                    >
                      After-Hours Care
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6">
                      For after-hours or emergency medical care, please contact
                      one of the local hospitals or one of the services below.
                      If you have an emergency in need of immediate attention,
                      call 911.
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
              <br />
              <Card>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="left"
                  spacing={2}
                  style={{ backgroundColor: isDark ? '#424242' : '#f5f5f5' }}
                  className={classes.copyrightCard}
                >
                  <Grid item>
                    <Typography
                      variant="h4"
                      color="primary"
                      style={{ paddingTop: 5 }}
                    >
                      Reporting Guidelines
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6">
                      Immediately report emergency situations occurring in or
                      near your facility (such as fire, explosion, storm damage,
                      hazardous material spills, chemical odors, flooding,
                      accidents, injuries, criminal or suspicious activities,
                      etc.) to the RUPD Communication Center or by using a
                      campus emergency telephone.
                      <ul>
                        <li>
                          <Typography variant="h6">
                            Location of emergency (you should also indicate that
                            you are at Rutgers University as part of your
                            location)
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="h6">
                            Nature of the emergency
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="h6">
                            Status of the emergency condition (i.e., in progress
                            or completed)
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="h6">
                            Any immediate threat(s) to life
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="h6">
                            Need for medical or other assistance
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="h6">
                            Description, location or direction of travel of
                            suspect(s), description of motor vehicle(s)
                            involved, presence of weapons
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="h6">
                            Any danger to emergency responders
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="h6">
                            Other information that would aid the emergency
                            response
                          </Typography>
                        </li>
                      </ul>
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Card>
        </Grid>
        <Grid item className={classes.fullWidth}>
          <Card className={classes.linksCard}>
            <Grid
              container
              direction="column"
              alignItems="left"
              justifyContent="flex-start"
              spacing={2}
              className={classes.copyrightCard}
            >
              <Grid item>
                <Typography variant="h2">Non-Emergency Contacts</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  If you need to contact RUPD Communications Center regarding a
                  non-emergency, please select a location to view the relevant
                  information:
                </Typography>
              </Grid>
              <Card>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="left"
                  spacing={2}
                  style={{ backgroundColor: isDark ? '#424242' : '#f5f5f5' }}
                  className={classes.copyrightCard}
                >
                  <Grid item>
                    <ul>
                      <li>
                        <Typography variant="h6">
                          New Brunswick/Piscataway
                          <a href="tel:(732)-932-7111"> (732)-932-7111</a>
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="h6">
                          Newark
                          <a href="tel:(732)-932-7111"> (973) 972-4491</a> or
                          <a href="tel:(973) 353-5111"> (973) 353-5111</a>
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="h6">
                          Camden
                          <a href="tel:(856) 225-6111"> (856) 225-6111</a>
                        </Typography>
                      </li>
                      <li>
                        <Typography variant="h6">
                          Other Facilities{' '}
                          <a href="tel:(732) 932-7111"> (732) 932-7111</a>
                        </Typography>
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Card>
        </Grid>

        <Grid item className={classes.fullWidth}>
          <Card className={classes.linksCard}>
            <Grid
              container
              direction="column"
              alignItems="left"
              justifyContent="flex-start"
              spacing={2}
              className={classes.copyrightCard}
            >
              <Grid item>
                <Typography variant="h2">Other Help Contacts</Typography>
              </Grid>
              <Card>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="left"
                  spacing={2}
                  style={{ backgroundColor: isDark ? '#424242' : '#f5f5f5' }}
                  className={classes.copyrightCard}
                >
                  <Grid item>
                    <Typography variant="h4" color="primary">
                      Mental Health Emergencies
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6">
                      If Counseling, Alcohol & Other Drug Assistance Program
                      (CAPS) is closed, or you are experiencing a mental health
                      emergency, please use one of the following resources:
                      <ul>
                        <li>
                          <Typography variant="h6">
                            ProtoCall, a phone-based service for students in
                            crisis when CAPS is closed. Call the main CAPS
                            number,
                            <a href="tel:848-932-7884"> 848-932-7884</a>, and
                            follow the prompts.
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="h6">
                            Acute Psychiatric Service (APS):
                            <a href="tel:855-515-5700"> 855-515-5700</a> , a
                            24-hour emergency service for local residents. If
                            you live outside of Middlesex County you should
                            contact the nearest hospital emergency department.
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="h6">
                            988 Suicide and Crisis Lifeline: Call or text to
                            988.
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="h6">
                            NJ Hope Line (Suicide Prevention):
                            <a href="tel:855-654-6735"> 855-654-6735</a>
                          </Typography>
                        </li>
                        <li>
                          <Typography variant="h6">
                            National Crisis Text Line: Text HELLO to 741741.
                          </Typography>
                        </li>
                      </ul>
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
              <br />
              <Card>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="left"
                  spacing={2}
                  style={{ backgroundColor: isDark ? '#424242' : '#f5f5f5' }}
                  className={classes.copyrightCard}
                >
                  <Grid item>
                    <Typography variant="h4" color="primary">
                      RSH Advice Nurse Line
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6">
                      <a href="tel:800-890-5882">Phone: 800-890-5882</a>
                      <br />
                      This free service is provided for non-urgent medical
                      problems that arise when our Health Centers are closed. It
                      provides Rutgers students with access to experienced
                      registered nurses who can answer health-related questions
                      and help students decide the best course of action to
                      take. <br />
                      The RSH Advice Nurse Line is produced by CitraHealth using
                      standardized procedures and nationally recognized Clinical
                      Triage Protocols. RSH reviews the calls for quality
                      assurance and to assist students with appropriate
                      follow-up.
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
              <br />
              <Grid item>
                <Typography variant="h4" color="primary">
                  Hospitals
                </Typography>
                <Card
                  style={{
                    marginTop: 10,
                    backgroundColor: '#4666a0',
                    color: 'white',
                  }}
                >
                  <Grid item>
                    <CardContent>
                      <Typography variant="h6">
                        Robert Wood Johnson University Hospital <br />
                        <a href="tel:732-828-3000" style={{ color: 'white' }}>
                          Phone: 732-828-3000
                        </a>
                      </Typography>
                    </CardContent>
                  </Grid>
                </Card>
                <Card
                  style={{
                    marginTop: 10,
                    backgroundColor: '#4666a0',
                    color: 'white',
                  }}
                >
                  <Grid item>
                    <CardContent>
                      <Typography variant="h6">
                        St. Peter&rsquo;s University Hospital <br />
                        <a href="tel:732-745-8600" style={{ color: 'white' }}>
                          Phone: 732-745-8600
                        </a>
                      </Typography>
                    </CardContent>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item className={classes.fullWidth}>
          <Card className={classes.linksCard}>
            <Grid
              container
              direction="column"
              alignItems="left"
              justifyContent="flex-start"
              spacing={2}
              className={classes.copyrightCard}
            >
              <Grid item>
                <Typography variant="h2">More Information</Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.moreInfoButon}
                  onClick={() =>
                    handleMoreInfoClick(
                      'https://ipo.rutgers.edu/publicsafety/eap/medical-emergency',
                    )
                  }
                >
                  <Typography variant="h5">Medical Emergency</Typography>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.moreInfoButon}
                  onClick={() =>
                    handleMoreInfoClick(
                      'https://ipo.rutgers.edu/publicsafety/eap/weather-emergency',
                    )
                  }
                >
                  <Typography variant="h5">Weather Emergency</Typography>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.moreInfoButon}
                  onClick={() =>
                    handleMoreInfoClick(
                      'https://ipo.rutgers.edu/publicsafety/eap/fire-emergency',
                    )
                  }
                >
                  <Typography variant="h5">Fire Emergency</Typography>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.moreInfoButon}
                  onClick={() =>
                    handleMoreInfoClick(
                      'https://ipo.rutgers.edu/publicsafety/eap/police-emergency',
                    )
                  }
                >
                  <Typography variant="h5">Police Emergency</Typography>
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default EmergencyInformation
