import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Fuse from 'fuse.js'
import { debounce } from 'lodash'

import { updateSearchBarContents } from '../../newsSlice'
import SearchBar from '../../../dashboard/DashboardComponents/SearchBar/SearchBar'
import NewsSearchBarStyles from './NewsSearchBarStyles'

/**
 * When the search bar is triggered, it will switch to the ShowAppList view where you pass in the applist you want to see
 * The search bar updates that applist by filtering the entire list each time you enter a new char
 */

function NewsSearchBar() {
  const dispatch = useDispatch()
  const classes = NewsSearchBarStyles()
  const { search, allArticles } = useSelector((s) => s.news)
  const [searchVal, setSearchVal] = React.useState('')

  React.useEffect(() => {
    setSearchVal('')
  }, [])

  const debounceSearch = React.useCallback(
    debounce((val, updatedSearch) => {
      const options = {
        keys: ['id'],
        shouldSort: true,
        threshold: 0.3, // This will make the search very strict
        distance: 10000, // This will allow matches to be anywhere in the string
      }

      const fuse = new Fuse(allArticles, options)
      const result = fuse.search(val)
      const articlesList = result.map((item) => item.item)

      dispatch(
        updateSearchBarContents({
          ...updatedSearch,
          articleList: articlesList,
          loading: false,
        }),
      )
    }, 500),
    [allArticles, dispatch],
  )

  const searchHandler = (event) => {
    const val = event.target.value
    setSearchVal(val)

    // Create a copy of the existing 'search' object and update its 'searchQuery'
    const updatedSearch = {
      ...search,
      searchQuery: val,
      // Set values based on whether search value exists
      enableSearch: val.length > 0,
      loading: val.length > 0,
      articleList: val.length > 0 ? search.articleList : [],
    }

    dispatch(updateSearchBarContents(updatedSearch)) // Dispatch an action to update the Redux state
    debounceSearch(val, updatedSearch) // Debounce the search function
  }

  const cancelSearch = () => {
    setSearchVal('')
    dispatch(
      updateSearchBarContents({
        searchQuery: '',
        enableSearch: false,
        articleList: [],
        loading: false,
      }),
    )
  }

  return (
    <SearchBar
      className={classes.searchBar}
      value={searchVal}
      placeholder="Search News"
      onCancelSearch={() => cancelSearch()}
      onChange={(val) => searchHandler(val)}
    />
  )
}

export default NewsSearchBar
