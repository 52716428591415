import React from 'react'
import { useSelector } from 'react-redux'
import NearestBusStops from '../../../sections/Buildings/components/NearestBusStops/NearestBusStops'
// Still need to update this component to use the BottomSheet
function BottomSheetNearestStop({ setSheetConfig }) {
  const { selectedBuilding } = useSelector((s) => s.buildings)
  const { nearestStopsLoading } = useSelector((s) => s.maps)

  React.useEffect(() => {
    const sheetConfig = {
      title: `Stops Near ${selectedBuilding.name}`,
      sheetColor: '',
      displayBackButton: true,
      displayCloseButton: true,
    }
    setSheetConfig(sheetConfig)
  }, [selectedBuilding])

  return nearestStopsLoading === 'fulfilled' ? (
    <NearestBusStops />
  ) : (
    <span>loading....</span>
  )
}

export default BottomSheetNearestStop
