/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
import * as React from 'react'
import uuid from 'react-uuid'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import BuildingMarker from './BuildingMarker'

export default function BuildingStops() {
  const { selectedBuilding } = useSelector((s) => s.buildings)
  const [buildingMarker, setBuildingMarker] = React.useState([])

  React.useEffect(() => {
    if (!_.isEmpty(selectedBuilding)) {
      generateBuildingMarker(selectedBuilding)
    }
  }, [selectedBuilding])

  const generateBuildingMarker = (building) => {
    const marker = <BuildingMarker key={uuid()} building={building} />
    setBuildingMarker(marker)
  }

  return <>{!_.isEmpty(selectedBuilding) && buildingMarker}</>
}
