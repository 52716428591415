/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import * as React from 'react'
import { Card, Typography, Collapse, Grid, IconButton } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import RestaurantIcon from '@material-ui/icons/Restaurant'
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus'
import RoomIcon from '@material-ui/icons/Room'
import FavoriteIcon from '@material-ui/icons/Favorite'
import LocalParkingIcon from '@material-ui/icons/LocalParking'

import {
  fetchNearestStops,
  closeBottomSheet,
  // updateMapTabValue,
  // setZoom,
} from '../../../sections/Map/redux/myMapsSlice'

import {
  setSelectedBuilding,
  addBuildingToFavorite,
  removeBuildingFromFavorite,
  fetchRestaurants,
  handleNearestStopClicked,
  handleRestaurantMobileClick,
  setSelectedFavoritedBuildings,
  // fetchParkingNearBuilding,
} from '../../../sections/Buildings/redux/myBuildingsSlice'

import { buildingListMobileStyles } from './buildingListMobileStyles'
import { sortListAlphabetically } from '../../../utils/helpers'
import CheckIsMobile from '../../../../../../utils/isMobileHook'
import { logMapEvent } from '../../../utils/events'
import {
  BUILDINGFAVORITE,
  BUILDINGUNFAVORITE,
  DIRECTIONSMAP,
  NEARESTSTOPSBUTTON,
  PARKINGMAP,
} from '../../../utils/constants'
import { storeFavoriteBuldingListToFirebase } from '../../../sections/Buildings/firebase/firebase'
// Each component is an item in the building list
// It takes the parameters and creates a clickable card that
// directs to parking data, restaurant and directions to get there

export const BuildingListMobile = ({
  bid,
  address,
  buildingName,
  buildingCity,
  picture,
  lat,
  lng,
  building,
  headingObject = { showHeading: false, header: 'test header' },
}) => {
  const classes = buildingListMobileStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const isMobile = CheckIsMobile()
  const { favoritedBuildings } = useSelector((s) => s.buildings)

  // const history = useHistory()
  const [fullAddress, setFullAddress] = React.useState('')
  const [img, setImg] = React.useState('')

  // eslint-disable-next-line no-unused-vars
  const [expanded, setExpanded] = React.useState(true)
  const [favorited, setFavorited] = React.useState(false)

  // Format City Name
  function titleCase(str) {
    return str
      .split(' ')
      .map((item) => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase())
      .join(' ')
  }

  React.useEffect(() => {
    let addressTemp = address
    let buildingCityTemp = buildingCity

    if (address == null) {
      addressTemp = ''
    }

    if (buildingCity == null) {
      buildingCityTemp = ''
    }

    if (picture == null) {
      setImg('./assets/images/building-placeholder.png')
    } else {
      setImg(picture)
    }

    buildingCityTemp = titleCase(buildingCityTemp)

    setFullAddress(addressTemp.concat(', ').concat(buildingCityTemp))

    // Check if building is in favorited list
    if (favoritedBuildings.length !== 0) {
      if (favoritedBuildings.some((b) => b.bid === bid)) {
        setFavorited(true)
      }
    }
  }, [])

  const getBuildingInfo = () => {
    logMapEvent(dispatch, DIRECTIONSMAP, buildingName)
    const start = 'https://maps.rutgers.edu/#/?lat='
    const mid = '&selected='
    const end = '&sidebar=true&zoom=21'
    const link = start
      .concat(lat)
      .concat('&lng=')
      .concat(lng)
      .concat(mid)
      .concat(bid)
      .concat(end)
    window.open(link)
  }

  const getRestaurantData = () => {
    const coord = {
      lat,
      lng,
    }
    if (isMobile === true) {
      dispatch(handleRestaurantMobileClick(true))
      history.push({
        pathname: '/RestaurantListMobile',
        state: { coord },
      })
    } else {
      dispatch(fetchRestaurants(coord))
    }
  }

  const getParkingInfo = () => {
    logMapEvent(dispatch, PARKINGMAP, buildingName)
    dispatch(closeBottomSheet(null))
    dispatch(setSelectedBuilding(building))
    // Don't use API for beta release
    // dispatch(setZoom(15))
    // dispatch(fetchParkingNearBuilding())
    // dispatch(updateMapTabValue(0))
    const start = `${process.env.REACT_APP_MAPS_PARKING}/#/?click=true&lat=`
    const mid = '&selected='
    const end = '&sidebar=true&zoom=18&parking=true'
    const link = start
      .concat(building.lat)
      .concat('&lng=')
      .concat(building.lng)
      .concat(mid)
      .concat(building.bid)
      .concat(end)
    window.open(link, '_blank')
  }

  const addToFavorite = () => {
    let newFavoriteList = []
    if (favorited) {
      // If already favorited, remove
      newFavoriteList = favoritedBuildings.filter((b) => b.bid !== bid)
      dispatch(removeBuildingFromFavorite(newFavoriteList))
      setFavorited(false)
    } else {
      // To do add building to list
      newFavoriteList = [...favoritedBuildings]
      newFavoriteList.push(building)
      dispatch(addBuildingToFavorite(newFavoriteList))
      setFavorited(true)
    }
    dispatch(
      setSelectedFavoritedBuildings(sortListAlphabetically(newFavoriteList)),
    )
    storeFavoriteBuldingListToFirebase(newFavoriteList.map((b) => b.bid))
    logMapEvent(
      dispatch,
      favorited ? BUILDINGUNFAVORITE : BUILDINGFAVORITE,
      building.name,
    )
  }

  const getNearestStops = () => {
    logMapEvent(dispatch, NEARESTSTOPSBUTTON, buildingName)
    dispatch(fetchNearestStops(bid))
    dispatch(handleNearestStopClicked(bid))
    if (isMobile) {
      handleMobile()
    }
  }

  const handleMobile = () => {
    history.push({
      pathname: '/NearestStopsMobile',
    })
  }
  return (
    <Card className={classes.root}>
      {headingObject.showHeading && (
        <div className={classes.headingStyles}>
          <h4>{headingObject.header}</h4>
        </div>
      )}
      <div>
        <img
          className={classes.img}
          style={{ height: headingObject.showHeading ? 180 : 200 }}
          src={img}
          alt="img"
          onError={(e) => {
            e.target.onerror = null
            e.target.src = './assets/images/building-placeholder.png'
          }}
        />
        <div className={classes.cardContent}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={2}>
              <> </>
            </Grid>
            <Grid item xs={8}>
              <Typography
                className={classes.buildingName}
                gutterBottom
                variant="h5"
                component="h2"
                noWrap
              >
                {buildingName}
              </Typography>
              <Typography
                className={classes.buildingAddress}
                variant="body2"
                color="textSecondary"
                component="p"
              >
                {fullAddress}
              </Typography>
            </Grid>
            <Grid item xs={2} style={{ paddingLeft: 16 }}>
              <IconButton
                edge="start"
                className={classes.favoriteIcon}
                color="inherit"
                aria-label="add/remove building favorite"
                onClick={() => addToFavorite()}
              >
                <FavoriteIcon
                  className={
                    favorited
                      ? classes.favoriteIconSelected
                      : classes.favoriteIcon
                  }
                />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div>
          <Grid
            container
            className={classes.bottomButtonRow}
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid item>
              <IconButton
                edge="start"
                className={classes.icon}
                color="inherit"
                aria-label="get nearest stops"
                onClick={() => getNearestStops()}
              >
                <DirectionsBusIcon className={classes.icon} />
              </IconButton>
            </Grid>

            <Grid item>
              <IconButton
                edge="start"
                className={classes.icon}
                color="inherit"
                aria-label="get building info"
                onClick={() => getBuildingInfo()}
              >
                <RoomIcon className={classes.icon} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                edge="start"
                className={classes.icon}
                color="inherit"
                aria-label="get parking info"
                onClick={() => getParkingInfo()}
              >
                <LocalParkingIcon className={classes.icon} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                edge="start"
                className={classes.icon}
                color="inherit"
                aria-label="get nearest restaurants"
                onClick={getRestaurantData}
              >
                <RestaurantIcon className={classes.icon} />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      </Collapse>
    </Card>
  )
}
