import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, Box } from '@material-ui/core'
import Loading from '../helpers/Loading'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'
import { MyFavoriteStyles } from './myFavoriteStyles'
import Widget from '../Widget'
import { fetchMyApps } from '../../../apps/appSlice'
import MyFavoritesManageView from './MyFavoritesManageView'
import MyFavoritesButton from './MyFavoritesButton'

const MyFavorites = ({
  title,
  showNote,
  noteMessage,
  notePosition,
  noteBackground,
  noteIcon,
  noteUrl,
  noteFontColor,
}) => {
  const widgetCallState = useSelector((state) => state.myApps.status)
  const dispatch = useDispatch()

  React.useEffect(() => {
    const user = localStorage.getItem('user') ?? 'visitor'
    const isGuest = user === '"visitor"' || user === ''
    dispatch(fetchMyApps(isGuest))
  }, [])

  return (
    <Widget>
      <Widget.Header title={title} />
      <Widget.Content
        showNote={showNote}
        noteMessage={noteMessage}
        notePosition={notePosition}
        noteBackground={noteBackground}
        noteIcon={noteIcon}
        noteUrl={noteUrl}
        noteFontColor={noteFontColor}
      >
        {renderMyFavoritesState(widgetCallState)}
      </Widget.Content>
    </Widget>
  )
}

function renderMyFavoritesState(widgetCallState) {
  switch (widgetCallState) {
    case 'fulfilled':
      return <Loaded />
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage />
        </Grid>
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item></Grid>
        </Grid>
      )
  }
}

function Loaded() {
  const classes = MyFavoriteStyles()
  return (
    <>
      <Box className={classes.favoritesListContainer}>
        <MyFavoritesManageView isMobile />
      </Box>
      <MyFavoritesButton title="Modify Favorites" title2="Done" />
    </>
  )
}

export default MyFavorites
