import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Widget from '../Widget'
import {
  fetchMyBenefits,
  selectMyBenefitsData,
  selectMyBenefitsStatus,
} from './MyBenefitsSlice'
import Loading from '../helpers/Loading'
import styles from './MyBenefitsStyles'
import IconLink from '../../DashboardComponents/IconLinks/IconLink'
import HiddenEyeButton from '../../DashboardComponents/HiddenEyeButton/HiddenEyeButton'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'

// eslint-disable-next-line no-unused-vars
function MyBenefits({
  className,
  title,
  widgetId,
  showNote,
  noteMessage,
  notePosition,
  noteBackground,
  noteIcon,
  noteUrl,
  noteFontColor,
}) {
  const classes = styles()
  const dispatch = useDispatch()
  const widgetData = useSelector(selectMyBenefitsData)
  const widgetStatus = useSelector(selectMyBenefitsStatus)

  React.useEffect(() => {
    dispatch(fetchMyBenefits())
  }, [])

  return (
    <Widget className={className}>
      <Widget.Header title={title} />
      <Widget.Content
        className={classes.container}
        showNote={showNote}
        noteMessage={noteMessage}
        notePosition={notePosition}
        noteBackground={noteBackground}
        noteIcon={noteIcon}
        noteUrl={noteUrl}
        noteFontColor={noteFontColor}
      >
        {renderMyBenefitsState(widgetStatus, widgetData, widgetId, classes)}
      </Widget.Content>
    </Widget>
  )
}

function renderMyBenefitsState(widgetStatus, widgetData, widgetId, classes) {
  switch (widgetStatus) {
    case 'fulfilled':
      return (
        <Loaded widgetData={widgetData} widgetId={widgetId} classes={classes} />
      )
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage />
        </Grid>
      )
    default:
      return <Box></Box>
  }
}

function Loaded({ widgetData, widgetId, classes }) {
  const dispatch = useDispatch()

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={classes.mainGrid}
      wrap="nowrap"
    >
      <Grid item className={classes.header}>
        <Typography variant="body1">
          <b>{widgetData.subtitle}</b>
        </Typography>
      </Grid>
      <Grid item className={classes.absence}>
        <AbsenceGrid
          classes={classes}
          absenceList={widgetData.hiddenEyeButtons}
          widgetData={widgetData}
          widgetId={widgetId}
          dispatch={dispatch}
        />
      </Grid>
      <Grid item className={classes.links}>
        <Links
          classes={classes}
          linkList={widgetData.iconLinks}
          widgetId={widgetId}
          dispatch={dispatch}
        />
      </Grid>
    </Grid>
  )
}

function AbsenceGrid({ classes, absenceList, widgetData, dispatch, widgetId }) {
  const handleValue = (index) => {
    switch (index) {
      case 0:
        return widgetData.vacdayleft ? widgetData.vacdayleft : 'N/A'
      case 1:
        return widgetData.sickdayleft ? widgetData.sickdayleft : 'N/A'
      case 2:
        return widgetData.aldayleft ? widgetData.aldayleft : 'N/A'
      case 3:
        return widgetData.phdayleft ? widgetData.phdayleft : 'N/A'
      case 4:
        return widgetData.mldayleft ? widgetData.mldayleft : 'N/A'
      case 5:
        return widgetData.plbdayleft ? widgetData.plbdayleft : 'N/A'
      default:
        return 'N/A'
    }
  }

  const handleUsedValue = (index) => {
    switch (index) {
      case 0:
        return widgetData.vactaken ? widgetData.vactaken : 'N/A'
      case 1:
        return widgetData.sicktaken ? widgetData.sicktaken : 'N/A'
      case 2:
        return widgetData.altaken ? widgetData.altaken : 'N/A'
      case 3:
        return widgetData.phtaken ? widgetData.phtaken : 'N/A'
      case 4:
        return widgetData.mltaken ? widgetData.mltaken : 'N/A'
      case 5:
        return widgetData.plbtaken ? widgetData.plbtaken : 'N/A'
      default:
        return 'N/A'
    }
  }
  return (
    <Grid
      container
      direction="column"
      alignContent="center"
      justifyContent="space-around"
      spacing={2}
      className={classes.dataRingsContainer}
    >
      <Grid item container alignItems="flex-start" spacing={2}>
        {absenceList.map((category, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Grid key={index} item xs={4} className={getcolor(index, classes)}>
            <HiddenEyeButton
              widgetId={widgetId}
              dispatch={dispatch}
              label={category.iconLabel}
              value={handleValue(index)}
              showUsedValue={handleUsedValue(index)}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

function Links({ linkList, widgetId, dispatch, classes }) {
  return (
    <Grid container direction="column">
      <Grid container justifyContent="space-evenly">
        {linkList.map((link, index) => (
          <Grid
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            container
            item
            justifyContent="center"
            xs={4}
            className={classes.row}
          >
            <IconLink
              icon={link.icon}
              iconLabel={link.iconLabel}
              link={link.link}
              widgetId={widgetId}
              dispatch={dispatch}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

const getcolor = (index, classes) => {
  switch (index) {
    case 0:
      return classes.redIcon
    case 1:
      return classes.blueIcon
    case 2:
      return classes.greenIcon
    default:
      return classes.grayIcon
  }
}

export default MyBenefits
