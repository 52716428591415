import * as React from 'react'
import { useSelector } from 'react-redux'
import CategoryCardSlider from '../../AppComponents/CategoryCardSlider/CategoryCardSlider'
import SetCarouselDimensions from '../../AppComponents/AppCarouselCombined/AppCarouselDimensions'
import { selectAppCategoriesWithApps, selectIsGuest } from '../../appSlice'
import AllAppsPageStyles from './AllAppsPageStyle'
import AppCarouselCombined from '../../AppComponents/AppCarouselCombined/AppCarouselCombined'

/**
 * All Apps Tab
 * Displays all applications in carousels
 * Displays category buttons in desktop view
 */
function AllAppsPage({ isMobile }) {
  const classes = AllAppsPageStyles()
  const dimensions = SetCarouselDimensions()
  const myAppsSorted = useSelector(selectAppCategoriesWithApps)
  const isGuest = useSelector(selectIsGuest)
  // Render the App Carousel Items
  const CreateAppCarousel = () =>
    myAppsSorted.map((category) => (
      <AppCarouselCombined
        key={category.name}
        appList={category.apps}
        carouselType="carousel"
        category={category.name}
        dimensions={dimensions}
        id={category.id}
        isGuest={isGuest}
        isMobile={isMobile}
      />
    ))

  return (
    <div className={classes.root}>
      <CategoryCardSlider isMobile={isMobile} />
      <CreateAppCarousel />
    </div>
  )
}

export default AllAppsPage
