import React from 'react'
import { useHistory } from 'react-router-dom'

import {
  Typography as Text,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
} from '@material-ui/core'
import BottomBannerCardStyles from './BottomBannerCardStyles'
import { getImageFromFirebaseWithHook } from '../../../../helpers'

const BottomBannerCard = ({ isMobile, title, img }) => {
  const classes = BottomBannerCardStyles()
  const history = useHistory()
  const [image, setImage] = React.useState(
    './assets/images/image-not-found.png',
  )

  React.useEffect(() => {
    getImageFromFirebaseWithHook(img, setImage)
  }, [])

  const handleClick = () =>
    history.push({
      pathname: isMobile ? '/SeeAllAppsPageMobile' : './infoPage',
      search: isMobile
        ? `?id=${encodeURIComponent(title)}&type=ForYou`
        : `?type=ForYou&id=${encodeURIComponent(title)}`,
    })

  return (
    <Card className={classes.root} onClick={handleClick} elevation={3}>
      <CardActionArea>
        <CardMedia className={classes.media} image={image} title={title} />
        <CardContent style={{ textAlign: 'left' }}>
          <Text className={classes.title}>{title}</Text>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default BottomBannerCard
