/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { doc, collection, getDoc, updateDoc } from 'firebase/firestore'
import axios from 'axios'
import { firestore } from '../../../../../utils/firebase'
import { partition, getDayOfWeek } from './helper'
import customColor from '../../../../theme/theme'

const initialState = {
  title: 'Course Schedule',
  courseScheduleData: [],
  scheduleData: [],
  fetchCourseStatus: 'idle',
  fetchTutorialStatus: 'idle',
  showCourseScheduleTutorial: false,
  courseModalOpen: false,
  view: 'table',
  tab: 'ru',
  currentTerm: 0,
}

export const fetchCourseSchedule = createAsyncThunk(
  'fetchCourseSchedule',
  async (currentTermParam = '') => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const rcpid = localStorage.getItem('rcpid')
    return axios.all([
      axios.get(
        `${process.env.REACT_APP_COURSESCHEDULE_ENDPOINT}${currentTermParam}`,
        {
          headers: {
            JWT: jwtToken,
            XWT: rcpid,
          },
        },
      ),
    ])
  },
)

export const fetchCourseScheduleModalData = createAsyncThunk(
  'fetchCourseScheduleModalData',
  async (currentTermParam = '', { getState }) => {
    const state = getState()
    const jwtToken = localStorage.getItem('myrJwtToken')
    const rcpid = localStorage.getItem('rcpid')
    const res = await axios.get(
      `${process.env.REACT_APP_COURSESCHEDULE_ENDPOINT}${currentTermParam}`,
      {
        headers: {
          JWT: jwtToken,
          XWT: rcpid,
        },
      },
    )
    const [regularCourses, remoteCourses] = partition(
      res.data[state.courseSchedule.tab],
      (item) => item.meetingDay,
    )
    return {
      courseModalData: res.data,
      regularCourses,
      remoteCourses,
    }
  },
)

export const fetchCourseDefaultTutorialData = createAsyncThunk(
  'fetchCourseDefaultTutorialData',
  async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_COURSESCHEDULE_DUMMY_ENDPOINT}`,
      {},
    )
    return res.data
  },
)

export const courseScheduleSlice = createSlice({
  name: 'CourseSchedule',
  initialState,
  reducers: {
    endTutorial: (state) => {
      const userDocRef = doc(
        collection(firestore, 'users'),
        localStorage.getItem('user') ?? 'visitor',
        'settings',
        'tutorials',
      )
      state.showCourseScheduleTutorial = false
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'production')
        getDoc(userDocRef)
      updateDoc(userDocRef, { show_course_schedule_tutorial: false })
    },
    toggleCourseScheduleModal: (state, action) => {
      state.courseModalOpen = action.payload
    },
    updateCurrentTerm: (state, action) => {
      state.currentTerm = action.payload
    },
    updateView: (state, action) => {
      state.view = action.payload
    },
    updateScheduleData: (state) => {
      const dayMap = {
        Mon: 1,
        Tues: 2,
        Wed: 3,
        Thurs: 4,
        Fri: 5,
        Sat: 6,
        Sun: 0,
      }
      const currentDate = new Date()
      const year = currentDate.getFullYear()
      const month = currentDate.getMonth()
      const currentDay = currentDate.getDate()
      // new format for weekly view
      const weeklySchedule = [
        {
          name: 'Sunday',
          events: [],
        },
        {
          name: 'Monday',
          events: [],
        },
        {
          name: 'Tuesday',
          events: [],
        },
        {
          name: 'Wednesday',
          events: [],
        },
        {
          name: 'Thursday',
          events: [],
        },
        {
          name: 'Friday',
          events: [],
        },
        {
          name: 'Saturday',
          events: [],
        },
      ]
      // could be RU or RBHS
      if (state.courseScheduleData[state.tab]) {
        state.courseScheduleData[state.tab].forEach((course) => {
          const startTimeModifier = course.startTime
            ? course.startTime.slice(
                course.startTime.length - 2,
                course.startTime.length,
              )
            : ''
          //   console.log('start time modifider ', startTimeModifier)
          const endTimeModifier = course.endTime
            ? course.endTime.slice(
                course.endTime.length - 2,
                course.endTime.length,
              )
            : ''
          //   console.log('endtimemodifider ', endTimeModifier)
          const toBeFormattedStartTime = course.startTime
            ? course.startTime.split(':')
            : ['3', '00PM']
          //   console.log('tobeformattedstartime ', toBeFormattedStartTime)
          const toBeFormattedEndTime = course.endTime
            ? course.endTime.split(':')
            : ['4', '00PM']
          //   console.log('tobeformattedendtime ', toBeFormattedEndTime)
          let day = dayMap[course.meetingDay]
          // gets the proper day of the week per course item
          day = getDayOfWeek(day, currentDay, currentDate)
          let startHour
          if (
            Number(toBeFormattedStartTime[0]) < 12 &&
            startTimeModifier === 'PM'
          ) {
            startHour = Number(toBeFormattedStartTime[0]) + 12
            // console.log('modified startHour ', startHour)
          } else {
            startHour = toBeFormattedStartTime[0]
          }
          let endHour
          if (
            Number(toBeFormattedEndTime[0]) < 12 &&
            endTimeModifier === 'PM'
          ) {
            endHour = Number(toBeFormattedEndTime[0]) + 12
            // console.log('modified endhour ', endHour)
          } else {
            endHour = toBeFormattedEndTime[0]
          }
          const startMinute =
            toBeFormattedStartTime !== []
              ? toBeFormattedStartTime[1].slice(0, 2)
              : ''
          const endMinute =
            toBeFormattedEndTime !== []
              ? toBeFormattedEndTime[1].slice(0, 2)
              : []
          const newStartDate = new Date(
            year,
            month,
            day,
            startHour,
            startMinute,
          )
          const newEndDate = new Date(year, month, day, endHour, endMinute)
          //   console.log(`${year}, ${month}, ${day}, ${endHour}, ${endMinute}`)
          //   console.log('newstartdate ', newStartDate)
          //   console.log('newenddate ', newEndDate)
          // week Sun-Sat from 0-6
          if (dayMap[course.meetingDay]) {
            weeklySchedule[dayMap[course.meetingDay]].events.push({
              title: `${course.courseTitle}`,
              startTime:
                newStartDate.getHours() + newStartDate.getMinutes() / 60,
              endTime: newEndDate.getHours() + newEndDate.getMinutes() / 60,
              color: customColor.rutgersRed,
              course,
            })
          }
        })
      }
      // console.log('weekly schedule ', weeklySchedule)
      state.scheduleData = weeklySchedule
    },
    updateTab: (state, action) => {
      state.tab = action.payload
    },
  },
  extraReducers: {
    [fetchCourseSchedule.rejected]: (state) => {
      state.fetchCourseStatus = 'error'
    },
    [fetchCourseSchedule.pending]: (state) => {
      state.fetchCourseStatus = 'pending'
    },
    [fetchCourseSchedule.fulfilled]: (state, action) => {
      state.fetchCourseStatus = 'fulfilled'
      state.title = action.payload[0].data.title
      state.courseScheduleData = action.payload[0].data
      // select default tab
      //   state.tab =
      //     action.payload[0].data?.rbhs && action.payload[0].data?.rbhs.length > 0
      //       ? 'rbhs'
      //       : 'ru'
    },
    [fetchCourseScheduleModalData.rejected]: (state) => {
      state.courseScheduleModalDataStatus = 'error'
    },
    [fetchCourseScheduleModalData.pending]: (state) => {
      state.courseScheduleModalDataStatus = 'pending'
    },
    [fetchCourseScheduleModalData.fulfilled]: (state, action) => {
      state.courseScheduleModalDataStatus = 'fulfilled'
      state.courseScheduleData = action.payload.courseModalData
      state.regularCourses = action.payload.regularCourses
      state.remoteCourses = action.payload.remoteCourses
      state.fetchCourseStatus = 'fulfilled'
    },
    [fetchCourseDefaultTutorialData.rejected]: (state) => {
      state.defaultTutorialStatus = 'error'
    },
    [fetchCourseDefaultTutorialData.pending]: (state) => {
      state.defaultTutorialStatus = 'pending'
    },
    [fetchCourseDefaultTutorialData.fulfilled]: (state, action) => {
      state.defaultTutorialStatus = 'fulfilled'
      state.title = action.payload.title
      state.defaultTutorialData = action.payload
      state.showCourseScheduleTutorial = true
    },
  },
})
export const selectCourseScheduleTitle = (state) => state.courseSchedule.title
export const selectCourseScheduleData = (state) =>
  state.courseSchedule.courseScheduleData
export const selectCourseScheduleStatus = (state) =>
  state.courseSchedule.fetchCourseStatus
export const selectShowCourseScheduleTutorial = (state) =>
  state.courseSchedule.showCourseScheduleTutorial
export const selectCourseScheduleTutorialStatus = (state) =>
  state.courseSchedule.fetchTutorialStatus
export const selectCourseScheduleModalOpen = (state) =>
  state.courseSchedule.courseModalOpen
export const selectTab = (state) => state.courseSchedule.tab
export const selectCurrentTerm = (state) => state.courseSchedule.currentTerm
export const selectDefaultTutorialData = (state) =>
  state.courseSchedule.defaultTutorialData
export const selectDefaultTutorialStatus = (state) =>
  state.courseSchedule.defaultTutorialStatus
export const {
  endTutorial,
  toggleCourseScheduleModal,
  updateCurrentTerm,
  updateView,
  updateScheduleData,
  updateTab,
} = courseScheduleSlice.actions

export default courseScheduleSlice.reducer
