/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import chunk from 'lodash/chunk'

const initialState = {
  jobOpps: undefined,
  positions: undefined,
  page: 1,
  jobDetail: '',
  jobDetailOpen: false,
  term: '',
  status: 'idle',
}

export const fetchJobs = createAsyncThunk('fetchJobs', async () => {
  const jwtToken = localStorage.getItem('myrJwtToken')
  const res = await axios.get(process.env.REACT_APP_JOBS_ENDPOINT, {
    headers: {
      JWT: window.location.hostname !== 'localhost' ? jwtToken : null,
    },
  })
  return {
    data: res.data?.items,
  }
})

export const jobOpportunitiesSlice = createSlice({
  name: 'jobOpportunitiesWidget',
  initialState,
  reducers: {
    updatePage: (state, action) => {
      state.page = action.payload
    },
    updateJobDetail: (state, action) => {
      state.jobDetail = action.payload
    },
    updateJobDetailOpen: (state) => {
      state.jobDetailOpen = !state.jobDetailOpen
    },
    updateSearchTerm: (state, action) => {
      const term = action.payload
      if (term !== '') {
        state.term = term
        // the array containing all the positions
        const newPositions = state.positions.filter(
          (position) =>
            // check to see if the position.name property is contained within the term
            position.title.toLowerCase().includes(term.toLowerCase()) ||
            position.content.toLowerCase().includes(term.toLowerCase()) ||
            position.author.toLowerCase().includes(term.toLowerCase()),
        )
        if (newPositions.length > 0) {
          state.jobOpps = { data: chunk(newPositions, 5) }
        } else {
          state.jobOpps = { data: newPositions }
        }
      } else {
        state.jobOpps = { data: chunk(state.positions, 5) }
      }
    },
    updateCancelSearch: (state) => {
      state.jobOpps = { data: chunk(state.positions, 5) }
    },
  },
  extraReducers: {
    [fetchJobs.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchJobs.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchJobs.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.jobOpps = { data: chunk(action.payload.data, 5) }
      state.positions = action.payload.data
    },
  },
})

export const {
  updatePage,
  updateJobDetail,
  updateJobDetailOpen,
  updateSearchTerm,
  updateCancelSearch,
} = jobOpportunitiesSlice.actions

export default jobOpportunitiesSlice.reducer
