import * as React from 'react'

import { Button, ButtonGroup } from '@material-ui/core'

class TutorialMode extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentStep: 0,
      showTutorial: props.showTutorial || false,
      steps: props.steps,
      endTutorial: props.stateEndTutorial,
      dispatch: props.dispatch,
    }
  }

  componentDidMount() {
    console.log('test')
  }

  handleStepChange(step) {
    const { state } = this
    state.currentStep = step
    this.setState(state)
  }

  handleEndTutorial() {
    const { state } = this
    state.showTutorial = false
    state.dispatch(state.endTutorial)
    this.setState(state)
  }

  render() {
    return this.state.showTutorial ? (
      <div>
        <div
          className={this.props.styles.tourBackdropTop}
          style={{
            width: this.props.steps[this.state.currentStep].top.width,
            height: this.props.steps[this.state.currentStep].top.height,
            left: this.props.steps[this.state.currentStep].top.left,
          }}
        />
        <div
          className={this.props.styles.tourBackdropRight}
          style={{
            width: this.props.steps[this.state.currentStep].right.width,
            height: this.props.steps[this.state.currentStep].right.height,
          }}
        />
        <div
          className={this.props.styles.tourBackdropBottom}
          style={{
            width: this.state.steps[this.state.currentStep].bottom.width,
            height: this.state.steps[this.state.currentStep].bottom.height,
            left: this.state.steps[this.state.currentStep].bottom.left,
          }}
        />
        <div
          className={this.props.styles.tourBackdropLeft}
          style={{
            width: this.props.steps[this.state.currentStep].left.width,
            height: this.props.steps[this.state.currentStep].left.height,
          }}
        />
        <div
          className={this.props.styles.popover}
          style={{
            top: this.props.steps[this.state.currentStep].popover.top,
            left: this.props.steps[this.state.currentStep].popover.left,
          }}
        >
          <h3 className={this.props.styles.popoverTitle}>
            {this.state.steps[this.state.currentStep].title}
          </h3>
          <p className={this.props.styles.popoverDescription}>
            {this.state.steps[this.state.currentStep].description}
          </p>
          <div className={this.props.styles.popoverNavigation}>
            <ButtonGroup aria-label="outlined primary button group">
              <Button
                disabled={this.state.currentStep === 0}
                onClick={() => {
                  this.handleStepChange(this.state.currentStep - 1)
                }}
              >
                Prev
              </Button>
              <Button
                disabled={
                  this.state.currentStep === this.state.steps.length - 1
                }
                onClick={() => {
                  this.handleStepChange(this.state.currentStep + 1)
                }}
              >
                Next
              </Button>
            </ButtonGroup>
            <Button
              className={this.props.styles.popoverEnd}
              onClick={() => {
                this.handleEndTutorial()
              }}
            >
              End Tour
            </Button>
          </div>
        </div>
      </div>
    ) : (
      <> </>
    )
  }
}

export default TutorialMode
