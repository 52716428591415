import { makeStyles } from '@material-ui/core/styles'

export const dashboardDialogStyles = makeStyles((theme) => ({
  dialogActionButton: {
    backgroundColor: theme.palette.backgroundColor.ternary,
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
}))

export default dashboardDialogStyles
