/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchCareerServices = createAsyncThunk(
  'fetchCareerServices',
  async () => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const res = await axios.get(process.env.REACT_APP_CAREERSERVICES, {
      headers: {
        JWT: jwtToken,
      },
    })
    return res.data
  },
)

const initialState = {
  status: 'idle',
  data: {
    title: 'Career Services',
  },
}

const careerServicesSlice = createSlice({
  name: 'careerServicesWidget',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchCareerServices.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchCareerServices.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchCareerServices.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.data = action.payload
    },
  },
})

export const selectCareerServicesData = (state) => state.careerServices.data
export const selectCareerServicesStatus = (state) => state.careerServices.status

export default careerServicesSlice.reducer
