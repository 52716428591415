/* eslint-disable react/no-danger */
import * as React from 'react'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useDispatch, useSelector } from 'react-redux'
import Widget from '../Widget'
import myWellnessStyles from './myWellnessStyles'
import {
  fetchMyWellness,
  selectMyWellnessData,
  selectMyWellnessStatus,
  fetchMyWellnessCategory,
  selectMyWellnessCategoryData,
  selectMyWellnessCategoryStatus,
  updateTherapeuticServices,
} from './myWellnessSlice'
import Loading from '../helpers/Loading'
import { logDashboardEvent } from '../../events'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'

// eslint-disable-next-line no-unused-vars
function MyWellness({
  className,
  title,
  widgetId,
  showNote,
  noteMessage,
  notePosition,
  noteBackground,
  noteIcon,
  noteUrl,
  noteFontColor,
}) {
  const dispatch = useDispatch()
  const widgetData = useSelector(selectMyWellnessData)
  const classes = myWellnessStyles()
  const widgetCallState = useSelector(selectMyWellnessStatus)
  const [detailedView, setDetailedView] = React.useState(false)
  const [openedWellnessCard, setOpenedWellnessCard] = React.useState('')

  React.useEffect(() => {
    dispatch(fetchMyWellness())
  }, [])

  return (
    <Widget className={className}>
      <Widget.Header title={title} category="default" />
      <Widget.Content
        className={classes.wellnessListContainer}
        showNote={showNote}
        noteMessage={noteMessage}
        notePosition={notePosition}
        noteBackground={noteBackground}
        noteIcon={noteIcon}
        noteUrl={noteUrl}
        noteFontColor={noteFontColor}
      >
        {detailedView ? (
          <RenderDetailedView
            widgetCallState={widgetCallState}
            openedWellnessCard={openedWellnessCard}
            setDetailedView={setDetailedView}
            setOpenedWellnessCard={setOpenedWellnessCard}
            classes={classes}
            widgetId={widgetId}
          />
        ) : (
          renderMyWellnessState(
            widgetCallState,
            widgetData,
            setOpenedWellnessCard,
            setDetailedView,
            classes,
            widgetId,
            dispatch,
          )
        )}
      </Widget.Content>
    </Widget>
  )
}

function renderMyWellnessState(
  widgetCallState,
  widgetData,
  setOpenedWellnessCard,
  setDetailedView,
  classes,
  widgetId,
  dispatch,
) {
  switch (widgetCallState) {
    case 'fulfilled':
      return (
        <Loaded
          widgetData={widgetData}
          setOpenedWellnessCard={setOpenedWellnessCard}
          setDetailedView={setDetailedView}
          classes={classes}
          widgetId={widgetId}
          dispatch={dispatch}
        />
      )
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage />
        </Grid>
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item></Grid>
        </Grid>
      )
  }
}

function Loaded(props) {
  return (
    <Box className={props.classes.wellnessListContainer}>
      <Grid container className={props.classes.wellnessCardGrid}>
        <Box className={props.classes.description}>
          <Typography variant="body1">
            {props.widgetData.description}
          </Typography>
        </Box>
        {props.widgetData.wellnessList.map((wellness, index) => (
          <WellnessObject
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            wellness={wellness}
            setOpenedWellnessCard={props.setOpenedWellnessCard}
            setDetailedView={props.setDetailedView}
            classes={props.classes}
          />
        ))}
      </Grid>
    </Box>
  )
}

function WellnessObject({
  wellness,
  setOpenedWellnessCard,
  setDetailedView,
  classes,
}) {
  const handleCardClick = () => {
    setOpenedWellnessCard(wellness)
    setDetailedView(true)
  }

  return (
    <Button
      className={classes.wellnessObject}
      variant="outlined"
      onClick={handleCardClick}
      style={{
        backgroundColor: wellness.color,
      }}
    >
      <Grid container direction="column">
        <Typography variant="subtitle1">{wellness.title}</Typography>
        <Typography variant="body2">{wellness.description}</Typography>
      </Grid>
    </Button>
  )
}

function RenderDetailedView({
  openedWellnessCard,
  setDetailedView,
  setOpenedWellnessCard,
  classes,
  widgetId,
}) {
  const dispatch = useDispatch()
  const widgetCallState = useSelector(selectMyWellnessCategoryStatus)
  const widgetCallData = useSelector(selectMyWellnessCategoryData)
  const backButtonHandler = () => {
    setDetailedView(false)
    setOpenedWellnessCard('')
  }

  React.useEffect(() => {
    if (openedWellnessCard.title === 'Therapeutic Services') {
      dispatch(updateTherapeuticServices())
    } else {
      dispatch(fetchMyWellnessCategory(openedWellnessCard.title))
    }
  }, [])

  switch (widgetCallState) {
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage />
        </Grid>
      )
    case 'fulfilled':
      return (
        <Box className={classes.wellnessListContainer}>
          <Grid container>
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              className={classes.header}
            >
              <Grid item>
                <Button
                  onClick={backButtonHandler}
                  startIcon={<ArrowBackIcon />}
                  style={{ color: openedWellnessCard.color }}
                />
              </Grid>
              <Grid item>
                <Typography
                  variant="h4"
                  style={{ color: openedWellnessCard.color }}
                >
                  {openedWellnessCard.title}
                </Typography>
              </Grid>
            </Grid>
            <Grid item className={classes.divider}>
              <Divider />
            </Grid>
            <Grid item>
              {openedWellnessCard.title === 'Therapeutic Services' &&
                renderDescriptionList(openedWellnessCard, classes)}
              {openedWellnessCard.title === 'Staying at Home' ||
              openedWellnessCard.title === 'Peer Support' ||
              openedWellnessCard.title === 'Physical Wellness' ||
              openedWellnessCard.title === 'Mental Wellness'
                ? renderVideoList(
                    widgetCallData,
                    classes,
                    openedWellnessCard,
                    widgetId,
                    dispatch,
                  )
                : null}
            </Grid>
          </Grid>
        </Box>
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item></Grid>
        </Grid>
      )
  }
}

function renderDescriptionList(openedWellnessCard, classes) {
  return (
    <Grid container spacing={2} className={classes.detailListContainer}>
      {openedWellnessCard.detail.descriptionList.map((listItem, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Grid item key={index}>
          <Box>
            <Typography
              variant="h6"
              style={{ color: openedWellnessCard.color }}
            >
              {listItem.title}
            </Typography>
            {listItem.description.map((paragraph, innerIndex) => (
              /* eslint-disable-next-line react/no-array-index-key */
              <Typography key={innerIndex} variant="body1">
                <span
                  // eslint-disable-next-line react/no-danger
                  className={classes.descriptionStyle}
                  dangerouslySetInnerHTML={{
                    __html: paragraph.replace(/href/g, "target='_blank' href"),
                  }}
                />
              </Typography>
            ))}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className={classes.myWellnessLinks}
              href={listItem.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {listItem.link}
            </a>
          </Box>
        </Grid>
      ))}
    </Grid>
  )
}

function renderVideoList(
  widgetCallData,
  classes,
  openedWellnessCard,
  widgetId,
  dispatch,
) {
  const handleCardClick = (link, title) => {
    if (widgetId && dispatch) {
      logDashboardEvent(dispatch, 'dashboardEvent', widgetId, title, link)
    }
    window.open(link, '_blank')
  }
  return (
    <Grid container spacing={2} className={classes.detailListContainer}>
      {Array.isArray(widgetCallData) &&
        widgetCallData.map((listItem, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Grid item key={index}>
            <Button
              /* eslint-disable-next-line react/no-array-index-key */
              key={index}
              onClick={() => handleCardClick(listItem.link, listItem.title)}
              className={classes.videoButton}
            >
              <Grid container direction="column">
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    align="left"
                    style={{ color: openedWellnessCard.color }}
                  >
                    {listItem.title}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography align="left" variant="body2">
                    {listItem.body}
                  </Typography>
                </Grid>
                <Grid item xs>
                  <img
                    className={classes.image}
                    src={listItem.image}
                    alt="vidImage"
                  />
                </Grid>
              </Grid>
            </Button>
          </Grid>
        ))}
    </Grid>
  )
}

export default MyWellness
