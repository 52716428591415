import {
  CAMDEN,
  Camden,
  NEWARK,
  NEW_BRUNSWICK,
  NewBrunswick,
  Newark,
  Other,
  PISCATAWAY,
} from '../../../utils/constants'

export function campusKeyToValueMap(key) {
  const map = {
    'NEW BRUNSWICK': NewBrunswick,
    NEWARK: Newark,
    CAMDEN: Camden,
  }
  return map[key]
}

// Get a list of buildings search criteria
export const getBuildingList = (buildings) => {
  const selectedCampusBuildings = {
    'New Brunswick': [],
    Newark: [],
    Camden: [],
    Other: [],
  }

  buildings.forEach((building) => {
    let key
    if (building.city === NEW_BRUNSWICK || building.city === PISCATAWAY) {
      key = NewBrunswick
    } else if (building.city === NEWARK) {
      key = Newark
    } else if (building.city === CAMDEN) {
      key = Camden
    } else {
      key = Other
    }

    selectedCampusBuildings[key].push({
      address: building.address,
      bid: building.bid,
      name: building.name,
      city: building.city,
      lat: building.lat,
      lng: building.lng,
      departments: building.departments,
      picture: building.picture,
      zip: building.zip,
    })
  })

  return selectedCampusBuildings
}

// Used to change map location when campus is selected
export const getLatLang = (city) => {
  const coordinates = {
    OTHER: {
      lat: 40.53149429948454,
      lang: -74.73776425857993,
      zoom: 9,
    },
    'NEW BRUNSWICK': {
      lat: 40.50045704434057,
      lang: -74.44818987833548,
      zoom: 14,
    },
    NEWARK: {
      lat: 40.74181263391162,
      lang: -74.1725927831175,
      zoom: 15,
    },
    CAMDEN: {
      lat: 39.948421629368006,
      lang: -75.12184399053766,
      zoom: 15,
    },
  }

  return coordinates[city]
}

export const sortBuildings = (list) =>
  list.slice().sort((a, b) => {
    const nameA = a.name.toUpperCase()
    const nameB = b.name.toUpperCase()
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  })

export function filterBuildingsByFavorite(buildings, favoritedBuildings) {
  // Check if both lists have items
  if (buildings.length > 0 && favoritedBuildings.size > 0) {
    return buildings.filter(
      (a) => !favoritedBuildings.some((b) => a.bid === b.bid),
    )
  }
  // If one of the lists is empty, return the buildingsFavorites as is
  return buildings
}
