import { makeStyles } from '@material-ui/styles'

export const AppAltStyles = makeStyles((theme) => ({
  root: {
    margin: '4px',
    height: '250px',
    width: '200px',
  },
  iconContainer: {
    width: '200px',
    height: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textContainer: {
    height: '60px',
  },
  appName: {
    marginLeft: '8px',
    marginTop: '5px',
    height: '25px',
  },
  iconButton: {
    position: 'relative',
    zIndex: 1000,
    fontSize: '21px',
    color: theme.palette.base.lightGray,
  },
  iconFocus: {
    '&:focus-visible': {
      outline: `1px solid ${theme.palette.button.focusBorder}`,
      boxShadow: `0 0 0 3px ${theme.palette.button.focusOutline}`,
    },
  },
  iconButtonBorder: {
    position: 'relative',
    zIndex: 1000,
    fontSize: '20px',
    color: theme.color.rutgersRed,
    marginBottom: '1px',
  },
  selectedFavoriteIconButton: {
    position: 'relative',
    zIndex: 1000,
    fontSize: '20px',
    color: theme.color.rutgersRed,
    margin: '0.8px',
  },
  favoritesDragHandle: {
    position: 'absolute',
    top: '1px',
    right: '-35px',
    width: '60px',
  },
  appNameText: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 2,
    wordBreak: 'keep-all',
    overflow: 'hidden',
    fontSize: '18px',
    color: theme.palette.primary.headerText,
    textAlign: 'center',
  },
  isNewBanner: {
    position: 'absolute',
    top: '15px',
    right: '-22px',
    width: '110px',
    transform: 'rotate(35deg)',
    backgroundColor: theme.palette.base.green,
  },
  isNewText: {
    color: 'black',
    fontSize: '12px',
    fontWeight: '600',
    textShadow: '2px',
  },
  maintenanceBanner: {
    position: 'absolute',
    top: '15px',
    right: '-22px',
    width: '110px',
    transform: 'rotate(35deg)',
    backgroundColor: theme.palette.base.yellow,
  },
  maintenanceText: {
    color: 'black',
    fontSize: '10px',
    fontWeight: '600',
    textShadow: '2px',
    marginLeft: 24,
  },
  maintenanceInfo: {
    color: theme.palette.base.red,
    fontSize: '13.3px',
  },
  marginBottom: {
    marginBottom: '10px',
  },
}))

export default AppAltStyles
