import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import ErrorIcon from '@material-ui/icons/Error'
import Grid from '@material-ui/core/Grid'

import Loading from '../helpers/Loading'
import Widget from '../Widget'
import { TabPanel } from '../../DashboardComponents/TabPanel/TabPanel'
import ApplyTab from './Tabs/ApplyTab'
import AwardTab from './Tabs/AwardTab'
import DocsTab from './Tabs/DocsTab'
import NotifsTab from './Tabs/NotifsTab'

import styles from './FinancialAidStyles'
import {
  fetchFinancialAid,
  selectFinancialAidData,
  selectFinancialAidStatus,
  fetchFinancialAidAwards,
  handleUpdateTab,
} from './FinanialAidSlice'
import {
  RutgersTabs,
  RutgersTab,
} from '../../DashboardComponents/Tabs/RutgersTabs'
import { fetchUser } from '../../../settings/settingsSlice'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'

// eslint-disable-next-line no-unused-vars
function FinancialAid({ className, title, widgetId }) {
  const classes = styles()
  const dispatch = useDispatch()
  const widgetData = useSelector(selectFinancialAidData)
  const widgetStatus = useSelector(selectFinancialAidStatus)

  React.useEffect(() => {
    const d = new Date()
    const year = d.getFullYear()
    dispatch(fetchUser()).then((responseData) => {
      const netid = responseData.payload?.data().netid
      dispatch(
        fetchFinancialAid({ netId: netid, awardYear: `${year}-${year + 1}` }),
      ).then((data) => {
        const programs =
          data.payload?.enrollment?.EnrollmentPrograms?.EnrollmentProgram?.map(
            (p) => p.Program,
          )
        if (programs) {
          Object.values(programs)?.forEach((program) =>
            dispatch(
              fetchFinancialAidAwards({
                netId: netid,
                programCode: program.ProgramCode,
                description: program.Description,
              }),
            ),
          )
        }
      })
    })
  }, [])

  return (
    <Widget className={className}>
      <Widget.Header title={title} category="default" />
      <Widget.Content className={classes.container}>
        {renderWidgetState(widgetStatus, widgetData, widgetId, classes)}
      </Widget.Content>
    </Widget>
  )
}

function renderWidgetState(widgetStatus, widgetData, widgetId, classes) {
  switch (widgetStatus) {
    case 'fulfilled':
      return (
        <Loaded widgetData={widgetData} classes={classes} widgetId={widgetId} />
      )
    case 'pending':
      return <Loading />
    case 'error':
      return <ErrorMessage />
    default:
      return <Loading />
  }
}

function Loaded({ widgetData, classes, widgetId }) {
  const dispatch = useDispatch()
  const { tabValue } = useSelector((s) => s.financialAid)
  const docsAlerts = widgetData.docsTab?.docs?.length > 0
  const notifsAlerts = widgetData.notifications?.response?.length > 0
  const awardAlerts = widgetData.awardTab?.formattedAwards?.length > 0
  const applyLabel = <Typography variant="body1">Apply</Typography>
  const docsLabel = (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-around"
    >
      <Typography variant="body1">Docs</Typography>
      {docsAlerts && <ErrorIcon />}
    </Grid>
  )
  const notifsLabel = (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-around"
    >
      <Typography variant="body1">Notifs</Typography>
      {notifsAlerts && <ErrorIcon />}
    </Grid>
  )
  const awardLabel = (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-around"
    >
      <Typography variant="body1">Award</Typography>
      {awardAlerts && <ErrorIcon />}
    </Grid>
  )

  const handleChange = (event, newValue) => {
    dispatch(handleUpdateTab(newValue))
  }

  return (
    <Box className={classes.modalTab}>
      <RutgersTabs value={tabValue} onChange={handleChange}>
        <RutgersTab label={applyLabel} />
        <RutgersTab label={docsLabel} />
        <RutgersTab label={notifsLabel} />
        <RutgersTab label={awardLabel} />
      </RutgersTabs>
      <TabPanel value={tabValue} index={0} className={classes.panel}>
        <ApplyTab
          classes={classes}
          widgetData={widgetData}
          widgetId={widgetId}
          dispatch={dispatch}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1} className={classes.panel}>
        <DocsTab
          classes={classes}
          widgetData={widgetData}
          widgetId={widgetId}
          dispatch={dispatch}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={2} className={classes.panel}>
        <NotifsTab
          classes={classes}
          widgetData={widgetData}
          widgetId={widgetId}
          dispatch={dispatch}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={3} className={classes.panel}>
        <AwardTab
          classes={classes}
          widgetData={widgetData}
          widgetId={widgetId}
          dispatch={dispatch}
        />
      </TabPanel>
    </Box>
  )
}

export default FinancialAid
