import * as React from 'react'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import { Menu, IconButton, Grid, Tooltip, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import ListAltIcon from '@material-ui/icons/ListAlt'
import CheckIsMobile from '../../../../../utils/isMobileHook'
import NewsSourceList from '../NewsSourceList/NewsSourceList'
import { useNewsSourceListDropDownStyles } from './NewsSourceListDropDownStyles'
import { selectDarkMode } from '../../../settings/settingsSlice'

const NewsSourceListDropDown = () => {
  // eslint-disable-next-line no-use-before-define
  const isMobile = CheckIsMobile()
  const history = useHistory()
  const isDark = useSelector(selectDarkMode)
  const classes = useNewsSourceListDropDownStyles()
  const { sources } = useSelector((s) => s.news)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    if (isMobile) {
      history.push({
        pathname: './NewsSourceListMobile',
      })
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      {!_.isEmpty(sources) && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <div className={classes.flex}>
              <Tooltip
                title={
                  <Typography variant="subtitle2">
                    News Source List Menu
                  </Typography>
                }
                placement="top"
                arrow
              >
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="news source list menu"
                  onClick={handleClick}
                >
                  <ListAltIcon className={classes.appBarIcon} />
                </IconButton>
              </Tooltip>
              <Menu
                className={classes.alertMenu}
                keepMounted
                elevation={5}
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    minWidth: '20vw',
                    backgroundColor: isDark ? '#313131' : '#f7f7f7',
                  },
                }}
              >
                <div>
                  <NewsSourceList />
                </div>
              </Menu>
            </div>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default NewsSourceListDropDown
