/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { doc, collection, getDoc, updateDoc } from 'firebase/firestore'
import axios from 'axios'
import { firestore } from '../../../../../utils/firebase'

const initialState = {
  data: {
    title: 'My Grades',
    semesters: [],
  },
  status: 'idle',
  showGradeTutorial: false,
  tab: 'ru',
}

export const fetchGrade = createAsyncThunk('fetchGrade', async () => {
  const jwtToken = localStorage.getItem('myrJwtToken')
  // const swtToken = localStorage.getItem('myrSwtToken')
  const rcpid = localStorage.getItem('rcpid')
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'production') {
    return axios.all([
      axios.get(`${process.env.REACT_APP_GRADE_ENDPOINT}`, {
        headers: {
          JWT: jwtToken,
          XWT: rcpid,
        },
      }),
    ])
  }

  return axios.all([
    axios.get(`${process.env.REACT_APP_GRADE_ENDPOINT}`, {
      headers: {
        JWT: jwtToken,
        XWT: rcpid,
      },
    }),
  ])
})

export const fetchDefaultTutorialData = createAsyncThunk(
  'fetchDefaultTutorialData',
  async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_GRADE_DUMMY_ENDPOINT}`,
      {},
    )
    return res.data
  },
)

export const gradeSlice = createSlice({
  name: 'GradeWidget',
  initialState,
  reducers: {
    endTutorial: (state) => {
      const userDocRef = doc(
        collection(firestore, 'users'),
        localStorage.getItem('user') ?? 'visitor',
        'settings',
        'tutorials',
      )
      state.showGradeTutorial = false
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'production')
        getDoc(userDocRef)
      updateDoc(userDocRef, { show_my_grades_tutorial: false })
    },
    updateTab: (state, action) => {
      state.tab = action.payload
    },
  },
  extraReducers: {
    [fetchGrade.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchGrade.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchGrade.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.data = action.payload[0].data
      // select default tab
      state.tab =
        action.payload[0].data?.rbhs && action.payload[0].data?.rbhs.length > 0
          ? 'rbhs'
          : 'ru'
    },
    [fetchDefaultTutorialData.rejected]: (state) => {
      state.defaultTutorialStatus = 'error'
    },
    [fetchDefaultTutorialData.pending]: (state) => {
      state.defaultTutorialStatus = 'pending'
    },
    [fetchDefaultTutorialData.fulfilled]: (state, action) => {
      state.defaultTutorialStatus = 'fulfilled'
      state.defaultTutorialData = action.payload
      state.showGradeTutorial = true
    },
  },
})
export const selectGradeData = (state) => state.grade.data
export const selectGradeStatus = (state) => state.grade.status
export const selectShowGradeTutorial = (state) => state.grade.showGradeTutorial
export const selectGradeTab = (state) => state.grade.tab
export const selectTab = (state) => state.grade.tab
export const { endTutorial, updateTab } = gradeSlice.actions
export const selectDefaultTutorialData = (state) =>
  state.grade.defaultTutorialData
export const selectDefaultTutorialStatus = (state) =>
  state.grade.defaultTutorialStatus

export default gradeSlice.reducer
