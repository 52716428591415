/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { sortBuildings } from '../utils/helpers'
import {
  fetchAllBuildings,
  fetchRestaurantsApi,
  fetchParkingNearBuildingApi,
} from '../api/api'
import { fetchUserFavoritesFromFirebase } from '../../../firebase/firebase'

export const fetchBuildings = createAsyncThunk(
  'map/fetchBuildings',
  async () => {
    try {
      // Fetch the list of all buildings and the user's favorited buildings
      const { buildingsList, campusBuildings } = await fetchAllBuildings()

      return {
        buildingsList,
        campusBuildings,
      }
    } catch (error) {
      console.error('Error fetching buildings and favorites:', error)
      throw error // Propagate the error so Redux knows about the failure
    }
  },
)

export const fetchBuildingsFavorites = createAsyncThunk(
  'map/fetchBuildingsFavorites',
  async (_, { getState }) => {
    const state = getState()
    const { buildings } = state.buildings
    const favoritesBuildingsList = await fetchUserFavoritesFromFirebase(
      'buildings',
    )

    const favoritedBuildingObjectList = []
    if (favoritesBuildingsList && favoritesBuildingsList.length > 0) {
      favoritesBuildingsList.map((id) => {
        const buildingObj = buildings.filter((building) => building.bid === id)
        return favoritedBuildingObjectList.push(buildingObj[0])
      })
    }
    return {
      favoritesBuildingsList: favoritedBuildingObjectList,
    }
  },
)

export const fetchParkingNearBuilding = createAsyncThunk(
  'map/fetchParkingNearBuilding',
  async () => {
    const response = await fetchParkingNearBuildingApi()
    return response.data
  },
)

export const fetchRestaurants = createAsyncThunk(
  'map/fetchRestaurants',
  async (coord) => {
    const returnData = await fetchRestaurantsApi(coord)
    return returnData
  },
)

const initialState = {
  buildingSubheader: 'FAVORITES',

  buildings: [],
  campusBuildings: {
    'New Brunswick': [],
    Newark: [],
    Camden: [],
    Other: [],
  },

  selectedBuilding: {},
  selectedBuildingID: null,
  selectedCampusBuildings: {
    'New Brunswick': [],
    Newark: [],
    Camden: [],
    Other: [],
  },

  favoritedBuildings: [], // These values don't change unless there's a db change
  selectedFavoritedBuildings: [], // Selected is the value that's used from the search

  selectedParkingSpot: '',
  nearestParking: {},

  restaurantData: [{ name: '' }],
  restaurantButtonSelected: false,
  showRestaurantMobile: false,

  buildingsLoading: 'idle',
  restaurantLoading: 'idle',
  nearestParkingLoading: 'idle',
}

const buildingSlice = createSlice({
  name: 'buildings',
  initialState,
  reducers: {
    setCampusBuildings: (state, action) => ({
      ...state,
      campusBuildings: action.payload,
    }),
    setSelectedBuilding: (state, action) => {
      const { lat, lng } = action.payload
      return {
        ...state,
        selectedBuilding: action.payload,
        lat: parseFloat(lat),
        lng: parseFloat(lng),
        zoom: 18,
      }
    },
    setBuildingSubheader: (state, action) => {
      if (state.buildingSubheader !== action.payload) {
        state.buildingSubheader = action.payload
      }
    },

    setSelectedCampusBuildings: (state, action) => ({
      ...state,
      selectedCampusBuildings: action.payload,
    }),
    handleViewBuildingInfoSheet: (state, action) => ({
      // Update the search bar contents
      ...state,
      selectedBuildingID: action.payload,
    }),

    addBuildingToFavorite: (state, action) => ({
      // Update the search bar contents
      ...state,
      favoritedBuildings: sortBuildings(action.payload),
    }),
    removeBuildingFromFavorite: (state, action) => ({
      // Update the search bar contents
      ...state,
      favoritedBuildings: sortBuildings(action.payload),
    }),
    setSelectedFavoritedBuildings: (state, action) => ({
      ...state,
      selectedFavoritedBuildings: action.payload,
    }),

    handleParkingClicked: (state, action) => ({
      ...state,
      selectedParkingSpot: action.payload,
    }),

    handleNearestStopClicked: (state, action) => {
      const bid = action.payload
      let building = {}
      for (let i = 0; i < state.buildings.length; i++) {
        if (state.buildings[i].bid === bid) {
          building = state.buildings[i]
          break
        }
      }
      return {
        ...state,
        selectedBuildingID: bid,
        selectedBuilding: building,
      }
    },

    setRestaurantButtonSelected: (state, action) => ({
      ...state,
      restaurantButtonSelected: action.payload,
    }),

    handleRestaurantMobileClick: (state, action) => ({
      ...state,
      showRestaurantMobile: action.payload,
    }),

    resetParking: (state) => ({
      ...state,
      selectedParkingSpot: '',
      nearestParking: {},
    }),
    resetBuilding: (state) => ({
      ...state,
      selectedBuilding: {},
    }),
    resetBuildingSlice: (state) => ({
      ...state,
      selectedParkingSpot: '',
      nearestParking: {},
      selectedBuilding: {},
    }),
  },
  extraReducers: {
    [fetchBuildings.pending]: (state) => ({
      ...state,
      buildingsLoading: 'pending',
    }),
    [fetchBuildings.fulfilled]: (state, action) => ({
      ...state,
      buildingsLoading: 'fulfilled',
      buildings: action.payload.buildingsList,
      campusBuildings: action.payload.campusBuildings,
      error: false,
    }),
    [fetchBuildings.rejected]: (state, action) => ({
      ...state,
      buildingsLoading: 'error',
      error: action.payload,
    }),

    [fetchBuildingsFavorites.pending]: (state) => ({
      ...state,
      buildingFavoritesLoading: 'pending',
    }),
    [fetchBuildingsFavorites.fulfilled]: (state, action) => ({
      ...state,
      buildingFavoritesLoading: 'fulfilled',
      favoritedBuildings: sortBuildings(action.payload.favoritesBuildingsList),
      selectedFavoritedBuildings: action.payload.favoritesBuildingsList,
    }),
    [fetchBuildingsFavorites.rejected]: (state) => ({
      ...state,
      buildingFavoritesLoading: 'error',
    }),

    [fetchParkingNearBuilding.pending]: (state) => ({
      ...state,
      nearestParkingLoading: 'pending',
    }),
    [fetchParkingNearBuilding.fulfilled]: (state, action) => ({
      ...state,
      nearestParkingLoading: 'fulfilled',
      nearestParking: action.payload,
      zoom: 16,
      error: false,
    }),
    [fetchParkingNearBuilding.rejected]: (state, action) => ({
      ...state,
      error: action.payload,
      nearestParkingLoading: 'error',
    }),

    [fetchRestaurants.pending]: (state) => ({
      ...state,
      restaurantLoading: 'pending',
    }),
    [fetchRestaurants.fulfilled]: (state, action) => ({
      ...state,
      restaurantLoading: 'fulfilled',
      restaurantData: action.payload,
      error: false,
    }),
    [fetchRestaurants.rejected]: (state, action) => ({
      ...state,
      error: action.payload,
      restaurantLoading: 'error',
    }),
  },
})

export const {
  setCampusBuildings,
  setSelectedBuilding,
  setBuildingSubheader,
  setSelectedCampusBuildings,
  handleViewBuildingInfoSheet,

  addBuildingToFavorite,
  removeBuildingFromFavorite,
  setSelectedFavoritedBuildings,

  handleParkingClicked,
  handleNearestStopClicked,

  setRestaurantButtonSelected,
  handleRestaurantMobileClick,

  resetParking,
  resetBuilding,
  resetBuildingSlice,
} = buildingSlice.actions

export default buildingSlice.reducer
