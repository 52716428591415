import React from 'react'
import {
  Checkbox,
  Divider,
  IconButton,
  Typography,
  Paper,
} from '@material-ui/core'

import useSortableItemStyles from './SortableItemStyles'
import Widget from '../../../../dashboard/widgets/Widget'

export default function Item({
  source,
  setEdited,
  unsubscribedList,
  setUnsubscribedList,
}) {
  const classes = useSortableItemStyles()
  const disabledSources = ['Rutgers Today']
  const updateList = (subscribe) => {
    let newList = []
    if (!subscribe) {
      newList = [...unsubscribedList, source.name]
    } else {
      newList = unsubscribedList.filter((name) => name !== source.name)
    }
    setEdited(true)
    setUnsubscribedList(newList)
  }

  return (
    <Paper className={classes.card} variant="outlined">
      <div className={classes.rowContainer}>
        <Typography variant="subtitle1">
          {source && source.name && (
            <>
              {source.name
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')}
            </>
          )}
        </Typography>
        <div>
          <IconButton>
            <Checkbox
              size="small"
              disabled={disabledSources.includes(source.name)}
              onClick={() => updateList(unsubscribedList.includes(source.name))}
              checked={!unsubscribedList.includes(source.name)}
            />
          </IconButton>
        </div>
      </div>
      <Divider variant="fullWidth" className={classes.divider} />
      <Widget.Spacer />
    </Paper>
  )
}
