import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  updateSearchBarContents,
  showSearchBarContents,
  updateSearchQuery,
} from '../../appSlice'
import SearchBar from '../../../dashboard/DashboardComponents/SearchBar/SearchBar'
import AppSearchBarStyles from './AppSearchBarStyles'

/**
 * When the search bar is triggered, it will switch to the ShowAppList view where you pass in the applist you want to see
 * The search bar updates that applist by filtering the entire list each time you enter a new char
 */
function AppSearchBar() {
  const dispatch = useDispatch()
  const classes = AppSearchBarStyles()
  const { displaySearchItems, searchQuery, apps } = useSelector((s) => s.myApps)
  function getSearchScore(item, val) {
    const query = val?.toLowerCase() // Convert the user's search query to lowercase
    if (item.title && item.title.toLowerCase().includes(query)) {
      return 1
    }

    if (item.tags && item.tags.some((tag) => tag.toLowerCase() === query)) {
      return 1
    }

    return 0
  }

  // eslint-disable-next-line no-use-before-define
  const searchHandler = (event) => {
    const val = event.target.value
    dispatch(updateSearchQuery(val))
    if (!displaySearchItems) {
      // Open or Close Search Bar Page
      dispatch(showSearchBarContents(true))
    } else if (displaySearchItems && val.length === 0) {
      dispatch(showSearchBarContents(false))
    } // Update the list that is being displayed
    dispatch(
      updateSearchBarContents(
        apps
          ?.filter((item) => getSearchScore(item, val) > 0)
          ?.sort((a, b) => getSearchScore(b, val) - getSearchScore(a, val)),
      ),
    )
  }

  const cancelSearch = () => {
    dispatch(updateSearchQuery(''))
    dispatch(updateSearchBarContents([]))
    dispatch(showSearchBarContents(false))
  }

  return (
    <SearchBar
      className={classes.searchBar}
      value={searchQuery}
      onChange={(val) => searchHandler(val)}
      onCancelSearch={cancelSearch}
      placeholder="Search Apps"
      moduleName="myApps"
      dispatch={dispatch}
    />
  )
}

export default AppSearchBar
