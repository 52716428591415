/* eslint-disable no-underscore-dangle */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OverlayView } from '@react-google-maps/api'
import uuid from 'react-uuid'
import isEqual from 'lodash/isEqual'
import IconPicker from '../../../../../apps/AppComponents/IconPicker/IconPicker'
import { updateBottomSheet } from '../../redux/myMapsSlice'
import { PARKINGMAP } from '../../../../utils/constants'
import { handleParkingClicked } from '../../../Buildings/redux/myBuildingsSlice'

function ParkingMarker(props) {
  const dispatch = useDispatch()
  const { parking } = props
  const [selected, setSelected] = React.useState(false)
  const { selectedParkingSpot } = useSelector((s) => s.buildings)

  React.useEffect(() => {
    if (isEqual(parking, selectedParkingSpot)) {
      setSelected(true)
    } else {
      setSelected(false)
    }
  }, [selectedParkingSpot])

  const handleClick = () => {
    setSelected(true)
    dispatch(handleParkingClicked(parking))
    dispatch(updateBottomSheet(PARKINGMAP))
  }

  const iconColor = selected ? '#c45e1f' : '#FF8A47'

  return (
    <button
      type="button"
      aria-label="Custom Marker"
      onClick={() => handleClick()}
    >
      <OverlayView
        key={uuid()}
        position={{
          lat: parking.lat,
          lng: parking.lng,
        }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <IconPicker iconName="fas fa-parking" color={iconColor} size="3x" />
      </OverlayView>
    </button>
  )
}

export default ParkingMarker
