import React from 'react'
import WidgetFormTextField from './components/WidgetFormTextField'
import WidgetFormCheckBox from './components/WidgetFormCheckBox'

function AdvancedWidgetForm({ classes, focusedWidget, handleFormChange }) {
  return (
    <div className={classes.advancedWidgetFormContainer}>
      <div className={classes.rowContainer}>
        <div className={classes.rowItem}>
          <WidgetFormTextField
            classes={classes}
            focusedWidget={focusedWidget}
            title="Custom"
            field="custom"
            handleFormChange={handleFormChange}
          />
        </div>

        <div className={classes.rowItem}>
          <WidgetFormTextField
            classes={classes}
            focusedWidget={focusedWidget}
            title="WS"
            field="ws"
            handleFormChange={handleFormChange}
          />
        </div>
      </div>

      <div className={classes.rowContainer}>
        <div className={classes.rowItem}>
          <WidgetFormTextField
            classes={classes}
            focusedWidget={focusedWidget}
            title="Screenshot"
            field="screenshot"
            handleFormChange={handleFormChange}
          />
        </div>
      </div>

      <div className={classes.rowContainer} />
      <div className={classes.rowContainer} />

      <div className={classes.rowContainer}>
        <div className={classes.rowItem}>
          <WidgetFormCheckBox
            classes={classes}
            focusedWidget={focusedWidget}
            title="DSP Apps"
            field="dsp_apps"
            handleFormChange={handleFormChange}
          />
        </div>
        <div className={classes.rowItem}>
          <WidgetFormCheckBox
            classes={classes}
            focusedWidget={focusedWidget}
            title="DSP Note"
            field="dsp_note"
            handleFormChange={handleFormChange}
          />
        </div>
      </div>
      <div className={classes.rowContainer}>
        <div className={classes.rowItem}>
          <WidgetFormCheckBox
            classes={classes}
            focusedWidget={focusedWidget}
            title="DSP Title"
            field="dsp_title"
            handleFormChange={handleFormChange}
          />
        </div>
        <div className={classes.rowItem}>
          <WidgetFormCheckBox
            classes={classes}
            focusedWidget={focusedWidget}
            title="DSP Refresh"
            field="dsp_refresh"
            handleFormChange={handleFormChange}
          />
        </div>
      </div>

      <div className={classes.rowContainer}>
        <div className={classes.rowItem}>
          <WidgetFormCheckBox
            classes={classes}
            focusedWidget={focusedWidget}
            title="DSP Refresh Time"
            field="dsp_refresh_time"
            handleFormChange={handleFormChange}
          />
        </div>
        <div className={classes.rowItem}>
          <WidgetFormCheckBox
            classes={classes}
            focusedWidget={focusedWidget}
            title="Lock"
            field="lock"
            handleFormChange={handleFormChange}
          />
        </div>
      </div>

      <div className={classes.rowContainer} />
      <div className={classes.rowContainer} />

      <div className={classes.rowContainer}>
        <div className={classes.rowItem}>
          <WidgetFormTextField
            classes={classes}
            focusedWidget={focusedWidget}
            title="Note URL"
            field="note_url"
            handleFormChange={handleFormChange}
          />
        </div>

        <div className={classes.rowItem}>
          <WidgetFormTextField
            classes={classes}
            focusedWidget={focusedWidget}
            title="Note Roles"
            field="note_roles"
            handleFormChange={handleFormChange}
          />
        </div>
      </div>

      <div className={classes.rowContainer}>
        <div className={classes.rowItem}>
          <WidgetFormTextField
            classes={classes}
            focusedWidget={focusedWidget}
            title="Note Message"
            field="note_msg"
            handleFormChange={handleFormChange}
          />
        </div>

        <div className={classes.rowItem}>
          <WidgetFormTextField
            classes={classes}
            focusedWidget={focusedWidget}
            title="Note Position"
            field="note_position"
            handleFormChange={handleFormChange}
          />
        </div>
      </div>

      <div className={classes.rowContainer}>
        <div className={classes.rowItem}>
          <WidgetFormTextField
            classes={classes}
            focusedWidget={focusedWidget}
            title="Note Icon"
            field="note_icon"
            handleFormChange={handleFormChange}
          />
        </div>

        <div className={classes.rowItem}>
          <WidgetFormTextField
            classes={classes}
            focusedWidget={focusedWidget}
            title="Note Font Color"
            field="note_fontColor"
            handleFormChange={handleFormChange}
          />
        </div>
      </div>

      <div className={classes.rowContainer}>
        <div className={classes.rowItem}>
          <WidgetFormTextField
            classes={classes}
            focusedWidget={focusedWidget}
            title="Note Campus IDs"
            field="note_campusIds"
            handleFormChange={handleFormChange}
          />
        </div>

        <div className={classes.rowItem}>
          <WidgetFormTextField
            classes={classes}
            focusedWidget={focusedWidget}
            title="Note Background"
            field="note_background"
            handleFormChange={handleFormChange}
          />
        </div>
      </div>
    </div>
  )
}

export default AdvancedWidgetForm
