import React from 'react'
import { useSelector } from 'react-redux'
import { Box, Typography } from '@material-ui/core'
import { TabPanel } from '../../../../../dashboard/DashboardComponents/TabPanel/TabPanel'

import NearestBusStopsTabs from './NearestBusStopsTabs'
import BuildingStreetView from '../BuildingStreetView/BuildingStreetView'
export default function NearestBusStops() {
  const { nearestStops, nearestStopsLoading, nearestStopsTabValue } =
    useSelector((s) => s.maps)

  if (nearestStopsLoading === 'error') {
    return (
      <Typography variant="h2">
        Unable to obtain nearest stops. Please try again later.
      </Typography>
    )
  }

  return nearestStopsLoading !== 'fulfilled' ? (
    <span>loading</span>
  ) : (
    <Box display="flex" flexDirection="column">
      <Box display="flex">
        <NearestBusStopsTabs tabs={nearestStops.stopList} />
      </Box>
      <Box padding="10px">
        <TabPanel value={nearestStopsTabValue} index={0}>
          <BuildingStreetView nearestStops={nearestStops} />
        </TabPanel>
        <TabPanel value={nearestStopsTabValue} index={1}>
          <BuildingStreetView nearestStops={nearestStops} />
        </TabPanel>
        <TabPanel value={nearestStopsTabValue} index={2}>
          <BuildingStreetView nearestStops={nearestStops} />
        </TabPanel>
      </Box>
    </Box>
  )
}
