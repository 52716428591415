import { makeStyles } from '@material-ui/core/styles'

export const loginClasses = makeStyles((theme) => ({
  heroImage: {
    height: '100%',
    width: '100%',
    background:
      'linear-gradient(rgba(204, 0, 51, 0.8), rgba(204, 0, 51, 0.8)), url(./assets/images/hero-image.jpeg)',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  carouselRoot: {
    height: '100%',
    position: 'relative',
  },
  carouselItem: {
    margin: '0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    color: theme.palette.primary.contrastText,
  },
  carouselImage: {
    maxHeight: '200px',
  },
  copyRightText: {
    color: theme.palette.base.red,
    textDecoration: 'underline',
    fontSize: '.875rem',
    '&:hover': {
      color: theme.palette.base.red,
    },
  },
}))

export default loginClasses
