/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery, Tab, Tabs, useTheme } from '@material-ui/core'
import {
  TabPanel,
  a11yProps,
} from '../../../../dashboard/DashboardComponents/TabPanel/TabPanel'
import { setMapSearchBottomSheetSelectedTab } from '../../../sections/Map/redux/myMapsSlice'
import {
  BOTTOMSHEET_SEARCH_TAB_LABELS_DESKTOP,
  BOTTOMSHEET_SEARCH_TAB_LABELS_MOBILE,
} from '../../../utils/constants'
import { useBottomSheetSearchTabs } from './BottomSheetSearchTabsStyles'

export default function BottomSheetSearchTabs({ TabOne, TabTwo, TabThree }) {
  const isDesktopView = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const labels = !isDesktopView
    ? BOTTOMSHEET_SEARCH_TAB_LABELS_MOBILE
    : BOTTOMSHEET_SEARCH_TAB_LABELS_DESKTOP

  const classes = useBottomSheetSearchTabs()
  const theme = useTheme()
  const dispatch = useDispatch()

  const { mapSearchBottomSheetSelectedTab } = useSelector((s) => s.maps)

  const handleChange = (event, newValue) => {
    dispatch(setMapSearchBottomSheetSelectedTab(newValue))
  }

  return (
    <div className={classes.root}>
      <Tabs
        className={classes.tabs}
        value={mapSearchBottomSheetSelectedTab}
        onChange={handleChange}
        scrollButtons="auto"
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
      >
        {labels.map((label, index) => (
          <Tab
            key={index}
            label={label}
            className={
              mapSearchBottomSheetSelectedTab === index
                ? classes.activeTab
                : classes.inactiveTab
            }
            {...a11yProps(index)}
          />
        ))}
      </Tabs>

      <TabPanel
        className={classes.root}
        style={{
          width: '100%',
        }}
        key="Bus"
        value={mapSearchBottomSheetSelectedTab}
        index={0}
        dir={theme.direction}
      >
        {TabOne}
      </TabPanel>

      <TabPanel
        className={classes.root}
        style={{
          width: '100%',
        }}
        key="Stops"
        value={mapSearchBottomSheetSelectedTab}
        index={1}
        dir={theme.direction}
      >
        {TabTwo}
      </TabPanel>

      <TabPanel
        className={classes.root}
        style={{
          width: '100%',
        }}
        key="Buildings"
        value={mapSearchBottomSheetSelectedTab}
        index={2}
        dir={theme.direction}
      >
        {TabThree}
      </TabPanel>
    </div>
  )
}
