import React from 'react'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import { Typography } from '@material-ui/core'
import Widget from '../../widgets/Widget'
import styles from './IconLinkStyles'

import IconPicker from '../../../apps/AppComponents/IconPicker/IconPicker'
import { logDashboardEvent } from '../../events'

function IconLink({
  icon,
  iconLabel,
  link,
  size = '3x',
  color = 'gray',
  disabled = false,
  widgetId,
  dispatch,
}) {
  const classes = styles()
  const fasIcon = <IconPicker iconName={icon} size={size} color={color} />

  return (
    <Box className={classes.iconLinkContainer}>
      <Widget.TabLink
        icon={fasIcon}
        iconLabel={iconLabel}
        hyperLink={link}
        disabled={disabled}
        widgetId={widgetId}
        dispatch={dispatch}
      />
    </Box>
  )
}

export function InlineIconLink({ iconLink, widgetId, dispatch }) {
  const classes = styles()

  const handleLinkClick = () => {
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardLinkEvent',
        widgetId,
        iconLink.label,
        iconLink.link,
      )
    }
  }

  return (
    <Grid item className={classes.rowContainer}>
      <Link href={iconLink.link} target="_blank" onClick={handleLinkClick}>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item>
            <IconPicker iconName={iconLink.icon} size="1x" />
          </Grid>
          <Grid item>
            <Typography className={classes.text} variant="subtitle1">
              {iconLink.iconLabel}
            </Typography>
          </Grid>
        </Grid>
      </Link>
    </Grid>
  )
}

export default IconLink
