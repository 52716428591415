import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import styles from './FooterStyles'

function Footer({ children, style, toggleStroke }) {
  const classes = styles()
  return (
    <Box className={classes.footer}>
      {toggleStroke ? (
        <Box
          sx={{
            height: '1px',
            bgcolor: 'gray',
            width: '100%',
            ...style,
          }}
        />
      ) : (
        <></>
      )}
      <Grid
        container
        direction="column"
        alignContent="center"
        justifyContent="center"
        className={classes.footerGrid}
      >
        {children}
      </Grid>
    </Box>
  )
}

export default Footer
