import { makeStyles } from '@material-ui/core/styles'

export const useNewsSourceListMobileStyles = makeStyles(() => ({
  container: {
    flex: 1,
    width: '100%',
    overscrollBehavior: 'contain',
    justifyContent: 'center',
  },
  appBar: {
    color: 'rgba(0,0,0,0.54)',
  },
  mobileBackButtonIcon: {
    fontSize: 24,
  },
  pageTitle: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 1,
    wordBreak: 'keep-all',
    overflow: 'hidden',
    fontSize: 22,
    fontWeight: '400',
    textOverflow: 'ellipsis',
    color: '#ffffff',
  },
  appBarIcon: {
    fontSize: 24,
  },
}))

export default useNewsSourceListMobileStyles
