import * as React from 'react'

/* Not Used. Just Here Temporarily */
function AdminPage() {
  return (
    <>
      <h1>ADMIN PAGE</h1>
    </>
  )
}

export default AdminPage
