/* eslint-disable */
import React from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  Box,
  Button,
  Card,
  CardActionArea,
  Grid,
  Typography,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import useGenericArticleStyles from './GenericArticleStyles'
import { analytics } from '../../../../../utils/firebase'
import { addProfileLogEvent } from '../../../dashboard/widgets/MyProfile/myProfileSlice'

function GenericArticle({ article, isLoading = false }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useGenericArticleStyles()
  return (
    <Box className={classes.genericArticleContainer} key={article.id}>
      {isLoading ? (
        <Skeleton width={125} />
      ) : (
        <Button
          component={Link}
          to={{
            pathname: './NewsSourcePage',
            search: `?id=${article.sourceID}&source=${article.sourceName}`,
          }}
          className={classes.sponsorLink}
          onClick={(e) => {
            e.stopPropagation()
            dispatch(
              addProfileLogEvent({
                module: 'News',
                object: 'Articles',
                objectId: encodeURIComponent(article.id),
                action: 'Click',
                actionContext: 'ViewArticle',
                actionContextValue: '',
                rcpid: localStorage.getItem('rutgersEduRCPID'),
                timestamp: Date.now(),
              }),
            )
          }}
        >
          <Typography className={classes.sponsorLinkText}>
            {article.sourceName}
          </Typography>
        </Button>
      )}
      <Card elevation={0} className={classes.card}>
        <CardActionArea
          onClick={(e) => {
            e.preventDefault()
            if (process.env.REACT_APP_FIREBASE_ON === 'true')
              analytics.logEvent('article_shown', { article: article.id })
            history.push({
              pathname: './ViewArticlePage',
              search: `?newsID=${article.id}`,
              state: article,
            })
          }}
        >
          <Grid container className={classes.articleContainer}>
            <Grid item xs={8}>
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid className={classes.fakedSponserLinkSpace} item />

                {isLoading ? (
                  <Skeleton width={220} style={{ marginTop: 8 }} />
                ) : (
                  <Grid item style={{ paddingTop: 0 }}>
                    <Typography className={classes.articleTitle}>
                      {article.id}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid item xs>
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="flex-end"
                spacing={2}
              >
                {isLoading ? (
                  <Skeleton width={130} />
                ) : (
                  <Grid item>
                    <Typography className={classes.timeElapsedText}>
                      {article.published}
                    </Typography>
                  </Grid>
                )}
                <Grid item className={classes.buttonContainer}>
                  {isLoading ? (
                    <Skeleton width={100} style={{ marginTop: 24 }} />
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      component={Link}
                      to={{
                        pathname: './ViewArticlePage',
                        search: `?newsID=${article.id}`,
                        state: article,
                      }}
                      onClick={(e) => {
                        // This prevent default is necessary or you'll trigger the
                        // cardactionarea's link as well.
                        e.preventDefault()
                        if (process.env.REACT_APP_FIREBASE_ON === 'true')
                          analytics.logEvent('article_shown', {
                            article: article.id,
                          })
                      }}
                    >
                      <Typography className={classes.buttonText}>
                        Learn More
                      </Typography>
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    </Box>
  )
}

export default GenericArticle
