import * as React from 'react'
import { Button, Box, Typography as Text } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import {
  selectMyFavoriteAppsList,
  updateReorderFavoriteAppsFlag,
  selectReorderFavoriteApps,
} from '../../../apps/appSlice'
import IconPicker from '../../../apps/AppComponents/IconPicker/IconPicker'
import MyFavoriteStyles from './myFavoriteStyles'

function MyFavoritesButton({ title, title2 }) {
  const dispatch = useDispatch()
  const classes = MyFavoriteStyles()
  const [reorderFlag, setReorderFlag] = React.useState(false) // State for the iconButton
  const reorderFavoritesAppList = useSelector(selectReorderFavoriteApps) // Redux value which receives a signal from AppTabs
  const favoritesAppList = useSelector(selectMyFavoriteAppsList)
  // If reorderFavoritesAppList changes, check it's state
  React.useEffect(() => {
    if (!reorderFavoritesAppList) {
      // If it is true -> false, set setReorderFlag to false
      setReorderFlag(false)
    }
  }, [reorderFavoritesAppList])
  // Exit edit mode when first rendering widget (in case user left apps page in edit mode)
  React.useEffect(() => {
    dispatch(updateReorderFavoriteAppsFlag(false))

    // dispatch event to exit edit mode in my favorites page
    return () => {
      dispatch(updateReorderFavoriteAppsFlag(false))
    }
  }, [])

  // Handle the onClick
  const handleModifyFavoritesAppListButton = () => {
    if (reorderFlag) {
      // True -> False. Update the state and redux value
      setReorderFlag(false)
      dispatch(updateReorderFavoriteAppsFlag(false))
    } else {
      setReorderFlag(true)
      dispatch(updateReorderFavoriteAppsFlag(true))
    }
  }
  // if favorites list is empty, do not render button
  return (
    <>
      {favoritesAppList.length ? (
        <Button
          aria-label={reorderFavoritesAppList ? title2 : title}
          className={
            reorderFavoritesAppList
              ? classes.saveFavsButton
              : classes.manageFavsButton
          }
          variant="outlined"
          onClick={() => handleModifyFavoritesAppListButton()}
        >
          <Box className={classes.footerButtonContent}>
            {reorderFavoritesAppList ? (
              <Box style={{ marginRight: '10px' }}>
                <IconPicker iconName="fas fa-check" size="xl" />
              </Box>
            ) : (
              <Box style={{ marginRight: '10px' }}>
                <ImportExportIcon
                  style={{ display: 'flex', alignItems: 'center' }}
                />
              </Box>
            )}

            <Text aria-hidden="true" className={classes.footerButtonText}>
              {reorderFavoritesAppList ? title2 : title}
            </Text>
          </Box>
        </Button>
      ) : (
        <></>
      )}
    </>
  )
}

export default MyFavoritesButton
