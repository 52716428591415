import React from 'react'
import { Typography, TextField } from '@material-ui/core'

function WidgetFormTextField({
  classes,
  title,
  field,
  disabled = false,
  handleFormChange,
  focusedWidget,
}) {
  return (
    <>
      <Typography className={classes.formTitle}> {title}</Typography>
      <TextField
        className={classes.textField}
        InputProps={{
          className: classes.textFieldInputProps,
        }}
        disabled={disabled}
        variant="outlined"
        placeholder={
          focusedWidget[field] ? focusedWidget[field].toString() : ''
        }
        value={focusedWidget[field]}
        onChange={(event) => handleFormChange(field, event)}
      />
    </>
  )
}

export default WidgetFormTextField
