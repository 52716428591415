import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) => ({
  container: {
    padding: '15px 5px 15px 5px',
    display: 'flex',
    overflow: 'auto',
    width: '100vw',
    margin: 0,
    marginLeft: '-20px',
    position: 'absolute',
    top: '50%',
    '-ms-transform': 'translateY(-50%)',
    transform: 'translateY(-50%)',
  },
  card: {
    flexShrink: '0',
    width: '335px',
    height: '515px',
    borderRadius: '10px',
    margin: '0 15px 20px 15px',
  },

  root: {
    flexGrow: 1,
    padding: '15px 0 15px 0',
  },
  widget: {
    height: 515,
    width: 335,
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    backgroundColor: theme.palette.backgroundColor.secondary,
  },
  header: {
    padding: theme.spacing(1.1),
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '1.1rem',
    color: theme.palette.primary.main,
    paddingLeft: 10,
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
    height: 1.5,
  },
  content: {
    position: 'relative',
    width: 335,
    overflow: 'clip',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
  },
  row: {
    padding: theme.spacing(1.5),
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
  },
  spacer: {
    height: theme.spacing(1.5),
  },
  profileDeepLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0.5),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.button.hover,
    },
    '&:focus-visible': {
      backgroundColor: theme.palette.button.hover,
    },
  },
  tabLinkBox: {
    width: 80,
    height: 70,
    textAlign: 'center',
    margin: '0 auto',
  },
  tabLinkText: {
    fontWeight: 400,
    margin: 0,
    maxHeight: 26,
    maxWidth: 80,
    padding: '0 3px',
    fontSize: '10px',
    overflow: 'hidden',
    display: '-webkit-box',
    lineHeight: 1.3,
    '-webkit-line-clamp': 2,
    'line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  removeWidgetIcon: {
    width: 20,
    height: 20,
    marginRight: 4,
  },
  removeWidgetHighlight: {
    fontSize: 20,
    color: theme.palette.primary.main,
  },
  mobileGlanceMenu: {
    position: 'fixed',
    zindex: 1000,
    top: 55,
    left: 0,
    right: 0,
    bottom: 55,
    width: '0px',
  },
  textCenter: {
    flex: '1 1 auto',
  },
  proxyModeContainer: {
    backgroundColor: 'rgba(0,0,0,.85)',
    paddingTop: 20,
  },
  proxyModeText: {
    marginTop: 20,
    color: theme.palette.primary.main,
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '10px',
    alignItems: 'center',
  },
}))

export default styles
