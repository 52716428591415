import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Slide,
} from '@material-ui/core'

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AddCircleIcon from '@material-ui/icons/AddCircle'

import styles from './AddWidgetAdminStyles'
import {
  addEditRemoveWidgetsInFireStore,
  setAddWidgetDialog,
  updateAlert,
} from '../../adminPageSlice'
import WidgetForm from '../WidgetForm/WidgetForm'
import { createNewWidgetTemplate } from '../../constants'

function AddWidgetAdmin({ classes }) {
  const dispatch = useDispatch()

  const changeStatus = () => {
    dispatch(setAddWidgetDialog(true))
  }
  return (
    <>
      <ListItem button onClick={changeStatus}>
        <ListItemIcon>
          <AddCircleIcon className={classes.listIconStyle} />
        </ListItemIcon>
        <ListItemText>Add a Widget</ListItemText>
      </ListItem>
    </>
  )
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" {...props} ref={ref} />
})

function AddWidgetAdminDialog() {
  const classes = styles()
  const dispatch = useDispatch()
  const openDialog = useSelector((s) => s.adminPage.addWidgetDialog)
  const widgets = useSelector((s) => s.adminPage.widgets)
  const [edited, setEdited] = React.useState(false)
  const [widget, setWidget] = React.useState({})

  React.useEffect(() => {
    if (openDialog) {
      setUpNewWidgetTemplate()
    }
  }, [openDialog])

  const setUpNewWidgetTemplate = () => {
    const newWidget = createNewWidgetTemplate()
    newWidget.id = (
      Math.max(...widgets.map((w) => parseInt(w.id, 10))) + 1
    ).toString()
    const widgetLength = widgets.length
    newWidget.order = widgetLength
    newWidget.index = widgetLength
    setWidget(newWidget)
  }

  const handleCancel = (forceClose) => {
    if (!edited || forceClose) {
      dispatch(setAddWidgetDialog(false))
    }
  }

  const handleCreateWidgetSubmit = () => {
    // TODO Need to do some validations here
    const newList = [...widgets, { ...widget, manuallyMoved: Date.now() }]
    dispatch(addEditRemoveWidgetsInFireStore(newList))
    setEdited(false)
    handleCancel(true)
    const msg = `Successfully created new widget ${widget.title}`
    showAlert(msg)
  }

  const showAlert = (msg) => {
    const alert = {
      showAlert: true,
      message: msg,
    }
    dispatch(updateAlert(alert))
  }

  return (
    <Dialog
      open={openDialog}
      onClose={() => handleCancel(false)}
      maxWidth="lg"
      fullWidth
      TransitionComponent={Transition}
    >
      <DialogTitle style={{ backgroundColor: '#f5f5f5' }}>
        <Typography className={classes.headerText}>
          Create A New Widget
        </Typography>
      </DialogTitle>
      <DialogContent dividers className={classes.dialog}>
        <WidgetForm
          focusedWidget={widget}
          setWidget={setWidget}
          setEdited={setEdited}
        />
      </DialogContent>
      <DialogActions style={{ backgroundColor: '#f5f5f5' }}>
        <Grid container directin="row" justifyContent="flex-end">
          <Button
            style={{ marginRight: '8px' }}
            onClick={() => handleCancel(true)}
            variant="outlined"
            color="primary"
          >
            {edited ? 'Cancel' : 'Close'}
          </Button>
          <Button
            onClick={handleCreateWidgetSubmit}
            variant="contained"
            disabled={!edited}
            color="primary"
          >
            Create Widget
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default AddWidgetAdmin
export { AddWidgetAdminDialog }
