/* eslint-disable no-unused-vars */
import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ScheduleView, createTheme, ThemeContext } from 'react-schedule-view'
import { useHistory } from 'react-router-dom'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Collapse from '@material-ui/core/Collapse'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import NativeSelect from '@material-ui/core/NativeSelect'
import IconButton from '@material-ui/core/IconButton'
import BookOutlinedIcon from '@material-ui/icons/BookOutlined'
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined'
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined'
import DirectionsCarOutlinedIcon from '@material-ui/icons/DirectionsCarOutlined'
import DirectionsBusOutlinedIcon from '@material-ui/icons/DirectionsBusOutlined'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Alert } from '@material-ui/lab'
import { Snackbar } from '@material-ui/core'

import {
  toggleCourseScheduleModal,
  fetchCourseScheduleModalData,
  updateScheduleData,
  updateCurrentTerm,
  updateView,
} from './courseScheduleSlice'
import courseScheduleStyles from './courseScheduleStyles'
import Loading from '../helpers/Loading'
import CheckIsMobile from '../../../../../utils/isMobileHook'
import {
  handleShowBottomSheetBuilding,
  // handleShowBottomSheetNearestStop,
} from './helper'
import { logDashboardEvent } from '../../events'

function CourseScheduleModal({ widgetId }) {
  const dispatch = useDispatch()
  const { courseScheduleData, currentTerm } = useSelector(
    (s) => s.courseSchedule,
  )
  React.useEffect(() => {
    dispatch(
      fetchCourseScheduleModalData(
        `?term=${courseScheduleData?.ruTerms[currentTerm]}`,
      ),
    )
  }, [])

  return <Loaded widgetId={widgetId} />
}

function Loaded({ widgetId }) {
  const dispatch = useDispatch()
  const classes = courseScheduleStyles()
  const {
    courseModalOpen,
    currentTerm,
    view,
    scheduleData,
    courseScheduleData,
  } = useSelector((s) => s.courseSchedule)
  const isDesktopView = useMediaQuery((theme) => theme.breakpoints.up('900'))
  const handleClose = (e, reason) => {
    if (reason === 'close') {
      dispatch(toggleCourseScheduleModal(false))
    }
  }
  const handleChangeView = () => {
    if (view === 'table') {
      dispatch(updateView('grid'))
    } else {
      dispatch(updateView('table'))
    }
  }
  const handleSemesterChange = (e) => {
    // dispatch an action that will find the currentTerm based on
    // the code returned
    dispatch(updateCurrentTerm(e.target.value))
    const currentTermParam = courseScheduleData.ruTerms[e.target.value]
      ? `?term=${courseScheduleData.ruTerms[e.target.value]}`
      : ''
    dispatch(fetchCourseScheduleModalData(currentTermParam))
  }
  return (
    <Dialog
      fullScreen="fullScreen"
      open={courseModalOpen}
      onClose={handleClose}
      aria-labelledby="course-schdule-dialog-title"
      aria-describedby="course-schedule-dialog-description"
      classes={{ scrollPaper: classes.courseScheduleDialog }}
    >
      <DialogTitle id="course-schedule-dialog-title">
        Course Schedule
      </DialogTitle>
      <DialogContent>
        <Box>
          <Typography>
            {`My ${courseScheduleData.ruTerms[currentTerm]} Course Schedule`}
          </Typography>
          {/* Table Body */}
          {view === 'table' && isDesktopView ? (
            <CourseScheduleTable widgetId={widgetId} />
          ) : (
            view === 'table' && (
              <MobileCourseScheduleTable widgetId={widgetId} />
            )
          )}
          {view === 'grid' && (
            <CourseScheduleGrid scheduleData={scheduleData} />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Semester
              </InputLabel>
              <NativeSelect
                defaultValue={currentTerm}
                value={currentTerm}
                inputProps={{
                  name: 'course-terms',
                  id: 'uncontrolled-native',
                }}
                onChange={handleSemesterChange}
              >
                {courseScheduleData.ruTerms &&
                courseScheduleData.ruTerms.length > 0
                  ? courseScheduleData.ruTerms.map((course, index) => (
                      <option value={index}>{course}</option>
                    ))
                  : undefined}
              </NativeSelect>
            </FormControl>
          </Box>
          <Button variant="outlined" onClick={handleChangeView}>
            {view === 'table' ? 'Grid View' : 'Table View'}
          </Button>
          <Button
            variant="outlined"
            onClick={(e) => handleClose(e, 'close')}
            color="primary"
          >
            Back to Dashboard
          </Button>
        </>
      </DialogActions>
    </Dialog>
  )
}

const CourseScheduleTable = ({ widgetId }) => {
  const classes = courseScheduleStyles()
  const [alert, showAlert] = React.useState(false)
  const isMobile = CheckIsMobile()
  const { buildings } = useSelector((s) => s.buildings)

  const {
    regularCourses,
    remoteCourses,
    currentTerm,
    courseScheduleModalDataStatus,
  } = useSelector((s) => s.courseSchedule)
  const dispatch = useDispatch()
  const history = useHistory()
  const LOCATION = 'NB'
  // Table Header Cells -
  const HEADERS = [
    { title: 'Day', id: 0 },
    { title: 'Time', id: 1 },
    { title: 'Course Title', id: 2 },
    { title: 'Course #', id: 3 },
    { title: 'Section', id: 4 },
    { title: 'Credit', id: 5 },
    { title: 'Course Type', id: 6 },
    { title: 'Location', id: 7 },
    { title: '', id: 8 },
  ]
  const handleParkingClick = (buildingID) => {
    window.open(
      `https://maps.rutgers.edu/#/?click=true&parking=true&selected=${buildingID}`,
      '_blank',
    )
  }

  const handleViewBuildingClick = (course) => {
    const gpsCoords = course.bldgInfo.bldgVM?.gpsCor?.split(',')
    const buildingUrl = `https://maps.rutgers.edu/#/?click=true&lat=${gpsCoords[0]}&lng=${gpsCoords[1]}&selected=${course.bldgInfo.bldgNo}&sidebar=true&zoom=16`
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardEvent',
        widgetId,
        `${course.courseTitle} Direction`,
        buildingUrl,
      )
    }
    // Will come back later 8/24/23
    // const buildingID = course.bldgInfo.bldgNo // Pass this into bellow function
    // const buildingObj = buildings.find(
    //   (building) => building.bid === buildingID,
    // )
    // if (!isMobile) {
    //   dispatch(handleViewBuildingInfoSheet(buildingID))
    // }
    // dispatch(setSelectedBuilding(buildingObj))
    // dispatch(fetchParkingNearBuilding())
    // history.push('/maps')
    // Open Maps and show nearest parking
    window.open(buildingUrl, '_blank')
  }

  const handleBusClick = (buildingID) => {
    const success = handleShowBottomSheetBuilding(
      dispatch,
      buildingID,
      buildings,
    )
    if (success) {
      dispatch(toggleCourseScheduleModal(false))
      if (isMobile) {
        history.push('/NearestStopsMobile')
      } else {
        history.push('/maps')
      }
    } else {
      showAlert(true)
    }
  }

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table
          stickyHeader
          size="small"
          fullWidth="true"
          className={classes.table}
          aria-label="cousrse schedule table"
        >
          <TableHead>
            <TableRow>
              {HEADERS.map((header) => (
                <TableCell align="left" key={header.id}>
                  {header.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {courseScheduleModalDataStatus === 'fulfilled' ? (
            <TableBody>
              {regularCourses && regularCourses.length > 0 ? (
                regularCourses.map((courseObj) => (
                  <TableRow key={`${courseObj.courseNo}`}>
                    <TableCell align="left">
                      {courseObj.meetingDay ? courseObj.meetingDay : 'N/A'}
                    </TableCell>
                    <TableCell align="left">
                      {courseObj.classTime ? courseObj.classTime : 'N/A'}
                    </TableCell>
                    <TableCell align="left">
                      <Box display="flex" flexDirection="column">
                        {courseObj.courseTitle ? courseObj.courseTitle : 'N/A'}
                        <span>
                          Professor:{' '}
                          {courseObj.instructorOne
                            ? courseObj.instructorOne
                            : 'N/A'}
                        </span>
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      {courseObj.courseNo ? courseObj.courseNo : 'N/A'}
                    </TableCell>
                    <TableCell align="left">
                      {courseObj.sectionNo ? courseObj.sectionNo : 'N/A'}
                    </TableCell>
                    <TableCell align="left">
                      {courseObj.creditsAttempted
                        ? courseObj.creditsAttempted
                        : 'N/A'}
                    </TableCell>
                    <TableCell align="left">
                      {courseObj.courseType ? courseObj.courseType : 'N/A'}
                    </TableCell>
                    <TableCell align="left">
                      {courseObj.bldgInfo && courseObj.dcsUrl ? (
                        <a
                          href={courseObj.dcsUrl}
                          rel="noopener noreferrer"
                          target="_blank"
                        >{`${courseObj.bldgInfo.bldgVM.bldgDescr} (Room: ${courseObj.roomNo})`}</a>
                      ) : (
                        'N/A'
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {/* check if the users location isnt RBHS */}
                      <Tooltip title="Final Exam">
                        <IconButton
                          component={Link}
                          classes={{ root: classes.icon }}
                          aria-label="final exam url"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            LOCATION === 'NWK'
                              ? `${process.env.REACT_APP_COURSE_SCHEDULEFINALEXAMRUNWK_DEEPLINK}-${currentTerm.termName}-${currentTerm.year}`
                              : `${process.env.REACT_APP_COURSE_SCHEDULEFINALEXAMRUCAM_DEEPLINK}&campus=nb&subject=&course=&index=${courseObj.regIndexNo}`
                          }
                        >
                          <DescriptionOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Bookstore">
                        <IconButton
                          component={Link}
                          aria-label="book store url"
                          classes={{ root: classes.icon }}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={courseObj.bookStoreUrl}
                        >
                          <BookOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                      {courseObj.bldgInfo && courseObj.bldgInfo.bldgURL ? (
                        <Tooltip title="Show Building">
                          <IconButton
                            component={Link}
                            aria-label="building url"
                            classes={{ root: classes.icon }}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => handleViewBuildingClick(courseObj)}
                          >
                            <LocationOnOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      ) : undefined}
                      {courseObj.bldgInfo && courseObj.bldgInfo.bldgNo ? (
                        <>
                          <Tooltip title="Parking Directions">
                            <IconButton
                              component={Link}
                              aria-label="parking lot url"
                              classes={{ root: classes.icon }}
                              onClick={() =>
                                handleParkingClick(courseObj.bldgInfo.bldgNo)
                              }
                            >
                              <DirectionsCarOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Nearest Stops">
                            <IconButton
                              component={Link}
                              aria-label="nearest stops"
                              classes={{ root: classes.icon }}
                              onClick={() =>
                                handleBusClick(courseObj.bldgInfo.bldgNo)
                              }
                            >
                              <DirectionsBusOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                      ) : undefined}
                      {courseObj.bldgInfo ? (
                        <Tooltip title="Classroom">
                          <IconButton
                            component={Link}
                            aria-label="classroom link"
                            classes={{ root: classes.icon }}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={courseObj.bldgInfo.bldgURL}
                          >
                            <AccountBalanceOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      ) : undefined}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableCell>No data</TableCell>
              )}
              <TableCell
                classes={{ root: classes.tableAdditionalHeader }}
                colSpan={9}
              >
                Remote-Asynchronous, Online, and By Arrangement Course Meetings:
              </TableCell>
              {remoteCourses && remoteCourses.length > 0 ? (
                remoteCourses.map((courseObj) => (
                  <TableRow key={`${courseObj.courseNo}`}>
                    <TableCell align="left">
                      {courseObj.meetingDay ? courseObj.meetingDay : 'N/A'}
                    </TableCell>
                    <TableCell align="left">
                      {courseObj.classTime ? courseObj.classTime : 'N/A'}
                    </TableCell>
                    <TableCell align="left">
                      <Box display="flex" flexDirection="column">
                        {courseObj.courseTitle ? courseObj.courseTitle : 'N/A'}
                        <span>
                          {courseObj.instructorOne
                            ? courseObj.instructorOne
                            : 'N/A'}
                        </span>
                      </Box>
                    </TableCell>
                    <TableCell align="left">
                      {courseObj.courseNo ? courseObj.courseNo : 'N/A'}
                    </TableCell>
                    <TableCell align="left">
                      {courseObj.sectionNo ? courseObj.sectionNo : 'N/A'}
                    </TableCell>
                    <TableCell align="left">
                      {courseObj.creditAttempted
                        ? courseObj.creditAttempted
                        : 'N/A'}
                    </TableCell>
                    <TableCell align="left">
                      {courseObj.courseType ? courseObj.courseType : 'N/A'}
                    </TableCell>
                    <TableCell align="left">
                      {courseObj.courseLocation
                        ? courseObj.courseLocation
                        : 'N/A'}
                    </TableCell>
                    <TableCell align="left">
                      {/* check if the users location isnt RBHS */}
                      <IconButton
                        component={Link}
                        aria-label="final exam url"
                        classes={{ root: classes.icon }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={
                          LOCATION === 'NWK'
                            ? `${process.env.REACT_APP_COURSESCHEDULEFINALEXAMNWK_DEEPLINK}-${currentTerm.termName}-${currentTerm.year}`
                            : `${process.env.REACT_APP_COURSESCHEDULEFINALEXAMRUCAM_DEEPLINK}&campus=nb&subject=&course=&index=${courseObj.regIndexNo}`
                        }
                      >
                        <DescriptionOutlinedIcon />
                      </IconButton>
                      <IconButton
                        component={Link}
                        aria-label="class room url"
                        classes={{ root: classes.icon }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={courseObj.bookStoreUrl}
                      >
                        <BookOutlinedIcon />
                      </IconButton>
                      {courseObj.bldgInfo && courseObj.bldgInfo.bldgURL ? (
                        <IconButton
                          component={Link}
                          aria-label="building url"
                          classes={{ root: classes.icon }}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={courseObj.bldgInfo.bldgURL}
                        >
                          <LocationOnOutlinedIcon />
                        </IconButton>
                      ) : undefined}
                      {courseObj.bldgInfo ? (
                        <IconButton
                          component={Link}
                          aria-label="building url"
                          classes={{ root: classes.icon }}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={courseObj.bldgInfo.bldgURL}
                        >
                          <AccountBalanceOutlinedIcon />
                        </IconButton>
                      ) : undefined}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableCell>No data</TableCell>
              )}
            </TableBody>
          ) : (
            <>
              {courseScheduleModalDataStatus === 'pending' ||
              courseScheduleModalDataStatus === 'idle' ? (
                <TableCell
                  classes={{ root: classes.tableAdditionalHeader }}
                  colSpan={9}
                >
                  <Loading />
                </TableCell>
              ) : (
                <TableCell
                  classes={{ root: classes.tableAdditionalHeader }}
                  colSpan={9}
                >
                  <Typography variant="body1">
                    No Schedule to display.
                  </Typography>
                </TableCell>
              )}
            </>
          )}
        </Table>
      </TableContainer>
      <Snackbar
        autoHideDuration={3000}
        open={alert}
        onClose={() => showAlert(false)}
      >
        <Alert severity="error">Error! Cannot navigate to maps page</Alert>
      </Snackbar>
    </Box>
  )
}

const MobileCourseScheduleTable = ({ widgetId }) => {
  const classes = courseScheduleStyles()
  const isMobile = CheckIsMobile()
  const {
    regularCourses,
    remoteCourses,
    currentTerm,
    courseScheduleModalDataStatus,
  } = useSelector((s) => s.courseSchedule)
  const LOCATION = 'NB'
  // Table Header Cells -
  const HEADERS = [
    { title: 'Day', id: 0 },
    { title: 'Time', id: 1 },
    { title: 'Course Title', id: 2 },
    { title: 'More Information', id: 3 },
  ]
  return (
    <Box>
      <TableContainer component={Paper}>
        <Table
          stickyHeader
          size="small"
          fullWidth="true"
          className={classes.table}
          aria-label="cousrse schedule table"
        >
          <TableHead>
            <TableRow>
              {HEADERS.map((header) => (
                <TableCell align="left" key={header.id}>
                  {header.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {courseScheduleModalDataStatus === 'fulfilled' ? (
            <TableBody>
              {regularCourses && regularCourses.length > 0 ? (
                regularCourses.map((courseObj) => (
                  <RegularCoursesRow
                    key={`${courseObj.courseNo}`}
                    courseObj={courseObj}
                    currentTerm={currentTerm}
                    LOCATION={LOCATION}
                    isMobile={isMobile}
                    widgetId={widgetId}
                  />
                ))
              ) : (
                <TableCell>No data</TableCell>
              )}
              <TableCell
                classes={{ root: classes.tableAdditionalHeader }}
                colSpan={9}
              >
                Remote-Asynchronous, Online, and By Arrangement Course Meetings:
              </TableCell>
              {remoteCourses && remoteCourses.length > 0 ? (
                remoteCourses.map((courseObj) => (
                  <RegularCoursesRow
                    key={`${courseObj.courseNo}`}
                    courseObj={courseObj}
                    currentTerm={currentTerm}
                    LOCATION={LOCATION}
                    isMobile={isMobile}
                  />
                ))
              ) : (
                <TableCell>No data</TableCell>
              )}
            </TableBody>
          ) : (
            <>
              {courseScheduleModalDataStatus === 'pending' ||
              courseScheduleModalDataStatus === 'idle' ? (
                <TableCell
                  classes={{ root: classes.tableAdditionalHeader }}
                  colSpan={9}
                >
                  <Loading />
                </TableCell>
              ) : (
                <TableCell
                  classes={{ root: classes.tableAdditionalHeader }}
                  colSpan={9}
                >
                  <Typography variant="body1">
                    No Schedule to display.
                  </Typography>
                </TableCell>
              )}
            </>
          )}
        </Table>
      </TableContainer>
    </Box>
  )
}

const EventTile = ({ event }) => {
  const classes = courseScheduleStyles()
  const { course } = event
  const theme = React.useContext(ThemeContext)
  return (
    <a
      href={course?.bldgInfo?.bldgURL}
      target="_blank"
      rel="noopener noreferrer"
      className={classes.courseAnchor}
    >
      <div style={{ fontWeight: 'bold', fontSize: '0.8rem', lineHeight: 1.2 }}>
        {event.title}
      </div>
      <div style={{ fontWeight: 'bold', fontSize: '0.8rem', lineHeight: 1.2 }}>
        {`${course?.bldgInfo?.bldgCd ? course?.bldgInfo?.bldgCd : ''}-${
          course.roomNo
        }`}
      </div>
      <div
        style={{
          fontSize: '0.8rem',
          fontWeight: 'lighter',
        }}
      >
        {theme.timeRangeFormatter(event.startTime, event.endTime)}
      </div>

      <div
        style={{
          fontSize: '0.8rem',
          marginTop: '0.25rem',
        }}
      >
        {event.description}
      </div>
    </a>
  )
}
const CourseScheduleGrid = ({ scheduleData }) => {
  //   console.log('scheduledata ', scheduleData)
  const { courseScheduleModalDataStatus } = useSelector((s) => s.courseSchedule)
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(updateScheduleData())
  }, [])
  const customTheme = createTheme('google', {
    minorGridlinesPerHour: 2,
    hourHeight: '80px',
    customTileComponent: EventTile,
  })
  return (
    <Box>
      <Typography variant="body1">Grid View for Course Schedule</Typography>
      {courseScheduleModalDataStatus === 'fulfilled' ? (
        <>
          {scheduleData && scheduleData.length > 0 ? (
            <ScheduleView
              theme={customTheme}
              daySchedules={scheduleData}
              viewStartTime={8}
              viewEndTime={24}
            />
          ) : (
            <Typography variant="body1">No Schedule to display.</Typography>
          )}
        </>
      ) : (
        <>
          {courseScheduleModalDataStatus === 'pending' ||
          courseScheduleModalDataStatus === 'idle' ? (
            <Loading />
          ) : (
            <Typography variant="body1">No Schedule to display.</Typography>
          )}
        </>
      )}
    </Box>
  )
}

const RegularCoursesRow = ({
  courseObj,
  currentTerm,
  LOCATION,
  isMobile,
  widgetId,
}) => {
  const classes = courseScheduleStyles()
  const dispatch = useDispatch()
  const { buildings } = useSelector((s) => s.buildings)

  const history = useHistory()
  const [open, updateOpen] = React.useState(false)
  const handleShowMoreInformation = () => {
    updateOpen(!open)
  }

  const handleViewBuildingClick = (course) => {
    const gpsCoords = course.bldgInfo.bldgVM?.gpsCor?.split(',')
    const buildingUrl = `https://maps.rutgers.edu/#/?click=true&lat=${gpsCoords[0]}&lng=${gpsCoords[1]}&selected=${course.bldgInfo.bldgNo}&sidebar=true&zoom=16`
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardEvent',
        widgetId,
        `${course.courseTitle} Direction`,
        buildingUrl,
      )
    }
    // Will come back later 8/24/23
    // const buildingID = course.bldgInfo.bldgNo // Pass this into bellow function
    // const buildingObj = buildings.find(
    //   (building) => building.bid === buildingID,
    // )
    // if (!isMobile) {
    //   dispatch(handleViewBuildingInfoSheet(buildingID))
    // }
    // dispatch(setSelectedBuilding(buildingObj))
    // dispatch(fetchParkingNearBuilding())
    // history.push('/maps')
    // Open Maps and show nearest parking
    window.open(buildingUrl, '_blank')
  }

  const handleParkingClick = (buildingID) => {
    window.open(
      `https://maps.rutgers.edu/#/?click=true&parking=true&selected=${buildingID}`,
      '_blank',
    )
  }

  const handleBusClick = (buildingID) => {
    dispatch(toggleCourseScheduleModal(false))
    handleShowBottomSheetBuilding(dispatch, buildingID, buildings)
    if (isMobile) {
      history.push('/NearestStopsMobile')
    } else {
      history.push('/maps')
    }
  }
  return (
    <>
      <TableRow key={`${courseObj.courseNo}`}>
        <TableCell align="left">
          {courseObj.meetingDay ? courseObj.meetingDay : 'N/A'}
        </TableCell>
        <TableCell align="left">
          {courseObj.classTime ? courseObj.classTime : 'N/A'}
        </TableCell>
        <TableCell align="left">
          {courseObj.courseTitle ? courseObj.courseTitle : 'N/A'}
          {'   '}
          {courseObj.courseNo ? courseObj.courseNo : 'N/A'}
          {'   '}
          {courseObj.sectionNo ? courseObj.sectionNo : 'N/A'}
        </TableCell>
        <TableCell width={50} align="left">
          <Box display="flex">
            <IconButton
              aria-label="course schedule expanded menu"
              onClick={handleShowMoreInformation}
            >
              <ExpandMoreIcon />
            </IconButton>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box marginY="10px">
              <Typography variant="subtitle1" gutterBottom component="div">
                Additional Course Information
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle2">
                Course #:{' '}
                <span className={classes.subtitleSpan}>
                  {courseObj.courseTitle ? courseObj.courseTitle : 'N/A'}
                  {'   '}
                  {courseObj.courseNo ? courseObj.courseNo : 'N/A'}
                  {'   '}
                  {courseObj.sectionNo ? courseObj.sectionNo : 'N/A'}
                </span>
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2">
                Instructor:{' '}
                <span className={classes.subtitleSpan}>
                  {courseObj.instructorOne ? courseObj.instructorOne : 'N/A'}
                </span>
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2">
                Course Type:{' '}
                <span className={classes.subtitleSpan}>
                  {courseObj.courseType ? courseObj.courseType : 'N/A'}
                </span>
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2">
                Location:{'  '}
                {courseObj.bldgInfo && courseObj.dcsUrl ? (
                  <a
                    href={courseObj.dcsUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <span
                      className={classes.subtitleSpan}
                    >{`${courseObj.bldgInfo.bldgVM.bldgDescr} (Room: ${courseObj.roomNo})`}</span>
                  </a>
                ) : (
                  'N/A'
                )}
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2">
                Credit(s):{' '}
                <span className={classes.subtitleSpan}>
                  {courseObj.creditsAttempted
                    ? courseObj.creditsAttempted
                    : 'N/A'}
                </span>
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2">
                Course Type:{' '}
                <span className={classes.subtitleSpan}>
                  {courseObj.courseType ? courseObj.courseType : 'N/A'}
                </span>
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2">
                Final Exam:{' '}
                <span className={classes.subtitleSpan}>
                  {`${courseObj.examDay} ${
                    courseObj.examTime ? courseObj.examTime : ''
                  }`}
                </span>
              </Typography>
            </Box>
            <Box>
              <Tooltip title="Final Exam URL">
                <IconButton
                  component={Link}
                  aria-label="final exam url"
                  classes={{ root: classes.icon }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    LOCATION === 'NWK'
                      ? `${process.env.REACT_APP_COURSESCHEDULEFINALEXAMNWK_DEEPLINK}-${currentTerm.termName}-${currentTerm.year}`
                      : `${process.env.REACT_APP_COURSESCHEDULEFINALEXAMRUCAM_DEEPLINK}&campus=nb&subject=&course=&index=${courseObj.regIndexNo}`
                  }
                >
                  <DescriptionOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Bookstore">
                <IconButton
                  classes={{ root: classes.icon }}
                  aria-label="book store url"
                  component={Link}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={courseObj.bookStoreUrl}
                >
                  <BookOutlinedIcon />
                </IconButton>
              </Tooltip>
              {courseObj.bldgInfo && courseObj.bldgInfo.bldgURL ? (
                <Tooltip title="Show Building">
                  <IconButton
                    aria-label="building url"
                    component={Link}
                    classes={{ root: classes.icon }}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => handleViewBuildingClick(courseObj)}
                  >
                    <LocationOnOutlinedIcon />
                  </IconButton>
                </Tooltip>
              ) : undefined}
              {courseObj.bldgInfo && courseObj.bldgInfo.bldgNo ? (
                <>
                  <Tooltip title="Parking Directions">
                    <IconButton
                      component={Link}
                      aria-label="parking lot directions"
                      classes={{ root: classes.icon }}
                      onClick={() =>
                        handleParkingClick(courseObj.bldgInfo.bldgNo)
                      }
                    >
                      <DirectionsCarOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Nearest Stops">
                    <IconButton
                      aria-label="nearest stops"
                      classes={{ root: classes.icon }}
                      component={Link}
                      onClick={() => handleBusClick(courseObj.bldgInfo.bldgNo)}
                    >
                      <DirectionsBusOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ) : undefined}
              {courseObj.bldgInfo ? (
                <Tooltip title="Classroom">
                  <IconButton
                    component={Link}
                    aria-label="classroom link"
                    classes={{ root: classes.icon }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={courseObj.bldgInfo.bldgURL}
                  >
                    <AccountBalanceOutlinedIcon />
                  </IconButton>
                </Tooltip>
              ) : undefined}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default CourseScheduleModal
