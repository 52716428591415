/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Grid } from '@material-ui/core'
import { useSelector } from 'react-redux'
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import RestaurantTile from '../RestaurantTile/RestaurantTile'

import CustomDotGroup from '../../../../apps/AppComponents/CustomDotGroup/CustomDotGroup'
import { restaurantCarouselStyles } from './restaurantCarouselStyles'

export default function RestaurantCarousel() {
  const classes = restaurantCarouselStyles()
  const [pages, setPages] = React.useState([])

  const { restaurantData } = useSelector((s) => s.buildings)
  const CAROUSEL_WIDTH = '95vw'
  const CAROUSEL_HEIGHT = '31vh'
  const NUM_RESTAURANTS_PER_SLIDE = 5
  const slideNumber = Math.ceil(
    restaurantData.length / NUM_RESTAURANTS_PER_SLIDE,
  )

  React.useEffect(() => {
    const restaurantList = []
    let startIndex = 0
    let endIndex = NUM_RESTAURANTS_PER_SLIDE
    for (let i = 0; i < slideNumber; i++) {
      const slicedList = restaurantData.slice(startIndex, endIndex)
      restaurantList.push(slicedList)
      startIndex = endIndex
      endIndex += NUM_RESTAURANTS_PER_SLIDE
    }
    setPages(restaurantList)
  }, [restaurantData])

  return (
    <div style={{ height: '36vh', display: 'flex', justifyContent: 'center' }}>
      <CarouselProvider
        naturalSlideWidth={CAROUSEL_WIDTH}
        naturalSlideHeight={CAROUSEL_HEIGHT}
        totalSlides={pages.length}
        style={{
          width: CAROUSEL_WIDTH,
          height: CAROUSEL_HEIGHT,
        }}
      >
        <Slider>
          {pages.map((restaurants, index) => (
            <Slide
              key={index}
              index={index}
              style={{
                height: CAROUSEL_HEIGHT,
                width: CAROUSEL_WIDTH,
              }}
            >
              <Grid
                container
                justifyContent="space-evenly"
                alignItems="center"
                style={{ marginLeft: 15 }}
              >
                {restaurants.map((restaurant) => (
                  <Grid item key={restaurant.name}>
                    <RestaurantTile restaurant={restaurant} />
                  </Grid>
                ))}
              </Grid>
            </Slide>
          ))}
        </Slider>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <ButtonBack className={classes.buttonStyle} color="secondary">
              <ArrowBackIosIcon className={classes.backArrow} />
            </ButtonBack>
          </Grid>

          <Grid item className={classes.dotGroup}>
            <div className={classes.customDotGroupContainer}>
              <CustomDotGroup slides={slideNumber} size="mini" />
            </div>
          </Grid>

          <Grid item>
            <ButtonNext className={classes.buttonStyle} color="secondary">
              <ArrowForwardIosIcon className={classes.backArrow} />
            </ButtonNext>
          </Grid>
        </Grid>
      </CarouselProvider>
    </div>
  )
}
