import React from 'react'

import {
  Card,
  Typography,
  Grid,
  Box,
  Divider,
  ButtonBase,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import { useDispatch, useSelector } from 'react-redux'

import styles from '../settingsStyles'
import PageToggle from '../components/PageToggle'

import { selectSettingsData, toggleLinkPreference } from '../settingsSlice'

function WebNavigation() {
  const classes = styles()
  const history = useHistory()
  const dispatch = useDispatch()

  const settingsData = useSelector(selectSettingsData)

  const handleLinkPreferenceToggle = () => {
    dispatch(toggleLinkPreference())
  }

  return (
    <Box className={classes.centerPage}>
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.container}
        wrap="nowrap"
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.sectionTitle}
        >
          <ButtonBase
            onClick={() => history.replace('/settings')}
            aria-label="Back"
          >
            <ArrowBackIos />
          </ButtonBase>
          <Typography variant="h1">
            <b>Web Navigation</b>
          </Typography>
        </Grid>
        <Grid item className={classes.fullWidth}>
          <Card className={classes.linksCard}>
            <PageToggle
              title="Smart Link Settings"
              description="Open smart links"
              action={handleLinkPreferenceToggle}
              state={settingsData.linkPreference}
            />
            <Divider variant="middle" className={classes.fullWidth} />
            <PageToggle
              title="Standard Link Settings"
              description="Open standard links"
              action={handleLinkPreferenceToggle}
              state={settingsData.linkPreference}
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default WebNavigation
