import * as React from 'react'
import Typography from '@material-ui/core/Typography'

/** 
  Tab Menu for widgets (Alerts, Dining, Financial Aid)
*/

export function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Typography component="span">{children}</Typography>}
    </div>
  )
}

export function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}
