import { makeStyles } from '@material-ui/core/styles'

// Any time that we would like to add CSS to our Components we will create a styles object. The syntax is different then normal CSS. Please notice that camel case is used to set the list-style-type. For your reference please visit https://v4.mui.com
export const customMarkerStyles = makeStyles(() => ({
  icon: {
    position: 'absolute',
    zIndex: 1,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  iconPointerDisabled: {
    // Sometimes we want to disable the pointer over the marker
    position: 'absolute',
    zIndex: 1,
  },
}))

export default customMarkerStyles
