import React from 'react'
import { MAX_ARTICLES_PER_SECTION } from '../../../constants'
import SetupArticleLayoutWithImage from './SetupArticleLayoutWithImage'

export default function ArticleLayoutWithImage({
  articles,
  isDesktopView,
  isLoading,
}) {
  const partionList = (arr, chunkSize) => {
    const res = []
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize)
      res.push(chunk)
    }
    return res
  }

  return (
    <>
      {partionList(articles, MAX_ARTICLES_PER_SECTION).map((partition) => (
        <SetupArticleLayoutWithImage
          articles={partition}
          isDesktopView={isDesktopView}
          isLoading={isLoading}
        />
      ))}
    </>
  )
}
