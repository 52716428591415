import * as React from 'react'

import ButtonBase from '@material-ui/core/ButtonBase'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { Divider, Typography as Text } from '@material-ui/core'

import { useDispatch, useSelector } from 'react-redux'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import Widget from '../Widget'
import myExpensesStyle from './myExpensesStyle'
import {
  fetchMyExpenses,
  selectMyExpensesData,
  selectMyExpensesStatus,
} from './myExpensesSlice'
import Loading from '../helpers/Loading'
import IconPicker from '../../../apps/AppComponents/IconPicker/IconPicker'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'
// eslint-disable-next-line no-unused-vars
function MyExpenses({
  className,
  title,
  widgetId,
  showNote,
  noteMessage,
  notePosition,
  noteBackground,
  noteIcon,
  noteUrl,
  noteFontColor,
}) {
  const dispatch = useDispatch()
  const widgetData = useSelector(selectMyExpensesData)
  const widgetCallState = useSelector(selectMyExpensesStatus)

  React.useEffect(() => {
    dispatch(fetchMyExpenses())
  }, [])

  return (
    <Widget className={className}>
      <Widget.Header title={title} category="Finance" />
      <Widget.Content
        showNote={showNote}
        noteMessage={noteMessage}
        notePosition={notePosition}
        noteBackground={noteBackground}
        noteIcon={noteIcon}
        noteUrl={noteUrl}
        noteFontColor={noteFontColor}
      >
        {renderMyExpensesState(widgetCallState, widgetData, widgetId, dispatch)}
      </Widget.Content>
    </Widget>
  )
}

function renderMyExpensesState(
  widgetCallState,
  widgetData,
  widgetId,
  dispatch,
) {
  switch (widgetCallState) {
    case 'fulfilled':
      return (
        <Loaded
          widgetData={widgetData}
          widgetId={widgetId}
          dispatch={dispatch}
        />
      )
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage />
        </Grid>
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item></Grid>
        </Grid>
      )
  }
}

function Loaded(props) {
  const classes = myExpensesStyle()

  return (
    <Grid container className={classes.expenseListContainer}>
      <Grid container className={classes.expenseListRecordsContainer}>
        {props.widgetData?.expenseList &&
        props.widgetData.expenseList?.length > 0 ? (
          props.widgetData.expenseList.map((expense, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <ExpenseObject key={index} expense={expense} />
          ))
        ) : (
          <Box
            display="flex"
            alignItems="center"
            marginLeft="auto"
            marginRight="auto"
            flexDirection="column"
          >
            <IconPicker iconName="check-circle" color="#00b339" size="5x" />
            <Typography variant="subtitle1">No Expense Data</Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  )
}

function ExpenseObject({ expense }) {
  const classes = myExpensesStyle()
  return (
    <Card className={classes.expenseObject} variant="outlined">
      <ButtonBase className={classes.expenseObjectLink}>
        <CardContent className={classes.expenseObjectBackground}>
          <Grid container direction="column" spacing={1}>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item>
                <Text variant="h4">${expense.amount}</Text>
              </Grid>

              <Grid item>
                {expense.paid ? (
                  <div>
                    <Grid item>
                      <Grid container alignItems="center">
                        <CheckCircleIcon className={classes.checkCircleIcon} />

                        <Text variant="subtitle1" className={classes.paidColor}>
                          PAID
                        </Text>
                      </Grid>
                    </Grid>
                  </div>
                ) : (
                  <div>
                    <Grid item>
                      <Grid container alignItems="center">
                        <RemoveCircleIcon
                          className={classes.removeCircleIcon}
                        />

                        <Text
                          variant="subtitle1"
                          className={classes.unpaidColor}
                        >
                          UNPAID
                        </Text>
                      </Grid>
                    </Grid>
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
            <Grid container justifyContent="flex-start">
              <Text variant="body1">{expense.label}</Text>
            </Grid>
          </Grid>
        </CardContent>
      </ButtonBase>
    </Card>
  )
}

export default MyExpenses
