import { makeStyles } from '@material-ui/core/styles'

export const myDiningStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: 440,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  rootNotes: {
    width: '100%',
    height: 400,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
}))

export const iconTileStyle = makeStyles({
  card: {
    width: 85,
    height: 85,
  },
  gridContainer: {
    padding: 2,
  },
  tileIcon: {
    paddingTop: 12,
  },
  text: {
    fontSize: 11,
    fontWeight: 600,
    paddingTop: 4,
  },
})
