import { makeStyles } from '@material-ui/core/styles'

export const tutorialStylesForBothUserRoles = makeStyles((theme) => ({
  tourBackdropTop: {
    position: 'absolute',
    zIndex: '999',
    backgroundColor: theme.palette.base.black,
    opacity: '.8',
    top: 35,
    left: 0,
  },
  tourBackdropRight: {
    position: 'absolute',
    zIndex: '999',
    backgroundColor: theme.palette.base.black,
    opacity: '.8',
    top: 35,
    right: 0,
  },
  tourBackdropBottom: {
    position: 'absolute',
    zIndex: '999',
    backgroundColor: theme.palette.base.black,
    opacity: '.8',
    bottom: 0,
    left: 0,
  },
  tourBackdropLeft: {
    position: 'absolute',
    zIndex: '999',
    backgroundColor: theme.palette.base.black,
    opacity: '.8',
    top: 35,
    left: 0,
  },
  popover: {
    margin: 0,
    position: 'absolute',
    zIndex: '1000',
    opacity: '1',
    maxWidth: '276px',
    padding: '1px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    lineBreak: 'auto',
    lineHeight: '1.42857143',
    textAlign: 'start',
    textDecoration: 'none',
    textShadow: 'none',
    textTransform: 'none',
    whiteSpace: 'normal',
    wordBreak: 'normal',
    wordSpacing: 'normal',
    wordWrap: 'normal',
    fontSize: '14px',
    backgroundColor: theme.palette.backgroundColor.secondary,
    backgroundClip: 'padding-box',
    border: '1px solid rgba(0,0,0,0.2)',
    borderRadius: '6px',
  },
  popoverTitle: {
    margin: 0,
    padding: '8px 14px',
    fontSize: '14px',
    backgroundColor: theme.palette.backgroundColor.ternary,
    borderBottom: '1px solid #ebebeb',
    borderRadius: '5px 5px 0 0',
  },
  popoverDescription: {
    padding: '9px 14px',
  },
  popoverEnd: {
    float: 'right',
  },
}))

export default tutorialStylesForBothUserRoles
