import * as React from 'react'
import Box from '@material-ui/core/Box'
import styles from './caseIndicatorStyles'

const CaseIndicator = ({ value }) => {
  const classes = styles()
  return (
    <Box className={classes.caseIndicatorContainer}>
      {Number.isNaN(value) && <span>{value}</span>}
      {value <= 9 ? (
        <span>{value}</span>
      ) : (
        <span>
          9<sup>+</sup>
        </span>
      )}
    </Box>
  )
}

export default CaseIndicator
