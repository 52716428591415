import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { useSelector } from 'react-redux'
import { selectShowSensitiveInfo } from '../../../settings/settingsSlice'

import styles from './HiddenEyeButtonStyles'
import { logDashboardEvent } from '../../events'

function HiddenEyeButton({ label, value, showUsedValue, widgetId, dispatch }) {
  // TODO Connect the sensitive info button to the universal one
  // const showSensitiveInfo = useThemeSelector((s) => s.showSensitiveInfo)
  const classes = styles()
  const showSensitiveInfo = useSelector(selectShowSensitiveInfo)
  const [showData, setShowData] = React.useState(showSensitiveInfo)

  React.useEffect(() => {
    // Detects change in showSensitiveInfo and changes showData to that
    setShowData(showSensitiveInfo)
  }, [showSensitiveInfo])

  const clickHandler = () => {
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardEvent',
        widgetId,
        !showData ? 'Show' : 'Hide',
        label,
      )
    }
    setShowData(!showData)
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <Box className={classes.ringContainer} onClick={clickHandler}>
        {showSensitiveInfo || showData ? (
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            className={classes.dataContainer}
          >
            <Typography className={classes.valueHover}>{value}</Typography>
            {showUsedValue && (
              <Typography
                align="center"
                variant="subtitle2"
                className={classes.labelText}
              >
                {showUsedValue} used
              </Typography>
            )}
          </Grid>
        ) : (
          <Grid container item direction="column" alignItems="center">
            <VisibilityOff className={classes.visibilityOff} />
          </Grid>
        )}
      </Box>
      <Grid container item direction="column" alignItems="center">
        <Typography
          align="center"
          variant="subtitle2"
          className={classes.labelText}
        >
          {label}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default HiddenEyeButton
