import React from 'react'
import { useSelector } from 'react-redux'
import { FixedSizeList } from 'react-window'
import { List } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useMyMapStyles from '../../MyMapStyles'
import { TabPanel } from '../../../dashboard/DashboardComponents/TabPanel/TabPanel'
import { EmptyComponent } from '../../utils/helpers'
import { BuildingListMobile } from '../../components/List/BuildingListMobile/BuildingListMobile'
import ResaurantListMobile from '../Restaurant/RestaurantListMobile/RestaurantListMobile'
import { sortBuildings } from './utils/helpers'

// Primarily used for mobile but it sets up building and stops data on the regular page too.
// This component is loading data to be used by other components which is confusing.
export default function BuildingPanel() {
  const classes = useMyMapStyles()
  const [combinedBuildings, setCombinedBuildings] = React.useState([])
  const [favoritedBuildingLength, setFavoritedBuildingLength] =
    React.useState(0)
  const { tabValue, showRestaurantMobile, searchValue } = useSelector(
    (s) => s.maps,
  )
  const { selectedCampusBuildings, favoritedBuildings } = useSelector(
    (s) => s.buildings,
  )

  // Update combined building list when dependencies change
  React.useEffect(() => {
    createBuildingsToShowList()
  }, [favoritedBuildings, searchValue, selectedCampusBuildings])

  // Create the list of buildings to show based on favorited and selected buildings
  const createBuildingsToShowList = () => {
    let favoritedBuildingModifiedList = favoritedBuildings

    // Filter the favoritedBuildings list based on searchValue
    if (searchValue !== '') {
      favoritedBuildingModifiedList = favoritedBuildingModifiedList.filter(
        (building) =>
          building.name.toLowerCase().includes(searchValue.toLowerCase()),
      )
    }

    // Combine the favorited buildings and selected buildings while excluding duplicates
    const combinedList = [
      ...favoritedBuildingModifiedList, // Add filtered favorites
      ...sortBuildings(Object.values(selectedCampusBuildings).flat()).filter(
        (selectedBuilding) =>
          !favoritedBuildingModifiedList.some(
            (favoritedBuilding) =>
              favoritedBuilding.bid === selectedBuilding.bid,
          ),
      ),
    ]

    // Update the state variables
    setCombinedBuildings(combinedList)
    setFavoritedBuildingLength(favoritedBuildingModifiedList.length)
  }

  // Create the heading object for each building based on the index
  const createHeadingForList = (index) => {
    const obj = { showHeading: false, header: '' }

    if (favoritedBuildingLength !== 0) {
      // If favorited buildings exist
      if (index === 0) {
        // First item
        obj.showHeading = true
        obj.header = 'Favorited Buildings'
      } else if (index === favoritedBuildingLength) {
        // After favorited buildings
        obj.showHeading = true
        obj.header = 'Other Buildings'
      }
    } else if (index === 0) {
      // If no favorited buildings exist, treat all buildings as "Other Buildings"
      obj.showHeading = true
      obj.header = 'Other Buildings'
    }

    return obj
  }

  // Render the BuildingListMobile component for each building
  const renderBuilding = (list, index) => (
    <BuildingListMobile
      key={list[index].bid}
      building={list[index]}
      bid={list[index].bid}
      buildingName={list[index].name}
      buildingCity={list[index].city}
      address={list[index].address}
      picture={list[index].picture}
      lat={list[index].lat}
      lng={list[index].lng}
      headingObject={createHeadingForList(index)} // Pass the heading object to BuildingListMobile
    />
  )

  // Render the building item for each index
  const renderItem = (index) => renderBuilding(combinedBuildings, index)

  return (
    <TabPanel value={tabValue} index={1}>
      {showRestaurantMobile && <ResaurantListMobile />}
      <>
        {combinedBuildings.length > 0 ? (
          <List className={classes.list}>
            <li key="section-buildings" className={classes.newListSection}>
              <ul className={classes.newUl} style={{ paddingLeft: 0 }}>
                <VirtualizedList
                  itemCount={combinedBuildings.length}
                  itemSize={325} // Set the height of each item
                  renderItem={renderItem}
                />
              </ul>
            </li>
          </List>
        ) : (
          <div className={classes.list}>
            <EmptyComponent />
          </div>
        )}
      </>
    </TabPanel>
  )
}

const useStyles = makeStyles((theme) => ({
  listItem: {
    marginBottom: theme.spacing(2), // Adjust the spacing value as needed
  },
}))

const VirtualizedList = ({ itemCount, itemSize, renderItem }) => {
  const classes = useStyles()
  const accountForNavBarHeight = 200
  const Row = ({ index, style }) => (
    <div className={classes.listItem} style={style}>
      {renderItem(index)}
    </div>
  )

  return (
    <FixedSizeList
      height={window.innerHeight + accountForNavBarHeight} // Height of the visible area
      itemCount={itemCount}
      itemSize={itemSize}
    >
      {Row}
    </FixedSizeList>
  )
}
