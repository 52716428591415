import { makeStyles } from '@material-ui/core/styles'

export const buildingStreetViewStyles = makeStyles((theme) => ({
  mapContainerStyle: {
    height: 400,
    width: '100%',
    [theme.breakpoints.down(550)]: {
      height: 300,
    },
  },
  nearbyStopText: {
    fontSize: 14,
    fontWeight: 'bold',
    marginRight: 20,
  },
  busRouteBox: {
    color: '#ffffff',
    padding: 20,
    fontSize: 18,
    marginRight: 20,
  },
  busRouteButton: {
    background: '#333333',
    color: '#ffffff',
    fontSize: 18,
    '&:hover': {
      background: '#333333',
    },
  },
  noRoutesText: {
    fontWeight: 'bold',
    fontSize: 18,
  },
  routesContainer: {
    width: '100%',
    marginTop: 10,
    overflowX: 'auto',
    overflowY: 'auto',
  },
}))
