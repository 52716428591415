import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Box, Typography } from '@material-ui/core'

import FilterSelector from '../../components/FilterSelector/FilterSelector'
import ArticleLayout from '../../components/ArticleLayout/ArticleLayout'

import { fetchNews, fetchSources } from '../../newsSlice'
import {
  fetchFilteredNews,
  selectFilteredNewsArticlesToDisplay,
  selectFilteredNewsFilters,
  selectFilteredNewsStatus,
} from './NewsSourcePageSlice'

import useNewsSourcePageStyles from './NewsSourcePageStyles'
import SearchResultList from '../../components/SearchResultList/SearchResultList'
import NewsSearchBarMobile from '../../components/NewsSearchBar/NewsSearchBarMobile'

function NewsSourcePage() {
  const dispatch = useDispatch()
  const classes = useNewsSourcePageStyles()
  const location = useLocation()
  const { drawerIsOpen } = useSelector((state) => state.navigation)
  const [sourceName, setSourceName] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const { search } = useSelector((s) => s.news)

  const articlesToDisplay = useSelector(selectFilteredNewsArticlesToDisplay)
  const filters = useSelector(selectFilteredNewsFilters)
  const newsCallState = useSelector(selectFilteredNewsStatus)
  const prevSourceNameRef = useRef(null)

  useEffect(() => {
    const query = location.search
    const id = new URLSearchParams(query).get('id')
    const source = `${new URLSearchParams(query).get('source')} News`

    if (prevSourceNameRef.current && prevSourceNameRef.current !== source) {
      // If there was a previous sourceName and it's different from current one
      window.location.reload()
    } else {
      // Update data without reloading
      setSourceName(source)
      dispatch(fetchSources()).then(() => {
        const sourceId = !Number.isNaN(parseInt(id, 10)) ? parseInt(id, 10) : id
        dispatch(fetchFilteredNews(sourceId)).then(() => {
          dispatch(fetchNews())
        })
      })
    }

    prevSourceNameRef.current = source // Update ref with the current sourceName for the next comparison
  }, [location, dispatch])

  useEffect(() => {
    if (newsCallState === 'fulfilled') {
      const timer = setTimeout(() => {
        setIsLoading(false) // Set to false after 1000ms delay
      }, 1000)

      // Clear the timer when the component unmounts
      return () => clearTimeout(timer)
    }
    return () => {} // Explicitly indicate no return value when newsCallState is not 'fulfilled'
  }, [newsCallState])

  return (
    <Box
      className={
        drawerIsOpen ? classes.centerPageDrawerOpen : classes.centerPage
      }
    >
      <NewsSearchBarMobile />
      {search.enableSearch && search.searchQuery !== '' ? (
        <SearchResultList classes={classes} />
      ) : (
        <>
          <Box className={classes.pageContainer}>
            <Typography variant="h1" className={classes.sectionHeader}>
              {sourceName}
            </Typography>
            <FilterSelector filters={filters} />
          </Box>
          {renderNewsState(newsCallState, articlesToDisplay, isLoading)}
        </>
      )}
    </Box>
  )
}

function renderNewsState(newsCallState, articlesToDisplay, isLoading) {
  switch (newsCallState) {
    case 'fulfilled':
    case 'pending':
      return (
        <ArticleLayout
          articlesWithImage={articlesToDisplay.articlesWithImage}
          articlesWithoutImage={articlesToDisplay.articlesWithoutImage}
          isLoading={isLoading}
        />
      )
    case 'error':
      return <Box>Error</Box>
    default:
      return <Box />
  }
}

export default NewsSourcePage
