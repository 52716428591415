import { makeStyles, createStyles } from '@material-ui/styles'

const BottomBannerCardStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: 'auto',
      marginTop: 8,
      marginBottom: 8,
      borderRadius: 16,
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        marginLeft: 8,
        marginRight: 8,
        marginTop: 16,
        marginBottom: 16,
      },
      [theme.breakpoints.between('md', 'lg')]: {
        width: 230,
      },
      [theme.breakpoints.between('lg', 'xl')]: {
        width: 310,
      },
      [theme.breakpoints.up('xl')]: {
        width: 405,
      },
    },
    media: {
      height: 180,
      paddingTop: '44.25%', // 16:9
    },
    title: {
      fontSize: 18,
      [theme.breakpoints.down('lg')]: {
        fontSize: 16,
      },
      fontWeight: '600',
      color: theme.palette.primary.headerText,
    },
    subheader: {
      fontSize: 12,
      color: theme.palette.secondary.headerText,
    },
  }),
)

export default BottomBannerCardStyles
