/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchRUInfo = createAsyncThunk('fetchRUInfo', async () => {
  const res = await axios.get(process.env.REACT_APP_RUINFO_ENDPOINT)
  return res.data
})

const initialState = {
  status: 'idle',
  data: {
    title: 'RU Info',
  },
}

const RUInfoSlice = createSlice({
  name: 'RUInfo',
  initialState,
  reducers: {
    updateTabValue: (state, action) => {
      state.tabValue = action.payload
    },
  },
  extraReducers: {
    [fetchRUInfo.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchRUInfo.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchRUInfo.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.data = action.payload
    },
  },
})

export const selectRUInfoData = (state) => state.RUInfo.data
export const selectRUInfoStatus = (state) => state.RUInfo.status

export default RUInfoSlice.reducer
