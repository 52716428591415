import { makeStyles } from '@material-ui/core/styles'

export const MyBenefitsStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    margin: 0,
    height: 470,
    width: 335,
    overflowY: 'auto',
    overflowX: 'clip',
    [theme.breakpoints.down(370)]: {
      width: 287,
      height: 450,
    },
  },
  mainGrid: {
    height: '100%',
    margin: '10px 0px',
  },
  header: {
    marginTop: '5px',
  },
  absence: {
    marginTop: '25px',
    width: '90%',
  },
  links: {
    width: '90%',
    marginTop: '20px',
  },
  row: {
    paddingBottom: '15px',
  },
  ringContainer: {
    width: 55,
    height: 55,
    borderRadius: '50%',
    boxShadow: '0 0 0 4px #A4A4A4',
  },
  visibilityOff: {
    paddingTop: 12,
    fontSize: 45,
  },
  dataText: {
    height: '100%',
  },
  blueIcon: {
    color: theme.palette.base.blue,
  },
  greenIcon: {
    color: theme.palette.base.green,
  },
  redIcon: {
    color: theme.palette.base.red,
  },
  grayIcon: {
    color: theme.palette.base.gray,
  },
  icon: {
    color: theme.palette.base.gray,
    fontSize: 40,
    '&:hover': {
      transform: 'scale(1.07)',
    },
  },
  categoryHolder: {
    margin: '5px 0px',
  },
}))

export default MyBenefitsStyles
