import * as React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import DoneIcon from '@material-ui/icons/Done'
import { Tooltip } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import ReorderFavoriteAppsListStyles from './ReorderFavoriteAppsListStyles'
import { updateReorderFavoriteAppsFlag } from '../../appSlice'

export function ReorderFavoriteAppsListIconButton() {
  const dispatch = useDispatch()
  const classes = ReorderFavoriteAppsListStyles()
  const [reorderFlag, setReorderFlag] = React.useState(false) // State for the iconButton

  const { displaySearchItems, myFavoriteAppsList, reorderFavoriteApps } =
    useSelector((s) => s.myApps)

  // If reorderFavoritesAppList changes, check it's state
  React.useEffect(() => {
    if (!reorderFavoriteApps) {
      // If it is true -> false, set setReorderFlag to false
      setReorderFlag(false)
    }
  }, [reorderFavoriteApps])

  // Handle the onClick event for the IconButton
  const handleModifyFavoritesAppListButton = () => {
    if (reorderFlag) {
      // True -> False. Update the state and redux value
      setReorderFlag(false)
      dispatch(updateReorderFavoriteAppsFlag(false))
    } else {
      setReorderFlag(true)
      dispatch(updateReorderFavoriteAppsFlag(true))
    }
  }

  return (
    <>
      {myFavoriteAppsList?.length > 0 && !displaySearchItems && (
        <Tooltip
          title={<Typography variant="subtitle2">Modify Favorites</Typography>}
          placement="top"
          arrow
        >
          <IconButton
            className={classes.reorderFavoritesButton}
            onClick={() => handleModifyFavoritesAppListButton()}
          >
            {reorderFlag ? (
              <DoneIcon className={classes.reorderButton} />
            ) : (
              <ImportExportIcon className={classes.reorderButton} />
            )}
          </IconButton>
        </Tooltip>
      )}
    </>
  )
}

export default ReorderFavoriteAppsListIconButton
