/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchParkingAnnouncements = createAsyncThunk(
  'fetchParkingAnnouncements',
  async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_RUDOTSSERVICE_ANNOUNCEMENTS_ENDPOINT}`,
    )
    return res.data
  },
)

export const fetchParkingCases = createAsyncThunk(
  'fetchParkingCases',
  async () => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const rcpid = localStorage.getItem('rcpid')
    const res = await axios.get(
      `${process.env.REACT_APP_RUDOTSSERVICE_MYCASES_ENDPOINT}`,
      {
        headers: {
          JWT: jwtToken,
          XWT: rcpid,
        },
      },
    )
    return res.data
  },
)

const initialState = {
  status: '',
  caseStatus: '',
  myCasesOpen: [],
  myCasesClosed: [],
  announcements: [],
  value: 0,
  value2: 0,
  query: '',
}

const RUDOTSServiceCenterSlice = createSlice({
  name: 'RUDOTSServiceCenter',
  initialState,
  reducers: {
    updateTabValue: (state, action) => {
      if (state.value !== action.payload) {
        state.value = action.payload
      }
    },
    updateTabValue2: (state, action) => {
      if (state.value2 !== action.payload) {
        state.value2 = action.payload
      }
      return state
    },
    updateQuery: (state, action) => {
      state.query = action.payload.target.value
    },
  },
  extraReducers: {
    [fetchParkingAnnouncements.pending]: (state) => ({
      ...state,
      status: 'pending',
    }),
    [fetchParkingAnnouncements.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.announcements = action.payload
    },
    [fetchParkingAnnouncements.rejected]: (state) => ({
      ...state,
      status: 'error',
    }),
    [fetchParkingCases.pending]: (state) => ({
      ...state,
      caseStatus: 'pending',
    }),
    [fetchParkingCases.fulfilled]: (state, action) => {
      state.caseStatus = 'fulfilled'
      const myCases = action.payload.results
      state.myCasesOpen = myCases.filter((cases) => cases.state !== 'Closed')
      state.myCasesClosed = myCases.filter((cases) => cases.state === 'Closed')
    },
    [fetchParkingCases.rejected]: (state) => ({
      ...state,
      caseStatus: 'error',
    }),
  },
})

export const { updateTabValue, updateTabValue2, updateQuery } =
  RUDOTSServiceCenterSlice.actions

export default RUDOTSServiceCenterSlice.reducer
