import React from 'react'
import { useSelector } from 'react-redux'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import Dialog from '@material-ui/core/Dialog'
import { ButtonBase, InputLabel } from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

import IconPicker from '../../../../apps/AppComponents/IconPicker/IconPicker'
import { handleUpdateYear } from '../FinanialAidSlice'
import { logDashboardEvent } from '../../../events'

function DocsTab({ widgetData, classes, widgetId, dispatch }) {
  const { year } = useSelector((s) => s.financialAid)

  const statusImportance = {
    Requested: 2,
    Open: 10,
    Acceptable: 11,
  }
  const loadYears = () => {
    const result = []
    widgetData.docsTab?.docs?.forEach((req) => {
      if (
        !result.includes(req.year) &&
        req.year !== 'Lifetime' &&
        req.year !== ''
      ) {
        result.push(req.year)
      }
    })
    result.sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
    const currentYear = new Date().getFullYear()
    const yearsSet = new Set()
    // REQUEST 4/16/24 REMOVE 2025
    result?.forEach((resultYear) => {
      //   if (resultYear?.toString() !== '2025') {
      yearsSet.add(resultYear?.toString())
      //   }
    })
    // REQUEST 4/16/24 REMOVE 2025
    yearsSet.add((currentYear + 1)?.toString())
    yearsSet.add((currentYear - 1)?.toString())
    yearsSet.add(currentYear?.toString())
    const years = Array.from(yearsSet)
    return years
  }
  const dropDown = loadYears()
  const [filteredReq, setFilteredReq] = React.useState([])
  const [open, setOpen] = React.useState(false)
  const [currentReq, setReq] = React.useState()

  const dialogHandler = (newState, req) => {
    setOpen(newState)
    setReq(req)
  }

  const handleChange = (event) => {
    dispatch(handleUpdateYear(event.target.value))
  }

  React.useEffect(() => {
    const filtered = []
    widgetData.docsTab?.docs?.filter((req) => {
      if (req.year === year || req.year === 'Lifetime' || req.year === '') {
        filtered.push(req)
      }
      return []
    })
    const sorted = filtered.sort(
      (a, b) =>
        statusImportance[a.status ? a.status : a.notifyStatus] -
        statusImportance[b.status ? b.status : b.notifyStatus],
    )
    setFilteredReq(sorted)
  }, [year])

  const handleStatusButtonClick = (req) => {
    if (widgetId && dispatch) {
      logDashboardEvent(dispatch, 'dashboardEvent', widgetId, req.req, req.url)
    }
    window.open(req.url, '_blank')
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      wrap="nowrap"
      className={classes.viewGrid}
    >
      <Grid item className={classes.indexAdjust}>
        <Grid
          container
          direction="column"
          alignItems="center"
          className={classes.fullWidth}
        >
          <Grid item>
            <Card className={classes.schoolCodeCard} variant="outlined">
              <Typography variant="body1">
                July {year - 1} - June {year}
              </Typography>
            </Card>
          </Grid>
          <Grid item className={classes.fullWidth}>
            <Grid container direction="row" className={classes.titleRow}>
              <Grid
                item
                container
                xs={2}
                direction="column"
                alignItems="center"
              >
                <Typography variant="subtitle1">Info</Typography>
              </Grid>
              <Grid item xs={7}>
                <Typography variant="subtitle1">Request</Typography>
              </Grid>
              <Grid
                item
                container
                xs={3}
                direction="column"
                alignItems="center"
              >
                <Typography variant="subtitle1">Status</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={classes.fullWidth}>
            <Divider variant="fullWidth" />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="column"
        wrap="nowrap"
        className={classes.reqHolder}
        spacing={1}
      >
        {filteredReq.length !== 0 ? (
          filteredReq.map((req) => (
            <Grid item container direction="row" alignItems="center">
              <Grid
                item
                container
                xs={2}
                direction="column"
                alignItems="center"
                onClick={() => dialogHandler(true, req)}
              >
                <IconPicker iconName="info-circle" size="2x" color="gray" />
              </Grid>
              {currentReq && (
                <Dialog open={open} onClose={() => dialogHandler(false)}>
                  <DialogTitle>
                    <Typography variant="subtitle1">
                      Request Detail: {currentReq.req}
                    </Typography>
                  </DialogTitle>
                  <DialogContent>
                    <Typography variant="body1">{currentReq.reqMsg}</Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      autoFocus
                      onClick={() => dialogHandler(false)}
                      color="primary"
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
              <Grid item xs={7}>
                <Typography variant="caption">{req.req}</Typography>
              </Grid>
              <Grid
                item
                container
                xs={3}
                direction="column"
                alignItems="center"
              >
                <Button
                  onClick={() => handleStatusButtonClick(req)}
                  className={
                    req.status === 'Acceptable' ||
                    req.status === 'Completed' ||
                    req.status === 'Received'
                      ? classes.statusButtonGreen
                      : classes.statusButtonRed
                  }
                >
                  {req.status}
                </Button>
              </Grid>
            </Grid>
          ))
        ) : (
          <Grid item>
            <Box className={classes.noReqBox}>
              <Box display="flex" justifyContent="center">
                <IconPicker iconName="circle-check" size="5x" color="#00b339" />
              </Box>
              <Typography>
                You do not have any financial aid documents for the selected
                year.
              </Typography>
            </Box>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        item
        direction="row"
        alignItems="baseline"
        justifyContent="space-between"
        className={classes.footer}
      >
        <Grid item>
          <InputLabel id="documents-year-select">
            <Select
              labelId="documents-year-select"
              value={year}
              onChange={handleChange}
            >
              {dropDown.map((option) => (
                <MenuItem value={option}>{option}</MenuItem>
              ))}
            </Select>
          </InputLabel>
        </Grid>
        {widgetData.docsTab.links.map((link) => (
          <Grid item>
            <SmallIconLink
              link={link}
              classes={classes}
              widgetId={widgetId}
              dispatch={dispatch}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

function SmallIconLink({ link, classes, widgetId, dispatch }) {
  const handleIconClick = () => {
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardEvent',
        widgetId,
        link.title,
        link.url,
      )
    }
    window.open(link.url, 'blank')
  }

  return (
    <ButtonBase onClick={handleIconClick}>
      <div className={classes.linkContainer}>
        <IconPicker iconName={link.icon} size="lg" color="gray" />
        <Typography className={classes.tinyText}>{link.title}</Typography>
      </div>
    </ButtonBase>
  )
}

export default DocsTab
