import { makeStyles } from '@material-ui/core/styles'

export const MyProfileStyles = makeStyles((theme) => ({
  jobsListContainer: {
    width: '100%',
    height: 70,
    marginBottom: 9,
    paddingLeft: '5px',
    paddingRight: '5px',
    [theme.breakpoints.down(370)]: {
      width: 270,
    },
  },
  marginLeft: {
    marginLeft: 3,
  },
  jobTitleText: {
    textAlign: 'left',
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 1,
    wordBreak: 'keep-all',
    overflow: 'hidden',
  },
  searchBar: {
    margin: 5,
  },
  searchIcon: {
    color: theme.palette.primary.main,
  },
  jobCardBackground: {
    height: 68,
    marginTop: 1,
    marginBottom: 4,
    marginRight: 4,
    padding: 4,
    paddingTop: 4,
    paddingBottom: 4,
    width: '100%',
    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.backgroundColor.ternary,
    },
  },
  subText: {
    overflow: 'hidden',
    color: theme.palette.secondary.headerText,
    fontSize: 14,
    fontWeight: '500',
  },
  jobDetailContainer: {
    marginTop: 5,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    height: 410,
    width: '100%',
    borderWidth: 0,
    textAlign: 'left',
  },
  jobDetailButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  jobDetailButtonApply: {
    background: '#00B339',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#009830',
    },
  },
  jobDetailButton: {
    background: theme.palette.base.red,
    color: theme.palette.base.white,
  },
  jobSearchIcon: {
    color: '#00B339',
    fontSize: 100,
  },
  jobSearchNotFoundText: {
    fontWeight: 900,
    fontSize: 20,
    textAlign: 'center',
  },
}))

export default MyProfileStyles
