/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { Card, Typography as Text, Link } from '@material-ui/core'

import { logDashboardEvent } from '../../events'

import Widget from '../Widget'
import { fetchUniversityMessages } from './UniversityMessagesSlice'
import universityMessagesWidgetStyles from './universityMessagesStyles'
import Loading from '../helpers/Loading'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'

// eslint-disable-next-line no-unused-vars
function UniversityMessages({ className, title, widgetId }) {
  const dispatch = useDispatch()
  const { data, status } = useSelector((s) => s.universityMessages)
  React.useEffect(() => {
    dispatch(fetchUniversityMessages())
  }, [])
  return (
    <Widget className={className}>
      <Widget.Header title={title} category="default" />
      <Widget.Content>
        {renderDYKState(status, data, widgetId, dispatch)}
      </Widget.Content>
    </Widget>
  )
}

function renderDYKState(status, data, widgetId, dispatch) {
  // TODO: Handle Proper Error States
  switch (status) {
    case 'fulfilled':
      return <Loaded data={data} widgetId={widgetId} dispatch={dispatch} />
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage />
        </Grid>
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item></Grid>
        </Grid>
      )
  }
}

function Loaded({ data, widgetId, dispatch }) {
  const classes = universityMessagesWidgetStyles()
  console.log('uni data ', data)
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.gridContainer}
    >
      <Box style={{ height: 474, overflowY: 'auto' }}>
        <UniversityCard
          data={data.content}
          widgetId={widgetId}
          dispatch={dispatch}
        />
      </Box>
    </Grid>
  )
}

function UniversityCard({ data, widgetId, dispatch }) {
  const handleLinkClick = (link, title) => {
    if (widgetId && dispatch) {
      logDashboardEvent(dispatch, 'dashboardLinkEvent', widgetId, title, link)
    }
  }
  const classes = universityMessagesWidgetStyles()
  return (
    <>
      {data?.length === 0 ? (
        <Text className={classes.noCaseText}>
          No University Messages check back later.
        </Text>
      ) : (
        <>
          {data.map((item, index) => (
            <Card
              className={classes.universityMessageCardBackground}
              border={1}
              key={index}
              variant="outlined"
            >
              <Grid container justifyContent="flex-start" direction="column">
                <Grid item className={classes.universityMessageContent}>
                  <img
                    src={item.image}
                    className={classes.universityMessageImage}
                    alt={item.title}
                  />
                  <Box display="flex" flexDirection="column">
                    <Text
                      variant="subtitle2"
                      className={classes.universityMessagesLink}
                    >
                      <Link
                        href={item.url}
                        onClick={() => handleLinkClick(item.url, item.title)}
                        target="_blank"
                        rel="noopener noreferrer"
                        color="primary"
                      >
                        {item.title}
                      </Link>
                    </Text>
                    <Grid item className={classes.universityMessageDate}>
                      <Text variant="subtitle2">{item.date}</Text>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          ))}
        </>
      )}
    </>
  )
}

export default UniversityMessages
