/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchUniversityMessages = createAsyncThunk(
  'fetchUniversityMessages',
  async () => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const rcpid = localStorage.getItem('rutgersEduRCPID')
    const res = await axios.get(process.env.REACT_APP_UNIVERSITY_MESSAGES, {
      headers: {
        JWT: jwtToken,
        XWT: rcpid,
      },
    })
    return res.data
  },
)

const initialState = {
  status: 'idle',
  data: {
    title: 'University Messages',
    image: '',
    content: '',
    categories: [],
  },
}

const universityMessagesSlice = createSlice({
  name: 'didYouKnowWidget',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUniversityMessages.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchUniversityMessages.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchUniversityMessages.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.data.content = action.payload
    },
  },
})

export default universityMessagesSlice.reducer
