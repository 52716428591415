import * as React from 'react'
import uuid from 'react-uuid'
import { useSelector } from 'react-redux'
import { Polyline } from '@react-google-maps/api'

export default function VehicleRoute() {
  const { selectedRoute, routeShape } = useSelector((s) => s.maps)
  const [coord, setCoord] = React.useState(null)
  React.useEffect(() => {
    if (selectedRoute && routeShape.length > 0) {
      const modified = routeShape
        .map((route) =>
          route.geometry.coordinates
            .map((coords) => ({
              lat: coords[1],
              lng: coords[0],
            }))
            .flat(),
        )
        .flat()
      const polylineModified = (
        <Polyline
          key={uuid()}
          // onLoad={onLoad}
          path={modified}
          options={{
            strokeColor: selectedRoute ? selectedRoute.route_color : '#cc0033',
            strokeOpacity: 0.8,
            strokeWeight: 3,
            fillOpacity: 0.35,
            fillColor: selectedRoute ? selectedRoute.route_color : '#cc0033',
            editable: false,
            zIndex: 1,
          }}
        />
      )
      setCoord(polylineModified)
    } else {
      setCoord([])
    }
  }, [routeShape, selectedRoute])

  return <>{coord}</>
}
