import { makeStyles, createStyles } from '@material-ui/styles'

export const ShowAppListStyles = makeStyles((theme) =>
  createStyles({
    showAppListContainer: {
      paddingTop: 0,
      [theme.breakpoints.down('sm')]: {
        paddingTop: 0,
      },
    },
    noMatches: {
      color: theme.palette.primary.headerText,
      fontSize: 20,
      textAlign: 'center',
      fontWeight: '600',
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
    },
    noMatchesContainter: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '5vh',
      alignItems: 'center',
    },
    addToFavoritesButton: {
      backgroundColor: theme.palette.backgroundColor.ternary,
      color: theme.palette.primary.main,
      textAlign: 'center',
      margin: '10px',
    },
    inTextIcon: {
      color: theme.palette.base.lightGray,
      fontSize: 22,
      alignSelf: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
      },
      margin: '2px 2px',
    },
    noMatchesIconText: {
      display: 'flex',
      flexDirection: 'row',
    },
    goToAppsButtonIcon: {
      marginRight: '10px',
    },
    popularAppsHeader: {
      fontSize: 24,
      fontWeight: '500',
      whiteSpace: 'nowrap',
      marginTop: '24px',
      marginBottom: '8px',
      color: theme.palette.primary.headerText,
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        marginLeft: '8px',
      },
    },
    popularText: {
      marginTop: '24px',
      marginBottom: '8px',
    },
    appAltListContainer: {
      marginLeft: '3%',
      marginRight: '3%',
    },
  }),
)

export default ShowAppListStyles
