import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) => ({
  container: {
    padding: 0,
    margin: 0,
    height: 475,
    width: 335,
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.breakpoints.down(370)]: {
      width: 287,
      height: 450,
    },
  },
  image: {
    width: '100%',
    padding: '0 !important',
    margin: 0,
  },
  fullWidth: {
    width: '100%',
  },
  columnGrid: {
    width: '100%',
    padding: 0,
    margin: 0,
  },
  desciption: {
    margin: '10px',
  },
  divider: {
    width: '90%',
    color: theme.palette.primary.main,
  },
  outerContainer: {
    display: 'flex',
  },
  gridTitleText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
    fontSize: '12px',
  },
}))

export default styles
