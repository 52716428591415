/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { doc, collection, getDoc, updateDoc } from 'firebase/firestore'
import axios from 'axios'
import { firestore } from '../../../../../utils/firebase'

const initialState = {
  title: '',
  campus: [],
  levels: [],
  terms: [],
  status: 'idle',
  showCourseRegistrationTutorial: false,
}

export const fetchCourseRegistration = createAsyncThunk(
  'fetchCourseRegistration',
  async () => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const rcpid = localStorage.getItem('rcpid')
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'production') {
      return axios.all([
        axios.get(process.env.REACT_APP_COURSEREGISTRATION_ENDPOINT, {
          headers: {
            JWT: jwtToken,
            XWT: rcpid,
          },
        }),
      ])
    }
    return axios.all([
      axios.get(`${process.env.REACT_APP_COURSEREGISTRATION_ENDPOINT}`),
    ])
  },
)

export const fetchCourseRegistrationTutorial = createAsyncThunk(
  'fetchCourseRegistrationTutorial',
  async () => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const rcpid = localStorage.getItem('rcpid')
    return axios.get(
      `${process.env.REACT_APP_COURSEREGISTRATIONTUTORIAL_ENDPOINT}`,
      {
        headers: {
          JWT: jwtToken,
          XWT: rcpid,
        },
      },
    )
  },
)

export const courseRegistrationSlice = createSlice({
  name: 'fetchCourseRegistration',
  initialState,
  reducers: {
    endTutorial: (state) => {
      state.showCourseRegistrationTutorial = false
      const userDocRef = doc(
        collection(firestore, 'users'),
        localStorage.getItem('user') ?? 'visitor',
        'settings',
        'tutorials',
      )
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'production')
        getDoc(userDocRef)
      updateDoc(userDocRef, { show_course_registration_tutorial: false })
    },
  },
  extraReducers: {
    [fetchCourseRegistration.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchCourseRegistration.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchCourseRegistration.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.title = action.payload[0].data.title
      state.campus = action.payload[0].data.campus
      state.levels = action.payload[0].data.levels
      state.terms = action.payload[0].data.terms
      state.iconLinks = action.payload[0].data.iconLinks
      state.courseRegistrationDataIncomplete =
        !state.title || !state.campus || !state.levels || !state.terms
    },
    [fetchCourseRegistrationTutorial.rejected]: (state) => {
      state.statusTutorialFetch = 'error'
    },
    [fetchCourseRegistrationTutorial.pending]: (state) => {
      state.statusTutorialFetch = 'pending'
    },
    [fetchCourseRegistrationTutorial.fulfilled]: (state, action) => {
      state.statusTutorialFetch = 'fulfilled'
      state.titleTutorial = action.payload.data.title
      state.campusTutorial = action.payload.data.campus
      state.levelsTutorial = action.payload.data.levels
      state.termsTutorial = action.payload.data.terms
      state.iconLinksTutorial = action.payload.data.iconLinks
    },
  },
})
export const selectCourseRegistrationTitle = (state) =>
  state.courseRegistration.title
export const selectCourseRegistrationCampus = (state) =>
  state.courseRegistration.campus
export const selectCourseRegistrationLevels = (state) =>
  state.courseRegistration.levels
export const selectCourseRegistrationTerms = (state) =>
  state.courseRegistration.terms
export const selectCourseRegistrationStatus = (state) =>
  state.courseRegistration.status
export const selectShowCourseRegistrationTutorial = (state) =>
  state.courseRegistration.showCourseRegistrationTutorial
export const selectCourseRegistrationIconLinks = (state) =>
  state.courseRegistration.iconLinks
export const selectCourseRegistrationDataIncomplete = (state) =>
  state.courseRegistration.courseRegistrationDataIncomplete
export const selectCourseRegistrationCampusTutorial = (state) =>
  state.courseRegistration.campusTutorial
export const selectCourseRegistrationLevelsTutorial = (state) =>
  state.courseRegistration.levelsTutorial
export const selectCourseRegistrationTermsTutorial = (state) =>
  state.courseRegistration.termsTutorial
export const selectCourseRegistrationStatusTutorial = (state) =>
  state.courseRegistration.statusTutorialFetch
export const selectCourseRegistrationIconLinksTutorial = (state) =>
  state.courseRegistration.iconLinksTutorial
export const { endTutorial } = courseRegistrationSlice.actions

export default courseRegistrationSlice.reducer
