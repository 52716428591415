import { makeStyles } from '@material-ui/core/styles'

const AppTabStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.components.newsBackground,
    width: '100%',
    marginTop: 55,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  selectedTab: {
    color: theme.palette.base.red,
  },
}))

export default AppTabStyles
