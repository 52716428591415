import * as React from 'react'
import { doc, collection, getDoc } from 'firebase/firestore'
import { makeStyles, createStyles } from '@material-ui/styles'
import { useDispatch, useSelector } from 'react-redux'

import {
  Menu,
  MenuItem,
  IconButton,
  MenuList,
  ListItemIcon,
  ListItemText,
  Typography,
  Tooltip,
} from '@material-ui/core'

import ApartmentIcon from '@material-ui/icons/Apartment'
import LocationCityIcon from '@material-ui/icons/LocationCity'

import { getLatLang } from '../../../Buildings/utils/helpers'
import { setCampus, setLatLang } from '../../redux/myMapsSlice'
import { selectDarkMode } from '../../../../../settings/settingsSlice'
import { firestore } from '../../../../../../../utils/firebase'
import {
  CAMDEN,
  CAMDEN_COLOR,
  Camden,
  NEWARK,
  NEWARK_COLOR,
  NEW_BRUNSWICK,
  NEW_BRUNSWICK_COLOR,
  NewBrunswick,
  Newark,
} from '../../../../utils/constants'

const CampusDropDown = () => {
  // eslint-disable-next-line no-use-before-define
  const classes = NotificationsDropDownStyles()
  const isDark = useSelector(selectDarkMode)

  // const { alerts } = useSelector((state) => state.dashboard);
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const { selectedCampus } = useSelector((s) => s.maps)

  const dispatch = useDispatch()
  // Sort the buildings into respective lists

  // For the campus filter
  const handleChange = (campus) => {
    const city = campus.value
    dispatch(setCampus(city))
    dispatch(setLatLang(getLatLang(city)))
    // Set the item on select menu
  }

  const campusList = [
    {
      label: NewBrunswick,
      value: NEW_BRUNSWICK,
      icon: <ApartmentIcon style={{ color: NEW_BRUNSWICK_COLOR }} />,
    },
    {
      label: Newark,
      value: NEWARK,
      icon: <ApartmentIcon style={{ color: NEWARK_COLOR }} />,
    },
    {
      label: Camden,
      value: CAMDEN,
      icon: <ApartmentIcon style={{ color: CAMDEN_COLOR }} />,
    },
  ]

  React.useEffect(() => {
    // Note: This handles the campus location default.
    const fetchLocationData = async () => {
      const userDocRef = doc(
        collection(firestore, 'users'),
        localStorage.getItem('user') || 'visitor',
        'userInfo',
        'Info',
      )
      // Engage links that open to the maps module will bypass this logic.
      const docData = await getDoc(userDocRef)
      const urlParams = new URLSearchParams(window.location.search)
      const venueWebsite = urlParams.get('venueWebsite')
      const mapsLat = urlParams.get('lat')
      const mapsLng = urlParams.get('lng')
      const address = urlParams.get('address')
      const imageUrl = urlParams.get('imageUrl')
      const eventName = urlParams.get('eventName')
      if (
        !eventName ||
        !imageUrl ||
        !imageUrl ||
        !address ||
        !mapsLng ||
        !mapsLat ||
        !venueWebsite
      ) {
        switch (docData.data()?.locationCode) {
          case '1':
            handleChange(campusList[0])
            break
          case '2':
            handleChange(campusList[1])
            break
          case '3':
            handleChange(campusList[2])
            break
          default:
            handleChange(campusList[0])
        }
      }
    }
    fetchLocationData()
  }, [])

  return (
    <div className={classes.flex}>
      <Tooltip
        title={<Typography variant="subtitle2">Select Campus</Typography>}
        placement="top"
        arrow
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="campus dropdown menu"
          onClick={handleClick}
        >
          <LocationCityIcon className={classes.appBarIcon} />
        </IconButton>
      </Tooltip>

      <Menu
        elevation={5}
        anchorEl={anchorEl}
        className={classes.alertMenu}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: '20vw',
            backgroundColor: isDark ? '#313131' : '#f7f7f7',
          },
        }}
      >
        <MenuList>
          {campusList.map((c) => (
            <CampusObject
              key={c.value}
              campus={c}
              selectedCampus={selectedCampus}
              handleChange={handleChange}
            />
          ))}
        </MenuList>
      </Menu>
    </div>
  )
}

export default CampusDropDown

const CampusObject = ({ campus, selectedCampus, handleChange }) => (
  <MenuItem
    key={campus.label}
    onClick={() => handleChange(campus)}
    style={
      campus.value === selectedCampus ? { backgroundColor: '#e6f7e6' } : {}
    }
  >
    <ListItemIcon>{campus.icon}</ListItemIcon>
    <ListItemText>{campus.label}</ListItemText>
  </MenuItem>
)

const NotificationsDropDownStyles = makeStyles((theme) =>
  createStyles({
    appBarIcon: {
      color: `${theme.palette.components.topBarIcon} !important`,
      fontSize: 24,
    },
    alertMenu: {
      marginTop: 3,
      marginLeft: 5,
    },
    flex: {
      display: 'flex',
    },
    checkCircleOutline: {
      color: theme.palette.base.green,
      marginLeft: 20,
    },
    text: {
      fontSize: 18,
      marginLeft: 8,
    },
  }),
)
