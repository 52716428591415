/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie'
import { auth, storage } from '../../../utils/firebase'

const initialState = {
  fetchLoginHintsStatus: 'idle',
  hints: [],
  fetchUserInfoStatus: 'idle',
  fetchNGUserInfoStatus: 'idle',
  userLoginStatusMessage: '',
  externalId: '',
  username: '',
}

// Mapping errors to error messages
// Used for displaying useful error messages on login failure
const LoginStatus = {
  SUCCESS: {
    symbol: Symbol('green'),
    message: 'Success! Logging you in now',
  },
  CASSHELL_FAILURE: {
    symbol: Symbol('red'),
    message:
      'There was an issue with accessing the CAS Server to log you in. Likely either your code is expired OR you need to logout and resign in. If retrying doesnt work logout at test-cas.rutgers.edu/logout and reload localhost and try again',
  },
  FIREBASEAUTH_FAILURE: {
    symbol: Symbol('yellow'),
    message:
      'There was an issue while attempting to access our Firebase DB. Likely something to do with Auth not updating. Delete your auth account in firebase -> test-my-rutgers -> authentication -> Delete your external ID. Then try logging in again',
  },
  FIREBASEACCOUNT_FAILURE: {
    symbol: Symbol('purple'),
    message:
      'There was an issue while attempting to find your account in Firebase. Try deleting your account in firebase (firebase -> test-my-rutgers -> firestore db -> users) and try again',
  },
  FIREBASECREATION_FAILURE: {
    symbol: Symbol('orange'),
    message:
      'There was an issue while attempting to create your account in Firebase. Check in firebase what got created in Authentication and Users db in firestore and what didnt before retrying. Might want to delete your account and fix before trying again.',
  },
  FIREBASEUPDATE_FAILURE: {
    symbol: Symbol('brown'),
    message:
      'There was an issue while attempting to update your account in Firebase. Account was found, but unable to apple changes.',
  },
  USERPROFILE_FAILURE: {
    symbol: Symbol('blue'),
    message:
      'The endpoint for getting userProfile failed. Double check its returning a response and hasnt been changed',
  },
}

export const fetchLoginHints = createAsyncThunk('fetchLoginHints', async () => {
  const res = await axios.get(process.env.REACT_APP_LOGIN_HINTS)
  return res.data
})

export const fetchFirebaseImage = createAsyncThunk(
  'fetchFirebaseImage',
  async (hint) => {
    const downloadUrl = await storage.refFromURL(hint.imageUrl).getDownloadURL()
    return { hint, downloadUrl }
  },
)

async function signIntoFirebaseAuth(casShellResponse, myrLoginResponse) {
  // This will work since the new endpoint creates the account on firebase
  try {
    // Tries to log in with the externalRCPID
    await auth.signInWithCustomToken(casShellResponse.data.tokens[4].token)
  } catch (error) {
    return { status: LoginStatus.FIREBASEAUTH_FAILURE, payload: {} }
  }
  return {
    status: LoginStatus.SUCCESS,
    payload: {
      casShellResponse,
      myrLoginResponse,
    },
  }
}

export const fetchNGUserInfo = createAsyncThunk('fetchUserInfo', async () => {
  const jwtToken = Cookies.get('myrJwtToken')
  const xwtToken = Cookies.get('rutgersEduRCPID')
  const firebaseToken = Cookies.get('myrFirebaseToken')
  try {
    await auth.signInWithCustomToken(firebaseToken)
  } catch (error) {
    console.log('failed to login firebase')
  }
  try {
    Cookies.set('myrFirebaseToken', 'blank')
    const myrLoginResponse = await axios.get(process.env.REACT_APP_NG_SIGNIN, {
      headers: {
        JWT: jwtToken,
      },
    })
    if (myrLoginResponse.data) {
      localStorage.setItem('user', myrLoginResponse.data?.rcpId)
      localStorage.setItem('rcpid', myrLoginResponse.data?.rcpId)
      localStorage.setItem('myrJwtToken', Cookies.get('myrJwtToken'))
      localStorage.setItem('myrSwtToken', Cookies.get('myrSwtToken'))
      localStorage.setItem('rutgersEduRCPID', Cookies.get('rutgersEduRCPID'))
    } else {
      return {
        status: LoginStatus.USERPROFILE_FAILURE,
        userInfo: {},
      }
    }
    return {
      status: LoginStatus.SUCCESS,
      userInfo: {
        myrLoginResponse,
      },
    }
  } catch (error) {
    return {
      status: LoginStatus.CASSHELL_FAILURE,
      userInfo: {},
    }
  }
})

// set all the local variables in one spot
const loadLocalStorage = (myrLoginResponse) => {
  localStorage.setItem('user', myrLoginResponse.data?.rcpId)
  localStorage.setItem('rcpid', myrLoginResponse.data?.rcpId)
  localStorage.setItem('myrJwtToken', Cookies.get('myrJwtToken'))
  localStorage.setItem('myrSwtToken', Cookies.get('myrSwtToken'))
}

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginVisitor: (state) => {
      state.username = 'visitor'
      localStorage.setItem('user', JSON.stringify(state.username))
      localStorage.setItem(
        'myrJwtToken',
        process.env.REACT_APP_GUEST_DASHBOARD_SECRET,
      )
      localStorage.setItem('rutgersEduRCPID', JSON.stringify(state.username))
      Cookies.set('myrJwtToken', process.env.REACT_APP_GUEST_DASHBOARD_SECRET)
      Cookies.set('rutgersEduRCPID', JSON.stringify(state.username))
      Cookies.set('user', JSON.stringify(state.username))
    },
  },
  extraReducers: {
    [fetchLoginHints.rejected]: (state) => {
      state.fetchLoginHintsStatus = 'error'
    },
    [fetchLoginHints.pending]: (state) => {
      state.fetchLoginHintsStatus = 'pending'
    },
    [fetchLoginHints.fulfilled]: (state, action) => {
      state.fetchLoginHintsStatus = 'fulfilled'
      state.hints = action.payload
    },
    [fetchFirebaseImage.fulfilled]: (state, action) => {
      const hintIndex = state.hints.findIndex(
        (hint) => hint.id === action.payload.hint.id,
      )
      state.hints[hintIndex] = {
        id: action.payload.hint.id,
        text: action.payload.hint.text,
        imageUrl: action.payload.downloadUrl,
      }
    },
    [fetchNGUserInfo.rejected]: (state) => {
      state.fetchNGUserInfoStatus = 'error'
      state.userLoginStatusMessage =
        'There was an issue with accessing the CAS Server to log you in. Please try again.'
    },
    [fetchNGUserInfo.pending]: (state) => {
      state.fetchNGUserInfoStatus = 'pending'
    },
    [fetchNGUserInfo.fulfilled]: (state, action) => {
      switch (action.payload.status) {
        case LoginStatus.SUCCESS: {
          state.fetchNGUserInfoStatus = 'fulfilled'
          break
        }
        case LoginStatus.CASSHELL_FAILURE: {
          state.fetchNGUserInfoStatus = 'error'

          state.userLoginStatusMessage =
            'There was an issue with accessing the CAS Server to log you in. Please try again.'
          break
        }
      }
    },
  },
})

export const { loginVisitor } = loginSlice.actions

export const selectLoginHints = (state) => state.login.hints
export const selectFetchUserInfoStatus = (state) =>
  state.login.fetchUserInfoStatus
export const selectFetchNGUserInfoStatus = (state) =>
  state.login.fetchNGUserInfoStatus
export const selectUserLoginStatusMessage = (state) =>
  state.login.userLoginStatusMessage
export const selectExternalId = (state) => state.login.externalId
export const selectUsername = (state) => state.login.username

export default loginSlice.reducer
