/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import {
  createAppCategoriesWithAppsObject,
  createForYouDataAppsList,
  fetchFavoriteAppsList,
  updateFirebaseWithNewFavoritesList,
  setFavoritedApps,
  storeFavoritesListToFirebase,
} from './helpers'

const initialState = {
  appCategories: [], // List of categories
  apps: [], // List of all apps
  currentTab: 2, // Tab
  displaySearchItems: false,
  forYouApps: [], // Apps to display on for you page
  myFavoriteAppsList: [], // List of favorited app IDs
  searchQuery: '',
  searchBarAppList: [], // List of Apps To Display When Being Searched
  appCategoriesWithApps: [], // List of objects (categoryName and associated apps)
  reorderFavoriteApps: false,
  isGuest: false,
  status: 'idle',
}

export const fetchMyApps = createAsyncThunk(
  'fetchMyApps',
  async (isGuestAccount) => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const rcpid = localStorage.getItem('rutgersEduRCPID')
    const res = await axios.all([
      axios.get(process.env.REACT_APP_MYAPPS_CATEGORIES_ENDPOINT),
      axios.get(process.env.REACT_APP_MYAPPS_APPS_ENDPOINT),
      axios.get(process.env.REACT_APP_MYAPPS_FORYOU_ENDPOINT, {
        headers: {
          JWT: jwtToken,
          XWT: rcpid,
        },
      }),
      fetchFavoriteAppsList(rcpid),
    ])
    // Apps can disabled using the active field.
    // Check FavoritesAppsPage's createFavoriteAppsList method
    // And SeeAllAppsMobile component
    return {
      categoriesData: res[0].data?.filter((app) => app.active),
      appsData: res[1].data?.filter((app) => app.active),
      forYouData: res[2].data,
      favoritesAppListData: res[3].data(),
      isGuest: isGuestAccount,
    }
  },
)

export const myAppsSlice = createSlice({
  name: 'myApps',
  initialState,
  reducers: {
    updateSearchQuery: (state, action) => ({
      // Update the search bar contents
      ...state,
      searchQuery: action.payload,
    }),
    updateSearchBarContents: (state, action) => ({
      // Update the search bar contents
      ...state,
      searchBarAppList: action.payload,
    }),
    showSearchBarContents: (state, action) => ({
      // Show search bar contents
      ...state,
      displaySearchItems: action.payload,
    }),
    updateReorderFavoriteAppsFlag: (state, action) => ({
      // Show search bar contents
      ...state,
      reorderFavoriteApps: action.payload,
    }),
    changeCurrentTab: (state, action) => ({
      // Show search bar contents
      ...state,
      currentTab: action.payload,
    }),
    createMyAppsData: (state) => {
      state.appCategoriesWithApps = createAppCategoriesWithAppsObject(state) // sorted apps into categories data
      state.forYouApps = createForYouDataAppsList(state)
      return state
    },
    updateFavoriteAppsList: (state, action) => {
      storeFavoritesListToFirebase(action.payload)
      state.myFavoriteAppsList = action.payload
      return state
    },
    updateFavoritedIndices: (state, action) =>
      updateFirebaseWithNewFavoritesList(state, action),
  },
  extraReducers: {
    [fetchMyApps.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchMyApps.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchMyApps.fulfilled]: (state, action) => {
      const favoritesAppList = action.payload.favoritesAppListData?.apps // Get list of favorited app IDs
      const { appsData } = action.payload // Get app list
      const categories = action.payload.categoriesData // Get category names
      const { isGuest } = action.payload
      let tabVal = 0
      if (isGuest) {
        tabVal = 2
      } else if (favoritesAppList?.length > 0) {
        tabVal = 0
      } else {
        tabVal = 1
      }
      state.currentTab = tabVal
      state.apps = setFavoritedApps(favoritesAppList, appsData) // New app list which adds isFavorited field and if it's favorited or not
      state.myFavoriteAppsList = favoritesAppList // Store favorited list to redux
      state.appCategories = categories.sort(
        (
          a,
          b, // Store categories to redux
        ) => (a.name > b.name ? 1 : -1),
      )
      // set favorites for the forYou page
      // Iterate through each app object in the array
      action.payload.forYouData?.forEach((singleApp) => {
        singleApp.fragmentList?.forEach((fragment) => {
          if (singleApp.renderType === 'appList') {
            fragment.apps = setFavoritedApps(
              state.myFavoriteAppsList,
              fragment.apps,
            )
          } else {
            fragment.associatedApps?.forEach((associatedApp) => {
              associatedApp.apps = setFavoritedApps(
                state.myFavoriteAppsList,
                associatedApp.apps,
              )
            })
          }
        })
      })
      state.forYouData = action.payload.forYouData // Store for you data to redux
      state.isGuest = isGuest
      state.status = 'fulfilled'
      myAppsSlice.caseReducers.createMyAppsData(state) // Call the reducer to organize data for myApps to use
    },
  },
})

export const selectAppCategories = (state) => state.myApps.appCategories
export const selectApps = (state) => state.myApps.apps
export const selectCurrentTab = (state) => state.myApps.currentTab
export const selectMyFavoriteAppsList = (state) =>
  state.myApps.myFavoriteAppsList
export const selectDisplaySearchItems = (state) =>
  state.myApps.displaySearchItems
export const selectSearchBarAppList = (state) => state.myApps.searchBarAppList
export const selectAppCategoriesWithApps = (state) =>
  state.myApps.appCategoriesWithApps
export const selectForYouApps = (state) => state.myApps.forYouApps
export const selectReorderFavoriteApps = (state) =>
  state.myApps.reorderFavoriteApps
export const selectIsGuest = (state) => state.myApps.isGuest
export const selectMyAppsCallStatus = (state) => state.myApps.status

export const {
  updateSearchQuery,
  updateSearchBarContents,
  showSearchBarContents,
  updateFavoriteAppsList,
  updateFavoritedIndices,
  updateReorderFavoriteAppsFlag,
  createMyAppsData,
  changeCurrentTab,
} = myAppsSlice.actions

export default myAppsSlice.reducer
