/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchTechGuide = createAsyncThunk('fetchTechGuide', async () => {
  const res = await axios.get(process.env.REACT_APP_TECHGUIDE)
  return res.data
})

const initialState = {
  status: 'idle',
  data: {
    title: 'Tech Guide',
  },
}

const TechGuideSlice = createSlice({
  name: 'techGuide',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchTechGuide.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchTechGuide.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchTechGuide.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.data = action.payload
    },
  },
})

export const selectTechGuidData = (state) => state.techGuide.data
export const selectTechGuidStatus = (state) => state.techGuide.status

export default TechGuideSlice.reducer
