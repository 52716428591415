/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Box, Tabs, Tab, useTheme, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { a11yProps } from '../../../dashboard/DashboardComponents/TabPanel/TabPanel'
import { updateMapTabValue } from '../../sections/Map/redux/myMapsSlice'
import { myMapPanelStyles } from './MapTabsMobileStyles'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Typography component="span">{children}</Typography>}
    </div>
  )
}

// Very confusing component because it doesn't pass a component in through the props
// It's setting the tabValue which and relies on the other component to render the content
export function MapTabsMobile(props) {
  const { tabs, TabOne, TabTwo } = props
  const classes = myMapPanelStyles()
  const dispatch = useDispatch()
  const { tabValue } = useSelector((s) => s.maps)
  const theme = useTheme()

  const handleChange = (event, newValue) => {
    dispatch(updateMapTabValue(newValue))
  }

  return (
    <Box className={classes.rootTabBox}>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        className={classes.rootTabContainer}
        aria-label="my-map tabs"
        variant="fullWidth"
      >
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            label={tab}
            className={
              tabValue === index ? classes.activeTab : classes.inactiveTab
            }
            {...a11yProps(index)}
          />
        ))}
      </Tabs>

      <TabPanel value={tabValue} index={0} dir={theme.direction}>
        {TabOne}
      </TabPanel>

      <TabPanel value={tabValue} index={1} dir={theme.direction}>
        {TabTwo}
      </TabPanel>
    </Box>
  )
}
