/* eslint-disable no-param-reassign */
import * as React from 'react'
import { useSelector } from 'react-redux'

import { Card, Typography, Grid, Box, ButtonBase } from '@material-ui/core'
import ReplyAll from '@material-ui/icons/ReplyAll'
import { useHistory } from 'react-router-dom'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import styles from '../settingsStyles'
import PageLink from '../components/PageLink'
import { selectMyProfileData } from '../../dashboard/widgets/MyProfile/myProfileSlice'
import { getImageFromFirebaseWithHook } from '../../apps/helpers'

function Profile() {
  const classes = styles()
  const history = useHistory()
  const widgetData = useSelector(selectMyProfileData)
  const { userProfile } = useSelector((s) => s.myProfile)
  const url =
    userProfile?.roles?.includes('employee') ||
    userProfile?.roles?.includes('ruEmployee') ||
    userProfile?.roles?.includes('rbhsEmployee') ||
    userProfile?.roles?.includes('ruStaff') ||
    userProfile?.roles?.includes('rbhsStaff') ||
    userProfile?.roles?.includes('staff')
      ? 'https://uhr.rutgers.edu/onesource/replace-new-brunswick-id'
      : 'https://ipo.rutgers.edu/publicsafety/iam/student-id'
  const { roles } = userProfile

  const [image, setImage] = React.useState(
    `${process.env.REACT_APP_IMAGES_URL}/assets/images/profile_default_avatar.png`,
  )

  React.useEffect(() => {
    if (widgetData && widgetData.idPhotoLink) {
      getImageFromFirebaseWithHook(widgetData.idPhotoLink, setImage)
    }
  }, [])

  return (
    <Box className={classes.centerPage}>
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.container}
        wrap="nowrap"
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.sectionTitle}
        >
          <ButtonBase
            onClick={() => history.replace('/settings')}
            aria-label="Back"
          >
            <ArrowBackIos />
          </ButtonBase>
          <Typography variant="h1">
            <b>Account Information</b>
          </Typography>
        </Grid>
        <Grid item className={classes.fullWidth}>
          {roles ? (
            <CardBasedOnRole
              roles={roles}
              isEmployeeFt={userProfile?.isEmployeeFt}
              firstName={userProfile?.firstName}
              lastName={userProfile?.lastName}
              classes={classes}
              image={image}
            />
          ) : null}
          <Typography variant="body1" className={classes.idDisclaimer}>
            Digital ID does not replace the policy of having your physical RU ID
            on you while on campus and is not valid for building access.
          </Typography>
          <Card className={classes.linksCard}>
            <PageLink
              title="Request a replacement"
              description="Lost/Stolen"
              link={() => window.open(url, '_blank')}
              icon={<ReplyAll />}
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

const CardBasedOnRole = ({
  roles,
  isEmployeeFt,
  firstName,
  lastName,
  image,
  classes,
}) => {
  //   If you have 1 role, then your template is specific to that role. Guest, fac/staff/ student.

  // Full Time fac/staff trumps all other roles. Landscape—example- student role and fac/staff role-  a fac/staff card is issued.
  // Student role- gets portrait template.
  // Guest role gets a guest card. landscape
  if (
    isEmployeeFt === true ||
    (roles.some(
      (role) =>
        role === 'ruEmployee' ||
        role === 'employee' ||
        role === 'ruFaculty' ||
        role === 'rbhsEmployee' ||
        role === 'rbhsFaculty' ||
        role === 'rbhsRetiree',
    ) &&
      !roles.some(
        (role) =>
          role === 'ruStudent' ||
          role === 'rbhsStudent' ||
          role === 'ruAdmit' ||
          role === 'ruAdmitComing',
      ))
  ) {
    return (
      <Card className={classes.idCardContainer}>
        <Box>
          <Box className={classes.idCardTop}>
            <img
              src="../assets/images/rutgers_shield.png"
              alt="id card"
              className={classes.idCardShield}
            />
          </Box>
          <Box justifyContent="space-between" display="flex">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            ></Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              height={150}
            >
              <Box>
                {firstName ? (
                  <Typography className={classes.idCardName} variant="body2">
                    {firstName}
                  </Typography>
                ) : null}
                {lastName ? (
                  <Typography className={classes.idCardName} variant="body2">
                    {lastName}
                  </Typography>
                ) : null}
              </Box>
              <Typography className={classes.idCardDesignation}>
                FACULTY / STAFF
              </Typography>
            </Box>
            {image ? (
              <img
                className={classes.idProfilePictureImage}
                src={image}
                alt="idCard"
                onError={(e) => {
                  e.target.onerror = null
                  e.target.src = `${process.env.REACT_APP_IMAGES_URL}/assets/images/profile_default_avatar.png`
                }}
              />
            ) : null}
          </Box>
        </Box>
      </Card>
    )
  }
  if (
    roles.some(
      (role) =>
        role === 'ruStudent' ||
        role === 'rbhsStudent' ||
        role === 'ruAdmit' ||
        role === 'ruAdmitComing',
    )
  ) {
    return (
      <Card className={classes.idCardContainerForStudent}>
        <Box display="flex" flexDirection="column">
          <Box className={classes.idCardTop}>
            <img
              src="../assets/images/rutgers_shield.png"
              alt="id card"
              className={classes.idCardShieldForStudent}
            />
          </Box>
          <Box justifyContent="space-between" display="flex">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Box>
                {firstName ? (
                  <Typography className={classes.idCardName} variant="body2">
                    {firstName}
                  </Typography>
                ) : null}
                {lastName ? (
                  <Typography className={classes.idCardName} variant="body2">
                    {lastName}
                  </Typography>
                ) : null}
              </Box>
            </Box>
          </Box>
          <Box display="flex" flex={1} alignItems="flex-end">
            <Typography className={classes.idCardDesignation}>
              STUDENT
            </Typography>
            {image ? (
              <img
                className={classes.idProfilePictureImage}
                src={image}
                alt="idCard"
                onError={(e) => {
                  e.target.onerror = null
                  e.target.src = `${process.env.REACT_APP_IMAGES_URL}/assets/images/profile_default_avatar.png`
                }}
              />
            ) : null}
          </Box>
        </Box>
      </Card>
    )
  }
  return (
    <Card className={classes.idCardGuestContainer}>
      <Box>
        <Box className={classes.idCardGuestTop}>
          <img
            src="../assets/images/rutgers_shield_red.png"
            alt="id card"
            className={classes.idCardShield}
          />
        </Box>
        <Box justifyContent="space-between" display="flex">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          ></Box>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height={150}
          >
            <Box>
              {firstName ? (
                <Typography className={classes.idCardName} variant="body2">
                  {firstName}
                </Typography>
              ) : null}
              {lastName ? (
                <Typography className={classes.idCardName} variant="body2">
                  {lastName}
                </Typography>
              ) : null}
            </Box>
            <Typography className={classes.idCardDesignation}>GUEST</Typography>
          </Box>
          {image ? (
            <img
              className={classes.idProfilePictureImage}
              src={image}
              alt="idCard"
              onError={(e) => {
                e.target.onerror = null
                e.target.src = `${process.env.REACT_APP_IMAGES_URL}/assets/images/profile_default_avatar.png`
              }}
            />
          ) : null}
        </Box>
      </Box>
    </Card>
  )
}

export default Profile
