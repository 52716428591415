import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import {
  AppBar,
  IconButton,
  Grid,
  Toolbar,
  Typography as Text,
} from '@material-ui/core'

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import {
  fetchMyApps,
  selectAppCategories,
  selectForYouApps,
  selectAppCategoriesWithApps,
  selectMyAppsCallStatus,
} from '../../../appSlice'
import SeeAllAppsMobileStyles from './SeeAllAppsMobileStyles'
import ShowAppList from '../../../AppComponents/ShowAppList/ShowAppList'
/**
 * All Apps tab in mobile view
 * Displays all applications
 */
export const SeeAllAppsPageMobile = () => {
  const classes = SeeAllAppsMobileStyles()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const [appList, setAppList] = React.useState([])
  const [categoryTitle, setCategoryTitle] = React.useState('')

  const appCategory = useSelector(selectAppCategories)
  const appCategoriesWithApps = useSelector(selectAppCategoriesWithApps)
  const forYouApps = useSelector(selectForYouApps)
  const status = useSelector(selectMyAppsCallStatus)

  React.useEffect(() => {
    if (status === 'idle' || status === 'pending') {
      dispatch(fetchMyApps())
    } else {
      const query = location.search
      const id = new URLSearchParams(query).get('id')
      const type = new URLSearchParams(query).get('type')
      if (type === 'slider' || type === 'carousel') {
        const category = appCategory.filter((c) => c.id === id)[0]
        const appsList = appCategoriesWithApps.filter(
          (c) => c.name === category.name,
        )[0]
        setCategoryTitle(category.name)
        setAppList(appsList.apps)
      } else if (type === 'forYou') {
        setCategoryTitle(id)
        const category = forYouApps.filter((l) => l.categoryName === id)[0]
        setAppList(category.apps)
      }
    }
  }, [status])

  return (
    <div className={classes.container}>
      <AppBar className={classes.appBar} position="relative" elevation={2}>
        <Toolbar className={classes.container}>
          <Grid container justifyContent="flex-start" alignItems="center">
            <Grid item sm={4}>
              <IconButton onClick={() => history.goBack()}>
                <ArrowBackIosIcon className={classes.mobileBackButtonIcon} />
              </IconButton>
            </Grid>
            <Grid item sm={4} className={classes.pageTitleContainer}>
              <Text className={classes.pageTitle}> {categoryTitle} </Text>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div className={classes.appListContainer}>
        <ShowAppList isMobile inputAppList={appList} category={categoryTitle} />
      </div>
    </div>
  )
}

export default SeeAllAppsPageMobile
