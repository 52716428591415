const myDegreeTutorial = [
  {
    title: 'My Degree Widget Tutorial',
    description: `The My Degree Widget provides you with a quick look at your degree information such as the name, major, GPA, credits earned, and additional links.`,
    top: {
      height: '100%',
      width: '100%',
      left: '0',
    },
    right: {
      height: '0',
      width: '0',
    },
    bottom: {
      height: '0',
      width: '0',
    },
    left: {
      height: '0',
      width: '0',
    },
    popover: {
      top: '200px',
      left: '30px',
    },
  },
  {
    title: 'Major and School',
    description: `Your current major(s)/minor(s) and school association information is listed here.`,
    top: {
      height: '0',
      width: '0',
      left: '0',
    },
    right: {
      height: '55px',
      width: '0px',
    },
    bottom: {
      height: '420px',
      width: '100%',
    },
    left: {
      height: '0',
      width: '0',
    },
    popover: {
      top: '300px',
      left: '30px',
    },
  },
  {
    title: 'GPA And Credits',
    description: `In this section you can view your cumulative GPA, last term GPA or total credits earned by clicking on the eye icon.`,
    top: {
      height: '60px',
      width: '100%',
      left: '0px',
    },
    right: {
      height: '190px',
      width: '0px',
    },
    bottom: {
      height: '215px',
      width: '100%',
    },
    left: {
      height: '190px',
      width: '0px',
    },
    popover: {
      top: '280px',
      left: '30px',
    },
  },
  {
    title: 'Show Your Grade',
    description: `Clicking on the eye icon will show or hide your cumulative GPA, last term GPA and total credits earned.`,
    top: {
      height: '60px',
      width: '100%',
      left: '0px',
    },
    right: {
      height: '190px',
      width: '0px',
    },
    bottom: {
      height: '215px',
      width: '100%',
    },
    left: {
      height: '190px',
      width: '0px',
    },
    popover: {
      top: '280px',
      left: '30px',
    },
  },
  {
    title: 'Degree Navigator',
    description: `A quick link to your degree navigator can be found by clicking this button.`,
    top: {
      height: '265px',
      width: '55px',
      left: '30px',
    },
    right: {
      height: '335px',
      width: '250px',
    },
    bottom: {
      height: '140px',
      width: '100%',
    },
    left: {
      height: '335px',
      width: '30px',
    },
    popover: {
      top: '100px',
      left: '25px',
    },
  },
  {
    title: 'Transcript, Verification, Term Grade',
    description: `A quick link to view your transcript, verification and information about your term grade can be found here.`,
    top: {
      height: '265px',
      width: '75px',
      left: '130px',
    },
    right: {
      height: '335px',
      width: '130px',
    },
    bottom: {
      height: '140px',
      width: '100%',
    },
    left: {
      height: '335px',
      width: '130px',
    },
    popover: {
      top: '100px',
      left: '25px',
    },
  },
  {
    title: 'Transfer Button',
    description: `A quick link to apply for a School to School transfer can be found by clicking this button.`,
    top: {
      height: '265px',
      width: '65px',
      left: '250px',
    },
    right: {
      height: '335px',
      width: '20px',
    },
    bottom: {
      height: '140px',
      width: '100%',
    },
    left: {
      height: '335px',
      width: '250px',
    },
    popover: {
      top: '100px',
      left: '25px',
    },
  },
  {
    title: 'Apply For Graduation',
    description: `A quick link to apply for graduation can be found by clicking this button.`,
    top: {
      height: '375px',
      width: '70px',
      left: '25px',
    },
    right: {
      height: '475px',
      width: '240px',
    },
    bottom: {
      height: '30px',
      width: '70px',
      left: '25px',
    },
    left: {
      height: '475px',
      width: '25px',
    },
    popover: {
      top: '180px',
      left: '25px',
    },
  },
  {
    title: 'Check For Graduation',
    description: `A quick link to check for graduation can be found by clicking this button.`,
    top: {
      height: '375px',
      width: '70px',
      left: '135px',
    },
    right: {
      height: '475px',
      width: '130px',
    },
    bottom: {
      height: '30px',
      width: '70px',
      left: '135px',
    },
    left: {
      height: '475px',
      width: '135px',
    },
    popover: {
      top: '180px',
      left: '25px',
    },
  },
  {
    title: 'Plan for RUN4 Success',
    description: `A quick link to plan for RUN4 success can be found by clicking this button.`,
    top: {
      height: '375px',
      width: '70px',
      left: '245px',
    },
    right: {
      height: '475px',
      width: '20px',
    },
    bottom: {
      height: '30px',
      width: '70px',
      left: '245px',
    },
    left: {
      height: '475px',
      width: '245px',
    },
    popover: {
      top: '180px',
      left: '25px',
    },
  },
]

export default myDegreeTutorial
