// This holds the default mappings for news sections
export const sectionMap = {
  rutgersToday: 'Rutgers Today',
  top: 'Top',
  feature: 'Featured',
  trend: 'Trending',
  most: 'Most Viewed',
  you: 'For You',
  rest: 'More News',
  social: 'Social Media',
}

export const twitterSource = {
  id: 'Twitter',
  sid: 50,
  url: 'https://twitter.com/RutgersU',
}

export const MAX_ARTICLES_PER_SECTION = 5
