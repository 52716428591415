import * as React from 'react'
import { useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { animated, useSpring } from 'react-spring'
import { useScroll } from '@use-gesture/react'
import styles from './WidgetStyles'
import * as widgets from '.'
import Loading from './helpers/Loading'
import { analytics } from '../../../../utils/firebase'
import { useHorizontalScroll } from '../../../helpers/useHorizontalScroll'
import GlanceMenu from '../../../navigation/glanceMenu/GlanceMenu'
import IconPicker from '../../apps/AppComponents/IconPicker/IconPicker'
import { Content, Header } from './Widget'
import ErrorPage from '../ErrorPage'
import WidgetErrorBoundary from './WidgetErrorBoundary'

/**
  WidgetsList is used to display all the widgets on the dashboard page
 */
function MobileWidgetsList(params) {
  const classes = styles()
  const scrollRef = useHorizontalScroll()
  const [spring, set] = useSpring(() => ({
    transform: 'perspective(500px) rotateY(0deg)',
  }))

  const bind = useScroll((event) => {
    set({
      transform: `perspective(500px) rotateY(${
        event.scrolling ? clamp(event.delta[0]) : 0
      }deg)`,
    })
  })

  function clamp(value, clampAt = 2) {
    if (value > 0) {
      return value > clampAt ? clampAt : value
    }
    return value < -clampAt ? -clampAt : value
  }
  return (
    <Box>
      <div ref={scrollRef} className={classes.container} {...bind()}>
        {renderWidgetListState(
          params.widgetCallState,
          params.apiReturnedWidgets,
          spring,
        )}
      </div>
      <Box className={classes.mobileGlanceMenu}>
        <GlanceMenu // Custom Glance Widget Component I created
          width={350}
          height="87.15vh"
          scrollbarReference={scrollRef}
        />
      </Box>
    </Box>
  )
}

function renderWidgetListState(widgetCallState, apiReturnedWidgets, spring) {
  switch (widgetCallState) {
    case 'fulfilled':
      return <Loaded apiReturnedWidgets={apiReturnedWidgets} spring={spring} />
    case 'pending':
      return <Loading />
    case 'error':
      return <ErrorPage />
    default:
      return <Box />
  }
}

function Loaded(props) {
  const classes = styles()
  const { userProfile } = useSelector((s) => s.myProfile)
  React.useEffect(() => {
    if (process.env.REACT_APP_FIREBASE_ON === 'true') {
      const widgetNames = props.apiReturnedWidgets.map(
        (apiWidget) => apiWidget.name,
      )
      analytics.logEvent('widget_shown', { widgets: widgetNames })
    }
  }, [])

  return props.apiReturnedWidgets.map((apiWidget) => {
    let Widget = widgets[apiWidget.key]
    Widget = Widget ?? widgets.WidgetNotFound

    const { proxyNetId } = userProfile
    if (
      (apiWidget.proxy === 'No' ||
        (apiWidget.proxy !== '' &&
          !userProfile.proxyPermissions?.includes(apiWidget.proxy))) &&
      proxyNetId
    ) {
      return (
        <animated.div
          key={apiWidget.key}
          className={classes.card}
          style={{
            ...props.spring,
          }}
        >
          <Grid item>
            <Paper className={classes.widget} elevation={0} category="default">
              <Header title={apiWidget.title} />
              <Content>
                <Box
                  height="100%"
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  className={classes.proxyModeContainer}
                >
                  <IconPicker
                    iconName="times-circle"
                    color="#cc0033"
                    size="5x"
                  />
                  <Typography
                    variant="subtitle1"
                    className={classes.proxyModeText}
                  >
                    Data Not Available in Proxy Mode.
                  </Typography>
                </Box>
              </Content>
            </Paper>
          </Grid>
        </animated.div>
      )
    }
    if (apiWidget.showOnDashboard) {
      return (
        <animated.div
          key={apiWidget.key}
          className={classes.card}
          style={{
            ...props.spring,
          }}
        >
          <WidgetErrorBoundary
            key={apiWidget.key}
            title={apiWidget.title}
            className={classes.widget}
          >
            <Widget
              key={apiWidget.key}
              widgetId={apiWidget.key}
              className={classes.widget}
              title={apiWidget.title}
              proxy={apiWidget.proxy}
              showNote={apiWidget.dsp_note}
              noteMessage={apiWidget.note_msg}
              notePosition={apiWidget.note_position}
              noteBackground={apiWidget.note_background}
              noteIcon={apiWidget.note_icon}
              noteUrl={apiWidget.note_url}
              noteFontColor={apiWidget.note_fontColor}
            >
              {apiWidget.name}
            </Widget>
          </WidgetErrorBoundary>
        </animated.div>
      )
    }
    return null
  })
}

export default MobileWidgetsList
