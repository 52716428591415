import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) => ({
  container: {
    padding: 0,
    margin: 0,
    height: 470,
    width: 335,
    overflowY: 'clip',
    overflowX: 'hidden',
    [theme.breakpoints.down(370)]: {
      width: 287,
      height: 450,
    },
  },
  gridContainer: {
    height: '100%',
  },
  inputContainer: {
    borderBottom: '1px solid',
    borderColor: `${theme.palette.backgroundColor.ternary} !important`,
    padding: '0px',
    margin: '0px',
  },
  cards: {
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingTop: '5px',
  },
  card: {
    padding: '5px',
    margin: '2px 5px',
  },
  formControl: {
    margin: '0px 3px',
  },
  accordion: {
    margin: '0px 5px',
    backgroundColor: theme.palette.backgroundColor.secondary,
    height: 'min-content',
    '&.Mui-expanded': {
      margin: '0px 5px',
    },
  },
  accordionDetails: {
    padding: '0px 5px 5px',
    borderRadius: '0px',
  },
  searchIcon: {
    color: theme.palette.base.red,
    fontSize: 24,
  },
  selectorGrid: {
    margin: '10px 0px 0px 0px',
    alignItems: 'baseline',
  },
  searchBar: {
    margin: '5px',
  },
  selectorCard: {
    margin: '5px',
    padding: '5px',
    backgroundColor: theme.palette.backgroundColor.ternary,
  },
  select: {
    '&:before': {
      borderColor: theme.palette.base.red,
    },
  },
  icon: {
    fill: theme.palette.base.red,
  },
}))

export default styles
