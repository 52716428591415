import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import InputBase from '@material-ui/core/InputBase'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import BackspaceIcon from '@material-ui/icons/Backspace'
import {
  updateSearchQuery,
  updateSearchBarContents,
  showSearchBarContents,
} from '../../../apps/appSlice'
// import {
//   setSearchValue,
//   setSelectedRoutes,
//   setSelectedStops,
// } from '../../../maps/sections/Map/redux/myMapsSlice'
// import { setSelectedCampusBuildings } from '../../../maps/sections/Buildings/redux/myBuildingsSlice'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}))

export default function CustomizedInputBase({
  placeholder,
  value,
  onChange,
  onRequestSearch,
  onCancelSearch,
  webViewMode,
  moduleName,
  dispatch,
}) {
  const classes = useStyles()
  return (
    <Paper component="form" className={classes.root} variant="outlined">
      <InputBase
        className={classes.input}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyPress={(e) => {
          if (e.key === 'Enter' && webViewMode) {
            onRequestSearch()
            e.preventDefault()
          } else if (e.key === 'Enter') {
            e.preventDefault()
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            if (moduleName === 'myApps') {
              dispatch(updateSearchQuery(''))
              dispatch(updateSearchBarContents([]))
              dispatch(showSearchBarContents(false))
            }
            if (moduleName === 'myMaps') {
              onCancelSearch()
            }
          }
        }}
        inputProps={{ 'aria-label': 'search' }}
      />
      <Divider className={classes.divider} orientation="vertical" />
      {value === '' ? (
        <IconButton
          onClick={onRequestSearch}
          className={classes.iconButton}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
      ) : (
        <IconButton
          onClick={onCancelSearch}
          className={classes.iconButton}
          aria-label="search"
        >
          <BackspaceIcon />
        </IconButton>
      )}
    </Paper>
  )
}
