/* eslint-disable no-param-reassign */
import React from 'react'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'

import styles from './ImageLinkStyles'
// import { storage } from '../../../../../utils/firebase'
import { logDashboardEvent } from '../../events'
import { getImageFromFirebaseWithHook } from '../../../apps/helpers'

function ImageLink({ imageLink, widgetId, dispatch }) {
  const { imageName, link, image } = imageLink
  const classes = styles()

  const [downloadedImage, setImg] = React.useState(
    './assets/images/placeholder-image.png',
  )

  //   const getImage = async (imageUrl) => {
  //     if (imageUrl) {
  //       imageUrl = imageUrl?.includes(
  //         `gs://${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
  //       )
  //         ? imageUrl
  //         : `gs://${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}${imageUrl}`
  //     }
  //     try {
  //       const img = await storage.refFromURL(imageUrl).getDownloadURL()
  //       setImg(img)
  //     } catch (error) {
  //       console.error(error)
  //       setImg('./assets/images/image-not-found.png')
  //     }
  //   }

  //   React.useEffect(() => {
  //     getImage(image)
  //   }, [])

  React.useEffect(() => {
    if (image) {
      getImageFromFirebaseWithHook(image, setImg)
    }
  }, [])

  const handleImageClick = () => {
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardLinkEvent',
        widgetId,
        imageName,
        link,
      )
    }
    window.open(link, '_blank')
  }

  return (
    <Grid className={classes.container} item>
      <Card
        className={classes.card}
        variant="outlined"
        onClick={handleImageClick}
      >
        <CardMedia className={classes.image} image={downloadedImage} />
        <CardContent className={classes.cardContent}>
          <Typography className={classes.text}>{imageName}</Typography>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default ImageLink
