import * as React from 'react'
import { Box, Grid, Button, Typography as Text } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectApps,
  selectMyFavoriteAppsList,
  selectReorderFavoriteApps,
} from '../../../apps/appSlice'
import ReorderWidgetList from '../../../apps/AppComponents/ReorderFavoriteAppsList/ReorderFavoriteAppsList'
import SetCarouselDimensions from '../../../apps/AppComponents/AppCarouselCombined/AppCarouselDimensions'
import CheckIsMobile from '../../../../../utils/isMobileHook'
import MyFavoriteStyles from './myFavoriteStyles'
import { onAppClick } from './utils/myFavoriteEventHandlers'
import { MyFavoritesEmptyComponent } from './MyFavoritesEmpty'
import MyFavoritesIconPicker from '../../../apps/AppComponents/IconPicker/MyFavoritesIconPicker'
const Item = ({ backGround, icon, title, url }) => {
  const classes = MyFavoriteStyles()
  const dispatch = useDispatch()
  const isMobile = CheckIsMobile()
  // handle App tile click (open window and event logging)
  const handleClick = () => onAppClick(title, url, dispatch, isMobile)

  return (
    <Button
      aria-label={title}
      className={classes.widgetTileWrapper}
      style={{ background: backGround, border: `2px solid ${backGround}` }}
      onClick={handleClick}
    >
      <Box className={classes.widgetTile}>
        <Box className={classes.widgetIcon}>
          <MyFavoritesIconPicker
            iconName={icon}
            color="white"
            size="3x"
            customSize={50}
          />
        </Box>

        <Box className={classes.widgetTitle}>
          <Text aria-hidden="true" style={{ padding: '2px' }} variant="caption">
            {title}
          </Text>
        </Box>
      </Box>
    </Button>
  )
}

function MyFavoritesManageView() {
  const appsList = useSelector(selectApps)
  const dimensions = SetCarouselDimensions()
  const myFavoriteAppsList = useSelector(selectMyFavoriteAppsList)
  const selectReorderFavoriteAppsList = useSelector(selectReorderFavoriteApps)
  const [favoritesAppList, setFavoriteAppsList] = React.useState([])

  // Create the favorites app list initially
  React.useEffect(() => {
    createFavoriteAppsList()
  }, [])

  // Recreate the list whenever the favorites app list is changed (Reorder)
  React.useEffect(() => {
    createFavoriteAppsList()
  }, [myFavoriteAppsList])

  const createFavoriteAppsList = () => {
    const appIndexList = myFavoriteAppsList
    const extractedFavoritesApps = {}

    appIndexList.forEach((id, index) => {
      extractedFavoritesApps[id.toString()] = { order: index, app: null }
    })
    appsList.forEach((app) => {
      const appID = app.id.toString()
      if (appID in extractedFavoritesApps) {
        extractedFavoritesApps[appID].app = app
      }
    })

    const extractedFavoritesAppsList = Object.values(extractedFavoritesApps)

    const removeNullFromExtractedFavoritesAppsList =
      extractedFavoritesAppsList.filter((el) => el.app != null)

    const sortedRemoveNullFromExtractedFavoritesAppsList =
      removeNullFromExtractedFavoritesAppsList.sort((a, b) =>
        a.order > b.order ? 1 : -1,
      )
    const finalFavoritedAppsList =
      sortedRemoveNullFromExtractedFavoritesAppsList
        .map((app) => app.app)
        .filter((appList) => appList.active)

    setFavoriteAppsList(finalFavoritedAppsList)
  }

  function renderWebFavoritesPage() {
    return selectReorderFavoriteAppsList ? (
      <Box>
        <ReorderWidgetList
          hideInfoIcon
          isMobile
          favoritesAppList={favoritesAppList}
          dimensions={dimensions}
          enableClick={!selectReorderFavoriteAppsList}
        />
      </Box>
    ) : (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {favoritesAppList.map((app) => (
          <Grid item key={app.title}>
            <Item
              title={app.title}
              backGround={app.color}
              icon={app.icon}
              url={app.url}
            />
          </Grid>
        ))}
      </Grid>
    )
  }

  return (
    <>
      {myFavoriteAppsList.length ? (
        renderWebFavoritesPage()
      ) : (
        <MyFavoritesEmptyComponent />
      )}
    </>
  )
}

export default MyFavoritesManageView
