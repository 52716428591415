import { makeStyles } from '@material-ui/core/styles'

// TODO Bring Back Unique Colors
export const gradeStyles = makeStyles((theme) => ({
  content: {
    margin: 0,
    padding: 0,
    height: 475,
    overflowY: 'hidden',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  transcriptIconButton: {
    color: theme.palette.secondary.headerText,
  },
  transcriptContainer: {
    width: 155,
  },
  header: {
    padding: 8,
    backgroundColor: theme.palette.background.widgetSubItemBackground,
  },
  headerContainer: {
    padding: '1px 0px',
  },
  grades: {
    overflowY: 'auto',
    width: 335,
    overflowX: 'hidden',
    borderRadius: 5,
    flex: 1,
  },
  greyBackground: {
    paddingLeft: 8,
    paddingTop: 4,
    paddingBottom: 4,
    backgroundColor: theme.palette.paper.secondary,
  },
  nonGreyBackground: {
    paddingLeft: 8,
    paddingTop: 4,
    paddingBottom: 4,
    backgroundColor: theme.palette.backgroundColor.secondary,
  },
  greyBackgroundText: {
    color: theme.palette.primary.headerText,
    width: 335,
  },
  title: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 1,
    wordBreak: 'keep-all',
    overflow: 'hidden',
    fontWeight: '600',
    fontSize: 12,
    marginBottom: 2,
    color: theme.palette.primary.headerText,
  },
  descr: {
    fontWeight: '600',
    fontSize: 9.5,
    color: theme.palette.secondary.headerText,
  },
  courseContainer: {
    width: 110,
    whiteSpace: 'pre',
  },
  gradeButtonContent: {
    width: '50px !important',
    maxWidth: '50px !important',
    minWidth: '50px !important',
    marginRight: 5,
  },
  visibilityIcon: {
    color: theme.palette.primary.headerText,
  },
  select: {
    '&:before': {
      borderColor: theme.palette.base.red,
    },
  },
  icon: {
    fill: theme.palette.base.red,
  },
  widgetContent: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  holdStyle: {
    paddingRight: 10,
    paddingLeft: 10,
    height: 475,
    overflowY: 'clip',
    marginTop: 165,
    width: 335,
  },
  award: {
    marginTop: '15px',
    margin: '10px',
    padding: '5px',
    backgroundColor: theme.palette.backgroundColor.ternary,
  },
  titleStyle: {
    fontWeight: 'bold',
  },
}))

export default gradeStyles
