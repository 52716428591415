import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import {
  updateAlert,
  refetchDefaultWidgets,
  widgetsAreEqual,
  setWidgets,
} from '../adminPageSlice'

function AlertAdmin() {
  const dispatch = useDispatch()
  const alertObj = useSelector((s) => s.adminPage.alertObj)
  const currentWidgets = useSelector((s) => s.adminPage.widgets)

  React.useEffect(() => {
    if (!alertObj.showAlert) {
      fetchData()
    }
  }, [alertObj])

  async function fetchData() {
    const d = await refetchDefaultWidgets()
    const newWidgets = d.dashboardData?.sort((a, b) => a.order - b.order)
    // Only update state if widgets are not equal to prevent rerender.
    // Mostly used as a sanity check because they should be equal because we're saving the data to state and firestore at the same time before this function triggers
    if (!widgetsAreEqual(currentWidgets, newWidgets)) {
      dispatch(setWidgets(newWidgets))
    }
  }

  const handleSnackbarClose = () => {
    const defaultAlert = {
      showAlert: false,
      message: '',
    }
    dispatch(updateAlert(defaultAlert))
  }

  return (
    <Snackbar
      autoHideDuration={5000}
      open={alertObj.showAlert}
      onClose={handleSnackbarClose}
    >
      <Alert severity="success">{alertObj.message}</Alert>
    </Snackbar>
  )
}

export default AlertAdmin
