/* eslint-disable react/no-array-index-key */
import * as React from 'react'
import { Card, Typography as Text, Grid, Link } from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { AnnouncementObjectStyles } from './facultyStaffCenterStyles'
import { logDashboardEvent } from '../../events'
/**
 * Announcement Object shows announcements in the Faculty/Staff Service Center widget
 * @param announcement the announcement object with title and link traits
 */
function Announcement({ announcements, widgetId, dispatch }) {
  const handleLinkClick = (link, title) => {
    if (widgetId && dispatch) {
      logDashboardEvent(dispatch, 'dashboardLinkEvent', widgetId, title, link)
    }
  }
  const classes = AnnouncementObjectStyles()
  return (
    <>
      {announcements.length === 0 ? (
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <CheckCircleOutlineIcon className={classes.circleIcon} />
          </Grid>

          <Grid item>
            <Text variant="subtitle1">No Announcement Data</Text>
          </Grid>
        </Grid>
      ) : (
        <>
          {announcements.results.map((announcement, index) => (
            <Card
              className={classes.announcementCardBackground}
              border={1}
              key={index}
              variant="outlined"
            >
              <Grid container justifyContent="flex-start" direction="column">
                <Grid item>
                  <Text variant="subtitle1">
                    <Link
                      href={announcement.details_url}
                      onClick={() =>
                        handleLinkClick(
                          announcement.details_url,
                          announcement.title,
                        )
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      color="primary"
                    >
                      {announcement.title}
                    </Link>
                  </Text>
                </Grid>
              </Grid>
            </Card>
          ))}
        </>
      )}
    </>
  )
}

export default Announcement
