import { makeStyles } from '@material-ui/core/styles'
import { desktopSideNavWidth } from '../navigationValues'

// TODO Make these colors match properly instead of hardcoded
export const sideNavStyles = makeStyles((theme) => ({
  sideNavDrawer: {
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.components.sideNav,
    color: theme.palette.base.white,
  },
  drawerOpen: {
    width: desktopSideNavWidth,
  },
  sideNavDrawerClosed: {
    width: '55px',
    overflow: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  sideNavDrawerOpen: {
    width: '300px',
    overflow: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  listIconStyle: {
    color: theme.palette.components.sideNavIcon,
  },
  nested: {
    marginLeft: theme.spacing(2),
    borderLeft: 'solid',
    borderColor: theme.palette.components.sideNavIcon,
  },
  dialog: {
    width: '70vw',
    maxWidth: '100%',
    height: '70vh',
    maxHeight: '450px',
    overflow: 'hidden',
    padding: '8px 12px',
    backgroundColor: theme.palette.backgroundColor.secondary,
  },
  dialogContainer: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  mainDialog: {
    maxWidth: '100vw',
  },
  buttonWidth: {
    width: '100%',
  },
  buttonReorder: {
    width: '100%',
    height: '40px',
    '&:hover': {
      cursor: 'grab',
    },
  },
  button: {
    height: '44px',
    padding: '6px',
    width: '100%',
    margin: '2px auto',
    justifyContent: 'flex-start',
    border: '2px solid blue',
    borderColor: theme.palette.base.lightGray,
  },
  widgetPaper: {
    width: '100%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  selectedPaper: {
    width: '100%',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  widgetPaperPadding: {
    padding: '5px',
  },
  buttonZIndex: {
    zIndex: theme.zIndex.modal + 100,
  },
  tabs: {
    width: '100%',
  },
  dialogContent: {
    padding: '5px 5px',
  },
  desc: {
    display: 'flex',
    alignContent: 'flex-end',
    justifyItems: 'flex-end',
  },
  textField: {
    width: '100%',
    height: '48px',
    margin: '0 auto',
  },
  paper: {
    maxWidth: '100%',
    padding: '5px',
  },
  verticalContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    height: '100%',
  },
  scrollContainer: {
    overflowY: 'auto',
    flexGrow: 1,
    padding: '5px',
    maxHeight: '100%',
  },
  popupContainer: {
    flexShrink: 1,
  },
  reorderListStyles: {
    margin: '5px',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  header: {
    textAlign: 'left',
    fontWeight: 500,
    fontSize: '.9rem',
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-user-select': 'none' /* Safari */,
    '-moz-user-select': 'none' /* Firefox */,
    '-ms-user-select': 'none' /* IE10+/Edge */,
    'user-select': 'none' /* Standard */,
  },
  cardContainer: {
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-user-select': 'none' /* Safari */,
    '-moz-user-select': 'none' /* Firefox */,
    '-ms-user-select': 'none' /* IE10+/Edge */,
    'user-select': 'none' /* Standard */,
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'flex-start',
    padding: '0 5px',
    paddingTop: '5px',
    marginBottom: '-10px',
    justifyContent: 'space-between',
  },
  paperContainer: {
    height: '100%',
  },
  editButtonText: {
    color: theme.palette.base.red,
    fontSize: 12,
  },
  deletePaper: {
    padding: '5px',
    '-webkit-user-select': 'none' /* Safari */,
    '-moz-user-select': 'none' /* Firefox */,
    '-ms-user-select': 'none' /* IE10+/Edge */,
    'user-select': 'none' /* Standard */,
  },
  icon: {
    color: theme.palette.primary.contrastText,
  },
  trashIcon: {
    color: theme.palette.base.red,
  },
  headerRowContainer: {
    padding: '5px',
  },
  divider: {
    backgroundColor: theme.palette.base.red,
  },
  description: {
    padding: '5px',
  },
  sideNavOpenClose: {
    color: theme.palette.base.white,
    height: 43,
    width: '97%',
    marginLeft: 5,
    borderRadius: '0.5rem',
    '&:focus-visible': {
      backgroundColor: theme.palette.components.sideNavHover,
      outline: `1px solid ${theme.palette.button.focusBorder}`,
      boxShadow: `0 0 0 3px ${theme.palette.button.focusOutline}`,
    },
    '&:hover': {
      backgroundColor: theme.palette.components.sideNavHover,
      cursor: 'pointer',
    },
  },
  sideNavButton: {
    width: 'calc(100% - 10px)',
    height: 43,
    marginLeft: 6,
    borderRadius: '0.5rem',
    '&:focus-visible': {
      backgroundColor: theme.palette.components.sideNavHover,
      outline: `1px solid ${theme.palette.button.focusBorder}`,
      boxShadow: `0 0 0 3px ${theme.palette.button.focusOutline}`,
    },
    '&:hover': {
      backgroundColor: theme.palette.components.sideNavHover,
      cursor: 'pointer',
    },
  },
  sideNavButtonActive: {
    width: 'calc(100% - 10px)',
    height: 43,
    marginLeft: 6,
    borderRadius: '0.5rem',
    background: theme.palette.base.white,
    color: theme.palette.base.red,
    '&:focus-visible': {
      backgroundColor: theme.palette.base.white,
      outline: `1px solid ${theme.palette.button.focusBorder}`,
      boxShadow: `0 0 0 3px ${theme.palette.button.focusOutline}`,
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.base.white,
    },
  },
  sideNavIcon: {
    marginLeft: '-5px',
  },
  sideNavIconColor: {
    color: theme.palette.base.red,
    backgroundColor: theme.palette.base.white,
    marginLeft: '-5px',
  },
  listItemText: {
    color: theme.palette.base.white,
  },
  listItemTextActive: {
    color: theme.palette.base.red,
  },
}))

export default sideNavStyles
