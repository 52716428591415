/* eslint-disable no-nested-ternary */
import * as React from 'react'
import { useSelector } from 'react-redux'
import { List, ListSubheader } from '@material-ui/core'
import {
  EmptyComponent,
  sortListAlphabetically,
} from '../../../../utils/helpers'
import StopsListComponent from '../../../List/StopsListComponent/StopsListComponent'
import {
  fetchStopsFavorites,
  setSelectedStops,
} from '../../../../sections/Map/redux/myMapsSlice'

function StopsList({ dispatch, classes }) {
  const [loading, setLoading] = React.useState(true)

  const {
    stops,
    stopsLoading,
    selectedStops,
    selectedFavoritedStops,
    favoritedStopsLoading,
  } = useSelector((s) => s.maps)

  React.useEffect(() => {
    dispatch(fetchStopsFavorites())
  }, [])

  React.useEffect(() => {
    if (stopsLoading === 'fulfilled' && favoritedStopsLoading === 'fulfilled') {
      setSheetData()
      setLoading(false)
    } else {
      setLoading(true)
    }
  }, [stopsLoading, favoritedStopsLoading])

  const setSheetData = () => {
    dispatch(
      setSelectedStops(
        sortListAlphabetically(
          stops.filter((el) => ![].includes(el)),
          'stop_name',
        ),
      ),
    )
  }

  return (
    <div className={classes.outerList}>
      {loading ? (
        <></>
      ) : (
        <>
          {selectedStops.length > 0 && (
            <List className={classes.list}>
              {selectedFavoritedStops.length > 0 && (
                <li
                  key="section-favorited-stops"
                  className={classes.newListSection}
                >
                  <ul className={classes.newUl}>
                    <ListSubheader className={classes.listSubHeader}>
                      Favorited Stops
                    </ListSubheader>
                    {selectedFavoritedStops.map((stop, index) => (
                      <StopsListComponent
                        key={`${stop.stop_code}-${stop.name}`}
                        stop={stop}
                        index={index}
                        code={stop.stop_code}
                        isFavorited={selectedFavoritedStops.some(
                          (s) => s.route_id === stop.stop_id,
                        )}
                      />
                    ))}
                  </ul>
                </li>
              )}

              <li key="section-stops" className={classes.newListSection}>
                <ul className={classes.newUl}>
                  <ListSubheader className={classes.listSubHeader}>
                    Stops
                  </ListSubheader>
                  {selectedStops.map((stop, index) => (
                    <StopsListComponent
                      stop={stop}
                      key={`${stop.stop_code}-${stop.name}`}
                      index={index}
                      code={stop.code}
                      isFavorited={selectedFavoritedStops.some(
                        (s) => s.route_id === stop.stop_id,
                      )}
                    />
                  ))}
                </ul>
              </li>
            </List>
          )}
          {selectedStops.length === 0 && (
            <div className={classes.list}>
              <EmptyComponent />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default StopsList
