import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import idleTimerStyles from './IdleTimerStyles'
import { handleProxyLogout } from '../dashboard/widgets/MyProfile/myProfileSlice'
import { handleLogout } from '../dashboard/dashboardSlice'

function IdleTimer({ openAlert, dispatch, userProfile }) {
  const classes = idleTimerStyles()
  const handleLogOut = () => {
    if (userProfile.proxyNetId) {
      dispatch(handleProxyLogout())
    } else {
      dispatch(handleLogout())
    }
  }
  return (
    <Dialog
      open={openAlert}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Session Timeout</DialogTitle>
      <DialogContent>
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            margin: 10,
          }}
        >
          <img
            className={classes.sessionTimeoutImage}
            src={`${process.env.REACT_APP_IMAGES_URL}/assets/images/hero-image.jpeg`}
            alt="idleTimerImage"
          />
        </div>
        <DialogContentText id="alert-dialog-description">
          Due to browser inactivity your session has timed out and you must
          login again to access your data, We apologize for any inconvience, but
          session timeouts were added for security reasons to ensure your data
          is protected.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleLogOut}>Log Out</Button>
      </DialogActions>
    </Dialog>
  )
}

export default IdleTimer
