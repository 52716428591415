import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Typography from '@material-ui/core/Typography'
import InputLabel from '@material-ui/core/InputLabel'
import Grid from '@material-ui/core/Grid'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import SearchIcon from '@material-ui/icons/Search'

import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import SearchBar from '../../DashboardComponents/SearchBar/SearchBar'
import IconPicker from '../../../apps/AppComponents/IconPicker/IconPicker'
import Loading from '../helpers/Loading'
import Widget from '../Widget'

import styles from './TechGuideStyles'
import {
  fetchTechGuide,
  selectTechGuidData,
  selectTechGuidStatus,
} from './TechGuideSlice'

import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'

// eslint-disable-next-line no-unused-vars
function TechGuide({ className, title, widgetId }) {
  const classes = styles()
  const dispatch = useDispatch()
  const widgetData = useSelector(selectTechGuidData)
  const widgetStatus = useSelector(selectTechGuidStatus)
  const { userProfile } = useSelector((s) => s.myProfile)

  React.useEffect(() => {
    dispatch(fetchTechGuide())
  }, [])

  return (
    <Widget className={className}>
      <Widget.Header title={title} category="default" />
      <Widget.Content className={classes.container}>
        {renderWidgetState(widgetStatus, widgetData, classes, userProfile)}
      </Widget.Content>
    </Widget>
  )
}

function renderWidgetState(widgetStatus, widgetData, classes, userProfile) {
  switch (widgetStatus) {
    case 'fulfilled':
      return (
        <Loaded
          widgetData={widgetData}
          classes={classes}
          userProfile={userProfile}
        />
      )
    case 'pending':
      return <Loading />
    case 'error':
      return <ErrorMessage />
    default:
      return <ErrorMessage />
  }
}

function Loaded({ widgetData, classes, userProfile }) {
  const campus = [
    {
      label: 'New Brunswick',
      value: 'newBrunswick',
    },
    {
      label: 'Newark',
      value: 'newark',
    },
    {
      label: 'Camden',
      value: 'camden',
    },
    {
      label: 'RBHS',
      value: 'rbhs',
    },
  ]

  const userRolesArr = [
    {
      label: 'Student',
      value: 'ruStudent',
    },
    {
      label: 'Faculty',
      value: 'ruEmployee',
    },
    {
      label: 'Staff',
      value: 'ruStaff',
    },
  ]
  const [location, setLocation] = React.useState(campus[0].value)
  const [role, setRole] = React.useState(userRolesArr[0].label.toLowerCase())
  React.useEffect(() => {
    const defaultLocation = userProfile.location
    if (defaultLocation) {
      const filteredCampusMenu = campus.filter(
        (place) => place.label === defaultLocation,
      )
      if (filteredCampusMenu.length > 0) {
        setLocation(filteredCampusMenu[0].value)
      }
    }
    if (userProfile.roles && userProfile.roles.length > 0) {
      if (userProfile.roles.includes('ruStudent')) {
        setRole(userRolesArr[0].label.toLowerCase())
      } else if (userProfile.roles.includes('rbhsStudent')) {
        setRole(userRolesArr[0].label.toLowerCase())
      } else if (userProfile.roles.includes('ruEmployee')) {
        setRole(userRolesArr[1].label.toLowerCase())
      } else if (userProfile.roles.includes('ruStaff')) {
        setRole(userRolesArr[2].label.toLowerCase())
      } else if (userProfile.roles.includes('employee')) {
        setRole(userRolesArr[1].label.toLowerCase())
      } else {
        setRole(userRolesArr[0].label.toLowerCase())
      }
    }
  }, [userProfile.location, userProfile.roles])

  const [filter, setFilter] = useState('')
  const [filteredWidgetData, setData] = useState(widgetData[role][location])
  const handleLocationChange = (event) => {
    setLocation(event.target.value)
  }
  const handleRoleChange = (event) => {
    setRole(event.target.value)
  }
  const onSearchChange = (newVal) => {
    setFilter(newVal)
  }

  const cancelSearch = () => {
    setFilter('')
  }

  const handleSearch = () => {}

  React.useEffect(() => {
    if (filter && filter !== '') {
      setData(
        widgetData[role][location].filter((helpItem) =>
          helpItem.title.toLowerCase().includes(filter.toLowerCase()),
        ),
      )
    } else {
      setData(widgetData[role][location])
    }
  }, [filter])

  React.useEffect(() => {
    if (filter && filter !== '') {
      setData(
        widgetData[role][location].filter((helpItem) =>
          helpItem.title.toLowerCase().includes(filter.toLowerCase()),
        ),
      )
    } else {
      setData(widgetData[role][location])
    }
  }, [role, location])

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      className={classes.gridContainer}
    >
      <Grid
        item
        container
        direction="column"
        className={classes.inputContainer}
      >
        <Grid item>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="center"
            className={classes.selectorGrid}
          >
            <Typography>I am</Typography>
            <InputLabel id="role-select" className={classes.formControl}>
              <Select
                labelId="role-select"
                value={role}
                className={classes.select}
                onChange={handleRoleChange}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
              >
                {userRolesArr.map((option) => (
                  <MenuItem key={option} value={option.label.toLowerCase()}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </InputLabel>
            <Typography> at </Typography>
            <InputLabel id="location-select" className={classes.formControl}>
              <Select
                labelId="location-select"
                value={location}
                className={classes.select}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
                onChange={handleLocationChange}
              >
                {campus.map((option) => (
                  <MenuItem key={option} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </InputLabel>
          </Grid>
        </Grid>
        <Grid item className={classes.searchBar}>
          <SearchBar
            placeholder="Search technology guides"
            className={classes.searchBar}
            value={filter}
            searchIcon={<SearchIcon className={classes.searchIcon} />}
            onChange={(event) => onSearchChange(event.target.value)}
            onCancelSearch={() => cancelSearch()}
            onRequestSearch={() => handleSearch()}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="column"
        wrap="nowrap"
        className={classes.cards}
      >
        {filteredWidgetData.map((card) => (
          <Accordion
            className={classes.accordion}
            variant="outlined"
            key={card.title + card.info}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid container direction="row" alignItems="baseline" spacing={1}>
                <Grid item>
                  <IconPicker
                    iconName={card.icon}
                    size="lg"
                    color="gray"
                    wrap="nowrap"
                  />
                </Grid>
                <Grid item xs>
                  <Typography variant="subtitle1">{card.title}</Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <Typography variant="body1">
                <span
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: card.info,
                  }}
                />
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </Grid>
  )
}

export default TechGuide
