import React from 'react'
import {
  Typography as Text,
  Grid,
  Card,
  CardContent,
  CardMedia,
  IconButton,
} from '@material-ui/core'
import Rating from '@material-ui/lab/Rating'

import DirectionsIcon from '@material-ui/icons/Directions'
import InfoIcon from '@material-ui/icons/Info'

import { restaurantTileStyles } from '../RestaurantCarousel/restaurantCarouselStyles'

export default function RestaurantTile({ restaurant }) {
  const classes = restaurantTileStyles()
  const openGoogleMaps = () => {
    window.open(
      'https://www.google.com/maps/place/?q=place_id:'.concat(
        restaurant.place_id,
      ),
    )
  }
  return (
    <Card className={classes.restaurantTileContainer}>
      {restaurant.photo && (
        <CardMedia
          component="img"
          className={classes.img}
          image={restaurant.photo}
          title="Contemplative Reptile"
          onError={(e) => {
            e.target.onerror = null
            e.target.src = './assets/images/building-placeholder.png'
          }}
        />
      )}

      <CardContent>
        <div className={classes.header}>
          <Text gutterBottom variant="h5" component="h2">
            {restaurant.name}
          </Text>
        </div>
        <div className={classes.ratingContainer}>
          <div className={classes.ratingItems}>
            <Rating
              name="read-only"
              value={restaurant.rating}
              precision={0.1}
              readOnly
            />
          </div>
          <div className={classes.ratingItems}>
            <Text> ({restaurant.user_ratings_total}) </Text>
          </div>
          <div className={classes.ratingItems}>
            {restaurant.price_level != null && (
              <Text> · {'$'.repeat(restaurant.price_level)} </Text>
            )}
          </div>
        </div>
      </CardContent>

      <Grid container alignItems="center" justifyContent="flex-end">
        <Grid item>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open google maps"
            onClick={openGoogleMaps}
          >
            <DirectionsIcon className={classes.icon} />
          </IconButton>
        </Grid>

        <Grid item>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open google maps"
            onClick={openGoogleMaps}
          >
            <InfoIcon className={classes.infoIcon} />
          </IconButton>
        </Grid>
      </Grid>
    </Card>
  )
}
