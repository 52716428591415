import {
  handleNearestStopClicked,
  handleViewBuildingInfoSheet,
  setSelectedBuilding,
} from '../../../maps/sections/Buildings/redux/myBuildingsSlice'
import {
  fetchNearestStops,
  updateBottomSheet,
} from '../../../maps/sections/Map/redux/myMapsSlice'
import { BUILDING, NEARESTSTOP } from '../../../maps/utils/constants'

export function partition(array, filter) {
  //   console.log('partition array ', array)
  const pass = []
  const fail = []
  array.forEach((e) => (filter(e) ? pass : fail).push(e))
  return [pass, fail]
}

export function getDayOfWeek(targetDay, currentDay, currentDate) {
  // current dates day of the week Sun - Sat : 0 - 6
  //   console.log('target day check ', targetDay)
  const currentDayOfWeek = currentDate.getDay()
  switch (currentDayOfWeek) {
    // Sunday
    case 0:
      if (targetDay === currentDayOfWeek) {
        return currentDay
      }
      if (targetDay === 1) {
        return Number(currentDay) + 1
      }
      if (targetDay === 2) {
        return Number(currentDay) + 2
      }
      if (targetDay === 3) {
        return Number(currentDay) + 3
      }
      if (targetDay === 4) {
        return Number(currentDay) + 4
      }
      if (targetDay === 5) {
        return Number(currentDay) + 5
      }
      return Number(currentDay) + 6
    // Monday
    case 1:
      if (targetDay === 0) {
        return Number(currentDay) - 1
      }
      if (targetDay === currentDayOfWeek) {
        return currentDay
      }
      if (targetDay === 2) {
        return Number(currentDay) + 1
      }
      if (targetDay === 3) {
        return Number(currentDay) + 2
      }
      if (targetDay === 4) {
        return Number(currentDay) + 3
      }
      if (targetDay === 5) {
        return Number(currentDay) + 3
      }
      return Number(currentDay) + 3
    // Tuesday
    case 2:
      if (targetDay === 0) {
        return Number(currentDay) - 2
      }
      if (targetDay === 1) {
        return Number(currentDay) - 1
      }
      if (targetDay === currentDayOfWeek) {
        return currentDay
      }
      if (targetDay === 3) {
        return Number(currentDay) + 1
      }
      if (targetDay === 4) {
        return Number(currentDay) + 2
      }
      if (targetDay === 5) {
        return Number(currentDay) + 3
      }
      if (targetDay === 6) {
        return Number(currentDay) + 4
      }
      return Number(currentDay) + 5
    // Wednesday
    case 3:
      if (targetDay === 0) {
        return Number(currentDay) - 3
      }
      if (targetDay === 1) {
        return Number(currentDay) - 2
      }
      if (targetDay === 2) {
        return Number(currentDay) - 1
      }
      if (targetDay === currentDayOfWeek) {
        return currentDay
      }
      if (targetDay === 4) {
        return Number(currentDay) + 1
      }
      if (targetDay === 5) {
        return Number(currentDay) + 2
      }
      if (targetDay === 6) {
        return Number(currentDay) + 3
      }
      return currentDay
    // Thursday
    case 4:
      if (targetDay === 0) {
        return Number(currentDay) - 4
      }
      if (targetDay === 1) {
        return Number(currentDay) - 3
      }
      if (targetDay === 2) {
        return Number(currentDay) - 2
      }
      if (targetDay === 3) {
        return Number(currentDay) - 1
      }
      if (targetDay === currentDayOfWeek) {
        return currentDay
      }
      if (targetDay === 5) {
        return Number(currentDay) + 1
      }
      if (targetDay === 6) {
        return Number(currentDay) + 2
      }
      return currentDay
    // Friday
    case 5:
      if (targetDay === 0) {
        return Number(currentDay) - 5
      }
      if (targetDay === 1) {
        return Number(currentDay) - 4
      }
      if (targetDay === 2) {
        return Number(currentDay) - 4
      }
      if (targetDay === 3) {
        return Number(currentDay) - 2
      }
      if (targetDay === 4) {
        return Number(currentDay) - 1
      }
      if (targetDay === currentDayOfWeek) {
        return currentDay
      }
      if (targetDay === 6) {
        return Number(currentDay) + 1
      }
      return currentDay
    // Saturday
    case 6:
      if (targetDay === 0) {
        return Number(currentDay) - 6
      }
      if (targetDay === 1) {
        return Number(currentDay) - 5
      }
      if (targetDay === 2) {
        return Number(currentDay) - 4
      }
      if (targetDay === 3) {
        return Number(currentDay) - 3
      }
      if (targetDay === 4) {
        return Number(currentDay) - 2
      }
      if (targetDay === 5) {
        return Number(currentDay) - 1
      }
      if (targetDay === currentDayOfWeek) {
        return currentDay
      }
      return currentDay
    default:
      return ''
  }
}

// static bookStore IDs can possibly be stored in the endpoint
export const bookStores = {
  NB: 58552,
  NK: 30058,
  NW: 30058,
  CM: 65132,
  CAM: 65132,
}
const termCode = (code, year) => {
  const termcodes = {
    0: 'I',
    1: 'W',
    7: 'A',
    9: 'F',
  }
  // let termNumber = code.toString()
  let termNumber = termcodes[code.toString()]
  const yearString = year.toString()
  termNumber += yearString.slice(yearString.length - 2)
  return termNumber
}
const getCourseNo = (courseString) => {
  const courseStringArray = courseString.split(':')
  return courseStringArray.pop()
}
export const buildLink = (course, section, campus, storeId, currentTerm) => {
  // use Data's regCd
  const link = []
  link.push(
    'https://securex.bncollege.com/webapp/wcs/stores/servlet/TBListView?catalogId=10001&storeId=',
  )
  // code based on campus
  // needs to be updated to the students location based off the profile service!!!!
  link.push(storeId || 58552)
  link.push('&courseXml=<textbookorder>')
  if (campus === 'CAM') link.push("<campus name='RUTGERS'>")
  link.push("<courses><course num='")
  link.push(getCourseNo(course.courseNumber))
  link.push("' dept='")
  link.push(course.subject)
  link.push("' sect='")
  link.push(section.number)
  link.push("' term='")
  link.push(
    termCode(
      currentTerm ? currentTerm.code : 0,
      currentTerm ? currentTerm.year : 0,
    ),
  )
  link.push("'></course></courses>")
  if (campus === 'CAM') link.push('</campus>')
  link.push('</textbookorder>')
  return encodeURI(link.join(''))
}

const fetchNoOp = () => () => Promise.resolve()

export const handleShowBottomSheetBuilding = (dispatch, bid, buildings) => {
  if (buildings && buildings.length > 0) {
    const building = buildings.find((b) => b.bid === bid)
    if (building) {
      dispatch(setSelectedBuilding(building))
      dispatch(fetchNoOp())
        .then(() => {
          dispatch(handleViewBuildingInfoSheet(building.bid))
          dispatch(updateBottomSheet(BUILDING))
        })
        .catch((error) => {
          console.error('An error occurred:', error)
        })
      return true
    }
  }
  return false
}

export const handleShowBottomSheetNearestStop = (dispatch, bid, buildings) => {
  if (buildings && buildings.length > 0) {
    const building = buildings.find((b) => b.bid === bid)
    if (building) {
      dispatch(setSelectedBuilding(building))

      // Fetch the nearest stops and then chain the next dispatches
      dispatch(fetchNearestStops(bid))
        .then(() => {
          // Once fetching is complete, dispatch the next action
          dispatch(handleNearestStopClicked(bid))
        })
        .then(() => {
          // After handling the nearest stop clicked, update the bottom sheet
          dispatch(updateBottomSheet(NEARESTSTOP))
        })
        .catch((error) => {
          console.error('An error occurred:', error)
        })
      return true
    }
  }
  return false
}
