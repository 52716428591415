import * as React from 'react'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import WebIcon from '@material-ui/icons/Web'

import Widget from '../../Widget'

// eslint-disable-next-line no-unused-vars
function WidgetNotFound({ className, title, widgetId }) {
  return (
    <Widget className={className}>
      <Widget.Header title={title} category="default" />
      <Widget.Content>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ height: '100%', marginTop: -30 }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <WebIcon style={{ fontSize: 100 }} />
            <Typography variant="body1" align="center" style={{ width: 300 }}>
              This widget is still in development. Please check back again
              later.
            </Typography>
          </Box>
        </Grid>
      </Widget.Content>
    </Widget>
  )
}

export default WidgetNotFound
