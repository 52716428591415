import { makeStyles } from '@material-ui/core/styles'

// Any time that we would like to add CSS to our Components we will create a styles object. The syntax is different then normal CSS. Please notice that camel case is used to set the list-style-type. For your reference please visit https://v4.mui.com
export const universityMessagesStyles = makeStyles(() => ({
  gridContainer: {
    padding: 0,
  },
  categoriesList: {
    listStyleType: 'none',
    padding: '0px',
  },
  universityMessageImage: {
    width: 80,
    height: 80,
    borderRadius: 8,
    objectFit: 'cover',
  },
  universityMessageCardBackground: {
    height: 'auto',
    padding: 10,
    paddingLeft: 15,
    paddingRight: 15,
    marginBottom: 15,
    marginTop: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '95%',
  },
  universityMessageContent: {
    display: 'flex',
  },
  universityMessagesLink: {
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 5,
  },
  universityMessageDate: {
    marginLeft: 10,
    color: '#9B9C9F',
  },
}))

export default universityMessagesStyles
