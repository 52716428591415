import React from 'react'

import { Card, Typography, Grid, Box, ButtonBase } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import Restore from '@material-ui/icons/Restore'
import { useDispatch } from 'react-redux'

import styles from '../settingsStyles'

import { deleteTutorials, restoreDashboardWidgets } from '../settingsSlice'
import PageLink from '../components/PageLink'

function AppInfo() {
  const classes = styles()
  const history = useHistory()
  const dispatch = useDispatch()

  const resetTutorials = () => {
    dispatch(deleteTutorials())
  }

  const handleRestoreDashboardWidgets = () => {
    dispatch(restoreDashboardWidgets())
  }

  return (
    <Box className={classes.centerPage}>
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.container}
        wrap="nowrap"
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.sectionTitle}
        >
          <ButtonBase
            onClick={() => history.replace('/settings')}
            aria-label="Back"
          >
            <ArrowBackIos />
          </ButtonBase>
          <Typography variant="h1">
            <b>Dashboard Settings</b>
          </Typography>
        </Grid>
        <Grid item className={classes.fullWidth}>
          <Card className={classes.linksCard}>
            <PageLink
              title="Reset Tutorials"
              description="All tutorials will show again"
              link={resetTutorials}
              icon={<Restore />}
            />
          </Card>
        </Grid>
        <Grid item className={classes.fullWidth}>
          <Card className={classes.linksCard}>
            <PageLink
              title="Restore Widgets"
              description="Restore dashboard widgets to default"
              link={handleRestoreDashboardWidgets}
              icon={<Restore />}
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AppInfo
