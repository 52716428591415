import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slide,
  Typography,
} from '@material-ui/core'
import ReceiptIcon from '@material-ui/icons/Receipt'

import Item from './NewsSourceItem/SortableItem'
import useNewsSectionReorderStyles from './ReorderNewsSourceStyles'
import {
  setOpenNewsSourcesSubscriptionDialogOpen,
  updateUnsubscribedSources,
} from '../../newsSlice'
import { setUnsubscribedNewsSource } from '../../helpers'

function HideNewsSource() {
  const dispatch = useDispatch()
  const classes = useNewsSectionReorderStyles()
  const openDialog = useSelector(
    (s) => s.news.newsSourcesSubscriptionDialogOpen,
  )

  const setOpen = () => {
    if (openDialog) {
      dispatch(setOpenNewsSourcesSubscriptionDialogOpen(false))
    } else {
      dispatch(setOpenNewsSourcesSubscriptionDialogOpen(true))
    }
  }

  return (
    <>
      <ListItem button onClick={() => setOpen()}>
        <ListItemIcon>
          <ReceiptIcon className={classes.listIconStyle} />
        </ListItemIcon>
        <ListItemText>News Source Subscriptions</ListItemText>
      </ListItem>
      <NewsSubscriptionDialog
        openDialog={openDialog}
        setOpen={setOpen}
        dispatch={dispatch}
        classes={classes}
      />
    </>
  )
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" {...props} ref={ref} />
})

function NewsSubscriptionDialog({ openDialog, setOpen, dispatch, classes }) {
  const { sources, unsubscribedSources } = useSelector((s) => s.news)

  const [newsSourcesWithData, setNewsSourcesWithData] = React.useState([])
  const [edited, setEdited] = React.useState(false)
  const [unsubscribedList, setUnsubscribedList] = React.useState([])

  React.useEffect(() => {
    setUnsubscribedList(unsubscribedSources)
  }, [unsubscribedSources])

  React.useEffect(() => {
    if (sources) {
      const sourcesCopy = [...sources]
      // Find the index of the source with name "Rutgers Today" in the filteredSources array
      const index = sourcesCopy.findIndex(
        (source) => source.name === 'Rutgers Today',
      )

      // Move the source with name "Rutgers Today" to the end of the filteredSources array
      if (index !== -1) {
        const rutgersToday = sourcesCopy.splice(index, 1)
        sourcesCopy.push(rutgersToday[0])
      }

      // Set the filtered and sorted sources as the state variable
      setNewsSourcesWithData(sourcesCopy)
    }
  }, [sources])

  const updateList = () => {
    setUnsubscribedNewsSource(unsubscribedList)
    dispatch(updateUnsubscribedSources(unsubscribedList))
    setEdited(false)
    setOpen()
  }

  return (
    <Dialog
      open={openDialog}
      onClose={() => setOpen()}
      TransitionComponent={Transition}
    >
      <DialogTitle>
        <Typography variant="h6" fontWeight="bold">
          Subscribe/Unsubscribe From News Sources
        </Typography>
      </DialogTitle>

      <DialogContent dividers className={classes.dialog}>
        <div className={classes.centeredContainer}>
          {newsSourcesWithData.map((source) => (
            <Item
              source={source}
              setEdited={setEdited}
              unsubscribedList={unsubscribedList}
              setUnsubscribedList={setUnsubscribedList}
            />
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Grid container directin="row" justifyContent="space-between">
          <Button onClick={setOpen} variant="outlined" color="primary">
            Cancel
          </Button>

          <Button
            onClick={() => updateList()}
            variant="outlined"
            disabled={!edited}
            color="primary"
          >
            Set
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default HideNewsSource
