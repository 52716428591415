/* eslint-disable */
import React from 'react'
import { Box, Typography, Grid } from '@material-ui/core'
import { useSelector } from 'react-redux'
import ArticleLayoutWithoutImage from '../ArticleLayout/helpers/ArticleLayoutWithoutImage'

// SearchResultList component
const SearchResultList = ({ classes }) => {
  // Use useSelector to get the relevant slice of the Redux state
  const { search } = useSelector((state) => state.news)

  // Use a ternary operator to decide which component to render
  return search.articleList.length > 0 || search.loading ? (
    <SearchResultsList
      classes={classes}
      articles={search.articleList}
      isLoading={search.loading}
    />
  ) : (
    <NoMatchesAvailable classes={classes} />
  )
}
// This component doesn't use ArticleLayout because of the conditional that wraps ArticleLayoutWithoutImage in it
// articlesWithoutImage.length > 0 prevents the component from rendering which gets rid of the loading look
const SearchResultsList = ({ classes, articles, isLoading }) => (
  <Box className={classes.pageContainer}>
    <ArticleLayoutWithoutImage articles={articles} isLoading={isLoading} />
  </Box>
)

const NoMatchesAvailable = ({ classes }) => (
  <Grid
    container
    direction="column"
    justifyContent="space-between"
    alignItems="center"
  >
    <Grid item>
      <Typography className={classes.noMatches}>
        No Matches Available
      </Typography>
    </Grid>
  </Grid>
)

export default SearchResultList
