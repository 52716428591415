/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchStudentServiceCenterCases = createAsyncThunk(
  'fetchStudentServiceCenterCases',
  async () => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const rcpid = localStorage.getItem('rcpid')
    const res = await axios.get(
      `${process.env.REACT_APP_STUDENTSERVICECENTER_CASES_ENDPOINT}`,
      {
        headers: {
          JWT: jwtToken,
          XWT: rcpid,
        },
      },
    )
    const openCases = []
    const closedCases = []
    res.data.records.forEach((record) => {
      if (
        record.Status === 'New' ||
        record.Status === 'Open' ||
        record.Status === 'In-Progress' ||
        record.Status === 'Escalated' ||
        record.Status === 'On Hold'
      ) {
        openCases.push(record)
      } else if (record.Status === 'Closed') {
        closedCases.push(record)
      }
    })
    return {
      myOpenCases: openCases,
      myClosedCases: closedCases,
    }
  },
)

const initialState = {
  caseStatus: '',
  myOpenCases: [],
  myClosedCases: [],
  value: 0,
  value2: 0,
  query: '',
}

const studentServiceCenterSlice = createSlice({
  name: 'studentServiceCenter',
  initialState,
  reducers: {
    updateTabValue: (state, action) => {
      if (state.value !== action.payload) {
        state.value = action.payload
      }
    },
    updateTabValue2: (state, action) => {
      if (state.value2 !== action.payload) {
        state.value2 = action.payload
        // state.caseStatus = 'pending'
      }
      return state
    },
    updateQuery: (state, action) => {
      state.query = action.payload.target.value
    },
  },
  extraReducers: {
    [fetchStudentServiceCenterCases.pending]: (state) => ({
      ...state,
      caseStatus: 'pending',
    }),
    [fetchStudentServiceCenterCases.fulfilled]: (state, action) => {
      state.caseStatus = 'fulfilled'
      state.myOpenCases = action.payload.myOpenCases
      state.myClosedCases = action.payload.myClosedCases
    },
    [fetchStudentServiceCenterCases.rejected]: (state) => ({
      ...state,
      caseStatus: 'error',
    }),
  },
})

export const { updateTabValue, updateTabValue2, updateQuery } =
  studentServiceCenterSlice.actions

export default studentServiceCenterSlice.reducer
