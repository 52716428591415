/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchFinancialAid = createAsyncThunk(
  'fetchFinancialAid',
  async ({ netId = '', awardYear = '' }) => {
    // dropdownYear + 1
    const res = await axios.get(process.env.REACT_APP_FINANCIALAID_ENDPOINT, {
      params: {
        netId,
        awardYear,
      },
      headers: {
        JWT: localStorage.getItem('myrJwtToken'),
        XWT: localStorage.getItem('rcpid'),
      },
    })
    return res.data
  },
)

export const fetchFinancialAidAwards = createAsyncThunk(
  'fetchFinancialAidAwards',
  async ({ netId, programCode, description }) => {
    const res = await axios.get(
      process.env.REACT_APP_FINANCIALAID_ENDPOINT_AWARDS,
      {
        params: {
          netId,
          programCode,
        },
        headers: {
          JWT: localStorage.getItem('myrJwtToken'),
          XWT: localStorage.getItem('rcpid'),
        },
      },
    )
    return { awardData: res.data, description }
  },
)

const initialState = {
  status: 'idle',
  tabValue: 0,
  data: {
    title: 'My Financial Aid',
    applyTab: {
      links: [
        {
          title: 'Cost of Attendance',
          emphasis: 'Cost',
          url: 'https://financialaid.rutgers.edu/cost-of-attendance/',
          icon: 'dollar-sign',
        },
        {
          title: 'Apply for Financial Aid',
          emphasis: 'Apply',
          url: 'https://financialaid.rutgers.edu/apply/',
          icon: 'dollar-sign',
        },
        {
          title: 'Scholarship Information',
          emphasis: 'Scholarships',
          url: 'https://financialaid.rutgers.edu/types-of-aid/scholarships/',
          icon: 'graduation-cap',
        },
        {
          title: 'Grant Information',
          emphasis: 'Grants',
          url: 'https://financialaid.rutgers.edu/types-of-aid/grants/',
          icon: 'info-circle',
        },
        {
          title: 'Loan Information',
          emphasis: 'Loans',
          url: 'https://financialaid.rutgers.edu/types-of-aid/loans/',
          icon: 'credit-card',
        },
        {
          title: 'Employment',
          emphasis: 'Employment',
          url: 'https://financialaid.rutgers.edu/student-work/',
          icon: 'briefcase',
        },
        {
          title: 'SAP Policy',
          emphasis: 'SAP',
          url: 'https://financialaid.rutgers.edu/eligibility/academic-progress/',
          icon: 'chart-line',
        },
        {
          title: 'Withdrawal Policy',
          emphasis: 'Withdrawal',
          url: 'https://financialaid.rutgers.edu/eligibility/enrollment-requirements/withdrawing-from-all-courses/',
          icon: 'sign-out-alt',
        },
        {
          title: 'Exit Counseling',
          emphasis: 'Exit',
          url: 'https://financialaid.rutgers.edu/types-of-aid/loans/exit-counseling/',
          icon: 'sign-out-alt',
        },
      ],
    },
    awardTab: {
      awardData: [
        {
          url: 'https://sfp.ocs.oc-test.com/rutgers-test/portal/login-form',
          year: '2022',
          netid: 'DEMO003',
          totalAmt: '10000',
          description: 'New Brunswick - Test Program',
        },
        {
          url: 'https://sfp.ocs.oc-test.com/rutgers-test/portal/login-form',
          year: '2023',
          netid: 'DEMO003',
          totalAmt: '5000',
          description: 'Newark - Test Program',
        },
        {
          url: 'https://sfp.ocs.oc-test.com/rutgers-test/portal/login-form',
          year: '2024',
          netid: 'DEMO003',
          totalAmt: '10000',
          description: 'Camden - Test Program',
        },
      ],
      formattedAwards: [],
      sirsRejectedPrompt:
        'ISIR - Correction to Your FAFSA is Required\n\nYour FAFSA was rejected by the federal processor. Correct your Student Aid Report (SAR) immediately at https://www.studentaid.gov. Corrections made online can take seven (7) business days to update; corrections made by mail may take up to four (4) weeks. For questions, visit https://www.studentaid.contact',
    },
    docsTab: {
      docs: [],
      links: [
        {
          title: 'FAFSA Status',
          url: 'https://studentaid.gov/fsa-id/sign-in/landing',
          icon: 'check-circle',
        },
        {
          title: 'NJ Grants Status',
          url: 'http://www.hesaa.org/Pages/NJGrantsHome.aspx',
          icon: 'check-circle',
        },
        {
          title: 'Loan Counseling',
          url: 'https://studentloans.gov/myDirectLoan/counselingInstructions.action',
          icon: 'question-circle',
        },
      ],
    },
  },
}

const financialAidSlice = createSlice({
  name: 'financialAid',
  initialState,
  reducers: {
    handleUpdateYear: (state, action) => {
      state.year = action.payload
    },
    handleUpdateTab: (state, action) => {
      state.tabValue = action.payload
    },
  },
  extraReducers: {
    [fetchFinancialAid.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchFinancialAid.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchFinancialAid.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.data.docsTab.docs = action.payload?.docs?.docs
      state.data = { ...state.data, ...action.payload }
      // years dropdown data [currentYear - 1, currentYear, currentYear+1, (any other data from API)]
      const yearsSet = new Set()
      const currentYear = new Date().getFullYear()
      yearsSet.add((currentYear - 1).toString())
      yearsSet.add(currentYear.toString())
      yearsSet.add((currentYear + 1).toString())
      const years = Array.from(yearsSet)
      state.years = years
      // make current dropdown year the first year theres financial aid award data
      state.year = currentYear
    },
    [fetchFinancialAidAwards.rejected]: (state) => {
      state.awardsStatus = 'error'
    },
    [fetchFinancialAidAwards.pending]: (state) => {
      state.awardsStatus = 'pending'
    },
    [fetchFinancialAidAwards.fulfilled]: (state, action) => {
      state.awardsStatus = 'fulfilled'
      //   let sum = 0
      //   Object.values(action.payload.awardData.awards.programs).forEach((p) => {
      //     sum += p.totalAmt
      //   })
      //   state.data.awardTab.awardData.push({
      //     url: action.payload.awardData.awards.year,
      //     year: action.payload.awardData.awards.year,
      //     netid: action.payload.awardData.awards.netid,
      //     totalAmt: sum,
      //     description: action.payload.description,
      //   })
      // formatAwardInformation object
      const formattedAwardAmountObjs =
        action.payload?.awardData?.awards?.programs
          ?.map((awardItem) =>
            awardItem.years.map((awardYearItem) => {
              const obj = {
                ...awardYearItem,
                url: action.payload.awardData.awards.url,
              }
              return obj
            }),
          )
          .flat() || []
      // add formatAwardInformation for each EnrollmentPrograms -> EnrollmentProgram -> Program
      const formattedAwards = formattedAwardAmountObjs
        .map((awardItems) => {
          const awardData =
            state.data.enrollment?.EnrollmentPrograms?.EnrollmentProgram?.map(
              (enrollmentItem) => {
                if (
                  enrollmentItem?.Program?.ProgramCode ===
                  awardItems.programName
                ) {
                  return {
                    ...awardItems,
                    ...enrollmentItem?.Program,
                  }
                }
                return null
              },
            )
          return awardData
        })
        .flat()
        .filter((item) => item !== null)

      //   add formatted awards change
      // Assuming state.data and state.data.awardTab are defined objects
      const uniqueFormattedAwards = new Set()

      // Add existing formattedAwards from state.data.awardTab to the set
      if (state.data?.awardTab?.formattedAwards) {
        state.data.awardTab.formattedAwards.forEach((award) => {
          uniqueFormattedAwards.add(JSON.stringify(award))
        })
      }

      // Add new formattedAwards to the set
      formattedAwards.forEach((award) => {
        uniqueFormattedAwards.add(JSON.stringify(award))
      })

      // Convert the Set back to an array of objects
      const mergedFormattedAwards = Array.from(uniqueFormattedAwards).map(
        (str) => JSON.parse(str),
      )
      state.data.awardTab.formattedAwards =
        mergedFormattedAwards?.length > 0 ? mergedFormattedAwards : []

      // years dropdown data [currentYear - 1, currentYear, currentYear+1, (any other data from API)]
      const yearsSet = new Set()
      const currentDate = new Date()
      const currentYear = currentDate.getFullYear()
      yearsSet.add((currentYear - 1).toString())
      yearsSet.add(currentYear.toString())
      yearsSet.add((currentYear + 1).toString())
      formattedAwards?.forEach((data) => {
        // REQUEST 4/16/24 REMOVE 2025
        // if (data.year !== '2025') {
        yearsSet.add(data.year)
        // }
      })
      const years = Array.from(yearsSet).sort((a, b) => b - a)
      state.years = years
      // make current dropdown year the current year if the month isn't July otherwise it should be the next year
      if (currentDate.getMonth() + 1 <= 5) {
        const yearIndex = years.findIndex(
          (year) => Number(year) === currentYear,
        )
        state.year = years[yearIndex]
      } else {
        const yearIndex = years.findIndex(
          (year) => Number(year) === currentYear + 1,
        )
        state.year = years[yearIndex]
      }
      //   state.year =
      //     formattedAwards.length > 0 ? formattedAwards[0]?.year : currentYear
      // set Award Tab as default
      if (formattedAwards?.length > 0) {
        // if a user doesn't have award data for the next year default to current year
        const doesUserHaveAwardData = formattedAwards?.findIndex(
          (formattedAwardYear) => formattedAwardYear?.year === state?.year,
        )
        if (doesUserHaveAwardData <= -1) {
          state.year = currentYear?.toString()
        }
        state.tabValue = 3
      }
    },
  },
})

export const selectFinancialAidData = (state) => state.financialAid.data
export const selectFinancialAidStatus = (state) => state.financialAid.status
export const selectFinancialAidProgramCodes = (state) => {
  let programCodes
  if ('enrollment' in state.financialAid.data) {
    programCodes =
      state.financialAid.data.enrollment.EnrollmentPrograms.EnrollmentProgram[0]
        .Program.ProgramCode
  }
  return programCodes
}

export const { handleUpdateYear, handleUpdateTab } = financialAidSlice.actions

export default financialAidSlice.reducer
