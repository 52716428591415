import axios from 'axios'

const apiClient = axios.create()

apiClient.interceptors.response.use(
  (response) => {
    if (response) {
      return response
    }
    throw new Error('No response received')
  },
  (error) => {
    // Handle errors
    if (error.response && error.response.status) {
      console.error(`Error with status code ${error.response.status}`)
    } else {
      console.error('Network error:', error)
    }

    // You can choose to handle the error here or let the calling function handle it.
    // For this example, we'll reject with a custom error message for clarity.
    return Promise.reject(
      new Error(`Error fetching data: ${error.message || 'Unknown error'}`),
    )
  },
)

export default apiClient
