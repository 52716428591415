import * as React from 'react'

import { ButtonBase, Grid } from '@material-ui/core'

import IconPicker from '../../../features/apps/AppComponents/IconPicker/IconPicker'

export default function SettingsLocalButtons(history) {
  const handleEmergencyContactClick = () => {
    history.push('/settings/emergencyInformation')
  }

  return (
    <>
      <Grid item md={4}>
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          style={{ paddingRight: 10 }}
        >
          <Grid item>
            <ButtonBase
              onClick={() => handleEmergencyContactClick()}
              aria-label="Emergency Information"
            >
              <IconPicker iconName="fa-shield-alt" color="#c41f32" size="3x" />
            </ButtonBase>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
