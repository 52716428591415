import React from 'react'
import { useSelector } from 'react-redux'
import { Box, CircularProgress, Grid } from '@material-ui/core/'
import RestaurantCarousel from '../../../sections/Restaurant/RestaurantCarousel/RestaurantCarousel'

function BottomSheetRestaurant({ setSheetConfig }) {
  const { selectedBuilding, restaurantLoading } = useSelector(
    (s) => s.buildings,
  )

  React.useEffect(() => {
    const sheetConfig = {
      title: `Restaurants Near ${selectedBuilding.name}`,
      sheetColor: '',
      displayBackButton: true,
      displayCloseButton: true,
    }
    setSheetConfig(sheetConfig)
  }, [])

  switch (restaurantLoading) {
    case 'fulfilled':
      return (
        <div>
          <RestaurantCarousel />
        </div>
      )
    case 'pending':
      return (
        <div>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{ width: '100vw' }}
          >
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        </div>
      )
    default:
      return (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          Error
        </Box>
      )
  }
}

export default BottomSheetRestaurant
