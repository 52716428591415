import React from 'react'
import _ from 'lodash'
import uuid from 'react-uuid'
import { List, ListItem, ListItemText } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import useNewsSourceListDropDownStyles from './NewsSourceListStyles'

function NewsSourceList() {
  const classes = useNewsSourceListDropDownStyles()
  const history = useHistory()
  const { newsStatus, sourcesStatus, sources, allArticles } = useSelector(
    (s) => s.news,
  )
  const [listItems, setListItems] = React.useState([])

  React.useEffect(() => {
    if (sourcesStatus === 'fulfilled' && newsStatus === 'fulfilled') {
      createNewsSourceListItems()
    }
  }, [newsStatus, sourcesStatus])

  const selectSource = (source) => {
    history.push({
      pathname: './NewsSourcePage',
      search: `?id=${source.sourceID}&source=${source.name}`,
    })
  }

  const createNewsSourceListItems = () => {
    const filteredSources = sources.filter((source) =>
      allArticles.some((article) => article.sourceName === source.name),
    )

    const items = filteredSources.map((source) => (
      <ListItem
        key={uuid()}
        button
        component="a"
        className={classes.listItemBody}
      >
        <ListItemText
          className={classes.listItemText}
          primary={source.name}
          onClick={() => selectSource(source)}
        />
      </ListItem>
    ))
    setListItems(items)
  }

  return (
    <List component="nav" className={classes.root}>
      {!_.isEmpty(sources) && listItems}
    </List>
  )
}

export default NewsSourceList
