/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import { Typography, IconButton } from '@material-ui/core'
import React from 'react'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
// import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { useDispatch, useSelector } from 'react-redux'
import IconPicker from '../../../apps/AppComponents/IconPicker/IconPicker'
import Widget from '../Widget'
import Loading from '../helpers/Loading'
import {
  selectGradFundData,
  selectGradFundStatus,
  fetchGradFund,
  fetchScheduleAnAdvisingLink,
  selectScheduleAnAdvisingLink,
  selectGradFundURLStatus,
} from './GradFundSlice'
import styles from './GradFundStyles'
// import { storage } from '../../../../../utils/firebase'
import { selectUserData } from '../../../settings/settingsSlice'
import { getImageFromFirebaseWithHook } from '../../../apps/helpers'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'

function MasonGross({
  className,
  title,
  showNote,
  noteMessage,
  notePosition,
  noteBackground,
  noteIcon,
  noteUrl,
  noteFontColor,
}) {
  const dispatch = useDispatch()
  const widgetData = useSelector(selectGradFundData)
  const widgetStatus = useSelector(selectGradFundStatus)
  const userData = useSelector(selectUserData)

  const classes = styles()

  React.useEffect(() => {
    dispatch(fetchGradFund())
  }, [])

  return (
    <Widget className={className}>
      <Widget.Header title={title} category="default" />
      <Widget.Content
        className={classes.container}
        showNote={showNote}
        noteMessage={noteMessage}
        notePosition={notePosition}
        noteBackground={noteBackground}
        noteIcon={noteIcon}
        noteUrl={noteUrl}
        noteFontColor={noteFontColor}
      >
        {renderWidget(widgetStatus, widgetData, classes, userData)}
      </Widget.Content>
    </Widget>
  )
}

function renderWidget(widgetStatus, widgetData, classes, userData) {
  switch (widgetStatus) {
    case 'pending':
      return <Loading />
    case 'error':
      return <ErrorMessage />
    case 'fulfilled':
      return (
        <Loaded widgetData={widgetData} classes={classes} userData={userData} />
      )
    default:
      return <ErrorMessage />
  }
}

function Loaded({ widgetData, classes, userData }) {
  const [image, setImg] = React.useState(null)
  const dispatch = useDispatch()
  const scheduleAdvisigLink = useSelector(selectScheduleAnAdvisingLink)
  const gradFundURLStatus = useSelector(selectGradFundURLStatus)

  React.useEffect(() => {
    if (widgetData && widgetData.imageLocation) {
      getImageFromFirebaseWithHook(widgetData.imageLocation, setImg)
    }
  }, [])

  React.useEffect(() => {
    if (gradFundURLStatus === 'fulfilled') {
      window.open(scheduleAdvisigLink)
    }
  }, [gradFundURLStatus])

  const fetchLink = (title, link) => {
    if (title === 'Schedule an Advising Appointment') {
      dispatch(fetchScheduleAnAdvisingLink(userData.email))
    } else {
      window.open(link)
    }
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.columnGrid}
      spacing={3}
    >
      <Grid item className={classes.image}>
        <img className={classes.image} src={image} alt="gradFund" />
      </Grid>
      <Grid item className={classes.description}>
        <Typography align="center" variant="body1">
          {widgetData.description}
        </Typography>
      </Grid>
      <div className={classes.outerContainer}>
        {widgetData.iconLinks.map((icon, index) => (
          <div key={index}>
            <IconButton
              style={{ backgroundColor: 'transparent' }}
              onClick={() => fetchLink(icon.iconLabel, icon.link)}
            >
              <Grid container direction="column">
                <Grid item>
                  <IconPicker iconName={icon.icon} size="2x" />
                </Grid>
                <Grid item>
                  <Typography className={classes.gridTitleText} variant="body1">
                    {icon.iconLabel}
                  </Typography>
                </Grid>
              </Grid>
            </IconButton>
          </div>
        ))}
      </div>
      {gradFundURLStatus === 'error' ? (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          Something went wrong. Please try again later.
        </Alert>
      ) : null}
    </Grid>
  )
}

export default MasonGross
