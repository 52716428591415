import { makeStyles } from '@material-ui/core'

export const myEmailRUConnectStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    margin: 0,
    height: 475,
    width: 335,
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.breakpoints.down(370)]: {
      width: 287,
      height: 450,
    },
  },
  emailTabContent: {
    padding: 0,
  },
  emailButton: {
    width: '100%',
  },
  tabScroll: {
    overflowY: 'auto',
    height: '430px',
  },
  tabContain: {
    overflowY: 'hidden',
    height: '430px',
  },
  collapse: {
    height: '305px',
    flexShrink: '0',
  },
  eventIconDot: {
    fontSize: '12px',
    transform: 'scale(1) translate(-50%, 50%)',
    color: theme.palette.base.red,
  },
  modalTabs: {
    width: '335px',
    height: '35px',
    minHeight: '35px',
    [theme.breakpoints.down(370)]: {
      width: '300px',
    },
  },
  tabBackground: {
    overflowY: 'hidden',
    width: '100%',
    minHeight: '35px',
    height: '35px',
    // backgroundColor: theme.palette.background.widgetSecondaryBackground,
  },
  tabText: {
    overflowY: 'hidden',
    display: 'flex',
    flex: '1',
    minHeight: 35,
    height: 35,
    minWidth: 30,
    width: 30,
    paddingLeft: 0,
    paddingRight: 0,
    color: theme.palette.base.red,
    backgroundColor: theme.palette.base.lightGray,
  },
  moveCalendar: {
    width: '335px',
    marginTop: '-100px',
    marginBottom: '-30px',
    zIndex: '0',
  },
  eventsHolder: {
    padding: '0px 10px',
  },
  eventScroll: {
    transition: 'all 1s ease',
    zIndex: '1',
  },
  dayView: {
    transition: 'all 1s ease',
    zIndex: '1',
    transform: 'translate(0, 10px)',
  },
  fullWidth: {
    width: '-webkit-fill-available',
  },
  badge: {
    color: theme.palette.base.red,
  },
  icon: {
    color: theme.palette.base.gray,
    fontSize: 40,
    '&:hover': {
      transform: 'scale(1.07)',
    },
  },
  headerText: {
    paddingLeft: '7px',
  },
  monthAndYear: {
    width: 'min-content',
    paddingBottom: '8px !important',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '6px !important',
    },
  },
  bellIcon: {
    color: theme.palette.base.red,
    fontSize: theme.typography.h2.fontSize,
    marginBottom: '-32px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '-28px',
    },
  },
  dayOverlay: {
    color: theme.palette.base.white,
  },
  dayOverlayHolder: {
    width: 'min-content',
    paddingBottom: '18px !important',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '12px !important',
    },
  },
  eventsCard: {
    margin: '5px',
  },
  eventHeader: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  calendarDivider: {
    width: '-webkit-fill-available',
    zIndex: '1',
  },
  eventCard: {
    padding: '5px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

export default myEmailRUConnectStyles
