/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Box } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { useDispatch, useSelector } from 'react-redux'
import Widget from '../Widget'
import { myDiningStyles } from './myDiningStyles'
import {
  TabPanel,
  a11yProps,
} from '../../DashboardComponents/TabPanel/TabPanel'
import NewBrunswickDining from './NewBrunswickDining/NewBrunswickDining'
import NewarkDining from './NewarkDining/NewarkDining'
import CamdenDining from './CamdenDining/CamdenDining'
import { fetchDiningData, updateTabValue } from './myDiningSlice'
import {
  RutgersTab,
  RutgersTabs,
} from '../../DashboardComponents/Tabs/RutgersTabs'
import Loading from '../helpers/Loading'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'

function MyDiningTabs(props) {
  const { tabs } = props
  const classes = myDiningStyles()
  const dispatch = useDispatch()
  const { tabValue } = useSelector((s) => s.myDining)

  const handleChange = (event, newValue) => {
    dispatch(updateTabValue(newValue))
  }

  return (
    <Box className={classes.rootTabBox}>
      <RutgersTabs
        value={tabValue}
        onChange={handleChange}
        aria-label="profile tabs"
        variant="fullWidth"
      >
        {/* Tabs should be props that each widget should create manually, to style and make calls */}
        {tabs.map((tab, index) => (
          <RutgersTab key={index} label={tab} {...a11yProps(index)} />
        ))}
      </RutgersTabs>
    </Box>
  )
}

function MyDining({
  className,
  title,
  widgetId,
  showNote,
  noteMessage,
  notePosition,
  noteBackground,
  noteIcon,
  noteUrl,
  noteFontColor,
}) {
  const dispatch = useDispatch()
  const { data, status } = useSelector((s) => s.myDining)
  React.useEffect(() => {
    dispatch(fetchDiningData())
  }, [])
  return (
    <Widget className={className}>
      <Widget.Header title={title} />
      <Widget.Content
        showNote={showNote}
        noteMessage={noteMessage}
        notePosition={notePosition}
        noteBackground={noteBackground}
        noteIcon={noteIcon}
        noteUrl={noteUrl}
        noteFontColor={noteFontColor}
      >
        {renderMyDiningState(status, data, widgetId, showNote, noteMessage)}
      </Widget.Content>
    </Widget>
  )
}

function renderMyDiningState(status, data, widgetId, showNote, noteMessage) {
  console.log('Status', status)
  switch (status) {
    case 'fulfilled':
      return (
        <Loaded
          widgetData={data}
          widgetId={widgetId}
          showNote={showNote}
          noteMessage={noteMessage}
        />
      )
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage />
        </Grid>
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item></Grid>
        </Grid>
      )
  }
}

// eslint-disable-next-line no-unused-vars
function Loaded({ widgetData, widgetId, showNote, noteMessage }) {
  const diningLocations = ['New Brunswick', 'Newark', 'Camden']
  const { tabValue } = useSelector((s) => s.myDining)
  return (
    <>
      <Box width="335px" height="35px">
        <MyDiningTabs tabs={diningLocations} />
      </Box>

      <TabPanel value={tabValue} index={0}>
        <NewBrunswickDining
          widgetData={widgetData}
          widgetId={widgetId}
          showNotes={showNote === true && noteMessage !== ''}
        />
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <NewarkDining
          widgetData={widgetData}
          widgetId={widgetId}
          showNotes={showNote === true && noteMessage !== ''}
        />
      </TabPanel>

      <TabPanel value={tabValue} index={2}>
        <CamdenDining
          widgetData={widgetData}
          widgetId={widgetId}
          showNotes={showNote === true && noteMessage !== ''}
        />
      </TabPanel>
    </>
  )
}

export default MyDining
