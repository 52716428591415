import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import * as React from 'react'

function Loading() {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      flex={1}
      style={{ height: '100%', marginTop: -50 }}
    >
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  )
}

export default Loading
