import { makeStyles, createStyles } from '@material-ui/styles'

export const ErrorMessageStyles = makeStyles(() =>
  createStyles({
    // ErrorMsg.js
    errorMsgContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '8vh',
      alignItems: 'center',
      margin: '0 auto',
    },
    image: {
      width: '30%',
      padding: '0 !important',
      margin: 15,
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }),
)

export default ErrorMessageStyles
