/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchGradFund = createAsyncThunk('fetchGradFund', async () => {
  const jwtToken = localStorage.getItem('myrJwtToken')
  const rcpid = localStorage.getItem('rcpid')
  const res = await axios.get(process.env.REACT_APP_GRADFUND_ENDPOINT, {
    headers: {
      JWT: jwtToken,
      XWT: rcpid,
    },
  })
  return res.data
})

export const fetchScheduleAnAdvisingLink = createAsyncThunk(
  'fetchScheduleAnAdvisingLink',
  async (email) => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const res = await axios.get(
      `${process.env.REACT_APP_GRADFUND_SCHEDULE_ENDPOINT}?email=${email}`,
      {
        headers: {
          token: jwtToken,
        },
      },
    )
    return res.data
  },
)

const initialState = {
  status: 'idle',
  data: {
    title: 'GradFund',
  },
  gradFundURLstatus: 'idle',
  link: null,
}

const gradFundSlice = createSlice({
  name: 'gradFund',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchGradFund.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchGradFund.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchGradFund.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.data = action.payload
    },
    [fetchScheduleAnAdvisingLink.rejected]: (state) => {
      state.gradFundURLstatus = 'error'
    },
    [fetchScheduleAnAdvisingLink.pending]: (state) => {
      state.gradFundURLstatus = 'pending'
    },
    [fetchScheduleAnAdvisingLink.fulfilled]: (state, action) => {
      state.gradFundURLstatus = 'fulfilled'
      state.link = action.payload
    },
  },
})

export const selectGradFundData = (state) => state.gradFund.data
export const selectGradFundStatus = (state) => state.gradFund.status
export const selectGradFundURLStatus = (state) =>
  state.gradFund.gradFundURLstatus
export const selectScheduleAnAdvisingLink = (state) => state.gradFund.link

export default gradFundSlice.reducer
