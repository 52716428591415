import { makeStyles } from '@material-ui/core/styles'

export const useNearesetStopsMobileStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  appBar: {
    color: 'rgba(0,0,0,0.54)',
  },
  mobileBackButtonIcon: {
    fontSize: 24,
  },
  pageTitle: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 1,
    wordBreak: 'keep-all',
    overflow: 'hidden',
    fontSize: 20,
    fontWeight: '400',
    textOverflow: 'ellipsis',
    color: '#ffffff',
    [theme.breakpoints.down(500)]: {
      width: 270,
    },
  },
  appBarIcon: {
    fontSize: 24,
  },
}))

export default useNearesetStopsMobileStyles
