export const serverDataParams = Object.freeze({
  NEW_ITEM: 'IsNew',
  UPDATED_ITEM: 'IsUpdated',
  POLICY_LIBRARY_URL: process.env.REACT_APP_POLICY_LIBRARY_URL,
})

export const uiTextContent = Object.freeze({
  MAIN_BUTTON_TEXT: ' Visit Policy Library',
  ITEM_CARD_BUTTON_TEXT: 'Review',
  SEARCHBAR_PLACHOLDER: 'Search Policy Library',
  NO_DESCRIPTION:
    'For additional information, please contact policy@uec.rutgers.edu',
  NO_ANNOUNCEMENTS: 'Sorry, no policy items to display.',
  NO_ANNOUNCEMENTS2: 'Please check back later.',
  TAB1_LABEL: 'Announcements',
  TAB2_LABEL: 'Policy Items',
})
