import firebase from 'firebase/compat/app'
import 'firebase/compat/analytics'
import 'firebase/compat/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getDatabase } from 'firebase/database'

// import UAParser from 'ua-parser-js'

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}
const firebaseApp = firebase.initializeApp(config)

const analytics = firebase.analytics()
const storage = getStorage(firebaseApp)
const auth = firebase.auth()
const firestore = getFirestore(firebaseApp)
const database = getDatabase(firebaseApp)

// function logUserData(
//   module,
//   object,
//   objectId,
//   action,
//   actionContext,
//   actionContextValue,
//   additionalInfo = {},
// ) {
//   // use EPOCH Time
//   const RCPID = localStorage.getItem('user') ?? 'visitor'
//   const parser = new UAParser()
//   const dataBase = firebase
//     .app()
//     .database(process.env.REACT_APP_FIREBASE_REALTIME_URL)
//   dataBase
//     .ref()
//     .child(Date.now())
//     .child(module)
//     .set({
//       [object]: {
//         [objectId]: {
//           [action]: {
//             [actionContext]: {
//               [actionContextValue]: {
//                 [RCPID]: {
//                   details: {
//                     info: JSON.stringify(parser.getResult()),
//                     ...additionalInfo,
//                   },
//                 },
//               },
//             },
//           },
//         },
//       },
//     })
// }

export {
  analytics,
  auth,
  database,
  firestore,
  storage,
  //   logUserData,
  firebase as default,
}
