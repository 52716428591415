/* eslint-disable import/named */
import { addProfileLogEvent } from './widgets/MyProfile/myProfileSlice'

const events = {
  dashboardEvent: {
    module: 'Dash',
    object: 'Widget',
    action: 'Click',
    actionContext: 'Button',
  },
  dashboardLinkEvent: {
    module: 'Dash',
    object: 'Widget',
    action: 'Click',
    actionContext: 'HyperLink',
  },
  alertsViewEvent: {
    module: 'Core',
    object: 'Alerts',
    action: 'Click',
    actionContext: 'ViewAlert',
  },
  alertsAcknowledgeEvent: {
    module: 'Core',
    object: 'Alerts',
    action: 'Click',
    actionContext: 'AcknowledgeAlert',
  },
  alertsDismissEvent: {
    module: 'Core',
    object: 'Alerts',
    action: 'Click',
    actionContext: 'DismissAlert',
  },
}

export const logDashboardEvent = (dispatch, event, objectId, value, url) => {
  // objectId is the widgetKey
  if (
    process.env.REACT_APP_FIREBASE_ON === 'true' &&
    window.location.hostname !== 'localhost'
  ) {
    dispatch(
      addProfileLogEvent({
        ...events[event],
        objectId,
        actionContextValue: value,
        rcpid: localStorage.getItem('rutgersEduRCPID'),
        url,
        timestamp: Date.now(),
      }),
    )
  }
}
