/* eslint-disable no-underscore-dangle */
import * as React from 'react'
import uuid from 'react-uuid'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import ParkingMarker from './ParkingMarker'
import { setLatLang } from '../../redux/myMapsSlice'

export default function ParkingStops() {
  const dispatch = useDispatch()
  const { nearestParking, nearestParkingLoading, selectedBuilding } =
    useSelector((s) => s.buildings)

  const [customMarkers, setCustomMarkers] = React.useState([])

  React.useEffect(() => {
    if (
      !(nearestParkingLoading === 'fulfilled') &&
      !_.isEmpty(nearestParking)
    ) {
      generateStops()
      const { lat, lng } = selectedBuilding
      const coords = { lat, lang: lng, zoom: 16 }
      dispatch(setLatLang(coords))
    }
  }, [nearestParkingLoading, nearestParking])

  const parsedCoordinates = (parking) => {
    const [lat, lng] = parking.lotCor.split(', ')
    const updatedParking = {
      ...parking, // Copy all fields from the original object
      lat,
      lng, // Overwrite the coord field with an object containing the updated lat and lng values
    }
    return updatedParking
  }

  const generateStops = () => {
    if (!_.isEmpty(nearestParking)) {
      const markers = nearestParking.bldgLotVMList.map((parking) => (
        <ParkingMarker key={uuid()} parking={parsedCoordinates(parking)} />
      ))
      setCustomMarkers(markers)
    }
  }

  return <>{!_.isEmpty(nearestParking) && customMarkers}</>
}
