import { makeStyles } from '@material-ui/core/styles'

// Any time that we would like to add CSS to our Components we will create a styles object. The syntax is different then normal CSS. Please notice that camel case is used to set the list-style-type. For your reference please visit https://v4.mui.com
export const didYouKnowWidgetStyles = makeStyles(() => ({
  gridContainer: {
    paddingRight: 10,
    paddingLeft: 10,
    height: 475,
    overflowY: 'clip',
    margin: 0,
    width: 335,
  },
  categoriesList: {
    listStyleType: 'none',
    padding: '0px',
  },
}))

export default didYouKnowWidgetStyles
