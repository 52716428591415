/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Cookies from 'js-cookie'

export const fetchMyScarletMail = createAsyncThunk(
  'fetchMyScarletMail',
  async () => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const rcpid = localStorage.getItem('rcpid')
    const response = await axios.get(
      process.env.REACT_APP_MYSCARLETMAIL_GET_TOKEN,
      {
        headers: {
          JWT: jwtToken,
          XWT: rcpid,
        },
      },
    )
    // const ev = await axios.get(process.env.REACT_APP_MYSCARLETEVENTS_ENDPOINT)
    const res = {
      auth: response.data,
      //   events: ev.data,
    }
    return res
  },
)

export const fetchMyScarletMailEmails = createAsyncThunk(
  'fetchMyScarletMailEmails',
  async (codeResponse) =>
    // codeResponse.code
    // https://gmail.googleapis.com/gmail/v1/users/er647%40scarlet-test.rutgers.edu/messages?maxResults
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_SCARLETMAIL_LIST_MESSAGES}${
        window.location.hostname === 'localhost'
          ? ''
          : `/me/messages?maxResults=15&key=${process.env.REACT_APP_SCARLETMAIL_CLIENT_SECRET}`
      }`,
      headers: {
        Authorization: `Bearer ${codeResponse.access_token}`,
      },
    }),
)

export const fetchMyScarletMailContents = createAsyncThunk(
  'fetchMyScarletMailContents',
  async ({ codeResponse, messageId }) =>
    // codeResponse.code
    axios({
      method: 'GET',
      url: `${process.env.REACT_APP_SCARLETMAIL_EMAIL_CONTENTS}${
        window.location.hostname === 'localhost'
          ? ''
          : `/me/messages/${messageId}?format=full&key=${process.env.REACT_APP_SCARLETMAIL_CLIENT_SECRET}`
      }`,
      headers: {
        Authorization: `Bearer ${codeResponse.access_token}`,
      },
    }),
)

export const fetchMyScarletMailEvents = createAsyncThunk(
  'fetchMyScarletMailEvents',
  async ({ codeResponse, today }) => {
    // codeResponse.code
    const startDate = today.toISOString()
    const endDate = new Date(today.getFullYear(), today.getMonth() + 1, 1)
    const res = await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_SCARLETMAIL_EMAIL_EVENTS}${
        window.location.hostname === 'localhost'
          ? ''
          : `/calendar/v3/calendars/primary/events?singleEvents=true&timeMax=${endDate.toISOString()}&timeMin=${startDate}`
      }`,
      headers: {
        Authorization: `Bearer ${codeResponse.access_token}`,
      },
    })
    const data = res.data.items.map((calendarEvent) => ({
      title: calendarEvent.summary,
      description: calendarEvent.summary,
      start: calendarEvent.start.dateTime,
      end: calendarEvent.end.dateTime,
      htmlLink: calendarEvent.htmlLink,
      conferenceData: calendarEvent.conferenceData,
    }))
    return data
  },
)

export const storeMyScarletMailToken = createAsyncThunk(
  'storeMyScarletMailToken',
  async (auth) => {
    const netId = Cookies.get('userId')
    const jwtToken = localStorage.getItem('myrJwtToken')
    const rcpid = localStorage.getItem('rcpid')
    const res = await axios.post(
      process.env.REACT_APP_MYSCARLETMAIL_STORE_TOKEN,
      { netId, code: auth.code },
      {
        headers: {
          JWT: jwtToken,
          XWT: rcpid,
        },
      },
    )

    return res.data
  },
)

export const clearMyScarletMailToken = createAsyncThunk(
  'clearMyScarletMailToken',
  async (_) => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const rcpid = localStorage.getItem('rcpid')
    const res = await axios.get(
      process.env.REACT_APP_MYSCARLETMAIL_CLEAR_TOKEN,
      {
        headers: {
          JWT: jwtToken,
          XWT: rcpid,
        },
      },
    )

    return res.data
  },
)

const initialState = {
  status: 'idle',
  getMailStatus: 'idle',
  storeAuthTokenStatus: 'idle',
  clearAuthTokenStatus: 'idle',
  auth: null,
  data: {
    messages: [],
    events: [],
    emails: [],
    title: 'Scarlet Mail',
  },
}

const myScarletMailSlice = createSlice({
  name: 'myScarletMail',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchMyScarletMail.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchMyScarletMail.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchMyScarletMail.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.auth = action.payload.auth
    },
    [storeMyScarletMailToken.rejected]: (state) => {
      state.storeAuthTokenStatus = 'error'
    },
    [storeMyScarletMailToken.pending]: (state) => {
      state.storeAuthTokenStatus = 'pending'
    },
    [storeMyScarletMailToken.fulfilled]: (state, action) => {
      state.storeAuthTokenStatus = 'fulfilled'
      state.auth = action.payload
    },
    [clearMyScarletMailToken.rejected]: (state) => {
      state.clearAuthTokenStatus = 'error'
    },
    [clearMyScarletMailToken.pending]: (state) => {
      state.clearAuthTokenStatus = 'pending'
    },
    [clearMyScarletMailToken.fulfilled]: (state, action) => {
      state.clearAuthTokenStatus = 'fulfilled'
      state.getMailStatus = 'idle'
    },
    [fetchMyScarletMailEmails.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchMyScarletMailEmails.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.data.messages = action.payload.data
    },
    [fetchMyScarletMailEmails.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchMyScarletMailContents.rejected]: (state) => {
      state.getMailStatus = 'error'
    },
    [fetchMyScarletMailContents.fulfilled]: (state, action) => {
      state.getMailStatus = 'fulfilled'
      const emailItem = action.payload.data.payload.headers
        .map((emailContents) => {
          const emailStruct = {}
          emailContents.name === 'From'
            ? (emailStruct.from = emailContents.value)
            : null
          emailContents.name === 'Date'
            ? (emailStruct.date = emailContents.value)
            : null
          emailContents.name === 'Subject'
            ? (emailStruct.subject = emailContents.value)
            : null
          emailContents.name === 'To'
            ? (emailStruct.to = emailContents.value)
            : null
          return emailStruct
        })
        .filter((email) => Object.keys(email).length > 0)
      const fromObj = emailItem.find((item) => item.from)
      const fromDate = emailItem.find((item) => item.date)
      const fromSubject = emailItem.find((item) => item.subject)
      const fromTo = emailItem.find((item) => item.to)
      // Plain text emails contain different HTML structure
      const plainEmailData = action.payload?.data?.payload?.parts
        ?.map((item) => (item.partId === '1' ? item?.body?.data : null))
        .filter((partData) => partData !== null)
      const encodedBody = action.payload?.data?.payload?.body?.data
        ? action.payload?.data?.payload?.body?.data
        : plainEmailData[0]
      const snippet = Buffer.from(encodedBody, 'base64')
      state.data.emails.push({
        id: action.payload?.data?.payload?.id,
        from: fromObj.from || '',
        date: fromDate.date || '',
        subject: fromSubject.subject || '',
        to: fromTo.to || '',
        snippet: snippet.toString() || 'No Email Body',
        link: `http://${process.env.REACT_APP_MYSCARLETMAIL_DEEPLINK}`,
        unRead: action.payload.data?.labelIds?.includes('UNREAD'),
      })
      const emailsWithoutDuplicates = Array.from(
        new Set(state.data.emails?.map(JSON.stringify)),
      ).map(JSON.parse)
      state.data.emails = emailsWithoutDuplicates
      if (state.data.emails && state.data.emails.length > 0) {
        state.data.emails.sort((a, b) => {
          const current = new Date(a.date).getTime() / 1000
          const next = new Date(b.date).getTime() / 1000
          return next - current
        })
      }
    },
    [fetchMyScarletMailContents.pending]: (state) => {
      state.getMailStatus = 'pending'
    },
    [fetchMyScarletMailEvents.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchMyScarletMailEvents.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchMyScarletMailEvents.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.data.events = action.payload
    },
  },
})

export const selectMyScarletMailData = (state) => state.scarletMail.data
export const selectMyScarletMailStatus = (state) => state.scarletMail.status

export default myScarletMailSlice.reducer
