export const credits = {
  mobile: [
    'John Cinquegrana',
    'Tom McCaffrey',
    'Attila Farkas',
    'Ruben Alias',
    'Aneesa Shaik',
    'Jason Merchan',
  ],
  web: [
    'Edwin Rivera',
    'Muhammad Rashid Mahfooz',
    'Ryan Janelli',
    'Attila Farkas',
    'Sumeet Singh',
    'Yash Nishikant',
    'Tanay Desai',
  ],
  backend: ['Amy Jordan', 'Timothy Lui', 'Paul Bernard', 'Tom McCaffrey'],
  systemArchitect: ['Yaw-Shing Wang'],
  pastContributors: [
    'Nicholas Summers',
    'Nico Spadavecchia',
    'Ushio (Louis) Shinohara',
    'Matthew Enad',
    'Kevin Shah',
    'Daniel Trotter',
    'Allen Davis-Swing',
    'Sidhu Arakkal',
    'Aadit Singh',
    'Rasika Nanasaheb Punde',
    'Shreesh Keskar',
  ],
}
