import { useBreakpointDown } from '../app/theme/helpers/useBreakpointDown'

/**
 * CheckIsMobile is a function that determines whether we should render the mobile view.
 */

const CheckIsMobile = () => {
  const matches = useBreakpointDown('sm')

  if (matches) {
    return true
  }
  return false
}

export default CheckIsMobile
