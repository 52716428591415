import * as React from 'react'
import { useSelector } from 'react-redux'
import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'
import SearchIcon from '@material-ui/icons/Search'

import {
  closeSearchBarToggle,
  closeSheet,
  openSearchBarToggle,
  resetMap,
} from '../../../features/maps/sections/Map/redux/myMapsSlice'

import CampusDropDown from '../../../features/maps/sections/Map/components/CampusDropDown/CampusDropDown'
import VehicleStopArrivalListDropDown from '../../../features/maps/sections/Vehicles/components/VehicleStopArrivalListDropDown/VehicleStopArrivalListDropDown'
import { ROUTE, VEHICLE } from '../../../features/maps/utils/constants'
import { LogOutButton } from './DashboardLocalButtons'

export default function MapsLocalButtons({
  classes,
  isMobile,
  history,
  dispatch,
  userProfile,
}) {
  const { bottomSheet, showSearchBar } = useSelector((state) => state.maps)

  const bottomSheetOpen = [ROUTE, VEHICLE].includes(bottomSheet.component)

  const handleSearchBarButtonClick = () => {
    if (showSearchBar) {
      dispatch(closeSearchBarToggle())
      dispatch(resetMap())
      dispatch(closeSheet())
    } else {
      dispatch(openSearchBarToggle())
    }
  }

  return (
    <Grid item md={4}>
      <Grid container justifyContent="flex-end" alignItems="center">
        <Grid item style={{ marginRight: 4 }}>
          <VehicleStopArrivalListDropDown
            isMobile={isMobile}
            dispatch={dispatch}
          />
        </Grid>

        {isMobile && bottomSheetOpen ? null : (
          <>
            <Grid item>
              <CampusDropDown />
            </Grid>
            <Grid item>
              <Tooltip
                title={<Typography variant="subtitle2">Search Menu</Typography>}
                placement="top"
                arrow
              >
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="Search Menu"
                  onClick={handleSearchBarButtonClick}
                >
                  <SearchIcon className={classes.appBarIcon} />
                </IconButton>
              </Tooltip>
            </Grid>
          </>
        )}

        {isMobile && !bottomSheetOpen && (
          <Grid item>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="Search Menu"
              onClick={() => history.push('/settings')}
            >
              <SettingsIcon className={classes.appBarIcon} />
            </IconButton>
          </Grid>
        )}
        <LogOutButton
          isMobile={isMobile}
          dispatch={dispatch}
          userProfile={userProfile}
          classes={classes}
        />
      </Grid>
    </Grid>
  )
}
