import { makeStyles } from '@material-ui/core/styles'

export const libraryQuickSearchStyles = makeStyles((theme) => ({
  quickSearchImgContainer: {
    marginTop: 5,
    marginBottom: 5,
  },
  quickSearchImg: {
    height: 35,
    width: 'auto',
  },
  searchBar: {
    backgroundColor: theme.palette.backgroundColor.ternary,
  },
}))

export default libraryQuickSearchStyles
