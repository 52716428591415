import { makeStyles, createStyles } from '@material-ui/styles'

export const MyFavoriteStyles = makeStyles((theme) =>
  createStyles({
    // MyFavorites.js
    favoritesListContainer: {
      width: '100%',
      height: '91.5%',
      overflowY: 'auto',
      paddingTop: '8px',
    },

    widgetTile: {
      height: '85px',
      maxHeight: '85px',
      width: '85px',
      overflow: 'hidden',
      borderRadius: '10px',
      color: 'black',
      textDecoration: 'none',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },

    widgetTileWrapper: {
      position: 'relative',
      margin: '10px',
      height: '86px',
      width: '86px',
      maxHeight: '86px',
      borderRadius: '10px',
      overflow: 'hidden',
      '&:focus-visible': {
        outline: `1px solid ${theme.palette.button.focusBorder}`,
        boxShadow: `0 0 0 3px ${theme.palette.button.focusOutline}`,
      },
      '&:hover': {
        cursor: 'pointer',
      },
    },

    widgetIcon: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '15px',
    },
    widgetTitle: {
      background: '#ffffff',
      textAlign: 'center',
      fontWeight: 400,
      textDecoration: 'none',
      fontSize: '10px',
      maxWidth: '87px',
      minWidth: '87px',
      maxHeight: '90px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      position: 'absolute',
      bottom: 0,
      left: 0,
    },

    footerButtonContent: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    manageFavsButton: {
      backgroundColor: '#004CC7',
      color: 'white',
      textAlign: 'center',
      width: '100%',
      height: '40px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    saveFavsButton: {
      color: 'white',
      textAlign: 'center',
      margin: '0px',
      width: '100%',
      height: '40px',
      backgroundColor: '#008020',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    // MyFavoritesEmpty.js
    noMatchesContainter: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: '5vh',
      alignItems: 'center',
      margin: '0 auto',
    },
    addToFavoritesButton: {
      backgroundColor: theme.palette.backgroundColor.ternary,
      color: theme.palette.primary.main,
      textAlign: 'center',
      margin: '10px',
    },
    inTextIcon: {
      color: theme.palette.base.lightGray,
      fontSize: 15,
      alignSelf: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
      },
      margin: '2px 2px',
    },
    noMatchesIconText: {
      display: 'flex',
      flexDirection: 'row',
    },
    goToAppsButtonIcon: {
      marginRight: '10px',
    },
  }),
)

export default MyFavoriteStyles
