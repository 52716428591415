import React from 'react'

import { Typography, Grid, Box, ButtonBase, Card } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import { useHistory } from 'react-router-dom'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import Divider from '@material-ui/core/Divider'
import styles from '../settingsStyles'

function PrivacyNotice() {
  const classes = styles()
  const history = useHistory()
  return (
    <Box className={classes.centerPage}>
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.container}
        wrap="nowrap"
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.sectionTitle}
        >
          <ButtonBase
            onClick={() => history.push('/settings/appinfo')}
            aria-label="Back"
          >
            <ArrowBackIos />
          </ButtonBase>
          <Typography variant="h1">
            <b>Privacy Notices</b>
          </Typography>
        </Grid>
        <Grid item className={classes.fullWidth}>
          <Card className={classes.linksCard}>
            <Grid
              container
              direction="column"
              alignItems="left"
              justifyContent="flex-start"
              spacing={2}
              className={classes.copyrightCard}
            >
              <Grid
                item
                container
                direction="row"
                alignItems="left"
                justifyContent="flex-start"
              ></Grid>

              <Grid item>
                <Typography variant="h2">Privacy Notices</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  Rutgers University is committed to protecting the privacy of
                  personal information of users and visitors to the
                  my.rutgers.edu. Rutgers collects certain kinds of data from
                  users and visitors as they browse the site for general site
                  analytics. This privacy notice discloses the privacy practices
                  for my.rutgers.edu. This privacy notice applies solely to
                  information collected by this website. It will notify you of
                  what personally identifiable information is collected from you
                  through the website, how it is used and with whom it may be
                  shared, what choices are available to you regarding the use of
                  your data, the security procedures in place to protect the
                  misuse of your information and how you can correct any
                  inaccuracies in the information.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h4">
                  Information Collection, Use, and Sharing
                </Typography>
              </Grid>
              <Divider variant="fullWidth" />
              <Grid item>
                <Typography variant="h6">
                  We are the sole owners of the information collected on this
                  site. We only have access to/collect information that you
                  voluntarily give us via email or other direct contact from
                  you. We will not sell or rent this information to anyone. We
                  will use your information to respond to you, regarding the
                  reason you contacted us. We will not share your information
                  with any third party outside of our organization, other than
                  as necessary to fulfill your request.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h4">
                  Updates to This Privacy Notice
                </Typography>
              </Grid>
              <Divider variant="fullWidth" />
              <Grid item>
                <Typography variant="h6">
                  We may make changes or additions to this policy at any time
                  and will post the changes on this web page. Unless you ask us
                  not to, we may contact you via email in the future to tell you
                  about changes to this privacy policy. This website contains
                  content from and links to other sites. Please be aware that we
                  are not responsible for the content or privacy practices of
                  such other sites. We encourage our users to be aware when they
                  leave our site and to read the privacy statements of any other
                  site that collects personally identifiable information.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h4">Cookies</Typography>
              </Grid>
              <Divider variant="fullWidth" />
              <Grid item>
                <Typography variant="h6">
                  During your use of this website, you may be assigned a cookie
                  to track your browsing behavior and activity more accurately.
                  Cookies are small text-based data files that are stored on
                  your computer’s hard drive and used to collect details about
                  each visit you make to the site. We use cookies to track site
                  usage and behavior for the purpose of delivering more
                  personalized, relevant web experiences to our visitors. Many
                  browsers automatically accept cookies by default, but you can
                  adjust your browser settings to disable cookies, which may
                  impact your access/usage of certain parts of the site.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h2">
                  Use of third-party services
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h4">Use of Google Resources</Typography>
              </Grid>
              <Divider variant="fullWidth" />
              <Grid item>
                <ul>
                  <li>
                    <Typography variant="h6">
                      <Link href="https://policies.google.com/privacy">
                        Google&apos;s Privacy Policy
                      </Link>
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="h6">
                      <Link href="https://safety.google/">
                        Google Safety Center
                      </Link>
                    </Typography>
                  </li>
                </ul>
              </Grid>
              <Grid item>
                <Typography variant="h4">
                  Passwords and Google Apps system
                </Typography>
              </Grid>
              <Divider variant="fullWidth" />
              <Grid item>
                <Typography variant="h6">
                  When you log in to your Rutgers Google account via the Web or
                  mobile device, you log in using Rutgers’s Central
                  Authentication Service (CAS) with your Rutgers NetID. That
                  service will then pass on assurance of your identity and
                  authorization to Google without passing on your password.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h4">
                  University Policy and Google Apps
                </Typography>
              </Grid>
              <Divider variant="fullWidth" />
              <Grid item>
                <Typography variant="h6">
                  Under the terms of Rutgers&apos;s agreement with Google,
                  Rutgers continues to own its own data, so consequently its
                  security and privacy policies continue to apply for members of
                  the Rutgers campus community using Google Apps.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h4">Use of Google APIs</Typography>
              </Grid>
              <Divider variant="fullWidth" />
              <Grid item>
                <Typography variant="h6">
                  Rutgers applications use Google APIs to provide email preview
                  widget for our users’ dashboard experience.
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h2">Disclosure of information</Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  Only staff who need the information to perform a specific job
                  are granted access to personally identifiable information.
                  Other than sharing your information with appropriate
                  university personnel and units to ensure the quality,
                  functionality, and security of our website, or to manage your
                  relationship with us, we will not disclose personally
                  identifiable information about your use of the site except
                  under the following circumstances:
                  <ul>
                    <li>
                      <Typography variant="h6">
                        With your prior written (including email) consent.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="h6">
                        When we have given you clear notice that we will
                        disclose information that you voluntarily provide.
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="h6">
                        With appropriate external parties, such as for
                        regulatory compliance or law enforcement agencies, in
                        order to investigate and respond to suspected violations
                        of law or university policy. Any such disclosures shall
                        comply with all applicable laws and university policies.
                      </Typography>
                    </li>
                  </ul>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h4">Security</Typography>
              </Grid>
              <Divider variant="fullWidth" />
              <Grid item>
                <Typography variant="h6">
                  We take precautions to protect your information. The
                  computers/servers in which we store personally identifiable
                  information are kept in a secure environment.
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

export default PrivacyNotice
