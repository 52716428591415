/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  title: 'My Money',
  flatHiddenEyeButton: {},
  paymentDue: '',
  paymentNotice: '',
  iconLinks: [],
  status: 'idle',
  tab: 'ru',
}

export const fetchMyMoney = createAsyncThunk('fetchMyMoney', async () => {
  const jwtToken = localStorage.getItem('myrJwtToken')
  const rcpid = localStorage.getItem('rcpid')
  const res = await axios.get(process.env.REACT_APP_MONEY_ENDPOINT, {
    headers: {
      JWT: jwtToken,
      XWT: rcpid,
    },
  })
  return res.data
})

export const moneySlice = createSlice({
  name: 'myMoneySlice',
  initialState,
  reducers: {
    updateTab: (state, action) => {
      state.tab = action.payload
    },
  },
  extraReducers: {
    [fetchMyMoney.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchMyMoney.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchMyMoney.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.data = action.payload
      state.title = action.payload.title
      state.hiddenEyeButton = action.payload[state.tab]
        ? action.payload[state.tab]?.flatHiddenEyeButton
        : ''
      state.paymentDue = action.payload[state.tab]
        ? action.payload[state.tab]?.paymentDue
        : ''
      state.paymentNotice = action.payload[state.tab]
        ? action.payload[state.tab]?.paymentNotice
        : ''
      state.iconLinks = action.payload.iconLinks
      state.billableCreditHours = action.payload[state.tab]?.billableCreditHrs
    },
  },
})
export const selectPaycheckTitle = (state) => state.myMoney.title
export const selectHiddenEyeButton = (state) => state.myMoney.hiddenEyeButton
export const selectPaymentNotice = (state) => state.myMoney.paymentNotice
export const selectMoneyPaymentDue = (state) => state.myMoney.paymentDue
export const selectIconLinks = (state) => state.myMoney.iconLinks

export const selectPaycheckStatus = (state) => state.myMoney.status
export const selectTab = (state) => state.myMoney.tab
export const { updateTab } = moneySlice.actions
export default moneySlice.reducer
