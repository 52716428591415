import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) => ({
  container: {
    padding: 5,
    margin: 0,
    height: 470,
    width: 335,
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.breakpoints.down(370)]: {
      width: 287,
      height: 450,
    },
  },
  image: {
    width: '100%',
    padding: '0 !important',
    margin: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  button: {
    backgroundColor: theme.palette.base.red,
    color: theme.palette.base.white,
    borderRadius: '10px',
  },
  infoBox: {
    padding: '15px',
    fontSize: '10px',
  },
  fullWidth: {
    width: '100%',
  },
  socialMediaIcon: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  listPadding: {
    margin: '20px 20px 0px 20px',
  },
  linkListContainer: {
    margin: '10px 0px',
    width: '100%',
  },
}))

export default styles
