import { makeStyles } from '@material-ui/core/styles'

// TODO Make these colors match properly instead of hardcoded
export const sideNavStyles = makeStyles((theme) => ({
  listIconStyle: {
    color: theme.palette.components.sideNavIcon,
  },
  header: {
    textAlign: 'left',
    fontWeight: 500,
    fontSize: '.9rem',
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-user-select': 'none' /* Safari */,
    '-moz-user-select': 'none' /* Firefox */,
    '-ms-user-select': 'none' /* IE10+/Edge */,
    'user-select': 'none' /* Standard */,
  },
  divider: {
    backgroundColor: theme.palette.base.red,
  },
  reorderListStyles: {
    padding: '5px 20px',
    overflowX: 'hidden',
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      padding: '5px 30px',
    },
  },
  dialog: {
    minWidth: '500px',
    width: '70vw',
    maxWidth: '100%',
    height: '70vh',
    maxHeight: '450px',
    overflow: 'hidden',
    padding: '0px',
    backgroundColor: theme.palette.backgroundColor.secondary,
    [theme.breakpoints.down('sm')]: {
      minWidth: '100px',
      width: '90vw',
    },
  },
  dialogContainer: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  deletePaper: {
    padding: '5px',
    '-webkit-user-select': 'none' /* Safari */,
    '-moz-user-select': 'none' /* Firefox */,
    '-ms-user-select': 'none' /* IE10+/Edge */,
    'user-select': 'none' /* Standard */,
  },
  trashIcon: {
    color: theme.palette.base.red,
  },
  containerStyle: {
    background: '#dadada',
    padding: 10,
    margin: 10,
    flex: 1,
    overflow: 'auto',
  },
  sideNavButton: {
    width: 'calc(100% - 10px)',
    height: 43,
    marginLeft: 6,
    borderRadius: '0.5rem',
    '&:focus-visible': {
      backgroundColor: theme.palette.components.sideNavHover,
      outline: `1px solid ${theme.palette.button.focusBorder}`,
      boxShadow: `0 0 0 3px ${theme.palette.button.focusOutline}`,
    },
    '&:hover': {
      backgroundColor: theme.palette.components.sideNavHover,
      cursor: 'pointer',
    },
  },
}))

export default sideNavStyles
