/* eslint-disable camelcase */
import React from 'react'
import uuid from 'react-uuid'
import axios from 'axios'
import { Box, CircularProgress } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useBottomSheetRoutesListStyles } from './bottomSheetRoutesListStyles'
import RouteItem from '../../../sections/Vehicles/components/RouteItem/RouteItem'
import { setTripUpdates } from '../../../sections/Map/redux/myMapsSlice'
import { getArrivalTime } from '../../../sections/Map/helpers/helpers'

export default function BottomSheetRoutesList({ setSheetConfig }) {
  const dispatch = useDispatch()
  const classes = useBottomSheetRoutesListStyles()

  const { trips, selectedStop } = useSelector((s) => s.maps)

  const [listState, setListState] = React.useState('pending')
  // Add a state variable to manage the loading indicator
  const [loading, setLoading] = React.useState(false)

  const [routeListWithTime, setRouteListWithTime] = React.useState([])

  React.useEffect(() => {
    const sheetConfig = {
      title: selectedStop.stop_name,
      sheetColor: '',
      displayBackButton: false,
      displayCloseButton: true,
    }
    setSheetConfig(sheetConfig)

    // Fetch and process arrivals immediately when the effect runs
    fetchAndProcessArrivals()

    // Set an interval to fetch and process arrivals every 10 seconds
    const intervalId = setInterval(fetchAndProcessArrivals, 10000)

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId)
  }, [selectedStop])

  // Function to fetch and process arrivals
  const fetchAndProcessArrivals = () => {
    setListState('pending')
    setLoading(true)

    const selectedRouteIds = selectedStop?.routes?.map(
      (route) => route.route_id,
    )

    axios
      .get(`${process.env.REACT_APP_PASSIOGO_TRIP_UPDATES_ENDPOINT}`)
      .then((response) => {
        const routeIdToArrivalsMap = {}

        const tripUpdates = response.data.entity
        const stopID = selectedStop.stop_id

        // Get the desired trips_id's for each route at the stop
        const tripsFiltered = Object.keys(trips)
          .filter((route_id) => selectedRouteIds?.includes(route_id))
          .reduce((acc, route_id) => {
            acc[route_id] = trips[route_id]
            return acc
          }, {})

        tripUpdates.forEach((item) => {
          const { tripId } = item.tripUpdate.trip
          // Find the route_id associated with the tripId
          const routeId = Object.keys(tripsFiltered).find((route_id) => {
            const includesTripId = tripsFiltered[route_id].includes(tripId)
            return includesTripId
          })

          if (routeId) {
            // Find the arrival time for the target stopID
            const stopTimeUpdate = item.tripUpdate.stopTimeUpdate.find(
              (update) => update.stopId === stopID,
            )

            if (stopTimeUpdate) {
              const arrivalTime = stopTimeUpdate.arrival.time

              // Add the arrival time to the routeIdToArrivalsMap
              if (!routeIdToArrivalsMap[routeId]) {
                routeIdToArrivalsMap[routeId] = []
              }

              routeIdToArrivalsMap[routeId].push(arrivalTime)
            }
          }
        })

        const updatedRoutes = selectedStop?.routes
          ?.map((route) => {
            const arrivalTimesForRoute = routeIdToArrivalsMap[route.route_id]

            // Check if there are any arrival times for the route
            if (!arrivalTimesForRoute) {
              return { ...route, arrivalTime: null } // No arrival times found
            }

            // Sort, map, and filter the arrival times
            const formattedArrivalTimes = arrivalTimesForRoute
              .sort((a, b) => a - b)
              .map(getArrivalTime)
              .filter((formattedArrival) => formattedArrival !== 'N/A')

            // Assign arrival times or a default message if none are available
            const arrivalTime =
              formattedArrivalTimes.length > 0
                ? formattedArrivalTimes
                : ['No arrival times available']

            return { ...route, arrivalTime }
          })
          .sort((a, b) => {
            // Prioritize non-null arrival times and sort alphabetically by route name
            if (!a.arrivalTime && b.arrivalTime) return 1
            if (a.arrivalTime && !b.arrivalTime) return -1
            return a.route_long_name.localeCompare(b.route_long_name)
          })

        setRouteListWithTime(updatedRoutes)
        dispatch(setTripUpdates(tripUpdates))
        setListState('fulfilled')
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <Box display="flex" className={classes.routesContainer}>
      <RenderListState
        classes={classes}
        listState={listState}
        routeListWithTime={routeListWithTime}
        selectedStop={selectedStop}
        setSheetConfig={setSheetConfig}
        loading={loading}
      />
    </Box>
  )
}

function RenderListState({
  classes,
  listState,
  routeListWithTime,
  selectedStop,
  setSheetConfig,
  loading,
}) {
  return (
    <>
      {(() => {
        if (listState === 'error') {
          return (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              Error
            </Box>
          )
        }

        if (listState === 'pending' && routeListWithTime?.length === 0) {
          return (
            <div className={classes.circularProgressContainer}>
              <CircularProgress style={{ color: '#cc0033' }} />
            </div>
          )
        }

        if (routeListWithTime?.length > 0) {
          return routeListWithTime.map((route) => (
            <RouteItem
              key={uuid()}
              route={route}
              selectedStop={selectedStop}
              setSheetConfig={setSheetConfig}
              loading={loading}
            />
          ))
        }

        return null
      })()}
    </>
  )
}
