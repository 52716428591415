import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import ForYouImageCarouselStyles from './ForYouImageCarouselStyles'
import ForYouImageCarouselItem from './ForYouImageCarouselItem'

const ForYouImageCarousel = ({ contentList }) => {
  const classes = ForYouImageCarouselStyles()
  const TRANSITION_TIME = 1000
  const IMAGE_INTERVAL_TIME = 8000
  return (
    <>
      <div className={classes.carouselBody}>
        <Carousel
          showArrows
          autoPlay
          infiniteLoop
          showThumbs={false}
          transitionTime={TRANSITION_TIME}
          interval={IMAGE_INTERVAL_TIME}
        >
          {contentList.map((content, index) => (
            <ForYouImageCarouselItem
              key={content.categoryName}
              img={content.imageUrl}
              title={content.categoryName}
              alignRight={index % 2 !== 0}
            />
          ))}
        </Carousel>
      </div>
    </>
  )
}

export default ForYouImageCarousel
