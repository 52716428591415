import { makeStyles } from '@material-ui/core/styles'

// TODO Bring Back Unique Colors
export const myPaycheckStyles = makeStyles((theme) => ({
  content: {
    textAlign: 'center',
    overflowY: 'auto',
  },
  container: {
    textAlign: 'center',
    overflowY: 'auto',
    height: 440,
  },
  gridRow: {
    marginTop: 20,
  },
  gridRowTop: {
    marginTop: 20,
  },
  icon: {
    color: theme.palette.secondary.headerText,
    fontSize: 40,
    '&:hover': {
      transform: 'scale(1.07)',
    },
  },
  gridMainStyle: {
    width: '100%',
  },
  textStyle: {
    fontWeight: 'bold',
    color: 'black',
  },
  award: {
    marginTop: 15,
    margin: 10,
    padding: 5,
    backgroundColor: theme.palette.backgroundColor.ternary,
  },
  titleStyle: {
    fontWeight: 'bold',
    marginTop: 20,
  },
}))

export default myPaycheckStyles
