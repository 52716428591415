import React from 'react'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import IconPicker from '../../../apps/AppComponents/IconPicker/IconPicker'

import styles from './InlineIconButtonsStyles'
import { logDashboardEvent } from '../../events'

function InlineIconButtons({ buttons, widgetId, dispatch }) {
  const classes = styles()
  const handleButtonClick = (button) => {
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardEvent',
        widgetId,
        button.iconLabel,
        button.link,
      )
    }
    window.open(button.link, '_blank')
  }
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-around"
      alignItems="center"
      spacing={1}
    >
      {buttons.map((button) => (
        <Grid item xs key={button.iconLabel + button.link}>
          <Button
            key={button.iconLabel + button.link}
            onClick={() => handleButtonClick(button)}
            className={classes.button}
          >
            <Grid
              container
              direction="column"
              justifyContent="space-around"
              alignItems="center"
            >
              <Grid item>
                <IconPicker iconName={button.icon} color="white" size="2x" />
              </Grid>
              <Grid item>
                <Typography variant="caption">{button.iconLabel}</Typography>
              </Grid>
            </Grid>
          </Button>
        </Grid>
      ))}
    </Grid>
  )
}

export default InlineIconButtons
