import * as React from 'react'

import {
  Card,
  Typography as Text,
  Grid,
  Link,
  Box,
  Button,
} from '@material-ui/core'
import { logDashboardEvent } from '../../events'
import PolicyStyles from './policyStyles'
import { uiTextContent } from './data/policyParams'
const ItemCard = ({ item, widgetId, dispatch }) => {
  const classes = PolicyStyles()

  const handleLinkClick = (title, link) => {
    if (widgetId && dispatch) {
      logDashboardEvent(dispatch, 'dashboardLinkEvent', widgetId, title, link)
      if (link) {
        window.open(link)
      }
    }
  }

  const EmptyDesctiption = () => {
    const emailPattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g
    const email = uiTextContent.NO_DESCRIPTION.match(emailPattern)[0]
    const text = uiTextContent.NO_DESCRIPTION.replaceAll(email, '')
    return (
      <Text variant="subtitle3">
        {text}
        <a href={`mailto:${email}`} target="_blank">
          {email}
        </a>
      </Text>
    )
  }
  return (
    <Card
      className={classes.announcementCardBackground}
      border={1}
      key={item.Id}
      variant="outlined"
    >
      <Grid container justifyContent="flex-start" direction="column">
        <Grid item>
          <Box className={classes.titleButtonContainer}>
            <Text variant="subtitle1">
              <Link
                href={item.URL}
                onClick={handleLinkClick}
                target="_blank"
                rel="noopener noreferrer"
                color="primary"
              >
                {item.Title}
              </Link>
            </Text>
            {item.ReviewAndCommentLink && (
              <Button
                className={classes.titleButton}
                onClick={() =>
                  handleLinkClick(item.Title, item.ReviewAndCommentLink)
                }
              >
                {uiTextContent.ITEM_CARD_BUTTON_TEXT}
              </Button>
            )}
          </Box>
          <Box className={classes.announcementBody}>
            {item.Description ? (
              <Text variant="subtitle3">{item.Description}</Text>
            ) : (
              <EmptyDesctiption />
            )}
          </Box>
        </Grid>
      </Grid>
    </Card>
  )
}

export default ItemCard
