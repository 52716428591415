export const gradeTutorial = [
  {
    title: 'My Grades Widget Tutorial',
    description: `The My Grades Widget provides you with a list of the courses you're taking during a semester. Clicking on any privacy icon will reveal your current grade.`,
    top: {
      height: '100%',
      width: '100%',
      left: '0',
    },
    right: {
      height: '0',
      width: '0',
    },
    bottom: {
      height: '0',
      width: '0',
    },
    left: {
      height: '0',
      width: '0',
    },
    popover: {
      top: '200px',
      left: '30px',
    },
  },
  {
    title: 'Semester',
    description: `This dropdown menu will allow you to filter your course grades by semester.`,
    top: {
      height: '0',
      width: '0',
      left: '0',
    },
    right: {
      height: '55px',
      width: '190px',
    },
    bottom: {
      height: '351px',
      width: '100%',
    },
    left: {
      height: '0',
      width: '0',
    },
    popover: {
      top: '220px',
      left: '30px',
    },
  },
  {
    title: 'Course Department and Grade',
    description: `In this section you can view the course name, department and you can view your grade by clicking on the eye icon.`,
    top: {
      height: '55px',
      width: '100%',
      left: '0px',
    },
    right: {
      height: '190px',
      width: '0px',
    },
    bottom: {
      height: '235px',
      width: '100%',
    },
    left: {
      height: '190px',
      width: '0px',
    },
    popover: {
      top: '220px',
      left: '30px',
    },
  },
  {
    title: 'Course Grade',
    description: `Clicking on the privacy icon will display your course grade.`,
    top: {
      height: '120px',
      width: '30px',
      left: '290px',
    },
    right: {
      height: '153px',
      width: '15px',
    },
    bottom: {
      height: '253px',
      width: '100%',
    },
    left: {
      height: '153px',
      width: '290px',
    },
    popover: {
      top: '220px',
      left: '30px',
    },
  },
  {
    title: 'Unofficial Transcript',
    description: `A quick link to obtain your unofficial transcript can be found here.`,
    top: {
      height: '0',
      width: '0',
      left: '0',
    },
    right: {
      height: '0',
      width: '0',
    },
    bottom: {
      height: '351px',
      width: '100%',
    },
    left: {
      height: '55px',
      width: '165px',
    },
    popover: {
      top: '220px',
      left: '30px',
    },
  },
]

export const gradeTutorialForTwoUserRoles = [
  {
    title: 'My Grades Widget Tutorial',
    description: `The My Grades Widget provides you with a list of the courses you're taking during a semester. Clicking on any privacy icon will reveal your current grade.`,
    top: {
      height: '100%',
      width: '100%',
      left: '0',
    },
    right: {
      height: '0',
      width: '0',
    },
    bottom: {
      height: '0',
      width: '0',
    },
    left: {
      height: '0',
      width: '0',
    },
    popover: {
      top: '200px',
      left: '30px',
    },
  },
  {
    title: 'Semester',
    description: `This dropdown menu will allow you to filter your course grades by semester.`,
    top: {
      height: '0',
      width: '0',
      left: '0',
    },
    right: {
      height: '55px',
      width: '190px',
    },
    bottom: {
      height: '385px',
      width: '100%',
    },
    left: {
      height: '0',
      width: '0',
    },
    popover: {
      top: '220px',
      left: '30px',
    },
  },
  {
    title: 'Course Department and Grade',
    description: `In this section you can view the course name, department and you can view your grade by clicking on the eye icon.`,
    top: {
      height: '90px',
      width: '100%',
      left: '0px',
    },
    right: {
      height: '190px',
      width: '0px',
    },
    bottom: {
      height: '270px',
      width: '100%',
    },
    left: {
      height: '190px',
      width: '0px',
    },
    popover: {
      top: '220px',
      left: '30px',
    },
  },
  {
    title: 'Course Grade',
    description: `Clicking on the privacy icon will display your course grade.`,
    top: {
      height: '100px',
      width: '65px',
      left: '270px',
    },
    right: {
      height: '0px',
      width: '0px',
    },
    bottom: {
      height: '285px',
      width: '100%',
    },
    left: {
      height: '155px',
      width: '270px',
    },
    popover: {
      top: '220px',
      left: '30px',
    },
  },
  {
    title: 'Unofficial Transcript',
    description: `A quick link to obtain your unofficial transcript can be found here.`,
    top: {
      height: '0',
      width: '0',
      left: '0',
    },
    right: {
      height: '0',
      width: '0',
    },
    bottom: {
      height: '385px',
      width: '100%',
    },
    left: {
      height: '55px',
      width: '145px',
    },
    popover: {
      top: '220px',
      left: '30px',
    },
  },
]
