import { makeStyles, createStyles } from '@material-ui/styles'

const ForYouPageStyles = makeStyles((theme) =>
  createStyles({
    forYouPageContainer: {
      background: theme.palette.components.newsBackground,
      paddingBottom: 50,
    },
    rowHeaderText: {
      fontSize: 24,
      fontWeight: '500',
      color: theme.palette.primary.headerText,
      marginLeft: '3%',
      marginRight: '3%',
      [theme.breakpoints.down('md')]: {
        paddingTop: 16,
      },
    },
  }),
)

export default ForYouPageStyles
