import { makeStyles } from '@material-ui/core/styles'

export const camdenEngageBuildingStyles = makeStyles((theme) => ({
  bottomSheetContainer: {
    width: '100vw',
    marginTop: 10,
    height: 310,
    overflowX: 'auto',
    overflowY: 'none',
    [theme.breakpoints.down(640)]: {
      overflow: 'hidden',
      height: '300 !important',
    },
  },
  descriptionTextContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  descriptionText: {
    marginBottom: 10,
    fontSize: 20,
    [theme.breakpoints.down(640)]: {
      fontSize: '1em',
      width: 160,
    },
  },
  descriptionImageContainer: {
    marginRight: 10,
  },
  descriptionImage: {
    width: '36vw',
    maxHeight: 260,
    borderRadius: 8,
    [theme.breakpoints.down(640)]: {
      width: 200,
      maxHeight: 140,
    },
  },
  websiteUrl: {
    fontSize: 20,
    [theme.breakpoints.down(640)]: {
      fontSize: '1em',
      width: 200,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}))
