import * as React from 'react'
import { Grid, Typography as Text, Card, Link, Box } from '@material-ui/core'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { MyCasesObjectStyles } from './RUDOTSServiceCenterStyles'
import { logDashboardEvent } from '../../events'

/**
 * MyCase Object that shows details on cases in the
 * My Cases tab of the RU DOTS Service Center widget
 * @param cases The case object to be passed down
 * @param status Tells us if the case is closed or open
 */
function MyCasesObject({ cases, status, widgetId, dispatch }) {
  const classes = MyCasesObjectStyles()
  const link = `${process.env.REACT_APP_RUDOTSSERVICE_BASE_URL}${cases.url}`
  const handleLinkClick = (eventLink, title) => {
    if (widgetId && dispatch) {
      logDashboardEvent(dispatch, 'dashboardEvent', widgetId, title, eventLink)
    }
  }
  dayjs.extend(localizedFormat)

  return (
    <Card className={classes.caseCardBackground} border={1} variant="outlined">
      <Grid container justifyContent="flex-start" direction="column">
        <Grid item className={classes.gridItem}>
          <Link
            href={link}
            target="_blank"
            underline="none"
            onClick={() => handleLinkClick(link, cases.description)}
          >
            <Text variant="subtitle1">{cases.description}</Text>
          </Link>
        </Grid>

        <Grid item>
          <Grid>
            <Grid item>
              <Text variant="subtitle2">{cases.display_value}</Text>
            </Grid>
            <Grid item>
              <Box display="flex">
                <Text variant="subtitle2">{cases.number}</Text>
                <Text
                  variant="subtitle2"
                  style={{ textAlign: 'right' }}
                  className={
                    status === 'closed'
                      ? classes.caseIDTextClosed
                      : classes.caseIDTextOpen
                  }
                >
                  {status === 'closed' ? 'Closed' : `Open`}
                </Text>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Text variant="subtitle2">
            {dayjs(cases.date_submitted).format('LLL')}
          </Text>
        </Grid>
      </Grid>
    </Card>
  )
}

export default MyCasesObject
