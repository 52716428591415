import React from 'react'
import { Typography as Text, Grid } from '@material-ui/core'
import dayjs from 'dayjs'
// import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import { useDispatch, useSelector } from 'react-redux'
import Widget from '../Widget'
import myPaycheckStyles from './myPaycheckStyles'
import {
  fetchMyPaycheck,
  selectPaycheckNetPay,
  selectPaycheckPayDate,
  selectPaycheckPayPeriodStart,
  selectPaycheckPayPeriodEnd,
  selectPaycheckStatus,
  selectIconList,
} from './myPaycheckSlice'
import Loading from '../helpers/Loading'
import IconLink from '../../DashboardComponents/IconLinks/IconLink'
import { formatUSD } from '../../../../../utils/format'

import HideShowButton from '../../DashboardComponents/HideShowButton/HideShowButton'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'

const DATE_FORMAT = 'ddd, MMM D, YYYY'
const COMPACT_DATE_FORMAT = 'MMM D, YYYY'
// eslint-disable-next-line no-unused-vars
function MyPaycheck({
  className,
  title,
  widgetId,
  showNote,
  noteMessage,
  notePosition,
  noteBackground,
  noteIcon,
  noteUrl,
  noteFontColor,
}) {
  const classes = myPaycheckStyles()
  const dispatch = useDispatch()
  const widgetCallState = useSelector(selectPaycheckStatus)

  const iconList = useSelector(selectIconList)
  const paycheckNetPay = useSelector(selectPaycheckNetPay)
  const paycheckPayDate = useSelector(selectPaycheckPayDate)
  const paycheckPayPeriodStart = useSelector(selectPaycheckPayPeriodStart)
  const paycheckPayPeriodEnd = useSelector(selectPaycheckPayPeriodEnd)

  React.useEffect(() => {
    dispatch(fetchMyPaycheck())
  }, [])

  return (
    <Widget className={className}>
      <Widget.Header title={title} category="default" />
      <Widget.Content
        className={classes.content}
        showNote={showNote}
        noteMessage={noteMessage}
        notePosition={notePosition}
        noteBackground={noteBackground}
        noteIcon={noteIcon}
        noteUrl={noteUrl}
        noteFontColor={noteFontColor}
      >
        {myPaycheckState(
          widgetCallState,
          widgetId,
          dispatch,
          paycheckNetPay,
          paycheckPayDate,
          paycheckPayPeriodStart,
          paycheckPayPeriodEnd,
          iconList,
        )}
      </Widget.Content>
    </Widget>
  )
}

function myPaycheckState(
  widgetCallState,
  widgetId,
  dispatch,
  paycheckNetPay,
  paycheckPayDate,
  paycheckPayPeriodStart,
  paycheckPayPeriodEnd,
  iconList,
) {
  const classes = myPaycheckStyles()
  // TODO: Handle Proper Error States
  switch (widgetCallState) {
    case 'fulfilled':
      return (
        <Loaded
          paycheckNetPay={paycheckNetPay}
          paycheckPayDate={paycheckPayDate}
          paycheckPayPeriodStart={paycheckPayPeriodStart}
          paycheckPayPeriodEnd={paycheckPayPeriodEnd}
          widgetId={widgetId}
          dispatch={dispatch}
          iconList={iconList}
        />
      )
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage />
        </Grid>
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item className={classes.text}></Grid>
        </Grid>
      )
  }
}

function Loaded(props) {
  const classes = myPaycheckStyles()
  const { iconList } = props
  const additionalVoiceOver = `Your paycheck is ${
    props.paycheckNetPay ? formatUSD(parseFloat(props.paycheckNetPay)) : 'N/A'
  }`
  return (
    <div>
      <Widget.Spacer />

      <HideShowButton
        displayData={
          props.paycheckNetPay
            ? formatUSD(parseFloat(props.paycheckNetPay))
            : 'N/A'
        }
        color="green"
        widgetId={props.widgetId}
        dispatch={props.dispatch}
        moduleName="Paycheck"
        additionalVoiceOver={additionalVoiceOver}
      />

      <Widget.Spacer />

      <Widget.Spacer />

      <Text variant="subtitle1">
        Payment Date:
        <span className={classes.paymentSpan}>
          {props.paycheckPayDate
            ? ` ${dayjs(props.paycheckPayDate).format(DATE_FORMAT)}`
            : ' N/A'}
        </span>
      </Text>

      <Text variant="subtitle1">
        Pay Period:
        {props.paycheckPayPeriodStart ? (
          <span className={classes.paymentSpan}>
            {` ${dayjs(props.paycheckPayPeriodStart).format(
              COMPACT_DATE_FORMAT,
            )} - ${dayjs(props.paycheckPayPeriodEnd).format(
              COMPACT_DATE_FORMAT,
            )}`}
          </span>
        ) : (
          <span className={classes.paymentSpan}> N/A</span>
        )}
      </Text>

      <Widget.Spacer />

      <Widget.Spacer />

      <Grid
        container
        direction="row"
        alignContent="space-between"
        justifyContent="center"
        className={classes.fullwidth}
      >
        {iconList.map((icon) => (
          <Grid
            container
            direction="column"
            alignContent="center"
            justifyContent="center"
            item
            xs={4}
            key={icon.iconLabel + icon.iconLink}
            className={classes.iconButton}
          >
            <IconLink
              icon={icon.icon}
              iconLabel={icon.iconLabel}
              link={icon.link}
              widgetId={props.widgetId}
              dispatch={props.dispatch}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export default MyPaycheck
