import { makeStyles } from '@material-ui/core/styles'

export const nearestBusStopsStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    marginTop: 20,
    // marginLeft: 20,
    height: 'auto',
    minHeight: 500,
    width: 'calc(100vw - 55px)',
    background: theme.palette.background.paper,
    [theme.breakpoints.down(500)]: {
      width: '100%',
      height: 'auto',
    },
  },
  rootTabBox: {
    width: '90vw',
    [theme.breakpoints.down(500)]: {
      width: '100%',
    },
  },
  rootTabContainer: {
    width: '90vw',
    [theme.breakpoints.down(500)]: {
      width: '100%',
    },
  },
  activeTab: {
    display: 'flex',
    // color: theme.palette.base.white,
    // background: theme.palette.base.red,
    flex: 1,
    paddingLeft: 0,
    paddingRight: 0,
  },
  inactiveTab: {
    display: 'flex',
    // color: theme.palette.base.red,
    // background: theme.palette.myApps.iconButton,
    flex: 1,
    paddingLeft: 0,
    paddingRight: 0,
  },
  closeNearestStopsButton: {
    background: '#333333',
    color: theme.palette.base.white,
    '&:hover': {
      background: '#333333',
    },
  },
  busDialogTitle: {
    background: theme.palette.base.red,
    color: theme.palette.base.white,
  },
  busDialogText: {
    fontSize: 30,
  },
  icon: {
    fontSize: 30,
  },
}))
