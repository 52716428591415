import { makeStyles } from '@material-ui/styles'

export const restaurantListMobile = makeStyles((theme) => ({
  appBar: {
    background: theme.palette.components.newsBackground,
    color: 'rgba(0,0,0,0.54)',
  },
  pageTitleContainer: {
    width: '86%',
  },
  pageTitle: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 1,
    wordBreak: 'keep-all',
    overflow: 'hidden',
    color: theme.palette.base.red,
    fontSize: 22,
    fontWeight: '400',
    textOverflow: 'ellipsis',
  },
  mobileBackButtonIcon: {
    color: theme.palette.base.red,
    fontSize: 24,
  },
  appBarcontainer: {
    flex: 1,
    width: '100%',
    background: theme.palette.components.newsBackground,
    // overflow: 'auto',
    overscrollBehavior: 'contain',
    // '&::-webkit-scrollbar': {
    //   display: 'none',
    // },
    justifyContent: 'center',
  },
  container: {
    flex: 1,
    width: '100%',
    // height: 'calc(100vh - 147px)',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.default,
    justifyContent: 'center',
    marginTop: 50,
    paddingBottom: 50,
  },
  containerDesktop: {
    flex: 1,
    width: '100%',
    margin: '30px',
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
    marginBottom: 'auto',
    paddingBottom: '20px',
  },
  relatedText: {
    fontSize: '24px',
    fontWeight: '600',
    color: theme.palette.primary.headerText,
    marginRight: '125px',
    paddingBottom: '10px',
  },
  icon: {
    color: theme.palette.primary.main,
  },
  backButtonContainer: {
    marginLeft: 15,
  },
}))

export const restaurantCardStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    margin: 8,
    height: 151,
  },
  restaurantName: {
    fontSize: 16,
  },
  restaurantVicinity: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 2,
    wordBreak: 'keep-all',
    overflow: 'hidden',
    fontSize: 13,
    fontWeight: '400',
    textOverflow: 'ellipsis',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  openText: {
    fontSize: 14,
    fontWeight: '600',
    color: '#48cc1f',
  },
  closedText: {
    fontSize: 14,
    fontWeight: '600',
    color: '#d21032',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    minWidth: 151,
    minHeight: 151,
    maxWidth: 151,
    maxHeight: 151,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}))
