import { makeStyles } from '@material-ui/core/styles'

export const restaurantCarouselStyles = makeStyles(() => ({
  dotGroup: {
    maxWidth: '150px',
  },
  backArrow: {
    position: 'relative',
    left: 4,
    top: 1,
  },
  forwardArrow: {
    position: 'relative',
    top: 1,
    left: 1,
  },
  customDotGroupContainer: {
    height: '40px',
    width: '100%',
  },
}))

export const restaurantTileStyles = makeStyles((theme) => ({
  restaurantTileContainer: {
    height: 275,
    width: 220,
    marginLeft: 4,
    marginRight: 4,
    backgroundColor: theme.palette.background.widgetSubItemBackground,
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 32,
  },
  img: {
    height: 125,
    width: 220,
  },
  header: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  ratingContainer: {
    display: 'flex',
    alignContent: 'flex-start',
    marginTop: 8,
  },
  ratingItems: {
    flex: 1,
  },
  iconButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  iconItems: {
    flex: 1,
  },
  icon: {
    color: '#cc0033',
    fontSize: 30,
  },
  infoIcon: {
    color: '#cfd8dc',
    fontSize: 25,
  },
  openText: {
    fontSize: 24,
    fontColor: '#cc0033',
  },
  closeText: {
    fontSize: 24,
  },
}))
