/* eslint-disable camelcase */
import * as React from 'react'
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography as Text,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import { useDispatch, useSelector } from 'react-redux'

import {
  handleSearchBarToggle,
  removeStopFromFavorite,
  addStopToFavorite,
  setSelectedFavoritedStops,
  handleStopClicked,
} from '../../../sections/Map/redux/myMapsSlice'
import { stopsListComponentStyles } from './stopsListComponentStyles'
import { sortListAlphabetically } from '../../../utils/helpers'
import { logMapEvent } from '../../../utils/events'
import {
  STOPMARKER,
  STOPSFAVORITE,
  STOPSUNFAVORITE,
} from '../../../utils/constants'
import { storeFavoriteStopsListToFirebase } from '../../../sections/Map/firebase/firebase'

const StopsListComponent = ({ stop, style, isFavorited }) => {
  const classes = stopsListComponentStyles()
  const dispatch = useDispatch()
  const { routes, favoritedStops } = useSelector((s) => s.maps)
  const [favorited, setFavorited] = React.useState(isFavorited)
  const [routesList, updateRoutesList] = React.useState([])

  React.useEffect(() => {
    // default to favorited
    const isFavoritedFound = favoritedStops?.find(
      (favoritedStop) => favoritedStop.stop_id === stop.stop_id,
    )
    if (isFavoritedFound) {
      setFavorited(true)
    } else {
      setFavorited(false)
    }
  }, [favoritedStops, stop])

  React.useEffect(() => {
    const routesListArray = []
    stop.routes.forEach((stopRoute) => {
      routes
        .map((route) => {
          if (route.route_id === stopRoute) {
            // return route.long_name
            return routesListArray.push(route.long_name)
          }
          return null
        })
        .filter((item) => item !== null)
    })
    updateRoutesList(routesListArray)
  }, [routes])

  const isValidSegments = () => true

  const viewStopInfo = () => {
    dispatch(handleSearchBarToggle(false))
    if (isValidSegments()) {
      logMapEvent(dispatch, STOPMARKER, stop.stop_name)
      dispatch(handleStopClicked(stop))
    }
  }

  const addToFavorite = (e) => {
    e.stopPropagation()
    let newFavoriteList = []
    setFavorited(!favorited)
    if (favorited) {
      // If already favorited, remove
      newFavoriteList = favoritedStops.filter((s) => s.stop_id !== stop.stop_id)
      // Add stop back to other list

      dispatch(
        removeStopFromFavorite(
          sortListAlphabetically(newFavoriteList, 'stop_name'),
        ),
      )
    } else {
      // To do add building to list
      newFavoriteList = [...favoritedStops]
      newFavoriteList.push(stop)

      dispatch(addStopToFavorite(newFavoriteList))
      //   setFavorited(true)
    }
    dispatch(
      setSelectedFavoritedStops(
        sortListAlphabetically(newFavoriteList, 'stop_name'),
      ),
    )

    storeFavoriteStopsListToFirebase(newFavoriteList.map((s) => s.stop_id))
    logMapEvent(
      dispatch,
      favorited ? STOPSUNFAVORITE : STOPSFAVORITE,
      stop.stop_name,
    )
  }
  return (
    <ListItem
      button
      onClick={viewStopInfo}
      key={stop.stop_id}
      style={style}
      className={classes.list}
    >
      <ListItemIcon style={{ width: '100%' }}>
        <ListItemText
          disableTypography
          primary={
            <Text type="body2" className={classes.busStopText}>
              {stop.stop_name}
            </Text>
          }
          secondary={
            <Text type="body2" className={classes.busStopSecondaryText}>
              {routesList.length > 0 ? routesList.join(', ') : null}
            </Text>
          }
        />
      </ListItemIcon>
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          aria-label="add/remove favorited building"
          onClick={addToFavorite}
        >
          {favorited ? (
            <FavoriteIcon className={classes.favoriteIconSelected} />
          ) : (
            <FavoriteBorderIcon className={classes.iconButtonBorder} />
          )}
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default StopsListComponent
