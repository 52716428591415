import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Paper, Typography } from '@material-ui/core'
import { useBottomSheetParkingStyles } from './BottomSheetParkingStyles'
import {
  handleViewBuildingInfoSheet,
  resetParking,
} from '../../../sections/Buildings/redux/myBuildingsSlice'
import CheckIsMobile from '../../../../../../utils/isMobileHook'
import { updateBottomSheet } from '../../../sections/Map/redux/myMapsSlice'
import { BUILDING } from '../../../utils/constants'

// Still need to update this component to use the BottomSheet
function BottomSheetParking({ setSheetConfig }) {
  const dispatch = useDispatch()
  const isMobile = CheckIsMobile()
  const classes = useBottomSheetParkingStyles()
  const { selectedParkingSpot, nearestParking, selectedBuildingID } =
    useSelector((s) => s.buildings)
  const [parkingList, setParkingList] = React.useState([])
  const colors = [
    '#FFC947', // Bright Yellow
    '#FF8A47', // Coral
    '#FF9B9B', // Light Coral
    '#FFD9D9', // Pale Pink
    '#FFE4B2', // Light Peach
    '#FFDAB9', // Peach
    '#F4D03F', // Mustard
    '#DAF7A6', // Pale Green
    '#A9DF9C', // Light Green
    '#5DADE2', // Light Blue
    '#A3E4D7', // Mint Green
    '#C39BD3', // Light Purple
    '#FF99CC', // Pink
    '#FF6347', // Tomato
    '#7DCEA0', // Greenish Cyan
  ]

  React.useEffect(() => {
    if (selectedParkingSpot) {
      const sheetConfig = {
        title: `Lot: ${selectedParkingSpot.lotName}`,
        sheetColor: '',
        displayBackButton: !isMobile,
        displayCloseButton: true,
        backButtonFunc,
      }
      setSheetConfig(sheetConfig)
      setParkingList(nearestParking.permitLotMap[selectedParkingSpot.lotCd])
    }
  }, [selectedParkingSpot, isMobile])

  const backButtonFunc = {
    myFunction() {
      dispatch(resetParking())
      dispatch(handleViewBuildingInfoSheet(selectedBuildingID))
      dispatch(updateBottomSheet(BUILDING))
    },
  }

  const permitList = parkingList.map((permit, ix) => (
    <Paper
      component="button"
      key={permit.permDescr}
      className={classes.markerBody}
      style={{ backgroundColor: `${colors[ix % colors.length]}` }}
    >
      <Typography variant="h6" className={classes.markerLabel}>
        {permit.permDescr}
      </Typography>
      <Box display="flex" flexDirection="column" width="100%">
        <Typography variant="h12" className={classes.timeLabel}>
          {`(${permit.permStart} - ${permit.permEnd})`}
        </Typography>
        <Typography variant="h12">
          {`Special Permit: ${permit.splPermit} `}
        </Typography>
      </Box>
    </Paper>
  ))

  return (
    <>
      <Box display="flex" className={classes.parkingMarkerContainer}>
        {permitList}
      </Box>
    </>
  )
}

export default BottomSheetParking
