import * as React from 'react'
import { ListSubheader } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { EmptyComponent } from '../../../../utils/helpers'
import BuildingListComponent from '../../../List/BuildingListComponent/BuildingListComponent'
import { fetchBuildingsFavorites } from '../../../../sections/Buildings/redux/myBuildingsSlice'

// Render the building list within the drawer
function BuildingList({ dispatch, classes }) {
  const {
    buildingSubheader,
    selectedCampusBuildings,
    selectedFavoritedBuildings,
  } = useSelector((s) => s.buildings)

  React.useEffect(() => {
    dispatch(fetchBuildingsFavorites())
  }, [])

  const isEmpty = () =>
    selectedCampusBuildings['New Brunswick']?.length +
      selectedCampusBuildings.Newark?.length +
      selectedCampusBuildings.Camden?.length +
      selectedCampusBuildings.Other?.length +
      selectedFavoritedBuildings?.length ===
    0

  return (
    <div
      style={{
        height: 'calc(100vh - 422px)',
        overflow: 'none',
        width: '100%',
        contentVisibility: 'auto',
      }}
    >
      <ListSubheader className={classes.listSubHeader}>
        {buildingSubheader}
      </ListSubheader>
      {!isEmpty() ? (
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              className={classes.fixedSizeList}
              height={height - 38}
              itemCount={
                [
                  ...selectedFavoritedBuildings,
                  ...selectedCampusBuildings['New Brunswick'],
                  ...selectedCampusBuildings.Newark,
                  ...selectedCampusBuildings.Camden,
                  ...selectedCampusBuildings.Other,
                ].length
              }
              itemSize={35}
              width={width}
              useIsScrolling
            >
              {BuildingListComponent}
            </FixedSizeList>
          )}
        </AutoSizer>
      ) : (
        <div className={classes.list}>
          <EmptyComponent />
        </div>
      )}
    </div>
  )
}

export default BuildingList
