import { makeStyles } from '@material-ui/core/styles'

export const useNewsSectionStyles = makeStyles((theme) => ({
  pageContainer: {
    padding: '0px 80px 20px 80px', // Hacky way. Need to find a margin auto alternative
    [theme.breakpoints.down('lg')]: {
      padding: '0 20px 20px 20px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '10px 8px 20px 8px',
    },
  },
  sectionHeader: {
    textTransform: 'capitalize',
    margin: '8px 0',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 600,
    textAlign: 'left',
    color: theme.palette.text.primary,
    fontSize: '39px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
    },
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  newsArticleItemContainer: {
    padding: '0',
  },
}))

export default useNewsSectionStyles
