import { makeStyles } from '@material-ui/core/styles'

export const buildingListComponentStyles = makeStyles((theme) => ({
  appBarIcon: {
    fontSize: 24,
    color: theme.palette.base.white,
  },
  buildingListText: {
    fontSize: 14,
    color: theme.palette.base.red,
    marginLeft: 10,
    marginRight: 10,
  },
  listItemIcon: {
    fontSize: 24,
    color: theme.palette.base.white,
  },
  favoriteIcon: {
    color: '#979797',
    fontSize: 24,
  },
  favoriteIconSelected: {
    color: '#cc0033',
    fontSize: 24,
  },
  newListSection: {
    backgroundColor: 'inherit',
    listStyle: 'none',
  },
  listSubHeader: {
    backgroundColor: theme.palette.paper.primary,
  },
  newUl: {
    backgroundColor: 'inherit',
    padding: 0,
    listStyle: 'none',
  },
  list: {
    overflow: 'none',
  },
  listItemView: {
    overflow: 'none',
    width: 'calc(100vw - 80px)',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100vw - 20px)',
    },
  },
  iconButtonBorder: {
    position: 'relative',
    zIndex: 1000,
    fontSize: '24px',
    color: theme.color.rutgersRed,
  },
}))
