import React from 'react'
import {
  SwipeableDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  ButtonBase,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import FormatShapesIcon from '@material-ui/icons/FormatShapes'
import EventNoteIcon from '@material-ui/icons/EventNote'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant'
import SchoolIcon from '@material-ui/icons/School'
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import WorkIcon from '@material-ui/icons/Work'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import EmailIcon from '@material-ui/icons/Email'
import WebIcon from '@material-ui/icons/Web'
import EventIcon from '@material-ui/icons/Event'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import GroupWorkIcon from '@material-ui/icons/GroupWork'
import FolderSharedIcon from '@material-ui/icons/FolderShared'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import LocalDiningIcon from '@material-ui/icons/LocalDining'
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary'
import PaletteIcon from '@material-ui/icons/Palette'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import HelpIcon from '@material-ui/icons/Help'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
import BookIcon from '@material-ui/icons/Book'
import CommuteIcon from '@material-ui/icons/Commute'
import FavoriteIcon from '@material-ui/icons/Favorite'

import { useSelector, useDispatch } from 'react-redux'
import { Button } from 'semantic-ui-react'
import { glanceMenuStyles } from './GlanceMenuStyles'
import {
  selectWidgets,
  //   fetchDashboard,
} from '../../features/dashboard/dashboardSlice'
// import IconPicker from '../../features/apps/AppComponents/IconPicker/IconPicker'
import {
  selectDrawerIsOpen,
  drawerClose,
  openReorderWidgetDrawer,
  drawerToggle,
} from '../navigationSlice'
import ReorderWidgets from '../sideNav/customButtons/ReorderWidgets'
/**
 * Glance Menu for the mobile version
 * @param width Width that the menu expands to
 * @param height The heigh that the menu will occupy up to
 * @param scrollbarReference The scrollbar used to scroll through the widgets
 */
const GlanceMenu = ({ scrollbarReference }) => {
  const classes = glanceMenuStyles()
  const apiReturnedWidgets = useSelector(selectWidgets)

  const dispatch = useDispatch()
  const drawerOpen = useSelector(selectDrawerIsOpen)

  //   React.useEffect(() => {
  //     dispatch(fetchDashboard())
  //   }, [])

  const scrollTo = (widgetPosition) => {
    scrollbarReference.current.scrollTo(365 * widgetPosition, 0)
    dispatch(drawerClose())
  }

  const handleManageWidgets = () => {
    dispatch(openReorderWidgetDrawer())
  }

  const handleOpenCloseSwipeableDrawer = () => {
    dispatch(drawerToggle())
  }

  const chooseSideNavIcon = (widgetName) => {
    const SideNavIcons = {
      Grade: <FormatShapesIcon className={classes.glanceMenuListItem} />,
      CourseSchedule: <EventNoteIcon className={classes.glanceMenuListItem} />,
      MyProfile: <AccountCircleIcon className={classes.glanceMenuListItem} />,
      MyAlerts: (
        <NotificationImportantIcon className={classes.glanceMenuListItem} />
      ),
      MyDegree: <SchoolIcon className={classes.glanceMenuListItem} />,
      CourseRegistration: (
        <OpenInBrowserIcon className={classes.glanceMenuListItem} />
      ),
      MyMoney: <AttachMoneyIcon className={classes.glanceMenuListItem} />,
      MyFlexibleWork: <WorkIcon className={classes.glanceMenuListItem} />,
      FinancialAid: <NoteAddIcon className={classes.glanceMenuListItem} />,
      MyEmailRUConnect: <EmailIcon className={classes.glanceMenuListItem} />,
      MyEmailScarletMail: <EmailIcon className={classes.glanceMenuListItem} />,
      JobsInternships: <WebIcon className={classes.glanceMenuListItem} />,
      CareerServices: <WorkIcon className={classes.glanceMenuListItem} />,
      CareerServicesEvents: (
        <EventIcon className={classes.glanceMenuListItem} />
      ),
      MyCourseActivity: <CheckBoxIcon className={classes.glanceMenuListItem} />,
      FacultyStaffCenter: (
        <GroupWorkIcon className={classes.glanceMenuListItem} />
      ),
      MyBenefits: <FolderSharedIcon className={classes.glanceMenuListItem} />,
      JobOpportunities: (
        <FolderSharedIcon className={classes.glanceMenuListItem} />
      ),
      MyPaycheck: <AttachMoneyIcon className={classes.glanceMenuListItem} />,
      MyTeachingSchedule: (
        <CalendarTodayIcon className={classes.glanceMenuListItem} />
      ),
      MyExpenses: <AttachMoneyIcon className={classes.glanceMenuListItem} />,
      MyDining: <LocalDiningIcon className={classes.glanceMenuListItem} />,
      LibraryQuickSearch: (
        <LocalLibraryIcon className={classes.glanceMenuListItem} />
      ),
      MasonGross: <PaletteIcon className={classes.glanceMenuListItem} />,
      EnrollmentPathwayRecommended: (
        <CheckCircleOutlineIcon className={classes.glanceMenuListItem} />
      ),
      EnrollmentPathway: (
        <CheckCircleOutlineIcon className={classes.glanceMenuListItem} />
      ),
      RUInfo: <HelpIcon className={classes.glanceMenuListItem} />,
      DidYouKnow: <InfoOutlinedIcon className={classes.glanceMenuListItem} />,
      MyWellness: <LocalHospitalIcon className={classes.glanceMenuListItem} />,
      GradFund: <AttachMoneyIcon className={classes.glanceMenuListItem} />,
      TechGuide: <BookIcon className={classes.glanceMenuListItem} />,
      ITServiceCenter: <HelpIcon className={classes.glanceMenuListItem} />,
      StudentServiceCenter: <HelpIcon className={classes.glanceMenuListItem} />,
      RUDOTSServiceCenter: (
        <CommuteIcon className={classes.glanceMenuListItem} />
      ),
      MyFavorites: <FavoriteIcon className={classes.glanceMenuListItem} />,
    }
    return SideNavIcons[widgetName]
  }

  return (
    <div style={{ position: 'relative' }}>
      <SwipeableDrawer
        anchor="left"
        open={drawerOpen}
        onClose={() => dispatch(drawerClose())}
        onOpen={() => {}}
        className={classes.test}
        style={{ position: 'absolute' }}
      >
        <div className={classes.verticalContainer}>
          <div className={classes.widgetList}>
            <List>
              {apiReturnedWidgets
                .filter((widget) => widget.showOnDashboard)
                .map((widget, index) => (
                  <>
                    <ListItem
                      button
                      key={widget.name}
                      onClick={() => scrollTo(index)}
                      className={classes.glanceMenuListItem}
                    >
                      <ListItemIcon className={classes.centerNoteIcon}>
                        {chooseSideNavIcon(widget.key)}
                      </ListItemIcon>
                      <ListItemText>{widget.title}</ListItemText>
                    </ListItem>
                    <Divider variant="middle" />
                  </>
                ))}
            </List>
          </div>
          <Button
            style={{ width: 200, margin: 15 }}
            variant="outlined"
            onClick={handleManageWidgets}
          >
            Manage Widgets
          </Button>
          <div className={classes.footer}>
            <ReorderWidgets />
          </div>
          <div className={classes.glanceMenuButtonOpen}>
            <ButtonBase onClick={handleOpenCloseSwipeableDrawer}>
              <CloseIcon className={classes.glanceMenuIcon} />
            </ButtonBase>
          </div>
        </div>
      </SwipeableDrawer>
      {!drawerOpen ? (
        <div className={classes.glanceMenuButtonClosed}>
          <ButtonBase onClick={handleOpenCloseSwipeableDrawer}>
            <MenuIcon className={classes.glanceMenuIconClosed} />
          </ButtonBase>
        </div>
      ) : null}
    </div>
  )
}

export default GlanceMenu
