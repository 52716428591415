import * as React from 'react'
import Grid from '@material-ui/core/Grid'
import { useDispatch, useSelector } from 'react-redux'

import Widget from '../Widget'
import { InlineIconLink } from '../../DashboardComponents/IconLinks/IconLink'
import ImageLink from '../../DashboardComponents/ImageLinks/ImageLink'
import SearchBar from '../../DashboardComponents/SearchBar/SearchBar'
import libraryQuickSearchStyles from './libraryQuickSearchStyles'
import {
  fetchlibraryQuickSearch,
  selectlibraryQuickSearchStatus,
  selectlibraryQuickSearchImageLinks,
  selectlibraryQuickSearchIconLinks,
  selectlibraryQuickSearchLink,
} from './libraryQuickSearchSlice'
import Loading from '../helpers/Loading'
import { logDashboardEvent } from '../../events'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'
// eslint-disable-next-line no-unused-vars
function LibraryQuickSearch({
  className,
  title,
  widgetId,
  showNote,
  noteMessage,
  notePosition,
  noteBackground,
  noteIcon,
  noteUrl,
  noteFontColor,
}) {
  const dispatch = useDispatch()
  const widgetCallState = useSelector(selectlibraryQuickSearchStatus)

  React.useEffect(() => {
    dispatch(fetchlibraryQuickSearch())
  }, [])

  return (
    <Widget className={className}>
      <Widget.Header title={title} category="default" />
      <Widget.Content
        showNote={showNote}
        noteMessage={noteMessage}
        notePosition={notePosition}
        noteBackground={noteBackground}
        noteIcon={noteIcon}
        noteUrl={noteUrl}
        noteFontColor={noteFontColor}
      >
        {renderLibraryQuickSearchState(widgetCallState, widgetId, dispatch)}
      </Widget.Content>
    </Widget>
  )
}

function renderLibraryQuickSearchState(widgetCallState, widgetId, dispatch) {
  const classes = libraryQuickSearchStyles()
  // TODO: Handle Proper Error States
  switch (widgetCallState) {
    case 'fulfilled':
      return <Loaded widgetId={widgetId} dispatch={dispatch} />
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage />
        </Grid>
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item className={classes.text}></Grid>
        </Grid>
      )
  }
}

function Loaded({ widgetId, dispatch }) {
  const classes = libraryQuickSearchStyles()
  const imageCardLinks = useSelector(selectlibraryQuickSearchImageLinks)
  const inlineIconLinks = useSelector(selectlibraryQuickSearchIconLinks)
  const searchLink = useSelector(selectlibraryQuickSearchLink)
  const [searchValue, setSearchValue] = React.useState('')

  const handleSearchBar = (val) => {
    setSearchValue(val)
  }

  const cancelSearch = () => {
    setSearchValue('')
  }

  const handleSearch = () => {
    const APIFirstHalf = `${searchLink}query=any,contains,`
    const APISecondHalf =
      '&tab=Everything_except_research&search_scope=MyInst_and_CI_2&vid=01RUT_INST:01RUT&lang=en&offset=0'
    const replaceSearchValueSpace = encodeURIComponent(searchValue)
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardLinkEvent',
        widgetId,
        'search',
        APIFirstHalf.concat(replaceSearchValueSpace).concat(APISecondHalf),
      )
    }
    window.open(
      APIFirstHalf.concat(replaceSearchValueSpace).concat(APISecondHalf),
    )
  }
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="space-evenly"
    >
      <Grid item className={classes.quickSearchImgContainer}>
        <img
          className={classes.quickSearchImg}
          alt="quickSearch"
          src="./assets/images/svgs/quickSearch.svg"
        />
      </Grid>

      <Grid item>
        <SearchBar
          placeholder="Search library resources"
          className={classes.searchBar}
          value={searchValue}
          webViewMode
          onChange={(newVal) => handleSearchBar(newVal.target.value)}
          onCancelSearch={() => cancelSearch()}
          onRequestSearch={() => handleSearch()}
        />
      </Grid>
      <Grid item container direction="row" justifyContent="space-around">
        {inlineIconLinks?.map((iconLink) => (
          <InlineIconLink
            iconLink={iconLink}
            key={iconLink.link + iconLink.iconLabel}
            widgetId={widgetId}
            dispatch={dispatch}
          />
        ))}
      </Grid>
      <Grid item>
        <Grid container justifyContent="space-around" alignItems="center">
          {imageCardLinks?.map((imageLink) => (
            <ImageLink
              imageLink={imageLink}
              classes={classes}
              key={imageLink.link + imageLink.imageName}
              widgetId={widgetId}
              dispatch={dispatch}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LibraryQuickSearch
