/* eslint-disable camelcase */
import React from 'react'
import { Paper, Typography, Box, CircularProgress } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import {
  setSelectedRoute,
  fetchShapesAsGeoJSON,
  fetchVehiclePositions,
} from '../../../Map/redux/myMapsSlice'
import { useRouteItemStyles } from './RouteItemStyles'
import { logMapEvent } from '../../../../utils/events'
import { ROUTEMARKER } from '../../../../utils/constants'
import { getEnhancedStops } from '../../../Map/helpers/helpers'

function RouteItem({ route, loading = false }) {
  const classes = useRouteItemStyles()
  const dispatch = useDispatch()

  const [handleClickRequest, setHandleClickRequest] = React.useState(false)
  const { trips, tripUpdates } = useSelector((s) => s.maps)

  const handleShowRoute = async (e, busRoute) => {
    try {
      setHandleClickRequest(true)

      const { route_id } = busRoute
      const trip_ids = trips[route_id]

      const enhancedStops = await getEnhancedStops(
        trip_ids, // key: route_id, value: list of trip_id's
        route_id,
      )

      dispatch(fetchVehiclePositions()) // Fetch vehicle positions

      const updatedBusRoute = { busRoute, enhancedStops, openSheet: true } // Create a new object that includes the original busRoute properties plus the stops

      dispatch(setSelectedRoute(updatedBusRoute)) // Dispatch the action with the updated busRoute object

      const desiredTripId = tripUpdates.find(
        (
          update, // use the trips map to get a trip_id for the selectedStop.stop_id
        ) =>
          trip_ids.some(
            (targetTripId) => update.tripUpdate.trip.tripId === targetTripId,
          ),
      )

      dispatch(fetchShapesAsGeoJSON(desiredTripId.tripUpdate.trip.tripId)) // Sets up the polyline
      // handleMobile()
      logMapEvent(dispatch, ROUTEMARKER, busRoute.route_long_name)
    } catch (error) {
      console.error('An error occurred while handling the show route:', error)
      // Handle error as needed
    }
    setHandleClickRequest(false)
  }

  const renderArrivalOrLoading = (rte, clss, lding) => {
    if (lding) {
      return (
        <div className={clss.noMatchesContainer}>
          <CircularProgress className={clss.noMatches} />
        </div>
      )
    }

    return (
      <>
        <Typography variant="h4" className={clss.firstRouteNameLabel}>
          <Box fontWeight="bold" display="inline">
            {rte.arrivalTime[0]}{' '}
          </Box>
        </Typography>
        {/* if the arrivalTime is greater than 1 it's safe to show index 1 */}
        {rte.arrivalTime.length > 1 ? (
          <Typography component="div">
            Next{' '}
            <Box fontWeight="bold" display="inline">
              {rte.arrivalTime[1]}
            </Box>
          </Typography>
        ) : null}
      </>
    )
  }

  const renderContent = (rte, clss, handleClickRequestParam, lding) => {
    // If handleClickRequestParam exists, return early with specific content.
    if (handleClickRequestParam) {
      return (
        <Box className={`${clss.commonBox} ${clss.contentValue}`}>
          {renderArrivalOrLoading(rte, clss, handleClickRequestParam)}
        </Box>
      )
    }

    // If rte.arrivalTime exists, return early with specific content.
    if (rte.arrivalTime) {
      return (
        <Box className={`${clss.commonBox} ${clss.contentValue}`}>
          {renderArrivalOrLoading(rte, clss, lding)}
        </Box>
      )
    }

    // Default return for 'Route not active'.
    return (
      <Box className={clss.commonBox}>
        <Typography variant="h6" className={clss.routeNameLabelNone}>
          Route not active.
        </Typography>
      </Box>
    )
  }

  return (
    <Paper
      component="button"
      disabled={!route.arrivalTime}
      onClick={(e) => handleShowRoute(e, route)}
      key={route.long_name}
      className={`${classes.routeMarker} ${
        !route.arrivalTime ? classes.routeMarkerInactive : ''
      }`}
      style={{
        background: route.route_color,
      }}
    >
      <Box className={classes.routeNameContainer}>
        <Typography variant="body1" noWrap className={classes.routeNameLabel}>
          {route.route_long_name}
        </Typography>
      </Box>

      {renderContent(route, classes, handleClickRequest, loading)}
    </Paper>
  )
}
export default RouteItem
