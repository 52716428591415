import React from 'react'
import { Box, Typography as Text } from '@material-ui/core'
import IconPicker from '../../../apps/AppComponents/IconPicker/IconPicker'
import { uiTextContent } from './data/policyParams'

const NoItemFound = ({ classes }) => (
  <Box className={classes.noItemFoundContainer}>
    <IconPicker iconName="fa-regular fa-folder-open" color="gray" size="4x" />
    <Box className={classes.noItemFoundText}>
      <Text variant="h6">{uiTextContent.NO_ANNOUNCEMENTS}</Text>
      <Text variant="h6">{uiTextContent.NO_ANNOUNCEMENTS2}</Text>
    </Box>
  </Box>
)

export default NoItemFound
