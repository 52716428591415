import React from 'react'

import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Tooltip from '@material-ui/core/Tooltip'

import IconPicker from '../../../../apps/AppComponents/IconPicker/IconPicker'
import { logDashboardEvent } from '../../../events'

function ApplyTab({ widgetData, widgetId, classes, dispatch }) {
  const handleIconClick = (link) => {
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardEvent',
        widgetId,
        link.title,
        link.url,
      )
    }
    window.open(link.url, '_blank')
  }

  const handleNeedAssistanceClick = () => {
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardEvent',
        widgetId,
        'Need Assistance',
        'https://financialaid.rutgers.edu/contact/',
      )
    }
    window.open('https://financialaid.rutgers.edu/contact/', '_blank')
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="space-between"
      className={classes.viewGrid}
      wrap="nowrap"
    >
      <Grid item>
        <Card className={classes.schoolCodeCard} variant="outlined">
          <Typography variant="body1">
            Rutgers Federal School Code: 002629
          </Typography>
        </Card>
      </Grid>
      <Grid
        item
        container
        direction="row"
        alignItems="stretch"
        justifyContent="center"
        className={classes.buttonGrid}
      >
        {widgetData.applyTab.links.map((link, index) => (
          <Grid
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            item
            xs={4}
            className={classes.gridButton}
            onClick={() => handleIconClick(link)}
          >
            <Tooltip title={link.title}>
              <Grid container direction="column" alignItems="center">
                <IconPicker iconName={link.icon} color="gray" size="3x" />
                <Typography variant="subtitle1">{link.emphasis}</Typography>
              </Grid>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
      <Grid item>
        <Card
          className={classes.assistanceCard}
          variant="outlined"
          onClick={handleNeedAssistanceClick}
        >
          <Grid container direction="row" spacing={1} justifyContent="center">
            <Grid item>
              <IconPicker iconName="phone" size="1x" color="gray" />
            </Grid>
            <Grid item>
              <Typography variant="body1">Need Assistance?</Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}

export default ApplyTab
