import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles(() => ({
  smallIcon: {
    marginRight: '5px',
    width: '40px',
  },
  clickable: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

export default styles
