import { collection, doc, updateDoc } from 'firebase/firestore'
import { firestore } from '../../../../../../utils/firebase'

export const storeFavoriteBuldingListToFirebase = (newFavoritesList) => {
  const firebaseID = localStorage.getItem('user') ?? 'visitor'
  const favoritesDocRef = doc(
    collection(firestore, 'users'),
    firebaseID,
    'settings',
    'favorites',
  )
  // Store this new list to firebase
  updateDoc(favoritesDocRef, { buildings: newFavoritesList })
}
