import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slide,
} from '@material-ui/core'

import FormatListBulleted from '@material-ui/icons/FormatListBulleted'
import useNewsSectionReorderStyles from './ReorderNewsSectionsStyles'
import { setNews, setOpenNewsReorderDialog } from '../../newsSlice'
import ReorderableNewsSection from './ReorderableNewsSection'
import { extractSectionsFromNewsList, setSectionsOrder } from '../../helpers'
function ReorderNewsSections() {
  const dispatch = useDispatch()
  const classes = useNewsSectionReorderStyles()
  const openDialog = useSelector((s) => s.news.newsReorderDialogOpen)

  const setOpen = () => {
    if (openDialog) {
      dispatch(setOpenNewsReorderDialog(false))
    } else {
      dispatch(setOpenNewsReorderDialog(true))
    }
  }

  return (
    <>
      <ListItem button onClick={() => setOpen()}>
        <ListItemIcon>
          <FormatListBulleted className={classes.listIconStyle} />
        </ListItemIcon>
        <ListItemText>Reorder News Sections</ListItemText>
      </ListItem>
      <ReorderNewsSectionDialog
        openDialog={openDialog}
        setOpen={setOpen}
        dispatch={dispatch}
        classes={classes}
      />
    </>
  )
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" {...props} ref={ref} />
})

function ReorderNewsSectionDialog({ openDialog, setOpen, dispatch, classes }) {
  const news = useSelector((s) => s.news.news)
  const [newsSections, setNewsSections] = React.useState([])
  const [edited, setEdited] = React.useState(false)

  React.useEffect(() => {
    const mapped = Object.entries(news)
      .sort(([, a], [, b]) => a.order - b.order)
      .map(([, value]) => value)
    setNewsSections(mapped)
  }, [news])

  const updateOrder = () => {
    const updatedNewsSections = newsSections.map((item, index) => ({
      ...item,
      order: index,
    }))

    setSectionsOrder(extractSectionsFromNewsList(updatedNewsSections))
    dispatch(setNews(updatedNewsSections))
    setOpen()
    setEdited(false)
  }

  return (
    <Dialog
      open={openDialog}
      onClose={() => setOpen()}
      TransitionComponent={Transition}
    >
      <DialogTitle>Drag to Reorder News Sections</DialogTitle>
      <DialogContent dividers className={classes.dialog}>
        <ReorderableNewsSection
          newsSections={newsSections}
          setEdited={setEdited}
          setNewsSections={setNewsSections}
        />
      </DialogContent>
      <DialogActions>
        <Grid container directin="row" justifyContent="space-between">
          <Button onClick={setOpen} variant="outlined" color="primary">
            Cancel
          </Button>

          <Button
            onClick={updateOrder}
            variant="outlined"
            disabled={!edited}
            color="primary"
          >
            Set Order
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default ReorderNewsSections
