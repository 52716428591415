import { makeStyles } from '@material-ui/core/styles'

const useWidgetFormStyles = makeStyles(() => ({
  root: {
    height: 435,
    margin: '8px 16px 8px 16px',
  },
  widgetFormContainer: {
    margin: '16px 16px 8px 16px',
    overflowY: 'auto',
  },
  advancedWidgetFormContainer: {
    margin: '16px 16px 8px 16px',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
    marginBottom: 8,
    flexShrink: 0,
  },
  rowItem: {
    flex: 1,
  },
  singleRowContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '0px 0px 2px 0px',
  },
  textField: {
    width: '80%',
    flex: 1,
    height: 32,
    margin: '0px 0px 6px 0px',
  },
  textFieldInputProps: {
    height: 32,
  },
  textFieldInputPropsWidgetForm: {
    height: 32,
    width: 300,
  },
  formTitle: {
    fontWeight: 500,
    margin: '0px 0px 1px 0px',
  },
  advancedFieldText: {
    fontWeight: 800,
  },
}))

export default useWidgetFormStyles
