import React from 'react'
import { Typography, Box, ButtonBase } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { camdenEngageBuildingStyles } from './camdenEngageBuildingStyles'
import IconPicker from '../../../../apps/AppComponents/IconPicker/IconPicker'

function BottomSheetCamdenEngageBuilding({ setSheetConfig }) {
  const classes = camdenEngageBuildingStyles()

  const { event, address, venueWebsite, imageUrl } = useSelector(
    (s) => s.buildings.selectedBuilding,
  )

  React.useEffect(() => {
    const sheetConfig = {
      displayBackButton: false,
      displayCloseButton: true,
    }
    setSheetConfig(sheetConfig)
  }, [])

  return (
    <Box display="flex" className={classes.bottomSheetContainer}>
      <div className={classes.descriptionTextContainer}>
        <Box className={classes.descriptionImageContainer}>
          <ButtonBase
            component="a"
            href={venueWebsite}
            target="_blank"
            alt={event}
          >
            <img
              className={classes.descriptionImage}
              src={imageUrl}
              alt={event}
            />
          </ButtonBase>
        </Box>
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            alignItems="center"
            marginBottom="10px"
            marginTop="10px"
          >
            <Box marginRight="10px">
              <IconPicker
                iconName="fas fa-calendar"
                color="#cc0033"
                size="2x"
              />
            </Box>
            <Typography className={classes.descriptionText}>{event}</Typography>
          </Box>
          <Box display="flex" alignItems="center" marginBottom="10px">
            <Box marginRight="10px">
              <IconPicker
                iconName="fas fa-building"
                color="#00643c"
                size="2x"
              />
            </Box>
            <Typography className={classes.descriptionText}>
              {address}
            </Typography>
          </Box>
          <Typography
            href={venueWebsite}
            target="_blank"
            component="a"
            className={classes.websiteUrl}
          >
            {venueWebsite}
          </Typography>
        </Box>
      </div>
    </Box>
  )
}

export default BottomSheetCamdenEngageBuilding
