/* eslint-disable no-debugger */
/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import dayjs from 'dayjs'
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

// dont leave here
function getCookie(name) {
  let value = ` ${document.cookie}`
  let start = value.indexOf(` ${name}=`)
  if (start === -1) {
    value = null
  } else {
    start = value.indexOf('=', start) + 1
    let end = value.indexOf(';', start)
    if (end === -1) {
      end = value.length
    }
    value = unescape(value.substring(start, end))
  }
  return value
}

const initialState = {
  title: 'My Campus Pass',
  tabs: [
    {
      title: 'Self-Screen',
      content: [
        'Any student, staff, or faculty who will be visiting Rutgers is expected to get a Campus Pass by completing the following self-screening survey.',
        'Your Campus Pass is valid for the day.',
      ],
      image: 'rutgers_campus_pass.png',
      passLink:
        'https://rutgers.service-now.com/rtr?id=health_screen_survey&language=undefined',
      additionalLink: 'https://coronavirus.rutgers.edu',
    },
    {
      title: 'Vaccine',
      image: 'vaccine.png',
      passLinkImage: 'vaccine_icon.png',
      passLink: 'https://rtr.ipo.rutgers.edu/vaccines/',
      additionalLink: 'https://coronavirus.rutgers.edu',
    },
  ],
  status: 'idle',
  value: 0,
  vaccineData: [],
  vaccineStatus: 'idle',
}

const handleConvertToEST = (surveyDate) => {
  // eslint-disable-next-line prefer-template
  const updatedDate = surveyDate.replace(' ', 'T')
  return dayjs(updatedDate)
    .utc('z')
    .local()
    .tz('America/New_York')
    .format('LLL')
}

const handleSurveyTime = (createdTime) => {
  // current date check
  if (createdTime) {
    const updatedCreatedDate = createdTime.replace(' ', 'T')
    const currentSurvey = new Date(updatedCreatedDate)
    const now = new Date()
    const nowTime = now.getTime()
    const currentSurveyHoursTime = currentSurvey.getTime()
    let timeDiff
    if (currentSurveyHoursTime < nowTime) {
      timeDiff = nowTime - currentSurveyHoursTime
    } else {
      timeDiff = currentSurveyHoursTime - nowTime
    }
    const milliSeconds = timeDiff
    const hours = Math.floor(milliSeconds / 1000 / 60 / 60)
    if (Math.abs(hours) >= 12) {
      return true
    }
  }
  return false
}

export const fetchCampusPass = createAsyncThunk('fetchCampusPass', async () => {
  const jwtToken = localStorage.getItem('myrJwtToken')
  const rcpid = localStorage.getItem('rcpid')
  const res = await axios.get(process.env.REACT_APP_CAMPUSPASS_ENDPOINT, {
    headers: {
      JWT: jwtToken,
      XWT: rcpid,
    },
  })
  let locationsArray = []
  let locationsString = ''
  let convertedDate = ''
  let showUpdateSurvey = false
  if (res.data.campusPassSurveyResult) {
    showUpdateSurvey = handleSurveyTime(
      res.data.campusPassSurveyResult.result.sys_created_on,
    )
    if (res.data.campusPassSurveyResult.result.locations) {
      convertedDate = handleConvertToEST(
        res.data.campusPassSurveyResult.result.sys_created_on,
      )
      locationsArray =
        res.data.campusPassSurveyResult.result.locations.split(',')
      locationsString = res.data.campusPassSurveyResult.result.locations
      if (locationsArray.length > 3) {
        locationsString = `${locationsArray.slice(0, 3).join()} etc...`
      }
    }
  }
  let counter = 1
  if (res.data.screenResults) {
    for (let i = 0; i < res.data.screenResults.vaccine.doses.length; i++) {
      if (res.data.screenResults.vaccine.doses[i - 1]) {
        if (
          res.data.screenResults.vaccine.doses[i].manufacturer ===
          res.data.screenResults.vaccine.doses[i - 1].manufacturer
        ) {
          counter += 1
          res.data.screenResults.vaccine.doses[i].doseIndex = counter
        } else {
          counter = 0
          counter += 1
          res.data.screenResults.vaccine.doses[i].doseIndex = counter
        }
      } else {
        res.data.screenResults.vaccine.doses[i].doseIndex = 1
      }
    }
  }
  return {
    ...res.data,
    convertedDate,
    showUpdateSurvey,
    locationsString,
    vaccineData: res.data.screenResults,
  }
})

export const fetchVaccineStatus = createAsyncThunk(
  'fetchVaccineStatus',
  async () => {
    const res = await axios.get(process.env.REACT_APP_VACCINESTATUS_ENDPOINT, {
      headers: {
        NetID: getCookie('loginNetId'),
      },
    })
    let counter = 1
    if (res.data) {
      for (let i = 0; i < res.data.vaccine.doses.length; i++) {
        if (res.data.vaccine.doses[i - 1]) {
          if (
            res.data.vaccine.doses[i].manufacturer ===
            res.data.vaccine.doses[i - 1].manufacturer
          ) {
            counter += 1
            res.data.vaccine.doses[i].doseIndex = counter
          } else {
            counter = 0
            counter += 1
            res.data.vaccine.doses[i].doseIndex = counter
          }
        } else {
          res.data.vaccine.doses[i].doseIndex = 1
        }
      }
    }
    return res.data
  },
)

export const campusPassSlice = createSlice({
  name: 'campusPassWidget',
  initialState,
  reducers: {
    updateTabValue: (state, action) => {
      if (state.value !== action.payload) {
        state.value = action.payload
      }
    },
  },
  extraReducers: {
    [fetchCampusPass.rejected]: (state) => {
      state.status = 'error'
      state.vaccineStatus = 'error'
    },
    [fetchCampusPass.pending]: (state) => {
      state.status = 'pending'
      state.vaccineStatus = 'pending'
    },
    [fetchCampusPass.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.vaccineStatus = 'fulfilled'
      state.result = action.payload.campusPassSurveyResult?.result
      state.locationsString = action.payload.locationsString
      state.convertedPreviousDate = action.payload.convertedDate
      state.showUpdateSurvey = action.payload.showUpdateSurvey
      state.vaccineData = action.payload.screenResults
      if (
        (state.vaccineData.vaccine_status === 'C' ||
          (state.result && state.result.significant_exposure === 'No')) &&
        state.result &&
        state.result.access_granted === true
      ) {
        state.isCleared = true
      } else {
        state.isCleared = false
      }
    },
    [fetchVaccineStatus.rejected]: (state) => {
      state.vaccineStatus = 'error'
    },
    [fetchVaccineStatus.pending]: (state) => {
      state.vaccineStatus = 'pending'
    },
    [fetchVaccineStatus.fulfilled]: (state, action) => {
      state.vaccineStatus = 'fulfilled'
      state.vaccineData = action.payload
      if (
        (state.vaccineData.vaccine_status === 'C' ||
          (state.result && state.result.significant_exposure === 'No')) &&
        state.result &&
        state.result.access_granted === true
      ) {
        state.isCleared = true
      } else {
        state.isCleared = false
      }
    },
  },
})

export const { updateTabValue } = campusPassSlice.actions

export const selectCampusPassTitle = (state) => state.campusPass.title
export const selectCampusPassTabs = (state) => state.campusPass.tabs
export const selectCampusPassStatus = (state) => state.campusPass.status

export default campusPassSlice.reducer
