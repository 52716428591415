import { makeStyles } from '@material-ui/core/styles'

export const useBottomSheetSearchTabs = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.backgroundColor.secondary,
    borderStartEndRadius: 32,
    borderTopLeftRadius: 32,
    width: '100%',
  },
  rootDark: {
    backgroundColor: theme.palette.backgroundColor.secondary,
    borderStartEndRadius: 32,
    borderTopLeftRadius: 32,
  },
  selectedTab: {
    color: theme.palette.base.red,
  },
  activeTab: {
    color: theme.palette.base.red,
    background: theme.palette.paper.primary,
    minWidth: 30,
    width: 30,
    paddingLeft: 0,
    paddingRight: 0,
  },
  inactiveTab: {
    background: theme.palette.paper.secondary,
    minWidth: 30,
    paddingLeft: 0,
    paddingRight: 0,
  },
  tabs: {
    borderStartEndRadius: 32,
    borderTopLeftRadius: 32,
    [theme.breakpoints.down('sm')]: {
      borderStartEndRadius: 0,
      borderTopLeftRadius: 0,
    },
  },
}))
