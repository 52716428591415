import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) => ({
  ringContainer: {
    width: 65,
    height: 65,
    borderRadius: '50%',
    boxShadow: '0 0 0 4px #A4A4A4',
  },
  visibilityOff: {
    paddingTop: 17,
    fontSize: 50,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  labelText: {
    color: theme.palette.primary.headerText,
  },
  dataContainer: {
    height: '100%',
  },
  valueHover: {
    fontSize: '18px',
    fontWeight: 600,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

export default styles
