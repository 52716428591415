const courseRegistrationTutorial = [
  {
    title: 'Course Registration Tutorial',
    description:
      'The Course Registration Widget provides you with a quick way to search for classes based on campus and semester.',
    top: {
      height: '100%',
      width: '100%',
      left: '0',
    },
    right: {
      height: '0',
      width: '0',
    },
    bottom: {
      height: '0',
      width: '0',
    },
    left: {
      height: '0',
      width: '0',
    },
    popover: {
      top: '200px',
      left: '30px',
    },
  },
  {
    title: 'Registration Search',
    description: `You can search for classes by keyword. Additionally, there are filter options for campus and semester.`,
    top: {
      height: '0',
      width: '0',
      left: '0',
    },
    right: {
      height: '0',
      width: '0',
    },
    bottom: {
      height: '220px',
      width: '100%',
    },
    left: {
      height: '0',
      width: '0',
    },
    popover: {
      top: '295px',
      left: '25px',
    },
  },
  {
    title: 'Course Schedule Planner',
    description: `A link view your course schedule planner.`,
    top: {
      height: '265px',
      width: '60px',
      left: '60px',
    },
    right: {
      height: '340px',
      width: '215px',
    },
    bottom: {
      height: '135px',
      width: '100%',
    },
    left: {
      height: '340px',
      width: '60px',
    },
    popover: {
      top: '100px',
      left: '25px',
    },
  },
  {
    title: 'Web Registration',
    description: `A link to the web registration page.`,
    top: {
      height: '265px',
      width: '67px',
      left: '215px',
    },
    right: {
      height: '345px',
      width: '53px',
    },
    bottom: {
      height: '130px',
      width: '100%',
    },
    left: {
      height: '345px',
      width: '215px',
    },
    popover: {
      top: '100px',
      left: '25px',
    },
  },
  {
    title: 'Schedule of Courses',
    description: `A link to your schedule of classes page.`,
    top: {
      height: '345px',
      width: '75px',
      left: '50px',
    },
    right: {
      height: '415px',
      width: '210px',
    },
    bottom: {
      height: '60px',
      width: '100%',
    },
    left: {
      height: '415px',
      width: '50px',
    },
    popover: {
      top: '180px',
      left: '25px',
    },
  },
  {
    title: 'Bookstore',
    description: `A link to the bookstore page.`,
    top: {
      height: '345px',
      width: '58px',
      left: '217px',
    },
    right: {
      height: '415px',
      width: '60px',
    },
    bottom: {
      height: '60px',
      width: '100%',
    },
    left: {
      height: '415px',
      width: '217px',
    },
    popover: {
      top: '180px',
      left: '25px',
    },
  },
]

export default courseRegistrationTutorial
