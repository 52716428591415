import { makeStyles } from '@material-ui/core'

export const styles = makeStyles((theme) => ({
  container: {
    padding: 0,
    margin: 0,
    height: 470,
    width: 335,
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.breakpoints.down(370)]: {
      width: 287,
      height: 450,
    },
  },
  emailTabContent: {
    paddingTop: '5px',
  },
  emailButton: {
    width: '-webkit-fill-available',
  },
  tabScroll: {
    overflowY: 'auto',
    height: '430px',
  },
  tabContain: {
    overflowY: 'hidden',
    height: '430px',
  },
  collapse: {
    height: 'auto',
    flexShrink: '0',
  },
  eventIconDot: {
    fontSize: '12px',
    transform: 'scale(1) translate(-50%, 50%)',
    color: theme.palette.base.red,
  },
  modalTabs: {
    width: '335px',
    height: '35px',
    minHeight: '35px',
    [theme.breakpoints.down(370)]: {
      width: '300px',
    },
  },
  moveCalendar: {
    width: '335px',
    height: '100%',
    marginTop: '-100px',
    zIndex: '0',
  },
  eventsHolder: {
    padding: '0px 10px',
  },
  eventScroll: {
    transition: 'all 1s ease',
    zIndex: '1',
  },
  dayView: {
    transition: 'all 1s ease',
    zIndex: '1',
    transform: 'translate(0, 10px)',
  },
  fullWidth: {
    width: '-webkit-fill-available',
  },
  badge: {
    color: theme.palette.base.red,
  },
  icon: {
    color: theme.palette.base.gray,
    fontSize: 40,
    '&:hover': {
      transform: 'scale(1.07)',
    },
  },
  headerText: {
    paddingLeft: '7px',
  },
  monthAndYear: {
    width: 'min-content',
    paddingBottom: '8px !important',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '6px !important',
    },
  },
  bellIcon: {
    color: theme.palette.base.red,
    fontSize: theme.typography.h2.fontSize,
    marginBottom: '-28px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '-28px',
    },
  },
  dayOverlay: {
    color: theme.palette.base.white,
  },
  dayOverlayHolder: {
    width: 'min-content',
    paddingBottom: '18px !important',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '12px !important',
    },
  },
  eventsCard: {
    margin: '5px',
  },
  eventHeader: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  calendarDivider: {
    width: '-webkit-fill-available',
    zIndex: '1',
  },
  eventCard: {
    padding: '5px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  emailAndCalendarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  emailAndCalendarContainerColor: {
    width: '90%',
    background: theme.palette.base.green,
    padding: 10,
    marginTop: 20,
    borderRadius: '0.5rem',
  },
  googleSignInContainer: {
    width: '90%',
    margin: 'center',
    marginTop: 20,
  },
  emailAndCalendarText: {
    color: theme.palette.base.white,
  },
  conferenceButton: {
    wordWrap: 'break-word',
    marginBottom: 10,
    '&:hover': {
      color: theme.palette.base.white,
    },
  },
}))

export default styles
