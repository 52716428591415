/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */

import React from 'react'
import uuid from 'react-uuid'
import _ from 'lodash'
import { Typography, CircularProgress } from '@material-ui/core'
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus'

import { useDispatch, useSelector } from 'react-redux'
import { bottomSheetStopInfoStyles } from './bottomSheetStopInfoStyles'
import { getEnhancedStops } from '../../../sections/Map/helpers/helpers'
import { logMapEvent } from '../../../utils/events'
import { handleVehicleClicked } from '../../../sections/Map/redux/myMapsSlice'
import { VEHICLEMARKER } from '../../../utils/constants'

function BottomSheetStopInfo({ setSheetConfig }) {
  const classes = bottomSheetStopInfoStyles()
  const dispatch = useDispatch()
  const { trips, vehiclesForStop, selectedStop, selectedRoute } = useSelector(
    (s) => s.maps,
  )

  const [currentSelectedStop, setCurrentSelectedStop] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const [stopsToShow, setStopsToShow] = React.useState({})
  const [arrivalTime, setArrivalTime] = React.useState('')
  const [vehicleLabel, setVehicleLabel] = React.useState('')
  const [firstArrival, setFirstArrival] = React.useState('')
  const [arrivalsState, setArrivalsState] = React.useState('')

  React.useEffect(() => {
    const fetchEnhancedStopsAndUpdate = async () => {
      if (!_.isEmpty(selectedStop) && !_.isEmpty(selectedRoute)) {
        setLoading(true)
        const sheetConfig = {
          title: selectedStop.stop_name,
          sheetColor: selectedRoute.route_color,
          displayBackButton: true,
          displayCloseButton: true,
          headingColor: selectedRoute.route_text_color,
        }
        setSheetConfig(sheetConfig)

        const { route_id } = selectedRoute
        const trip_ids = trips[route_id]

        // Fetch stops by route ID
        const newEnhancedStops = await getEnhancedStops(trip_ids, route_id)

        const stop = newEnhancedStops.find(
          (s) => s.stop_id === selectedStop.stop_id,
        )

        // Extract the first arrival object
        const first_arrival = stop?.arrivals ? stop?.arrivals[0] : {}
        setFirstArrival(first_arrival)
        // Extract the arrival time and vehicle label
        const arrival_time = first_arrival?.arrivalTime || ''
        const vehicle_label = first_arrival?.vehicle?.vehicle?.label || ''

        setArrivalTime(arrival_time)
        setVehicleLabel(vehicle_label)
        const arrivals = stop?.arrivals ?? []

        setArrivalsState(arrivals)
        const idealArrivalLengthsToShow = 6
        const stops_to_show = Math.min(
          arrivals.length,
          idealArrivalLengthsToShow,
        )
        setStopsToShow(stops_to_show)
        setCurrentSelectedStop(stop)
        setLoading(false)
      }
    }

    // Fetch and update enhanced stops immediately when the effect runs
    fetchEnhancedStopsAndUpdate()

    // Set an interval to fetch and update enhanced stops every 15 seconds
    const intervalId = setInterval(fetchEnhancedStopsAndUpdate, 10000)

    // Clear the interval when the component is unmounted or when either selectedStop or selectedRoute changes
    return () => clearInterval(intervalId)
  }, [selectedStop, selectedRoute])

  const selectVehicle = (vehicleToFind) => {
    if (vehicleToFind) {
      // Find the matching vehicle from vehiclesForStop based on some property, like 'id' or 'label'
      const selectedVehicle = vehiclesForStop.find(
        (vehicle) => vehicle.vehicle.id === vehicleToFind.vehicle.id,
      )
      const vehicleId = selectedVehicle.vehicle.id

      logMapEvent(dispatch, VEHICLEMARKER, vehicleId)
      dispatch(handleVehicleClicked(selectedVehicle))
    }
  }

  return (
    <RenderContent
      loading={loading}
      currentSelectedStop={currentSelectedStop}
      vehicleLabel={vehicleLabel}
      arrivalTime={arrivalTime}
      selectVehicle={selectVehicle}
      firstArrival={firstArrival}
      selectedRoute={selectedRoute}
      stopsToShow={stopsToShow}
      arrivalsState={arrivalsState}
      classes={classes}
    />
  )
}

const RenderContent = ({
  loading,
  currentSelectedStop,
  vehicleLabel,
  arrivalTime,
  selectVehicle,
  firstArrival,
  selectedRoute,
  stopsToShow,
  arrivalsState,
  classes,
}) => (
  <div className={classes.centerContent}>
    {loading ? (
      <CircularProgress style={{ color: '#cc0033' }} />
    ) : currentSelectedStop?.arrivals ? (
      <>
        <Typography className={classes.header}>
          <DirectionsBusIcon
            className={classes.directionBusIcon}
            style={{
              color: selectedRoute?.route_color,
            }}
          />
          {'Estimated Arrival: '}
        </Typography>
        <Typography variant="h4" className={classes.firstStopNameLabel}>
          <GenerateFirstArrivalDisplayText
            vehicleLabel={vehicleLabel}
            arrivalTime={arrivalTime}
            selectVehicle={selectVehicle}
            firstArrival={firstArrival}
          />
        </Typography>
        {currentSelectedStop?.arrivals?.length > 1 && (
          <GetArrivalText
            currentSelectedStop={currentSelectedStop}
            selectVehicle={selectVehicle}
            selectedArrivalTimes={arrivalsState?.slice(1, stopsToShow)}
            classes={classes}
          />
        )}
      </>
    ) : (
      <Typography variant="body1" className={classes.stopNameLabel}>
        N/A
      </Typography>
    )}
  </div>
)

// Function to display upcoming arrival times
const GetArrivalText = ({ selectVehicle, selectedArrivalTimes, classes }) => (
  <Typography variant="body1" className={classes.stopNameLabel}>
    Next{' '}
    {selectedArrivalTimes?.length > 0
      ? selectedArrivalTimes.map((arrivalObj, index) => (
          <span key={uuid()}>
            <b>{arrivalObj.arrivalTime}</b>
            {arrivalObj.vehicle?.vehicle?.label && (
              <span>
                {' (#'}
                <span
                  role="button"
                  tabIndex="0"
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => selectVehicle(arrivalObj.vehicle)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      selectVehicle(arrivalObj.vehicle)
                    }
                  }}
                >
                  {arrivalObj.vehicle?.vehicle?.label}
                </span>
                )
              </span>
            )}
            {/* Add a comma if this isn't the last element */}
            {index < selectedArrivalTimes.length - 1 ? ', ' : ''}
          </span>
        ))
      : null}
  </Typography>
)

// Function to generate first arrival display text
const GenerateFirstArrivalDisplayText = ({
  arrivalTime,
  firstArrival,
  vehicleLabel,
  selectVehicle,
}) => (
  <>
    <b>{arrivalTime}</b>
    {vehicleLabel && (
      <span>
        {' (#'}
        <span
          role="button"
          tabIndex="0"
          style={{
            fontWeight: 'normal',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={() => selectVehicle(firstArrival.vehicle)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              selectVehicle(firstArrival.vehicle)
            }
          }}
        >
          {vehicleLabel}
        </span>
        )
      </span>
    )}
  </>
)

export default BottomSheetStopInfo
