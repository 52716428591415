import * as React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Typography as Text } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import FavoriteIcon from '@material-ui/icons/Favorite'
import Box from '@material-ui/core/Box'
import MyFavoriteStyles from './myFavoriteStyles'

export const MyFavoritesEmptyComponent = () => {
  const classes = MyFavoriteStyles()
  const history = useHistory()
  return (
    <>
      <Box className={classes.noMatchesContainter}>
        <Text variant="subtitle1">You have no favorites!</Text>
        <Button
          variant="outlined"
          className={classes.addToFavoritesButton}
          onClick={() => history.push('/apps?myAppsTab=forYou')}
        >
          <AddIcon className={classes.goToAppsButtonIcon} />
          Add to favorites
        </Button>
        <Box className={classes.noMatchesIconText}>
          <Text variant="subtitle2">
            Just click on <u>Add to favorites</u> and press the
          </Text>
          <FavoriteIcon className={classes.inTextIcon} />
          <Text variant="subtitle2">icon</Text>
        </Box>
        <Text variant="subtitle2">
          next to any application to add it to the favorites list.
        </Text>
      </Box>
    </>
  )
}
