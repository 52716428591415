import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) => ({
  container: {
    padding: 0,
    margin: 0,
    height: 470,
    width: 335,
    overflowY: 'hidden',
    overflowX: 'hidden',
    [theme.breakpoints.down(370)]: {
      width: 287,
      height: 450,
    },
  },
  rootContainer: {
    marginTop: '7px',
    display: 'flex',
    width: '100%',
    minHeight: 35,
    height: 35,
  },
  generalRow: {
    minHeight: '50px',
    padding: '0px 5px',
  },
  adaptiveRow: {
    minHeight: '50px',
    padding: '0px 5px 0px 30px',
  },
  iconBox: {
    marginRight: '10px',
  },
  gradeButton: {
    backgroundColor: theme.palette.base.blue,
    color: theme.palette.base.white,
  },
  red: {
    backgroundColor: theme.palette.base.red,
    color: theme.palette.base.white,
  },
  yellow: {
    backgroundColor: theme.palette.base.yellow,
    color: theme.palette.base.white,
  },
  green: {
    backgroundColor: theme.palette.base.green,
    color: theme.palette.base.white,
  },
  buttonText: {
    padding: '0px 5px',
    color: theme.palette.base.white,
  },
  accordionHolder: {
    marginTop: '5px',
    marginLeft: '5px',
    height: 'calc(100% - 72px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  root: {
    width: '100%',
  },
  accordionDetails: {
    padding: '0px 5px 5px',
    borderRadius: '0px',
  },
  accordion: {
    backgroundColor: theme.palette.backgroundColor.ternary,
  },
  activityCard: {
    backgroundColor: theme.palette.backgroundColor.ternary,
  },
  todoTitle: {
    color: theme.palette.primary.headerText,
    '&:hover': {
      color: theme.palette.primary.headerText,
    },
  },
}))

export default styles
