import { makeStyles } from '@material-ui/core/styles'

export const useFilterSelectorStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    marginBottom: 4,
    marginTop: 4,
  },
  filterContainer: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  filterCard: {
    padding: '5px',
    margin: '4px 1px 4px 1px',
    whiteSpace: 'nowrap',
  },
  filterCardSelected: {
    padding: '5px',
    margin: '4px 1px 4px 1px',
    background: theme.color.rutgersRed,
    color: theme.color.white,
    whiteSpace: 'nowrap',
  },
  filterCardText: {
    textTransform: 'capitalize',
    padding: '5px',
  },
  imageListItem: { height: 'auto', width: 'auto', margin: '0 2px 0 2px' },
}))

export default useFilterSelectorStyles
