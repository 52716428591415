import * as React from 'react'
import { useDispatch } from 'react-redux'
import BottomSheetSearchTabs from '../BottomSheetSearchTabs/BottomSheetSearchTabs'
import BusList from './components/BusList'
import StopsList from './components/StopsList'
import BuildingList from './components/BuildingList'
import { BusDrawerStyles } from './BottomSheetSearchStyles'

const BottomSheetSearch = ({ setSheetConfig }) => {
  const dispatch = useDispatch()
  const classes = BusDrawerStyles()

  React.useEffect(() => {
    const sheetConfig = {
      title: '',
      displayBackButton: false,
      sheetColor: '',
      displayCloseButton: false,
    }
    setSheetConfig(sheetConfig)
  }, [])

  return (
    <>
      <BottomSheetSearchTabs
        TabOne={<BusList dispatch={dispatch} classes={classes} />}
        TabTwo={<StopsList dispatch={dispatch} classes={classes} />}
        TabThree={<BuildingList dispatch={dispatch} classes={classes} />}
      />
    </>
  )
}

export default BottomSheetSearch
