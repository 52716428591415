import { makeStyles } from '@material-ui/core/styles'

export const BusDrawerStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  newListSection: {
    backgroundColor: 'inherit',
    listStyle: 'none',
  },
  listSubHeader: {
    backgroundColor: theme.palette.paper.primary,
  },
  newUl: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  outerList: {
    height: 'calc(100vh - 422px)',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 222px)',
    },
  },
  list: {
    width: '100%',
    overflow: 'auto',
    height: 'calc(100vh - 422px)',
    overflowY: 'auto',
    paddingBottom: 50,
    paddingTop: 0,
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 222px)',
    },
  },
  appBarIcon: {
    fontSize: 24,
    color: theme.palette.base.white,
  },
  searchBar: {
    width: 'auto',
    marginLeft: '12px',
    marginRight: '12px',
  },
  margin: {
    marginLeft: 8,
  },
  filterBody: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 4,
    marginBottom: 4,
  },
  filterLabel: {
    float: 'left',
    marginLeft: 16,
  },
  filterLabelText: {
    color: theme.palette.primary.contrastText,
  },
  select: {
    float: 'right',
    marginRight: 16,
  },
  optionContainer: {
    color: theme.palette.primary.contrastText,
  },
  optionText: {
    marginLeft: 8,
    color: theme.palette.primary.headerText,
  },
  fixedSizeList: {
    listStyle: 'none',
    overflow: 'none',
  },
}))
