/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState = {
  title: 'Library QuickSearch',
  status: 'idle',
  data: {
    searchUrl: '',
    additionalInfo: [],
    libraries: [],
  },
}

export const fetchlibraryQuickSearch = createAsyncThunk(
  'fetchlibraryQuickSearch',
  async () => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const res = await axios.get(
      process.env.REACT_APP_LIBRARYQUICKSEARCH_ENDPOINT,
      {
        headers: {
          JWT: jwtToken,
        },
      },
    )
    return res.data
  },
)

export const libraryQuickSearchSlice = createSlice({
  name: 'libraryQuickSearchWidget',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchlibraryQuickSearch.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchlibraryQuickSearch.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchlibraryQuickSearch.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.title = action.payload.title
      state.imageCardLinks = action.payload.imageCardLinks
      state.inlineIconLinks = action.payload.inlineIconLinks
      state.imageLinks = action.payload.imageLinks
      state.imageLocation = action.payload.imageLocation
      state.data.searchLink = action.payload.searchLink
      state.data.additionalInfo = action.payload.additionalInfo
      state.data.libraries = action.payload.libraries
    },
  },
})
export const selectlibraryQuickSearchTitle = (state) =>
  state.libraryQuickSearch.title
export const selectlibraryQuickSearchLink = (state) =>
  state.libraryQuickSearch.data.searchLink
export const selectlibraryQuickSearchIconLinks = (state) =>
  state.libraryQuickSearch.inlineIconLinks
export const selectlibraryQuickSearchImageLocation = (state) =>
  state.libraryQuickSearch.imageLocation
export const selectlibraryQuickSearchImageLinks = (state) =>
  state.libraryQuickSearch.imageCardLinks
export const selectlibraryQuickSearchStatus = (state) =>
  state.libraryQuickSearch.status
export const selectLibraryQuickSearchAdditionalInfo = (state) =>
  state.libraryQuickSearch.data.additionalInfo
export const selectLibraryQuickSearchLibraries = (state) =>
  state.libraryQuickSearch.data.libraries

export default libraryQuickSearchSlice.reducer
