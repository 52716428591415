/* eslint-disable no-unused-vars */
import * as React from 'react'
import { Box, Grid, TextField, Typography } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles'
import { DesktopMac } from '@material-ui/icons'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useDispatch, useSelector } from 'react-redux'
import DesktopWidgetsList from './DesktopWidgetsList'
import MobileWidgetsList from './MobileWidgetsList'

import {
  fetchDashboard,
  selectWidgets,
  selectWidgetCallStatus,
  fetchUserOrder,
} from '../dashboardSlice'

/**
  WidgetsList is used to display all the widgets on the dashboard page depending on screen size
 */
function WidgetsList({ isAdmin }) {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up('md'))
  const dispatch = useDispatch()
  const widgetCallState = useSelector(selectWidgetCallStatus)
  const apiReturnedWidgets = useSelector(selectWidgets)
  const adminWidgets = useSelector((s) => s.adminPage.widgets)
  const adminWidgetCallState = useSelector((s) => s.adminPage.status)

  React.useEffect(() => {
    console.log('Getting following widgets')
    console.log(apiReturnedWidgets)
  }, [apiReturnedWidgets])

  React.useEffect(() => {
    // TODO: Add Role param to change widgets returned based on role
    dispatch(fetchDashboard())
    dispatch(fetchUserOrder())
  }, [])

  const AdminDisabledModeMessage = () => (
    <Grid
      container
      direction="column"
      alignItems="center"
      spacing={2}
      style={{ margin: 'auto' }}
    >
      <Grid item>
        <DesktopMac color="primary" fontSize="large" />
      </Grid>
      <Grid item>
        <Typography variant="h6" align="center">
          Admin Mode Is Not Available On Mobile.
        </Typography>
      </Grid>
    </Grid>
  )

  return (
    <Box>
      {matches ? (
        <DesktopWidgetsList
          isAdmin={isAdmin}
          widgetCallState={isAdmin ? adminWidgetCallState : widgetCallState}
          apiReturnedWidgets={isAdmin ? adminWidgets : apiReturnedWidgets}
        />
      ) : (
        <>
          {isAdmin ? (
            <AdminDisabledModeMessage />
          ) : (
            <MobileWidgetsList
              widgetCallState={widgetCallState}
              apiReturnedWidgets={apiReturnedWidgets}
            />
          )}
        </>
      )}
    </Box>
  )
}

export default WidgetsList
