import * as React from 'react'
import { Typography as Text } from '@material-ui/core'

import Box from '@material-ui/core/Box'
import ErrorMessageStyles from './errorStyles'

export const ErrorMessage = () => {
  const classes = ErrorMessageStyles()

  return (
    <>
      <Box className={classes.errorMsgContainer}>
        <Text variant="subtitle1">Oops something went wrong...</Text>
        <img
          src="./assets/images/error1.png"
          alt="handshake homepage"
          className={classes.image}
        />
        <Text variant="subtitle2">Please check back later.</Text>
      </Box>
    </>
  )
}
