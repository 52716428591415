import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) => ({
  button: {
    width: '100%',
    backgroundColor: theme.palette.base.red,
    color: theme.palette.base.white,
    borderRadius: '10px',
    margin: '5px 0px',
  },
}))

export default styles
