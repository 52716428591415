import { makeStyles } from '@material-ui/core/styles'

export const useMyMapStyles = makeStyles((theme) => ({
  mapContainer: {
    marginTop: 55,
    marginLeft: 55,
    width: 'calc(100vw - 55px)',
    height: 'calc(100vh - 55px)',
    [theme.breakpoints.down(770)]: {
      width: '100vw',
      height: 'calc(100vh - 105px)',
      marginLeft: 0,
      marginTop: 0,
    },
  },
  mapContainerMobilePage: {
    marginLeft: 55,
    width: 'calc(100vw - 55px)',
    height: 'calc(100vh - 55px)',
    [theme.breakpoints.down(770)]: {
      width: '100vw',
      height: 'calc(100vh)',
      marginLeft: 0,
      marginTop: 0,
    },
  },
  mapContainerNavOpen: {
    marginLeft: 300,
    width: '100vw',
    height: '100vh',
    [theme.breakpoints.down(1910)]: {
      marginLeft: 0,
      width: '100vw',
      height: '100vh',
    },
    [theme.breakpoints.down(600)]: {
      marginLeft: 0,
      width: '100vw',
      height: 'calc(100vh - 103px)',
    },
  },
}))

export default useMyMapStyles
