import { makeStyles } from '@material-ui/core/styles'

const CategoryCardSliderStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    marginBottom: 16,
    marginTop: 4,
  },
  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  media: {
    height: 200,
    width: 300,
    opacity: 0.6,
    borderRadius: 8,
    marginRight: 6,
  },
  card: {
    borderRadius: 8,
    background: 'black',
    marginRight: 4,
    marginLeft: 4,
    zIndex: 2,
  },
  title: {
    position: 'absolute',
    top: '10px',
    left: '10px',
    color: theme.palette.base.white,
    textAlign: 'left',
    fontWeight: '900',
    textShadow: '2px',
    zIndex: 3,
  },
}))

export default CategoryCardSliderStyles
