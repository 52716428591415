import { makeStyles } from '@material-ui/styles'

export const buildingListMobileStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 16,
    width: '100%',
  },
  list: {
    width: '100%',
  },
  img: {
    width: '100%',
  },
  appBarIcon: {
    fontSize: 24,
    color: theme.palette.base.white,
  },
  articleSponsorText: {
    fontSize: 14,
    color: theme.palette.base.white,
    marginLeft: 10,
    marginRight: 10,
  },
  cardContent: {
    textAlign: 'center',
    marginBottom: 8,
  },
  icon: {
    color: '#cc0033',
    fontSize: 35,
  },
  favoriteIcon: {
    color: '#979797',
    fontSize: 24,
  },
  favoriteIconSelected: {
    color: '#cc0033',
    fontSize: 24,
  },
  buildingName: {
    fontSize: 16,
  },
  buildingAddress: {
    fontSize: 14,
  },
  headingStyles: {
    height: 20,
    marginLeft: 4,
  },
}))
