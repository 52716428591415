import { makeStyles } from '@material-ui/core/styles'

// TODO Bring Back Unique Colors
const courseRegistrationStyles = makeStyles((theme) => ({
  iconStyle: {
    fontSize: 40,
    color: theme.palette.secondary.headerText,
    '&:hover': {
      transform: 'scale(1.07)',
    },
  },
  notchedOutline: {
    borderWidth: '1px',
  },
  selectContainer: {
    width: 250,
    margin: 8,
    zIndex: 4,
    marginTop: 15,
  },
  secondarySelect: {
    width: 250,
    margin: 8,
    zIndex: 3,
  },
  tertiarySelect: {
    width: 250,
    margin: 8,
    zIndex: 2,
  },
  textFieldContainer: {
    margin: 8,
    zIndex: 1,
  },
  textField: {
    width: 250,
    height: 10,
  },
  iconContainer: {
    marginTop: 15,
  },
  submitButton: {
    marginTop: 20,
    width: '8rem',
    color: 'white',
    backgroundColor: theme.palette.base.green,
  },
  selector: {
    width: '100%',
    height: 35,
  },
  icon: {
    fill: theme.palette.base.red,
  },
}))

export { courseRegistrationStyles }
