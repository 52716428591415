/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-curly-brace-presence */
import * as React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { AppBar, Grid, Toolbar, Button } from '@material-ui/core'

import CheckIsMobile from '../../../utils/isMobileHook'

import {
  // fetchMyAlerts,
  selectMyAlertsData,
  resetRefresh,
  selectMyAlertsRefresh,
} from '../../features/dashboard/widgets/MyAlerts/MyAlertsSlice'
import { selectShowSensitiveInfo } from '../../features/settings/settingsSlice'
import { selectAlertsIsOpen } from '../navigationSlice'

import renderAppBarLocalButtons from './components/renderAppBarLocalButtons'
// import BetaMode from './sections/BetaMode'
import topAppBarStyles from './topAppBarStyles'

// Component for the top app bar
export function TopAppBar() {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const isMobile = CheckIsMobile()
  const classes = topAppBarStyles()
  const showSensitiveInfo = useSelector(selectShowSensitiveInfo)
  const dialogOpen = useSelector(selectAlertsIsOpen)
  const refresh = useSelector(selectMyAlertsRefresh)
  const settingsObj = useSelector((s) => s.settings)
  const { darkMode } = settingsObj?.data
  const { search } = useSelector((s) => s.news)
  const { userProfile } = useSelector((s) => s.myProfile)

  // Check if we are on mobile or not instead if the widget is loaded
  const alertsArray = useSelector(selectMyAlertsData).alerts
  const [activeAlerts, setActiveAlerts] = React.useState(0)

  React.useEffect(() => {
    const personalAlertsNumber = alertsArray?.notificationAlerts?.filter(
      (alert) =>
        alert.alerttype === 'EmergencyAlert' ||
        (alert.alerttype === 'StudentAlert' && alert.acknowledgedTS === '') ||
        (alert.alerttype === 'EmployeeAlert' && alert.acknowledgedTS === '') ||
        (alert.severityLevel === 'High' && alert.acknowledgedTS === ''),
    ).length
    setActiveAlerts(personalAlertsNumber)
  }, [alertsArray])

  React.useEffect(() => {
    if (refresh) {
      // dispatch(fetchMyAlerts())
      dispatch(resetRefresh())
    }
  }, [refresh])

  // List of routes where we never want to show the app bar
  const noShowRoutes = [
    '/login',
    '/SeeAllAppsPageMobile',
    '/ViewAppInfoPageMobile',
    '/RestaurantListMobile',
    '/NearestStopsMobile',
    '/NewsSourceListMobile',
  ]

  const sometimesShow = {
    '/ViewArticlePage': isMobile,
  }

  // Check if current route is in noShowRoutes or in sometimesShow and the condition is true
  if (
    noShowRoutes.includes(location.pathname) ||
    sometimesShow[location.pathname]
  ) {
    return null
  }
  return (
    <AppBar className={classes.appBar} position="relative" elevation={2}>
      <Toolbar>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
          className={classes.gridClass}
        >
          {!isMobile && <Grid item md={4} />}

          <Grid item md={4} sm={8}>
            <Grid
              container
              direction="row"
              justifyContent={isMobile ? 'flex-start' : 'center'}
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item>
                <Button
                  component="a"
                  className={classes.appButton}
                  href={process.env.REACT_APP_HOMEBUTTON_REROUTE}
                >
                  <img
                    className={classes.myRutgersLogo}
                    alt="Rutgers Logo"
                    src={
                      darkMode
                        ? `${process.env.REACT_APP_IMAGES_URL}/assets/images/my-rutgers-logo-dark-new.png`
                        : `${process.env.REACT_APP_IMAGES_URL}/assets/images/my-rutgers-logo-light-new.png`
                    }
                  />
                </Button>
              </Grid>

              {/* <Grid item>
                <BetaMode />
              </Grid> */}
            </Grid>
          </Grid>
          {renderAppBarLocalButtons(
            location.pathname,
            classes,
            dispatch,
            history,
            isMobile,
            showSensitiveInfo,
            dialogOpen,
            activeAlerts,
            search,
            userProfile,
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default TopAppBar
