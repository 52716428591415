import * as React from 'react'
import { useDispatch } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import ExitToApp from '@material-ui/icons/ExitToApp'
import AppStyles from '../../AppsStyles'
import { handleLogout } from '../../../dashboard/dashboardSlice'

function ErrorPage() {
  const classes = AppStyles()
  const dispatch = useDispatch()

  return (
    <Grid container justifyContent="center">
      <Box className={classes.optionsContainer}>
        <div className={classes.colorbox}>
          <div className={classes.errorSectionText}>
            <Typography variant="h1">Session Timed Out</Typography>
            <Typography variant="h5">
              Please log out and log back in using the button below
            </Typography>
          </div>
          <Button
            component="label"
            variant="contained"
            className={classes.errorPageLogout}
            onClick={() => dispatch(handleLogout())}
            startIcon={<ExitToApp />}
          >
            Logout
          </Button>
        </div>
      </Box>
    </Grid>
  )
}

export default ErrorPage
