/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useSelector } from 'react-redux'

import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import Select from '@material-ui/core/Select'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import HideShowButton from '../../../DashboardComponents/HideShowButton/HideShowButton'

import { formatUSD } from '../../../../../../utils/format'

import IconPicker from '../../../../apps/AppComponents/IconPicker/IconPicker'
import { selectShowSensitiveInfo } from '../../../../settings/settingsSlice'
import { handleUpdateYear } from '../FinanialAidSlice'
import { logDashboardEvent } from '../../../events'

function AwardCard({ award, showSensitiveInfo, classes, widgetId, dispatch }) {
  const [showAward, setShowAward] = React.useState(showSensitiveInfo)

  const handleShowAward = () => {
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardEvent',
        widgetId,
        !showAward ? 'Show Award' : 'Hide Award',
        '',
      )
    }
    setShowAward(!showAward)
  }

  const handleIconClick = (url) => {
    if (widgetId && dispatch) {
      logDashboardEvent(dispatch, 'dashboardEvent', widgetId, 'View Award', url)
    }
    window.open(url, '_blank')
  }
  const additionalVoiceOver = `Your award amount is ${formatUSD(
    parseInt(award.totalAmt, 10),
  )}`

  return (
    <Grid item style={{ width: '100%' }}>
      <Card
        className={classes.awardCard}
        variant="outlined"
        key={award.description}
      >
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <Typography variant="subtitle1">
              Total Award for {award.year}:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" style={{ textAlign: 'center' }}>
              {award.Description}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton className={classes.transparent}>
              <HideShowButton
                displayData={formatUSD(parseInt(award.totalAmt, 10))}
                color="green"
                widgetId={widgetId}
                dispatch={handleShowAward}
                moduleName="Award"
                additionalVoiceOver={additionalVoiceOver}
              ></HideShowButton>
            </IconButton>
          </Grid>
          <Grid item className={classes.retainer}>
            <Button
              className={classes.retainer}
              onClick={() => handleIconClick(award.url)}
            >
              <Grid
                container
                direction="row"
                alignItems="baseline"
                justifyContent="center"
                spacing={2}
              >
                <Grid item>
                  <IconPicker iconName="envelope-open" color="gray" size="3x" />
                </Grid>
                <Typography>Award Letter and Information</Typography>
              </Grid>
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  )
}

function ViewTab({ widgetData, widgetId, dispatch, classes }) {
  const yearsSet = new Set()
  widgetData.awardTab?.awardData?.forEach((data) => {
    // REQUEST 4/16/24 REMOVE 2025
    // if (data.year !== '2025') {
    yearsSet.add(data.year)
    // }
  })
  const showSensitiveInfo = useSelector(selectShowSensitiveInfo)
  const { year, years } = useSelector((s) => s.financialAid)

  const handleChange = (event) => {
    dispatch(handleUpdateYear(event.target.value))
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
      className={classes.viewGrid}
    >
      <Grid item>
        <Card className={classes.schoolCodeCard} variant="outlined">
          <Typography variant="body1">
            July {year - 1} - June {year}
          </Typography>
        </Card>
      </Grid>
      <Grid className={classes.viewAwardsGrid}>
        {widgetData.isirStatus?.response?.filter(
          (item) => item.isirRejected === 'Y',
        ).length > 0 && (
          <Grid item style={{ width: '100%' }}>
            <Box className={classes.sirsRejectedPromptContainer}>
              <Typography style={{ textAlign: 'center' }}>
                <span style={{ fontWeight: 'bold' }}>
                  {widgetData.awardTab.sirsRejectedPrompt}
                </span>
              </Typography>
            </Box>
          </Grid>
        )}
        {widgetData.awardTab.formattedAwards
          .filter((award) => award.year === year)
          .map((award, index) => (
            <AwardCard
              award={award}
              showSensitiveInfo={showSensitiveInfo}
              classes={classes}
              widgetId={widgetId}
              dispatch={dispatch}
              key={index}
            />
          ))}
        {widgetData.awardTab?.formattedAwards?.filter(
          (award) => award.year === year,
        ).length <= 0 ? (
          <Grid item style={{ width: '100%' }}>
            <Card className={classes.awardCard} variant="outlined">
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <IconPicker
                    iconName="circle-check"
                    size="5x"
                    color="#00b339"
                  />
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">No Award Data</Typography>
                </Grid>
                <Grid item className={classes.retainer}>
                  <Button
                    className={classes.retainer}
                    onClick={() =>
                      window.open(
                        process.env.REACT_APP_FINANCIALAID_LINK,
                        '_blank',
                      )
                    }
                  >
                    <Grid
                      container
                      direction="row"
                      alignItems="baseline"
                      justifyContent="center"
                      spacing={2}
                    >
                      <Typography>Click here for more information</Typography>
                    </Grid>
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ) : null}
      </Grid>
      <Grid
        container
        item
        direction="row"
        alignItems="baseline"
        justifyContent="flex-start"
        className={classes.footer}
      >
        <Grid item>
          <InputLabel id="award-year-select">
            <Select
              labelId="award-year-select"
              value={year}
              onChange={handleChange}
            >
              {years?.map((option, index) => (
                <MenuItem value={option} key={index}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </InputLabel>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ViewTab
