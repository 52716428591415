import React from 'react'
import { useDroppable } from '@dnd-kit/core'
import { SortableContext } from '@dnd-kit/sortable'

import SortableItem from './SortableItem'

const containerStyle = {
  padding: 20,
  margin: 0,
  flex: 1,
  height: '100%',
  touchAction: 'manipulation',
  overflowX: 'hidden',
  overflowY: 'auto',
}

export default function Container(props) {
  const { id, items: widgets, onDelete, activeId } = props

  const { setNodeRef } = useDroppable({
    id,
  })

  return (
    <SortableContext id={id} items={widgets}>
      <div ref={setNodeRef} style={containerStyle}>
        {!props.hideItems &&
          widgets &&
          widgets.map((widget) => (
            <SortableItem
              key={widget.id}
              id={widget.id}
              widget={widget}
              onDelete={onDelete}
              activeId={activeId}
            />
          ))}
      </div>
    </SortableContext>
  )
}
