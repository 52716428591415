import React from 'react'
import { Typography, Checkbox } from '@material-ui/core'

function WidgetFormCheckBox({
  classes,
  title,
  field,
  handleFormChange,
  focusedWidget,
}) {
  return (
    <>
      <Typography className={classes.formTitle}>
        {title}:{' '}
        <Checkbox
          checked={Boolean(focusedWidget[field])}
          onChange={(event) => handleFormChange(field, event)}
        />
      </Typography>
    </>
  )
}

export default WidgetFormCheckBox
