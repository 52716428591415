import { makeStyles } from '@material-ui/styles'

const AppCarouselStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
    overscrollBehavior: 'contain',
  },
  carouselContainerDiv: {
    marginLeft: '3%',
    marginRight: '3%',
    marginBottom: '40px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0px',
      marginLeft: '0%',
      marginRight: '0%',
      marginTop: '40px',
    },
  },
  carouselContainer: {
    marginTop: 8,
    marginBottom: 8,
    display: 'flex',
    justifyContent: 'space-between',
  },
  categoryText: {
    fontSize: 24,
    fontWeight: '500',
    marginLeft: '4px',
    color: theme.palette.primary.headerText,
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      marginLeft: '8px',
    },
  },
  seeAllButtonText: {
    color: theme.palette.base.blue,
    [theme.breakpoints.down('sm')]: {
      marginRight: '8px',
    },
  },
  customDotGroupContainer: {
    height: '40px',
    width: '100%',
  },
  marginBottom: {
    marginBottom: '32px',
  },
  buttonStyle: {
    color: theme.palette.base.darkGray,
    borderRadius: 100,
    outlineWidth: 0,
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    '&:disabled': {
      color: theme.palette.base.gray,
    },
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  dotGroup: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: '150px',
    },
  },
  backArrow: {
    position: 'relative',
    left: 4,
    top: 1,
  },
  forwardArrow: {
    position: 'relative',
    top: 1,
    left: 1,
  },
  gridMargin: {
    marginBottom: '32px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '8px',
      marginTop: '10px',
    },
  },
}))
export default AppCarouselStyles
