import * as React from 'react'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Badge from '@material-ui/core/Badge'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import HomeIcon from '@material-ui/icons/Home'
import PersonIcon from '@material-ui/icons/Person'
import RemoveIcon from '@material-ui/icons/Remove'

import { useDispatch, useSelector } from 'react-redux'
import {
  selectFlexibleWorkData,
  selectFlexibleWorkStatus,
  fetchFlexibleWork,
} from './FlexibleWorkSlice'
import styles from './FlexibleWorkStyles'
import Widget from '../Widget'
import IconLink from '../../DashboardComponents/IconLinks/IconLink'
import { TabPanel } from '../../DashboardComponents/TabPanel/TabPanel'
import Loading from '../helpers/Loading'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'

function MyFlexibleWork({
  className,
  widgetId,
  showNote,
  noteMessage,
  notePosition,
  noteBackground,
  noteIcon,
  noteUrl,
  noteFontColor,
}) {
  const widgetCallState = useSelector(selectFlexibleWorkStatus)
  const widgetData = useSelector(selectFlexibleWorkData)
  const classes = styles()
  const dispatch = useDispatch()
  React.useEffect(() => {
    dispatch(fetchFlexibleWork())
  }, [])

  return (
    <Widget className={className}>
      <Widget.Header title={widgetData.title} category="default" />
      <Widget.Content
        className={classes.container}
        showNote={showNote}
        noteMessage={noteMessage}
        notePosition={notePosition}
        noteBackground={noteBackground}
        noteIcon={noteIcon}
        noteUrl={noteUrl}
        noteFontColor={noteFontColor}
      >
        {renderFlexibleWorkState(
          widgetCallState,
          widgetData,
          widgetId,
          classes,
        )}
      </Widget.Content>
    </Widget>
  )
}

function renderFlexibleWorkState(
  widgetCallState,
  widgetData,
  widgetId,
  classes,
) {
  // TODO: Handle Proper Error States
  switch (widgetCallState) {
    case 'fulfilled':
      return (
        <Loaded widgetData={widgetData} widgetId={widgetId} classes={classes} />
      )
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage />
        </Grid>
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item></Grid>
        </Grid>
      )
  }
}

function Loaded({ widgetData, widgetId, classes }) {
  return widgetData?.information?.jobs ? (
    <JobsAvailable
      widgetData={widgetData}
      widgetId={widgetId}
      classes={classes}
    />
  ) : (
    <NoFWA links={widgetData.links} classes={classes} widgetId={widgetId} />
  )
}

function NoFWA({ links, classes, widgetId }) {
  return (
    <Grid container direction="column" spacing={2} className={classes.noFWA}>
      <Grid item>
        <Typography>
          <span className={classes.redText}>Flexible Work Arrangement</span>{' '}
          (FWA) are{' '}
          <b>
            alternate arrangements or schedules from the traditional working day
            and week
          </b>{' '}
          flexibility around the job tasks rather than the location or the
          schedule. Employees may request a different work schedule to meet
          personal or family needs.
        </Typography>
      </Grid>
      <Grid item>
        <Typography>
          Please click on the <span className={classes.redText}>Request</span>{' '}
          button below to submit an application. The request will be routed to
          the approvers to review and approve/deny based on the designated
          timeline established in the FWA policy.
        </Typography>
      </Grid>
      <Grid item container direction="row" justifyContent="space-around">
        <FWALinks
          links={links}
          classes={classes}
          showBadge={false}
          widgetId={widgetId}
        />
      </Grid>
    </Grid>
  )
}

function JobsAvailable({ widgetData, widgetId, classes }) {
  const { jobs, approverCount } = widgetData.information
  const fwaRefreshTime = widgetData.information.fwaRefreshTime ?? ''
  const { links } = widgetData
  const [jobSelected, setJobSelected] = React.useState(0)

  const handleChange = (_, newValue) => {
    setJobSelected(newValue)
  }

  const jobsFiltered = []
  let pendingApprovalStatusIndex = -1
  jobs.forEach((job) => {
    if (job.alertStatus.includes('Pending Approval')) {
      if (pendingApprovalStatusIndex !== -1)
        jobsFiltered.splice(pendingApprovalStatusIndex, 1)
      pendingApprovalStatusIndex = jobsFiltered.length
    }
    jobsFiltered.push(job)
  })

  return (
    <Box className={classes.modalTab}>
      <Tabs
        value={jobSelected}
        onChange={handleChange}
        variant={jobs.length > 3 ? 'scrollable' : 'fullWidth'}
        className={classes.tabBackground}
      >
        {jobsFiltered.map((job) => (
          <Tab
            className={classes.tabText}
            key={job.title + job.department + job.requestNumber}
            label={
              <Typography className={classes.tabTitle}>{job.title}</Typography>
            }
          />
        ))}
      </Tabs>
      {jobsFiltered.map((job, index) => (
        <TabPanel
          value={jobSelected}
          index={index}
          className={classes.panel}
          key={job.title + job.department + job.requestNumber}
        >
          <FWAPage
            job={jobsFiltered[jobSelected]}
            classes={classes}
            approverCount={approverCount}
            fwaRefreshTime={fwaRefreshTime}
            links={links}
            widgetId={widgetId}
          />
        </TabPanel>
      ))}
    </Box>
  )
}

function FWAPage({
  job,
  classes,
  approverCount,
  fwaRefreshTime,
  links,
  widgetId,
}) {
  const { schedule } = job
  const badgeCount = parseInt(approverCount, 10)

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="space-between"
      spacing={1}
      className={classes.fullHeight}
    >
      <Grid item container direction="column" className={classes.fullWidth}>
        <Header job={job} classes={classes} />
      </Grid>
      <Grid
        item
        container
        direction="column"
        className={classes.calendarGrid}
        justifyContent={
          schedule && job.requestStatus ? 'space-between' : 'center'
        }
      >
        {schedule && (
          <Grid
            item
            container
            justifyContent="space-between"
            alignContent="flex-start"
            className={classes.fullWidth}
          >
            <Calendar schedule={schedule} classes={classes} />
            {job.requestStatus === 'APVD' && fwaRefreshTime !== '' && (
              <Grid
                item
                container
                direction="column"
                alignItems="center"
                className={classes.statusNotifier}
              >
                <Typography variant="caption">
                  [Status updated on
                  {` ${new Date(fwaRefreshTime).toLocaleString('en-GB', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  })}`}
                  ]
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
        {job.requestStatus && (
          <Grid item className={classes.fullWidth}>
            <FWAAlert
              classes={classes}
              schedule={schedule}
              alert={job.requestStatus}
              widgetId={widgetId}
              fwaRefreshTime={fwaRefreshTime}
            />
          </Grid>
        )}
      </Grid>

      <Grid item container direction="row" justifyContent="space-around">
        <FWALinks
          links={links}
          classes={classes}
          badgeCount={badgeCount}
          widgetId={widgetId}
        />
      </Grid>
    </Grid>
  )
}

// Big Components for the Widget

function Header({ job, classes }) {
  return (
    <>
      <Paper className={classes.titlePaper} square elevation={0}>
        <Typography variant="subtitle1" align="center">
          Flexible Work Arrangment: {job.fwaType}
        </Typography>
      </Paper>
      <Grid item container direction="column" alignItems="center">
        <Typography variant="subtitle1">{job.department}</Typography>
        <Typography variant="caption">
          Effective: {job.effectiveDate} to {job.expirationDate}
        </Typography>
      </Grid>
    </>
  )
}

function Calendar({ schedule, classes }) {
  const days = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ]

  const date = new Date()
  const today = date.getDay()

  const [weekSelected, setWeekSelected] = React.useState(0)

  const week1Button = () => {
    setWeekSelected(0)
  }
  const week2Button = () => {
    setWeekSelected(1)
  }

  return (
    <>
      {schedule && (
        <Grid container direction="column">
          <Grid item>
            <WeekSelector
              classes={classes}
              weekSelected={weekSelected}
              week1Button={week1Button}
              week2Button={week2Button}
              numWeeks={schedule.calendar?.length ?? 0}
            />
          </Grid>
          <TableContainer
            component={Paper}
            className={classes.tableContainer}
            elevation={0}
          >
            <Table className={classes.tablePaper} size="small" elevation={0}>
              <TableHead>
                <TableRow className={classes.headerStyle}>
                  {days.map((day, index) => (
                    <TableCell
                      key={day}
                      padding="none"
                      align="center"
                      textAlign="center"
                      className={
                        today === index
                          ? classes.todayTextContainer
                          : classes.headerText
                      }
                    >
                      <Box
                        padding="none"
                        align="center"
                        textAlign="center"
                        height="50%"
                        className={today === index ? classes.todayText : null}
                      >
                        {day.substring(0, 3)}
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className={classes.bodyStyle}>
                  {days.map((day) => (
                    <TableCell padding="none" align="center" key={day}>
                      <CellRow
                        day={day}
                        FWA={schedule[weekSelected].calendar[0]}
                        classes={classes}
                      />
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow className={classes.bodyStyle}>
                  {schedule[weekSelected].calendar[1] && (
                    <>
                      {days.map((day) => (
                        <TableCell padding="none" align="center" key={day}>
                          <CellRow
                            day={day}
                            FWA={schedule[weekSelected].calendar[1]}
                            classes={classes}
                            icons={false}
                          />
                        </TableCell>
                      ))}
                    </>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Grid
            item
            container
            direction="row"
            justifyContent="space-evenly"
            className={classes.legendStyle}
          >
            <Legend />
          </Grid>
        </Grid>
      )}
    </>
  )
}

function FWAAlert({ alert, schedule, classes }) {
  return (
    <>
      {alert !== 'APVD' && (
        <Paper className={classes.alertPaper} variant="outlined" elevation={0}>
          <Grid item container direction="column" alignContent="center">
            {!schedule && (
              <Typography align="center">Request Submitted</Typography>
            )}
            <Typography align="center">{alert}</Typography>
          </Grid>
        </Paper>
      )}
    </>
  )
}

// Helper Components for Big Components

function Legend() {
  return (
    <>
      <Grid item xs container direction="row" justifyContent="center">
        <PersonIcon htmlColor="#cc0033" />
        <Typography>In-Person</Typography>
      </Grid>
      <Grid item xs container direction="row" justifyContent="center">
        <HomeIcon />
        <Typography>Remote</Typography>
      </Grid>
    </>
  )
}

function CellRow({ day, FWA, classes, icons = true }) {
  const remote = FWA[`${day}Type`] === 'Remote'
  const hours = FWA[`${day}StartTime`]
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      {hours ? (
        <>
          {remote
            ? icons && <HomeIcon className={classes.remoteStyle} />
            : icons && <PersonIcon className={classes.inPersonStyle} />}
          {!icons && (
            <Typography className={classes.breakText}>Break</Typography>
          )}
          <Typography className={classes.tinyText}>
            {FWA[`${day}StartTime`]}
          </Typography>
          <Typography className={classes.tinyText}>
            {FWA[`${day}EndTime`]}
          </Typography>
        </>
      ) : (
        <>
          <RemoveIcon />
        </>
      )}
    </Grid>
  )
}

function FWALinks({ classes, badgeCount, showBadge = true, links, widgetId }) {
  const dispatch = useDispatch()

  return (
    <>
      <IconLink
        icon="far fa-calendar-alt"
        iconLabel="Request / Change FWA"
        link={links[0]}
        widgetId={widgetId}
        dispatch={dispatch}
        size="2x"
      />
      <Badge
        badgeContent={badgeCount}
        invisible={!showBadge || badgeCount === 0}
        color="primary"
        overlap="circular"
        className={classes.badgePlacement}
      >
        <IconLink
          icon="fas fa-list-check"
          iconLabel="Approve / Assign FWA"
          link={links[1]}
          size="2x"
          widgetId={widgetId}
          dispatch={dispatch}
        />
      </Badge>
      <IconLink
        icon="fas fa-compass"
        iconLabel="Read FWA Policy"
        link={links[2]}
        size="2x"
        widgetId={widgetId}
        dispatch={dispatch}
      />
    </>
  )
}

function WeekSelector({
  classes,
  weekSelected,
  week1Button,
  week2Button,
  numWeeks,
}) {
  return (
    <Grid
      item
      container
      direction="row"
      alignContent="flex-end"
      justifyContent="flex-end"
    >
      {numWeeks > 0 && (
        <Grid item>
          <Typography
            onClick={week1Button}
            variant="caption"
            className={
              weekSelected === 0
                ? classes.activeSelectionText
                : classes.selectionText
            }
          >
            Week 1
          </Typography>
        </Grid>
      )}
      {numWeeks > 1 && (
        <>
          <Grid item>
            <Typography>|</Typography>
          </Grid>
          <Grid item>
            <Typography
              onClick={week2Button}
              variant="caption"
              className={
                weekSelected === 1
                  ? classes.activeSelectionText
                  : classes.selectionText
              }
            >
              Week 2
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default MyFlexibleWork
