export function checkIfEmergencyAlertHasBeenSeenAlready(alert) {
  if (alert.alerttype !== 'EmergencyAlert') {
    return true
  }
  return storeDockeyInLocalStorage(alert.dockey)
}

// Function to set the array in local storage or cookie with an expiry time of 9 hours
function storeDockeyInLocalStorage(dockey) {
  // Check if dockey already exists in Local Storage
  const storedData = localStorage.getItem('storedDockeyArray')
  const now = new Date().getTime()
  if (storedData) {
    const existingData = JSON?.parse(storedData)
    const existingDockeyArray = existingData?.dockeys || []

    if (existingData?.expiry && now > existingData?.expiry) {
      // If stored data has expired, remove it and return early
      localStorage.removeItem('storedDockeyArray')
      return false
    }

    if (existingDockeyArray?.includes(dockey)) {
      // If dockey already exists, return without storing again
      return true
    }

    // Add new dockey to the existing array
    existingDockeyArray.push(dockey)

    const expiryTime = now + 9 * 60 * 60 * 1000 // 9 hours in milliseconds

    const data = {
      dockeys: existingDockeyArray,
      expiry: expiryTime,
    }

    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('storedDockeyArray', JSON?.stringify(data))
      return false
    }
    return false
  }
  // If no stored data, store the dockey directly
  const expiryTime = now + 9 * 60 * 60 * 1000 // 9 hours in milliseconds

  const data = {
    dockeys: [dockey],
    expiry: expiryTime,
  }

  if (typeof localStorage !== 'undefined') {
    localStorage?.setItem('storedDockeyArray', JSON.stringify(data))
  }
  return false
}
