/* eslint-disable eqeqeq */
import React from 'react'
import { Typography as Text } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'

// Sort list alphabeticall to display
export const sortListAlphabetically = (list, key = 'name') => {
  const buildingsSortedAlphabetically = list.slice(0)
  buildingsSortedAlphabetically.sort((a, b) => {
    const x = a[key].toLowerCase()
    const y = b[key].toLowerCase()
    if (x < y) {
      return -1
    }
    if (x > y) {
      return 1
    }
    return 0
  })
  return buildingsSortedAlphabetically
}

export const EmptyComponent = () => {
  const classes = ShowAppListStyles()
  return (
    <div className={classes.noMatchesContainer}>
      <Text className={classes.noMatches}>No Matches Available</Text>
    </div>
  )
}

export const ShowAppListStyles = makeStyles((theme) =>
  createStyles({
    noMatchesContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 32,
    },
    noMatches: {
      color: theme.palette.primary.headerText,
      fontSize: 20,
      fontWeight: '600',
      display: 'flex',
      justifyContent: 'center',
      marginTop: 32,
    },
  }),
)

/* eslint-disable no-bitwise */
export function decode(encoded) {
  // array that holds the points
  // this function will decode the encoded segments for each point,
  // it will return a latitude and longitude pair to create the polylines on the MyMap component.
  const points = []
  let index = 0
  const len = encoded.length
  let lat = 0
  let lng = 0
  while (index < len) {
    let b
    let shift = 0
    let result = 0
    do {
      b = encoded.charAt(index++).charCodeAt(0) - 63 // finds ascii                                                                                    //and substract it by 63
      result |= (b & 0x1f) << shift
      shift += 5
    } while (b >= 0x20)

    const dlat = (result & 1) != 0 ? ~(result >> 1) : result >> 1
    lat += dlat
    shift = 0
    result = 0
    do {
      b = encoded.charAt(index++).charCodeAt(0) - 63
      result |= (b & 0x1f) << shift
      shift += 5
    } while (b >= 0x20)
    const dlng = (result & 1) != 0 ? ~(result >> 1) : result >> 1
    lng += dlng

    points.push({ lat: lat / 1e5, lng: lng / 1e5 })
  }
  return points
}

export function filterByValue(items, key, value) {
  const lowerCaseValue = value.toLowerCase()
  return items.filter((item) =>
    item[key].toLowerCase().includes(lowerCaseValue),
  )
}

export const mapLightStyles = [
  {
    featureType: 'landscape.man_made',
    elementType: 'geometry',
    stylers: [{ color: '#faf6eb' }],
  },
  {
    featureType: 'landscape.natural',
    elementType: 'geometry',
    stylers: [{ color: '#d0e3b4' }],
  },
  {
    featureType: 'landscape.natural.terrain',
    elementType: 'geometry',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi.business',
    elementType: 'geometry',
    stylers: [{ color: '#d0e3b4' }],
  },
  {
    featureType: 'poi.medical',
    elementType: 'geometry',
    stylers: [{ color: '#f4e6e9' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: '#c0d79d' }, { weight: 0.5 }],
  },
  {
    featureType: 'poi.school',
    elementType: 'geometry',
    stylers: [{ color: '#f7f1df' }],
  },
  {
    featureType: 'poi.sports_complex',
    elementType: 'geometry',
    stylers: [{ color: '#abc18b' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'road',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry.fill',
    stylers: [{ color: '#ffffff' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [{ color: '#ffe15f' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#efd151' }],
  },
  {
    featureType: 'road.local',
    elementType: 'labels',
    stylers: [{ visibility: 'simplified' }],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text',
    stylers: [{ color: '#9e9e9e' }],
  },
  { featureType: 'transit', stylers: [{ visibility: 'simplified' }] },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [{ weight: 0.5 }],
  },
  {
    featureType: 'transit.station.airport',
    elementType: 'geometry.fill',
    stylers: [{ color: '#cfb2db' }],
  },
  {
    featureType: 'transit.station.rail',
    stylers: [{ visibility: 'simplified' }],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#92e1e2' }, { visibility: 'simplified' }],
  },
]

export const mapDarkStyles = [
  { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
  { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
  { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#d59563' }],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#d59563' }],
  },
  { featureType: 'poi.business', stylers: [{ visibility: 'off' }] },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: '#263c3f' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#6b9a76' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: '#38414e' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#212a37' }],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#9ca5b3' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{ color: '#746855' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#1f2835' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#f3d19c' }],
  },
  {
    featureType: 'road.local',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [{ color: '#2f3948' }],
  },
  {
    featureType: 'transit.station',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#d59563' }],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#17263c' }],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#515c6d' }],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#17263c' }],
  },
]

export const formatArrivals = (arrivals) => {
  const currentTime = new Date()
  const arrivalTimes = arrivals.map((arrival) => {
    const nowTime = currentTime.getTime()
    let arrivalTime = new Date(arrival.arrival_at)
    arrivalTime = arrivalTime.getTime()
    let timeDiff
    if (arrivalTime < nowTime) {
      timeDiff = nowTime - arrivalTime
    } else {
      timeDiff = arrivalTime - nowTime
    }
    const minutes = Math.floor(timeDiff / 60000)
    return {
      ...arrival,
      time: minutes > 1 ? `${minutes} mins` : ' < 1 min',
    }
  })
  return arrivalTimes
}
