/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchStaffAnnouncements = createAsyncThunk(
  'fetchStaffAnnouncements',
  async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_FACULTYSTAFF_ANNOUNCEMENTS_ENDPOINT}`,
    )
    return res.data
  },
)

export const fetchStaffOpenCases = createAsyncThunk(
  'fetchStaffOpenCases',
  async () => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const rcpid = localStorage.getItem('rcpid')
    const res = await axios.get(
      `${process.env.REACT_APP_FACULTYSTAFF_OPENCASES_ENDPOINT}`,
      {
        headers: {
          JWT: jwtToken,
          XWT: rcpid,
        },
      },
    )
    return res.data
  },
)

export const fetchStaffClosedCases = createAsyncThunk(
  'fetchStaffClosedCases',
  async () => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const rcpid = localStorage.getItem('rcpid')
    const res = await axios.get(
      `${process.env.REACT_APP_FACULTYSTAFF_CLOSEDCASES_ENDPOINT}`,
      {
        headers: {
          JWT: jwtToken,
          XWT: rcpid,
        },
      },
    )
    return res.data
  },
)

const initialState = {
  status: '',
  caseStatus: '',
  myCasesOpen: [],
  myCasesClosed: [],
  announcements: [],
  value: 0,
  value2: 0,
  query: '',
}

const facultyStaffSlice = createSlice({
  name: 'facultyStaffCenter',
  initialState,
  reducers: {
    updateTabValue: (state, action) => {
      if (state.value !== action.payload) {
        state.value = action.payload
      }
    },
    updateTabValue2: (state, action) => {
      if (state.value2 !== action.payload) {
        state.value2 = action.payload
        // state.caseStatus = 'pending'
      }
      return state
    },
    updateQuery: (state, action) => {
      state.query = action.payload.target.value
    },
  },
  extraReducers: {
    [fetchStaffAnnouncements.pending]: (state) => ({
      ...state,
      status: 'pending',
    }),
    [fetchStaffAnnouncements.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.announcements = action.payload
    },
    [fetchStaffAnnouncements.rejected]: (state) => ({
      ...state,
      status: 'error',
    }),
    [fetchStaffOpenCases.pending]: (state) => ({
      ...state,
      caseStatus: 'pending',
    }),
    [fetchStaffOpenCases.fulfilled]: (state, action) => {
      state.caseStatus = 'fulfilled'
      state.myCasesOpen = action.payload.results
    },
    [fetchStaffOpenCases.rejected]: (state) => ({
      ...state,
      caseStatus: 'error',
    }),
    [fetchStaffClosedCases.pending]: (state) => ({
      ...state,
      caseStatus: 'pending',
    }),
    [fetchStaffClosedCases.fulfilled]: (state, action) => {
      state.caseStatus = 'fulfilled'
      state.myCasesClosed = action.payload.results
    },
    [fetchStaffClosedCases.rejected]: (state) => ({
      ...state,
      caseStatus: 'error',
    }),
  },
})

export const { updateTabValue, updateTabValue2, updateQuery } =
  facultyStaffSlice.actions

export default facultyStaffSlice.reducer
