import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  AppBar,
  Grid,
  Toolbar,
  Typography as Text,
  Card,
  CardContent,
  IconButton,
  Typography,
} from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import Rating from '@material-ui/lab/Rating'

import {
  fetchRestaurants,
  handleRestaurantMobileClick,
} from '../../Buildings/redux/myBuildingsSlice'
import FlatList from '../../../../apps/AppComponents/ShowAppList/FlatList'
import Loading from '../../../../dashboard/widgets/helpers/Loading'

import {
  restaurantCardStyles,
  restaurantListMobile,
} from './restaurantListMobileStyles'

function RestaurantCard({ restaurant }) {
  const classes = restaurantCardStyles()
  const [openNow, setOpenNow] = React.useState(false)

  const openGoogleMaps = () => {
    window.open(
      'https://www.google.com/maps/place/?q=place_id:'.concat(
        restaurant.place_id,
      ),
    )
  }

  React.useEffect(() => {
    try {
      setOpenNow(restaurant.opening_hours.open_now)
    } catch (e) {
      setOpenNow(null)
    }
  }, [])

  return (
    <div>
      <Card className={classes.root} onClick={openGoogleMaps}>
        <img
          className={classes.cover}
          alt={restaurant.name}
          src={restaurant.photo}
          onError={(e) => {
            e.target.onerror = null
            e.target.src = './assets/images/building-placeholder.png'
          }}
        />
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography
              className={classes.restaurantName}
              component="h5"
              variant="h5"
            >
              {restaurant.name}
            </Typography>
            <Typography
              className={classes.restaurantVicinity}
              variant="subtitle1"
              color="textSecondary"
            >
              {restaurant.vicinity}
            </Typography>
            {openNow === true ? (
              <Typography
                className={classes.openText}
                variant="subtitle1"
                color="textSecondary"
              >
                Open
              </Typography>
            ) : (
              <Typography
                className={classes.closedText}
                variant="subtitle1"
                color="textSecondary"
              >
                Closed
              </Typography>
            )}
            <Rating
              style={{ zIndex: 0 }}
              name="read-only"
              value={restaurant.rating}
              precision={0.1}
              readOnly
            />
          </CardContent>
        </div>
      </Card>
    </div>
  )
}

export const ResaurantListMobile = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { restaurantData, restaurantLoading } = useSelector(
    (state) => state.buildings,
  )

  // eslint-disable-next-line no-use-before-define
  const classes = restaurantListMobile()
  const [restaurantList, setRestaurantList] = React.useState([{ name: '' }])

  React.useEffect(() => {
    // setRestaurantList(restaurantData)
    dispatch(fetchRestaurants(props.history.location.state.coord))
  }, [])

  React.useEffect(() => {
    setRestaurantList(restaurantData)
  }, [restaurantData])

  const goBackToBuildingMobile = () => {
    dispatch(handleRestaurantMobileClick(false))
    history.goBack()
  }

  return (
    <div className={classes.container}>
      <AppBar className={classes.appBar} position="fixed" elevation={2}>
        <Toolbar className={classes.appBarcontainer}>
          <Grid container justifyContent="flex-start" alignItems="center">
            <Grid item sm={4}>
              <IconButton onClick={goBackToBuildingMobile}>
                <ArrowBackIosIcon className={classes.mobileBackButtonIcon} />
              </IconButton>
            </Grid>
            <Grid item sm={4} className={classes.pageTitleContainer}>
              <Text className={classes.pageTitle}> Restaurant List </Text>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {restaurantLoading !== 'fulfilled' ? (
        <div style={{ marginTop: '20%' }}>
          <Loading />
        </div>
      ) : (
        <FlatList
          data={restaurantList}
          renderItem={(restaurant) => (
            <RestaurantCard restaurant={restaurant} />
          )}
          // keyExtractor={(r) => r.name}
          ListEmptyComponent={<></>}
        />
      )}
    </div>
  )
}

export default ResaurantListMobile
