import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) => ({
  container: {
    padding: 0,
    margin: 0,
    height: 470,
    width: 335,
    overflowY: 'clip',
    overflowX: 'hidden',
    [theme.breakpoints.down(370)]: {
      width: 287,
      height: 450,
    },
  },
  header: {
    backgroundColor: theme.palette.backgroundColor.ternary,
    color: theme.palette.primary.main,
    padding: '10px 0px',
    marginTop: 10,
    marginLeft: 10,
    marginRight: 10,
    whiteSpace: 'pre-line',
  },
  icon: {
    fontSize: '40px',
    color: theme.palette.base.gray,
    '&:hover': {
      filter: 'brightness(90%)',
      transform: 'scale(1.04)',
    },
  },
  tablink: {
    '&:hover': {
      cursor: 'pointer',
    },
    marginBottom: '15px',
  },
  fullHeight: {
    height: '100%',
  },
  fill: {
    height: '-webkit-fill-available',
  },
  link: {
    margin: '20px 0',
  },
  agentButtonSpacing: {
    marginTop: '90px',
  },
}))

export default styles
