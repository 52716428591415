import * as React from 'react'
import { Typography } from '@material-ui/core'
import MultiSelect from './components/MultiSelect/MultiSelect'
import WidgetFormCheckBox from './components/WidgetFormCheckBox'
import WidgetFormTextField from './components/WidgetFormTextField'
import WidgetFormMultiSelect from './components/WidgetFormMultiSelect'

function BasicWidgetForm({ classes, focusedWidget, handleFormChange }) {
  return (
    <div className={classes.widgetFormContainer}>
      <div className={classes.rowContainer}>
        <div className={classes.rowItem}>
          <WidgetFormCheckBox
            classes={classes}
            focusedWidget={focusedWidget}
            title="Active"
            field="active"
            handleFormChange={handleFormChange}
          />
        </div>
        <div className={classes.rowItem}>
          <WidgetFormCheckBox
            classes={classes}
            focusedWidget={focusedWidget}
            title="Default"
            field="default"
            handleFormChange={handleFormChange}
          />
        </div>
      </div>

      <div className={classes.singleRowContainer}>
        <WidgetFormTextField
          classes={classes}
          focusedWidget={focusedWidget}
          title="Title"
          field="title"
          handleFormChange={handleFormChange}
        />
      </div>

      <div className={classes.singleRowContainer}>
        <WidgetFormTextField
          classes={classes}
          focusedWidget={focusedWidget}
          title="Description"
          field="desc"
          handleFormChange={handleFormChange}
        />
      </div>

      <div className={classes.rowContainer}>
        <div className={classes.rowItem}>
          <WidgetFormTextField
            classes={classes}
            focusedWidget={focusedWidget}
            title="Key"
            field="key"
            handleFormChange={handleFormChange}
          />
        </div>

        <div className={classes.rowItem}>
          <div className={classes.singleRowContainer}>
            <WidgetFormTextField
              classes={classes}
              focusedWidget={focusedWidget}
              title="Id"
              field="id"
              handleFormChange={handleFormChange}
            />
          </div>
        </div>
      </div>

      <div className={classes.rowContainer}>
        <div className={classes.rowItem}>
          <div className={classes.singleRowContainer}>
            <WidgetFormTextField
              classes={classes}
              focusedWidget={focusedWidget}
              title="Icon"
              field="icon"
              handleFormChange={handleFormChange}
            />
          </div>
        </div>
        <div className={classes.rowItem}>
          <div className={classes.singleRowContainer}>
            <WidgetFormTextField
              classes={classes}
              focusedWidget={focusedWidget}
              title="Program"
              field="program"
              handleFormChange={handleFormChange}
            />
          </div>
        </div>
      </div>

      <div className={classes.rowContainer}>
        <div className={classes.rowItem}>
          <div className={classes.singleRowContainer}>
            <WidgetFormMultiSelect
              classes={classes}
              focusedWidget={focusedWidget}
              isMulti
              title="Roles"
              field="roles"
              handleFormChange={handleFormChange}
            />
          </div>
        </div>
        <div className={classes.rowItem}>
          <div className={classes.singleRowContainer}>
            <WidgetFormMultiSelect
              classes={classes}
              focusedWidget={focusedWidget}
              isMulti
              title="Subscribable"
              field="subscribable"
              handleFormChange={handleFormChange}
            />
          </div>
        </div>
      </div>

      <div className={classes.rowContainer}>
        <div className={classes.rowItem}>
          <div className={classes.singleRowContainer}>
            <Typography className={classes.formTitle}>CampusIDs</Typography>
            <MultiSelect
              k="campusIds"
              isMulti
              focusedWidget={focusedWidget}
              handleFormChange={handleFormChange}
            />
          </div>
        </div>

        <div className={classes.rowItem}>
          <div className={classes.singleRowContainer}>
            <WidgetFormMultiSelect
              classes={classes}
              focusedWidget={focusedWidget}
              isMulti
              title="Level"
              field="level"
              handleFormChange={handleFormChange}
            />
          </div>
        </div>
      </div>
      <div className={classes.rowContainer}>
        <div className={classes.rowItem}>
          <div className={classes.singleRowContainer}>
            <WidgetFormMultiSelect
              classes={classes}
              focusedWidget={focusedWidget}
              isMulti
              title="Org Code"
              field="orgCode"
              handleFormChange={handleFormChange}
            />
          </div>
        </div>
        <div className={classes.rowItem}>
          <div className={classes.singleRowContainer}>
            <WidgetFormMultiSelect
              classes={classes}
              focusedWidget={focusedWidget}
              isMulti
              title="Unit Code"
              field="unitCode"
              handleFormChange={handleFormChange}
            />
          </div>
        </div>
      </div>
      <div className={classes.rowContainer}>
        <div className={classes.rowItem}>
          <div className={classes.singleRowContainer}>
            <WidgetFormMultiSelect
              classes={classes}
              focusedWidget={focusedWidget}
              title="Proxy"
              field="proxy"
              handleFormChange={handleFormChange}
            />
          </div>
        </div>
        <div className={classes.rowItem}>
          <div className={classes.singleRowContainer}>
            <WidgetFormMultiSelect
              classes={classes}
              focusedWidget={focusedWidget}
              title="Category"
              field="category"
              handleFormChange={handleFormChange}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default BasicWidgetForm
