/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { doc, collection, getDoc, updateDoc } from 'firebase/firestore'
import axios from 'axios'
import { firestore } from '../../../../../utils/firebase'

const initialState = {
  title: 'My Degree',
  degree: '',
  school: '',
  status: 'idle',
  holds: [],
  showDegreeTutorial: false,
  statusTutorialFetch: 'idle',
}

export const fetchMyDegree = createAsyncThunk('fetchMyDegree', async () => {
  const jwtToken = localStorage.getItem('myrJwtToken')
  const rcpid = localStorage.getItem('rcpid')
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'production') {
    return axios.all([
      axios.get(`${process.env.REACT_APP_MYDEGREE_ENDPOINT}`, {
        headers: {
          JWT: jwtToken,
          XWT: rcpid,
        },
      }),
    ])
  }

  return axios.all([
    axios.get(`${process.env.REACT_APP_MYDEGREE_ENDPOINT}`, {
      headers: {
        JWT: jwtToken,
        XWT: rcpid,
      },
    }),
  ])
})

export const fetchMyDegreeTutorial = createAsyncThunk(
  'fetchMyDegreeTutorial',
  async () => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const rcpid = localStorage.getItem('rcpid')
    return axios.get(`${process.env.REACT_APP_MYDEGREETUTORIAL_ENDPOINT}`, {
      headers: {
        JWT: jwtToken,
        XWT: rcpid,
      },
    })
  },
)

export const myDegreeSlice = createSlice({
  name: 'myDegreeWidget',
  initialState,
  reducers: {
    endTutorial: (state) => {
      const userDocRef = doc(
        collection(firestore, 'users'),
        localStorage.getItem('user') ?? 'visitor',
        'settings',
        'tutorials',
      )
      state.showDegreeTutorial = false
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'production')
        getDoc(userDocRef)
      updateDoc(userDocRef, { show_my_degree_tutorial: false })
    },
  },
  extraReducers: {
    [fetchMyDegree.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchMyDegree.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchMyDegree.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.title = action.payload[0].data.title
      state.degree = action.payload[0].data.degree
      state.school = action.payload[0].data.school
      state.holds = action.payload[0].data.holds
      // updating logic for displaying degree credit
      action.payload[0].data.gradAndUndergrad
        ?.map((item) => {
          if (item.value > 0) {
            action.payload[0].data.hiddenEyeButtons[2].value = item.value
            action.payload[0].data.hiddenEyeButtons[2].iconLabel =
              item.iconLabel
          }
          return item
        })
        .filter((item) => item !== null)
      state.hiddenEyeButtons = [
        action.payload[0].data.hiddenEyeButtons[0],
        action.payload[0].data.hiddenEyeButtons[1],
        action.payload[0].data.hiddenEyeButtons[2],
      ]
      state.iconLinks = action.payload[0].data.iconLinks
      state.myDegreeDataIncomplete =
        !state.title ||
        !state.degree ||
        !state.school ||
        !state.hiddenEyeButtons
    },
    [fetchMyDegreeTutorial.rejected]: (state) => {
      state.statusTutorialFetch = 'error'
    },
    [fetchMyDegreeTutorial.pending]: (state) => {
      state.statusTutorialFetch = 'pending'
    },
    [fetchMyDegreeTutorial.fulfilled]: (state, action) => {
      state.statusTutorialFetch = 'fulfilled'
      state.titleTutorial = action.payload.data.title
      state.degreeTutorial = action.payload.data.degree
      state.schoolTutorial = action.payload.data.school
      state.hiddenEyeButtonsTutorial = action.payload.data.hiddenEyeButtons
      state.iconLinksTutorial = action.payload.data.iconLinks
    },
  },
})

export const selectMyDegreeTitle = (state) => state.myDegree.title
export const selectMyDegreeDegree = (state) => state.myDegree.degree
export const selectMyDegreeSchool = (state) => state.myDegree.school
export const selectMyDegreeHolds = (state) => state.myDegree.holds
export const selectHiddenEyeButtons = (state) => state.myDegree.hiddenEyeButtons
export const selectMyDegreeStatus = (state) => state.myDegree.status
export const selectIconLinks = (state) => state.myDegree.iconLinks
export const selectShowDegreeTutorial = (state) =>
  state.myDegree.showDegreeTutorial
export const { endTutorial } = myDegreeSlice.actions
export const selectMyDegreeDataIncomplete = (state) =>
  state.myDegree.myDegreeDataIncomplete
export const selectMyDegreeDegreeTutorial = (state) =>
  state.myDegree.degreeTutorial
export const selectMyDegreeSchoolTutorial = (state) =>
  state.myDegree.schoolTutorial
export const selectHiddenEyeButtonsTutorial = (state) =>
  state.myDegree.hiddenEyeButtonsTutorial
export const selectMyDegreeStatusTutorial = (state) =>
  state.myDegree.statusTutorialFetch
export const selectIconLinksTutorial = (state) =>
  state.myDegree.iconLinksTutorial
export default myDegreeSlice.reducer
