import * as React from 'react'
import { GoogleMap } from '@react-google-maps/api'
import { useDispatch, useSelector } from 'react-redux'
import {
  handleSearchBarToggle,
  updateBottomSheet,
  updateLatLng,
} from './redux/myMapsSlice'
import { mapLightStyles, mapDarkStyles } from '../../utils/helpers'
import Vehicles from '../Vehicles/Vehicles'
import { TabPanel } from '../../../dashboard/DashboardComponents/TabPanel/TabPanel'
import { selectDarkMode } from '../../../settings/settingsSlice'
import useMyMapStyles from '../../MyMapStyles'
import Stops from './components/Stops/Stops'
import BottomSheet from '../../components/BottomSheets/BottomSheet'
import VehicleRoute from '../Vehicles/components/VehicleRoute/VehicleRoute'
import FirebaseStops from './components/FirebaseStops/FirebaseStops'
import BuildingStops from './components/BuildingStops/BuildingStops'
import ParkingStops from './components/ParkingStops/ParkingStops'
import { setSelectedBuilding } from '../Buildings/redux/myBuildingsSlice'
import { CAMDENENGAGEBUILDING } from '../../utils/constants'

export default function MapPanel({ disableDefaultUI = false }) {
  const dispatch = useDispatch()
  const classes = useMyMapStyles()
  const darkMode = useSelector(selectDarkMode)
  const [mapRef, setMapRef] = React.useState(null)

  const { lat, lng, zoom, tabValue, showSearchBar } = useSelector((s) => s.maps)

  const handleClick = () => {
    if (showSearchBar) {
      dispatch(handleSearchBarToggle())
    }
  }

  const { drawerIsOpen } = useSelector((state) => state.navigation)

  const handleUnmount = () => {
    // Reset the center point for the map to coordinates lat: 40.51868, lng: -74.46147,
    // when a user leaves the mymaps page
    dispatch(updateLatLng({ lat: 40.51868, lng: -74.46147, zoom: 18 }))
  }

  const onDragEnd = () => {
    // set the new center to here based on the mapRef center
    dispatch(
      updateLatLng({ lat: mapRef.center.lat(), lng: mapRef.center.lng() }),
    )
  }

  const getMapStyles = (customClasses) => {
    if (!drawerIsOpen) {
      if (disableDefaultUI) {
        return customClasses.mapContainerMobilePage
      }
      return customClasses.mapContainer
    }
    return customClasses.mapContainerNavOpen
  }

  // Listen for lat lng in query params
  const urlParams = new URLSearchParams(window.location.search)
  const venueWebsite = urlParams.get('venueWebsite')
  const mapsLat = urlParams.get('lat')
  const mapsLng = urlParams.get('lng')
  const address = urlParams.get('address')
  const imageUrl = urlParams.get('imageUrl')
  const eventName = urlParams.get('eventName')
  React.useEffect(() => {
    if (mapsLat && mapsLng) {
      dispatch(
        setSelectedBuilding({
          event: eventName,
          lat: mapsLat,
          lng: mapsLng,
          address,
          venueWebsite,
          imageUrl,
        }),
      )
      dispatch(updateLatLng({ lat: mapsLat, lng: mapsLng, zoom: 18 }))
      dispatch(updateBottomSheet(CAMDENENGAGEBUILDING))
    }
  }, [mapsLat, mapsLng])

  return (
    <TabPanel value={tabValue} index={0}>
      <GoogleMap
        id="myMap"
        mapContainerClassName={getMapStyles(classes)}
        zoom={zoom}
        center={{ lat, lng }}
        onClick={handleClick}
        onDragEnd={onDragEnd}
        onUnmount={handleUnmount}
        label="off"
        onLoad={(map) => setMapRef(map)}
        options={{
          zoomControlOptions: { position: 7 },
          styles: darkMode ? mapDarkStyles : mapLightStyles,
          streetViewControlOptions: { position: 5 },
          disableDefaultUI,
          rotateControl: false,
          tilt: 0,
        }}
      >
        <Stops />
        <ParkingStops />
        <BuildingStops />
        <FirebaseStops />
        <VehicleRoute />
        <Vehicles />
        <BottomSheet />
      </GoogleMap>
    </TabPanel>
  )
}
