/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

/*
    This file contains the redux tool kit code for the state that the DidYouKnow component uses, the call that is made to obtain data and selector functions that we can call inside of the DidYouKnow component to get access to our state.
*/

// All functions in redux that need to make a call out to a resource need to be created using createAsyncThunk. Reference: https://redux-toolkit.js.org/api/createAsyncThunk -  The thunk action creator function will have plain action creators for the pending, fulfilled, and rejected cases attached as nested fields. You will see that the extraReducers object contains action creators for fetchDidYouKnow.fulfilled, fetchDidYouKnow.pending, and fetchDidYouKnow.rejected. Inside all of these action creators we are updating the state object accordingly.
export const fetchDidYouKnow = createAsyncThunk('fetchDidYouKnow', async () => {
  const jwtToken = localStorage.getItem('myrJwtToken')
  const res = await axios.get(process.env.REACT_APP_DYK_ENDPOINT, {
    headers: {
      JWT: jwtToken,
    },
  })
  return res.data
})

// This is the initial state object the reducers and extraReducers will change based on whatever we need to change it to. It is required when creating the createSlice object.
const initialState = {
  status: 'idle',
  data: {
    title: 'Did You Know?',
    image: '',
    content: '',
    categories: [],
  },
}

const didYouKnowSlice = createSlice({
  name: 'didYouKnowWidget',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchDidYouKnow.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchDidYouKnow.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchDidYouKnow.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.data.title = action.payload.title
      state.data.image = action.payload.image
      state.data.content = action.payload.content
      state.data.categories = action.payload.categories
    },
  },
})
// List of selector functions that can be used to access state inside of the DidYouKnow.js Component.
export const selectDYKData = (state) => state.didYouKnow.data
export const selectDYKStatus = (state) => state.didYouKnow.status

export default didYouKnowSlice.reducer
