import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) => ({
  caseIndicatorContainer: {
    backgroundColor: theme.palette.base.red,
    color: theme.palette.base.white,
    borderRadius: '50%',
    height: 22,
    width: 22,
    marginLeft: 5,
  },
}))

export default styles
