/* eslint-disable no-unused-vars */
import * as React from 'react'
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography as Text,
  IconButton,
  ListItemSecondaryAction,
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'

import DirectionsBusIcon from '@material-ui/icons/DirectionsBus'
import {
  handleSearchBarToggle,
  removeRouteFromFavorite,
  addRouteToFavorite,
  setSelectedFavoritedRoutes,
  setSelectedRoute,
  fetchShapesAsGeoJSON,
  fetchVehiclePositions,
  setZoom,
} from '../../../sections/Map/redux/myMapsSlice'
import { busListComponentStyles } from './busListStyles'
import { sortListAlphabetically } from '../../../utils/helpers'
import CheckIsMobile from '../../../../../../utils/isMobileHook'
import { logMapEvent } from '../../../utils/events'
import {
  BUSFAVORITE,
  BUSUNFAVORITE,
  ROUTEMARKER,
} from '../../../utils/constants'
import { getEnhancedStops } from '../../../sections/Map/helpers/helpers'
import { storeFavoriteRoutesListToFirebase } from '../../../sections/Map/firebase/firebase'

const BusListComponent = ({
  route,
  busName,
  busColor,
  routeID,
  isFavorited,
}) => {
  const classes = busListComponentStyles()
  const isMobile = CheckIsMobile()
  const history = useHistory()
  const [favorited, setFavorited] = React.useState(isFavorited)

  const { trips, favoritedRoutes } = useSelector((s) => s.maps)

  React.useEffect(() => {
    // default to favorited
    const isFavoritedFound = favoritedRoutes?.find(
      (favoritedRoute) => favoritedRoute.route_id === route.route_id,
    )
    if (isFavoritedFound) {
      setFavorited(true)
    } else {
      setFavorited(false)
    }
  }, [favoritedRoutes])

  const dispatch = useDispatch()

  const handleBusClick = async () => {
    dispatch(handleSearchBarToggle(false))
    const tripsIds = trips[routeID]
    const tripId = tripsIds[0]
    dispatch(fetchShapesAsGeoJSON(tripId))

    // Fetch stops by route ID
    const enhancedStops = await getEnhancedStops(tripsIds, routeID)

    // TODO Set the # in the component instead
    const updatedRoute = {
      ...route,
      route_color: route.route_color,
      route_text_color: route.route_text_color,
    }

    const updatedBusRoute = {
      busRoute: updatedRoute,
      enhancedStops,
      openSheet: false,
    }

    // Dispatch the action with the updated busRoute object
    dispatch(setSelectedRoute(updatedBusRoute))
    // Select a random stop to display. Maybe we can improve this to be the closest stop to user
    // dispatch(handleStopOnRouteClicked(enhancedStops[0]))
    dispatch(fetchVehiclePositions())
    dispatch(setZoom(14))

    logMapEvent(dispatch, ROUTEMARKER, route.route_long_name)
  }

  const addToFavorite = (e) => {
    e.preventDefault()
    e.stopPropagation()
    let newFavoriteList = []
    setFavorited(!favorited)
    if (favorited) {
      // If already favorited, remove
      newFavoriteList = favoritedRoutes.filter((r) => r.route_id !== routeID)

      dispatch(
        removeRouteFromFavorite(
          sortListAlphabetically(newFavoriteList, 'route_long_name'),
        ),
      )
      //   setFavorited(false)
    } else {
      // To do add building to list
      newFavoriteList = [...favoritedRoutes]
      newFavoriteList.push(route)

      dispatch(
        addRouteToFavorite(
          sortListAlphabetically(newFavoriteList, 'route_long_name'),
        ),
      )
    }
    dispatch(
      setSelectedFavoritedRoutes(
        sortListAlphabetically(newFavoriteList, 'route_long_name'),
      ),
    )
    storeFavoriteRoutesListToFirebase(newFavoriteList.map((r) => r.route_id))
    logMapEvent(
      dispatch,
      favorited ? BUSUNFAVORITE : BUSFAVORITE,
      route.route_long_name,
    )
  }

  return (
    <ListItem
      button
      onClick={handleBusClick}
      key={`${busName}-${busName}`}
      className={classes.list}
    >
      <ListItemIcon style={{ width: '100%' }}>
        <DirectionsBusIcon
          style={{ color: busColor }}
          className={classes.listItemIcon}
        />

        <ListItemText
          disableTypography
          primary={
            <Text type="body2" className={classes.busListText}>
              {busName}
            </Text>
          }
        />
      </ListItemIcon>
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          aria-label="add/remove favorited building"
          onClick={addToFavorite}
        >
          {favorited ? (
            <FavoriteIcon className={classes.favoriteIconSelected} />
          ) : (
            <FavoriteBorderIcon className={classes.iconButtonBorder} />
          )}
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default BusListComponent
