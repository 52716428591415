import React from 'react'
import Select from 'react-select'
import { keysToShowForAdmin } from '../../../../constants'
import useMultiSelectStyles from './MultiSelectStyles'

const customStyles = {
  control: (base) => ({
    ...base,
    width: '400px',
  }),
}

function MultiSelect({ k, focusedWidget, handleFormChange, isMulti }) {
  const classes = useMultiSelectStyles()
  const { options } = keysToShowForAdmin[k]
  const [value, setValue] = React.useState([])

  const initVal = () => {
    const widgetVal = focusedWidget[k]

    let val = null

    // Check if widgetVal is a string
    if (typeof widgetVal === 'string') {
      // Check if widgetVal is an empty string
      if (widgetVal.trim() === '') {
        // Set val to an empty array if widgetVal is an empty string
        val = []
      } else {
        try {
          // Try to parse widgetVal as a JSON string
          val = JSON.parse(widgetVal).map((v) =>
            options.find((option) => option.value === v),
          )
        } catch {
          // If widgetVal is not a valid JSON string, set val to the option that matches the string value
          val = options.find((option) => option.value === widgetVal)
        }
      }
    } else if (Array.isArray(widgetVal)) {
      // If widgetVal is an array, map its values to options
      val = widgetVal.map((v) => options.find((option) => option.value === v))
    } else {
      // If widgetVal is not a string or an array, set val to the option that matches the value
      val = options.find((option) => option.value === widgetVal)
    }
    // Set the value using setValue
    setValue(val)
  }

  React.useEffect(() => {
    initVal()
  }, [])

  React.useEffect(() => {
    initVal()
  }, [focusedWidget[k]])

  return (
    <Select
      className={classes.root}
      isMulti={isMulti}
      styles={customStyles}
      options={options}
      value={value}
      onChange={(selectedOptions) => handleFormChange(k, selectedOptions)}
    />
  )
}

export default MultiSelect
