import { makeStyles } from '@material-ui/core/styles'

// TODO Bring Back Unique Colors
export const styles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.base.white,
    fontSize: 40,
    '&:hover': {
      transform: 'scale(1.07)',
    },
  },
  iconLinkContainer: {
    padding: '5px',
  },
  text: {
    color: theme.palette.primary.headerText,
  },
  rowContainer: {
    margin: '15px 0px',
    textDecoration: 'none',
    fontSize: '1.5em',
    '&:hover': {
      transform: 'scale(1.02)',
      textDecoration: 'underline',
    },
  },
  verticalSpace: {
    marginBottom: '8px',
  },
}))

export default styles
