import { collection, doc, getDoc, updateDoc } from 'firebase/firestore'
import { firestore } from '../../../../../../utils/firebase'

export async function fetchFirebaseMarkersApi() {
  const docRef = doc(firestore, 'MyMaps', 'customMarkers')
  const docSnapshot = await getDoc(docRef)

  if (docSnapshot.exists()) {
    return docSnapshot.data().markers
  }
  throw new Error('No markers found in Firebase')
}

export const storeFavoriteRoutesListToFirebase = (newFavoritesList) => {
  const firebaseID = localStorage.getItem('user') ?? 'visitor'
  const favoritesDocRef = doc(
    collection(firestore, 'users'),
    firebaseID,
    'settings',
    'favorites',
  )
  // Store this new list to firebase
  updateDoc(favoritesDocRef, { routes: newFavoritesList })
}

export const storeFavoriteStopsListToFirebase = (newFavoritesList) => {
  const firebaseID = localStorage.getItem('user') ?? 'visitor'
  const favoritesDocRef = doc(
    collection(firestore, 'users'),
    firebaseID,
    'settings',
    'favorites',
  )
  // Store this new list to firebase
  updateDoc(favoritesDocRef, { stops: newFavoritesList })
}
