/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchTeachingSchedule = createAsyncThunk(
  'fetchTeachingSchedule',
  async () => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const rcpid = localStorage.getItem('rcpid')
    const res = await axios.get(
      process.env.REACT_APP_MYTEACHINGSCHEDULE_ENDPOINT,
      {
        headers: {
          JWT: jwtToken,
          XWT: rcpid,
        },
      },
    )
    return res.data
  },
)

const initialState = {
  status: 'idle',
  tab: 'ru',
  data: {
    title: 'My Teaching Schedule',
  },
}

const MyTeachingScheduleSlice = createSlice({
  name: 'myTeachingSchedule',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchTeachingSchedule.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchTeachingSchedule.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchTeachingSchedule.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.tab = action.payload?.rbhs?.length > 0 ? 'rbhs' : 'ru'
      state.data = action.payload
    },
  },
})

export const selectTeachingScheduleData = (state) =>
  state.myTeachingSchedule.data
export const selectTeachingScheduleStatus = (state) =>
  state.myTeachingSchedule.status

export default MyTeachingScheduleSlice.reducer
