import { MAX_ARTICLES_PER_SECTION } from '../../constants'

const ONE_ARTICLE_PER_ROW = [12]
const TWO_ARTICLES_PER_ROW = [6, 6]
const THREE_ARTICLES_PER_ROW = [4, 4, 4]

// Layout for News Page
export const layoutTemplate = {
  desktop: {
    5: [...TWO_ARTICLES_PER_ROW, ...THREE_ARTICLES_PER_ROW],
    4: [...TWO_ARTICLES_PER_ROW, ...TWO_ARTICLES_PER_ROW],
    3: THREE_ARTICLES_PER_ROW,
    2: TWO_ARTICLES_PER_ROW,
    1: ONE_ARTICLE_PER_ROW,
    0: [],
  },
  mobile: {
    5: [
      ...ONE_ARTICLE_PER_ROW,
      ...TWO_ARTICLES_PER_ROW,
      ...TWO_ARTICLES_PER_ROW,
    ],
    4: [...TWO_ARTICLES_PER_ROW, ...TWO_ARTICLES_PER_ROW],
    3: [...ONE_ARTICLE_PER_ROW, ...TWO_ARTICLES_PER_ROW],
    2: TWO_ARTICLES_PER_ROW,
    1: ONE_ARTICLE_PER_ROW,
    0: [],
  },
}
export const WHICH_ARTICLE_LAYOUT = { WITH_IMAGE: 0, WITHOUT_IMAGE: 1, BOTH: 2 }

export const PLACEHOLDER_ARTICLES = Array.from(
  { length: MAX_ARTICLES_PER_SECTION },
  (_, index) => ({
    id: `loading-${index}`,
    sourceID: '',
    sourceName: '',
    published: '',
    imageUrl: 'temp',
  }),
)
