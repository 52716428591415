/* eslint-disable */
import React from 'react'
import Box from '@material-ui/core/Box'
import Widget from './Widget'
import { ErrorMessage } from '../DashboardComponents/ErrorMessages/ErrorMsg'

class WidgetErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Widget className={this.props.className}>
          <Widget.Header title={this.props.title} category="default" />
          <Widget.Content>
            <Box display="flex" justifyContent="center">
              <ErrorMessage />
            </Box>
          </Widget.Content>
        </Widget>
      )
    }

    return this.props.children
  }
}

export default WidgetErrorBoundary
