import { makeStyles } from '@material-ui/core/styles'

export const useNewsSectionReorderStyles = makeStyles((theme) => ({
  listIconStyle: {
    color: theme.palette.components.sideNavIcon,
  },
  dialog: {
    minWidth: '500px',
    width: '70vw',
    maxWidth: '100%',
    height: '70vh',
    maxHeight: '450px',
    overflow: 'hidden',
    padding: '0px',
    backgroundColor: theme.palette.backgroundColor.secondary,
    [theme.breakpoints.down('sm')]: {
      minWidth: '100px',
      width: '90vw',
    },
  },
}))

export default useNewsSectionReorderStyles
