/* eslint-disable no-param-reassign */
import * as React from 'react'

import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

// import { storage } from '../../../../../utils/firebase'

import {
  selectCareerServicesData,
  selectCareerServicesStatus,
  fetchCareerServices,
} from './CareerServicesSlice'
import styles from './CareerServicesStyles'
import InlineIconButtons from '../../DashboardComponents/InlineIconButtons/InlineIconButtons'
import InlineSocialMediaIcons from '../../DashboardComponents/InlineSocialMediaIcons/InlineSocialMediaIcons'
import LinkList from '../../DashboardComponents/LinkList.js/LinkList'
import Loading from '../helpers/Loading'
import Widget, { Spacer } from '../Widget'
import { getImageFromFirebaseWithHook } from '../../../apps/helpers'
import { logDashboardEvent } from '../../events'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'
// eslint-disable-next-line no-unused-vars
function CareerServices({
  className,
  title,
  widgetId,
  showNote,
  noteMessage,
  notePosition,
  noteBackground,
  noteIcon,
  noteUrl,
  noteFontColor,
}) {
  const classes = styles()
  const dispatch = useDispatch()
  const widgetData = useSelector(selectCareerServicesData)
  const widgetStatus = useSelector(selectCareerServicesStatus)

  React.useEffect(() => {
    dispatch(fetchCareerServices())
  }, [])

  return (
    <Widget className={className}>
      <Widget.Header title={title} category="default" />
      <Widget.Content
        className={classes.container}
        showNote={showNote}
        noteMessage={noteMessage}
        notePosition={notePosition}
        noteBackground={noteBackground}
        noteIcon={noteIcon}
        noteUrl={noteUrl}
        noteFontColor={noteFontColor}
      >
        {renderCareerServices(
          widgetStatus,
          widgetData,
          widgetId,
          dispatch,
          classes,
        )}
      </Widget.Content>
    </Widget>
  )
}

function renderCareerServices(
  widgetCallState,
  widgetData,
  widgetId,
  dispatch,
  classes,
) {
  // TODO: Handle Proper Error States
  switch (widgetCallState) {
    case 'fulfilled':
      return (
        <Loaded
          widgetData={widgetData}
          classes={classes}
          widgetId={widgetId}
          dispatch={dispatch}
        />
      )
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage />
        </Grid>
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item></Grid>
        </Grid>
      )
  }
}

function Loaded({ widgetData, widgetId, dispatch, classes }) {
  const [image, setImg] = React.useState('')

  React.useEffect(() => {
    if (widgetData && widgetData.imageLocation) {
      getImageFromFirebaseWithHook(widgetData.imageLocation, setImg)
    }
  }, [])

  const handleButtonClick = (link, title) => {
    if (widgetId && dispatch) {
      logDashboardEvent(dispatch, 'dashboardEvent', widgetId, title, link)
    }
    window.open(link, '_blank')
  }

  return (
    <Grid container direction="column">
      <Grid
        item
        onClick={() =>
          handleButtonClick(widgetData.imageURL, 'handshake homepage')
        }
      >
        <img src={image} alt="handshake homepage" className={classes.image} />
      </Grid>
      <Grid item>
        <InlineIconButtons
          buttons={widgetData.inlineIconButtons}
          widgetId={widgetId}
          dispatch={dispatch}
        />
      </Grid>
      <Grid item>
        <LinkList
          links={widgetData.linkList}
          widgetId={widgetId}
          dispatch={dispatch}
        />
      </Grid>
      <Grid item container direction="column" alignItems="center">
        <Grid item>
          <Typography variant="subtitle1">Locations</Typography>
        </Grid>
        <Grid item container direction="column" alignItems="center">
          {widgetData.locations.map((location) => (
            <Grid
              key={location}
              item
              xs
              container
              direction="column"
              alignItems="center"
            >
              {location.split('\\n').map((segment) => (
                <Grid item key={segment}>
                  <Typography align="center" variant="caption">
                    {segment}
                  </Typography>
                </Grid>
              ))}
              <Spacer />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" align="center">
          Connect with Career Services
        </Typography>
      </Grid>
      <Grid item>
        <InlineSocialMediaIcons
          buttons={widgetData.inlineSocialMediaIcons}
          widgetId={widgetId}
          dispatch={dispatch}
        />
      </Grid>
    </Grid>
  )
}

export default CareerServices
