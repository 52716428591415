import * as React from 'react'

import _ from 'lodash'
import { Menu, IconButton, Grid, Typography, Tooltip } from '@material-ui/core'
import { useSelector } from 'react-redux'
import ScheduleIcon from '@material-ui/icons/WatchLater'
import CancelIcon from '@material-ui/icons/Cancel'

import {
  closeBottomSheet,
  fetchStops,
  resetMap,
  resetRouteShape,
  setLatLang,
  setZoom,
} from '../../../Map/redux/myMapsSlice'
import {
  resetBuilding,
  resetParking,
} from '../../../Buildings/redux/myBuildingsSlice'

import { ROUTE, VEHICLE } from '../../../../utils/constants'
import { selectDarkMode } from '../../../../../settings/settingsSlice'
import VehicleStopArrivalList from '../VehicleStopArrivalList/VehicleStopArrivalList'
import useVehicleStopArrivalListDropDownStyles from './VehicleStopArrivalListDropDownStyles'
import { getLatLang } from '../../../Buildings/utils/helpers'

const VehicleStopArrivalListDropDown = ({ isMobile, dispatch }) => {
  // eslint-disable-next-line no-use-before-define
  const vehicleListRef = React.useRef(null)
  const isDark = useSelector(selectDarkMode)
  const classes = useVehicleStopArrivalListDropDownStyles()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [route, setRoute] = React.useState({
    long_name: '',
    color: '',
    route_text_color: '',
  })
  const { enhancedStops, selectedRoute, bottomSheet, selectedCampus } =
    useSelector((s) => s.maps)

  React.useEffect(() => {
    if (selectedRoute) {
      setRoute({
        long_name: selectedRoute.route_long_name,
        color: selectedRoute.route_color,
        route_text_color: selectedRoute.route_text_color,
      })
    }
  }, [selectedRoute])
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const exitRoutes = () => {
    dispatch(setLatLang(getLatLang(selectedCampus)))
    dispatch(resetRouteShape())
    dispatch(closeBottomSheet(null))
    dispatch(fetchStops())
    dispatch(resetMap())
    dispatch(resetBuilding())
    dispatch(resetParking())
    dispatch(setZoom(14))
  }

  return (
    <>
      {!_.isEmpty(enhancedStops) &&
        (bottomSheet.component === ROUTE ||
          bottomSheet.component === VEHICLE) && (
          <Grid
            container
            direction="row"
            alignItems="center"
            className={classes.root}
            style={{ backgroundColor: route.color }}
          >
            <Grid item>
              <Typography className={classes.routeLongNameText}>
                {route.long_name}
              </Typography>
            </Grid>
            <Grid item className={classes.gridButton}>
              <div className={classes.flex}>
                <Tooltip
                  title={
                    <Typography variant="subtitle2">
                      View Route Times
                    </Typography>
                  }
                  placement="bottom"
                  arrow
                >
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="View Route Times"
                    onClick={handleClick}
                    style={{
                      padding: '8px',
                      color: route.route_text_color,
                    }}
                  >
                    <ScheduleIcon className={classes.appBarIcon} />
                  </IconButton>
                </Tooltip>
                <Menu
                  className={classes.alertMenu}
                  keepMounted
                  elevation={5}
                  anchorEl={anchorEl}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      minWidth: '20vw',
                      backgroundColor: isDark ? '#313131' : '#f7f7f7',
                    },
                  }}
                >
                  <VehicleStopArrivalList ref={vehicleListRef} />
                </Menu>
              </div>
            </Grid>
            {!isMobile && (
              <Grid item className={classes.gridButton}>
                <Tooltip
                  title={
                    <Typography variant="subtitle2">Exit Route</Typography>
                  }
                  placement="bottom"
                  arrow
                >
                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="Exit Route"
                    onClick={() => exitRoutes()}
                    style={{
                      padding: '8px',
                      color: route.route_text_color,
                    }}
                  >
                    <CancelIcon className={classes.appBarIcon} />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        )}
    </>
  )
}

export default VehicleStopArrivalListDropDown
