import React, { useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import {
  Typography as Text,
  Grid,
  Button,
  IconButton,
  Toolbar,
} from '@material-ui/core'
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { useLocation } from 'react-router-dom'
import {
  createIcon,
  convertGSImgList,
  selectFavoritedAppAndUpdate,
} from '../../../helpers'
import ViewAppInfoPageStyles from './AppInfoPageStyles'
import SetCarouselDimensions from '../../../AppComponents/AppCarouselCombined/AppCarouselDimensions'
import ShowAppList from '../../../AppComponents/ShowAppList/ShowAppList'
import { fetchMyApps } from '../../../appSlice'
import AppCarouselCombined from '../../../AppComponents/AppCarouselCombined/AppCarouselCombined'
import { addProfileLogEvent } from '../../../../dashboard/widgets/MyProfile/myProfileSlice'
import CheckIsMobile from '../../../../../../utils/isMobileHook'
import CustomDotGroup from '../../../AppComponents/AppCarouselCombined/AppCarouselDots'

const ImageCarousel = ({ classes, app }) => {
  const [images, setImages] = React.useState([])
  const [showImages, setShowImages] = React.useState(false)
  React.useEffect(() => {
    const getImageData = async () => {
      if (app.screenshotUrls) {
        const imageList = await convertGSImgList(app.screenshotUrls)
        setImages(imageList)
        setShowImages(true)
      } else {
        setShowImages(false)
      }
    }
    getImageData()
  }, [app])
  return showImages ? (
    <Grid container className={classes.categoryCardSliderContainer}>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={100}
        totalSlides={images.length}
        className={classes.carouselProvider}
        interval="5000"
        isPlaying="true"
        playDirection="forward"
      >
        <Slider className={classes.sliderContainer}>
          {images.map((img) => (
            <Slide key={img}>
              <div className={classes.imageStyle}>
                <img alt="temp" src={img} />
              </div>
            </Slide>
          ))}
        </Slider>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item>
            <ButtonBack className={classes.buttonStyle}>
              <ArrowBackIosIcon className={classes.backArrow} />
            </ButtonBack>
          </Grid>

          <Grid item className={classes.dotGroup}>
            <div className={classes.customDotGroupContainer}>
              <CustomDotGroup slides={images.length} />
            </div>
          </Grid>

          <Grid item>
            <ButtonNext className={classes.buttonStyle}>
              <ArrowForwardIosIcon className={classes.forwardArrow} />
            </ButtonNext>
          </Grid>
        </Grid>
      </CarouselProvider>
    </Grid>
  ) : null
}

export const ViewAppInfoPage = () => {
  const classes = ViewAppInfoPageStyles()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const isMobile = CheckIsMobile()
  const dimensions = SetCarouselDimensions()
  const ref = useRef()

  const {
    apps,
    appCategories,
    appCategoriesWithApps,
    status,
    displaySearchItems,
    searchBarAppList,
    isGuest,
  } = useSelector((s) => s.myApps)

  const [app, setApp] = React.useState('')
  const [favoriteSelected, setFavoriteSelected] = React.useState(false)
  const [appCategory, setAppCategory] = React.useState('')
  const [appCarousel, setAppCarousel] = React.useState(null)
  const [icon, setIcon] = React.useState(null)

  const createPage = async (func) => {
    if (status === 'idle' || status === 'pending') {
      dispatch(fetchMyApps())
    } else {
      const query = location.search
      const id = new URLSearchParams(query).get('appID')
      const selectedApp = apps.filter((a) => a.id === id)[0]
      const category = appCategories.filter(
        (c) => c.id === selectedApp.category.toString(),
      )[0]
      func(category, selectedApp)
    }
  }

  const createPageContent = async (category, selectedApp) => {
    createCarousel(category)
    setAppCategory(category)
    setApp(selectedApp)
    setIcon(createIcon(selectedApp.icon, '3x'))
    setFavoriteSelected(selectedApp.isFavorited)
  }

  // Fetch the data first then create page.
  // Need to fetch data because if url is sent to someone, page doesn't have data yet
  React.useEffect(() => {
    ref.current.scrollTop = 0
    createPage(createPageContent)
  }, [status, location])

  // Recreate carousel when dimensions change which is triggered by page width
  React.useEffect(() => {
    createPage(createCarousel)
  }, [dimensions])

  const handleFavorited = () => {
    setFavoriteSelected(!favoriteSelected)
    selectFavoritedAppAndUpdate(dispatch, app.id)
  }

  const createCarousel = (category) => {
    const appName = category.name
    const sortedApps = appCategoriesWithApps.filter(
      (l) => l.name === category.name,
    )[0]
    const carousel = (
      <AppCarouselCombined
        key={appName}
        appList={sortedApps.apps}
        carouselHeader="Related Apps"
        category={appName}
        isGuest={isGuest}
        dimensions={dimensions}
        isMobile={isMobile}
        showCategoryText
      />
    )
    setAppCarousel(carousel)
  }

  const goBackButton = () => {
    window.scrollTo(0, 0)
    history.goBack()
  }

  return displaySearchItems ? (
    <div className={classes.container}>
      <Toolbar />
      <ShowAppList isMobile={false} inputAppList={searchBarAppList} />
    </div>
  ) : (
    <div className={classes.container} ref={ref}>
      <Grid container className={classes.topContainer}>
        <Grid item>
          <IconButton onClick={() => goBackButton()}>
            <ArrowBackIosIcon className={classes.mobileBackButtonIcon} />
          </IconButton>
        </Grid>
        <Grid
          xs={2}
          item
          className={classes.iconContainer}
          style={{ backgroundColor: app.color }}
        >
          {icon}
        </Grid>

        <Grid item xs={8} className={classes.headingContainer}>
          <Grid container direction="column" alignItems="flex-start">
            <Grid item>
              <Text component="span" className={classes.appNameText}>
                {app.title}
              </Text>
            </Grid>

            <Grid item>
              <Text component="span" className={classes.categoryText}>
                {appCategory.name}
              </Text>
            </Grid>

            <Grid container alignItems="center" justifyContent="flex-start">
              <Grid item>
                <IconButton onClick={() => handleFavorited()}>
                  {favoriteSelected ? (
                    <FavoriteIcon
                      className={classes.selectedFavoriteIconButton}
                    />
                  ) : (
                    <FavoriteBorderIcon className={classes.iconButtonBorder} />
                  )}
                  {/* <FavoriteIcon
                    className={
                      favoriteSelected
                        ? classes.selectedFavoriteIconButton
                        : classes.iconButton
                    }
                  /> */}
                </IconButton>
              </Grid>

              <Grid item>
                <Button
                  className={classes.openButton}
                  onClick={() => {
                    if (process.env.REACT_APP_FIREBASE_ON === 'true') {
                      dispatch(
                        addProfileLogEvent({
                          module: 'Apps',
                          object: 'AppTile',
                          objectId: 'AppsInfo',
                          action: 'Click',
                          actionContext: 'ViewFragmentPage',
                          actionContextValue: app.id,
                          rcpid: localStorage.getItem('rutgersEduRCPID'),
                          timestamp: Date.now(),
                          deviceType: 'Web',
                        }),
                      )
                    }
                    window.open(app.url)
                  }}
                >
                  Open
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          className={classes.descriptionContainer}
        >
          <Grid item>
            <Text className={classes.descriptionHeaderText}>
              {app.shortSummary}
            </Text>
          </Grid>

          <Grid item className={classes.infoTextContainer}>
            <Text
              className={classes.infoText}
              dangerouslySetInnerHTML={{ __html: app.info }}
            />
          </Grid>
        </Grid>
      </Grid>
      <ImageCarousel classes={classes} apps={apps} app={app} />
      {appCarousel}
    </div>
  )
}

export default ViewAppInfoPage
