import React from 'react'
import {
  Typography as Text,
  IconButton,
  Grid,
  AppBar,
  Toolbar,
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'

import useNewsSourceListMobileStyles from './NewsSourceListMobileStyles'
import NewsSourceList from '../../components/NewsSourceList/NewsSourceListMobile'
import { setNews, updateUnsubscribedSources } from '../../newsSlice'
import { NewsTabsMobile } from '../../components/NewsTabsMobile/NewsTabsMobile'
import ReorderableNewsSection from '../../components/ReorderNewsSections/ReorderableNewsSection'
import {
  extractSectionsFromNewsList,
  setSectionsOrder,
  setUnsubscribedNewsSource,
} from '../../helpers'

function SourcesPanel({ newsStatus, unsubscribedList, setUnsubscribedList }) {
  return (
    <>
      {newsStatus === 'fulfilled' && (
        <NewsSourceList
          unsubscribedList={unsubscribedList}
          setUnsubscribedList={setUnsubscribedList}
        />
      )}
    </>
  )
}

function CategoriesPanel({ newsSections, setNewsSections }) {
  return (
    <>
      <ReorderableNewsSection
        newsSections={newsSections}
        setNewsSections={setNewsSections}
        setEdited={() => {}}
      />
    </>
  )
}

function NewsSourceListMobile() {
  const dispatch = useDispatch()
  const classes = useNewsSourceListMobileStyles()
  const history = useHistory()
  const { news, newsStatus, unsubscribedSources } = useSelector((s) => s.news)
  const [unsubscribedList, setUnsubscribedList] = React.useState([])
  const [newsSections, setNewsSections] = React.useState([])

  const newsPanels = ['Sources', 'Categories']

  React.useEffect(() => {
    setUnsubscribedList(unsubscribedSources)
  }, [unsubscribedSources])

  React.useEffect(() => {
    const mapped = Object.entries(news)
      .sort(([, a], [, b]) => a.order - b.order)
      .map(([, value]) => value)
    setNewsSections(mapped)
  }, [news])

  const updateOrder = () => {
    const updatedNewsSections = newsSections.map((item, index) => ({
      ...item,
      order: index,
    }))

    setSectionsOrder(extractSectionsFromNewsList(updatedNewsSections))
    dispatch(setNews(updatedNewsSections))
  }

  const exitPage = () => {
    setUnsubscribedNewsSource(unsubscribedList)
    dispatch(updateUnsubscribedSources(unsubscribedList))
    updateOrder()
    history.goBack()
  }

  return (
    <div className={classes.container}>
      <AppBar
        className={classes.appBar}
        position="fixed"
        elevation={2}
        style={{ backgroundColor: '#cc0033' }}
      >
        <Toolbar className={classes.container}>
          <Grid container justifyContent="flex-start" alignItems="center">
            <Grid item sm={4}>
              <IconButton onClick={() => exitPage()}>
                <ArrowBackIosIcon className={classes.mobileBackButtonIcon} />
              </IconButton>
            </Grid>
            <Grid item sm={4}>
              <Text className={classes.pageTitle}> Customize myNews </Text>
            </Grid>
          </Grid>
          <Grid item sm={4}>
            <></>
          </Grid>
        </Toolbar>
      </AppBar>

      <div style={{ paddingTop: 55 }}>
        <NewsTabsMobile
          tabs={newsPanels}
          updateOrder={updateOrder}
          TabOne={
            <SourcesPanel
              newsStatus={newsStatus}
              unsubscribedList={unsubscribedList}
              setUnsubscribedList={setUnsubscribedList}
            />
          }
          TabTwo={
            <CategoriesPanel
              newsSections={newsSections}
              setNewsSections={setNewsSections}
            />
          }
        />
      </div>
    </div>
  )
}

export default NewsSourceListMobile
