import * as React from 'react'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { Grid, Typography as Text, Card, Link } from '@material-ui/core'
import { ToDoObjectStyles } from './facultyStaffCenterStyles'
import { logDashboardEvent } from '../../events'

/**
 * Maps all to-do tasks in a case
 * @param cases The case object to be passed down
 */
function ToDoObject({ cases, widgetId, dispatch }) {
  const taskList = cases.task_list

  return taskList.map((toDoCase) => (
    <ToDoObjectBody
      cases={cases}
      toDoCase={toDoCase}
      widgetId={widgetId}
      dispatch={dispatch}
    />
  ))
}

export default ToDoObject

/**
 * To-Do object that shows the title, display_value (as hyperlink) and
 * stateLabel of a case task_list
 * @param cases Original case object
 * @param toDoCase Specific to-do task being displayed
 */
function ToDoObjectBody({ cases, toDoCase, widgetId, dispatch }) {
  const classes = ToDoObjectStyles()

  const link = `${process.env.REACT_APP_FACULTYSTAFF_BASE_URL}/hrportal?id=hrj_ticket_page&sys_id=${toDoCase.todo_sys_id}`

  const handleLinkClick = (eventLink, title) => {
    if (widgetId && dispatch) {
      logDashboardEvent(dispatch, 'dashboardEvent', widgetId, title, eventLink)
    }
  }

  return (
    <Card className={classes.caseCardBackground} border={1} variant="outlined">
      <Grid container justifyContent="flex-start" direction="column">
        <Grid item className={classes.linkStyle}>
          <Link
            href={link}
            underline="none"
            target="_blank"
            onClick={() => handleLinkClick(link, toDoCase.title)}
          >
            <Text variant="subtitle1">{toDoCase.title}</Text>
          </Link>
        </Grid>

        <Grid item>
          <Text variant="body1">{toDoCase.display_value}</Text>
        </Grid>

        <Grid item className={classes.gridItem}>
          <Grid
            container
            direction="row"
            wrap="nowrap"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item>
              <Text variant="body1">Status:</Text>
            </Grid>

            {/* Change color of label and icon depending on status */}
            <Grid item container direction="row" alignItems="center">
              <FiberManualRecordIcon
                fontSize="small"
                className={
                  cases.stateLabel === 'Ready'
                    ? classes.greenIcon
                    : classes.yellowIcon
                }
              />
              <Text
                variant="body1"
                className={
                  cases.stateLabel === 'Ready'
                    ? classes.greenText
                    : classes.yellowText
                }
              >
                {`${cases.stateLabel}`}
              </Text>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}
