import { makeStyles } from '@material-ui/core/styles'

export const useBigArticleStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.paper.primary,
  },
  cardContent: {
    height: '140px',
    [theme.breakpoints.down('md')]: {
      height: '127px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '100px',
    },
  },
  articleImage: {
    height: '300px',
    [theme.breakpoints.down('sm')]: {
      height: '180px',
    },
    borderRadius: '2px',
  },
  articleSourceText: {
    fontSize: '16px',
    paddingLeft: 4,
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      paddingLeft: 0,
    },
    color: theme.palette.base.red,
    fontWeight: 600,
    paddingTop: 2,
    letterSpacing: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  articleTitle: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 2,
    wordBreak: 'keep-all',
    overflow: 'hidden',
    fontSize: '18px',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    fontWeight: 600,
    color: theme.palette.primary.headerText,
    textAlign: 'left',
  },
  buttonText: {
    fontSize: '12px',
    color: theme.palette.base.white,
    textTransform: 'none',
  },
  timeElapsedText: {
    fontSize: '14px',
    [theme.breakpoints.down('md')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    fontWeight: 500,
    color: theme.palette.secondary.headerText,
    marginRight: '2px',
  },
  titleContainer: {
    paddingLeft: 12,
    paddingRight: 12,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  bottomContainer: {
    marginTop: 6,
    paddingBottom: 8,
    paddingLeft: 3,
    paddingRight: 3,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 2,
    },
  },
  button: {
    height: '30px',
    width: '100px',
    borderRadius: '8px',
    backgroundColor: theme.palette.base.rutgersRed,
    marginLeft: '8px',
    '&:hover': {
      backgroundColor: '#B00020',
    },
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 'auto',
    height: 'auto',
  },
  container: {
    overflow: 'auto',
    overscrollBehavior: 'contain',
    marginLeft: '10%',
    marginRight: '10%',
    overflowX: 'hidden',
  },
  sponsorLink: {
    textDecoration: 'none',
    '&:hover': {
      background: '#ffffff',
    },
    textAlign: 'bottom',
  },
}))

export default useBigArticleStyles
