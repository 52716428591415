/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchMasonGross = createAsyncThunk('fetchMasonGross', async () => {
  const jwtToken = localStorage.getItem('myrJwtToken')
  const rcpid = localStorage.getItem('rcpid')
  const res = await axios.get(process.env.REACT_APP_MASONGROSS_ENDPOINT, {
    headers: {
      JWT: jwtToken,
      XWT: rcpid,
    },
  })
  return res.data
})

const initialState = {
  status: 'idle',
  data: {
    title: 'Mason Gross Dance',
  },
}

const masonGrossSlice = createSlice({
  name: 'masonGross',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchMasonGross.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchMasonGross.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchMasonGross.fulfilled]: (state, action) => {
      state.data = action.payload
      state.status = 'fulfilled'
    },
  },
})

export const selectMasonGrossData = (state) => state.masonGross.data
export const selectMasonGrossStatus = (state) => state.masonGross.status

export default masonGrossSlice.reducer
