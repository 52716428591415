import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google'

import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Box from '@material-ui/core/Box'
import Badge from '@material-ui/core/Badge'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import EmailIcon from '@material-ui/icons/Email'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import Collapse from '@material-ui/core/Collapse'
import NotificationsIcon from '@material-ui/icons/Notifications'
import CloudIcon from '@material-ui/icons/Cloud'
import DOMPurify from 'dompurify'

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

import Widget from '../Widget'

import {
  selectMyScarletMailData,
  fetchMyScarletMail,
  fetchMyScarletMailEmails,
  fetchMyScarletMailContents,
  fetchMyScarletMailEvents,
  storeMyScarletMailToken,
  clearMyScarletMailToken,
} from './MyEmailScarletMailSlice'
import styles from './MyEmailScarletMailStyles'
import { TabPanel } from '../../DashboardComponents/TabPanel/TabPanel'
import Loading from '../helpers/Loading'
import {
  RutgersTab,
  RutgersTabs,
} from '../../DashboardComponents/Tabs/RutgersTabs'
import { logDashboardEvent } from '../../events'
import IconPicker from '../../../apps/AppComponents/IconPicker/IconPicker'
import { textColorBasedOnBackground } from '../../../../../utils/utils'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'

// API DOCS for get threadId list - https://developers.google.com/gmail/api/reference/rest/v1/users.messages/list?apix_params=%7B%22userId%22%3A%22er647%40scarletmail.rutgers.edu%22%2C%22maxResults%22%3A10%7D
// get list of messages https://gmail.googleapis.com/gmail/v1/users/{userId}/messages
// API DOCS for get Email per ThreadId https://developers.google.com/gmail/api/reference/rest/v1/users.messages/get
// get https://gmail.googleapis.com/gmail/v1/users/er647%40scarletmail.rutgers.edu/messages/185f74133387e1c6?key=[YOUR_API_KEY]
// calendar items https://www.googleapis.com/calendar/v3/users/me/calendarList?key=[YOUR_API_KEY]

// eslint-disable-next-line no-unused-vars
function MyEmailScarletMail({
  className,
  title,
  widgetId,
  showNote,
  noteMessage,
  notePosition,
  noteBackground,
  noteIcon,
  noteUrl,
  noteFontColor,
}) {
  const dispatch = useDispatch()
  const widgetData = useSelector(selectMyScarletMailData)
  const { auth, getMailStatus, status } = useSelector((s) => s.scarletMail)
  const classes = styles()
  const codeResponse = { access_token: auth }
  //   const codeResponse = localStorage.getItem('codeResponse')
  //     ? JSON.parse(localStorage.getItem('codeResponse'))
  //     : ''
  //   const getMailStatus = false
  React.useEffect(() => {
    dispatch(fetchMyScarletMail()).then((res) => {
      const codeResponseObj = { access_token: '' }
      if (res.payload?.auth && res.payload?.auth !== '') {
        codeResponseObj.access_token = res.payload?.auth
        dispatch(fetchMyScarletMailEmails(codeResponseObj)).then((response) => {
          // console.log('response ', response)
          if (response.payload.status === 200) {
            response.payload.data?.messages.forEach((message) => {
              dispatch(
                fetchMyScarletMailContents({
                  codeResponse: codeResponseObj,
                  messageId: message.threadId,
                }),
              ).then(() => {
                dispatch(
                  fetchMyScarletMailEvents({
                    codeResponse: codeResponseObj,
                    today: new Date(),
                  }),
                )
              })
            })
          }
        })
      } else if (
        window.location.hostname === 'localhost' ||
        codeResponse !== ''
      ) {
        dispatch(fetchMyScarletMailEmails(codeResponse)).then((response) => {
          //   console.log('response ', response)
          if (response.payload.status === 200) {
            response.payload.data?.messages.forEach((message) => {
              dispatch(
                fetchMyScarletMailContents({
                  codeResponse,
                  messageId: message.threadId,
                }),
              ).then(() => {
                dispatch(
                  fetchMyScarletMailEvents({
                    codeResponse,
                    today: new Date(),
                  }),
                )
              })
            })
          }
        })
      }
    })
  }, [])

  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_MYSCARLETMAIL_CLIENTID}
    >
      <Widget className={className}>
        <Widget.Header title={title} />
        <Widget.Content
          className={classes.container}
          showNote={showNote}
          noteMessage={noteMessage}
          notePosition={notePosition}
          noteBackground={noteBackground}
          noteIcon={noteIcon}
          noteUrl={noteUrl}
          noteFontColor={noteFontColor}
        >
          {getMailStatus === 'fulfilled' ? (
            renderEmail(
              getMailStatus,
              widgetData,
              classes,
              dispatch,
              codeResponse,
            )
          ) : (
            <ScarletMailActivation
              classes={classes}
              widgetId={widgetId}
              title={title}
              dispatch={dispatch}
              getMailStatus={status}
            />
          )}
        </Widget.Content>
      </Widget>
    </GoogleOAuthProvider>
  )
}

function ScarletMailActivation({ classes, widgetId, title, dispatch }) {
  const loginHook = useGoogleLogin({
    onSuccess: (codeResponse) => {
      localStorage.setItem('codeResponse', JSON.stringify(codeResponse))
      const codeResponseObj = { access_token: '' }
      dispatch(storeMyScarletMailToken(codeResponse)).then((res) => {
        if (res) {
          dispatch(
            fetchMyScarletMailEmails({ access_token: res.payload }),
          ).then((response) => {
            // console.log('fetchMyScarletmailEmails ', response)
            if (response.payload.status === 200) {
              response.payload.data?.messages.forEach((message) => {
                dispatch(
                  fetchMyScarletMailContents({
                    codeResponse: { access_token: res.payload },
                    messageId: message.threadId,
                  }),
                ).then(() => {
                  codeResponseObj.access_token = res.payload
                  dispatch(
                    fetchMyScarletMailEvents({
                      codeResponse: codeResponseObj,
                      today: new Date(),
                    }),
                  )
                })
              })
            }
          })
        }
      })
    },
    onError: (errorResponse) => {
      console.log('error occured ', errorResponse)
    },
    flow: 'auth-code',
    ux_mode: 'popup',
    // redirect_uri: process.env.REACT_APP_MYSCARLETMAIL_REDIRECT_DOMAIN,
    hosted_domain: process.env.REACT_APP_MYSCARLETMAIL_HOSTED_DOMAIN,
    scope:
      'https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/calendar.events.readonly https://www.googleapis.com/auth/calendar.readonly openid',
  })

  const login = () => {
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardEvent',
        widgetId,
        title,
        'https://www.googleapis.com/auth/gmail.readonly',
      )
    }
    loginHook()
  }
  return (
    <Grid container>
      <Box className={classes.emailAndCalendarContainer}>
        <Box className={classes.emailAndCalendarContainerColor}>
          <Typography
            variant="subtitle1"
            className={classes.emailAndCalendarText}
          >
            ScarletMail email & calendar preview widget.
          </Typography>
          <Typography variant="body1" className={classes.emailAndCalendarText}>
            Please click on the Email Preview Activation bottom below and follow
            the instructions to activate this widget:
          </Typography>
        </Box>
      </Box>
      <Box className={classes.emailAndCalendarContainer}>
        <Box className={classes.googleSignInContainer}>
          <Button color="primary" variant="outlined" onClick={() => login()}>
            Sign in with Google 🚀{' '}
          </Button>
        </Box>
      </Box>
    </Grid>
  )
}

function renderEmail(
  widgetStatus,
  widgetData,
  classes,
  dispatch,
  codeResponse,
) {
  switch (widgetStatus) {
    case 'fulfilled':
      return (
        <Loaded
          data={widgetData}
          classes={classes}
          dispatch={dispatch}
          codeResponse={codeResponse}
        />
      )
    case 'loading':
      return <Loading />
    case 'error':
      return <ErrorMessage />
    default:
      return <ErrorMessage />
  }
}

function Loaded({ data, classes, dispatch, codeResponse }) {
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Grid container direction="column">
      <Grid item className={classes.modalTabs}>
        <RutgersTabs value={value} onChange={handleChange}>
          <RutgersTab label="Mail" />
          <RutgersTab label="Calendar" />
          <RutgersTab label="Apps" />
        </RutgersTabs>
      </Grid>
      <Grid item>
        <TabPanel value={value} index={0} className={classes.emailTabContent}>
          <EmailList data={data} classes={classes} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <CalendarTab
              data={data.events}
              classes={classes}
              dispatch={dispatch}
              codeResponse={codeResponse}
            />
          </MuiPickersUtilsProvider>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <QuickLinksTab classes={classes} dispatch={dispatch} />
        </TabPanel>
      </Grid>
    </Grid>
  )
}

function EmailList({ data, classes }) {
  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      className={classes.tabScroll}
    >
      <Grid item>
        <Grid container direction="row" justifyContent="flex-start">
          <Grid item xs={2}>
            <Typography variant="subtitle1" className={classes.headerText}>
              From
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="subtitle1" className={classes.headerText}>
              Subject
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle1">Date/Time</Typography>
          </Grid>
        </Grid>
      </Grid>
      {data.emails.map((email, index) => (
        <Grid item key={email.date?.toString()}>
          <EmailCard email={email} classes={classes} />
          {index !== data.messages?.length - 1 && <Divider variant="middle" />}
        </Grid>
      ))}
    </Grid>
  )
}

function EmailCard({ email, classes }) {
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }
  return (
    <Box>
      <Button onClick={handleOpen} className={classes.emailButton}>
        <Grid item xs={12}>
          <Grid container direction="row" spacing={1} alignItems="flex-start">
            <Grid item style={{ width: 60 }}>
              <SenderImage email={email} classes={classes} />
            </Grid>
            <Grid item style={{ width: 185 }}>
              {email.unRead === true ? (
                <Typography align="left">
                  <b>{email.subject}</b>
                </Typography>
              ) : (
                <Typography align="left">{email.subject}</Typography>
              )}
            </Grid>
            <Grid item>
              {email.unRead ? (
                <Typography>
                  <b>{getDate(email?.date)}</b>
                </Typography>
              ) : (
                <Typography>{getDate(email?.date)}</Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Button>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>
          <Typography variant="h5">{email?.from}</Typography>
          <Typography variant="subtitle1">{email?.subject}</Typography>
        </DialogTitle>
        <DialogContent>
          {email.snippet !== null ? (
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(email.snippet, {
                  ADD_ATTR: ['target'],
                }),
              }}
            />
          ) : (
            <Typography variant="body1">Check your email for more!</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => window.open(email?.link, '_blank')}
            color="primary"
          >
            Open Scarlet Email List
          </Button>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

function SenderImage({ email }) {
  const alphabet = 'abcdefghijklmnopqrstuvwxyz'
  const character = email?.from?.substring(0, 1)
  const colors = [
    '#63d8db',
    '#ecaaae',
    '#67c6f2',
    '#e3c297',
    '#a7b4f0',
    '#d5e5ae',
    '#e1b0dd',
    '#abe7bf',
    '#b4bfe7',
    '#a5c089',
    '#8bd0eb',
    '#8bc2ab',
    '#9de1da',
  ]
  const color =
    colors[Math.floor(alphabet.indexOf(character.toLowerCase()) / 2)]

  if (email.unRead) {
    return (
      <Avatar
        style={{
          backgroundColor: color,
          color: textColorBasedOnBackground(color),
          marginTop: 5,
        }}
      >
        {character}
      </Avatar>
    )
  }

  return (
    <Avatar
      style={{
        backgroundColor: color,
        color: textColorBasedOnBackground(color),
        marginTop: 5,
      }}
    >
      {email.from?.substring(0, 1)}
    </Avatar>
  )
}

function getDate(date) {
  const now = new Date()
  const emailDate = new Date(date)
  if (now.getDate() === emailDate.getDate()) {
    return formatAMPM(date)
  }
  return `${emailDate.getMonth() + 1}/${emailDate.getDate() + 1}`
}

function CalendarTab({ data, classes, dispatch, codeResponse }) {
  const [date, changeDate] = useState(new Date())
  const [dayView, setDayView] = useState(false)
  const findDates = () => {
    const result = []
    data?.forEach((element) => {
      result.push(new Date(element.start).toDateString())
    })
    return result
  }

  const handleMonthChange = (obj) => {
    dispatch(
      // eslint-disable-next-line no-underscore-dangle
      fetchMyScarletMailEvents({ codeResponse, today: new Date(obj._d) }),
    )
  }

  const events = findDates()
  const eventIcon = <FiberManualRecordIcon className={classes.eventIconDot} />
  const [selectedDayEvents, setSelectedDayEvents] = useState([])

  React.useEffect(() => {
    setSelectedDayEvents(
      data.filter(
        (day) =>
          new Date(day.start).toDateString() === new Date(date).toDateString(),
      ),
    )
  }, [date])

  return (
    <Grid
      container
      direction="column"
      className={classes.tabScroll}
      wrap="nowrap"
    >
      <Collapse in={!dayView} className={classes.collapse}>
        <Grid item className={classes.moveCalendar}>
          <DatePicker
            autoOk
            variant="static"
            openTo="date"
            value={date}
            onChange={changeDate}
            onMonthChange={handleMonthChange}
            renderDay={(day, dateParam, isInCurrentMonth, dayComponent) => {
              const eventDay = new Date(day)
              const isSelected =
                isInCurrentMonth && events.includes(eventDay.toDateString())

              return (
                <Badge badgeContent={isSelected ? eventIcon : undefined}>
                  {dayComponent}
                </Badge>
              )
            }}
          />
        </Grid>
      </Collapse>
      {!dayView && <Divider className={classes.calendarDivider} />}
      <Grid item className={dayView ? classes.dayView : classes.eventScroll}>
        <SelectedDayEventsCard
          date={date}
          selectedDayEvents={selectedDayEvents}
          classes={classes}
          dayView={dayView}
          setDayView={setDayView}
        />
      </Grid>
    </Grid>
  )
}

function SelectedDayEventsCard({
  date,
  selectedDayEvents,
  classes,
  dayView,
  setDayView,
}) {
  const displayDate = new Date(date)
  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const handleChange = () => {
    setDayView(!dayView)
  }
  return (
    <Card variant="outlined" className={classes.eventsCard}>
      <Grid container direction="column" alignItems="center">
        <Grid
          item
          container
          direction="row"
          alignItems="center"
          spacing={1}
          justifyContent="center"
          className={classes.eventHeader}
          onClick={handleChange}
        >
          <Grid item>
            <Typography variant="h2">{displayDate.getDate()}</Typography>
          </Grid>
          <Grid
            item
            container
            direction="column"
            className={classes.monthAndYear}
          >
            <Grid item>{month[displayDate.getMonth()]}</Grid>
            <Grid item>{displayDate.getFullYear()}</Grid>
          </Grid>
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            className={classes.dayOverlayHolder}
          >
            <NotificationsIcon className={classes.bellIcon} />
            <Typography variant="subtitle1" className={classes.dayOverlay}>
              {selectedDayEvents.length}
            </Typography>
          </Grid>
        </Grid>
        <Divider variant="middle" className={classes.fullWidth} />
        <Timeline align="alternate">
          {selectedDayEvents.map((event, index) => (
            <TimelineItem>
              <TimelineOppositeContent>
                {formatAMPM(event.start)}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                {index !== selectedDayEvents.length - 1 && (
                  <TimelineConnector />
                )}
              </TimelineSeparator>
              <TimelineContent>
                <EventCard event={event} classes={classes} />
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Grid>
    </Card>
  )
}

function EventCard({ event, classes }) {
  const [isOpen, setIsOpen] = useState(false)
  const handleOpen = () => {
    setIsOpen(true)
  }
  const handleClose = () => {
    setIsOpen(false)
  }
  return (
    <Box>
      <Card
        className={classes.eventCard}
        variant="outlined"
        onClick={handleOpen}
      >
        <Typography variant="subtitle1">{event.title}</Typography>
      </Card>
      <Dialog open={isOpen} onClose={handleClose} fullWidth>
        <DialogTitle>
          <Typography variant="h5">{event.title}</Typography>
        </DialogTitle>
        <DialogContent>
          {event.conferenceData ? (
            <>
              <div style={{ width: '100%' }}>
                <Button
                  variant="contained"
                  color="primary"
                  component="a"
                  target="_blank"
                  href={event.conferenceData?.entryPoints?.[0].uri}
                  className={classes.conferenceButton}
                >
                  {event.conferenceData.conferenceSolution.name}
                </Button>
                <Box
                  display="flex"
                  component="a"
                  href={event.conferenceData?.entryPoints?.[1].uri}
                  target="_blank"
                >
                  <IconPicker
                    color="gray"
                    iconName={['fas', 'phone']}
                    size="2x"
                  />
                  <Typography
                    variant="subtitle1"
                    style={{ marginLeft: 10, marginBottom: 10 }}
                  >
                    Join by Phone
                  </Typography>
                </Box>
                <Typography variant="body1">
                  {event.conferenceData?.entryPoints?.[1].regionCode}{' '}
                  {event.conferenceData?.entryPoints?.[1].label} PIN:{' '}
                  {event.conferenceData?.entryPoints?.[1].pin}#
                </Typography>
              </div>
            </>
          ) : (
            <Typography variant="body1">No details...</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => window.open(event.htmlLink, '_blank')}
            color="primary"
          >
            Open Event
          </Button>
          {event.location && (
            <Button
              onClick={() => window.open(event.location, '_blank')}
              color="primary"
            >
              Event Link
            </Button>
          )}
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

function QuickLinksTab({ classes, dispatch }) {
  const handleLogout = () => {
    dispatch(clearMyScarletMailToken())
  }
  return (
    <Grid container direction="column">
      <Widget.Spacer />
      <Widget.Spacer />
      <Grid container direction="row" justifyContent="space-evenly">
        <Grid item>
          <Widget.TabLink
            hyperLink="http://mail.scarletmail.rutgers.edu/"
            icon={<EmailIcon className={classes.icon} />}
            iconLabel="Email"
          />
        </Grid>
        <Grid item>
          <Widget.TabLink
            hyperLink="http://calendar.scarletmail.rutgers.edu/"
            icon={<CalendarTodayIcon className={classes.icon} />}
            iconLabel="Calendar"
          />
        </Grid>
        <Grid item>
          <Widget.TabLink
            hyperLink="http://docs.scarletmail.rutgers.edu/"
            icon={<CloudIcon className={classes.icon} />}
            iconLabel="Drive"
          />
        </Grid>
      </Grid>
      <Widget.Spacer />
      <Widget.Spacer />
      <Grid container direction="row" justifyContent="center">
        <Grid item>
          <Button color="primary" variant="outlined" onClick={handleLogout}>
            Reset Widget
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

function formatAMPM(input) {
  const date = new Date(input)
  let hours = date.getHours()
  let minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'pm' : 'am'
  hours %= 12
  hours = hours || 12
  minutes = minutes < 10 ? `0${minutes}` : minutes
  const strTime = `${hours}:${minutes}${ampm}`
  return strTime
}

export default MyEmailScarletMail
