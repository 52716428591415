import { makeStyles } from '@material-ui/core/styles'
import { desktopSideNavWidth } from '../../../../navigation/navigationValues'

export const useNewsSourcePageStyles = makeStyles((theme) => ({
  centerPage: {
    paddingTop: 60,
    paddingLeft: 55,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
    background: theme.palette.components.newsBackground,
  },
  centerPageDrawerOpen: {
    paddingTop: 60,
    paddingLeft: desktopSideNavWidth * 0.8,
    overflow: 'hidden',
    transition: 'width 0.4s',
    background: theme.palette.components.newsBackground,
  },
  pageContainer: {
    padding: '0px 80px 20px 80px', // Hacky way. Need to find a margin auto alternative
    [theme.breakpoints.down('lg')]: {
      padding: '0 20px 20px 20px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '10px 8px 0px 8px',
    },
  },
  pageContainerHeader: {
    padding: '0px 80px 8px 80px', // Hacky way. Need to find a margin auto alternative
    [theme.breakpoints.down('lg')]: {
      padding: '0 20px 10px 20px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '10px 8px 0px 8px',
    },
  },

  sectionHeader: {
    textTransform: 'capitalize',
    margin: '15px 0',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 600,
    textAlign: 'left',
    color: theme.palette.text.primary,
    fontSize: '39px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
    },
  },
  filterContainer: {
    overflowX: 'auto',
  },
  filterCard: {
    padding: '5px',
    width: '5rem',
  },
  filterCardSelected: {
    padding: '5px',
    width: '5rem',
    background: '#cc0033',
    color: theme.color.white,
  },
  filterCardText: {
    textTransform: 'capitalize',
    padding: '5px',
  },
}))

export default useNewsSourcePageStyles
