/* eslint-disable react/no-array-index-key */
import React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import Typography from '@material-ui/core/Typography'
import Alert from '../../../../../helpers/Alerts/Alert'
import { updateAlertsHistoryOpen } from '../MyAlertsSlice'

function AlertList({
  alertList,
  classes,
  dialogHandler,
  alertHistory,
  dispatch,
}) {
  const handleViewPastHistory = () => {
    dispatch(updateAlertsHistoryOpen())
  }
  return (
    <>
      <Grid
        component="div"
        direction="column"
        className={alertHistory ? classes.alertListHeight : {}}
      >
        {alertList?.length > 0 ? (
          alertList?.map((alert, index) => (
            <Grid
              item
              key={`${alert.dockey}-${index}`}
              className={classes.fullWidth}
            >
              <Alert
                alert={alert}
                classes={classes}
                dialogHandler={dialogHandler}
              />
            </Grid>
          ))
        ) : (
          <Grid
            container
            className={classes.fullWidth}
            direction="column"
            alignItems="center"
          >
            <Grid item>
              <DoneAllIcon className={classes.doneIcon} />
            </Grid>
            <Grid item>
              <Typography align="center" variant="h5">
                You&apos;re all caught up!
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      {alertHistory ? (
        <Button
          className={classes.pastHistoryButton}
          aria-label="View Past History"
          variant="outlined"
          onClick={handleViewPastHistory}
        >
          View Past History
        </Button>
      ) : null}
    </>
  )
}

export default AlertList
