import _ from 'lodash'
import * as React from 'react'
import uuid from 'react-uuid'
import { useSelector } from 'react-redux'
import { ROUTE } from '../../../../utils/constants'
import CustomMarker from '../CustomMarker/CustomMarker'

export default function Stops() {
  const {
    stops,
    selectedRoute,
    stopsLoading,
    bottomSheet,
    arrivalsForAllStopsOnRoute,
  } = useSelector((s) => s.maps)
  const [customMarkers, setCustomMarkers] = React.useState([])

  React.useEffect(() => {
    if (stopsLoading !== 'fulfilled' || !arrivalsForAllStopsOnRoute) {
      generateStops()
    } else {
      generateStops()
    }
  }, [stops])

  React.useEffect(() => {
    // when a user closes the bottom sheet for routes we need to generate stops again
    generateStops()
  }, [selectedRoute])

  const generateStops = () => {
    let filteredStops = []
    if (
      bottomSheet.component === ROUTE &&
      !_.isEmpty(arrivalsForAllStopsOnRoute)
    ) {
      // If route is selected
      // if (Object.keys(arrivalsForAllStopsOnRoute.arrivals).length > 0) {
      //   filteredStops = Object.values(arrivalsForAllStopsOnRoute.arrivals)
      // }
      filteredStops = stops
    } else {
      filteredStops = stops
    }
    if (filteredStops?.length > 0) {
      const markers = filteredStops.map((stop) => (
        <CustomMarker key={uuid()} stop={stop} selectedRoute={selectedRoute} />
      ))
      setCustomMarkers(markers)
    }
  }

  return <>{customMarkers.length > 0 && customMarkers}</>
}
