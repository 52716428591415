/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchRUConnect = createAsyncThunk('fetchRUConnect', async () => {
  const today = new Date()
  const startDate = today.toISOString()
  // midnight
  const endDate = new Date(
    today.getFullYear(),
    today.getMonth() + 2,
    0,
    23,
    0,
    0,
  ).toISOString()
  const mes = await axios.get(process.env.REACT_APP_RUCONNECT_ENDPOINT, {
    headers: {
      JWT:
        window.location.hostname === 'localhost'
          ? undefined
          : localStorage.getItem('myrJwtToken'),
      XWT: localStorage.getItem('rcpid'),
    },
  })
  //   const events = await axios.get(
  //     process.env.REACT_APP_RUCONNECT_EVENTS_ENDPOINT +
  //       (window.location.hostname === 'localhost'
  //         ? ''
  //         : `?start=${startDate}&end=${endDate}`),
  //     {
  //       headers: {
  //         JWT:
  //           window.location.hostname === 'localhost'
  //             ? undefined
  //             : localStorage.getItem('myrJwtToken'),
  //         XWT: localStorage.getItem('rcpid'),
  //       },
  //     },
  //   )
  //   const eventsData = JSON.parse(events.data.value)
  //   eventsData.value.sort(function sortDateAscending(a, b) {
  //     return a.start.dateTime > b.start.dateTime
  //   })
  const res = {
    messages: JSON.parse(mes.data.value),
    // events: eventsData,
    startDate,
    endDate,
  }
  return res
})

export const fetchCalendarEvents = createAsyncThunk(
  'fetchCalendarEvents',
  async (_, { getState }) => {
    const state = getState()
    const res = await axios.get(
      `${process.env.REACT_APP_RUCONNECT_EVENTS_ENDPOINT}?start=${state.ruConnect.startDate}&end=${state.ruConnect.endDate}`,
      {
        headers: {
          JWT:
            window.location.hostname === 'localhost'
              ? undefined
              : localStorage.getItem('myrJwtToken'),
          XWT: localStorage.getItem('rcpid'),
        },
      },
    )
    return res
  },
)

const initialState = {
  status: 'idle',
  data: {
    messages: [],
    events: [],
    title: 'RUConnect Mail',
  },
}

const ruConnectSlice = createSlice({
  name: 'ruConnect',
  initialState,
  reducers: {
    getNextMonth: (state) => {
      // calculate the next month
      const date = new Date(state.startDate)
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      if (month > 11) {
        year += 1
        month = 0
      }
      const nextMonthStart = new Date(year, month)
      const nextMonthEnd = new Date(
        nextMonthStart.getFullYear(),
        nextMonthStart.getMonth() + 1,
        0,
        23,
        0,
        0,
      )
      state.startDate = nextMonthStart.toISOString()
      state.endDate = nextMonthEnd.toISOString()
    },
    getPrevMonth: (state) => {
      // calculate the next month
      const date = new Date(state.endDate)
      let year = date.getFullYear()
      let month = date.getMonth() - 1
      if (month < 0) {
        year -= 1
        month = 11
      }
      const prevMonthStart = new Date(year, month)
      const prevMonthEnd = new Date(
        prevMonthStart.getFullYear(),
        prevMonthStart.getMonth() + 1,
        0,
        23,
        0,
        0,
      )
      state.startDate = prevMonthStart.toISOString()
      state.endDate = prevMonthEnd.toISOString()
    },
  },
  extraReducers: {
    [fetchRUConnect.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchRUConnect.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchRUConnect.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.data.messages = action.payload.messages
      // state.data.events = action.payload.events
      state.startDate = action.payload.startDate
      state.endDate = action.payload.endDate
    },
    [fetchCalendarEvents.rejected]: (state) => {
      state.eventStatus = 'error'
    },
    [fetchCalendarEvents.pending]: (state) => {
      state.eventStatus = 'pending'
    },
    [fetchCalendarEvents.fulfilled]: (state, action) => {
      state.eventStatus = 'fulfilled'
      state.data.events = JSON.parse(action.payload.data.value)
    },
  },
})

export const { getPrevMonth, getNextMonth } = ruConnectSlice.actions

export default ruConnectSlice.reducer
