/* eslint-disable no-param-reassign */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import InlineIconButtons from '../../DashboardComponents/InlineIconButtons/InlineIconButtons'
import InlineSocialMediaIcons from '../../DashboardComponents/InlineSocialMediaIcons/InlineSocialMediaIcons'
import LinkList from '../../DashboardComponents/LinkList.js/LinkList'
import Widget from '../Widget'
import Loading from '../helpers/Loading'
// import { storage } from '../../../../../utils/firebase'

import {
  fetchMasonGross,
  selectMasonGrossData,
  selectMasonGrossStatus,
} from './MasonGrossSlice'
import styles from './MasonGrossStyles'
import { logDashboardEvent } from '../../events'
import { getImageFromFirebaseWithHook } from '../../../apps/helpers'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'
// eslint-disable-next-line no-unused-vars
function MasonGross({
  className,
  title,
  widgetId,
  showNote,
  noteMessage,
  notePosition,
  noteBackground,
  noteIcon,
  noteUrl,
  noteFontColor,
}) {
  const dispatch = useDispatch()
  const widgetData = useSelector(selectMasonGrossData)
  const widgetStatus = useSelector(selectMasonGrossStatus)
  const classes = styles()

  React.useEffect(() => {
    dispatch(fetchMasonGross())
  }, [])

  return (
    <Widget className={className}>
      <Widget.Header title={title} category="default" />
      <Widget.Content
        className={classes.container}
        showNote={showNote}
        noteMessage={noteMessage}
        notePosition={notePosition}
        noteBackground={noteBackground}
        noteIcon={noteIcon}
        noteUrl={noteUrl}
        noteFontColor={noteFontColor}
      >
        {renderWidget(widgetStatus, widgetData, classes, widgetId, dispatch)}
      </Widget.Content>
    </Widget>
  )
}

function renderWidget(widgetStatus, widgetData, classes, widgetId, dispatch) {
  switch (widgetStatus) {
    case 'pending':
      return <Loading />
    case 'error':
      return <ErrorMessage />
    case 'fulfilled':
      return (
        <Loaded
          widgetData={widgetData}
          classes={classes}
          widgetId={widgetId}
          dispatch={dispatch}
        />
      )
    default:
      return <ErrorMessage />
  }
}

function Loaded({ widgetData, classes, widgetId, dispatch }) {
  const [image, setImg] = React.useState(null)

  React.useEffect(() => {
    if (widgetData && widgetData.imageLocation) {
      getImageFromFirebaseWithHook(widgetData.imageLocation, setImg)
    }
  }, [])

  const handleButtonClick = (link, title) => {
    if (widgetId && dispatch) {
      logDashboardEvent(dispatch, 'dashboardEvent', widgetId, title, link)
    }
    window.open(link, '_blank')
  }

  return (
    <Grid container direction="column">
      <Grid
        item
        onClick={() =>
          handleButtonClick(widgetData.imageURL, 'Mason Gross Dance')
        }
      >
        <img src={image} alt="Mason Gross Dance" className={classes.image} />
      </Grid>
      <Grid item>
        <InlineIconButtons
          buttons={widgetData.inlineIconButtons}
          widgetId={widgetId}
          dispatch={dispatch}
        />
      </Grid>
      <Grid item>
        <LinkList
          links={widgetData.linkList}
          widgetId={widgetId}
          dispatch={dispatch}
        />
      </Grid>
      <Grid item container direction="column" alignItems="center">
        <Grid item>
          <Typography variant="subtitle1">Address</Typography>
        </Grid>
        <Grid item xs container direction="column" alignItems="center">
          <Typography align="center" variant="caption">
            {widgetData.description.school}
          </Typography>
        </Grid>
        <Grid item xs container direction="column" alignItems="center">
          <Typography align="center" variant="caption">
            {widgetData.description.address}
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <InlineSocialMediaIcons
          buttons={widgetData.inlineSocialMediaIcons}
          widgetId={widgetId}
          dispatch={dispatch}
        />
      </Grid>
    </Grid>
  )
}

export default MasonGross
