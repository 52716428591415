/* eslint-disable react/no-array-index-key */
/* eslint-disable radix */
/* eslint-disable dot-notation */
import React, { useState } from 'react'
import DOMPurify from 'dompurify'
import { useDispatch, useSelector } from 'react-redux'
// import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import Loading from '../helpers/Loading'
import Widget from '../Widget'
import {
  fetchMyAlerts,
  selectMyAlertsData,
  selectMyAlertsStatus,
  acknowledgeAlert,
  updateAlertsHistoryOpen,
} from './MyAlertsSlice'
import myAlertsStyles from './MyAlertsStyles'
import AlertGUI from './Components/AlertGUI'

import DashboardDialog from '../../DashboardComponents/DashboardDialog/DashboardDialog'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'
import {
  formatAckDate,
  formatRefundDate,
  ensureHttps,
} from '../../../../../utils/utils'

const defaultAlert = {
  id: '',
  datePosted: '',
  dateAcknowledged: '',
  from: '',
  title: '',
  message: '',
  active: '',
}

function MyAlerts({
  className,
  title,
  showNote,
  noteMessage,
  notePosition,
  noteBackground,
  noteIcon,
  noteUrl,
  noteFontColor,
}) {
  const dispatch = useDispatch()
  const widgetData = useSelector(selectMyAlertsData)
  const widgetStatus = useSelector(selectMyAlertsStatus)
  const { alertsHistoryOpen } = useSelector((s) => s.myAlerts)
  const classes = myAlertsStyles()

  const [alert, setAlert] = useState(defaultAlert)

  React.useEffect(() => {
    dispatch(fetchMyAlerts())
  }, [])

  const dialogHandler = (openAlert = defaultAlert, acknowledge = false) => {
    if (acknowledge) {
      dispatch(acknowledgeAlert(alert))
    }
    setAlert(openAlert)
  }

  return (
    <Widget className={className}>
      <Widget.Header title={title} category="default" />
      <Widget.Content
        className={classes.container}
        showNote={showNote}
        noteMessage={noteMessage}
        notePosition={notePosition}
        noteBackground={noteBackground}
        noteIcon={noteIcon}
        noteUrl={noteUrl}
        noteFontColor={noteFontColor}
      >
        {renderMyAlerts(
          widgetStatus,
          widgetData,
          classes,
          dialogHandler,
          dispatch,
          alertsHistoryOpen,
        )}
      </Widget.Content>
    </Widget>
  )
}

function renderMyAlerts(
  widgetStatus,
  widgetData,
  classes,
  dialogHandler,
  dispatch,
  alertsHistoryOpen,
) {
  switch (widgetStatus) {
    case 'pending':
      return <Loading />
    case 'error':
      return <ErrorMessage />
    case 'fulfilled':
      return (
        <Loaded
          data={widgetData}
          classes={classes}
          dialogHandler={dialogHandler}
          dispatch={dispatch}
          alertsHistoryOpen={alertsHistoryOpen}
        />
      )
    default:
      return <Box />
  }
}

function Loaded({ data, classes, dialogHandler, dispatch, alertsHistoryOpen }) {
  const headers = [
    'Start Date',
    'End Date',
    'Title',
    'Message',
    'Link',
    'Acknowledged Time',
    'Alert Type',
    'Severity Level',
  ]
  const handleAckDate = (acknowledgedDate) => {
    let dateACK = acknowledgedDate
    dateACK = formatAckDate(dateACK)
    if (dateACK === '') {
      return <Typography variant="body1">N/A</Typography>
    }
    return (
      <Typography variant="body1">
        {dateACK?.toLocaleDateString('en-US')}
      </Typography>
    )
  }
  const formatSeverityLevel = (level) => {
    const levels = {
      High: (
        <Chip
          classes={{ root: classes.alertListHighChip }}
          size="medium"
          label={level}
          clickable={false}
        />
      ),
      Medium: (
        <Chip
          classes={{ root: classes.alertListMediumChip }}
          size="medium"
          label={level}
          clickable={false}
        />
      ),
      Low: (
        <Chip
          classes={{ root: classes.alertListLowChip }}
          size="medium"
          label={level}
          clickable={false}
        />
      ),
    }
    return levels[level] || 'N/A'
  }
  const rows = data?.alerts?.alertHistory
    ?.map((item) => ({
      start_date: (
        <Typography variant="body1">
          {item[`start_date`] ||
            item[`sent_date`] ||
            formatRefundDate(item[`lastUpdated`])}
        </Typography>
      ),
      end_date: (
        <Typography variant="body1">
          {item[`end_date`] || item[`exp_date`]}
        </Typography>
      ),
      title: <Typography variant="body1">{item[`title`]}</Typography>,
      message: (
        <Typography variant="body1">
          {
            <div
              // eslint-disable-next-line react/no-danger
              className={classes.descriptionStyle}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(item[`message`] || 'N/A', {
                  ADD_ATTR: ['target'],
                }),
              }}
            />
          }
        </Typography>
      ),
      link: (
        <a href={item.link} rel="noopener noreferrer" target="_blank">
          {ensureHttps(item[`link`])}
        </a>
      ),
      acknowledgedTS: handleAckDate(item[`acknowledgedTS`]),
      alerttype: <Typography variant="body1">{item[`alerttype`]}</Typography>,
      severityLevel: formatSeverityLevel(item[`severityLevel`]),
    }))
    .map((alertRow) => Object.values(alertRow))
  return (
    <>
      <DashboardDialog
        open={alertsHistoryOpen}
        dialogTitle={<Typography variant="h3">Past Alert History</Typography>}
        dispatch={dispatch}
        closeDialog={updateAlertsHistoryOpen}
        dialogContent={
          <AlertsHistoryTable
            headers={headers}
            rows={rows}
            ariaLabel="Past Alerts History"
          />
        }
        maxWidth="100%"
      />
      <AlertGUI data={data} classes={classes} dialogHandler={dialogHandler} />
    </>
  )
}

const AlertsHistoryTable = ({ headers, rows, ariaLabel }) => (
  <TableContainer component={Paper}>
    <Table
      style={{ tableLayout: 'auto', whiteSpace: 'normal' }}
      aria-label={ariaLabel}
    >
      <TableHead style={{ backgroundColor: '#cc0033' }}>
        <TableRow>
          {headers.map((header, index) => (
            <TableCell
              key={index}
              align="left"
              style={{ color: 'white', width: 178 }}
            >
              {header}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows?.map((row, rowIndex) => (
          <TableRow key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <TableCell key={cellIndex} align="left">
                {cell}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
)

export default MyAlerts
