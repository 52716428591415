/* eslint-disable react/no-array-index-key */
import * as React from 'react'
import { useDispatch } from 'react-redux'
import {
  Box,
  Button,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Widget from '../Widget'
import adminStyles from './adminWidgetStyles'
import Footer from '../../DashboardComponents/Footer/Footer'
import { setEditDialog, setFocusedWidget } from '../../../admin/adminPageSlice'
import {
  keysToShowForAdmin,
  widgetKeysInOrderList,
} from '../../../admin/constants'

function AdminWidget({ className, widgetData, index }) {
  return (
    <Widget className={className}>
      <Widget.Header title={widgetData.title} category="default" />
      <Widget.Content>
        <Loaded widgetData={widgetData} index={index} />
      </Widget.Content>
    </Widget>
  )
}

// The loaded function is responsible for creating our UI after the call for data is successful. We create  a hook function called didYouKnowStyles to get access to a variable that contains styling our UI. You can learn more about it at https://v4.mui.com/styles/basics/.
function Loaded({ widgetData, index }) {
  const classes = adminStyles()
  const dispatch = useDispatch()
  const DISPLAYPRIORITY = 1
  const handleEdit = () => {
    dispatch(setEditDialog(true))
    dispatch(setFocusedWidget(index))
  }

  function SpecialListItem({ k, ix }) {
    const [isExpanded, setIsExpanded] = React.useState(false)
    const [showIcon, setShowIcon] = React.useState(false)
    const [value, setValue] = React.useState('')

    React.useEffect(() => {
      parseInputToBePresentableAndSetData()
    }, [])

    const parseInputToBePresentableAndSetData = () => {
      const convertValueToKey = new Set([
        // Any options where label != value. Takes value and uses label for show
        'roles',
        'subscribable',
        'campusIds',
        'category',
        'level',
        'orgCode',
        'unitCode',
      ])
      const SUBSTRING_COUNT = 25
      let data = widgetData[k]
      if (convertValueToKey.has(k)) {
        const { options } = keysToShowForAdmin[k]
        if (typeof data === 'object' && data) {
          try {
            const d = data.map((v) => {
              const option = options.find((op) => op.value === v)
              return option ? option.label : null
            })
            data = d
          } catch (e) {
            console.log(e)
          }
        } else if (typeof data === 'number' && data) {
          try {
            const d = options.find((obj) => obj.value === data)
            data = d.label
          } catch (e) {
            console.log(e)
          }
        }
      }

      let rawText = JSON.stringify(data)
      if (Array.isArray(data)) {
        // eslint-disable-next-line no-useless-escape
        rawText = rawText.replace(/[\[\]"]/g, '').replace(/,/g, ', ')
      }
      rawText = rawText.replace(/^"|"$/g, '')

      const text = {
        short: '',
        long: 'EMPTY',
      }
      const isLongText = rawText.length > SUBSTRING_COUNT

      if (rawText.length !== 0) {
        const shouldAddEllipse = isLongText ? '...' : ''
        text.short = rawText.substring(0, SUBSTRING_COUNT) + shouldAddEllipse
        text.long = rawText
      } else {
        text.short = '---'
      }

      setValue(text)
      setShowIcon(isLongText)
    }

    const handleExpandClick = () => {
      setIsExpanded(!isExpanded)
    }

    return (
      <ListItem className={ix % 2 === 0 ? classes.even : classes.odd}>
        <ListItemText
          primary={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography className={classes.fields}>
                {k.charAt(0).toUpperCase() + k.slice(1)}:
              </Typography>
              <Typography className={classes.values}>
                {!isExpanded ? value.short : value.long}
              </Typography>
            </div>
          }
        />
        {showIcon && (
          <ListItemSecondaryAction>
            <IconButton edge="end" onClick={handleExpandClick}>
              {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
    )
  }

  return (
    <div className={classes.root}>
      <Box style={{ height: 435, overflowY: 'auto' }}>
        <div className={classes.demo}>
          <List dense>
            {widgetKeysInOrderList(DISPLAYPRIORITY).map((key, ix) => (
              <SpecialListItem key={`${key}-${ix}`} k={key} ix={ix} />
            ))}
          </List>
        </div>
      </Box>
      <Footer>
        <Box className={classes.buttonContainer}>
          <Button
            className={classes.button}
            onClick={() => handleEdit()}
            variant="contained"
            color="primary"
          >
            Edit
          </Button>
        </Box>
      </Footer>
    </div>
  )
}

export default AdminWidget
