import * as React from 'react'
import { Box } from '@material-ui/core'
import useWidgetFormStyles from './WidgetFormStyles'
import BasicWidgetForm from './BasicWidgetForm'
import AdvancedWidgetForm from './AdvancedWidgetForm'
import WidgetFormTabs from './components/WidgetFormTabs/WidgetFormTabs'

/**
 * If focusedWidget (index of widget in list) is not null, we pull in the details from the widget list
 * If null, we're creating a brand new widget so use blank slate
 */
function WidgetForm({ focusedWidget, setEdited, setWidget }) {
  const classes = useWidgetFormStyles()
  const checkBoxKeys = new Set([
    'active',
    'default',
    'showOnDashboard',
    'lock',
    'dsp_apps',
    'dsp_note',
    'dsp_refresh_time',
    'dsp_title',
  ])
  const regularSelectKeys = new Set(['proxy', 'category'])
  const multiSelectKeys = new Set([
    'roles',
    'subscribable',
    'campusIds',
    'level',
    'orgCode',
    'unitCode',
  ])

  const handleFormChange = (key, event) => {
    const data = { ...focusedWidget }
    let val = null
    // console.log(key, event, val)
    if (checkBoxKeys.has(key)) {
      val = event.target.checked
    } else if (regularSelectKeys.has(key)) {
      val = event.value
    } else if (multiSelectKeys.has(key)) {
      val = handleMultiSelectLogic(event)
    } else {
      val = event.target.value
    }
    data[key] = val
    setWidget(data)
    setEdited(true)
  }

  const handleMultiSelectLogic = (event) => {
    const EMPTY_STRING_EQUALS_ALL = ''
    let items = event.map((option) => option.value)
    if (items.includes(EMPTY_STRING_EQUALS_ALL)) {
      // If list contains empty string -> All choice was selected
      if (items.length === 2) {
        // 2 means that all choice was select before and another field just got added. Remove all choices expect for new choice
        items = items.filter((item) => item !== '')
      } else {
        // More than 2 means that there was a list of choices and then All was added. Remove everything but All
        items = ['']
      }
    }
    return items
  }
  return (
    <Box>
      <WidgetFormTabs
        TabOne={
          <BasicWidgetForm
            classes={classes}
            focusedWidget={focusedWidget}
            handleFormChange={handleFormChange}
          />
        }
        TabTwo={
          <AdvancedWidgetForm
            classes={classes}
            focusedWidget={focusedWidget}
            handleFormChange={handleFormChange}
          />
        }
      />
    </Box>
  )
}

export default WidgetForm
