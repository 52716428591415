import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { toggleAdminMode } from '../../../settings/settingsSlice'

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: 250,
    width: 650,
  },
  errorText: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
  },
}))

function PasswordModal({ open, setOpen }) {
  const ADMIN_PASSWORD = process.env.REACT_APP_ADMIN_MODE_PASSWORD
  const [password, setPassword] = useState('')
  const [error, setError] = useState(false)

  const classes = useStyles()
  const dispatch = useDispatch()

  const handleClose = () => {
    setPassword('')
    setError(false)
    setOpen(false)
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
  }

  const handleProceed = () => {
    setError(false)
    if (password === ADMIN_PASSWORD) {
      setPassword('')
      dispatch(toggleAdminMode(true))
      handleClose()
    } else {
      setPassword('')
      setError(true)
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle>Admin Mode Password</DialogTitle>
        <DialogContent dividers>
          <Typography variant="body1">
            This action requires administrator privileges. Please enter the
            password to continue.
          </Typography>
          <TextField
            autoFocus
            label="Password"
            margin="dense"
            variant="outlined"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            fullWidth
          />
          {error && (
            <div className={classes.errorText}>
              Invalid password. Please try again.
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleProceed} color="primary" variant="contained">
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default PasswordModal
