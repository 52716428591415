/* eslint-disable  no-undef */
/* eslint-disable no-underscore-dangle */
import React from 'react'
import { useDispatch } from 'react-redux'
import { OverlayView } from '@react-google-maps/api'
import uuid from 'react-uuid'
import { handleFirebaseMarkerClicked } from '../../redux/myMapsSlice'
import IconPicker from '../../../../../apps/AppComponents/IconPicker/IconPicker'

function FirebaseMarker(props) {
  const dispatch = useDispatch()
  const { stop } = props

  const updateHandleMarkerIsActive = (e, markerStop) => {
    handleFetchStopLogic(e, markerStop)
  }

  const handleFetchStopLogic = (e, markerStop) => {
    dispatch(handleFirebaseMarkerClicked(markerStop))
  }
  return (
    <button
      type="button"
      aria-label="Custom Marker"
      onClick={(e) => updateHandleMarkerIsActive(e, stop)}
    >
      <OverlayView
        key={uuid()}
        position={{
          lat: stop.location._lat,
          lng: stop.location._long,
        }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      >
        <IconPicker iconName={stop.icon} color={stop.color} size="2x" />
      </OverlayView>
    </button>
  )
}

export default FirebaseMarker
