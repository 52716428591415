/* eslint-disable react/no-array-index-key */
import React from 'react'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import {
  ButtonBase,
  Card,
  CardContent,
  Typography as Text,
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import vaccineStatusStyles from './vaccineStatusStyles'
import Loading from '../helpers/Loading'

export default function VaccineStatus() {
  dayjs.extend(localizedFormat)
  const classes = vaccineStatusStyles()
  //   const dispatch = useDispatch()
  const { vaccineStatus, vaccineData } = useSelector((s) => s.campusPass)
  //   React.useEffect(() => {
  //     if (
  //       vaccineStatus !== 'fulfilled' &&
  //       vaccineStatus !== 'error' &&
  //       vaccineStatus !== 'pending'
  //     ) {
  //       dispatch(fetchVaccineStatus())
  //     }
  //   }, [vaccineStatus])
  const handleManufacterer = (name) => {
    switch (name) {
      case 'Moderna':
        return './assets/images/moderna.png'
      case 'AstraZeneca':
        return './assets/images/astrazeneca.png'
      case 'Johnson & Johnson':
        return './assets/images/jj.png'
      case 'Pfizer':
        return './assets/images/pfizer.png'
      case 'Sinopharm':
        return './assets/images/sinopharm.png'
      case 'Sinovac':
        return './assets/images/sinovac.jpg'
      case 'Covishield':
        return './assets/images/covishield.png'
      case 'Who Other':
        return './assets/images/who_other.png'
      default:
        return './assets/images/who_other.png'
    }
  }
  return (
    <Grid container direction="column">
      <Box className={classes.headerContainer}>
        {/* You have taken one vaccine */}
        {vaccineData.vaccine && vaccineData.vaccine.doses.length === 1 && (
          <Box className={classes.vaccineContainer}>
            <img
              src={handleManufacterer(
                vaccineData.vaccine.doses[0].manufacturer,
              )}
              className={classes.singleVaccineImage}
              alt="Vaccine"
            />
            <Text variant="h5" className={classes.singleVaccineText}>
              You have taken the 1st dose:
            </Text>
            <Text variant="h5" className={classes.singleVaccineText}>
              {dayjs(vaccineData.vaccine.doses[0].date).format('dddd, LL')}
            </Text>
            {vaccineData.vaccine &&
            vaccineData.vaccine.appointments.length > 0 ? (
              <Box className={classes.appointmentsContainer}>
                <Text variant="h5">You have appointment(s):</Text>
                {vaccineData.vaccine.appointments.map((vaccine, index) => (
                  <Card key={index}>
                    <CardContent>
                      <Text variant="h5">
                        {dayjs(vaccine.date).format('LLLL')}
                      </Text>
                      <Text variant="h5">{vaccine.location}</Text>
                    </CardContent>
                  </Card>
                ))}
              </Box>
            ) : (
              <Box
                display="flex"
                padding="10px"
                height="160px"
                alignItems="center"
              >
                <ButtonBase
                  component="a"
                  target="_blank"
                  href="https://rtr.ipo.rutgers.edu/vaccines/"
                >
                  <img
                    className={classes.singleVaccineIcon}
                    src="./assets/images/vaccine_icon.png"
                    alt="Vaccine Icon"
                  />
                  <Text className={classes.singleVaccineIconText} variant="h5">
                    Click here if you would like to update your vaccination
                    status
                  </Text>
                </ButtonBase>
              </Box>
            )}
          </Box>
        )}
        {/* You have taken no vaccines */}
        {vaccineData.vaccine && vaccineData.vaccine.doses.length === 0 && (
          <Box display="flex" flexDirection="column">
            <img
              src="./assets/images/vaccine.png"
              alt="Person taking vaccine"
              className={classes.vaccineImageTwo}
            />
            <Box
              display="flex"
              padding="10px"
              alignItems="center"
              height="160px"
            >
              <ButtonBase
                component="a"
                target="_blank"
                href="https://rtr.ipo.rutgers.edu/vaccines/"
              >
                <img
                  className={classes.singleVaccineIcon}
                  src="./assets/images/vaccine_icon.png"
                  alt="Vaccine Icon"
                />
                <Text className={classes.singleVaccineIconText} variant="h5">
                  Click here if you are interested in getting the vaccine or
                  uploading previous vaccination status.
                </Text>
              </ButtonBase>
            </Box>
          </Box>
        )}
        {/* You have taken more than one vaccine */}
        {vaccineData.vaccine && vaccineData.vaccine.doses.length > 1 && (
          <>
            <Text className={classes.headerText} variant="h5">
              You have taken:
            </Text>
            <Divider classes={{ root: classes.divider }} />
            {renderVaccineStatusState(
              vaccineStatus,
              vaccineData,
              handleManufacterer,
            )}
          </>
        )}
      </Box>
    </Grid>
  )
}

function renderVaccineStatusState(
  vaccineStatus,
  vaccineData,
  handleManufacterer,
) {
  switch (vaccineStatus) {
    case 'fulfilled':
      return (
        <Loaded
          apiReturnedContent={vaccineData}
          handleManufacterer={handleManufacterer}
        />
      )
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>Error</Grid>
        </Grid>
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item></Grid>
        </Grid>
      )
  }
}

function Loaded(props) {
  const classes = vaccineStatusStyles()
  const { apiReturnedContent } = props
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box className={classes.vaccineListContainer}>
        <List component="nav">
          {apiReturnedContent.vaccine.doses.map((vaccine, index) => (
            <Box key={`${index}-${vaccine.doseIndex}`}>
              <ListItem>
                <Text className={classes.headerText} variant="body1">
                  {vaccine.doseIndex}
                </Text>
                <img
                  className={classes.vaccineImage}
                  src={props.handleManufacterer(vaccine.manufacturer)}
                  alt="Vaccine"
                />
                <Text className={classes.vaccineHeader} variant="body1">
                  {dayjs(vaccine.date).format('MM/DD/YYYY')}
                </Text>
              </ListItem>
              <Divider />
            </Box>
          ))}
          {apiReturnedContent.flu_vaccine !== '' && (
            <ListItem>
              <Text className={classes.fluVaccineHeaderText} variant="h5">
                Flu Vaccine
              </Text>
              <Text className={classes.fluVaccineDateText}>
                {dayjs(apiReturnedContent.flu_vaccine).format('MM/DD/YYYY')}
              </Text>
            </ListItem>
          )}
        </List>
      </Box>
    </Grid>
  )
}
