import { addProfileLogEvent } from '../../dashboard/widgets/MyProfile/myProfileSlice'

const events = {
  stopMarker: {
    object: 'Stop',
    objectId: 'StopMarker',
    action: 'Click',
    actionContext: 'ViewRoutes',
  },
  routeMarker: {
    object: 'Route',
    objectId: 'RouteMarker',
    action: 'Click',
    actionContext: 'ViewRoute',
  },
  vehicleMarker: {
    object: 'Vehicle',
    objectId: 'VehicleMarker',
    action: 'Click',
    actionContext: 'ViewVehicle',
  },
  busFavorite: {
    object: 'BusTab',
    objectId: 'BusFavorite',
    action: 'Click',
    actionContext: 'FavoriteBusRoute',
  },
  stopsFavorite: {
    object: 'StopsTab',
    objectId: 'StopsFavorite',
    action: 'Click',
    actionContext: 'FavoriteBusStop',
  },
  buildingFavorite: {
    object: 'BuildingsTab',
    objectId: 'BuildingFavorite',
    action: 'Click',
    actionContext: 'FavoriteBuilding',
  },
  nearestStopsButton: {
    object: 'BuildingsTab',
    objectId: 'NearestStopsButton',
    action: 'Click',
    actionContext: 'ViewNearestStops',
  },
  directionsMap: {
    object: 'BuildingsTab',
    objectId: 'DirectionsMap',
    action: 'Click',
    actionContext: 'ExternalClick',
  },
  parkingMap: {
    object: 'BuildingsTab',
    objectId: 'ParkingMap',
    action: 'Click',
    actionContext: 'ExternalClick',
  },
}

export const logMapEvent = (dispatch, event, value) => {
  if (process.env.REACT_APP_FIREBASE_ON === 'true') {
    dispatch(
      addProfileLogEvent({
        ...events[event],
        module: 'Map',
        actionContextValue: value,
        rcpid: localStorage.getItem('rutgersEduRCPID'),
        timestamp: Date.now(),
      }),
    )
  }
}
