/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { partition } from './helper'

export const fetchEnrollmentPathway = createAsyncThunk(
  'fetchEnrollmentPathway',
  async (enrollmentType) => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const rcpid = localStorage.getItem('rcpid')
    let res
    if (enrollmentType === 'required') {
      res = await axios.get(
        `${process.env.REACT_APP_ENROLLMENTPATHWAY_REQUIRED_ENDPOINT}?reqItemsOnly=false`,
        {
          headers: {
            JWT: jwtToken,
            XWT: rcpid,
          },
        },
      )
    }
    // else {
    //   res = await axios.get(
    //     `${process.env.REACT_APP_ENROLLMENTPATHWAY_REQUIRED_ENDPOINT}?reqItemsOnly=true`,
    //     {
    //       headers: {
    //         JWT: jwtToken,
    //         XWT: rcpid,
    //       },
    //     },
    //   )
    // }
    // sort the data that comes back by display order
    res.data.sort((a, b) => a.itemVM.displayOrder > b.itemVM.displayOrder)
    // sort the response data by CODE: REQUIRED and RECOMMENDED
    const [required, recommended] = partition(
      res.data,
      (item) => item.itemVM.category.code === 'REQ',
    )
    let completedRequiredTasks = []
    let pendingRequiredTasks = []
    let completedRecommendedTasks = []
    let pendingRecommendedTasks = []
    completedRequiredTasks = required.filter((task) => task.isComplete)
    pendingRequiredTasks = required.filter((task) => task.isComplete === false)
    completedRecommendedTasks = recommended.filter((task) => task.isComplete)
    pendingRecommendedTasks = recommended.filter(
      (task) => task.isComplete === false,
    )
    return {
      allTasks: res.data,
      title: res.data.title,
      required,
      recommended,
      completedRequiredTasks,
      pendingRequiredTasks,
      completedRecommendedTasks,
      pendingRecommendedTasks,
    }
  },
)

export const sendEnrollmentPathwayTaskClick = createAsyncThunk(
  'sendEnrollmentPathwayTaskClick',
  async (task) => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const rcpid = localStorage.getItem('rcpid')
    const res = axios.post(
      `${process.env.REACT_APP_ENDROLLMENTPATHWAY_POST_DATA_ENDPOINT}`,
      {
        netId: task.netId,
        itemId: task.itemId,
        checkStatus: !task.isComplete,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          JWT: jwtToken,
          XWT: rcpid,
        },
      },
    )
    return res.data
  },
)

const initialState = {
  title: 'Enrollment Pathway',
  status: 'idle',
  taskClickStatus: 'idle',
  taskClickResult: [],
  data: [],
  pendingTasks: [],
  completedTasks: [],
  recommendedPercentage: 0,
  requiredPercentage: 0,
}

const enrollmentPathwaySlice = createSlice({
  name: 'enrollmentPathway',
  initialState,
  reducers: {
    updatePercentage: (state, action) => {
      state.percentage = action.payload
    },
    updatePendingTasks: (state, action) => {
      const item = action.payload
      if (
        action.payload.type.includes('Required') ||
        action.payload.type.includes('required')
      ) {
        // add the item to the pendingTasks array
        state.data.pendingRequiredTasks = [
          ...state.data.pendingRequiredTasks,
          { ...item, isComplete: false },
        ]
        // remove item from completedTasks
        const index = state.data.completedRequiredTasks.findIndex(
          (element) => element.itemId === item.itemId,
        )
        if (index > -1) {
          state.data.completedRequiredTasks.splice(index, 1)
        }
      } else {
        // add the item to the pendingTasks array
        state.data.pendingRecommendedTasks = [
          ...state.data.pendingRecommendedTasks,
          { ...item, isComplete: false },
        ]
        // remove item from completedTasks
        const index = state.data.completedRecommendedTasks.findIndex(
          (element) => element.itemId === item.itemId,
        )
        if (index > -1) {
          state.data.completedRecommendedTasks.splice(index, 1)
        }
      }
    },
    updateCompleteTasks: (state, action) => {
      const item = action.payload
      // remove item from pendingTasks
      if (
        action.payload.type.includes('Required') ||
        action.payload.type.includes('required')
      ) {
        const index = state.data.pendingRequiredTasks.findIndex(
          (element) => element.itemId === item.itemId,
        )
        if (index > -1) {
          state.data.pendingRequiredTasks.splice(index, 1)
        }
        // add the item to the completedTasks array
        state.data.completedRequiredTasks = [
          ...state.data.completedRequiredTasks,
          { ...item, isComplete: true },
        ]
      } else {
        const index = state.data.pendingRecommendedTasks.findIndex(
          (element) => element.itemId === item.itemId,
        )
        if (index > -1) {
          state.data.pendingRecommendedTasks.splice(index, 1)
        }
        // add the item to the completedRecommendedTasks array
        state.data.completedRecommendedTasks = [
          ...state.data.completedRecommendedTasks,
          { ...item, isComplete: true },
        ]
      }
    },
    calculatePercentage: (state, action) => {
      if (
        action.payload.type.includes('required') ||
        action.payload.type.includes('Required')
      ) {
        const percentage = Math.floor(
          (state.data.completedRequiredTasks.length /
            state.data.required.length) *
            100,
        )
        state.requiredPercentage = percentage || 0
      } else {
        const percentage = Math.floor(
          (state.data.completedRecommendedTasks.length /
            state.data.recommended.length) *
            100,
        )
        state.recommendedPercentage = percentage || 0
      }
    },
  },
  extraReducers: {
    [fetchEnrollmentPathway.rejected]: (state) => {
      state.status = 'error'
      state.data = {
        allTasks: [],
        required: [],
        recommended: [],
        completedRequiredTasks: [],
        pendingRequiredTasks: [],
        completedRecommendedTasks: [],
        pendingRecommendedTasks: [],
      }
    },
    [fetchEnrollmentPathway.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchEnrollmentPathway.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.title = action.payload.title
      state.data = action.payload
    },
    [sendEnrollmentPathwayTaskClick.pending]: (state) => {
      state.taskClickStatus = 'pending'
    },
    [sendEnrollmentPathwayTaskClick.fulfilled]: (state, action) => {
      state.taskClickStatus = 'fulfilled'
      state.taskClickResult = action.payload
    },
    [sendEnrollmentPathwayTaskClick.rejected]: (state, action) => {
      state.taskClickStatus = 'error'
      state.taskClickResult = action.payload
    },
  },
})

export const {
  updatePercentage,
  calculatePercentage,
  updatePendingTasks,
  updateCompleteTasks,
} = enrollmentPathwaySlice.actions

export default enrollmentPathwaySlice.reducer
