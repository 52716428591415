/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { doc, collection, getDoc } from 'firebase/firestore'
import {
  Typography as Text,
  Grid,
  Select,
  IconButton,
  Button,
  MenuItem,
  Box,
  InputLabel,
  Card,
} from '@material-ui/core'

import DescriptionIcon from '@material-ui/icons/Description'
import ErrorIcon from '@material-ui/icons/Error'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { useDispatch, useSelector } from 'react-redux'
import { gradeTutorial, gradeTutorialForTwoUserRoles } from './gradeTutorial'
import Loading from '../helpers/Loading'
import tutorialStyles from '../../DashboardComponents/Tutorial/TutorialStyles'
import TutorialMode from '../../DashboardComponents/Tutorial/TutorialMode'
import Widget from '../Widget'
import { selectShowSensitiveInfo } from '../../../settings/settingsSlice'
import IconPicker from '../../../apps/AppComponents/IconPicker/IconPicker'
import { firestore } from '../../../../../utils/firebase'
import gradeStyles from './gradeStyles'

import {
  fetchGrade,
  selectGradeData,
  selectGradeStatus,
  selectShowGradeTutorial,
  endTutorial,
  selectGradeTab,
  selectTab,
  updateTab,
  fetchDefaultTutorialData,
  selectDefaultTutorialData,
  selectDefaultTutorialStatus,
} from './gradeSlice'
import { logDashboardEvent } from '../../events'
import {
  RutgersTabs,
  RutgersTab,
} from '../../DashboardComponents/Tabs/RutgersTabs'
import { a11yProps } from '../../DashboardComponents/TabPanel/TabPanel'
import tutorialStylesForBothUserRoles from '../../DashboardComponents/Tutorial/TutorialStylesForDualRole'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'

function Grade({
  className,
  title,
  widgetId,
  showNote,
  noteMessage,
  notePosition,
  noteBackground,
  noteIcon,
  noteUrl,
  noteFontColor,
}) {
  const dispatch = useDispatch()
  const widgetData = useSelector(selectGradeData)
  const [isBoth, setIsBoth] = useState(false)
  const [tutorial, setTutorial] = useState(gradeTutorial)

  const tutorialStylesClasses = tutorialStyles()
  const tutorialStylesForBothUserRolesClasses = tutorialStylesForBothUserRoles()
  const [tutorialClasses, setTutorialClasses] = useState(tutorialStylesClasses)
  const widgetCallState = useSelector(selectGradeStatus)
  const showTutorial = useSelector(selectShowGradeTutorial)
  const tab = useSelector(selectGradeTab)
  const stateEndTutorial = useSelector(endTutorial)
  const classes = gradeStyles()

  const widgetTutorialData = useSelector(selectDefaultTutorialData)
  const widgetTutorialDataCallState = useSelector(selectDefaultTutorialStatus)

  React.useEffect(() => {
    dispatch(fetchGrade()).then((responseData) => {
      console.log(responseData)
      if (
        responseData?.payload[0]?.data?.rbhs &&
        responseData?.payload[0]?.data?.rbhs?.length > 0
      ) {
        dispatch(updateTab('rbhs'))
      }
      if (
        responseData?.payload[0]?.data?.rbhs &&
        responseData?.payload[0]?.data?.rbhs?.length > 0 &&
        responseData?.payload[0]?.data?.ru &&
        responseData?.payload[0]?.data?.ru.length > 0
      ) {
        setIsBoth(true)
        setTutorial(gradeTutorialForTwoUserRoles)
        setTutorialClasses(tutorialStylesForBothUserRolesClasses)
      } else {
        setIsBoth(false)
        setTutorial(gradeTutorial)
        setTutorialClasses(tutorialStylesClasses)
      }
    })
  }, [])

  return (
    <Widget className={className}>
      <Widget.Header title={title} category="default" />
      <Widget.Content
        className={classes.content}
        showNote={showNote}
        noteMessage={noteMessage}
        notePosition={notePosition}
        noteBackground={noteBackground}
        noteIcon={noteIcon}
        noteUrl={noteUrl}
        noteFontColor={noteFontColor}
      >
        {renderGradeState(
          widgetCallState,
          widgetData,
          tab,
          showTutorial,
          stateEndTutorial,
          dispatch,
          widgetId,
          title,
          widgetTutorialData,
          widgetTutorialDataCallState,
          isBoth,
          tutorial,
          tutorialClasses,
        )}
      </Widget.Content>
    </Widget>
  )
}

function renderGradeState(
  widgetCallState,
  widgetData,
  tab,
  showTutorial,
  stateEndTutorial,
  dispatch,
  widgetId,
  title,
  widgetTutorialData,
  widgetTutorialDataCallState,
  isBoth,
  tutorial,
  tutorialClasses,
) {
  const classes = gradeStyles()
  const showDummyData =
    (widgetData.ru === null || widgetData.rbhs === null) &&
    !widgetTutorialData &&
    showTutorial
  if (showDummyData) {
    dispatch(fetchDefaultTutorialData())
  }
  if ((widgetData.ru === null || widgetData.rbhs === null) && showTutorial) {
    switch (widgetTutorialDataCallState) {
      case 'fulfilled':
        return (
          <Loaded
            transcript={widgetTutorialData.transcript}
            semesters={widgetTutorialData[tab]}
            semesterData={widgetTutorialData}
            showTutorial={showTutorial}
            stateEndTutorial={stateEndTutorial}
            dispatch={dispatch}
            widgetId={widgetId}
            title={title}
            isBoth={isBoth}
            tutorial={tutorial}
            tutorialClasses={tutorialClasses}
          />
        )
      case 'pending':
        return <Loading />
      case 'error':
        return <ErrorMessage />
      default:
        return <DisplayDefaultCaseUI classes={classes} />
    }
  } else {
    switch (widgetCallState) {
      case 'fulfilled':
        return (
          <Loaded
            transcript={widgetData.transcript}
            semesters={widgetData[tab]}
            semesterData={widgetData}
            showTutorial={showTutorial}
            stateEndTutorial={stateEndTutorial}
            dispatch={dispatch}
            widgetId={widgetId}
            title={title}
            isBoth={isBoth}
            tutorial={tutorial}
            tutorialClasses={tutorialClasses}
          />
        )
      case 'pending':
        return <Loading />
      case 'error':
        return <DisplayErrorCaseUI classes={classes} />
      default:
        return <DisplayDefaultCaseUI classes={classes} />
    }
  }
}

function DisplayDefaultCaseUI({ classes }) {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item className={classes.text}></Grid>
    </Grid>
  )
}

function DisplayErrorCaseUI({ classes }) {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <ErrorMessage />
    </Grid>
  )
}

function CourseObject({ course, greyBackground, sem }) {
  const classes = gradeStyles()
  // TODO Update showgrades to grab from state
  const showSensitiveInfo = useSelector(selectShowSensitiveInfo)
  const [showGrade, setShowGrade] = useState(showSensitiveInfo)

  // TODO Hook this up to topbar action buttons
  useEffect(() => {
    // Detects change in showSensitiveInfo
    setShowGrade(showSensitiveInfo)
  }, [showSensitiveInfo])

  useEffect(() => {
    setShowGrade(false)
  }, [sem])

  let shortened = course.courseName
    .split(' ')
    .map((word) => (word.length < 4 ? word : `${word.substring(0, 4)}`))
    .join(' ')

  if (shortened.length > 15) {
    shortened = shortened.substring(0, 15)
  }

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className={
        greyBackground ? classes.greyBackground : classes.nonGreyBackground
      }
    >
      <Grid item xs={4}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          className={classes.courseContainer}
        >
          <Grid item>
            <Text variant="caption">
              <b>
                {[...course.courseName].length < 15
                  ? course.courseName
                  : shortened}
              </b>
            </Text>
          </Grid>

          <Grid item>
            <Grid container>
              <Grid item>
                <Text variant="caption">Course:</Text>
              </Grid>

              <Grid item>
                <Text variant="caption">{` ${course.courseNumber}`}</Text>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container>
              <Grid item>
                <Text variant="caption">Section:</Text>
              </Grid>

              <Grid item>
                <Text variant="caption">{` ${course.section}`}</Text>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container>
              <Grid item>
                <Text variant="caption">Credits:</Text>
              </Grid>

              <Grid item>
                <Text variant="caption">{` ${course.credits}`}</Text>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <Grid container direction="column">
          <Grid item>
            <Text variant="caption">{course.department}</Text>
          </Grid>

          <Grid item>
            <Text variant="caption">{course.school}</Text>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={2}>
        <IconButton
          edge={false}
          color="inherit"
          aria-label="show/hide grade"
          onClick={() => setShowGrade(!showGrade)}
          className={classes.gradeButtonContent}
        >
          {showGrade || showSensitiveInfo ? (
            <Text variant="h4">{course.grade}</Text>
          ) : (
            <VisibilityOff className={classes.visibilityIcon} />
          )}
        </IconButton>
      </Grid>
    </Grid>
  )
}

function Loaded({
  transcript,
  semesters,
  semesterData,
  stateEndTutorial,
  dispatch,
  widgetId,
  title,
  isBoth,
  tutorial,
  tutorialClasses,
}) {
  const classes = gradeStyles()

  // TODO Make sure this grabs from actual state, not faked state
  const [semesterList, setSemesterList] = useState([])
  const [semester, setSemester] = useState('')
  const [courses, setCourses] = useState([])
  const tab = useSelector(selectTab)
  const [showTutorialBool, setShowTutorial] = React.useState(false)

  const handleChange = (event) => {
    const semesterSelected = event.target.value
    console.log('semesterSelected ', semesterSelected)
    setSemester(semesterSelected)
  }

  const handleTabChange = (e, newValue) => {
    dispatch(updateTab(newValue))
  }

  //   React.useEffect(() => {
  //     setCourses([])
  //     setSemester('')
  //     setSemesterList([])
  //   }, [semesters])

  React.useEffect(() => {
    if (semesters) {
      for (let i = 0; i < semesters.length; i++) {
        if (semesters[i].name === semester) {
          setCourses(semesters[i].courses)
        }
      }
    }
  }, [semester])

  React.useEffect(() => {
    const list = []
    if (semesterData[tab]) {
      for (let i = 0; i < semesterData[tab].length; i++) {
        list.push(semesterData[tab][i].name)
      }
      setSemesterList(list)
      setSemester(String(list[0]))
      setCourses(semesters[0].courses)
    } else {
      setSemesterList([])
      setSemester('')
      setCourses([])
    }
  }, [semesters])

  React.useEffect(() => {
    const list = []
    if (semesterData[tab]) {
      for (let i = 0; i < semesterData[tab].length; i++) {
        list.push(semesterData[tab][i].name)
      }
      setSemesterList(list)
      setSemester(String(list[0]))
      setCourses(semesters[0].courses)
    } else {
      setSemesterList([])
      setSemester('')
      setCourses([])
    }
    const fetchTutorialData = async () => {
      const userDocRef = doc(
        collection(firestore, 'users'),
        localStorage.getItem('user'),
        'settings',
        'tutorials',
      )
      const docData = await getDoc(userDocRef)
      setShowTutorial(docData.data()?.show_my_grades_tutorial)
    }
    fetchTutorialData()

    return () => setShowTutorial(false)
  }, [])

  const handleTranscriptButton = () => {
    const { link } = transcript
    if (widgetId && dispatch) {
      logDashboardEvent(dispatch, 'dashboardLinkEvent', widgetId, title, link)
    }
    window.open(link)
  }
  return (
    <div className={classes.widgetContent}>
      {isBoth === true ? (
        <RutgersTabs
          value={tab}
          onChange={handleTabChange}
          aria-label="Top Tab"
        >
          <RutgersTab label="RU" value="ru" {...a11yProps(0)} />
          <RutgersTab label="RBHS" value="rbhs" {...a11yProps(1)} />
        </RutgersTabs>
      ) : null}
      {semesterData[`${tab}Holds`] ? (
        <>
          {semesterData[`${tab}Holds`].map((hold) => (
            <Grid item style={{ width: '100%' }}>
              <Card
                className={classes.award}
                style={{ textAlign: 'center', alignItems: 'center' }}
                variant="outlined"
              >
                <Grid container direction="column" alignItems="center">
                  <Grid item>
                    <IconPicker
                      iconName={hold?.icon?.icon || 'exclamation-circle'}
                      color={hold?.icon?.color || '#cc0033'}
                      size="5x"
                    ></IconPicker>
                  </Grid>
                  <Grid item>
                    <Text
                      style={{ marginTop: '10px', marginBottom: '5px' }}
                      className={classes.titleStyle}
                      variant="body1"
                    >
                      {hold.title}
                    </Text>
                  </Grid>
                  <Grid item>
                    <Text variant="body1">
                      {hold.message
                        .split('https://personalinfo.rutgers.edu.')
                        .map((segment, index, array) => {
                          const key = `segment_${index}_${segment}`
                          return (
                            <React.Fragment key={key}>
                              {segment}
                              {index !== array.length - 1 && (
                                <u>
                                  <a
                                    href="https://personalinfo.rutgers.edu."
                                    style={{
                                      fontWeight: 'bold',
                                      color: 'black',
                                    }}
                                  >
                                    https://personalinfo.rutgers.edu.
                                  </a>
                                </u>
                              )}
                            </React.Fragment>
                          )
                        })}
                    </Text>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          ))}
        </>
      ) : (
        <>
          <Grid
            container
            justifyContent="space-around"
            alignItems="flex-end"
            direction="row"
            className={classes.headerContainer}
          >
            <Grid item>
              <InputLabel id="semester-select" className={classes.formControl}>
                <Select
                  labelId="semester-select"
                  value={semester}
                  onChange={handleChange}
                  className={classes.select}
                  inputProps={{
                    classes: {
                      icon: classes.icon,
                    },
                  }}
                >
                  {semesterList.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuItem key={index} value={item} aria-label={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </InputLabel>
            </Grid>

            <Grid item className={classes.transcriptContainer}>
              <Button onClick={() => handleTranscriptButton()}>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item>
                    <DescriptionIcon className={classes.transcriptIconButton} />
                  </Grid>

                  <Grid item>
                    <Text variant="caption" color="textSecondary">
                      Unofficial Transcript
                    </Text>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.header}
          >
            <Grid item xs={4}>
              <Text variant="subtitle1">Course</Text>
            </Grid>

            <Grid item xs={6}>
              <Text variant="subtitle1">Dept/School</Text>
            </Grid>

            <Grid item xs={2}>
              <Text variant="subtitle1">Grade</Text>
            </Grid>
          </Grid>
          <div className={classes.grades}>
            {courses.length > 0 ? (
              courses.map((course, index) => (
                <CourseObject
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  course={course}
                  sem={semester}
                  greyBackground={index % 2 === 0}
                />
              ))
            ) : (
              <Grid item>
                <Box
                  display="flex"
                  alignItems="center"
                  marginLeft="auto"
                  marginRight="auto"
                  flexDirection="column"
                >
                  <IconPicker
                    iconName="check-circle"
                    color="#00b339"
                    size="5x"
                  />
                  <Text variant="subtitle1">No Grade Data</Text>
                </Box>
              </Grid>
            )}
          </div>
          {showTutorialBool === true ? (
            <TutorialMode
              showTutorial={showTutorialBool}
              steps={tutorial}
              styles={tutorialClasses}
              stateEndTutorial={stateEndTutorial}
              dispatch={dispatch}
            />
          ) : null}
        </>
      )}
    </div>
  )
}

export default Grade
