import { makeStyles } from '@material-ui/core/styles'

// TODO Bring Back Unique Colors
export const myDegreeStyles = makeStyles((theme) => ({
  degreeContainer: {
    textAlign: 'center',
    overflowY: 'auto',
    position: 'relative',
  },
  icon: {
    color: theme.palette.secondary.headerText,
    fontSize: 40,
    '&:hover': {
      transform: 'scale(1.07)',
    },
  },
  tableRow: {
    display: 'flex',
    width: '100%',
  },
  containerMargin: {
    marginTop: 25,
  },
  blueIcon: {
    color: theme.palette.base.blue,
  },
  greenIcon: {
    color: theme.palette.base.green,
  },
  redIcon: {
    color: theme.palette.base.red,
  },
  verticalSpace: {
    margin: '15px 0px',
  },
  award: {
    marginTop: '15px',
    margin: '10px',
    padding: '5px',
    backgroundColor: theme.palette.backgroundColor.ternary,
  },
  titleStyle: {
    fontWeight: 'bold',
  },
}))

export default myDegreeStyles
