import * as React from 'react'
import {
  Grid,
  Typography as Text,
  Card,
  Link,
  Collapse,
  Box,
  IconButton,
} from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { MyCasesObjectStyles } from './itServiceCenterStyles'
import { logDashboardEvent } from '../../events'

/**
 * MyCase Object that shows details on cases in the
 * My Cases tab of the IT Service Center widget
 * @param cases The case object to be passed down
 * @param status Tells us if the case is closed or open
 */
function MyCasesObject({ cases, status, widgetId, dispatch }) {
  const classes = MyCasesObjectStyles()

  const [expanded, setExpanded] = React.useState(false)
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const link = `${process.env.REACT_APP_ITSTAFF_BASE_URL}${cases.url}`
  const handleLinkClick = (eventLink, title) => {
    if (widgetId && dispatch) {
      logDashboardEvent(dispatch, 'dashboardEvent', widgetId, title, eventLink)
    }
  }

  return (
    <Card className={classes.caseCardBackground} border={1} variant="outlined">
      <Grid container justifyContent="flex-start" direction="column">
        <Grid item className={classes.gridItem}>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            underline="none"
            onClick={() => handleLinkClick(link, cases.title)}
          >
            <Text variant="subtitle1">{cases.title}</Text>
          </Link>
        </Grid>

        <Grid item>
          <Grid>
            <Grid item>
              <Text variant="subtitle2">{cases.display_value}</Text>
            </Grid>

            <Grid item>
              <Box display="flex">
                <Text variant="subtitle2">{cases.number}</Text>
                <Text
                  variant="subtitle2"
                  style={{ textAlign: 'right' }}
                  className={
                    status === 'closed'
                      ? classes.caseIDTextClosed
                      : classes.caseIDTextOpen
                  }
                >
                  {status === 'closed' ? 'Closed' : `Open`}
                </Text>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid
            container
            justifyContent="flex-start"
            direction="column"
            className={classes.caseDescription}
          >
            <Grid item>
              <IconButton
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
                className={classes.iconButton}
              >
                <ExpandMoreIcon
                  className={
                    expanded ? classes.expandOpen : classes.expandClosed
                  }
                />

                <Text variant="button">Case Description</Text>
              </IconButton>

              <Collapse in={expanded} timeout="auto" unmountOnExit>
                {cases.description.length === 0 ? (
                  <Text variant="caption">There is no case description.</Text>
                ) : (
                  <Text variant="caption" className={classes.descriptionText}>
                    {cases.description}
                  </Text>
                )}
              </Collapse>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}

export default MyCasesObject
