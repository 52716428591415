import { makeStyles } from '@material-ui/styles'

export const InfoPageStyles = makeStyles((theme) => ({
  appBar: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 25,
    background: theme.palette.background.paper,
    color: 'rgba(0,0,0,0.54)',
  },
  appBarContainer: {
    flex: 1,
    width: '100%',
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
    overscrollBehavior: 'contain',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    justifyContent: 'center',
    // paddingTop: 55,
  },
  pageTitle: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 1,
    wordBreak: 'keep-all',
    overflow: 'hidden',
    color: theme.palette.base.red,
    fontSize: 22,
    fontWeight: '400',
  },
  container: {
    background: theme.palette.components.newsBackground,
    overflowX: 'hidden',
    width: '100%',
    paddingBottom: '24px',
    paddingTop: '48px',
    paddingLeft: '55px',
    paddingRight: '20px',
    height: '100vh',
  },
  headerContainer: {
    background: theme.palette.components.newsBackground,
    borderRadius: '15px',
    height: 'auto',
    marginTop: '16px',
    marginLeft: '16px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px',
    },
  },
  img: {
    height: '200px',
    width: 'auto',
    maxWidth: '50%',
    marginTop: 'auto',
    marginBottom: 'auto',
    paddingTop: '10px',
    paddingBottom: '10px',
    marginLeft: '10px',
    borderRadius: '15px 15px 15px 15px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '0px',
      maxWidth: '100%',
    },
  },
  categoryTitleContainer: {
    marginLeft: '16px',
    paddingRight: '16px',
    marginTop: '8px',
  },
  categoryTitle: {
    textAlign: 'start',
    color: theme.palette.primary.headerText,
    fontSize: '32px',
  },
  categoryDescription: {
    textAlign: 'start',
    color: theme.palette.primary.headerText,
    fontSize: '20px',
    marginRight: '20px',
    paddingBottom: '10px',
  },
  appContainer: {
    background: theme.palette.components.newsBackground,
    borderRadius: '8px',
    marginBottom: '30px',
    marginLeft: '3%',
  },
}))

export default InfoPageStyles
