/* eslint-disable react/no-array-index-key */
import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { Grid, Box, Link, Typography as Text } from '@material-ui/core'

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import { itServiceCenterStyles } from './itServiceCenterStyles'
import {
  TabPanel,
  a11yProps,
} from '../../DashboardComponents/TabPanel/TabPanel'
import Widget, { SmallIconLink } from '../Widget'
import SearchBar from '../../DashboardComponents/SearchBar/SearchBar'

import MyCasesObject from './MyCasesObject'
import Announcement from './Announcement'
import {
  fetchITSMAnnouncements,
  fetchITSMCases,
  updateQuery,
  updateTabValue,
  updateTabValue2,
} from './itServiceCenterSlice'
import Footer from '../../DashboardComponents/Footer/Footer'
import Loading from '../helpers/Loading'
import {
  RutgersTabs,
  RutgersTab,
} from '../../DashboardComponents/Tabs/RutgersTabs'
import CaseIndicator from '../../DashboardComponents/CaseIndicator/CaseIndicator'
import { logDashboardEvent } from '../../events'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'

/**
 * IT Service Center Widget
 * Shows announcements and cases for the account as well as redirect links
 * @param {*} param0
 * @returns
 */
// eslint-disable-next-line no-unused-vars
export default function ITServiceCenter({ className, title, widgetId }) {
  const classes = itServiceCenterStyles()
  const dispatch = useDispatch()
  const { announcements, status } = useSelector((s) => s.itServiceCenter)
  React.useEffect(() => {
    dispatch(fetchITSMAnnouncements())
    dispatch(fetchITSMCases())
  }, [])
  return (
    <Widget className={className}>
      <Widget.Header title={title} />
      <Widget.Content className={classes.content}>
        {renderFSCState(announcements, status, widgetId)}
      </Widget.Content>
    </Widget>
  )
}

function renderFSCState(announcements, status, widgetId) {
  switch (status) {
    case 'fulfilled':
      return <Loaded announcements={announcements} widgetId={widgetId} />
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage />
        </Grid>
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item></Grid>
        </Grid>
      )
  }
}
function renderOpenCaseState(caseStatus, widgetId, dispatch) {
  switch (caseStatus) {
    case 'fulfilled':
      return <MyOpenCases widgetId={widgetId} dispatch={dispatch} />
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage />
        </Grid>
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item></Grid>
        </Grid>
      )
  }
}
function renderClosedCaseState(caseStatus, widgetId, dispatch) {
  switch (caseStatus) {
    case 'fulfilled':
      return <MyClosedCases widgetId={widgetId} dispatch={dispatch} />
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>Error</Grid>
        </Grid>
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item></Grid>
        </Grid>
      )
  }
}

function Loaded(props) {
  const { announcements, widgetId } = props
  const classes = itServiceCenterStyles()
  const dispatch = useDispatch()
  const settingsObj = useSelector((s) => s.settings.data)
  const { darkMode } = settingsObj

  const handleChange = (event, newValue) => {
    dispatch(updateTabValue(newValue))
  }
  const handleChange2 = (event, newValue2) => {
    dispatch(updateTabValue2(newValue2))
  }

  const { value, value2, query, caseStatus, myCasesOpen } = useSelector(
    (s) => s.itServiceCenter,
  )
  React.useEffect(() => {
    if (value === 1) {
      dispatch(fetchITSMCases())
    }
  }, [value])

  // Search bar onSearch: static link, the
  // We want it to redirect the page to this link when user searches
  const searchOnRequest = () => {
    // Spaces are replaced by '%20' in the search link
    const redirectString = query.toString()
    const link = `https://rutgers.service-now.com/sp?id=search&spa=1&q=${encodeURIComponent(
      redirectString,
    )}`

    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardEvent',
        widgetId,
        redirectString,
        link,
      )
    }
    // Opens new tab with search bar value
    window.open(link, '_blank')
  }

  const buttonTitle = 'Launch Rutgers IT Help'
  const handleFooterLinkClick = (link) => {
    if (widgetId && dispatch) {
      logDashboardEvent(dispatch, 'dashboardEvent', widgetId, buttonTitle, link)
    }
  }

  return (
    <Grid
      container
      direction="column"
      className={classes.mainGrid}
      wrap="nowrap"
    >
      <Grid container direction="column" wrap="nowrap">
        <Grid item className={classes.imgContainer}>
          <img
            className={classes.img}
            alt="OneSource"
            src={
              darkMode === true
                ? './assets/images/itservicecenter_dark.png'
                : './assets/images/itservicecenter_light.png'
            }
          />
        </Grid>

        <Grid item className={classes.searchBar}>
          <SearchBar
            placeholder="How can we help you?"
            value={query}
            onChange={(result) => dispatch(updateQuery(result))}
            onCancelSearch={() =>
              dispatch(updateQuery({ target: { value: '' } }))
            }
            webViewMode
            onRequestSearch={searchOnRequest}
            variant="outlined"
          />
        </Grid>
      </Grid>

      <Grid className={classes.modalTabs}>
        <RutgersTabs value={value} onChange={handleChange} aria-label="Top Tab">
          <RutgersTab label="Alerts and Notices" {...a11yProps(0)} />
          <RutgersTab
            label={
              <Box display="flex">
                <Text className={classes.tabTextLabel}>My Items</Text>
                {myCasesOpen && myCasesOpen?.results?.length > 0 && (
                  <CaseIndicator value={myCasesOpen?.results?.length} />
                )}
              </Box>
            }
            {...a11yProps(1)}
          />
        </RutgersTabs>
      </Grid>
      <Grid item className={classes.scrollContent}>
        <TabPanel value={value} index={0}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
          >
            <div className={classes.announcementList}>
              <Announcement
                announcements={announcements}
                widgetId={widgetId}
                dispatch={dispatch}
              />
            </div>
          </Grid>
        </TabPanel>

        {/* My cases tab */}
        <TabPanel value={value} index={1}>
          <Box className={classes.modalTabs}>
            <RutgersTabs
              value={value2}
              onChange={handleChange2}
              aria-label="Top Tab"
            >
              <RutgersTab label="Open" {...a11yProps(2)} />
              <RutgersTab label="Closed" {...a11yProps(3)} />
            </RutgersTabs>
          </Box>

          {/* Open tab */}
          <TabPanel value={value2} index={0}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
            >
              <div className={classes.casesList}>
                {renderOpenCaseState(caseStatus, widgetId, dispatch)}
              </div>
            </Grid>
          </TabPanel>

          {/* Closed tab */}
          <TabPanel value={value2} index={1}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
            >
              <div className={classes.casesList}>
                {renderClosedCaseState(caseStatus, widgetId, dispatch)}
              </div>
            </Grid>
          </TabPanel>
        </TabPanel>
      </Grid>
      {/* Bottom container */}
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        wrap="nowrap"
        className={classes.bottomContainer}
      >
        <Grid
          item
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          className={classes.grid1}
        >
          <Grid item>
            <SmallIconLink
              classes={classes}
              icon="fas fa-book-open"
              iconLabel={
                <Text component="span" className={classes.tabLink}>
                  Find Answers
                </Text>
              }
              link={`${process.env.REACT_APP_ITSTAFF_BASE_URL}/sp?id=ithelp_kb_view2`}
              widgetId={widgetId}
              dispatch={dispatch}
            />
          </Grid>

          <Grid item>
            <SmallIconLink
              classes={classes}
              icon="fas fa-book"
              iconLabel={
                <Text component="span" className={classes.tabLink}>
                  Request Services
                </Text>
              }
              link={`${process.env.REACT_APP_ITSTAFF_BASE_URL}/sp?id=sc_category`}
              widgetId={widgetId}
              dispatch={dispatch}
            />
          </Grid>

          <Grid item>
            <SmallIconLink
              classes={classes}
              icon="fas fa-question"
              iconLabel={
                <Text component="span" className={classes.tabLink}>
                  Get Help
                </Text>
              }
              link={`${process.env.REACT_APP_ITSTAFF_BASE_URL}/sp?id=sc_cat_item&sys_id=3c2100e41b672490217ca9bfbd4bcb86`}
              widgetId={widgetId}
              dispatch={dispatch}
            />
          </Grid>
        </Grid>
        <Footer>
          <Text variant="subtitle1">
            <Link
              href={`${process.env.REACT_APP_ITSTAFF_BASE_URL}/sp?id=index`}
              target="_blank"
              underline="none"
              color="secondary"
              onClick={() =>
                handleFooterLinkClick(
                  `${process.env.REACT_APP_ITSTAFF_BASE_URL}/sp?id=index`,
                )
              }
            >
              {buttonTitle}
            </Link>
          </Text>
        </Footer>
      </Grid>
    </Grid>
  )
}

function MyOpenCases({ widgetId, dispatch }) {
  const classes = itServiceCenterStyles()
  const { myCasesOpen } = useSelector((s) => s.itServiceCenter)
  return (
    <>
      {myCasesOpen && myCasesOpen.results.length === 0 ? (
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <CheckCircleOutlineIcon className={classes.circleIcon} />
          </Grid>

          <Grid item>
            <Text variant="subtitle1">No Open Case Data</Text>
          </Grid>
        </Grid>
      ) : (
        <Grid container direction="column">
          <>
            {myCasesOpen?.results?.map((cases, index) => (
              <MyCasesObject
                key={index}
                cases={cases}
                widgetId={widgetId}
                dispatch={dispatch}
              />
            ))}
          </>
        </Grid>
      )}
    </>
  )
}

function MyClosedCases({ widgetId, dispatch }) {
  const classes = itServiceCenterStyles()
  const { myCasesClosed } = useSelector((s) => s.itServiceCenter)
  return (
    <>
      {myCasesClosed && myCasesClosed?.results?.length === 0 ? (
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <CheckCircleOutlineIcon className={classes.circleIcon} />
          </Grid>

          <Grid item>
            <Text variant="subtitle1">No Closed Case Data</Text>
          </Grid>
        </Grid>
      ) : (
        <Grid container direction="column">
          <>
            {myCasesClosed?.results?.map((cases, index) => (
              <MyCasesObject
                key={index}
                cases={cases}
                status="closed"
                widgetId={widgetId}
                dispatch={dispatch}
              />
            ))}
          </>
        </Grid>
      )}
    </>
  )
}
