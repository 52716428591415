/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchCareerServicesEvents = createAsyncThunk(
  'fetchCareerServicesEvents',
  async () => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const res = await axios.get(process.env.REACT_APP_CAREERSERVICESANDEVENTS, {
      headers: {
        JWT: jwtToken,
      },
    })
    return res.data
  },
)

const initialState = {
  status: 'idle',
  data: {
    title: 'Career Services and Events',
  },
}

const careerServicesEventsSlice = createSlice({
  name: 'careerServicesEventsWidget',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchCareerServicesEvents.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchCareerServicesEvents.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchCareerServicesEvents.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.data = action.payload
    },
  },
})

export const selectCareerServicesEventsData = (state) =>
  state.careerServicesEvents.data
export const selectCareerServicesEventsStatus = (state) =>
  state.careerServicesEvents.status

export default careerServicesEventsSlice.reducer
