import React, { useState, useEffect } from 'react'
import { Slide, Grid } from '@material-ui/core'
import { useSelector } from 'react-redux'
import useNewsStyles from '../../NewsStyles'
import CheckIsMobile from '../../../../../utils/isMobileHook'
import NewsSearchBar from './NewsSearchBar'

const NewsSearchBarMobile = () => {
  const classes = useNewsStyles()
  const isMobile = CheckIsMobile()
  const { search } = useSelector((state) => state.news)

  const [slideDirection, setSlideDirection] = useState('up')

  useEffect(() => {
    setSlideDirection(search.enableSearch && isMobile ? 'up' : 'down')
  }, [search.enableSearch, isMobile])

  return (
    <Slide
      direction={slideDirection}
      in={isMobile && search.enableSearch}
      mountOnEnter
      unmountOnExit
    >
      <Grid item className={classes.searchBar} xs={12}>
        <NewsSearchBar isMobile={isMobile} />
      </Grid>
    </Slide>
  )
}

export default NewsSearchBarMobile
