import { makeStyles, createStyles } from '@material-ui/styles'

export const useMapSearchBarStyles = makeStyles((theme) =>
  createStyles({
    searchBarContainer: {
      position: 'absolute',
      left: '53%',
      top: '16%',
      zIndex: 900,
      width: '70vw',
      transform: 'translate(-50%, -50%)',
    },
    searchBar: {
      backgroundColor: theme.palette.backgroundColor.secondary,
      width: '100%',
    },
    searchBarContainerMobile: {
      zIndex: 900,
    },
    searchBarMobile: {
      backgroundColor: theme.palette.backgroundColor.secondary,
      width: '100%',
    },
  }),
)

export default useMapSearchBarStyles
