/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { doc, collection, getDoc, setDoc, updateDoc } from 'firebase/firestore'
import axios from 'axios'
import { firestore } from '../../../utils/firebase'
import { getCookie } from '../../../utils/utils'

export const fetchSettings = createAsyncThunk('fetchSettings', async () => {
  const userSettingsDocRef = doc(
    collection(firestore, 'users'),
    getCookie('rutgersEduRCPID'),
    'settings',
    'preferences',
  )
  const docRef = doc(
    collection(firestore, 'users'),
    getCookie('rutgersEduRCPID'),
    'settings',
    'userState',
  )
  getDoc(docRef)
  const docSnapshot = axios.all([
    await getDoc(userSettingsDocRef),
    await getDoc(docRef),
  ])
  return docSnapshot
})

export const fetchUser = createAsyncThunk('fetchUser', async () => {
  const userDocRef = doc(
    collection(firestore, 'users'),
    localStorage.getItem('user') ?? 'visitor',
    'userInfo',
    'Info',
  )
  const docSnapshot = await getDoc(userDocRef)
  return docSnapshot
})

export const fetchAuthorizedUsers = createAsyncThunk('fetch', async () => {
  const authorizedUsersDocRef = doc(
    collection(firestore, 'users'),
    localStorage.getItem('user') ?? 'visitor',
    'settings',
    'userDevices',
  )

  const docSnapshot = await getDoc(authorizedUsersDocRef)
  return docSnapshot
})

export const deleteDevice = createAsyncThunk(
  'deleteDevice',
  async (deviceID) => {
    const userDevicesDocRef = doc(
      collection(firestore, 'users'),
      localStorage.getItem('user') ?? 'visitor',
      'settings',
      'userDevices',
    )
    const targetID = deviceID
    const snapshot = await getDoc(userDevicesDocRef)
    let data = snapshot.data().devices
    data = data.filter((device) => device.ID !== targetID)

    await setDoc(userDevicesDocRef, { devices: data })

    return data
  },
)

export const sendFeedback = createAsyncThunk('sendFeedback', async (body) =>
  axios.post(process.env.REACT_APP_FEEDBACK_ENDPOINT, body),
)

export const sendMyAppsRefresh = createAsyncThunk(
  'sendMyAppsRefresh',
  async () => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const rcpid = localStorage.getItem('rcpid')
    const res = await axios.get(process.env.REACT_APP_MYAPPS_REFRESH_ENDPOINT, {
      headers: {
        JWT: jwtToken,
        XWT: rcpid,
      },
    })
    return res.data
  },
)

// REACT_APP_DASHBOARD_ENDPOINT
export const restoreDashboardWidgets = createAsyncThunk(
  'restoreDashboardWidgets',
  async () => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    const rcpid = localStorage.getItem('rcpid')
    const res = await axios.get(process.env.REACT_APP_DASHBOARD_ENDPOINT, {
      headers: {
        JWT: jwtToken,
        XWT: rcpid,
        Guesttoken: '!!RESET!!',
      },
    })
    return res.data
  },
)

const initialState = {
  status: 'idle',
  refreshDashboardWidgetsStatus: 'idle',
  showSensitiveInfo: false,
  adminMode: false,
  data: {
    darkMode: false,
  },
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    toggleAdminMode: (state, action) => {
      const docRef = doc(
        collection(firestore, 'users'),
        localStorage.getItem('user') ?? 'visitor',
        'settings',
        'userState',
      )
      getDoc(docRef).then((docSnap) => {
        const snapshotData = docSnap.data()
        if (snapshotData.adminModeActive) {
          setDoc(docRef, {
            adminModeActive: action.payload,
            acknowledgedCommunity: snapshotData?.acknowledgedCommunity ?? false,
          })
        } else if (!snapshotData.adminModeActive) {
          setDoc(docRef, {
            adminModeActive: action.payload,
            acknowledgedCommunity: snapshotData?.acknowledgedCommunity ?? false,
          })
        }
      })
      state.adminMode = action.payload
    },
    toggleTheme: (state) => {
      state.data.darkMode = !state.data.darkMode
      const darkMode = state.data.darkMode ? 1 : 0
      const docRef = doc(
        collection(firestore, 'users'),
        localStorage.getItem('user') ?? 'visitor',
        'settings',
        'preferences',
      )

      getDoc(docRef)
        .then((docSnapshot) => {
          if (docSnapshot.exists()) {
            updateDoc(docRef, { dark_mode_override: darkMode })
          } else {
            setDoc(docRef, { dark_mode_override: darkMode })
          }
        })
        .catch((error) => {
          console.error('Error toggling theme:', error)
          throw error
        })
    },
    toggleClosestStop: (state) => {
      state.data.closestStop = !state.data.closestStop
      const closestStop = state.data.closestStop ? 1 : 0

      const docRef = doc(
        collection(firestore, 'users'),
        localStorage.getItem('user') ?? 'visitor',
        'settings',
        'preferences',
      )

      getDoc(docRef)
        .then((docSnapshot) => {
          if (docSnapshot.exists()) {
            updateDoc(docRef, { init_closest_stop: closestStop })
          } else {
            setDoc(docRef, { init_closest_stop: closestStop })
          }
        })
        .catch((error) => {
          console.error('Error toggling closest stop:', error)
          throw error
        })

      updateDoc(docRef, { init_closest_stop: closestStop }).catch((error) => {
        console.error('Error updating closest stop:', error)
        throw error
      })
    },
    toggleLinkPreference: (state) => {
      state.data.linkPreference = !state.data.linkPreference
      const linkPreference = state.data.linkPreference ? 1 : 0

      const docRef = doc(
        collection(firestore, 'users'),
        localStorage.getItem('user') ?? 'visitor',
        'settings',
        'preferences',
      )

      updateDoc(docRef, { browser_open_smartlinks: linkPreference })
        .then(() => {
          console.log('Link preference updated successfully')
        })
        .catch((error) => {
          console.error('Error updating link preference:', error)
          throw error
        })
    },
    deleteTutorials: () => {
      const docRef = doc(
        collection(firestore, 'users'),
        localStorage.getItem('user') ?? 'visitor',
        'settings',
        'tutorials',
      )

      setDoc(docRef, {})
        .then(() => {
          console.log('Tutorials document set successfully')
        })
        .catch((error) => {
          console.error('Error setting tutorials document:', error)
          throw error
        })
    },
    toggleShowSensitiveInfo: (state) => {
      state.showSensitiveInfo = !state.showSensitiveInfo

      const showSensitiveInfoPref = state.showSensitiveInfo ? 1 : 0

      const docRef = doc(
        collection(firestore, 'users'),
        localStorage.getItem('user') ?? 'visitor',
        'settings',
        'preferences',
      )

      getDoc(docRef)
        .then((docSnapshot) => {
          if (docSnapshot.exists()) {
            updateDoc(docRef, { show_sensitive_info: showSensitiveInfoPref })
          } else {
            setDoc(docRef, { show_sensitive_info: showSensitiveInfoPref })
          }
        })
        .catch((error) => {
          console.error('Error show sensitivity:', error)
          throw error
        })
    },
  },
  extraReducers: {
    [fetchSettings.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchSettings.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchSettings.fulfilled]: (state, action) => {
      state.status = 'fulfilled'

      // show check if there is a user defined such that we can get their privacy settings
      state.showSensitiveInfo =
        action.payload[0].data()?.show_sensitive_info === 1
      state.data.darkMode =
        action.payload[0]?.data()?.dark_mode_override &&
        action.payload[0]?.data()?.dark_mode_override === 1
      state.adminMode = action.payload[1].data()?.adminModeActive
        ? action.payload[1].data().adminModeActive
        : false
    },
    [fetchAuthorizedUsers.pending]: (state) => {
      state.devicesStatus = 'pending'
    },
    [fetchAuthorizedUsers.rejected]: (state) => {
      state.devicesStatus = 'rejected'
    },
    [fetchAuthorizedUsers.fulfilled]: (state, action) => {
      state.devicesStatus = 'fulfilled'
      state.data.authorizedDevices = action.payload.data()
    },
    [fetchUser.pending]: (state) => {
      state.userStatus = 'pending'
    },
    [fetchUser.rejected]: (state) => {
      state.userStatus = 'rejected'
    },
    [fetchUser.fulfilled]: (state, action) => {
      state.data.user = action.payload.data() ? action.payload.data() : []
    },
    [deleteDevice.rejected]: (state) => {
      state.deleteDeviceStatus = 'rejected'
    },
    [deleteDevice.pending]: (state) => {
      state.userStatus = 'pending'
      state.deleteDeviceStatus = 'pending'
    },
    [deleteDevice.fulfilled]: (state) => {
      state.deleteDeviceStatus = 'fulfilled'
    },
    [sendMyAppsRefresh.rejected]: (state) => {
      state.refreshStatus = 'error'
    },
    [sendMyAppsRefresh.pending]: (state) => {
      state.refreshStatus = 'pending'
    },
    [sendMyAppsRefresh.fulfilled]: (state, action) => {
      state.refreshStatusState = action.payload
      state.refreshStatus = 'fulfilled'
    },
    [restoreDashboardWidgets.rejected]: (state) => {
      state.refreshDashboardWidgetsStatus = 'error'
    },
    [restoreDashboardWidgets.pending]: (state) => {
      state.refreshDashboardWidgetsStatus = 'pending'
    },
    [restoreDashboardWidgets.fulfilled]: (state) => {
      state.refreshDashboardWidgetsStatus = 'fulfilled'
    },
  },
})

export const selectSettingsData = (state) => state.settings.data
export const selectDevicesStatus = (state) => state.settings.devicesStatus
export const selectUserData = (state) => state.settings.data.user
export const selectUserStatus = (state) => state.settings.data.userStatus
export const deleteDeviceStatus = (state) => state.settings.deleteDeviceStatus
export const selectShowSensitiveInfo = (state) =>
  state.settings.showSensitiveInfo
export const selectAuthorizedDevices = (state) =>
  state.settings.data.authorizedDevices
export const selectSettingsStatus = (state) => state.settings.data.status
export const selectDarkMode = (state) => state.settings.data.darkMode
export const {
  toggleTheme,
  toggleClosestStop,
  toggleLinkPreference,
  deleteTutorials,
  toggleShowSensitiveInfo,
  toggleAdminMode,
} = settingsSlice.actions

export default settingsSlice.reducer
