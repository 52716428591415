/* eslint-disable no-unused-vars */
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from '@dnd-kit/modifiers'
import Alert from '@material-ui/lab/Alert'

import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MenuIcon from '@material-ui/icons/Menu'

import {
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
  closestCorners,
} from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'

import Container from './dnd-kit/Container'
import { Item } from './dnd-kit/SortableItem'

import styles from './ReorderWidgetsAdminStyles'
import {
  updateWidgets,
  updateActiveWidgetIds,
  setReorderDialog,
} from '../../adminPageSlice'

function ReorderWidgetsAdmin({ classes }) {
  const dispatch = useDispatch()

  const changeStatus = () => {
    dispatch(setReorderDialog(true))
  }
  return (
    <>
      <ListItem button onClick={changeStatus}>
        <ListItemIcon>
          <MenuIcon className={classes.listIconStyle} />
        </ListItemIcon>
        <ListItemText>Reorder a Widget</ListItemText>
      </ListItem>
    </>
  )
}

function ReorderWidgetsAdminDialog() {
  const classes = styles()
  const dispatch = useDispatch()
  const openDialog = useSelector((s) => s.adminPage.reorderDialogOpen)
  const widgets = useSelector((s) => s.adminPage.widgets)
  const userOrder = useSelector((s) => s.adminPage.widgets)
  const [dashboardWidgets, setDashboardWidgets] = React.useState([])
  const [edited, setEdited] = React.useState(false)

  const [activeId, setActiveId] = React.useState()

  const sensors = useSensors(
    useSensor(PointerSensor, {
      delay: 5,
    }),
  )

  const updateOrder = () => {
    // console.log('sending to updateWidgets reducer ', dashboardWidgets)
    dispatch(updateWidgets(dashboardWidgets))
    // dispatch(drawerClose())
    // setOpen(false)
    handleCancel()
  }

  const [snackBarOpen, setSnackbarOpen] = React.useState(false)

  const handleSnackbarClose = () => setSnackbarOpen(false)

  React.useEffect(() => {
    const mapped = widgets.map((widget) => {
      const element = userOrder
        ? userOrder.find((inner) => parseInt(widget.id, 10) === inner.id)
        : []
      const elementManuallyMoved = element ? element.manuallyMoved : false
      return {
        ...widget,
      }
    })
    setDashboardWidgets(mapped)
  }, [widgets])

  const handleDragStart = (event) => {
    const { active } = event
    const { id } = active
    const draggingWidget = dashboardWidgets.find(
      (widget) => parseInt(widget.id, 10) === parseInt(id, 10),
    )
    setActiveId(draggingWidget)
  }

  const handleDragEnd = (event) => {
    const { active, over } = event
    // eslint-disable-next-line no-shadow
    const { id: activeId } = active
    const { id: overId } = over
    const oldIndex = dashboardWidgets.findIndex(
      (widget) => parseInt(widget.id, 10) === parseInt(activeId, 10),
    )
    const newIndex = dashboardWidgets.findIndex(
      (widget) => parseInt(widget.id, 10) === parseInt(overId, 10),
    )
    // Adds pink background to widget moved
    if (overId !== activeId) {
      dispatch(updateActiveWidgetIds(activeId))
    }
    const copy = dashboardWidgets.map((dashboardWidget) => ({
      ...dashboardWidget,
    }))
    copy[oldIndex].manuallyMoved = Date.now()
    const newArray = arrayMove(copy, oldIndex, newIndex)
    setEdited(true)
    setActiveId(null)
    setDashboardWidgets(newArray)
  }

  const onDelete = (id) => {
    const oldIndex = dashboardWidgets.findIndex(
      (widget) => parseInt(widget.id, 10) === parseInt(id, 10),
    )
    const updatedItem = dashboardWidgets[oldIndex]
    if (updatedItem.lock) {
      setSnackbarOpen(true)
      return
    }
    updatedItem.showOnDashboard = false
    setDashboardWidgets([...dashboardWidgets, updatedItem])
    // playTrashSound()
  }

  const handleCancel = () => {
    dispatch(setReorderDialog(false))
  }

  return (
    <Dialog open={openDialog} onClose={() => handleCancel()}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis]}
      >
        <DialogTitle>Drag to Reorder Widgets</DialogTitle>
        <DialogContent dividers className={classes.dialog}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              height: '100%',
            }}
          >
            <Container
              id="active"
              items={dashboardWidgets}
              activeId={activeId}
              onDelete={onDelete}
            />
          </div>
          <DragOverlay modifiers={[restrictToParentElement]}>
            {activeId ? <Item widget={activeId} overlay /> : null}
          </DragOverlay>
          <Snackbar
            autoHideDuration={1000}
            open={snackBarOpen}
            onClose={handleSnackbarClose}
          >
            <Alert severity="error">Can&rsquo;t delete this widget!</Alert>
          </Snackbar>
        </DialogContent>
        <DialogActions>
          <Grid container directin="row" justifyContent="space-between">
            <Button onClick={handleCancel} variant="outlined" color="primary">
              Cancel
            </Button>

            <Button
              onClick={updateOrder}
              variant="outlined"
              disabled={!edited}
              color="primary"
            >
              Set Order
            </Button>
          </Grid>
        </DialogActions>
      </DndContext>
    </Dialog>
  )
}

export default ReorderWidgetsAdmin
export { ReorderWidgetsAdminDialog }
