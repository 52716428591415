import { useState, useEffect } from 'react'

/**
 * Sets the number of Alt App to display
 * Responsive based on window size
 */

// Define the dimensions and corresponding number of alt app to show
const dimensions = {
  mobile: {
    numApps: 0,
  },
  tablet: {
    numApps: 3,
  },
  desktop: {
    numApps: 4,
  },
  desktopMD: {
    numApps: 5,
  },
  desktopLG: {
    numApps: 6,
  },
  desktopXL: {
    numApps: 7,
  },
  desktopXXL: {
    numApps: 8,
  },
}

// Define your break points here
function getCurrentBreakpoint() {
  const screenWidth = window.innerWidth
  // Break point values in px
  const PHONE_WIDTH = 760
  const IPAD_WIDTH = 884
  const DESKTOP_WIDTH = 1224
  const SLIGHTY_LARGER_DESKTOP_WIDTH = 1320
  const HUGE_DESKTOP_WIDTH = 1529
  const ENOURMOUS_DESKTOP_WIDTH = 1744

  // Find which breakpoint
  const mobile = screenWidth <= PHONE_WIDTH
  const tablet = screenWidth > PHONE_WIDTH && screenWidth <= IPAD_WIDTH
  const desktop = screenWidth > IPAD_WIDTH && screenWidth <= DESKTOP_WIDTH
  const desktopMD =
    screenWidth > DESKTOP_WIDTH && screenWidth <= SLIGHTY_LARGER_DESKTOP_WIDTH
  const desktopLG =
    screenWidth >= SLIGHTY_LARGER_DESKTOP_WIDTH &&
    screenWidth <= HUGE_DESKTOP_WIDTH
  const desktopXL =
    screenWidth >= HUGE_DESKTOP_WIDTH && screenWidth <= ENOURMOUS_DESKTOP_WIDTH

  let breakPoint = null

  // Set the breakpoint type to return
  if (mobile) {
    breakPoint = 'mobile'
  } else if (tablet) {
    breakPoint = 'tablet'
  } else if (desktop) {
    breakPoint = 'desktop'
  } else if (desktopMD) {
    breakPoint = 'desktopMD'
  } else if (desktopLG) {
    breakPoint = 'desktopLG'
  } else if (desktopXL) {
    breakPoint = 'desktopXL'
  } else {
    breakPoint = 'desktopXXL'
  }

  return dimensions[breakPoint].numApps
}

const SetNumOfAppAltPerRow = () => {
  const getNumOfAppAltPerRowInitialState = () => {
    // Sets the numbers of app based on screen width initially
    const numApps = getCurrentBreakpoint()
    return numApps
  }

  const [numOfAppsPerRow, setNumOfAppsPerRow] = useState(
    getNumOfAppAltPerRowInitialState,
  )

  useEffect(() => {
    // Sets number of apps responsively
    const getNumOfAppAltPerRow = () => {
      const numApps = getCurrentBreakpoint()
      setNumOfAppsPerRow(numApps)
    }

    getNumOfAppAltPerRow()
    window.addEventListener('resize', getNumOfAppAltPerRow)

    return () => window.removeEventListener('resize', getNumOfAppAltPerRow)
  }, [])

  return numOfAppsPerRow
}

export default SetNumOfAppAltPerRow
