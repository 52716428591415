import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Box from '@material-ui/core/Box'
import Badge from '@material-ui/core/Badge'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import EmailIcon from '@material-ui/icons/Email'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import Collapse from '@material-ui/core/Collapse'
import NotificationsIcon from '@material-ui/icons/Notifications'
import CloudIcon from '@material-ui/icons/Cloud'
import DOMPurify from 'dompurify'

import Widget from '../Widget'
import {
  fetchRUConnect,
  getPrevMonth,
  getNextMonth,
  fetchCalendarEvents,
} from './myEmailRUConnectSlice'
import { TabPanel } from '../../DashboardComponents/TabPanel/TabPanel'
import myEmailRUConnectStyles from './myEmailRUConnectStyles'
import Loading from '../helpers/Loading'
import {
  RutgersTab,
  RutgersTabs,
} from '../../DashboardComponents/Tabs/RutgersTabs'
import IconPicker from '../../../apps/AppComponents/IconPicker/IconPicker'
import { textColorBasedOnBackground } from '../../../../../utils/utils'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'

dayjs.extend(utc)
dayjs.extend(timezone)
// eslint-disable-next-line no-unused-vars
function MyEmailRUConnect({
  className,
  title,
  showNote,
  noteMessage,
  notePosition,
  noteBackground,
  noteIcon,
  noteUrl,
  noteFontColor,
}) {
  const classes = myEmailRUConnectStyles()
  const dispatch = useDispatch()
  const { status, data } = useSelector((s) => s.ruConnect)
  React.useEffect(() => {
    dispatch(fetchRUConnect()).then(() => {
      dispatch(fetchCalendarEvents())
    })
  }, [])
  return (
    <Widget className={className}>
      <Widget.Header title={title} />
      <Widget.Content
        className={classes.container}
        showNote={showNote}
        noteMessage={noteMessage}
        notePosition={notePosition}
        noteBackground={noteBackground}
        noteIcon={noteIcon}
        noteUrl={noteUrl}
        noteFontColor={noteFontColor}
      >
        {renderEmail(status, data, classes)}
      </Widget.Content>
    </Widget>
  )
}

function renderEmail(widgetStatus, widgetData, classes) {
  switch (widgetStatus) {
    case 'fulfilled':
      return <Loaded data={widgetData} classes={classes} />
    case 'loading':
      return <Loading />
    case 'error':
      return <ErrorMessage />
    default:
      return <Loading />
  }
}

function Loaded({ data, classes }) {
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Grid container direction="column">
      <Grid item className={classes.modalTabs}>
        <RutgersTabs value={value} onChange={handleChange}>
          <RutgersTab label="Mail" />
          <RutgersTab label="Calendar" />
          <RutgersTab label="Apps" />
        </RutgersTabs>
      </Grid>
      <Grid item>
        <TabPanel value={value} index={0} className={classes.emailTabContent}>
          <EmailList data={data} classes={classes} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <CalendarTab data={data.events} classes={classes} />
          </MuiPickersUtilsProvider>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <QuickLinksTab classes={classes} />
        </TabPanel>
      </Grid>
    </Grid>
  )
}

function EmailList({ data, classes }) {
  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      className={classes.tabScroll}
    >
      <Grid item>
        <Grid container direction="row" justifyContent="flex-start">
          <Grid item xs={2}>
            <Typography variant="subtitle1" className={classes.headerText}>
              From
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="subtitle1" className={classes.headerText}>
              Subject
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle1">Date/Time</Typography>
          </Grid>
        </Grid>
      </Grid>
      {data.messages.value.map((email, index) => (
        <Grid item key={email.id}>
          <EmailCard email={email} classes={classes} />
          {index !== data.messages.length - 1 && <Divider variant="middle" />}
        </Grid>
      ))}
    </Grid>
  )
}

function EmailCard({ email, classes }) {
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <Box>
      <Button onClick={handleOpen} className={classes.emailButton}>
        <Grid item xs={12}>
          <Grid container direction="row" spacing={1} alignItems="flex-start">
            <Grid item xs={2}>
              <SenderImage email={email} classes={classes} />
            </Grid>
            <Grid item xs={7}>
              {email.isRead === false ? (
                <Typography align="left">
                  <b>{email.subject}</b>
                </Typography>
              ) : (
                <Typography align="left">{email.subject}</Typography>
              )}
            </Grid>
            <Grid item xs={2}>
              {email.isRead === false ? (
                <Typography>
                  <b>{getDate(email.receivedDateTime)}</b>
                </Typography>
              ) : (
                <Typography>{getDate(email.receivedDateTime)}</Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        style={{ marginTop: 55 }}
      >
        <DialogTitle>
          <Typography variant="h5">{email.from.name}</Typography>
          <Typography variant="subtitle1">{email.subject}</Typography>
        </DialogTitle>
        <DialogContent>
          {email.body !== null ? (
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(email.body.content, {
                  ADD_ATTR: ['target'],
                }),
              }}
            />
          ) : (
            <Typography variant="body1">Check your email for more!</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => window.open(email.webLink, '_blank')}
            color="primary"
          >
            Open Mail
          </Button>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

function SenderImage({ email }) {
  const alphabet = 'abcdefghijklmnopqrstuvwxyz'
  const character = email.from.emailAddress.name.substring(0, 1)
  const colors = [
    '#63d8db',
    '#ecaaae',
    '#67c6f2',
    '#e3c297',
    '#a7b4f0',
    '#d5e5ae',
    '#e1b0dd',
    '#abe7bf',
    '#b4bfe7',
    '#a5c089',
    '#8bd0eb',
    '#8bc2ab',
    '#9de1da',
  ]
  const color =
    colors[Math.floor(alphabet.indexOf(character.toLowerCase()) / 2)]

  if (email.unRead) {
    return (
      <Avatar
        style={{
          backgroundColor: color,
          color: textColorBasedOnBackground(color),
          marginTop: 5,
        }}
      >
        {character}
      </Avatar>
    )
  }
  return (
    <Avatar
      style={{
        backgroundColor: color,
        color: textColorBasedOnBackground(color),
        marginTop: 5,
      }}
    >
      {email.from.emailAddress.name.substring(0, 1)}
    </Avatar>
  )
}

function getDate(date) {
  const now = new Date()
  const emailDate = new Date(date)
  if (now.getDate() === emailDate.getDate()) {
    return formatAMPM(date)
  }
  return `${emailDate.getMonth() + 1}/${emailDate.getDate() + 1}`
}

function CalendarTab({ data, classes }) {
  const { startDate } = useSelector((s) => s.ruConnect)
  const dispatch = useDispatch()
  const [date, changeDate] = useState(new Date())
  const [dayView, setDayView] = useState(false)
  const findDates = () => {
    const result = []
    data.value.forEach((element) => {
      result.push(new Date(element.start.dateTime).toDateString())
    })
    return result
  }
  const events = findDates()
  const eventIcon = <FiberManualRecordIcon className={classes.eventIconDot} />
  const [selectedDayEvents, setSelectedDayEvents] = useState([])

  React.useEffect(() => {
    setSelectedDayEvents(
      data.value.filter(
        (day) =>
          new Date(day.start.dateTime).toDateString() ===
          new Date(date).toDateString(),
      ),
    )
  }, [date])
  const handleMonthChange = (obj) => {
    if (new Date(obj).getTime() > new Date(startDate).getTime()) {
      dispatch(getNextMonth())
      dispatch(fetchCalendarEvents())
    } else {
      dispatch(getPrevMonth())
      dispatch(fetchCalendarEvents())
    }
  }
  return (
    <Grid
      container
      direction="column"
      className={classes.tabScroll}
      wrap="nowrap"
    >
      <Collapse in={!dayView} className={classes.collapse}>
        <Grid item className={classes.moveCalendar}>
          <DatePicker
            autoOk
            variant="static"
            openTo="date"
            value={date}
            onChange={changeDate}
            onMonthChange={handleMonthChange}
            renderDay={(day, dateParam, isInCurrentMonth, dayComponent) => {
              const eventDay = new Date(day)
              const isSelected =
                isInCurrentMonth && events.includes(eventDay.toDateString())

              return (
                <Badge badgeContent={isSelected ? eventIcon : undefined}>
                  {dayComponent}
                </Badge>
              )
            }}
          />
        </Grid>
      </Collapse>
      {!dayView && <Divider className={classes.calendarDivider} />}
      <Grid item className={dayView ? classes.dayView : classes.eventScroll}>
        <SelectedDayEventsCard
          date={date}
          selectedDayEvents={selectedDayEvents}
          classes={classes}
          dayView={dayView}
          setDayView={setDayView}
        />
      </Grid>
    </Grid>
  )
}

function SelectedDayEventsCard({
  date,
  selectedDayEvents,
  classes,
  dayView,
  setDayView,
}) {
  const displayDate = new Date(date)
  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const handleChange = () => {
    setDayView(!dayView)
  }
  return (
    <Card className={classes.eventsCard}>
      <Grid container direction="column" alignItems="center">
        <Grid
          item
          container
          direction="row"
          alignItems="flex-end"
          spacing={1}
          justifyContent="center"
          className={classes.eventHeader}
          onClick={handleChange}
        >
          <Grid item>
            <Typography variant="h2">{displayDate.getDate()}</Typography>
          </Grid>
          <Grid
            item
            container
            direction="column"
            className={classes.monthAndYear}
          >
            <Grid item>{month[displayDate.getMonth()]}</Grid>
            <Grid item>{displayDate.getFullYear()}</Grid>
          </Grid>
          <Grid
            item
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            className={classes.dayOverlayHolder}
          >
            <NotificationsIcon className={classes.bellIcon} />
            <Typography variant="subtitle1" className={classes.dayOverlay}>
              {selectedDayEvents.length}
            </Typography>
          </Grid>
        </Grid>
        <Divider variant="middle" className={classes.fullWidth} />
        <Timeline align="alternate">
          {selectedDayEvents.map((event, index) => (
            <TimelineItem key={event.start.dateTime}>
              <TimelineOppositeContent>
                {dayjs(event.start.dateTime)
                  .utc('z')
                  .local()
                  .tz('America/New_York')
                  .format('h:mm A')}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                {index !== selectedDayEvents.length - 1 && (
                  <TimelineConnector />
                )}
              </TimelineSeparator>
              <TimelineContent>
                <EventCard event={event} classes={classes} />
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      </Grid>
    </Card>
  )
}

function EventCard({ event, classes }) {
  const [isOpen, setIsOpen] = useState(false)
  const handleOpen = () => {
    setIsOpen(true)
  }
  const handleClose = () => {
    setIsOpen(false)
  }
  return (
    <Box>
      <Card className={classes.eventCard} onClick={handleOpen}>
        <Typography variant="subtitle1">{event.subject}</Typography>
      </Card>
      <Dialog open={isOpen} onClose={handleClose} fullWidth>
        <DialogTitle>
          <Typography variant="h5">{event.subject}</Typography>
        </DialogTitle>
        <DialogContent>
          {event.body.content ? (
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(event.body.content, {
                  ADD_ATTR: ['target'],
                }),
              }}
            />
          ) : (
            <Typography variant="body1">No details...</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => window.open(event.webLink, '_blank')}
            color="primary"
          >
            Open Event
          </Button>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

function QuickLinksTab({ classes }) {
  return (
    <Grid container direction="column">
      <Widget.Spacer />
      <Widget.Spacer />
      <Grid container direction="row" justifyContent="space-evenly">
        <Grid item style={{ marginBottom: 20 }}>
          <Widget.TabLink
            hyperLink="https://outlook.office.com/mail"
            icon={<EmailIcon className={classes.icon} />}
            iconLabel="Email"
          />
        </Grid>
        <Grid item>
          <Widget.TabLink
            hyperLink="https://outlook.office.com/calendar/view/week"
            icon={<CalendarTodayIcon className={classes.icon} />}
            iconLabel="Calendar"
          />
        </Grid>
        <Grid item>
          <Widget.TabLink
            hyperLink="https://rutgersconnect-my.sharepoint.com/_layouts/15/MySite.aspx?MySiteRedirect=AllDocuments"
            icon={<CloudIcon className={classes.icon} />}
            iconLabel="Drive"
          />
        </Grid>
        <Grid item>
          <Widget.TabLink
            hyperLink="https://teams.microsoft.com/_#/?lm=deeplink&lmsrc=officeWaffle"
            icon={
              <IconPicker iconName={['fas', 'user']} color="gray" size="3x" />
            }
            iconLabel="Teams"
          />
        </Grid>
        <Grid item>
          <Widget.TabLink
            hyperLink="https://outlook.office.com/people"
            icon={
              <IconPicker iconName={['fas', 'users']} color="gray" size="3x" />
            }
            iconLabel="People"
          />
        </Grid>
        <Grid item>
          <Widget.TabLink
            hyperLink="https://www.office.com/launch/word"
            icon={
              <IconPicker
                iconName={['far', 'file-word']}
                color="gray"
                size="3x"
              />
            }
            iconLabel="Word"
          />
        </Grid>
        <Grid item>
          <Widget.TabLink
            hyperLink="https://www.office.com/launch/excel?auth=2"
            icon={
              <IconPicker
                iconName={['far', 'file-excel']}
                color="gray"
                size="3x"
              />
            }
            iconLabel="Excel"
          />
        </Grid>
        <Grid item>
          <Widget.TabLink
            hyperLink="https://www.office.com/launch/powerpoint?auth=2"
            icon={
              <IconPicker
                iconName={['far', 'file-powerpoint']}
                color="gray"
                size="3x"
              />
            }
            iconLabel="PowerPoint"
          />
        </Grid>
        <Grid item>
          <Widget.TabLink
            hyperLink="https://www.office.com/launch/onenote?auth=2"
            icon={
              <IconPicker
                iconName={['far', 'sticky-note']}
                color="gray"
                size="3x"
              />
            }
            iconLabel="OneNote"
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

function formatAMPM(input) {
  const date = new Date(input)
  let hours = date.getHours()
  let minutes = date.getMinutes()
  const ampm = hours >= 12 ? 'pm' : 'am'
  hours %= 12
  hours = hours || 12
  minutes = minutes < 10 ? `0${minutes}` : minutes
  const strTime = `${hours}:${minutes}${ampm}`
  return strTime
}

export default MyEmailRUConnect
