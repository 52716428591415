import React from 'react'
import { Typography } from '@material-ui/core'
import MultiSelect from './MultiSelect/MultiSelect'

function WidgetFormMultiSelect({
  classes,
  title,
  field,
  isMulti = false,
  handleFormChange,
  focusedWidget,
}) {
  return (
    <>
      <Typography className={classes.formTitle}>{title} </Typography>
      <MultiSelect
        k={field}
        isMulti={isMulti}
        focusedWidget={focusedWidget}
        handleFormChange={handleFormChange}
      />
    </>
  )
}

export default WidgetFormMultiSelect
