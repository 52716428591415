/* eslint-disable import/no-named-as-default-member */
import * as React from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import ErrorIcon from '@material-ui/icons/Error'
import { ButtonBase, Typography as Text } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Widget from '../Widget'
import {
  TabPanel,
  a11yProps,
} from '../../DashboardComponents/TabPanel/TabPanel'
import campusPassStyles from './campusPassStyles'
import {
  fetchCampusPass,
  //   fetchVaccineStatus,
  selectCampusPassTabs,
  selectCampusPassStatus,
  updateTabValue,
} from './campusPassSlice'
import VaccineStatus from './VaccineStatus'
// import TestingStatus from './TestingStatus'
import Footer from '../../DashboardComponents/Footer/Footer'
import IconPicker from '../../../apps/AppComponents/IconPicker/IconPicker'
import Loading from '../helpers/Loading'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'
import {
  RutgersTab,
  RutgersTabs,
} from '../../DashboardComponents/Tabs/RutgersTabs'

// eslint-disable-next-line no-unused-vars
function CampusPass({ className, title, widgetId }) {
  const dispatch = useDispatch()
  const classes = campusPassStyles()
  const widgetTabs = useSelector(selectCampusPassTabs)
  const widgetCallState = useSelector(selectCampusPassStatus)
  React.useEffect(() => {
    dispatch(fetchCampusPass())
    // dispatch(fetchVaccineStatus())
  }, [])
  return (
    <Widget className={className}>
      <Widget.Header title={title} category="default" />
      <Widget.Content className={classes.content}>
        {renderCampusPassState(widgetCallState, widgetTabs)}
      </Widget.Content>
    </Widget>
  )
}

function renderCampusPassState(widgetCallState, apiReturnedContent) {
  const classes = campusPassStyles()
  // TODO: Handle Proper Error States
  switch (widgetCallState) {
    case 'fulfilled':
      return <Loaded apiReturnedContent={apiReturnedContent} />
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage />
        </Grid>
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item className={classes.text}></Grid>
        </Grid>
      )
  }
}

function Loaded(props) {
  const classes = campusPassStyles()
  const dispatch = useDispatch()
  const {
    value,
    vaccineData,
    showUpdateSurvey,
    result,
    convertedPreviousDate,
    isCleared,
    locationsString,
  } = useSelector((s) => s.campusPass)
  const handleLink = (link) => {
    window.open(link, '_blank')
  }
  const handleChange = (event, newValue) => {
    dispatch(updateTabValue(newValue))
  }
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classes.mainGrid}
    >
      <Box className={classes.modalTabs}>
        <RutgersTabs value={value} onChange={handleChange} aria-label="Top Tab">
          <RutgersTab label="Self-Status" {...a11yProps(0)} />
          <RutgersTab
            label={
              <Box display="flex">
                <Text className={classes.tabTextLabel}>Vaccine</Text>
                {vaccineData.vaccine &&
                  vaccineData.vaccine.doses.length <= 0 && <ErrorIcon />}
              </Box>
            }
            {...a11yProps(2)}
          />
        </RutgersTabs>
      </Box>
      <TabPanel value={value} index={0} className={classes.tabPanel1}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          {/* when survey results need to be renewed */}
          {result && !result.status && showUpdateSurvey === true && (
            <Grid item className={classes.previousSurveyContainer}>
              <Text
                className={classes.previousSurveyText}
                variant="body1"
                align="center"
              >
                Please renew your Campus Pass.
              </Text>
              <Box className={classes.previousSurveyResultsContainer}>
                <Text
                  className={classes.previousSurveyResultsText}
                  variant="body1"
                  align="center"
                >
                  Based on your last submitted survey from{' '}
                  {convertedPreviousDate} you were{' '}
                  {isCleared === true ? (
                    <span className={classes.surveyCleared}>cleared</span>
                  ) : (
                    <span className={classes.surveyNotCleared}>
                      not cleared
                    </span>
                  )}{' '}
                  to enter:
                </Text>
              </Box>
              <Box className={classes.surveyLocationsContainer}>
                <Text
                  className={classes.surveyLocations}
                  variant="h5"
                  align="center"
                >
                  {locationsString}
                </Text>
              </Box>
              <Box className={classes.renewButtonContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.renewCampusPassButton}
                  onClick={() => {
                    handleLink(
                      `${process.env.REACT_APP_CAMPUS_PASS_RENEW_LINK}/rtr/?id=health_screen_survey&language=en`,
                    )
                  }}
                >
                  <Text className={classes.renewButtonText} variant="h5">
                    Renew My Campus Pass
                    <br />
                    <span className={classes.renewButtonSpan}>
                      Submit a new self-screen survey
                    </span>
                  </Text>
                </Button>
              </Box>
              <Box className={classes.renewSurveyButtonContainer}>
                <ButtonBase
                  component="a"
                  className={classes.centerIcon}
                  href={`${process.env.REACT_APP_CAMPUS_PASS_RENEW_LINK}/rtr?id=health_screen_dashboard`}
                  target="_blank"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignContent="center"
                  >
                    <Box className={classes.renewIcon}>
                      <IconPicker iconName="file-text" color="gray" size="5x" />
                    </Box>
                    <Text variant="body1">View my survey history</Text>
                  </Box>
                </ButtonBase>
              </Box>
            </Grid>
          )}
          {/* when survey results are current */}
          {result && !result.status && showUpdateSurvey === false && (
            <Box className={classes.previousSurveyContainer}>
              <Box className={classes.surveyResultsIconContainer}>
                {isCleared === true ? (
                  <IconPicker
                    color="#21ba45"
                    iconName="check-circle"
                    size="10x"
                  />
                ) : (
                  <IconPicker
                    color="#cc0033"
                    iconName="times-circle"
                    size="10x"
                  />
                )}
              </Box>
              <Box>
                <Text className={classes.surveyResultsHeader} variant="h4">
                  You are{' '}
                  {isCleared === true ? (
                    <span className={classes.surveyCleared}>Cleared</span>
                  ) : (
                    <span className={classes.surveyNotCleared}>
                      Not Cleared
                    </span>
                  )}{' '}
                  for entry
                </Text>
                {result.significant_exposure === 'Yes' && (
                  <Text className={classes.significantExposureText}>
                    Due to significate exposure.
                  </Text>
                )}
                <Text className={classes.submittedSurveyText}>
                  Based on your submitted
                </Text>
                <Text className={classes.surveyTimestampText}>
                  Survey from {convertedPreviousDate}
                </Text>
                <Text
                  component="a"
                  href="https://coronavirus.rutgers.edu/symptom-checker/"
                  target="_blank"
                  className={classes.furtherInstructionsLink}
                >
                  Click here for further instructions
                </Text>
                <Box display="flex" justifyContent="space-between">
                  <ButtonBase
                    component="a"
                    href={`${process.env.REACT_APP_CAMPUS_PASS_RENEW_LINK}/rtr?id=health_screen_survey&language=en`}
                    target="_blank"
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignContent="center"
                    >
                      <Box className={classes.renewIcon}>
                        <IconPicker
                          iconName="icon-keyboard-regular"
                          color="gray"
                          size="5x"
                        />
                      </Box>
                      <Text variant="body1">Update my survey</Text>
                    </Box>
                  </ButtonBase>
                  <ButtonBase
                    component="a"
                    href={`${process.env.REACT_APP_CAMPUS_PASS_RENEW_LINK}/rtr?id=health_screen_dashboard`}
                    target="_blank"
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignContent="center"
                    >
                      <Box className={classes.renewIcon}>
                        <IconPicker
                          iconName="file-text"
                          color="gray"
                          size="5x"
                        />
                      </Box>
                      <Text variant="body1">View my survey history</Text>
                    </Box>
                  </ButtonBase>
                </Box>
              </Box>
            </Box>
          )}
          {/* when survey Results don't exist */}
          {!result && (
            // Dont do this, this is temporary and will be fixed with handling second tab
            <>
              {/*  eslint-disable-next-line react/no-array-index-key */}
              <Grid item className={classes.text}>
                <Text variant="subtitle1">
                  Any student, staff, or faculty who will be visiting Rutgers is
                  expected to get a Campus Pass by completing the following
                  self-screening survey.
                </Text>
                <Text variant="subtitle1">
                  Your Campus Pass is valid for the day.
                </Text>
              </Grid>
              <img
                src="./assets/images/rutgers_campus_pass.png"
                alt="campus pass icon"
              />
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.campusPassLink}
                  onClick={() => {
                    handleLink(
                      `${process.env.REACT_APP_CAMPUS_PASS_RENEW_LINK}/rtr?id=health_screen_dashboard`,
                    )
                  }}
                >
                  <Grid container direction="column" style={{ padding: 4 }}>
                    <Grid item>
                      <Text variant="h5">Get My Campus Pass</Text>
                    </Grid>

                    <Grid item>
                      <Text variant="caption">
                        Submit a new self-screen survey
                      </Text>
                    </Grid>
                  </Grid>
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </TabPanel>
      {/* <TabPanel value={value} index={1} className={classes.tabPanel1}>
        <TestingStatus />
      </TabPanel> */}
      <TabPanel value={value} index={1} className={classes.tabPanel1}>
        <VaccineStatus />
      </TabPanel>
      <Grid item className={classes.footer}>
        <Footer>
          <Button
            onClick={() => {
              handleLink(props.apiReturnedContent[0].additionalLink)
            }}
          >
            <Text variant="subtitle1">Additional Information</Text>
          </Button>
        </Footer>
      </Grid>
    </Grid>
  )
}

export default CampusPass
