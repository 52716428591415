import React from 'react'
import { useDroppable } from '@dnd-kit/core'
import { SortableContext } from '@dnd-kit/sortable'

import SortableItem from './SortableItem'

const containerStyle = {
  padding: 20,
  margin: 0,
  flex: 1,
  height: '100%',
  touchAction: 'manipulation',
  overflowX: 'hidden',
  overflowY: 'auto',
}

export default function Container(props) {
  const { id, newsSections, activeId } = props

  const { setNodeRef } = useDroppable({
    id,
  })

  return (
    <SortableContext id={id} items={newsSections}>
      <div ref={setNodeRef} style={containerStyle}>
        {!props.hideItems &&
          newsSections &&
          newsSections.map((source) =>
            source.articles.length > 0 && source.tag !== 'rest' ? (
              <SortableItem
                key={source.id}
                id={source.id}
                source={source}
                activeId={activeId}
              />
            ) : null,
          )}
      </div>
    </SortableContext>
  )
}
