export const courseScheduleTutorial = [
  {
    title: 'Courses Widget Tutorial',
    description:
      'The Course Schedule Widget will provide all important class schedule information at a quick glance.',
    top: {
      height: '100%',
      width: '100%',
      left: '0',
    },
    right: {
      height: '0',
      width: '0',
    },
    bottom: {
      height: '0',
      width: '0',
    },
    left: {
      height: '0',
      width: '0',
    },
    popover: {
      top: '200px',
      left: '30px',
    },
  },
  {
    title: 'Individual Course',
    description: `Each course that you're currently enrolled in will include the course name, course number, credit, instructor, time, location and campus information.`,
    top: {
      height: '0',
      width: '0',
      left: '0',
    },
    right: {
      height: '0',
      width: '0',
    },
    bottom: {
      height: '280px',
      width: '100%',
    },
    left: {
      height: '0',
      width: '0',
    },
    popover: {
      top: '220px',
      left: '30px',
    },
  },
  {
    title: 'Final Exam',
    description: `A quick link to view the status of your final exam.`,
    top: {
      height: '162px',
      width: '35px',
      left: '105px',
    },
    right: {
      height: '190px',
      width: '195px',
    },
    bottom: {
      height: '285px',
      width: '100%',
    },
    left: {
      height: '190px',
      width: '105px',
    },
    popover: {
      top: '220px',
      left: '30px',
    },
  },
  {
    title: 'Bus Information',
    description: `Clicking on the Bus Icon will provide you will the relevant bus stop locations, with real time location and capacity.`,
    top: {
      height: '162px',
      width: '35px',
      left: '150px',
    },
    right: {
      height: '190px',
      width: '150px',
    },
    bottom: {
      height: '285px',
      width: '100%',
    },
    left: {
      height: '190px',
      width: '150px',
    },
    popover: {
      top: '220px',
      left: '30px',
    },
  },
  {
    title: 'Map Indicator',
    description: `Click the map icon to launch the Rutgers University map.`,
    top: {
      height: '162px',
      width: '35px',
      left: '195px',
    },
    right: {
      height: '190px',
      width: '105px',
    },
    bottom: {
      height: '285px',
      width: '100%',
    },
    left: {
      height: '190px',
      width: '195px',
    },
    popover: {
      top: '220px',
      left: '30px',
    },
  },
  {
    title: 'Student Parking',
    description: `The car icon will provide you with the nearest parking lot that a student can park.`,
    top: {
      height: '162px',
      width: '35px',
      left: '240px',
    },
    right: {
      height: '190px',
      width: '60px',
    },
    bottom: {
      height: '285px',
      width: '100%',
    },
    left: {
      height: '190px',
      width: '240px',
    },
    popover: {
      top: '220px',
      left: '30px',
    },
  },
]

export const courseScheduleTutorialTwoUserRoles = [
  {
    title: 'Courses Widget Tutorial',
    description:
      'The Course Schedule Widget will provide all important class schedule information at a quick glance.',
    top: {
      height: '100%',
      width: '100%',
      left: '0',
    },
    right: {
      height: '0',
      width: '0',
    },
    bottom: {
      height: '0',
      width: '0',
    },
    left: {
      height: '0',
      width: '0',
    },
    popover: {
      top: '200px',
      left: '30px',
    },
  },
  {
    title: 'Individual Course',
    description: `Each course that you're currently enrolled in will include the course name, course number, credit, instructor, time, location and campus information.`,
    top: {
      height: '0',
      width: '0',
      left: '0',
    },
    right: {
      height: '0',
      width: '0',
    },
    bottom: {
      height: '260px',
      width: '100%',
    },
    left: {
      height: '0',
      width: '0',
    },
    popover: {
      top: '220px',
      left: '30px',
    },
  },
  {
    title: 'Final Exam',
    description: `A quick link to view the status of your final exam.`,
    top: {
      height: '162px',
      width: '35px',
      left: '105px',
    },
    right: {
      height: '190px',
      width: '195px',
    },
    bottom: {
      height: '250px',
      width: '100%',
    },
    left: {
      height: '190px',
      width: '105px',
    },
    popover: {
      top: '220px',
      left: '30px',
    },
  },
  {
    title: 'Bus Information',
    description: `Clicking on the Bus Icon will provide you will the relevant bus stop locations, with real time location and capacity.`,
    top: {
      height: '162px',
      width: '35px',
      left: '150px',
    },
    right: {
      height: '190px',
      width: '150px',
    },
    bottom: {
      height: '250px',
      width: '100%',
    },
    left: {
      height: '190px',
      width: '150px',
    },
    popover: {
      top: '220px',
      left: '30px',
    },
  },
  {
    title: 'Map Indicator',
    description: `Click the map icon to launch the Rutgers University map.`,
    top: {
      height: '162px',
      width: '35px',
      left: '195px',
    },
    right: {
      height: '190px',
      width: '105px',
    },
    bottom: {
      height: '250px',
      width: '100%',
    },
    left: {
      height: '190px',
      width: '195px',
    },
    popover: {
      top: '220px',
      left: '30px',
    },
  },
  {
    title: 'Student Parking',
    description: `The car icon will provide you with the nearest parking lot that a student can park.`,
    top: {
      height: '162px',
      width: '35px',
      left: '240px',
    },
    right: {
      height: '190px',
      width: '60px',
    },
    bottom: {
      height: '250px',
      width: '100%',
    },
    left: {
      height: '190px',
      width: '240px',
    },
    popover: {
      top: '220px',
      left: '30px',
    },
  },
]

export const courseScheduleTutorialForRU = [
  {
    title: 'Courses Widget Tutorial - RU',
    description:
      'The Course Schedule Widget will provide all important class schedule information at a quick glance.',
    top: {
      height: '100%',
      width: '100%',
      left: '0',
    },
    right: {
      height: '0',
      width: '0',
    },
    bottom: {
      height: '0',
      width: '0',
    },
    left: {
      height: '0',
      width: '0',
    },
    popover: {
      top: '200px',
      left: '30px',
    },
  },
  {
    title: 'Individual Course',
    description: `Each course that you're currently enrolled in will include the course name, course number, credit, instructor, time, location and campus information.`,
    top: {
      height: '0',
      width: '0',
      left: '0',
    },
    right: {
      height: '0',
      width: '0',
    },
    bottom: {
      height: '240px',
      width: '100%',
    },
    left: {
      height: '0',
      width: '0',
    },
    popover: {
      top: '240px',
      left: '30px',
    },
  },
  {
    title: 'Final Exam',
    description: `A quick link to view the status of your final exam.`,
    top: {
      height: '197px',
      width: '35px',
      left: '105px',
    },
    right: {
      height: '225px',
      width: '195px',
    },
    bottom: {
      height: '250px',
      width: '100%',
    },
    left: {
      height: '225px',
      width: '105px',
    },
    popover: {
      top: '240px',
      left: '30px',
    },
  },
  {
    title: 'Bus Information',
    description: `Clicking on the Bus Icon will provide you will the relevant bus stop locations, with real time location and capacity.`,
    top: {
      height: '197px',
      width: '35px',
      left: '150px',
    },
    right: {
      height: '225px',
      width: '150px',
    },
    bottom: {
      height: '250px',
      width: '100%',
    },
    left: {
      height: '225px',
      width: '150px',
    },
    popover: {
      top: '240px',
      left: '30px',
    },
  },
  {
    title: 'Map Indicator',
    description: `Click the map icon to launch the Rutgers University map.`,
    top: {
      height: '197px',
      width: '35px',
      left: '195px',
    },
    right: {
      height: '225px',
      width: '105px',
    },
    bottom: {
      height: '250px',
      width: '100%',
    },
    left: {
      height: '225px',
      width: '195px',
    },
    popover: {
      top: '240px',
      left: '30px',
    },
  },
  {
    title: 'Student Parking',
    description: `The car icon will provide you with the nearest parking lot that a student can park.`,
    top: {
      height: '197px',
      width: '35px',
      left: '240px',
    },
    right: {
      height: '225px',
      width: '60px',
    },
    bottom: {
      height: '250px',
      width: '100%',
    },
    left: {
      height: '225px',
      width: '240px',
    },
    popover: {
      top: '240px',
      left: '30px',
    },
  },
]
