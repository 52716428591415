/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  drawerIsOpen: false,
  reorderWidgetDrawer: false,
  addWidgetIsOpen: false,
  alertsIsOpen: false,
  communityDialogIsOpen: false,
}

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    drawerOpen: (state) => ({
      ...state,
      drawerIsOpen: true,
    }),
    drawerClose: (state) => ({
      ...state,
      drawerIsOpen: false,
    }),
    drawerToggle: (state) => ({
      ...state,
      drawerIsOpen: !state.drawerIsOpen,
    }),
    openReorderWidgetDrawer: (state) => ({
      ...state,
      drawerIsOpen: true,
      reorderWidgetDrawer: true,
    }),
    closeReorderWidgetDrawer: (state) => ({
      ...state,
      drawerIsOpen: false,
      reorderWidgetDrawer: false,
    }),

    toggleReorderWidgetDrawer: (state, action) => ({
      ...state,
      reorderWidgetDrawer: action.payload,
    }),
    addWidgetOpen: (state) => ({
      ...state,
      addWidgetIsOpen: true,
    }),
    addWidgetClose: (state) => ({
      ...state,
      addWidgetIsOpen: false,
    }),
    addWidgetToggle: (state) => ({
      ...state,
      addWidgetIsOpen: !state.addWidgetIsOpen,
    }),
    alertsOpen: (state) => ({
      ...state,
      alertsIsOpen: true,
    }),
    alertsClose: (state) => ({
      ...state,
      alertsIsOpen: false,
    }),
    communityDialogOpen: (state) => ({
      ...state,
      communityDialogIsOpen: true,
    }),
    communityDialogClose: (state) => ({
      ...state,
      communityDialogIsOpen: false,
    }),
  },
})

export const {
  drawerOpen,
  drawerClose,
  drawerToggle,
  addWidgetClose,
  addWidgetOpen,
  addWidgetToggle,
  alertsOpen,
  alertsClose,
  openReorderWidgetDrawer,
  closeReorderWidgetDrawer,
  toggleReorderWidgetDrawer,
} = navigationSlice.actions
export const selectAddWidgetIsOpen = (state) => state.navigation.addWidgetIsOpen
export const selectReorderWidgetIsOpen = (state) =>
  state.navigation.reorderWidgetDrawer
export const selectDrawerIsOpen = (state) => state.navigation.drawerIsOpen
export const selectAlertsIsOpen = (state) => state.navigation.alertsIsOpen

export default navigationSlice.reducer
