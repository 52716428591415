import React from 'react'
import GenericArticle from '../../GenericArticle/GenericArticle'
import { PLACEHOLDER_ARTICLES } from '../constants'

// Essentially just a list of articles
export default function ArticleLayoutWithoutImage({
  articles,
  isLoading = false,
}) {
  const PLACE_HOLDER_MULTIPLIER = 3
  return (
    <>
      {(isLoading
        ? [...Array(PLACE_HOLDER_MULTIPLIER)].flatMap(
            () => PLACEHOLDER_ARTICLES,
          )
        : articles.filter((article) => !article.published.includes('-'))
      ).map((newsArticle, index) => (
        <GenericArticle
          key={isLoading ? `loading-${index}` : newsArticle.id}
          article={newsArticle}
          isLoading={isLoading}
        />
      ))}
    </>
  )
}
