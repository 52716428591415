import * as React from 'react'
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import Visibility from '@material-ui/icons/Visibility'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectShowSensitiveInfo,
  toggleShowSensitiveInfo,
} from '../../../features/settings/settingsSlice'

function ShowSensitiveInfoSideNav({ classes }) {
  const showSensitiveInfo = useSelector(selectShowSensitiveInfo)
  const dispatch = useDispatch()
  const changeStatus = () => {
    dispatch(toggleShowSensitiveInfo())
  }
  return (
    <ListItem
      button
      className={classes.sideNavButton}
      onClick={changeStatus}
      focusRipple
    >
      <ListItemIcon>
        {showSensitiveInfo ? (
          <Visibility className={classes.listIconStyle} />
        ) : (
          <VisibilityOff className={classes.listIconStyle} />
        )}
      </ListItemIcon>
      <ListItemText>Show Sensitive Info</ListItemText>
    </ListItem>
  )
}

export default ShowSensitiveInfoSideNav
