import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) => ({
  container: {
    padding: 0,
    margin: 0,
    height: 470,
    width: 335,
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme.breakpoints.down(370)]: {
      width: 287,
      height: 450,
    },
  },
  modalTab: {
    width: '335px',
    height: '35px',
    minHeight: '35px',
    [theme.breakpoints.down(370)]: {
      width: '300px',
    },
  },
  tabBackground: {
    overflowY: 'hidden',
    width: '100%',
    minHeight: '35px',
    height: '35px',
    // backgroundColor: theme.palette.background.widgetSecondaryBackground,
  },
  tabText: {
    minHeight: 35,
    height: 35,
    minWidth: 30,
    padding: '0 2px 0 2px',
    width: '100%',
    fontSize: 13,
    fontWeight: 600,
    color: theme.palette.base.red,
    backgroundColor: theme.palette.backgroundColor.secondary,
  },
  tabTitle: {
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  icon: {
    fontSize: '30px',
    opacity: 0.4,
  },
  panel: {
    height: '435px',
    overflowX: 'clip',
    overflowY: 'clip',
  },
  titlePaper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: '5px',
    color: theme.palette.base.white,
    backgroundColor: theme.palette.base.red,
  },
  alertPaper: {
    width: 'calc(100% - 10px)',
    padding: '5px',
    display: 'flex',
    marginLeft: '5px',
    justifyContent: 'center',
    borderColor: theme.palette.base.red,
    color: theme.palette.base.red,
    borderWidth: '2px',
  },
  titleContainer: {
    marginBottom: '-5px',
  },
  headerStyle: {
    backgroundColor: '#0f61e6',
  },
  bodyStyle: {
    backgroundColor:
      theme.palette.type === 'light' ? '#e9f5f9' : theme.palette.base.gray,
    paddingLeft: '2px',
    paddingRight: '2px',
  },
  headerText: {
    color: theme.palette.base.white,
    fontWeight: 600,
    textTransform: 'capitalize',
  },
  todayTextContainer: {
    color: theme.palette.base.black,
    textTransform: 'capitalize',
    fontWeight: 600,
  },
  todayText: {
    display: 'inline',
    borderRadius: 3,
    backgroundColor: '#FFFFFF',
    textTransform: 'capitalize',
    padding: '2px 4px',
  },
  tableContainer: {
    width: 'calc(100% - 10px)',
    marginLeft: '5px',
  },
  tablePaper: {
    width: '100%',
    padding: '0px',
    borderRadius: 0,
    backgroundColor: '#0f61e6',
  },
  tinyText: {
    fontSize: '10px',
    fontWeight: 500,
    color:
      theme.palette.type === 'light'
        ? theme.palette.base.black
        : theme.palette.base.white,
  },
  breakText: {
    fontSize: '10px',
    fontWeight: 500,
    width: '100%',
    color: theme.palette.base.darkGray,
    backgroundColor: theme.palette.base.yellow,
  },
  remoteStyle: {
    fontSize: '30px',
    opacity: 0.6,
    color:
      theme.palette.type === 'light'
        ? theme.palette.base.gray
        : theme.palette.base.darkGray,
  },
  inPersonStyle: {
    fontSize: '30px',
    opacity: 0.6,
    color: theme.palette.base.red,
  },
  fullWidth: {
    width: '100%',
  },
  calendarGrid: {
    width: '100%',
    flexGrow: 1,
  },
  selectionText: {
    color: theme.palette.base.gray,
    padding: '0px 5px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  activeSelectionText: {
    color: theme.palette.base.blue,
    padding: '0px 5px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  noFWA: {
    padding: '10px',
  },
  redText: {
    color: theme.palette.base.red,
  },
  legendStyle: {
    color:
      theme.palette.type === 'light'
        ? theme.palette.base.gray
        : theme.palette.base.white,
    marginTop: '5px',
  },
  weekSelector: {
    marginBottom: '-10px',
  },
  fullHeight: {
    height: '100%',
  },
  statusNotifier: {
    marginTop: '3px',
    color:
      theme.palette.type === 'light'
        ? theme.palette.base.gray
        : theme.palette.base.white,
  },
}))

export default styles
