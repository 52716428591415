import { makeStyles } from '@material-ui/core/styles'

export const useViewArticlePageStyles = makeStyles((theme) => ({
  articleContainer: {
    paddingBottom: 30,
  },
  appBar: {
    background: theme.palette.background.paper,
    color: 'rgba(0,0,0,0.54)',
  },
  appBarContainer: {
    flex: 1,
    width: '100%',
    backgroundColor: theme.palette.background.default,
    overscrollBehavior: 'contain',
    justifyContent: 'center',
  },
  pageTitleContainer: {
    width: '86%',
  },
  pageTitle: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 1,
    wordBreak: 'keep-all',
    overflow: 'hidden',
    color: theme.palette.base.red,
    fontSize: 22,
    fontWeight: '400',
    textOverflow: 'ellipsis',
  },
  mobileBackButtonIcon: {
    color: theme.palette.base.red,
    fontSize: 24,
  },
  container: {
    margin: '90px 70px 10px 120px',
    maxWidth: '1100px',
    [theme.breakpoints.down('sm')]: {
      margin: '10px 0px 0px 0px',
      padding: '10px 20px 0 20px',
    },
  },
  image: {
    maxHeight: '500px',
    width: '100%',
    objectFit: 'cover',
    objectPosition: '50% 20%',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '200px',
    },
    borderRadius: '3px',
  },
  titleBox: {
    marginBottom: '5px',
    fontWeight: 700,
    fontSize: '32px',
    textAlign: 'left',
    letterSpacing: '1px',
    color: theme.palette.primary.headerText,
  },
  titleBoxMobile: {
    marginBottom: '5px',
    fontWeight: 700,
    fontSize: '24px',
    textAlign: 'left',
    letterSpacing: '1px',
    color: theme.palette.primary.headerText,
  },
  articleDetailsContainer: {
    marginBottom: '8px',
  },
  author: {
    color: theme.palette.secondary.headerText,
    fontSize: '15px',
    [theme.breakpoints.down('md')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
    fontWeight: 500,
  },
  publishedDate: {
    color: theme.palette.secondary.headerText,
    fontSize: '15px',
    [theme.breakpoints.down('md')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
    fontWeight: 500,
  },
  articleSourceText: {
    fontSize: '18px',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
    color: theme.color.rutgersRed,
    fontWeight: 600,
    letterSpacing: 1,
  },
  learnMoreButton: {
    backgroundColor: theme.palette.base.red,
    height: '50px',
    borderRadius: '7px',
    fontSize: '18px',
    color: 'white',
    width: '100%',
    marginBottom: '50px',
    marginTop: '10px',
    '&:hover': {
      backgroundColor: '#B00020',
    },
    letterSpacing: '2px',
  },
  divider: {
    margin: '10px 0',
    backgroundColor: theme.palette.primary.main,
  },
  description: {
    color: theme.palette.primary.headerText,
    textAlign: 'left',
    fontSize: '15px',
    marginTop: '8px',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
  },
}))

export default useViewArticlePageStyles
