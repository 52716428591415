import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import styles from './LinkListStyles'
import IconPicker from '../../../apps/AppComponents/IconPicker/IconPicker'
import { logDashboardEvent } from '../../events'

function LinkList({ links, widgetId, dispatch }) {
  const classes = styles()
  const handleLinkClick = (link) => {
    if (widgetId && dispatch) {
      logDashboardEvent(
        dispatch,
        'dashboardLinkEvent',
        widgetId,
        link.iconLabel,
        link.link,
      )
    }
    window.open(link.link, '_blank')
  }
  return (
    <Grid container direction="column" spacing={1}>
      {links.map((link) => (
        <Grid
          key={link.link + link.iconLabel}
          item
          container
          direction="row"
          alignItems="center"
          onClick={() => handleLinkClick(link)}
          className={classes.clickable}
        >
          <Grid item align="center" className={classes.smallIcon}>
            <IconPicker iconName={link.icon} color="gray" size="2x" />
          </Grid>
          <Grid item xs>
            <Typography>{link.iconLabel}</Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}

export default LinkList
