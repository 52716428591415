/* eslint-disable camelcase */
import React from 'react'
import {
  GoogleMap,
  StreetViewPanorama,
  useJsApiLoader,
} from '@react-google-maps/api'
import uuid from 'react-uuid'
import { useSelector, useDispatch } from 'react-redux'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import { useHistory } from 'react-router-dom'

import { buildingStreetViewStyles } from './buildingStreetViewStyles'
import {
  setSelectedStop,
  handleStopClicked,
  fetchRoutes,
  fetchStops,
  updateMapTabValue,
  fetchVehiclePositions,
  setSelectedRoute,
  fetchShapesAsGeoJSON,
  fetchTripUpdatesCall,
} from '../../../Map/redux/myMapsSlice'
import CheckIsMobile from '../../../../../../../utils/isMobileHook'
import { useRouteItemStyles } from '../../../Vehicles/components/RouteItem/RouteItemStyles'
import { getEnhancedStops } from '../../../Map/helpers/helpers'
import { logMapEvent } from '../../../../utils/events'
import { ROUTEMARKER } from '../../../../utils/constants'
import { textColorBasedOnBackground } from '../../../../../../../utils/utils'

function BuildingStreetView({ nearestStops }) {
  const dispatch = useDispatch()
  const classes = buildingStreetViewStyles()
  const isMobile = CheckIsMobile()
  const history = useHistory()
  const [lat, updateLat] = React.useState(0)
  const [lng, updateLng] = React.useState(0)
  const [currentStop, setCurrentStop] = React.useState({})
  const { isLoaded } = useJsApiLoader({
    id: 'rutgers-my-map',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  })
  const { nearestStopsTabValue, stops, selectedStop } = useSelector(
    (s) => s.maps,
  )
  React.useEffect(() => {
    if (
      nearestStops &&
      nearestStops.stopList &&
      nearestStops.stopList.length > 0
    ) {
      const stopCorArray =
        nearestStops.stopList[nearestStopsTabValue].stopCor.split(',')
      updateLat(parseFloat(stopCorArray[0]))
      updateLng(parseFloat(stopCorArray[1]))
      const stopID = nearestStops.stopList[nearestStopsTabValue].stopId
      const stop = stops.filter((s) => s.stop_id === stopID)[0]
      if (stop) {
        setCurrentStop(stop)
        dispatch(setSelectedStop(stop))
        dispatch(fetchTripUpdatesCall())
      }
    }
  }, [])

  const handleStopInfo = () => {
    dispatch(fetchRoutes())
    dispatch(fetchStops())
    dispatch(handleStopClicked(currentStop))
    if (isMobile) {
      const BUSTAB = 0
      dispatch(updateMapTabValue(BUSTAB))
      handleMobile()
    }
  }

  const updateRouteColors = (route) => {
    // Create a new object to avoid modifying the original
    const updatedRoute = { ...route }

    // Utility function to prepend '#' if missing in the color code
    const prependHashToColor = (color) =>
      color.startsWith('#') ? color : `#${color}`

    // Update the color codes
    if (updatedRoute.routeColor) {
      updatedRoute.routeColor = prependHashToColor(route.routeColor)
    }

    if (updatedRoute.routeTextColor) {
      updatedRoute.routeTextColor = prependHashToColor(route.routeTextColor)
    }

    return updatedRoute
  }

  const handleMobile = () => {
    history.push({
      pathname: '/maps',
    })
  }
  return (
    <Box display="flex" flexDirection="column" alignItems="center" marginY={1}>
      {isLoaded &&
      nearestStops &&
      nearestStops.stopList &&
      nearestStops.stopList.length > 0 ? (
        <>
          <GoogleMap
            id="nearestRouteMap"
            mapContainerClassName={classes.mapContainerStyle}
            zoom={7}
            center={{
              lat,
              lng,
            }}
          >
            <StreetViewPanorama
              position={{
                lat,
                lng,
              }}
              visible
            />
          </GoogleMap>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item>
              <Typography className={classes.nearbyStopText}>
                Nearby Stop:{' '}
                <Button variant="outlined" onClick={handleStopInfo}>
                  {nearestStops.stopList[nearestStopsTabValue].stopDescr}
                </Button>
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.nearbyStopText}>
                Est. Walking Time:{' '}
                <Typography component="span">
                  {nearestStops.stopList[nearestStopsTabValue].timeStr}
                </Typography>
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.nearbyStopText}>
                Distance:{' '}
                <Typography component="span">
                  {nearestStops.stopList[nearestStopsTabValue].estWalkDist}{' '}
                  miles
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          <Box
            display="flex"
            className={classes.routesContainer}
            style={{ width: isMobile ? window.innerWidth : '100%' }}
          >
            {nearestStops?.stopList[nearestStopsTabValue]?.routeVMList.length >
            0
              ? nearestStops?.stopList[nearestStopsTabValue]?.routeVMList.map(
                  (route) => (
                    <RouteItem
                      key={uuid()}
                      route={updateRouteColors(route)}
                      selectedStop={selectedStop}
                      isMobile={isMobile}
                      handleMobile={handleMobile}
                      showFirst
                    />
                  ),
                )
              : null}
          </Box>
        </>
      ) : (
        <Typography className={classes.noRoutesText}>
          No Routes to display.
        </Typography>
      )}
    </Box>
  )
}

const RouteItem = ({ route, isMobile, handleMobile }) => {
  const classes = useRouteItemStyles()
  const dispatch = useDispatch()
  const { trips, tripUpdates } = useSelector((s) => s.maps)
  const textColor = textColorBasedOnBackground(route?.routeColor)
  const routeColor = route?.routeColor
  const handleShowRoute = async () => {
    try {
      // dispatch(handleStopClick(route))
      const { routeId } = route
      const trip_ids = trips[routeId]

      const enhancedStops = await getEnhancedStops(
        trip_ids, // key: route_id, value: list of trip_id's
        routeId,
      )

      // Fetch vehicle positions
      dispatch(fetchVehiclePositions())
      // Create a new object that includes the original busRoute properties plus the stops
      console.log(route)
      const updatedBusRoute = {
        busRoute: {
          agency_id: '',
          arrivalTime: [],
          isActive: route.isActive,
          route_color: routeColor,
          route_id: route.routeId,
          route_long_name: route.routeName,
          route_short_name: route.routeName,
          route_text_color: textColor,
        },
        enhancedStops,
        openSheet: true,
      }

      // Dispatch the action with the updated busRoute object
      dispatch(setSelectedRoute(updatedBusRoute))

      // use the trips map to get a trip_id for the selectedStop.stop_id
      const desiredTripId = tripUpdates.find((update) =>
        trip_ids.some(
          (targetTripId) => update.tripUpdate.trip.tripId === targetTripId,
        ),
      )
      // Sets up the polyline
      if (desiredTripId) {
        dispatch(fetchShapesAsGeoJSON(desiredTripId.tripUpdate.trip.tripId))
        if (isMobile) {
          const BUSTAB = 0
          dispatch(updateMapTabValue(BUSTAB))
          handleMobile()
        }
        logMapEvent(dispatch, ROUTEMARKER, route.routeName)
      }
    } catch (error) {
      console.error('An error occurred while handling the show route:', error)
      // Handle error as needed
    }
  }
  return (
    <Paper
      component="button"
      // disabled={route.is_active !== true}
      onClick={handleShowRoute}
      key={route.long_name}
      style={{ background: route.routeColor }}
      className={
        route.is_active ? classes.routeMarker : classes.routeMarkerInactive
      }
    >
      <Typography
        variant="h5"
        className={classes.routeNameLabel}
        style={{
          color: textColor,
        }}
      >
        {route.routeName}
      </Typography>
    </Paper>
  )
}

export default BuildingStreetView
