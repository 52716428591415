import * as React from 'react'
import { ListItem } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import {
  handleViewBuildingInfoSheet,
  setBuildingSubheader,
} from '../../../sections/Buildings/redux/myBuildingsSlice'
import { buildingListComponentStyles } from './buildingListComponentStyles'
import BuildingListItem from '../BuildingListItem/BuildingListItem'
import { updateBottomSheet } from '../../../sections/Map/redux/myMapsSlice'
import {
  BUILDING,
  CAMDEN,
  NEWARK,
  NEW_BRUNSWICK,
  NewBrunswick,
  OTHER,
  PISCATAWAY,
} from '../../../utils/constants'

const BuildingListComponent = ({ key, index, style, isScrolling }) => {
  const classes = buildingListComponentStyles()
  const dispatch = useDispatch()

  const { favoritedBuildings, selectedCampusBuildings } = useSelector(
    (s) => s.buildings,
  )

  const buildings = [
    ...favoritedBuildings,
    ...selectedCampusBuildings[NewBrunswick],
    ...selectedCampusBuildings.Newark,
    ...selectedCampusBuildings.Camden,
    ...selectedCampusBuildings.Other,
  ]

  React.useEffect(() => {
    if (favoritedBuildings.length > 0) {
      if (favoritedBuildings.some((b) => b.bid === buildings[index]?.bid)) {
        dispatch(setBuildingSubheader('FAVORITES'))
      }
    }
  }, [])

  React.useEffect(() => {
    if (isScrolling === true) {
      if (favoritedBuildings.some((b) => b.bid === buildings[index]?.bid)) {
        dispatch(setBuildingSubheader('FAVORITES'))
      } else if (
        buildings[index]?.city === NEW_BRUNSWICK ||
        buildings[index]?.city === PISCATAWAY
      ) {
        dispatch(setBuildingSubheader(NEW_BRUNSWICK))
      } else if (buildings[index]?.city === NEWARK) {
        dispatch(setBuildingSubheader(NEWARK))
      } else if (buildings[index]?.city === CAMDEN) {
        dispatch(setBuildingSubheader(CAMDEN))
      } else {
        dispatch(setBuildingSubheader(OTHER))
      }
    }
  }, [isScrolling])

  const viewBuildingInfo = (e) => {
    const { target } = e
    const regex = /favoriteIcon/
    const clickedFavorited = Object.values(target.parentElement.classList).join(
      ',',
    )
    if (!regex.test(clickedFavorited)) {
      dispatch(handleViewBuildingInfoSheet(buildings[index]?.bid))
      dispatch(updateBottomSheet(BUILDING))
    }
  }
  return (
    <ListItem
      button
      onClick={viewBuildingInfo}
      style={style}
      key={key}
      className={classes.list}
    >
      <BuildingListItem
        key={
          buildings[index]?.name +
          buildings[index]?.city +
          buildings[index]?.bid
        }
        building={buildings[index]}
        name={buildings[index]?.name}
        city={buildings[index]?.city}
        bid={buildings[index]?.bid}
        isFavorited={favoritedBuildings.some(
          (b) => b.bid === buildings[index]?.bid,
        )}
      />
    </ListItem>
  )
}

export default BuildingListComponent
