import React from 'react'
import { PropTypes } from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/core/styles'
import WidgetFormTabStyles from './WidgetFormTabsStyles'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Typography component="span">{children}</Typography>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  }
}

export default function WidgetFormTabs({ TabOne, TabTwo }) {
  const classes = WidgetFormTabStyles()
  const theme = useTheme()
  const [currentTab, setCurrentTab] = React.useState(0)

  const handleChange = (action, newValue) => {
    setCurrentTab(newValue)
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={currentTab}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
          variant="fullWidth"
        >
          <Tab label="Basic Fields" {...a11yProps(0)} />
          <Tab label="Advanced Fields" {...a11yProps(1)} />
        </Tabs>
      </AppBar>

      <TabPanel value={currentTab} index={0} dir={theme.direction}>
        {TabOne}
      </TabPanel>

      <TabPanel value={currentTab} index={1} dir={theme.direction}>
        {TabTwo}
      </TabPanel>
    </div>
  )
}
