import { makeStyles } from '@material-ui/core/styles'

export const AppStyles = makeStyles((theme) => ({
  appsPageContainer: {
    paddingTop: '40px',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 0,
    },
    backgroundColor: theme.palette.backgroundColor.main,
    minHeight: '100vh !important',
    overflow: 'visible',
  },
  container: {
    paddingLeft: 55,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20,
      paddingLeft: 0,
    },
  },
  containerMobile: {
    flex: 1,
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
    overscrollBehavior: 'contain',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  searchBar: {
    margin: '8px',
    background: theme.palette.components.newsBackground,
  },
  noMatches: {
    color: theme.palette.primary.headerText,
    fontSize: '20px',
    fontWeight: '600',
  },
  errorPageRoot: {
    marginTop: '60px',
  },
  errorPageHeader: {
    fontWeight: 600,
  },
  errorResolutionSteps: {
    fontSize: '14px',
  },
  errorPageHeaderContainer: {
    margin: 15,
  },
  errorPageResolutionHeader: {
    marginLeft: 15,
  },
  colorbox: {
    width: 600,
    height: 200,
    padding: 5,
    backgroundColor: theme.palette.background.default,
    boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
    borderRadius: 10,
    marginBottom: 50,
    textAlign: 'center',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      width: 500,
    },
    [theme.breakpoints.down('xs')]: {
      width: 300,
    },
  },
  errorPageLogout: {
    marginTop: 30,
    border: '3px solid rgb(199,20,20)',
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    fontSize: '20px',
    borderRadius: '5px',
    color: 'rgb(199,20,20)',
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
  },
  errorSectionText: {
    marginTop: 15,
    fontStyle: 'bold',
  },
  optionsContainer: {
    marginTop: 100,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    margin: '0 auto',
  },
}))

export default AppStyles
