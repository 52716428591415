import React from 'react'
import { Dot } from 'pure-react-carousel'
import { Button, Container } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css'

/** 
  Displays Custom Dot Group for the Carousel 
*/

const CustomDotGroup = ({ slides, size }) => (
  <Container textAlign="center">
    <Button.Group size={size}>
      {[...Array(slides).keys()].map((slide) => (
        <Button
          as={Dot}
          key={slide}
          size={size}
          icon="circle"
          slide={slide}
          style={{
            color: '#616161',
            backgroundColor: 'transparent',
            paddingLeft: 2,
            paddingRight: 2,
            marginTop: '5px',
          }}
        />
      ))}
    </Button.Group>
  </Container>
)

export default CustomDotGroup
