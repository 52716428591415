import React from 'react'
import { Grid } from '@material-ui/core'

import BigArticle from '../../BigArticle/BigArticle'
import { layoutTemplate, PLACEHOLDER_ARTICLES } from '../constants'

export default function SetupArticleLayoutWithImage({
  articles,
  isDesktopView,
  isLoading,
}) {
  const [organizedArticles, setOrganizedArticles] = React.useState([])
  const [layout, setLayout] = React.useState([])

  const sortArticles = (articlesList) => {
    const filteredArticles = articlesList.filter(
      (article) => !article.published.includes('-'),
    )

    return filteredArticles.reduce(
      (result, article) => {
        if (article.imageUrl) {
          result.withImage.push(article)
        } else {
          result.withoutImage.push(article)
        }
        return result
      },
      { withImage: [], withoutImage: [] },
    )
  }

  React.useEffect(() => {
    // Layout can contain articles with & without image
    const { withImage, withoutImage } = sortArticles(articles)

    const layoutForWithImage = isDesktopView
      ? layoutTemplate.desktop[withImage.length]
      : layoutTemplate.mobile[withImage.length]

    const layoutForWithoutImage = isDesktopView
      ? layoutTemplate.desktop[withoutImage.length]
      : layoutTemplate.mobile[withoutImage.length]

    const tempLayout = [...layoutForWithImage, ...layoutForWithoutImage]

    setLayout(tempLayout)
    setOrganizedArticles([...withImage, ...withoutImage])
  }, [articles, isDesktopView])

  return (
    <Grid container justifyContent="center" spacing={1}>
      {layout.map((size, index) => {
        const currentArticle = isLoading
          ? PLACEHOLDER_ARTICLES[index]
          : organizedArticles[index]
        return (
          <Grid key={currentArticle.id} item xs={size}>
            <BigArticle
              article={currentArticle}
              isMobile={isDesktopView}
              isLoading={isLoading}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}
