import { makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles((theme) => ({
  centerPage: {
    padding: '60px 55px 0 95px',
    backgroundColor: theme.palette.backgroundColor.main,
    [theme.breakpoints.down('sm')]: {
      padding: '60px 20px 0 20px',
    },
    minHeight: '100vh !important',
    // this is the component that lets up scroll through the widgets on mobile:
    overflow: 'visible',
  },
  dialog: {
    width: '70vw',
    maxWidth: '100%',
    height: '70vh',
    maxHeight: '450px',
    overflow: 'hidden',
    padding: '8px 12px',
    backgroundColor: theme.palette.backgroundColor.secondary,
    [theme.breakpoints.down('sm')]: {
      minWidth: '100px',
      width: '90vw',
    },
  },
  dialogContainer: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  button: {
    height: '44px',
    padding: '6px',
    width: '100%',
    margin: '2px auto',
    justifyContent: 'flex-start',
    border: '2px solid blue',
    borderColor: theme.palette.base.lightGray,
  },
  widgetPaper: {
    width: '100%',
    backgroundColor: theme.palette.backgroundColor.ternary,
    cursor: 'pointer',
  },
  selectedPaper: {
    borderColor: theme.palette.base.red,
    cursor: 'pointer',
    border: '2px solid',
  },
  widgetPaperPadding: {
    padding: '5px',
  },
  titlePadding: {
    padding: '10px',
    paddingLeft: '5px',
  },
  subtitlePadding: {
    paddingLeft: '5px',
  },
  checkmarkImage: {
    position: 'absolute',
    padding: 5,
  },
  backgroundDiv: {
    backgroundColor: 'white',
    width: '28px',
    height: '28px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonZIndex: {
    zIndex: theme.zIndex.modal + 100,
  },
  selectedButton: {
    height: '44px',
    padding: '6px',
    width: '100%',
    margin: '2px auto',
    justifyContent: 'flex-start',
    border: '2px solid blue',
    borderColor: theme.palette.base.red,
  },
  tabs: {
    width: '100%',
  },
  dialogContent: {
    height: '100%',
    padding: '5px',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  desc: {
    display: 'flex',
    alignContent: 'flex-end',
    justifyItems: 'flex-end',
  },
  textField: {
    width: '100%',
    height: '48px',
    margin: '0 auto',
  },
  paper: {
    maxWidth: '100%',
    padding: '5px',
  },
  verticalContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    height: '100%',
  },
  scrollContainer: {
    overflowY: 'auto',
    flexGrow: 1,
    padding: '5px',
    maxHeight: '100%',
  },
  popupContainer: {
    flexShrink: 1,
  },
  widgetImage: {
    width: '100%',
    height: '100%',
  },
  errorPageHeader: {
    fontWeight: 600,
  },
  errorResolutionSteps: {
    fontSize: '14px',
  },
  errorPageHeaderContainer: {
    margin: 15,
  },
  errorPageResolutionHeader: {
    marginLeft: 15,
  },
  colorbox: {
    width: 600,
    height: 200,
    padding: 5,
    backgroundColor: theme.palette.background.default,
    boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
    borderRadius: 10,
    marginBottom: 50,
    textAlign: 'center',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      width: 500,
    },
    [theme.breakpoints.down('xs')]: {
      width: 300,
    },
  },
  errorPageLogout: {
    marginTop: 30,
    border: '3px solid rgb(199,20,20)',
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    fontSize: '20px',
    borderRadius: '5px',
    color: 'rgb(199,20,20)',
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
  },
  errorPageRestore: {
    marginTop: 42,
    border: '3px solid rgb(199,20,20)',
    backgroundColor: theme.palette.background.default,
    boxShadow: 'none',
    fontSize: '20px',
    borderRadius: '5px',
    color: 'rgb(199,20,20)',
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
  },
  errorSectionText: {
    marginTop: 15,
    fontStyle: 'bold',
  },
  container: {
    marginTop: 50,
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    margin: '0 auto',
  },
}))

export default styles
