/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
import * as React from 'react'
import uuid from 'react-uuid'
import { useDispatch, useSelector } from 'react-redux'
import { fetchFirebaseMarkers } from '../../redux/myMapsSlice'
import FirebaseMarker from '../FirebaseMarker/FirebaseMarker'

export default function FirebaseStops() {
  const dispatch = useDispatch()
  const { firebaseMarkersLoading } = useSelector((s) => s.maps)

  React.useEffect(() => {
    dispatch(fetchFirebaseMarkers())
  }, [dispatch])

  return firebaseMarkersLoading === 'fulfilled' && <FirebaseMarkers />
}

function FirebaseMarkers() {
  const { firebaseMarkers } = useSelector((s) => s.maps)

  return (
    <>
      {firebaseMarkers.map((stop) => (
        <FirebaseMarker key={uuid()} stop={stop} />
      ))}
    </>
  )
}
