import { addProfileLogEvent } from '../../MyProfile/myProfileSlice'

export const onAppClick = (title, appUrl, dispatch, isMobile) => {
  if (process.env.REACT_APP_FIREBASE_ON === 'true') {
    dispatch(
      addProfileLogEvent({
        module: 'Dash',
        object: 'Widget',
        objectId: 'MyFavorites',
        action: 'Click',
        actionContext: 'ExternalClick',
        actionContextValue: title,
        rcpid: localStorage.getItem('rutgersEduRCPID'),
        url: appUrl,
        timestamp: Date.now(),
        deviceType: isMobile ? 'Mobile' : 'Web',
      }),
    )
  }
  window.open(appUrl, '_blank')
}
// kept for later in event of updating firebase calls to new backend
export const handleDeFavorited = (e, title, dispatch, isMobile) => {
  e.stopPropagation()
  const user = localStorage.getItem('user') ?? 'visitor'
  const isGuest = user === '"visitor"' || user === ''

  if (!isGuest) {
    if (process.env.REACT_APP_FIREBASE_ON === 'true') {
      dispatch(
        addProfileLogEvent({
          module: 'Dash',
          object: 'Widget',
          objectId: 'MyFavorites',
          action: 'Click',
          actionContext: 'UnFavorite',
          actionContextValue: title,
          rcpid: localStorage.getItem('rutgersEduRCPID'),
          timestamp: Date.now(),
          deviceType: isMobile ? 'Mobile' : 'Web',
        }),
      )
    }
  }
}
