/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const fetchMyWellness = createAsyncThunk('fetchMyWellness', async () => {
  const jwtToken = localStorage.getItem('myrJwtToken')
  const res = await axios.get(process.env.REACT_APP_MYWELLNESS_ENDPOINT, {
    headers: {
      JWT: jwtToken,
    },
  })
  return res.data
})

export const fetchMyWellnessCategory = createAsyncThunk(
  'fetchMyWellnessCategory',
  async (title) => {
    const jwtToken = localStorage.getItem('myrJwtToken')
    let categoryEndPoint = process.env.REACT_APP_MYWELLNESSCATEGORY_ENDPOINT
    switch (title) {
      case 'Staying at Home':
        categoryEndPoint =
          process.env.REACT_APP_MYWELLNESS_WORKINGFROMHOME__ENDPOINT
        break
      case 'Physical Wellness':
        categoryEndPoint =
          process.env.REACT_APP_MYWELLNESS_PHYSICALWELLNESS__ENDPOINT
        break
      case 'Mental Wellness':
        categoryEndPoint =
          process.env.REACT_APP_MYWELLNESS_MENTALWELLNESS__ENDPOINT
        break
      case 'Peer Support':
        categoryEndPoint =
          process.env.REACT_APP_MYWELLNESS_PEERSUPPORT__ENDPOINT
        break
      default:
        break
    }
    const res = await axios.get(categoryEndPoint, {
      headers: {
        JWT: jwtToken,
      },
    })
    return res.data
  },
)

const initialState = {
  status: 'idle',
  data: {
    title: 'My Wellness',
    description: '',
    footer: '',
    footerurl: '',
    wellnessList: [],
  },
}

const myWellnessSlice = createSlice({
  name: 'myWellnessSlice',
  initialState,
  reducers: {
    updateTherapeuticServices: (state) => {
      state.categoryStatus = 'fulfilled'
    },
  },
  extraReducers: {
    [fetchMyWellness.rejected]: (state) => {
      state.status = 'error'
    },
    [fetchMyWellness.pending]: (state) => {
      state.status = 'pending'
    },
    [fetchMyWellness.fulfilled]: (state, action) => {
      state.status = 'fulfilled'
      state.data.title = action.payload.title
      state.data.description = action.payload.description
      state.data.wellnessList = action.payload.wellnessList
      state.data.footer = action.payload.footer
      state.data.footerurl = action.payload.footerurl
    },
    [fetchMyWellnessCategory.rejected]: (state) => {
      state.categoryStatus = 'error'
    },
    [fetchMyWellnessCategory.pending]: (state) => {
      state.categoryStatus = 'pending'
    },
    [fetchMyWellnessCategory.fulfilled]: (state, action) => {
      state.categoryStatus = 'fulfilled'
      state.categoryData = action.payload
    },
  },
})

export const selectMyWellnessData = (state) => state.myWellness.data
export const selectMyWellnessStatus = (state) => state.myWellness.status
export const selectMyWellnessCategoryData = (state) =>
  state.myWellness.categoryData
export const selectMyWellnessCategoryStatus = (state) =>
  state.myWellness.categoryStatus
export const { updateTherapeuticServices } = myWellnessSlice.actions

export default myWellnessSlice.reducer
