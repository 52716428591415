import { makeStyles } from '@material-ui/core/styles'

export const ViewAppInfoMobileStyles = makeStyles((theme) => ({
  appBar: {
    background: theme.palette.components.newsBackground,
    color: 'rgba(0,0,0,0.54)',
  },
  myRutgersLogo: {
    height: 0,
    maxWidth: 0,
  },
  mobileBackButtonIcon: {
    color: theme.palette.base.red,
    fontSize: 24,
  },
  container: {
    flex: 1,
    width: '100%',
    background: theme.palette.components.newsBackground,
    // overflow: 'auto',
    overscrollBehavior: 'contain',
    // '&::-webkit-scrollbar': {
    //   display: 'none',
    // },
    justifyContent: 'center',
  },
  pageTitleContainer: {
    width: '86%',
  },
  pageTitle: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 1,
    wordBreak: 'keep-all',
    overflow: 'hidden',
    color: theme.palette.base.red,
    fontSize: 22,
    fontWeight: '400',
    textOverflow: 'ellipsis',
  },
  relatedText: {
    fontSize: '24px',
    fontWeight: '600',
    color: theme.palette.primary.headerText,
    marginRight: '125px',
    paddingBottom: '10px',
  },
  iconContainer: {
    width: '80px',
    height: '80px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headingContainer: {
    paddingLeft: '16px',
  },
  appNameText: {
    display: '-webkit-box',
    boxOrient: 'vertical',
    lineClamp: 1,
    wordBreak: 'keep-all',
    overflow: 'hidden',
    fontSize: '15px',
    color: theme.palette.primary.headerText,
    textAlign: 'left',
    fontWeight: '600',
  },
  categoryText: {
    color: theme.palette.secondary.headerText,
    fontSize: '13px',
    textAlign: 'start',
    fontWeight: '500',
  },
  iconButton: {
    fontSize: '20px',
  },
  selectedFavoriteIconButton: {
    fontSize: '20px',
    color: theme.palette.base.red,
  },
  openButton: {
    borderRadius: '20px',
    color: theme.palette.base.red,
    backgroundColor: theme.palette.base.grey,
    height: '25px',
    fontWeight: '600',
  },
  descriptionContainer: {
    marginTop: '10px',
  },
  descriptionHeaderText: {
    color: theme.palette.primary.headerText,
    textAlign: 'start',
    fontSize: '14px',
    fontWeight: '600',
  },
  infoTextContainer: {
    padding: '8px',
  },
  infoText: {
    color: theme.palette.secondary.headerText,
    textAlign: 'start',
    fontSize: '14px',
    fontWeight: '600',
  },
  carouselGridContainer: {
    marginTop: '10px',
    marginBottom: '30px',
  },
  CarouselProvider: {
    marginLeft: 'auto',
    marginRight: 'auto',
    height: '80vh',
    width: '50%',
  },
  categoryCardSliderContainer: {
    marginTop: '20px',
  },
  sliderContainer: {
    marginLeft: 4,
    marginRight: 4,
  },
  slides: {
    height: '80vh',
    width: '90%',
    marginBottom: '10px',
  },
  img: {
    height: '100%',
    width: '80%',
    borderRadius: '8px',
  },
  appCarouselBody: {
    paddingBottom: '80px',
  },
}))

export default ViewAppInfoMobileStyles
