/* eslint-disable no-unused-vars */
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'

import Loading from '../helpers/Loading'
import Widget from '../Widget'
import IconPicker from '../../../apps/AppComponents/IconPicker/IconPicker'

import styles from './MyTeachingScheduleStyles'
import {
  fetchTeachingSchedule,
  selectTeachingScheduleData,
  selectTeachingScheduleStatus,
} from './MyTeachingScheduleSlice'
import { logDashboardEvent } from '../../events'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'
// eslint-disable-next-line no-unused-vars
function MyTeachingSchedule({
  className,
  title,
  widgetId,
  showNote,
  noteMessage,
  notePosition,
  noteBackground,
  noteIcon,
  noteUrl,
  noteFontColor,
}) {
  const classes = styles()
  const dispatch = useDispatch()
  const widgetData = useSelector(selectTeachingScheduleData)
  const widgetStatus = useSelector(selectTeachingScheduleStatus)

  React.useEffect(() => {
    dispatch(fetchTeachingSchedule())
  }, [])

  return (
    <Widget className={className}>
      <Widget.Header title={title} category="default" />
      <Widget.Content
        className={classes.container}
        showNote={showNote}
        noteMessage={noteMessage}
        notePosition={notePosition}
        noteBackground={noteBackground}
        noteIcon={noteIcon}
        noteUrl={noteUrl}
        noteFontColor={noteFontColor}
      >
        {renderWidgetState(
          widgetStatus,
          widgetData,
          classes,
          widgetId,
          dispatch,
        )}
      </Widget.Content>
    </Widget>
  )
}

function renderWidgetState(
  widgetStatus,
  widgetData,
  classes,
  widgetId,
  dispatch,
) {
  switch (widgetStatus) {
    case 'fulfilled':
      return (
        <Loaded
          widgetData={widgetData}
          classes={classes}
          widgetId={widgetId}
          dispatch={dispatch}
        />
      )
    case 'pending':
      return <Loading />
    case 'error':
      return <ErrorMessage />
    default:
      return <ErrorMessage />
  }
}

function Loaded({ widgetData, classes, widgetId, dispatch }) {
  const { tab } = useSelector((s) => s.myTeachingSchedule)
  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      className={classes.gridContainer}
    >
      <Grid
        container
        item
        direction="column"
        className={classes.coursesContainer}
        spacing={1}
      >
        {widgetData[tab]?.map((course, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Grid item key={index} className={classes.noPadding}>
            <CourseInfo
              course={course}
              classes={classes}
              index={index}
              widgetId={widgetId}
              dispatch={dispatch}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

function CourseInfo({ course, classes, index, widgetId, dispatch }) {
  const handleLinkClick = (link, buttonTitle) => {
    window.open(link, '_blank')
    if (widgetId && dispatch) {
      logDashboardEvent(dispatch, 'dashboardEvent', widgetId, buttonTitle, link)
    }
  }
  return (
    <div
      className={
        index % 2 === 0
          ? classes.courseInfoGridWhite
          : classes.courseInfoGridGray
      }
    >
      <Grid container className={classes.gridPadding} direction="column">
        <Grid item>
          <Typography variant="subtitle1">{course.courseTitle}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">
            <b>Course #:</b> {course.course} <b>Section:</b> {course.sectionNo}
          </Typography>
        </Grid>
        <Grid item container direction="row">
          <Grid item container direction="column" xs={3}>
            <Grid item>
              <Typography variant="body2">
                <b>Roster:</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                <b>Time:</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                <b>Type:</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                <b>Campus:</b>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                <b>Location:</b>
              </Typography>
            </Grid>
          </Grid>
          <Grid item container direction="column" xs>
            <Grid item>
              <Typography variant="body2">{course.roster}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                {course.meetingDay} {course.startTime}- {course.endTime}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">
                {course.courseType ? course.courseType : 'n/a'}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">{course.campusCode}</Typography>
            </Grid>
            <Grid item>
              {course.bldInfo &&
              course.bldInfo.bldgVM &&
              course.bldInfo.bldgVM.bldgDescr ? (
                <Typography variant="body2">
                  {course.dcsUrl ? (
                    <Link href={course.dcsUrl} target="blank">
                      {course.bldInfo.bldgVM.bldgDescr} Room: {course.roomno}
                    </Link>
                  ) : (
                    <span>
                      {course.bldInfo.bldgVM.bldgDescr} Room: {course.roomno}
                    </span>
                  )}
                </Typography>
              ) : (
                <Typography variant="body2">No location info.</Typography>
              )}
            </Grid>
          </Grid>
          <Grid item container direction="row" justifyContent="flex-end">
            {course.dcsUrl && (
              <Grid item>
                <IconButton
                  className={classes.iconButton}
                  onClick={() => handleLinkClick(course.dcsUrl, 'university')}
                >
                  <IconPicker iconName="university" />
                </IconButton>
              </Grid>
            )}
            {course.bldInfo && (
              <Grid item>
                <IconButton
                  className={classes.iconButton}
                  onClick={() =>
                    handleLinkClick(course.bldInfo.bldgURL, 'map-marker-alt')
                  }
                >
                  <IconPicker iconName="map-marker-alt" />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default MyTeachingSchedule
