import { makeStyles } from '@material-ui/core/styles'

export const myAlertsStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    margin: 0,
    height: 470,
    width: 335,
    overflowY: 'clip',
    overflowX: 'hidden',
    [theme.breakpoints.down(370)]: {
      width: 287,
      height: 450,
    },
  },
  retainer: {
    height: '100%',
  },
  modalTabs: {
    width: '335px',
    height: '35px',
    minHeight: '35px',
    minWidth: '335px',
    [theme.breakpoints.down(370)]: {
      width: '300px',
    },
  },
  alertsListContainer: {
    padding: '5px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  alertsListContainerHistory: {
    overflowY: 'hidden',
    overflowX: 'hidden',
  },
  alertListHeight: {
    display: 'block',
    height: 396,
    overflowY: 'auto',
  },
  alertListHighChip: {
    background: theme.palette.base.red,
    color: theme.palette.base.white,
    fontSize: '1rem',
    '&:hover': {
      background: theme.palette.base.red,
    },
  },
  alertListMediumChip: {
    background: theme.palette.base.blue,
    color: theme.palette.base.white,
    fontSize: '1rem',
    '&:hover': {
      background: theme.palette.base.blue,
    },
  },
  alertListLowChip: {
    background: theme.palette.base.yellow,
    color: theme.palette.base.black,
    fontSize: '1rem',
    '&:hover': {
      background: theme.palette.base.yellow,
    },
  },
  fullWidth: {
    maxWidth: '100% !important',
  },
  pastHistoryButton: {
    width: '100%',
    background: theme.palette.primary.main,
    color: theme.palette.base.white,
    height: 44,
    fontSize: '1rem',
  },
  icon: {
    borderLeft: '5px solid red',
  },
}))

export default myAlertsStyles
