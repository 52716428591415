import * as React from 'react'
import { useDispatch } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ExitToApp from '@material-ui/icons/ExitToApp'
import { handleLogout } from './dashboardSlice'
import { restoreDashboardWidgets } from '../settings/settingsSlice'
import styles from './DashboardStyles'

function ErrorPage() {
  const classes = styles()
  const dispatch = useDispatch()

  return (
    <Box className={classes.container}>
      <div className={classes.colorbox}>
        <div className={classes.errorSectionText}>
          <Typography variant="h1">Session Timed Out</Typography>
          <Typography variant="h5">
            Please log out and log back in using the button below
          </Typography>
        </div>
        <Button
          component="label"
          variant="contained"
          className={classes.errorPageLogout}
          onClick={() => dispatch(handleLogout())}
          startIcon={<ExitToApp />}
        >
          Logout
        </Button>
      </div>
      <div className={classes.colorbox}>
        <div className={classes.errorSectionText}>
          <Typography variant="h3">Still having issues?</Typography>
          <Typography variant="h5">
            Please try restoring your widgets using the button below
          </Typography>
        </div>
        <Button
          component="label"
          variant="contained"
          className={classes.errorPageRestore}
          onClick={() =>
            dispatch(restoreDashboardWidgets()).then(window.location.reload())
          }
        >
          Restore Widgets
        </Button>
      </div>
    </Box>
  )
}

export default ErrorPage
