/* eslint-disable */
import axios from 'axios'
import { fetchPassioGoVehiclePositions, fetchTripUpdates } from '../api/api'

export function getOverallState(states) {
  const hasError = states.some((state) => state === 'error')

  if (hasError) {
    return 'error'
  }

  const hasPending = states.some((state) => state === 'pending')

  if (hasPending) {
    return 'pending'
  }

  // If we haven't found any 'error' or 'pending' states, we can assume all states are 'fulfilled'
  return 'fulfilled'
}

export const fetchStopsByRouteId = async (routeId) => {
  try {
    const url1 = `${process.env.REACT_APP_PASSIOGO_STOPS_BY_ROUTE_ID_ENDPOINT}?routeId=${routeId}`
    const url2 = `${process.env.REACT_APP_PASSIOGO_STOPS_AS_GEO_JSON_ENDPOINT}`

    // Fetch data concurrently
    const [response1, response2] = await Promise.all([
      axios.get(url1),
      axios.get(url2),
    ])

    const stopsByRoute = response1.data
    const stopsAsGeoJson = response2.data.features.map(
      (feature) => feature.properties,
    )

    // Combine the two data sets
    const combinedStops = stopsByRoute.map((stop) => {
      const correspondingGeoJsonStop = stopsAsGeoJson.find(
        (geoJsonStop) => geoJsonStop.stop_id === stop.stop_id,
      )
      return {
        ...stop,
        ...correspondingGeoJsonStop,
      }
    })

    return combinedStops
  } catch (error) {
    console.error('An error occurred:', error)
    throw error
  }
}

export const getEnhancedStops = async (tripIds, route_id) => {
  try {
    const stops = await fetchStopsByRouteId(route_id)
    const vehicles = await fetchPassioGoVehiclePositions()

    // Define an asynchronous function to handle your logic
    const fetchAndEnhanceStops = async () => {
      // Fetch trip updates
      const tripUpdates = await fetchTripUpdates()

      // Assuming vehicles is your list of vehicle objects, create a mapping from tripId to vehicleId
      const tripToVehicleMap = vehicles.reduce((acc, vehicleObj) => {
        const { vehicle } = vehicleObj
        return { ...acc, [vehicle.trip.tripId]: vehicle }
      }, {})

      // Filter out matching trip updates
      const matchingTripUpdates = tripIds.flatMap((tripId) =>
        tripUpdates.filter(
          (update) => update.tripUpdate.trip.tripId === tripId,
        ),
      )

      // Enhance stops with arrival times and vehicle info
      const enhancedStops = stops.map((stop) => {
        // Mapping of arrivals along with the corresponding vehicle info
        const arrivals = matchingTripUpdates.flatMap((update) => {
          const vehicle = tripToVehicleMap[update.tripUpdate.trip.tripId]
          return update.tripUpdate.stopTimeUpdate
            .filter((stu) => stu.stopId === stop.stop_id)
            .map((stu) => ({
              arrivalTime: stu.arrival.time,
              vehicle,
            }))
        })

        // Sort by arrival time in unix format
        arrivals.sort((a, b) => a.arrivalTime - b.arrivalTime)
        // Convert unix time to readable time without modifying the original array
        let updatedArrivals = arrivals.map((arr) => ({
          ...arr,
          arrivalTime: getArrivalTime(arr.arrivalTime),
        }))
        // AS OF 2/6/24 WE NEED TO REMOVE ANY ARRIVAL TIMES OF N/A
        updatedArrivals = cleanseOldArrivalTimes(updatedArrivals)
        return {
          ...stop,
          arrivals: updatedArrivals,
        }
      })
      // console.log('enhancedStops ', enhancedStops)
      return enhancedStops
    }

    // Call the function to fetch and enhance stops
    const enhancedStops = await fetchAndEnhanceStops()
    return enhancedStops
  } catch (error) {
    console.error('An error occurred while fetching stops by route ID:', error)
    // Handle error as needed
    throw error
  }
}

/**
 * Converts a Unix timestamp to a human-readable time from now, with a buffer for slight inaccuracies.
 * @param {number|string} estArrivalTime - The estimated arrival time as a Unix timestamp.
 * @returns {string|null} A string representing the arrival time from now or null if input is invalid.
 */
export const getArrivalTime = (estArrivalTime) => {
  const BUFFER_THRESHOLD_VAL = 20
  const SIXTY_SECONDS = 60
  const MILLISECONDS_IN_A_SECOND = 1000
  const MILLISECONDS_IN_A_MINUTE = 60000

  const arrivalTime = parseInt(estArrivalTime, 10) * MILLISECONDS_IN_A_SECOND

  if (Number.isNaN(arrivalTime)) {
    console.error('Converted arrival time is not a number.')
    return null
  }

  const currentTime = new Date().getTime()
  const timeDiff = arrivalTime - currentTime
  const minutes = Math.floor(timeDiff / MILLISECONDS_IN_A_MINUTE)
  const seconds = Math.floor(
    (timeDiff % MILLISECONDS_IN_A_MINUTE) / MILLISECONDS_IN_A_SECOND,
  )

  // Simplify conditional logic using early returns
  if (minutes > 1) return `${minutes} mins`
  if (minutes === 1) return '1 min'
  if (seconds < -BUFFER_THRESHOLD_VAL) return 'N/A' // Too far in the past
  if (seconds <= BUFFER_THRESHOLD_VAL) return 'Now' // Within the threshold buffer
  if (seconds < SIXTY_SECONDS) return `~${seconds} seconds` // Imminent arrival

  return 'N/A' // Default case for times outside expected range
}

export const getTextColorForRouteItem = (route) => {
  const defaultColor = '#000000'
  const overrides = { 4067: '#000000' }

  let routeTextColor =
    route?.route_id in overrides
      ? overrides[route.route_id]
      : route?.route_text_color || defaultColor

  // Prepend '#' if missing
  if (!routeTextColor.startsWith('#')) {
    routeTextColor = `#${routeTextColor}`
  }

  return routeTextColor
}

export const cleanseOldArrivalTimes = (updatedArrivals) =>
  updatedArrivals.filter(
    (updatedArrival) => updatedArrival.arrivalTime !== 'N/A',
  )
