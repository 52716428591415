import { makeStyles } from '@material-ui/core/styles'

export const enrollmentPathwayStyles = makeStyles((theme) => ({
  content: {
    padding: 0,
  },
  linearProgressContainer: {
    width: '100%',
    height: 30,
    marginTop: 10,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.backgroundColor.secondary,
    borderColor: `${theme.palette.backgroundColor.ternary} !important`,
    borderBottom: '1px solid',
    position: 'relative',
  },
  linearProgressBar: {
    width: '90%',
    position: 'absolute',
    height: 20,
    borderRadius: 5,
  },
  linearBarBackgroundColor: {
    background: theme.palette.base.gray,
  },
  linearProgressBarOne: {
    background: theme.palette.base.green,
  },
  linearProgressText: {
    color: '#ffffff',
    fontWeight: 'bold',
    zIndex: 1000,
  },
  enrollmentTaskCard: {
    margin: 15,
  },
  enrollmentTaskCardContent: {
    display: 'flex',
  },
  enrollmentTasksContainer: {
    flex: 1,
    overflowY: 'auto',
    paddingTop: '5px',
    paddingBottom: '5px',
    width: 335,
  },
  enrollmentPathwayButton: {
    background: theme.palette.base.red,
    color: theme.palette.base.white,
    fontSize: 13,
    fontWeight: 600,
    '&:hover': {
      color: theme.palette.base.white,
      background: theme.palette.base.red,
    },
  },
  enrollmentCardActions: {
    justifyContent: 'space-between',
  },
  enrollmentItemDateButton: {
    background: theme.palette.base.red,
    color: theme.palette.base.white,
    fontWeight: 600,
  },
  enrollmentTaskTitle: {
    marginLeft: 20,
    color: theme.palette.primary.headerText,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tasksHeader: {
    fontWeight: 600,
    marginLeft: 15,
    paddingTop: 10,
  },
  mainGrid: {
    height: 475,
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      'inset 0 0 0 2px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    // backgroundImage:
    //   'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    // '$root.Mui-focusVisible &': {
    //   outline: '2px auto rgba(19,124,189,.6)',
    //   outlineOffset: 2,
    // },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.base.red,
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: theme.palette.base.red,
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.base.red,
    },
  },
  enrollmentPathwayErrorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  enrollmentPathwayContainerColor: {
    width: '90%',
    background: theme.palette.base.red,
    color: theme.palette.base.white,
    padding: 10,
    marginTop: 10,
    borderRadius: '0.5rem',
  },
}))
