/* eslint-disable react/no-array-index-key */
import * as React from 'react'
import { doc, collection, getDoc } from 'firebase/firestore'
// MUI Imports
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import {
  //   ButtonBase,
  ClickAwayListener,
  //   Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core'
import SvgIcon from '@material-ui/core/SvgIcon'
// import ExpandLess from '@material-ui/icons/ExpandLess'
// import ExpandMore from '@material-ui/icons/ExpandMore'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import uuid from 'react-uuid'

// Other Imports
// Custom Imports
import styles from './sideNavStyles'
import { navigation } from './sidenav.constants'
import { drawerClose, drawerToggle } from '../navigationSlice'
import { useBreakpointUp } from '../../theme/helpers/useBreakpointUp'
import {
  fetchUser,
  selectUserData,
} from '../../features/settings/settingsSlice'
import { CustomIcon } from '../../features/apps/AppComponents/IconPicker/IconPicker'
import { firestore } from '../../../utils/firebase'
import CommunityDialog from '../communityDialog/CommunityDialog'
import { addProfileLogEvent } from '../../features/dashboard/widgets/MyProfile/myProfileSlice'

// TODO This needs a major overhaul. We should implement this so it builds itself based off
// of whatever the navigation routes are.
// TODO Add back sub drawers for dashboard, apps, and news
function SideNav() {
  const location = useLocation()
  const isDesktop = useBreakpointUp('md')
  const drawerIsOpen = useSelector((state) => state.navigation.drawerIsOpen)
  const userData = useSelector(selectUserData)
  const [previous, setPrevious] = React.useState(drawerIsOpen)
  const history = useHistory()
  const classes = styles()
  const dispatch = useDispatch()

  // fetch the user so we can see if they are a visitor or not
  React.useEffect(() => {
    dispatch(fetchUser())
  }, [])

  // make sure the nav drawer is closed by default in the mobile layout.
  React.useEffect(() => {
    if (isDesktop === false) {
      dispatch(drawerClose())
    }
  }, [isDesktop, dispatch])

  const clickAwayHandler = () => {
    if (!previous) {
      setPrevious(true)
    } else {
      dispatch(drawerClose())
      setPrevious(false)
    }
  }

  if (location.pathname === '/login') {
    return null
  }
  // navigation.routes.forEach((listItem) => console.log(listItem))

  return (
    <ClickAwayListener onClickAway={clickAwayHandler}>
      <SwipeableDrawer
        elevation={3}
        anchor="left"
        open={drawerIsOpen}
        variant={isDesktop ? 'permanent' : 'temporary'}
        onOpen={() => {}}
        onClose={() => dispatch(drawerClose())}
        className={`${classes.sideNavDrawer} ${
          drawerIsOpen ? classes.sideNavDrawerOpen : classes.sideNavDrawerClosed
        }`}
        classes={{
          paper: `${classes.sideNavDrawer} ${
            drawerIsOpen
              ? classes.sideNavDrawerOpen
              : classes.sideNavDrawerClosed
          }`,
        }}
      >
        <List>
          {/* TODO fix hover hotspot to include menu item name */}
          {!drawerIsOpen ? (
            <Tooltip
              title={
                <Typography variant="subtitle2">Open Main Menu</Typography>
              }
              placement="right"
              arrow
              enterNextDelay={250}
            >
              <ListItem
                button
                key={uuid()}
                onClick={() => dispatch(drawerToggle())}
                aria-label="Open Main Menu"
                className={classes.sideNavOpenClose}
                style={{ width: 43 }}
                focusRipple
              >
                <ListItemIcon className={classes.sideNavIcon}>
                  <CustomIcon
                    imgSrc={`${process.env.REACT_APP_IMAGES_URL}/assets/images/svgs/sidenav_icon.svg`}
                    size={22}
                  />
                </ListItemIcon>
              </ListItem>
            </Tooltip>
          ) : (
            <ListItem
              button
              key={uuid()}
              onClick={() => dispatch(drawerToggle())}
              className={classes.sideNavOpenClose}
              focusRipple
            >
              <ListItemIcon className={classes.sideNavIcon}>
                <CustomIcon
                  imgSrc={`${process.env.REACT_APP_IMAGES_URL}/assets/images/svgs/sidenav_icon.svg`}
                  size={22}
                />
              </ListItemIcon>
              <ListItemText
                aria-label="Close Main Menu"
                style={{ paddingLeft: '5px' }}
              >
                Close Main Menu
              </ListItemText>
            </ListItem>
          )}
          <div style={{ marginTop: '24px' }} />
          {navigation.routes.map((listItem, index) => (
            <NavListItem
              key={`${uuid()}-${index}`}
              history={history}
              location={location}
              dispatch={dispatch}
              listItem={listItem}
              userData={userData}
              classes={classes}
              drawerIsOpen={drawerIsOpen}
            />
          ))}
        </List>
      </SwipeableDrawer>
    </ClickAwayListener>
  )
}

function NavListItem({
  classes,
  listItem,
  drawerIsOpen,
  history,
  dispatch,
  location,
  userData,
}) {
  const isAdmin = useSelector((s) => s.settings.adminMode)
  const hasSubList =
    (listItem.subCategories != null &&
      userData &&
      ['/', '/news'].includes(location.pathname)) ||
    isAdmin

  const [communityDialogOpen, setCommunityDialogOpen] = React.useState(false)

  const handleCommunityDialogClose = () => {
    setCommunityDialogOpen(false)
  }

  const handleCommunityRedirect = () => {
    logNavClick()
    window.location.replace(process.env.REACT_APP_MYCOMMUNITY_URL)
  }

  const logNavClick = () => {
    if (process.env.REACT_APP_FIREBASE_ON === 'true') {
      dispatch(
        addProfileLogEvent({
          module: 'SideNav',
          object: 'SideNavButton',
          objectId: 'SideNavItem',
          action: 'Click',
          actionContext: 'SideNavClick',
          actionContextValue: listItem.title,
          rcpid: localStorage.getItem('rutgersEduRCPID'),
          url: listItem.path,
          timestamp: Date.now(),
        }),
      )
    }
  }

  const fetchUserState = async () => {
    const docRef = doc(
      collection(firestore, 'users'),
      localStorage.getItem('user') ?? 'visitor',
      'settings',
      'userState',
    )

    try {
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        return docSnap
      }
      console.log('User state document does not exist')
      return null
    } catch (error) {
      console.error('Error fetching user state:', error)
      return null
    }
  }

  const listItemAction = () => {
    if (listItem.title === 'Community') {
      fetchUserState().then((snapshot) => {
        const communityAcknowledgement = snapshot.data().acknowledgedCommunity
        console.log('Community Acknowledgement returned: ')
        console.log(communityAcknowledgement)
        if (!communityAcknowledgement) {
          console.log('Inside community ack false')
          setCommunityDialogOpen(true)
        } else handleCommunityRedirect()
      })

      return
    }

    logNavClick()

    if (listItem.path.includes('https://')) {
      window.location.replace(listItem.path)
    }
    if ([listItem.path, listItem.adminPath].includes(location.pathname)) {
      dispatch(drawerClose())
      return
    }
    if (listItem.path || listItem.adminPath) {
      dispatch(drawerClose())
      history.push(listItem.path)
    }
  }

  if (listItem.customComponent) {
    return <listItem.customComponent classes={classes} />
  }

  const pathIsActive =
    listItem.path === location.pathname ||
    listItem.subPages?.includes(location.pathname)

  return (
    <>
      {!drawerIsOpen ? (
        <Tooltip
          title={<Typography variant="subtitle2">{listItem.title}</Typography>}
          placement="right"
          arrow
        >
          <ListItem
            button
            onClick={listItemAction}
            className={
              pathIsActive ? classes.sideNavButtonActive : classes.sideNavButton
            }
            style={{ width: 43 }}
            focusRipple
          >
            <ListItemIcon>
              <SideNavIcon
                htmlColor="#E79494"
                svgPathIcon={listItem.SvgPath}
                classes={{
                  root:
                    listItem.path === location.pathname
                      ? classes.sideNavIconColor
                      : classes.sideNavIcon,
                }}
              />
            </ListItemIcon>
          </ListItem>
        </Tooltip>
      ) : (
        <ListItem
          button
          onClick={listItemAction}
          className={
            pathIsActive ? classes.sideNavButtonActive : classes.sideNavButton
          }
          focusRipple
        >
          <ListItemIcon>
            <SideNavIcon
              htmlColor="#E79494"
              svgPathIcon={listItem.SvgPath}
              classes={{
                root:
                  listItem.path === location.pathname
                    ? classes.sideNavIconColor
                    : classes.sideNavIcon,
              }}
            />
          </ListItemIcon>
          {drawerIsOpen ? (
            <ListItemText
              className={
                pathIsActive ? classes.listItemTextActive : classes.listItemText
              }
            >
              {listItem.title}
            </ListItemText>
          ) : null}
        </ListItem>
      )}
      {hasSubList &&
        drawerIsOpen &&
        listItem.subCategories &&
        ((window.location.pathname === `${process.env.REACT_APP_BASENAME}/` &&
          listItem.title === 'Dashboard') ||
          (window.location.pathname ===
            `${process.env.REACT_APP_BASENAME}/news` &&
            listItem.title === 'News')) && (
          <List component="div" disablePadding className={classes.nested}>
            {listItem?.subCategories.map((subCategory, index) => (
              <>
                {subCategory.requiresAdmin === isAdmin && (
                  <NavListItem
                    key={`${uuid()}-${index}`}
                    listItem={subCategory}
                    classes={classes}
                    location={location}
                    drawerIsOpen={drawerIsOpen}
                  />
                )}
              </>
            ))}
          </List>
        )}
      <CommunityDialog
        classes={classes}
        open={communityDialogOpen}
        handleClose={handleCommunityDialogClose}
        handleRedirect={handleCommunityRedirect}
      />
    </>
  )
}

export function SideNavIcon(props) {
  return (
    <SvgIcon htmlColor={props.htmlColor} classes={props.classes}>
      {props.svgPathIcon}
    </SvgIcon>
  )
}

export default SideNav
