import { makeStyles } from '@material-ui/core/styles'

// TODO Bring Back Unique Colors
export const myPaycheckStyles = makeStyles((theme) => ({
  content: {
    textAlign: 'center',
    margin: 0,
    padding: 0,
    height: 475,
    overflowY: 'auto',
  },
  icon: {
    color: theme.palette.secondary.headerText,
    fontSize: 40,
    '&:hover': {
      transform: 'scale(1.07)',
    },
  },
  paymentSpan: {
    color: theme.palette.secondary.headerText,
  },
  fullwidth: {
    width: '100%',
  },
  iconButton: {
    margin: '10px 0',
  },
}))

export default myPaycheckStyles
