import { makeStyles } from '@material-ui/core/styles'

// TODO Make these colors match properly instead of hardcoded
export const bottomTabBarStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.components.appBar,
    height: 55,
    minHeight: 55,
    maxHeight: 55,
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: 999999,
  },
  iconSelected: {
    color: theme.palette.primary.main,
    width: 'auto',
    fontSize: 29,
    margin: '0',
  },
  icon: {
    color: theme.palette.secondary.headerText,
    fontSize: 26,
    width: 'auto',
    margin: '0',
  },
  textSelected: {
    fontSize: 10,
    color: theme.palette.primary.main,
    fontWeight: '500',
  },
  text: {
    fontSize: 10,
    fontWeight: '500',
  },
  root: {
    padding: 0,
    width: 'auto',
    maxWidth: 'auto',
    minWidth: 'auto',
    zIndex: 999999,
  },
}))

export default bottomTabBarStyles
