/* eslint-disable no-param-reassign */
import axios from 'axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { sortNewsByDate, formatTweet, handleEpochDate } from '../../helpers'

export const fetchFilteredNews = createAsyncThunk(
  'fetchFilteredNews',
  async (sourceID) => {
    // TODO: Make the call based on the sourceID, here it is passed and filtered manually
    switch (sourceID) {
      case 50: {
        // Twitter
        const res = await axios.get(process.env.REACT_APP_NEWS_TWEETS_ENDPOINT)
        res.data = Object.values(res.data.tweets).map((tweet) =>
          formatTweet(tweet),
        )
        return res
      }
      default: {
        const res = await axios.get(process.env.REACT_APP_NEWS_ENDPOINT)
        return { data: res.data, sourceID }
      }
    }
  },
)

const initialState = {
  status: 'idle',
  activeFilter: 'all',
  filters: [],
  articlesToDisplay: {},
  data: {},
}

const filterArticlesByActiveFilter = (articles, activeFilter) =>
  articles.filter(
    (article) =>
      activeFilter === 'all' || article.categories.includes(activeFilter),
  )

const filteredNewsSlice = createSlice({
  name: 'filteredNews',
  initialState,
  reducers: {
    setActiveFilter: (state, action) => {
      state.activeFilter = action.payload
      state.articlesToDisplay =
        filteredNewsSlice.caseReducers.setArticlesToDisplay(state)
    },
    setArticlesToDisplay: (state) => {
      let { articlesWithImage, articlesWithoutImage } = state.data.articles
      const { activeFilter } = state

      articlesWithImage = filterArticlesByActiveFilter(
        articlesWithImage,
        activeFilter,
      )

      articlesWithoutImage = filterArticlesByActiveFilter(
        articlesWithoutImage,
        activeFilter,
      )

      return {
        ...state,
        articlesWithImage,
        articlesWithoutImage,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFilteredNews.rejected, (state) => {
        state.status = 'error'
      })
      .addCase(fetchFilteredNews.pending, (state) => {
        state.status = 'pending'
      })
      .addCase(fetchFilteredNews.fulfilled, (state, action) => {
        const { data, sourceID } = action.payload
        const categoriesSet = new Set()
        const unSortedArticlesWithImage = []
        const unsortedArticlesWithoutImage = []
        data.forEach((article) => {
          if (
            article.sid !== sourceID &&
            article.tag !== sourceID && // Skip if neither sid nor tag matches sourceID
            !(sourceID === 'social' && article.sid >= 41) // Skip if sourceID is 'social' and sid is >=
          ) {
            return
          }
          // Map news
          const mappedArticle = mapNewsArticle(article)
          // Separate articles with and without images
          if (
            mappedArticle.imageUrl !== '' &&
            mappedArticle.imageUrl !== null
          ) {
            unSortedArticlesWithImage.push(mappedArticle)
          } else {
            unsortedArticlesWithoutImage.push(mappedArticle)
          }

          // Add categories to set
          if (mappedArticle.categories) {
            mappedArticle.categories.forEach((category) =>
              categoriesSet.add(category),
            )
          }
        })

        state.filters = ['all', ...categoriesSet]

        const articlesWithImage = sortNewsByDate(unSortedArticlesWithImage)
        const articlesWithoutImage = sortNewsByDate(
          unsortedArticlesWithoutImage,
        )

        // Set sorted default list of articles
        const sortedAndFilteredArticles = {
          articlesWithImage,
          articlesWithoutImage,
        }
        console.log('sortedAndFilteredArticles ', sortedAndFilteredArticles)
        state.data.articles = sortedAndFilteredArticles
        state.articlesToDisplay = sortedAndFilteredArticles

        state.status = 'fulfilled'
      })
  },
})

function mapNewsArticle(article) {
  return {
    id: article.id,
    categories: article.categories,
    title: article.id,
    sourceID: article.sid,
    sourceName: article.sourceEntryId,
    author: article.author,
    click_thru: article.click_thru,
    description: article.description,
    filter: article.filter,
    imageUrl: article.imageUrl,
    firebaseImageUrl: article.firebaseImageUrl,
    published: handleEpochDate(article.pubDate.seconds),
    pubDateEpoch: article.pubDate.seconds,
    tag: article.tag,
    type: article.type,
    url: article.url,
    view_count: article.view_count,
    view_count_wk: article.view_count_wk,
  }
}

export const selectFilteredNewsData = (state) => state.filteredNews.data
export const selectFilteredNewsStatus = (state) => state.filteredNews.status
export const selectFilteredNewsFilters = (state) => state.filteredNews.filters
export const selectFilteredNewsActiveFilter = (state) =>
  state.filteredNews.activeFilter
export const selectFilteredNewsArticlesToDisplay = (state) =>
  state.filteredNews.articlesToDisplay

export const { setActiveFilter, setArticlesToDisplay } =
  filteredNewsSlice.actions

export default filteredNewsSlice.reducer
