/* eslint-disable */
import React from 'react'

import Grid from '@material-ui/core/Grid'

import IconPicker from '../../../apps/AppComponents/IconPicker/IconPicker'
import Widget from '../../widgets/Widget'
import styles from './IconLinkStyles'

function IconLinks({
  icons,
  size = '3x',
  disabled = false,
  perRow = 4,
  widgetId,
  dispatch,
}) {
  const xsVal = 12 / perRow
  const classes = styles()
  return (
    <Grid container direction="row" justifyContent="center">
      {icons &&
        icons.map((icon) => (
          <Grid
            container
            key={icon.iconLabel + icon.link}
            className={classes.verticalSpace}
            direction="column"
            alignContent="center"
            item
            xs={xsVal}
          >
            <Widget.TabLink
              icon={
                <IconPicker iconName={icon.icon} size={size} color="gray" />
              }
              iconLabel={icon.iconLabel}
              hyperLink={icon.link}
              disabled={disabled}
              widgetId={widgetId}
              dispatch={dispatch}
            />
          </Grid>
        ))}
    </Grid>
  )
}

export default IconLinks
