/* eslint-disable prefer-destructuring */
import * as React from 'react'
import { doc, collection, getDoc } from 'firebase/firestore'
import {
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core'

import { useDispatch, useSelector } from 'react-redux'

import Widget from '../Widget'
import { courseRegistrationStyles } from './courseRegistrationStyles'
import {
  fetchCourseRegistration,
  selectCourseRegistrationCampus,
  selectCourseRegistrationLevels,
  selectCourseRegistrationTerms,
  selectCourseRegistrationStatus,
  selectShowCourseRegistrationTutorial,
  selectCourseRegistrationIconLinks,
  endTutorial,
  selectCourseRegistrationDataIncomplete,
  selectCourseRegistrationIconLinksTutorial,
  selectCourseRegistrationTermsTutorial,
  selectCourseRegistrationLevelsTutorial,
  selectCourseRegistrationCampusTutorial,
  fetchCourseRegistrationTutorial,
} from './courseRegistrationSlice'
import Loading from '../helpers/Loading'
import IconLinks from '../../DashboardComponents/IconLinks/IconLinks'

import tutorialStyles from '../../DashboardComponents/Tutorial/TutorialStyles'
import TutorialMode from '../../DashboardComponents/Tutorial/TutorialMode'
import courseRegistrationTutorial from './courseRegistrationTutorial'
import { firestore } from '../../../../../utils/firebase'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'

// eslint-disable-next-line no-unused-vars
function CourseRegistration({ className, title, widgetId }) {
  const dispatch = useDispatch()
  const { userProfile } = useSelector((s) => s.myProfile)
  const campus = useSelector(selectCourseRegistrationCampus)
  const levels = useSelector(selectCourseRegistrationLevels)
  const terms = useSelector(selectCourseRegistrationTerms)
  const widgetCallState = useSelector(selectCourseRegistrationStatus)
  const showTutorial = useSelector(selectShowCourseRegistrationTutorial)
  const stateEndTutorial = useSelector(endTutorial)
  const iconLinks = useSelector(selectCourseRegistrationIconLinks)
  const courseRegistrationDataIncomplete = useSelector(
    selectCourseRegistrationDataIncomplete,
  )

  const campusTutorial = useSelector(selectCourseRegistrationCampusTutorial)
  const levelsTutorial = useSelector(selectCourseRegistrationLevelsTutorial)
  const termsTutorial = useSelector(selectCourseRegistrationTermsTutorial)
  const iconLinksTutorial = useSelector(
    selectCourseRegistrationIconLinksTutorial,
  )

  React.useEffect(() => {
    dispatch(fetchCourseRegistration())
  }, [])

  React.useEffect(() => {
    if (showTutorial && courseRegistrationDataIncomplete)
      dispatch(fetchCourseRegistrationTutorial())
  }, [showTutorial, courseRegistrationDataIncomplete])

  return (
    <Widget className={className}>
      <Widget.Header title={title} category="default" />
      <Widget.Content>
        {renderCourseRegistrationState(
          widgetCallState,
          userProfile.location,
          userProfile.level,
          showTutorial && courseRegistrationDataIncomplete
            ? campusTutorial
            : campus,
          showTutorial && courseRegistrationDataIncomplete
            ? levelsTutorial
            : levels,
          showTutorial && courseRegistrationDataIncomplete
            ? termsTutorial
            : terms,
          showTutorial,
          stateEndTutorial,
          dispatch,
          showTutorial && courseRegistrationDataIncomplete
            ? iconLinksTutorial
            : iconLinks,
        )}
      </Widget.Content>
    </Widget>
  )
}

function renderCourseRegistrationState(
  widgetCallState,
  defaultLocation,
  defaultLevel,
  campus,
  levels,
  terms,
  showTutorial,
  stateEndTutorial,
  dispatch,
  iconLinks,
) {
  const classes = courseRegistrationStyles()
  // TODO: Handle Proper Loading and Error States
  switch (widgetCallState) {
    case 'fulfilled':
      return (
        <Loaded
          defaultLocation={defaultLocation}
          defaultLevel={defaultLevel}
          campus={campus}
          levels={levels}
          terms={terms}
          showTutorial={showTutorial}
          stateEndTutorial={stateEndTutorial}
          dispatch={dispatch}
          iconLinks={iconLinks}
        />
      )
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage />
        </Grid>
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item className={classes.text}></Grid>
        </Grid>
      )
  }
}

function Loaded({
  defaultLocation,
  defaultLevel,
  campus,
  levels,
  terms,
  stateEndTutorial,
  dispatch,
  iconLinks,
}) {
  const classes = courseRegistrationStyles()

  const tutorialClasses = tutorialStyles()
  const tutorial = courseRegistrationTutorial
  const emptyCampusMenu = [
    {
      label: 'New Brunswick',
      value: 'NB',
    },
  ]
  const emptyLevelsMenu = [
    {
      label: 'Undergraduate',
      value: 'U',
    },
  ]
  const emptyTermsMenu = [
    {
      label: 'Winter 2021',
      value: '12021',
    },
  ]

  const [campusVal, setCampusVal] = React.useState(campus[0].value)
  const [levelVal, setLevelVal] = React.useState(levels[0].value)
  const [termVal, setTermVal] = React.useState('')
  const [courseField, setCourseField] = React.useState('')
  const [showTutorialBool, setShowTutorial] = React.useState(false)

  React.useEffect(() => {
    if (defaultLocation) {
      const defaultCampusMenu = campus.filter(
        (place) => place.label === defaultLocation,
      )
      if (defaultCampusMenu.length > 0) {
        setCampusVal(defaultCampusMenu[0].value)
      }
    }
    if (defaultLevel) {
      const defaultLevelMenu = levels.filter(
        (level) => level.label === defaultLevel,
      )
      if (defaultLevelMenu.length > 0) {
        setLevelVal(defaultLevelMenu[0].value)
      }
    }
    if (terms) setTermVal(terms[0].value)
  }, [campus, levels, terms, defaultLocation, defaultLevel])

  React.useEffect(() => {
    const fetchTutorialData = async () => {
      const userDocRef = doc(
        collection(firestore, 'users'),
        localStorage.getItem('user'),
        'settings',
        'tutorials',
      )
      const docData = await getDoc(userDocRef)
      setShowTutorial(docData.data()?.show_course_registration_tutorial)
    }
    fetchTutorialData()
    return () => setShowTutorial(false)
  }, [])

  const handleCampusVal = (c) => {
    setCampusVal(c.target.value)
  }
  const handleLevelVal = (l) => {
    setLevelVal(l.target.value)
  }

  const handleTermVal = (t) => {
    setTermVal(t.target.value)
  }

  const handleChange = (e) => {
    setCourseField(e.target.value)
  }

  const handleSubmit = () => {
    const APIStart = 'http://sis.rutgers.edu/soc/'

    let searchFieldAPI = ''
    if (courseField) {
      const searchFieldAPIPiece = '#keyword?keyword='
      const replaceSearchValueSpace = encodeURIComponent(courseField.trim())
      searchFieldAPI = searchFieldAPIPiece
        .concat(replaceSearchValueSpace)
        .concat('&')
    } else {
      searchFieldAPI = '#subjects?'
    }
    const termAPIVal = 'semester='.concat(termVal)
    const campusAPIVal = '&campus='.concat(campusVal)
    const levelAPIVal = '&level='.concat(levelVal)
    window.open(
      APIStart.concat(searchFieldAPI)
        .concat(termAPIVal)
        .concat(campusAPIVal)
        .concat(levelAPIVal),
    )
  }

  return (
    <div>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item className={classes.selectContainer}>
          <InputLabel id="campus-menu-select">
            <Select
              labelId="campus-menu-select"
              value={campusVal}
              onChange={(c) => handleCampusVal(c)}
              className={classes.selector}
              inputProps={{
                classes: {
                  icon: classes.icon,
                },
              }}
            >
              {(campus ?? emptyCampusMenu).map((option) => (
                <MenuItem key={option} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </InputLabel>
        </Grid>

        <Grid item className={classes.secondarySelect}>
          <InputLabel id="level-select">
            <Select
              value={levelVal}
              labelId="level-select"
              onChange={(l) => handleLevelVal(l)}
              className={classes.selector}
              inputProps={{
                classes: {
                  icon: classes.icon,
                },
              }}
            >
              {(levels ?? emptyLevelsMenu).map((option) => (
                <MenuItem key={option} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </InputLabel>
        </Grid>

        <Grid item className={classes.tertiarySelect}>
          <InputLabel id="term-select">
            <Select
              value={termVal}
              labelId="term-select"
              onChange={(t) => handleTermVal(t)}
              className={classes.selector}
              inputProps={{
                classes: {
                  icon: classes.icon,
                },
              }}
            >
              {(terms ?? emptyTermsMenu).map((option) => (
                <MenuItem key={option} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </InputLabel>
        </Grid>

        <Grid item className={classes.textFieldContainer}>
          <InputLabel id="search-courses">
            <TextField
              className={classes.textField}
              size="small"
              label="Search Courses"
              labelId="search-courses"
              variant="outlined"
              value={courseField}
              onChange={(e) => handleChange(e)}
            />
          </InputLabel>
        </Grid>
        <Grid item>
          <Button
            className={classes.submitButton}
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
        </Grid>
        <Widget.Spacer />
        <Widget.Spacer />
        <Grid item>
          <IconLinks icons={iconLinks} perRow={2} />
        </Grid>
      </Grid>
      {showTutorialBool === true ? (
        <TutorialMode
          showTutorial={showTutorialBool}
          steps={tutorial}
          styles={tutorialClasses}
          stateEndTutorial={stateEndTutorial}
          dispatch={dispatch}
        />
      ) : null}
    </div>
  )
}

export default CourseRegistration
