import * as React from 'react'
import { useMediaQuery } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import SearchBar from '../../../dashboard/DashboardComponents/SearchBar/SearchBar'
import { filterByValue, sortListAlphabetically } from '../../utils/helpers'
import {
  setSearchValue,
  setSelectedRoutes,
  setSelectedStops,
} from '../../sections/Map/redux/myMapsSlice'
import { setSelectedCampusBuildings } from '../../sections/Buildings/redux/myBuildingsSlice'
import useMapSearchBarStyles from './MapSearchBarStyles'

/**
 * There's an issue with this component. When you move in the isDesktopView and showSearchBar into this component instead of on parent, the UI doesn't look right on mobile.
 */
function MapSearchBar() {
  const dispatch = useDispatch()
  const classes = useMapSearchBarStyles()
  const [tabLabel, setTabLabel] = React.useState('')
  const isDesktopView = useMediaQuery((theme) => theme.breakpoints.up('md'))
  // const isDesktopView = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const { showSearchBar } = useSelector((state) => state.maps)
  const {
    routes,
    stops,
    tabValue,
    mapSearchBottomSheetSelectedTab,
    searchValue,
  } = useSelector((s) => s.maps)

  const { campusBuildings } = useSelector((s) => s.buildings)

  React.useEffect(() => {
    const val = {
      0: 'Routes',
      1: 'Stops',
      2: 'Buildings',
    }

    const label = `Search ${val[mapSearchBottomSheetSelectedTab]}`
    setTabLabel(label)
  }, [mapSearchBottomSheetSelectedTab])

  React.useEffect(() => {
    if (tabValue === 1) {
      const label = `Search Buildings`
      setTabLabel(label)
    }
  }, [tabValue])

  React.useEffect(() => {
    if (!showSearchBar) {
      cancelSearch()
    }
  }, [showSearchBar])

  const handleSearchBar = (event) => {
    const val = event.target.value
    dispatch(setSearchValue(val))

    // Use the utility function to filter the data
    const filteredRouteList = filterByValue(routes, 'route_long_name', val)
    const filteredStops = filterByValue(stops, 'stop_name', val)

    const campusKeys = ['New Brunswick', 'Newark', 'Camden', 'Other']
    const filteredCampusBuildings = {}
    campusKeys.forEach((key) => {
      filteredCampusBuildings[key] = filterByValue(
        campusBuildings[key],
        'name',
        val,
      )
    })

    // Dispatching the filtered data
    dispatch(setSelectedRoutes(filteredRouteList))
    dispatch(setSelectedStops(filteredStops))
    dispatch(setSelectedCampusBuildings(filteredCampusBuildings))

    // Handling empty search value
    if (val === '') {
      cancelSearch()
    }
  }

  const cancelSearch = () => {
    dispatch(setSearchValue(''))
    dispatch(setSelectedStops(sortListAlphabetically(stops, 'stop_name')))
    dispatch(
      setSelectedRoutes(sortListAlphabetically(routes, 'route_long_name')),
    )
    dispatch(
      setSelectedCampusBuildings({
        'New Brunswick': sortListAlphabetically(
          campusBuildings['New Brunswick'],
        ),
        Newark: sortListAlphabetically(campusBuildings.Newark),
        Camden: sortListAlphabetically(campusBuildings.Camden),
        Other: sortListAlphabetically(campusBuildings.Other),
      }),
    )
  }

  return (
    <>
      {showSearchBar && (
        <div
          className={
            isDesktopView
              ? classes.searchBarContainer
              : classes.searchBarContainerMobile
          }
        >
          <SearchBar
            className={
              isDesktopView ? classes.searchBar : classes.searchBarMobile
            }
            value={searchValue}
            onChange={(val) => handleSearchBar(val)}
            onCancelSearch={() => cancelSearch()}
            placeholder={tabLabel}
            moduleName="myMaps"
            dispatch={dispatch}
          />
        </div>
      )}
    </>
  )
}

export default MapSearchBar
