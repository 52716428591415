import { makeStyles } from '@material-ui/core/styles'

// TODO Bring Back Unique Colors
export const campusPassStyles = makeStyles((theme) => ({
  content: {
    padding: 0,
  },
  tabBackground: {
    overflowY: 'hidden',
    width: '100%',
    minHeight: 35,
    height: '35px',
  },
  modalTabs: {
    width: 335,
    height: 35,
    minHeight: 35,
    [theme.breakpoints.down(370)]: {
      width: 300,
    },
  },
  tabTextLabel: {
    marginRight: 10,
  },
  text: {
    color: theme.palette.primary.headerText,
    margin: 10,
  },
  buttonColor: {
    // color: theme.palette.base.white,
    // backgroundColor: theme.palette.base.red,
    marginTop: 8,
    // '&:hover': {
    //   backgroundColor: theme.palette.base.redHover,
    // },
  },
  img: {
    height: 160,
    width: 180,
  },
  margin: {
    margin: theme.spacing(1),
  },
  campusPassLink: {
    // color: theme.palette.secondary.dark,
    height: 60,
    width: 230,
    borderRadius: 25,
    // backgroundColor: theme.palette.base.yellow,
    '&:hover': {
      // backgroundColor: theme.palette.base.yellowHover,
    },
  },
  previousSurveyContainer: {
    height: 400,
    width: 330,
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
  },
  previousSurveyResultsContainer: {
    marginBottom: 20,
  },
  previousSurveyText: {
    fontWeight: 600,
    color: theme.palette.base.red,
    flexDirection: 'flex-start',
    paddingBottom: 10,
  },
  surveyCleared: {
    color: theme.palette.base.green,
    fontWeight: 600,
  },
  surveyNotCleared: {
    color: theme.palette.base.red,
    fontWeight: 600,
  },
  surveyLocationsContainer: {
    marginBottom: 20,
  },
  surveyLocations: {
    fontWeight: 600,
    textAlign: 'center',
  },
  renewButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
  },
  renewCampusPassButton: {
    background: '#f9c642',
    color: theme.palette.primary.headerText,
    borderRadius: '1rem',
    '&:hover': {
      background: '#f9c642',
    },
  },
  renewButtonText: {
    fontSize: 22,
  },
  renewButtonSpan: {
    fontSize: 16,
  },
  renewSurveyButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  renewIcon: {
    margin: '0 auto',
  },
  surveyResultsIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 15,
  },
  surveyResultsHeader: {
    fontWeight: 600,
    textAlign: 'center',
  },
  significantExposureText: {
    color: theme.palette.base.red,
    textAlign: 'center',
    paddingBottom: 10,
  },
  submittedSurveyText: {
    color: theme.palette.base.gray,
    textAlign: 'center',
    paddingBottom: 10,
  },
  surveyTimestampText: {
    color: theme.palette.base.gray,
    fontWeight: 600,
    textAlign: 'center',
    paddingBottom: 10,
  },
  furtherInstructionsLink: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 10,
  },
  centerIcon: {
    margin: '0px auto',
  },
  footer: {
    width: '100%',
  },
  mainGrid: {
    height: '475px',
    display: 'flex',
  },
  tabPanel1: {
    flex: 1,
    minHeight: '1px',
  },
}))

export default campusPassStyles
