import { makeStyles } from '@material-ui/core/styles'

export const MyWellnessStyles = makeStyles((theme) => ({
  wellnessListContainer: {
    padding: 0,
    margin: 0,
    height: 475,
    width: 335,
    overflowY: 'hidden',
    overflowX: 'hidden',
    [theme.breakpoints.down(370)]: {
      width: 287,
      height: 450,
    },
  },
  wellnessCardGrid: {
    overflowX: 'hidden',
    overflowY: 'auto',
    height: 'calc(100% - 45px)',
  },
  wellnessObject: {
    width: '100%',
    height: 'auto',
    marginBottom: 9,
    marginLeft: 5,
    marginRight: 5,
    textAlign: 'left',
    color: 'white',
    [theme.breakpoints.down(370)]: {
      width: 270,
    },
  },
  image: {
    width: '100%',
    borderRadius: '5px',
  },
  divider: {
    width: '100%',
  },
  header: {
    margin: '10px 0px 5px 0px',
  },
  detailListContainer: {
    height: '382px',
    width: '335px',
    margin: 0,
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  footer: {
    height: '50px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    marginBottom: 0,
    boxShadow: 3,
    width: '100%',
  },
  description: {
    padding: '10px',
  },
  videoCard: {
    textAlign: 'left',
  },
  videoButton: {
    backgroundColor: theme.palette.backgroundColor.secondary,
  },
  myWellnessLinks: {
    textDecoration: 'underline',
    fontSize: '1rem',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
}))

export default MyWellnessStyles
