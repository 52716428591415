import apiClient from '../../../api/apiClient'
import processRoutesResponse from '../schemas/routes'

// Define endpoints at the top
const ENDPOINTS = {
  TRIP_UPDATES: process.env.REACT_APP_PASSIOGO_TRIP_UPDATES_ENDPOINT,
  TRIPS: process.env.REACT_APP_PASSIOGO_TRIPS_ENDPOINT,
  VEHICLE_POSITIONS: process.env.REACT_APP_PASSIOGO_VEHICLE_POSITIONS,
  STOPS_AS_GEO_JSON: process.env.REACT_APP_PASSIOGO_STOPS_AS_GEO_JSON_ENDPOINT,
  STOPS: process.env.REACT_APP_PASSIOGO_STOPS_ENDPOINT,
  SHAPES_AS_GEO_JSON: process.env.REACT_APP_PASSIOGO_SHAPES_AS_GEOJSON_ENDPOINT,
  ROUTES: process.env.REACT_APP_PASSIOGO_ROUTES_ENDPOINT,
  STOP_TIMES_BY_STOP_ID:
    process.env.REACT_APP_PASSIOGO_STOP_TIMES_BY_STOP_ID_ENDPOINT,
}

export const fetchTripUpdates = async () => {
  const response = await apiClient.get(ENDPOINTS.TRIP_UPDATES)
  return response.data.entity
}

export const fetchTrips = async () => {
  const response = await apiClient.get(ENDPOINTS.TRIPS)
  return { trips: response.data }
}

export const fetchPassioGoVehiclePositions = async () => {
  const response = await apiClient.get(ENDPOINTS.VEHICLE_POSITIONS)
  return response.data.entity
}

export const fetchStopsAsGeoJson = async () => {
  const response = await apiClient.get(ENDPOINTS.STOPS_AS_GEO_JSON)
  return response.data.features
}

export const fetchPassioGoShapesAsGeoJSON = async (tripId) => {
  const response = await apiClient.get(ENDPOINTS.SHAPES_AS_GEO_JSON, {
    params: {
      tripId,
    },
  })
  return response.data
}

export const fetchStopsOnly = async () => {
  const response = await apiClient.get(ENDPOINTS.STOPS)
  return response
}

export const fetchPassioGoRoutes = async () => {
  const response = await apiClient.get(ENDPOINTS.ROUTES)
  const processedResponse = processRoutesResponse(response)
  return processedResponse.data
}

export const fetchScheduleForStopID = async (stopID) => {
  const response = await apiClient.get(ENDPOINTS.STOP_TIMES_BY_STOP_ID, {
    params: {
      stopId: stopID,
    },
  })
  return response.data
}

export async function fetchNearestStopsApi(buildingId) {
  const endpoint = process.env.REACT_APP_NEARESTSTOP_ENDPOINT
  const params =
    window.location.hostname !== 'localhost' ? `/${buildingId}` : ''
  const response = await apiClient.get(`${endpoint}${params}`)
  return response.data
}
