import * as React from 'react'

import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useDispatch, useSelector } from 'react-redux'
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import {
  selectCareerServicesEventsData,
  selectCareerServicesEventsStatus,
  fetchCareerServicesEvents,
} from './CareerServicesEventsSlice'

import styles from './CareerServicesEventsStyles'
import InlineSocialMediaIcons from '../../DashboardComponents/InlineSocialMediaIcons/InlineSocialMediaIcons'
import Footer from '../../DashboardComponents/Footer/Footer'
import Loading from '../helpers/Loading'
import Widget from '../Widget'
import { logDashboardEvent } from '../../events'
import { ErrorMessage } from '../../DashboardComponents/ErrorMessages/ErrorMsg'

// eslint-disable-next-line no-unused-vars
function CareerServices({ className, title, widgetId }) {
  const classes = styles()
  const dispatch = useDispatch()
  const widgetData = useSelector(selectCareerServicesEventsData)
  const widgetStatus = useSelector(selectCareerServicesEventsStatus)

  React.useEffect(() => {
    dispatch(fetchCareerServicesEvents())
  }, [])

  return (
    <Widget className={className}>
      <Widget.Header title={title} category="default" />
      <Widget.Content className={classes.container}>
        {renderCareerServices(
          widgetStatus,
          widgetData,
          classes,
          widgetId,
          dispatch,
        )}
      </Widget.Content>
    </Widget>
  )
}

function renderCareerServices(
  widgetCallState,
  widgetData,
  classes,
  widgetId,
  dispatch,
) {
  // TODO: Handle Proper Error States
  switch (widgetCallState) {
    case 'fulfilled':
      return (
        <Loaded
          widgetData={widgetData}
          classes={classes}
          widgetId={widgetId}
          dispatch={dispatch}
        />
      )
    case 'pending':
      return <Loading />
    case 'error':
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <ErrorMessage />
        </Grid>
      )
    default:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item></Grid>
        </Grid>
      )
  }
}

function Loaded({ widgetData, classes, dispatch, widgetId }) {
  const buttonTitle = 'Apply'
  const handleEventClick = (link) => {
    window.open(link, '_blank')
    if (widgetId && dispatch) {
      logDashboardEvent(dispatch, 'dashboardEvent', widgetId, buttonTitle, link)
    }
  }
  return (
    <Grid container direction="column" className={classes.gridContainer}>
      <Grid
        item
        container
        direction="column"
        className={classes.eventsHolder}
        wrap="nowrap"
      >
        {widgetData.events.map((event) => (
          <Grid item key={event.description}>
            <Accordion className={classes.accordionCard} variant="outlined">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">{event.title}</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.description}>
                <Grid container direction="column" spacing={2}>
                  <Typography variant="body2" className={classes.description}>
                    {event.description}
                  </Typography>
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="flex-end"
                  >
                    <Button
                      onClick={() => handleEventClick(event.link)}
                      color="primary"
                      variant="contained"
                    >
                      {buttonTitle}
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
      <Grid item>
        <Footer toggleStroke>
          <InlineSocialMediaIcons
            buttons={widgetData.inlineSocialMediaIcons}
            widgetId={widgetId}
            dispatch={dispatch}
          />
        </Footer>
      </Grid>
    </Grid>
  )
}

export default CareerServices
