/* eslint-disable no-return-assign */
import React from 'react'
import { Typography as Text, Card, CardMedia, Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import ForYouImageCarouselStyles from './ForYouImageCarouselStyles'
import { getImageFromFirebaseWithHook } from '../../../../helpers'

const ForYouImageCarouselItem = ({ img, title, alignRight }) => {
  const classes = ForYouImageCarouselStyles()
  const history = useHistory()
  const [image, setImage] = React.useState(
    './assets/images/image-not-found.png',
  )

  React.useEffect(() => {
    getImageFromFirebaseWithHook(img, setImage)
  }, [])

  const handleClick = () => {
    history.push({
      pathname: './infoPage',
      search: `?type=ForYou&id=${title}`,
    })
  }

  return (
    <div key={title} style={{ height: '30vh', width: '100%' }}>
      <Card className={classes.root} elevation={3}>
        <CardMedia className={classes.media} image={image} title="img" />

        <Text className={alignRight ? classes.headerRight : classes.headerLeft}>
          {title}
        </Text>

        <Button
          className={
            alignRight
              ? classes.learnMoreButtonRight
              : classes.learnMoreButtonLeft
          }
          onClick={handleClick}
        >
          Learn More
        </Button>
      </Card>
    </div>
  )
}
export default ForYouImageCarouselItem
